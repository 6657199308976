import { db } from "initFirebase";
import { useForm } from "react-hook-form";
import { SetStateAction, useEffect, useState, useContext } from "react";
import { Modal, Row, Col, Form, InputGroup, FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { BsPlus } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import {
	SearchIcon,
	CheckmarIcon,
	CheckmarkCircle,
	EditIcon,
	CloseCircle,
	EditIcon2,
	Arrow,
	Eye,
	Registro_Actividad,
} from "assets/Imports";

import Select from "react-select";
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { getNumsAndDatesByGenId, Seminary, getGens, Gens } from "services/GenerationService";
import PhoneInput from "react-phone-number-input/mobile";
import es from "react-phone-number-input/locale/es.json";
import "./stylesModal.scss";
import "../../CollaboratorContainer.scss";
import { SeccionsModal } from "./seccionsModal";
import EventLog from "./eventsModal";
import { MenusDataContext } from "../../context/menu-data.provider";
import { useAuth } from "@hooks/useAuth";
import { useUpdateCollaborator } from "@hooks/collaborators/UseUpdateCollaborator";
import Swal from "sweetalert2";
import { UseRole } from "@hooks/collaborators/UseRole";
import { useDeleteCollaborator } from "@hooks/collaborators/DeleteCollaborator";

export function EditCollaboratorModal(props) {
	const { show, Hide, selectedUser, fetchCollaborators } = props;
	const [sessionsModal, setsessionsModal] = useState<boolean>(false);
	const [showEvents, setshowEvents] = useState(false);
	const { PreviewMode, rutasAccesibles,rutasAccesiblesColb } = useContext(MenusDataContext);
	const [selectedMenus, setSelectedMenus] = useState<any>([]);
	const { loading, error, updateCollaborator } = useUpdateCollaborator();
	const { GetRoles } = UseRole;
	const user = useAuth().user;
	const [roles, setRoles] = useState<any>([]);
	const deleteCollaborator = useDeleteCollaborator();
	const [selectedRole, setSelectedRole] = useState("");
	const [nameCollaborator, setNameCollaborator] = useState(selectedUser?.NameCollaborator);
	const [selectedItems, setSelectedItems] = useState([]);

	const fetchRoles = async () => {
		try {
			const rolesData = await GetRoles();

			const options: any = [];
			rolesData.map((role) =>
				options.push({
					value: role.id,
					label: role.roleName,
				})
			);

			setRoles(options);
		} catch (error) {
			console.error("Error al obtener los roles:", error);
		}
	};

	useEffect(() => {
		fetchRoles();
	}, []);

	const handleAddMenu = (menus) => {
		const tempSections = rutasAccesiblesColb.filter((e) => menus.indexOf(e.id) !== -1);
		const newMenus = tempSections.map((menu) => ({
			titulo: menu.Titulo,
			id: menu.id,
		}));
		setSelectedMenus(newMenus);
	};

	let history = useHistory();

	const handleRemoveMenu = (menu) => {
		setSelectedMenus((prevMenus) => prevMenus.filter((selectedMenu) => selectedMenu.id !== menu.id));
	};

	useEffect(() => {
		if (selectedUser && show) {
			const tempSections = rutasAccesiblesColb.filter((e) => selectedUser.seccionsCollaboratorId.indexOf(e.id) !== -1);
			const predataseccions = tempSections.map((menu) => ({
				titulo: menu.Titulo,
				id: menu.id,
			}));
			setSelectedMenus(predataseccions);
		} else {
			setSelectedMenus([]);
		}
	}, [selectedUser, show]);

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			border: "none",
			boxShadow: "none", // Elimina la sombra del select
			//width: '100%',Ajusta el ancho del select
		}),
		indicatorSeparator: () => ({
			display: "none",
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			paddingRight: "5px", // Aumenta este valor para mover el icono hacia la derecha
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "#000000", // Establece el color del texto como negro oscuro
			fontWeight: "bolder",
		}),
	};

	/*const handleCancel = () => {
		setSelectedMenus([]);
	};*/

	const customSelectComponents = {
		DropdownIndicator: () => <BsPlus style={{ fontSize: "35px", color: "black" }} />,
	};

	const form = useForm({
		defaultValues: {
			collaborator_name: selectedUser?.NameCollaborator,
			collaborator_email: selectedUser?.Email,
			permissions: [selectedUser?.seccionsCollaborator],
			collaborator_rol:
				selectedUser.rolesCollaborator && selectedUser.rolesCollaborator.length > 0 ? selectedUser.rolesCollaborator[0] : "",
		},
	});

	const {
		register,
		handleSubmit,
		reset,
		control,
		getValues,
		setValue,
		watch,
		formState,
		formState: { errors, isSubmitSuccessful },
	} = form;

	const validateEmail = (value) => {
		if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
			return "Correo electrónico con formato invalido";
		}
		return true;
	};

	const save = async (data) => {
		try {
			let selectedMenusIDs: any = [];

			selectedMenus.map((menu: any) => selectedMenusIDs.push(menu.id));

			let updatedData = {
				name: nameCollaborator,
				email: selectedUser.Email,
				permissions: selectedMenusIDs,
				roles: selectedRole,
			};

			// Agrega dynamicText solo si selectedItems no está vacío
			if (selectedItems.length > 0) {
				updatedData["dynamicText"] = selectedItems;
			}

			await updateCollaborator(selectedUser?.id, updatedData, selectedMenusIDs);
			toast.success("El usuario ha sido actualizado exitosamente!");
			setSelectedMenus([]);
			props.Hide();
			fetchCollaborators();
		} catch (error) {
			console.log(error);
			toast.error("Hubo un problema al actualizar los permisos.");
		}
	};

	useEffect(() => {
		if (selectedUser) {
			reset({
				collaborator_name: selectedUser.NameCollaborator,
				collaborator_email: selectedUser.Email,
				//	permissions: selectedUser.seccionsCollaborator,
				collaborator_rol:
					roles[
						selectedUser.rolesCollaborator && selectedUser.rolesCollaborator.length > 0
							? roles.findIndex((e) => e.label === selectedUser.rolesCollaborator[0])
							: 0
					],
			});
			setNameCollaborator(selectedUser.NameCollaborator);
			if (selectedUser.rolesCollaborator && selectedUser.rolesCollaborator.length > 0) {
				const actualRoleIndex = roles.findIndex((e) => e.label === selectedUser.rolesCollaborator[0]);
				setSelectedRole(actualRoleIndex !== -1 ? roles[actualRoleIndex].value : "");
			} else {
				setSelectedRole("");
			}
		}
	}, [selectedUser]);

	function isprevieMode(value) {
		if (value === false) {
			toast("Este usuario no tiene ninguna sección asignada aún", {
				icon: "⚠️",
				style: {
					borderRadius: "10px",
					background: "linear-gradient(to right, #ee1c34, #500b28 69.67%)",
					color: "white",
				},
			});
		} else {
			history.push(`/admin${selectedUser?.seccionsCollaboratorRoutes[0]}`);
			PreviewMode();
		}
	}
	if (!selectedUser) return <></>;
	return (
		<>
			<Modal show={show} onHide={Hide} dialogClassName="user-profile-modal collaborator modal-content">
				<SeccionsModal
					show={sessionsModal}
					selectedUser={selectedUser}
					selectedMenu={selectedMenus}
					Hide={() => {
						setsessionsModal(false);
					}}
					handleRemoveMenu={handleRemoveMenu}
					onAddMenu={handleAddMenu}
					setSelectedItems={setSelectedItems}
					selectedItems={selectedItems}
				/>
				<EventLog
					show={showEvents}
					selectedUser={selectedUser}
					Hide={() => {
						setshowEvents(false);
					}}
				/>
				<Modal.Header className="header" closeButton>
					<Modal.Title className="title">
						<h3 style={{ fontSize: "2rem !important" }}>Detalles</h3>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<div>
						{/*<Toaster />*/}
						<div className="name-streak">
							<h2>{selectedUser?.NameCollaborator}</h2>
						</div>

						<label className="modal-labels" htmlFor="student-name">
							Nombre:
						</label>
						<div className="position-relative">
							<FormControl
								className={`input1 ${errors.collaborator_name ? "is-invalid" : ""}`}
								defaultValue={selectedUser?.NameCollaborator}
								id="student-name"
								type="text"
								placeholder="Nombre del colaborador"
								onChange={(e) => setNameCollaborator(e.target.value)}
							/>

							{errors.collaborator_name && <Form.Text className="text-danger">{errors.collaborator_name.message}</Form.Text>}
						</div>

						<label className="modal-labels" htmlFor="student-email">
							Correo:
						</label>
						<div>
							<input
								className="input1"
								defaultValue={selectedUser?.Email}
								id="student-email"
								type="email"
								style={{ border: "2px solid transparent" }}
								readOnly
								{...register("collaborator_email", { required: "Email requerido", validate: validateEmail })}
							/>
							{errors.collaborator_email && (
								<p style={{ color: "red", fontWeight: "8px", textAlign: "center" }}>{errors.collaborator_email?.message}</p>
							)}
						</div>
						<label className="modal-labels" htmlFor="student-secondaryEmail">
							Secciones asigndas:
						</label>
						<div className="collaborator-sections" onClick={() => setsessionsModal(true)}>
							{selectedMenus.length === 0
								? "sin secciones asignadas"
								: selectedMenus?.map((section, i) => {
										return (
											section.titulo + (i === selectedMenus.length - 2 ? " y " : i === selectedMenus.length - 1 ? "." : ", ")
										);
								  })}
							<div className="top-0 end-0 text-right w-100" style={{ marginLeft: "90%" }}>
								<img src={EditIcon2} className="m-0"></img>
							</div>
						</div>

						<label className="modal-labels mt-2" htmlFor="gen">
							{roles.length > 0 ? "Rol asignado" : "\tPara habilitar el seleccionador de roles,crea uno!"}
						</label>
						<div>
							{roles.length > 0 && (
								<select
									className="input1"
									placeholder={selectedUser?.rolesCollaborator}
									value={selectedRole}
									// Establece el valor seleccionado en el campo
									onChange={(e) => setSelectedRole(e.target.value)}
								>
									<option value="" disabled>
										Sin asignar
									</option>
									{roles.map((role) => {
										return <option value={role.value}>{role.label}</option>;
									})}
								</select>
							)}
						</div>

						<div
							className="d-flex align-items-center"
							style={{ marginTop: "25px" }}
							onClick={() => {
								setshowEvents(true);
							}}
						>
							<img src={Registro_Actividad} style={{cursor:"pointer"}}></img>
							<img src={Arrow} className="fa fa-icono" style={{ marginLeft: "8px" }} />
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="modal-footer">
					<div
						className="d-flex justify-content-start align-items-center"
						style={{ cursor: "pointer", marginRight: "50%" }}
						onClick={() => {
							isprevieMode(selectedUser?.seccionsCollaborator.length > 0);
						}}
					>
						<img src={Eye} className="fa fa-icono" />
						<span className="span-collaborator">Vista de colaborador</span>
					</div>

					<div className="d-flex justify-content-between w-100">
						<button
							className="btn btn-secondary secondary-action text-truncate"
							style={{ maxWidth: "100%", marginRight: "10px", display: "flex" }}
							type="button"
							onClick={async () => {
								const result = await Swal.fire({
									title: `¿Eliminar a ${selectedUser.NameCollaborator} como tu colaborador?`,
									text: "No podrás revertir esta acción!",
									icon: "warning",
									showCancelButton: true,
									confirmButtonColor: "#3085d6",
									cancelButtonColor: "#d33",
									confirmButtonText: "Sí, eliminar!",
									backdrop: false,
								});

								if (result.isConfirmed) {
									deleteCollaborator(selectedUser?.id);
									props.Hide();
									toast.success("Se ha removido este colaborador!");
									fetchCollaborators(); // recarga los usuarios después de eliminar
								}
							}}
						>
							Eliminar colaborador
						</button>

						<button className="btn btn-secondary secondary-action" type="submit" onClick={save}>
							Guardar
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
}
