import "./SeeWordsModalStyles.scss";
import Modal from 'react-bootstrap/Modal';
import { capitalizeFirstLetter } from "functions/Functions";
import { language } from "interfaces/Interfaces";

interface seeWordsModalProps {
    words: Array<language>,
    show: boolean;
    onHide: () => void;
    selected: string;
}

function SeeWordsModal(props: seeWordsModalProps) {

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="see-words-creating-personalized-quiz-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {capitalizeFirstLetter(props.selected) + " - " + props.words.length + " términos"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.words.map((word, index) => {
                    return (
                        <div key={"words-from-see-words-modal-" + index}>
                            <p>
                                {word.native_word.replace(/"/g, "")}
                            </p>
                        </div>
                    )
                })}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => props.onHide()} className="linear-bg action-btn">Cerrar</button>
            </Modal.Footer>
        </Modal>
    )
}

export default SeeWordsModal;