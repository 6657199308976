import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

// Firebase configuration
const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: "gs://academia-de-lenguas-f6f35.appspot.com/",
};

export function getTimestamp() {
	return firebase.firestore.FieldValue.serverTimestamp();
}

const fb = firebase.initializeApp(config);
export const auth = fb.auth();
export const db = fb.firestore();
export const functions = getFunctions();

if (process.env.REACT_APP_ENV === "development") {
	connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}
