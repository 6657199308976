import ContactCard from "./ContactCard.tsx/ContactCard";
import { collection, doc, getDoc, getDocs, limit, query, updateDoc, where } from "firebase/firestore";
import { db } from "initFirebase";
import "./ContactContainer.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReplyContactModal from "./ReplyContact/ReplyContactModal";
import useCollaboratorLogs from "@hooks/collaborators/useCollaboratorLogs";
import ShowReplyModal from "./ReplyContact/ShowReplyModal";
import { useHistory } from "react-router-dom";
import { Spinner20vh } from "@components/Spinner/SpinnerMyAccountView";
import toast, { Toaster } from "react-hot-toast";
import { NofoundMessage } from "assets/Imports";
function ContactContainer({ user }) {
	const [contacts, setContacts] = useState<any>([]);
	const [checking, setChecking] = useState("unreaded");
	const [refresh, setRefresh] = useState(true);
	const [aScroll, setAScroll] = useState<boolean>(false);
	const location = useLocation<any>();
	const [loading, setLoading] = useState(true);
	const [scrollElement, setScrollElement]: any = useState(null);
	const [replyContactModal, setReplyContactModal] = useState<boolean>();
	const [contactToReply, setContactToReply] = useState<any>("");
	const { contactLog } = useCollaboratorLogs();
	const [contactId, setContactId] = useState<any>(null);
	const [showReply, setShowReply] = useState<any>(null);
	const history = useHistory();
	const [viewContact, setviewContact] = useState(false);
	const getContacts = async () => {
		const q = query(collection(db, "newsletter"), where("status", "==", "pending"));
		var array: any = [];
		// var amount = 0;
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			const data = {
				...doc.data(),
				id: doc.id,
			};
			array.push(data);
		});
		array.sort(compare);
		setContacts(array);
		setLoading(false);
	};

	const setContactNotificationSeen = async (contactInfoId) => {
		// When answering a contact message, the notification associated with the contact is set as seen.
		try {
			const q = query(collection(db, "notifications"), where("newsLetterId", "==", contactInfoId), limit(1));
			const querySnapshot = await getDocs(q);
			let contactNotificationId = querySnapshot.docs[0].id ?? "";
			const docRef = doc(db, "notifications", contactNotificationId);
			await updateDoc(docRef, {
				seen: true,
			});
		} catch (error) {
			console.log(error);
		}
	};

	const getContactsReaded = async () => {
		const q = query(collection(db, "newsletter"), where("status", "==", "resolved"));
		var array: any = [];
		// var amount = 0;
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			const data = {
				...doc.data(),
				id: doc.id,
			};
			array.push(data);
		});
		array.sort(compare);
		setContacts(array);
	};

	function compare(a, b) {
		if (a.created > b.created) {
			return -1;
		}
		if (a.created < b.created) {
			return 1;
		}
		return 0;
	}

	const getContactDoc = async (contactId: string) => {
		const docRef = doc(db, "newsletter", contactId);
		const snap = await getDoc(docRef);
		if (snap.exists()) {
			return { id: snap.id, ...snap.data() } as any;
		}
		return null;
	};

	useEffect(() => {
		if (checking === "unreaded") {
			getContacts();
		} else if (checking === "readed") {
			getContactsReaded();
		}
	}, [checking, refresh]);

	const scrollToNotification = async () => {
		if (location.state && location.state.newsLetterId) {
			const contactId = location.state.newsLetterId;
			const contactDoc = await getContactDoc(contactId);

			if (contactDoc) {
				setChecking(contactDoc.status === "resolved" ? "readed" : "unreaded");

				let scroll = window.document.getElementById("contact-card-" + location?.state?.newsLetterId);
				if (scroll !== null) {
					setScrollElement(scroll);
					scroll.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
					scroll.style.border = "2px solid #ca5050";
					setAScroll(true);
				} else {
					if (scrollElement !== null) {
						let e = scrollElement;
						e.style.border = "none";
					}
				}
			} else {
				alert("Parece que esta solicitud de contacto ha sido eliminada");
				delete location.state;
			}
		}
	};

	useEffect(() => {
		if (location.state !== undefined) {
			if (scrollElement) {
				scrollElement.style.border = "none";
			}
			setAScroll(false);
			setScrollElement(null);
			scrollToNotification();
		}
		if (location?.state?.checkedMessage !== undefined) {
			if (location?.state?.checkedMessage) {
				setChecking("readed");
			} else {
				setChecking("unreaded");
			}
		}
	}, [contacts, loading, location.state]);

	useEffect(() => {
		if (location?.state?.contactId) {
			setContactId(location?.state?.contactId);
			setviewContact(true);
		}
	}, [location.state]);

	const handleClickBackToContacts = () => {
		setviewContact(false);
		// Eliminar el contactId del location.state para evitar que se muestre al recargar la página
		history.replace({ ...location, state: { ...location.state, contactId: undefined, checkedMessage: undefined } });
	};

	return (
		<>
			<h1>Contacto</h1>
			<p>Los comentarios que han dejado los usuarios.</p>
			<Toaster />
			{viewContact && (
				<span
					style={{
						margin: 0,
						marginBottom: "4%",
						cursor: "pointer",
						color: "#ca5050",
						fontWeight: "bold",
						textDecoration: "underline",
					}}
					onClick={handleClickBackToContacts}
				>
					click para volver a ver todos tus contactos
				</span>
			)}
			<div style={{ marginBottom: 20 }}>
				<button
					className={"contact-type-selector-btn" + (checking === "unreaded" ? " active" : "")}
					onClick={() => setChecking("unreaded")}
				>
					No leídos
				</button>
				<button
					className={"contact-type-selector-btn" + (checking === "readed" ? " active" : "")}
					onClick={() => setChecking("readed")}
				>
					Leídos
				</button>
			</div>
			<ReplyContactModal
				collaborator={user}
				contactLog={contactLog}
				setRefresh={setRefresh}
				refresh={refresh}
				showModal={replyContactModal}
				onHide={() => {
					setReplyContactModal(false);
				}}
				contactInfo={contactToReply}
				setContactNotificationSeen={setContactNotificationSeen}
			/>
			<ShowReplyModal
				onHide={() => {
					setShowReply(false);
				}}
				show={showReply}
				reply={contactToReply}
			/>

			<div>
				{!loading ? (
					(() => {
						const filteredContacts = viewContact ? contacts.filter((contact) => contact.id === contactId) : contacts;
						return filteredContacts.length > 0 ? (
							filteredContacts.map((contact, i) => {
								return (
									<>
										<ContactCard
											key={contact.id}
											data={contact}
											setRefresh={setRefresh}
											refresh={refresh}
											highlighted={contact.id === contactId}
											setAScroll={setAScroll}
											aScroll={aScroll}
											checking={checking}
											setReplyContactModal={setReplyContactModal}
											setShowReply={setShowReply}
											setContactToReply={setContactToReply}
											setContactNotificationSeen={setContactNotificationSeen}
										/>
									</>
								);
							})
						) : (
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									height: "100vh",
								}}
							>
								<h1>Este contenido no esta disponible por ahora o ya fue borrado.</h1>
								<img src={NofoundMessage} style={{ width: "100%", height: "77%", objectFit: "contain" }}></img>
							</div>
						);
					})()
				) : (
					<Spinner20vh />
				)}
			</div>
		</>
	);
}

export default ContactContainer;
