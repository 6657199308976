import { sendEmailToAdmin } from "@api/index";
import CustomForm from "@components/Shared/CustomForm/CustomForm";
import { addDoc, collection } from "firebase/firestore";
import { db, getTimestamp } from "initFirebase";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import emailjs from 'emailjs-com';

function NewsletterContainer() {
  const postNewsleterUser = async (userObject) => {
    const data = {
      email: userObject.email,
      name: userObject.email,
      phone: userObject.phone,
      message: "",
    };
    try {
      await db.collection("newsletter").add(userObject).then(async (res) => {
        await addDoc(collection(db, 'notifications'), {
          type: 'new-contact-form',
          newsLetterId: res.id,
          name: "Academia de Lenguas Internacionales",
          text: "Nueva solicitud de contacto de " + data.name,
          date: new Date(),
          userId: 'admin',
          userType: 2,
          seen: false,
          profileColor: "#000000",
          sectionId: "4srk2wxgaBdxqGo23vsW"
        }).then(() => {
          sendEmail();
        });
      });
      const response = await sendEmailToAdmin(data);
    } catch (error) {
      console.error(error);
    }
  };

  const sendEmail = () => {
    const dataemail = {
      subject: 'Nueva solicitud de contacto',
      message: 'Tienes una nueva solicitud de contacto en Academia de Lenguas Internacionales'
    }

    emailjs.send('service_kznboar', 'template_33c8s4e', dataemail, 'user_PPI0O8nofs9cbuJ3JRWlT').then(function (res) {
    }, function (error) {
      console.log(error);
    });
  }

  const initialStateValues = {
    name: "",
    email: "",
    phone: "",
    description: "",
    prefered: "",
    status: "pending",
    created: getTimestamp(),
  };

  const [validated, setValidated] = useState(false);

  return (
    <div className="section__newsletter">
      <Container className="shadow-lg p-3 mb-5 bg-white rounded">
        <h3>
          <b>Regístrate y recibe noticias y anuncios importantes</b>
        </h3>
        <p>
          Si deseas estar al tanto de nuestras próximas convocatorias o
          simplemente quieres establecer contacto con nosotros, por favor
          compártenos tu nombre y tu dirección de correo electrónico.
        </p>
        <CustomForm
          postFunction={postNewsleterUser}
          initialStateValues={initialStateValues}
          validated={validated}
          setValidated={setValidated}
        />
      </Container>
    </div>
  );
}

export default NewsletterContainer;
