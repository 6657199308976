import "./WordInfoModal.scss";
import "react-tooltip/dist/react-tooltip.css";
import Modal from "react-bootstrap/Modal";
// import { Tooltip as BsTooltip } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { WordRowCard } from "../WordRowCard/WordRowCard";
import {
	getArmByDifficultByNumber,
	getDominatedIconByNumber,
	getDifficultStringSpanish,
	getInformationByLanguage,
} from "../PersonalizedTest/functions/functions";
import { returnFlag, romanize } from "functions/Functions";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
import { useEffect, useState } from "react";
import Tooltip from "functions/Tooltip/Tooltip";
import { BsInfoCircleFill } from "react-icons/bs";
import { WarningTypeSelector } from "@containers/VocabularySeminaryContainer/components/Test/components/WarningTypeSelector/WarningTypeSelector";
import {
	returnWarningBorderColor,
	returnWarningTypeImage,
	returnWarningTypeTitle,
} from "@containers/VocabularySeminaryContainer/functions/VocabularyFunctions";
import parse from "html-react-parser";
import { CustomSpinner } from "@components/Spinner/SpinnerMyAccountView";
import { fetchWordProgress } from "@services/VocabularyService";

export function WordInfoModal(props) {
	const [warningType, setWarningType] = useState<string>("warning");
	const [wordData, setWordData] = useState<any | null>(null);
	const [correctRatio, setCorrectRatio] = useState<number>(0);
	const [lastStudied, setLastStudied] = useState([]);
	const [firstStudied, setFirstStudied] = useState([]);
	const [seminaries, setSeminaries] = useState([]);

	if (props.word.dominated === null) {
		props.word.dominated = 0;
	}

	const getWordData = async (wordDataId: number) => {
		try {
			const res = await fetchWordProgress(wordDataId);
			setWordData(res);
		} catch (error) {
			console.error("Error fetching word data", error);
		}
	};

	useEffect(() => {
		if (props.word.id) {
			getWordData(props.word.id);
		} else if (props.word.word_data_id) {
			getWordData(props.word.word_data_id);
		}
	}, [props.word]);

	useEffect(() => {
		if (wordData) {
			if (wordData.lastStudied) {
				setLastStudied(wordData.lastStudied.split("/"));
			}
			if (wordData.firstStudied) {
				setFirstStudied(wordData.firstStudied.split("/"));
			}

			setCorrectRatio(
				parseInt(((wordData.times_correct_answer / wordData.times_answered) * 100).toFixed())
			);

			if (wordData.seminarios) {
				setSeminaries(wordData.seminarios.split(", "));
			}
		}
	}, [wordData]);

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header className="edit-word-info-modal-header" closeButton>
				<BsInfoCircleFill className="info-icon" />
				<Modal.Title id="contained-modal-title-vcenter">
					{getInformationByLanguage(props.word?.language)}
				</Modal.Title>
				{returnFlag(props.word?.language)}
			</Modal.Header>
			<Modal.Body>
				{!wordData ? (
					<CustomSpinner height={200} borderSize={4} size={30} />
				) : (
					<>
						<WordRowCard
							word={props.word}
							language={props.word.language}
							answerType={"normal"}
							setState={props.setState}
							state={props.state}
							index={props.index}
							countRefresh={props.countRefresh}
							setCountRefresh={props.setCountRefresh}
						/>
						<div className="grid-info">
							<div
								className="grid-info-item item-category"
								style={
									wordData.singular_category === undefined || wordData.singular_category === null
										? { display: "none" }
										: {}
								}
							>
								<b>Categoría:</b> &nbsp; {wordData.singular_category}
							</div>
							{wordData.times_answered === 0 && (
								<>
									<b style={{ textAlign: "center", gridRow: "1/3", gridColumn: "2" }}>Dificultad</b>
									<b style={{ textAlign: "center", gridRow: "2", gridColumn: "2", color: "gray" }}>
										Sin datos de estudio
									</b>
								</>
							)}
							<div
								className="grid-info-item item-difficulty"
								style={wordData?.times_answered === 0 ? { display: "none" } : {}}
							>
								<b>Dificultad</b>
								<div className="difficulty-container">
									<div className="diff-icon">
										{getArmByDifficultByNumber(wordData.custom_difficulty)}{" "}
									</div>
									<span>{getDifficultStringSpanish(wordData.custom_difficulty)}</span>
								</div>
								<ReactTooltip
									anchorSelect=".diff-icon"
									content={`${wordData.times_correct_answer}/${wordData.times_answered}`}
									data-tooltip-offset={10}
									place="top"
									style={{
										opacity: 1,
										backgroundColor: "black",
										padding: "8px",
										fontSize: "0.7rem",
									}}
								/>
							</div>
							<div
								className="grid-info-item item-correct-ratio"
								style={isNaN(correctRatio) ? { display: "none" } : {}}
							>
								<b>Tasa de aciertos:</b>
								{correctRatio}%
							</div>

							<div
								className="grid-info-item item-subcategory"
								style={
									!wordData.sub_category ||
									wordData.sub_category === undefined ||
									wordData.sub_category === null
										? { display: "none" }
										: {}
								}
							>
								<b>Subcategoría:</b> &nbsp; {wordData.sub_category}
							</div>
							<div
								className="grid-info-item item-appearsin"
								style={seminaries.length === 0 ? { display: "none" } : {}}
							>
								<b>Aparece en:</b> &nbsp;{" "}
								{seminaries.map((e, index) => {
									return romanize(e) + (seminaries.length - 1 > index ? ", " : "");
								})}
							</div>
							{firstStudied && (
								<div
									className="grid-info-item item-studied-last"
									style={
										wordData.firstStudied === undefined || wordData.firstStudied === null
											? { display: "none" }
											: {}
									}
								>
									<b>Estudiada por primera vez:</b> &nbsp; {firstStudied[1]}/{firstStudied[0]}/
									{firstStudied[2]}
								</div>
							)}

							<div
								className="grid-info-item item-domain"
								style={
									!(wordData.dominated !== undefined && wordData.dominated !== null)
										? { display: "none" }
										: {}
								}
							>
								<b>Nivel de dominio</b>
								<div className="domination-balls-container">
									{wordData.times_answered === 0 && wordData.dominated === 0 ? (
										<>
											<div
												className={
													"progress-ball one " + (wordData.dominated === -3 ? "active" : "")
												}
												style={{ backgroundColor: "rgb(71 71 71)" }}
											></div>
											<div
												className={
													"progress-ball two " + (wordData.dominated === -2 ? "active" : "")
												}
												style={{ backgroundColor: "rgb(110 110 110)" }}
											></div>
											<div
												className={
													"progress-ball three " + (wordData.dominated === -1 ? "active" : "")
												}
												style={{ backgroundColor: "rgb(150 150 150)" }}
											></div>
											<Tooltip content="Aún no has estudiado este término." direction="bottom">
												<div
													className={
														"progress-ball four " + (wordData.dominated === 0 ? "active" : "")
													}
													style={{
														backgroundColor: "rgb(190 190 190)",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														color: "white",
														fontFamily: "sans-serif",
														fontSize: "0.9rem",
													}}
												>
													?
												</div>
											</Tooltip>
											<div
												className={
													"progress-ball five " + (wordData.dominated === 1 ? "active" : "")
												}
												style={{ backgroundColor: "rgb(150 150 150)" }}
											></div>
											<div
												className={
													"progress-ball six " + (wordData.dominated === 2 ? "active" : "")
												}
												style={{ backgroundColor: "rgb(110 110 110)" }}
											></div>
											<div
												className={
													"progress-ball seven " + (wordData.dominated === 3 ? "active" : "")
												}
												style={{ backgroundColor: "rgb(71 71 71)" }}
											></div>
										</>
									) : (
										<>
											<div
												className={
													"progress-ball one " + (wordData.dominated === -3 ? "active" : "")
												}
											></div>
											<div
												className={
													"progress-ball two " + (wordData.dominated === -2 ? "active" : "")
												}
											></div>
											<div
												className={
													"progress-ball three " + (wordData.dominated === -1 ? "active" : "")
												}
											></div>
											<div
												className={
													"progress-ball four " + (wordData.dominated === 0 ? "active" : "")
												}
											></div>
											<div
												className={
													"progress-ball five " + (wordData.dominated === 1 ? "active" : "")
												}
											></div>
											<div
												className={
													"progress-ball six " + (wordData.dominated === 2 ? "active" : "")
												}
											></div>
											<div
												className={
													"progress-ball seven " + (wordData.dominated === 3 ? "active" : "")
												}
											></div>
										</>
									)}
								</div>
							</div>

							{lastStudied && (
								<div
									className="grid-info-item item-studied-first"
									style={
										wordData.lastStudied === undefined || wordData.lastStudied === null
											? { display: "none" }
											: {}
									}
								>
									<b>Estudiada por última vez:</b> &nbsp; {lastStudied[1]}/{lastStudied[0]}/
									{lastStudied[2]}
								</div>
							)}
							<div
								className="grid-info-item item-studied-first"
								style={
									wordData.times_answered === undefined || wordData.times_answered === null
										? { display: "none" }
										: {}
								}
							>
								<b>Total de veces estudiado:</b> &nbsp; {wordData.times_answered}
							</div>
							{/* <div className="grid-info-item empty"></div> */}
						</div>
						<WarningTypeSelector
							setWarningType={setWarningType}
							word={props.word}
							warningType={warningType}
						/>
						<div className="warnings-parent-container">
							{props.word?.[warningType] !== undefined && (
								<>
									{props.word[warningType] !== "undefined" &&
										props.word[warningType].length > 0 &&
										props.word[warningType] !== '<p class="pre"><br></p>' &&
										props.word[warningType] !==
											'<p class="pre"><br></p><p class="pre"><br></p>' && (
											<div
												className={"warning-container "}
												style={{ borderColor: returnWarningBorderColor(warningType) }}
											>
												<h3 style={{ color: returnWarningBorderColor(warningType) }}>
													{returnWarningTypeTitle(warningType)}
												</h3>
												<div className="ql-editor">
													{parse(props.word[warningType], { trim: false })}
												</div>
												{returnWarningTypeImage(warningType)}
											</div>
										)}
								</>
							)}
						</div>
					</>
				)}
			</Modal.Body>
			<Modal.Footer className="justify-content-center">
				<button className="action-btn linear-bg" onClick={props.onHide}>
					Cerrar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
