import firebase from 'firebase/compat/app';
import { addDoc, collection } from 'firebase/firestore';
import { db } from 'initFirebase';
import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import './styles.scss';

interface CustomFormProps {
  postFunction: (e) => void;
  initialStateValues: any;
  validated: any;
  setValidated: any;
}

function CustomForm(props: CustomFormProps) {
  const [values, setValues] = useState(props.initialStateValues);
  const [show, setShow] = useState(false);
  const [prefered, setPrefered] = useState('Correo');
  const [addSendGrid, setAddSendGrid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
      props.setValidated(true);
      setLoading(false);
    } else {

      if (addSendGrid) {
        const tryAddSendgrid = await firebase.functions().httpsCallable("addSendGrid");
        await tryAddSendgrid({ email: values.email }).then((res) => {
          props.postFunction({ ...values, prefered });
          props.setValidated(false);
          setShow(true);
          setValues(props.initialStateValues);
          setPrefered('');
        })
      } else {
        props.postFunction({ ...values, prefered });
        props.setValidated(false);
        setShow(true);
        setValues(props.initialStateValues);
        setPrefered('');
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleClose = () => {
    setShow(false);
    setLoading(false);
  }


  return (
    <div className="custom-form">
      <Form noValidate validated={props.validated} onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>
                <b>Nombre completo</b>
              </Form.Label>
              <Form.Control
                onChange={handleInputChange}
                required
                type="text"
                name="name"
                value={values.name}
                placeholder="Luke Skywalker"
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingresa un nombre.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                <b>Correo electrónico</b>
              </Form.Label>
              <Form.Control
                required
                onChange={handleInputChange}
                type="email"
                name="email"
                value={values.email}
                placeholder="example@example.com"
              />
              <Form.Control.Feedback type="invalid">
                Por favor, ingresa un correo válido.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="formBasicPhone">
              <Form.Label>
                <b>Número de teléfono</b>
              </Form.Label>
              <Form.Control
                onChange={handleInputChange}
                type="text"
                name="phone"
                required
                minLength={10}
                value={values.phone}
                placeholder="55 1234 5678"
              />
              <Form.Control.Feedback type="invalid">
                Por favor, ingresa un número de 10 dígitos.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="formBasicSelect">
              <Form.Label>
                <b>Medio de contacto preferido</b>
              </Form.Label>
              <Form.Control
                required
                as="select"
                onChange={(e) => {
                  setPrefered(e.target.value);
                }}
                
              >
                <option value="Correo">Correo electrónico</option>
                <option value="WhatsApp">WhatsApp</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-0'>
          <Col md={12}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>
                <b>Comentarios</b>
              </Form.Label>
              <Form.Control
                onChange={handleInputChange}
                as="textarea"
                required
                rows={3}
                name="description"
                value={values.description}
                placeholder="¡Me encantan sus cursos!"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-0'>
          <Col className='text-center' md={12}>
            <input className='mr-2' id="sendgrid-checkbox" type="checkbox" checked={addSendGrid} onChange={(e) => setAddSendGrid(e.target.checked)} />
            <label htmlFor="sendgrid-checkbox">Deseo ser notificado en futuras convocatorias</label>
          </Col>
        </Row>
        <div className="button__container text-center">
          {!loading ? (
            <button className="tertiary">Contactar</button>
          ) : (
            <div className="spinner-border text-danger" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </Form>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Datos enviados</Modal.Title>
        </Modal.Header>
        <Modal.Body>Hemos recibido tus datos. </Modal.Body>
        <Modal.Footer>
          <button className="tertiary" onClick={handleClose}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CustomForm;
