import PaymentContainer from "@containers/PaymentContainer/PaymentContainer";

function InscriptionScreen() {
	return (
		<div style={{ overflow: "hidden", position: "relative" }}>
			<PaymentContainer />
		</div>
	);
}

export default InscriptionScreen;
