import { useEffect, useState } from "react";
import { Modal, Row, Form, Col } from "react-bootstrap";
import InputMask from "react-input-mask";
import { collection, doc, getDocs, orderBy, query, updateDoc, where, getDoc, setDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { useHistory } from "react-router-dom";
import "./stylesModal.css";
import "../../CollaboratorContainer.scss";
import "../../CollaboratorContainer.css";
import Select from "react-select";
import { user } from "firebase-functions/v1/auth";
import { useAuth } from "@hooks/useAuth";
import toast, { Toaster } from "react-hot-toast";
export function AddCollaboratorModal(props) {
	const { showmodal, Hide, AddCollaraborator } = props;
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [users, setUsers]: any = useState([]);
	const [userSelected, setUserSelected] = useState("");
	//const user = useAuth().user;

	const getUsers = async () => {
		let studentsArray: any = [];
		const q = query(collection(db, "users"));
		const querySnapshot = await getDocs(q);
		let i = 0;
		querySnapshot.forEach((doc) => {
			const data2 = {
				label: doc.data().name + " - " + doc.data().email,
				value: doc.id,
			};
			studentsArray.push(data2);
			i++;
			if (i === querySnapshot.size) {
				setUsers(studentsArray);
			}
		});
	};

	const handleSelection = (e) => {
		setUserSelected(e.value);
	};

	const handleGuardarClick = async (userSelected, setUserSelected) => {
		try {
			AddCollaraborator(userSelected, setUserSelected);
			props.Hide()
		} catch {
			// console.log("No se pudo agregar un colaborador nuevo");
		}
	};

	useEffect(() => {
		getUsers();
	}, []);

	return (
		<Modal
			show={showmodal}
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			onHide={Hide}
			className="custom-modal"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Nuevo colaborador</Modal.Title>
			</Modal.Header>
			<Modal.Body className="custom-modal">
				<p>Selecciona un usuario para ser tu Colaborador.</p>
				<Select onChange={(e) => handleSelection(e)} options={users} />
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-secondary secondary-action" onClick={Hide}>
					Cerrar
				</button>
				<button
					className="btn btn-primary primary-action"
					onClick={() => {
						handleGuardarClick(userSelected, setUserSelected);
					}}
				>
					Guardar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
