import { Modal } from "react-bootstrap";
import "./RequestInvoiceModal.scss";
import { useEffect, useState } from "react";
import { InvoiceApiData, InvoiceData, generateInvoice, uploadInvoiceFireBase } from "@services/InvoiceServices";
import { InvoiceSentModal } from "./InvoiceSentModal";
import useCollaboratorLogs, { collaborator, user } from "@hooks/collaborators/useCollaboratorLogs";
// Many lines of code in this file were taken from the GenerateInvoideModal file, which is why may class names are called "gi"
export default function RequestInvoiceModal(props: any) {
	const [loader, setLoader] = useState<boolean>(false);
	const [invoiceSentModal, setInvoiceSentModal] = useState<boolean>(false);
	const { invoiceLog } = useCollaboratorLogs();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoader(true);
		const form = e.target;
		let data: InvoiceData = {
			createdAt: new Date(),
			requestDate: new Date(),
			fiscalRegime: form.fiscalRegime.value,
			street: form.street.value,
			city: form.city.value,
			state: form.state.value,
			name: form.name.value,
			email: form.email.value,
			paymentForm: form.paymentMethod.value,
			use: form.invoiceUse.value,
			rfc: form.rfc.value.toUpperCase(),
			zipCode: form.zipCode.value,
			corporateName: form.corporateName.value.toUpperCase(),
			amount: parseFloat(form.invoiceAmount.value),
			status: "Pending",
			netPrice: parseFloat((form.invoiceAmount.value / 1.16).toFixed(2)),
		};
		uploadInvoiceFireBase(data).then(async (res) => {
			if (res !== null) {
				alert("Solicitud creada con éxito.");
				if (props.collaborator) {
					let tempCollaborator: collaborator = {
						name: props.collaborator.name,
						id: props.collaborator.id,
						role: props.collaborator.collaborator_rol ?? "",
					};
					let tempUser: user = { name: form.name.value, id: "" };
					await invoiceLog("request", tempCollaborator, tempUser);
				}
				props.getInvoices();
				props.setCategorie(2);
				props.onHide();
			}
		});
		setLoader(false);
	};

	// useEffect(() => {
	// 	console.log(props);
	// }, [props]);

	return (
		<Modal show={props.show} onHide={props.onHide} dialogClassName="generate-invoice-modal" contentClassName="gi-modal-content">
			<InvoiceSentModal
				show={invoiceSentModal}
				onHide={() => {
					setInvoiceSentModal(false);
				}}
			/>
			<Modal.Header closeButton className="gi-modal-header">
				<Modal.Title className="gi-modal-title">Generar solicitud</Modal.Title>
			</Modal.Header>
			<Modal.Body className="gi-modal-body">
				<form onSubmit={handleSubmit}>
					<div className="gi-form-grid">
						<div className="gi-form-group left">
							<label htmlFor="input-1">Nombre</label>
							<input
								type="text"
								id="input-1"
								name="name"
								required
								defaultValue={props.userInvoiceInfo?.name ?? ""}
								maxLength={50}
							/>
						</div>
						<div className="gi-form-group right">
							<label htmlFor="input-2">Estado</label>
							<input type="text" id="input-2" name="state" required maxLength={30} />
						</div>
						<div className="gi-form-group left">
							<label htmlFor="input-3">Correo</label>
							<input
								type="email"
								id="input-3"
								name="email"
								required
								defaultValue={props.userInvoiceInfo?.email ?? ""}
								maxLength={50}
							/>
						</div>
						<div className="gi-form-group right">
							<label htmlFor="input-4">Calle</label>
							<input type="text" id="input-4" name="street" required maxLength={50} />
						</div>
						<div className="gi-form-group left">
							<label htmlFor="input-5">RFC</label>
							<input type="text" id="input-5" name="rfc" required maxLength={16} />
						</div>
						<div className="gi-form-group right">
							<label htmlFor="input-6">Código postal</label>
							<input type="text" id="input-6" name="zipCode" required maxLength={11} />
						</div>
						<div className="gi-form-group left">
							<label htmlFor="input-7-2">Régimen fiscal</label>
							<select id="input-7-2" name="fiscalRegime" defaultValue={""} required >
								<option value="" disabled>
									Selecciona una opción
								</option>
								<option value="601">(601) General de Ley Personas Morales</option>
								<option value="603">(603) Personas Morales con Fines no Lucrativos</option>
								<option value="605">(605) Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
								<option value="606">(606) Arrendamiento</option>
								<option value="607">(607) Régimen de Enajenación o Adquisición de Bienes</option>
								<option value="608">(608) Demás ingresos</option>
								<option value="609">(609) Consolidación</option>
								<option value="610">(610) Residentes en el Extranjero sin Establecimiento Permanente en México</option>
								<option value="611">(611) Ingresos por Dividendos (socios y accionistas)</option>
								<option value="612">(612) Personas Físicas con Actividades Empresariales y Profesionales</option>
								<option value="614">(614) Ingresos por intereses</option>
								<option value="615">(615) Régimen de los ingresos por obtención de premios</option>
								<option value="616">(616) Sin obligaciones fiscales</option>
								<option value="620">(620) Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
								<option value="621">(621) Incorporación Fiscal</option>
								<option value="622">(622) Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
								<option value="623">(623) Opcional para Grupos de Sociedades</option>
								<option value="624">(624) Coordinados</option>
								<option value="625">
									(625) Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas
								</option>
								<option value="626">(626) Régimen Simplificado de Confianza</option>
								<option value="628">(628) Hidrocarburos</option>

								<option value="629">(629) De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
								<option value="630">(630) Enajenación de acciones en bolsa de valores</option>
							</select>
						</div>
						<div className="gi-form-group right">
							<label htmlFor="input-8">Ciudad</label>
							<input type="text" id="input-8" name="city" required maxLength={50} />
						</div>
						<div className="gi-form-group corporate-name">
							<label htmlFor="input-7-1">
								Razón social
								<br />
								<span className="note">
									*Nombre Fiscal o Razón Social del cliente. Sin el régimen societario (ej.: S.A. de C.V.).
								</span>
							</label>
							<input type="text" id="input-7-1" name="corporateName" required maxLength={70} />
						</div>
					</div>
					<div style={{ width: "100%", height: "1px", backgroundColor: "rgba(112, 112, 112, 0.5)", margin: "27px auto" }}></div>
					<div className="gi-form-grid">
						<div className="gi-form-group left">
							<label htmlFor="payment-method">Método de pago:</label>
							<select id="payment-method" name="paymentMethod" defaultValue={""} required>
								<option value="" disabled>
									Selecciona una opción
								</option>
								<option value="01">Efectivo</option>
								<option value="03">Oxxo Pay</option>
								<option value="03">SPEI</option>
								<option value="03">Transferencia</option>
								<option value="03">Depósito Bancario</option>
								<option value="04">Tarjeta</option>
								<option value="04">Paypal</option>
								<option value="03">Otro</option>
							</select>
						</div>
						<div className="gi-form-group right">
							<label htmlFor="invoice-use">Uso de factura:</label>
							<select id="invoice-use" name="invoiceUse" defaultValue={""} required>
								<option value="" disabled>
									Selecciona una opción
								</option>
								<option value="G03">Gastos en general</option>
								<option value="D10">Pagos por servicios educativos (colegiaturas)</option>
							</select>
						</div>
						<div className="gi-form-group left">
							<label htmlFor="invoice-amount">Monto facturado:</label>
							<input
								type="number"
								id="invoice-amount"
								name="invoiceAmount"
								required
								defaultValue={props.invoiceAmount}
								disabled={props.invoiceAmount}
								max={300000}
								maxLength={8}
								style={
									props.invoiceAmount ? { all: "unset", border: "solid #ca5050", paddingLeft: "10px", borderRadius: "10px" } : {}
								}
							/>
						</div>
					</div>
					<div className="gi-modal-footer">
						<button className="discard-btn" onClick={props.onHide} type="button">
							Descartar
						</button>
						<button className={"generate-btn " + (loader === true ? "disabled-btn" : "")} type="submit" disabled={loader}>
							Generar
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
}
