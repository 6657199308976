import { useEffect, useState } from "react";
import SeminaryCard from "./components/SeminaryCard.tsx/SeminaryCard";
import GenerationsCard from "./components/Generations/GenerationsCard";
import LanguagesCard from "./components/Languages/LanguagesCard";
import ReportsCard from "./components/Reports/ReportsCard";
import { getSeminaries, getLanguages, getWordReports } from "@api/Vocabulary";
import { addDoc, collection, doc, getDoc, getDocs, limit, orderBy, query, snapshotEqual, where } from "firebase/firestore";
import { db } from "initFirebase";
import "./VocabularyStyles.scss";
import { useHistory, useLocation } from "react-router-dom";
import { compareGenerations } from "functions/Generations/FunctionsGenerations";
import { EditWordModal } from "@components/Vocabulary/EditWordModal/EditWordModal";

function VocabularyAdmin({ user }) {
	const location: any = useLocation();
	const [view, setView] = useState<number>(location.state !== undefined ? location?.state?.view : 1);
	const [options, setOption] = useState(location.state !== undefined ? location?.state?.options : "seminary");
	const [seminaries, setSeminaries] = useState<Array<any>>(new Array());
	const [languages, setLanguages] = useState<Array<any>>(new Array());
	const [generations, setGenerations] = useState<Array<any>>(new Array());
	const history = useHistory();
	const [errorsRegister, setErrorsRegister] = useState({ timesGood: 0, times: 0, users: [], usersGood: [] });
	const [reports, setReports] = useState<Array<any>>(new Array());
	const [uncheckedReports, setUncheckedReports] = useState<any>("");
	const [scrollElement, setScrollElement]: any = useState(null);

	useEffect(() => {
		if (location.state === null) {
			console.log("render entre")
			setView(1);
			setOption("seminary")
		}
	}, []);

	const getAllWordReports = async () => {
		setReports([]);
		getWordReports().then((res) => {
			if (res.reports !== undefined) {
				setReports(res.reports);
			}
		});
	};

	const countUncheckedReports = () => {
		let count = 0;
		reports.forEach((e) => {
			if (e.checked === 0) {
				count++;
			}
		});
		setUncheckedReports(count);
	};

	const getAllgenerations = async () => {
		let generations_temp: any = [];
		const getGeneration = query(collection(db, "generations"));
		const querySnapshot = await getDocs(getGeneration);
		await Promise.all(
			querySnapshot.docs.map(async (generation) => {
				const q = query(collection(db, "users"), where("generationId", "==", generation.id));
				const snapStudents = await getDocs(q);
				generations_temp.push({ ...generation.data(), numStudents: snapStudents.size });
			})
		);
		setGenerations(generations_temp.sort(compareGenerations));
	};

	const getAllLanguages = async () => {
		await getLanguages()
			.then((res) => {
				if (res.totalWordsLanguages !== undefined) {
					setLanguages(res.totalWordsLanguages);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getErrorsRegister = async () => {
		const errorDocRef = doc(db, "errorsRegister", "structuredClone");
		const docSnap = await getDoc(errorDocRef);

		if (docSnap.exists()) {
			setErrorsRegister({
				times: docSnap.data().times,
				timesGood: docSnap.data().timesGood,
				users: docSnap.data().users,
				usersGood: docSnap.data().usersGood,
			});
		}
	};

	const toggleFilter = (num, option) => {
		setView(num);
		setOption(option);
	};

	const handleChange = (e) => {
		setOption(e.target.value);
	};

	const getSeminars = async () => {
		await getSeminaries()
			.then((res) => {
				if (res.seminaries !== undefined) {
					setSeminaries(res.seminaries);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const scrollToNotification = () => {
		let scroll = document.getElementById("report-" + location.state.reportId);
		if (scroll !== null) {
			setScrollElement(scroll);
			scroll.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
			scroll.style.border = "2px solid red";
			setTimeout(() => {
				if (scroll !== null) {
					scroll.style.border = "initial";
				}
			}, 3000);
		}
	};

	useEffect(() => {
		if (reports.length > 0) {
			countUncheckedReports();
		}
	}, [reports]);

	useEffect(() => {
		if (location?.state !== undefined) {
			if (location?.state?.view === 3 && reports.length > 0) {
				scrollToNotification();
			}
		}
	}, [reports]);

	useEffect(() => {
		getErrorsRegister();
		getSeminars();
		getAllLanguages();
		getAllgenerations();
		getAllWordReports();
	}, []);

	return (
		<div className="vocabulary-admin-main-container">
			<div className="title">
				<h1>Vocabulario</h1>
			</div>
			<div className="subtitle">
				<h3>
					<b>Usuarios con errores: </b>
					{errorsRegister.times}
				</h3>
				<h3>
					<b>Usuarios sin errores: </b>
					{errorsRegister.timesGood}
				</h3>
			</div>
			<div className="subtitle">
				<h3>Revisa las listas de vocabularios para las clases y los progresos de los estudiantes</h3>
			</div>
			<div className="options-btn-containers">
				<div className="d-flex w-100 justify-content-between align-items-center flex-wrap" style={{ gap: 10 }}>
					<div className="buttons-container">
						<div
							className={"btn-1 " + (view === 1 ? "active" : "")}
							onClick={() => {
								toggleFilter(1, "seminary");
							}}
						>
							<h3>Editar Vocabulario</h3>
						</div>
						<div
							className={"btn-2 " + (view === 2 ? "active" : "")}
							onClick={() => {
								toggleFilter(2, "generations");
							}}
						>
							<h3>Estadísticas</h3>
						</div>
						<div
							className={"btn-3 " + (view === 3 ? "active" : "")}
							onClick={() => {
								toggleFilter(3, "vocabulary");
							}}
						>
							<h3>Vocabulario ({uncheckedReports})</h3>
						</div>
					</div>
					<button className="action-btn linear-bg ml-auto" onClick={() => history.push("/admin/vocabulario/subcategorias")}>
						Subcategorías
					</button>
				</div>
				{(options == "generations" || options == "languages") && (
					<select value={options} onChange={handleChange}>
						<option value="generations">Por generación</option>
						<option value="languages">Por idioma</option>
					</select>
				)}
			</div>
			<div className="vocabulary-seminary-cards-container">
				{options === "seminary" &&
					seminaries.map((seminario, index) => {
						return <SeminaryCard data={seminario} key={"seminaryCard-" + index} />;
					})}
				{options === "generations" &&
					generations.map((generation, index) => {
						return <GenerationsCard data={generation} key={"generation-" + index} />;
					})}
				{options === "languages" &&
					languages.map((language, index) => {
						return <LanguagesCard data={language} key={"languagesCard-" + index} />;
					})}
				{options === "vocabulary" && reports.length > 0 && (
					<ReportsCard data={reports} setUncheckedReports={setUncheckedReports}>
						<EditWordModal getAllWordReports={getAllWordReports} />
					</ReportsCard>
				)}
			</div>
		</div>
	);
}

export default VocabularyAdmin;
