import React, { useEffect, useState } from "react";
import { db } from "initFirebase";
import Container from "react-bootstrap/esm/Container";
import ModulesContainer from "./ModulesContainer/ModulesContainer";
import "./styles.scss";
import { Dynamics } from "./Dynamics/Dynamics";
import { ModulesSection } from "assets/Imports";
import { Modes } from "@screens/Home/components/Modes/Modes";

function ProgramContainer() {
	const [programInfo, setProgramInfo] = useState<any>({});

	const getProgramInfo = async () => {
		db.collection("dynamic_copies")
			.doc("AseQzYW4e5SuvVSU4E3r")
			.collection("primera")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setProgramInfo({
						...doc.data(),
						id: doc.id,
					});
				});
			});
	};

	useEffect(() => {
		getProgramInfo();
	}, []);

	return (
		<>
			<div className="header__program">
				<button className="button2 text-uppercase d-none d-sm-block">
					<span className="text">
						{programInfo.titulo ? programInfo.titulo : "PROGRAMA DE ALTO RENDIMIENTO EN 8 IDIOMAS SIMULTÁNEOS"}
					</span>
				</button>
				{/* <h2 className="text-center text-uppercase">
          <span className="text">{programInfo.titulo ? programInfo.titulo : "PROGRAMA DE ALTO RENDIMIENTO EN 8 IDIOMAS SIMULTÁNEOS"}</span>
        </h2> */}
				<Container>
					<h1 className="d-block d-sm-none">
						{programInfo.titulo ? programInfo.titulo : "PROGRAMA DE ALTO RENDIMIENTO EN 8 IDIOMAS SIMULTÁNEOS"}
					</h1>

					<div className="description">
						<p className="text-right">
							<li>{programInfo.right_copie}</li>
						</p>
						<p>{programInfo.paragraph1}</p>
						<p>{programInfo.paragraph2}</p>
					</div>
				</Container>
			</div>
			<img src={ModulesSection} alt="Wave" className="wave" />
			<div className="section__program mb-4 pt-2">
				<Modes />
			</div>
			<div className="section__program mt-4">
				<ModulesContainer />
			</div>
		</>
	);
}

export default ProgramContainer;
