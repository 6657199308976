import "./WordRowCardStyles.scss";
import parse from "html-react-parser";
import {
	getCorrectWord,
	getWord,
} from "@containers/VocabularySeminaryContainer/components/Test/functions/TestFunctions";
import { useEffect, useState } from "react";
import { languagesCodes } from "functions/Pronunciation/Pronunciation";
import axios from "axios";
import { EditWordModal } from "@components/Vocabulary/EditWordModal/EditWordModal";
import { AuthProvider, useAuth } from "@hooks/useAuth";
import { VolumeIcon, CreateIcon } from "assets/Imports";
import { WordInfoModal } from "../WordInfoModal/WordInfoModal";
import useAudios from "@hooks/useAudios";

export function WordRowCard(props) {
	const [audio, setAudio] = useState<string>("");
	const [editWordModal, setEditWordModal] = useState({ show: false, word: {} });
	const [wordInfoModal, setWordInfoModal] = useState({ show: false, word: {}, index: 0 });
	const { getGKey, playWordAudio } = useAudios();
	const { user } = useAuth();
	const listenWord = async (word) => {
		if (audio === "") {
			try {
				const gKey = await getGKey();
				axios
					.post(
						`https://texttospeech.googleapis.com/v1beta1/text:synthesize`,
						{
							audioConfig: {
								audioEncoding: "LINEAR16",
								pitch: 0,
								speakingRate: 1,
							},
							input: {
								text: `${word}`,
							},
							voice: {
								languageCode: `${languagesCodes[props.language].langCode}`,
								name: `${languagesCodes[props.language].name}`,
							},
						},
						{
							headers: {
								Authorization: `Bearer ${gKey}`,
								"X-Goog-User-Project": "academia-de-lenguas-f6f35",
								"Content-Type": "application/json",
							},
						}
					)
					.then((res) => {
						setAudio(res.data.audioContent);
						playWordAudio(res.data.audioContent);
					})
					.catch((error) => {
						console.error("error text to speech", error);
					});
			} catch (error) {
				console.error(error);
			}
		} else {
			playWordAudio(audio);
		}
	};

	useEffect(() => {
		if (props.setMiddleScreenEditWordModal !== undefined) {
			if (editWordModal.show === true) {
				props.setMiddleScreenEditWordModal(true);
			} else if (editWordModal.show === false) {
				props.setMiddleScreenEditWordModal(false);
			}
		}
	}, [editWordModal]);

	useEffect(() => {
		setAudio("");
	}, [props.language]);
	return (
		<div className="word-container-word-row-card">
			{audio !== "" && (
				<audio
					style={{ position: "absolute", zIndex: -100, opacity: 0 }}
					id={"listenWord-" + props.index}
					controls
				>
					<source src={"data:audio/mp3;base64," + audio} />
				</audio>
			)}
			<div className="text-container">
				<div className="word-to-study-container">
					{wordInfoModal.show && (
						<WordInfoModal
							show={wordInfoModal.show}
							onHide={() => setWordInfoModal({ ...wordInfoModal, show: false, index: 0 })}
							word={wordInfoModal.word}
							index={wordInfoModal.index}
							setCountRefresh={props.setCountRefresh}
							countRefresh={props.countRefresh}
						/>
					)}
					<p
						onClick={() => setWordInfoModal({ show: true, word: props.word, index: props.index })}
						style={
							(props.language === "chinese" && props.answerType === "normal") ||
							(props.language === "japanese" &&
								props.answerType === "normal" &&
								props.word.optional_word !== null &&
								props.word.optional_word !== "")
								? { cursor: "pointer" }
								: { color: "#ca5050", cursor: "pointer" }
						}
					>
						{((props.language === "chinese" && props.answerType === "normal") ||
							(props.language === "japanese" &&
								props.answerType === "normal" &&
								props.word.optional_word !== null &&
								props.word.optional_word !== "")) && <span>{props.word.optional_word}&nbsp;</span>}
						<span
							style={{
								color:
									props.word.optional_word !== null &&
									props.word.optional_word !== "" &&
									props.answerType === "normal"
										? "#00000080"
										: "",
							}}
						>
							{(props.language === "japanese" || props.language === "chinese") &&
								props.word.optional_word !== null &&
								props.word.optional_word !== "" &&
								props.answerType === "normal" &&
								"("}
							{getWord(props.answerType, props.word)}
							{(props.language === "japanese" || props.language === "chinese") &&
								props.word.optional_word !== null &&
								props.word.optional_word !== "" &&
								props.answerType === "normal" && <span style={{ color: "#00000080" }}></span> &&
								")"}
						</span>
					</p>
				</div>
				<div className="answer-word-container">
					<p>
						{((props.language === "chinese" && props.answerType === "selected-lang") ||
							(props.language === "japanese" &&
								props.answerType === "selected-lang" &&
								props.word.optional_word !== null &&
								props.word.optional_word !== "")) && (
							<>
								<span style={{ color: "#000000" }}>{props.word.optional_word}&nbsp;</span>
								{props.language === "chinese" && (
									<span style={{ color: "#00000080" }}>
										<>({getCorrectWord(props.answerType, props.word)})</>
									</span>
								)}
							</>
						)}
						{props.language === "chinese" && props.answerType === "kanji" && (
							<>
								<span style={{ color: "#000000" }}>
									{getCorrectWord(props.answerType, props.word)}&nbsp;
								</span>
								<span style={{ color: "#00000080" }}>({props.word.native_word})</span>
							</>
						)}
						<span style={{ color: "#00000080" }}>
							{props.language === "japanese" &&
								props.word.optional_word !== null &&
								props.word.optional_word !== "" &&
								props.answerType === "selected-lang" &&
								"("}
							{(props.language !== "chinese" ||
								(props.language === "chinese" && props.answerType === "normal")) && (
								<div>{parse(getCorrectWord(props.answerType, props.word))}</div>
							)}
							{props.language === "japanese" &&
								props.word.optional_word !== null &&
								props.word.optional_word !== "" &&
								props.answerType === "selected-lang" && (
									<span style={{ color: "#00000080" }}></span>
								) &&
								")"}
						</span>
					</p>
				</div>
			</div>
			<div className="listen-word-container">
				{user.userType === 2 && props.disableEdit === undefined && (
					<div className="wrench">
						<img
							className="wrench"
							src={CreateIcon}
							alt="icono de llave inglesa"
							onClick={() => setEditWordModal({ show: true, word: props.word })}
						/>
					</div>
				)}
				{user.userType === 2 && (
					<EditWordModal
						show={editWordModal.show}
						onHide={() => setEditWordModal({ show: false, word: {} })}
						word={editWordModal.word}
						language={props.word.language}
						setCountRefresh={props.setCountRefresh}
						countRefresh={props.countRefresh}
					/>
				)}

				<img
					src={VolumeIcon}
					style={
						props.language === "none"
							? {
									filter:
										"invert(56%) sepia(0%) saturate(0%) hue-rotate(164deg) brightness(90%) contrast(91%)",
							  }
							: {}
					}
					alt="icono de volumen"
					onClick={
						props.language !== "none"
							? () => listenWord(getWord("normal", props.word, true))
							: () => {}
					}
				/>
			</div>
		</div>
	);
}
