import { Link } from "react-router-dom";
import "./SeminaryCardStyles.scss";
import { wordsSeminaryBookmarked, wordsSeminary } from "@api/Vocabulary";
import { useEffect, useState } from "react";

function SeminaryCard({ data }) {
	const [bookmarks, setBookmarks] = useState<number>(0);

	const getTotalBookmarked = async () => {
		await wordsSeminaryBookmarked(data.seminario).then((res) => {
			setBookmarks(res);
		});
	};

	useEffect(() => {
		getTotalBookmarked();
	}, [data]);

	return (
		<div className="vocabulary-seminary-card">
			<div className="title">
				<h2>Seminario {data.seminario}</h2>
				<h3>{data.total_palabras} términos</h3>
			</div>
			<div className="bookmarks" style={!(bookmarks > 0) ? { display: "none" } : {}}>
				{bookmarks}
			</div>
			<div className="action">
				<Link to={`/admin/vocabulario/seminario/${data.seminario}`}>
					<button>editar</button>
				</Link>
			</div>
		</div>
	);
}

export default SeminaryCard;
