import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { db } from "initFirebase";
import { addDoc, collection, getDocs, query, updateDoc, doc, getDoc } from "firebase/firestore";
import "./FormStyle.scss";
import { whichIsWrongForm2 } from "functions/Forms/FormsFunctions";
import { FormExperienceComponent } from "@components/FormExperience/FormExperienceComponent";

function FormContainer() {
	const windowUrl = window.location.search;
	const params = new URLSearchParams(windowUrl);
	const genId: any = params.get("id") !== null ? params.get("id") : "No existe";
	const [validated, setValidated] = useState(false);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);

	const [MultipleQ10, setMultipleQ10] = useState("0");
	const [genName, setGenName] = useState("No existe");

	useEffect(() => {
		if (genId !== "No existe") {
			getGen();
		}
	}, []);

	const getGen = async () => {
		const genRef = doc(db, "generations", genId);
		const docSnap = await getDoc(genRef);

		if (docSnap.exists()) {
			setGenName(docSnap.data().name);
		} else {
			// doc.data() will be undefined in this case
			console.log("No such document!");
		}
	};

	//query para poner todos los usuarios con el formulario activo
	const changeUsers = async () => {
		const q = query(collection(db, "users"));
		const querySnapshot = await getDocs(q);

		querySnapshot.forEach(async (snap) => {
			let userRef = doc(db, "users", snap.id);
			await updateDoc(userRef, {
				formAnswered: true,
			});
		});
	};
	// useEffect(() => {
	//     changeUsers();
	// }, [])

	const handleSubmit = async (event) => {
		setLoading(true);
		const form = event.currentTarget;
		let userId = "No existe";
		let generationId = genId;
		let generationName = genName;
		let matricula = "No existe";

		if (form.checkValidity() === false) {
			event.preventDefault();
			setLoading(false);
			event.stopPropagation();
			whichIsWrongForm2(form);
		} else {
			event.preventDefault();
			await db
				.collection("users")
				.where("email", "==", form.email.value)
				.get()
				.then(async (snap) => {
					if (snap.docs.length > 0) {
						userId = snap.docs[0].id;
						generationId = snap.docs[0].data().generationId;
						matricula = snap.docs[0].data().matricula;
						var docRef = db.collection("generations").doc(snap.docs[0].data().generationId);
						await docRef
							.get()
							.then((doc: any) => {
								if (doc.exists) {
									generationName = doc.data().name;
								} else {
									// doc.data() will be undefined in this case
									console.log("No such document!");
								}
							})
							.catch((error) => {
								console.log("Error getting document:", error);
							});
					}
				})
				.then(async () => {
					const docRef = await addDoc(collection(db, "forms2"), {
						//Bloque 1
						email: form.email.value,

						//Bloque 2
						multipleQ1: form.multipleQ1.value,
						multipleQ1_Other: form.multipleQ1_Other !== undefined ? form.multipleQ1_Other.value : "-",
						multipleQ2: form.multipleQ2.value,
						multipleQ3: form.multipleQ3.value,
						multipleQ4: form.multipleQ4.value,
						timeStudyDuringWeek: `${form.q171.value} horas cada ${form.q172.value} ${form.q173.value}`,

						//Bloque 3
						multipleQ5: form.multipleQ5.value,
						multipleQ6: form.multipleQ6.value,
						multipleQ7: form.multipleQ7.value,
						multipleQ8: form.multipleQ8.value,
						multipleQ9: form.multipleQ9.value,
						multipleQ15: form.multipleQ15.value,

						//Bloque 4
						multipleQ10: form.multipleQ10.value,
						multipleQ10_Other: form.multipleQ10_Other !== undefined ? form.multipleQ10_Other.value : "-",
						q11: form.q11.value,
						q12: form.q12.value,
						q13: form.q13.value,
						q14: form.q14.value,
						mostImportantLearning: form.q19.value,
						userId: userId,
						generationId: generationId,
						generationName: generationName,
						matricula: matricula,
						createdAt: new Date().toLocaleString(),
					}).then(() => {
						if (userId !== "No existe") {
							console.log("con userid");
							var docRef = db
								.collection("users")
								.doc(userId)
								.update({
									formAnswered2: true,
								})
								.then(() => {
									alert("Formulario enviado");
									history.push("/");
								});
						} else {
							console.log("sinuserId");
							alert("Formulario enviado");
							history.push("/");
						}
					});
				});
		}

		setValidated(true);
	};

	return (
		<div className="form-main-container">
			<div className="modal-form-header-container">
				<h2 className="form-title" style={{ maxWidth: 520 }}>
					Encuesta sobre la experiencia del estudiante
				</h2>
				<div className="steps-circles-container">
					<div onClick={() => setStep(1)} className={"circle " + (step >= 1 ? "active" : "")}>
						<h3>1</h3>
					</div>
					<div onClick={() => setStep(2)} className={"circle " + (step >= 2 ? "active" : "")}>
						<h3>2</h3>
					</div>
					<div onClick={() => setStep(3)} className={"circle " + (step >= 3 ? "active" : "")}>
						<h3>3</h3>
					</div>
				</div>
			</div>
			<p style={{ margin: 0, textAlign: "end" }}>
				<a className="obligatory">*</a> Campos obligatorios.
			</p>
			<p className="font-weight-bold text-center">Módulo II</p>
			<FormExperienceComponent
				validated={validated}
				setStep={setStep}
				step={step}
				MultipleQ10={MultipleQ10}
				setMultipleQ10={setMultipleQ10}
				handleSubmit={handleSubmit}
				user={null}
			/>
		</div>
	);
}

export default FormContainer;
