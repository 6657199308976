import ProgressBarComponent from "@components/ProgressBar/ProgressBarComponent";
import { collection, getDocs, where, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import NextPaymentCard from "./NextPaymentCard/NextPaymentCard";
import PaymentHistoryCard from "./PaymentHistoryCard.tsx/PaymentHistoryCard";
import "./styles.scss";
import { db } from "initFirebase";
import { getAuth } from "firebase/auth";
import { Button, Modal } from "react-bootstrap";
import firebase from "firebase/compat/app";
import { useHistory } from "react-router";
import FormModal from "@components/ModalFormUser/FormModal";
import { useSeminariesService } from "../../services/SeminariesService";
import { useLocation } from "react-router-dom";
import GenerateInvoiceModal from "@containers/Admin/InvoiceContainer/GenerateInvoiceModal";
import useInvoices from "@containers/Admin/InvoiceContainer/useInvoices";
import { useAuth } from "@hooks/useAuth";

function PaymentHistoryContent({ user }) {
	let name;
	const [payments, setPayments]: any = useState([]);
	const userId = useAuth().user.id;
	const { userGeneration } = useAuth();
	const [totalPay, setTotalPay] = useState(0);
	const [lastPay, setLastPay] = useState<any>([]);
	const [nextPay, setNextPay] = useState(false);
	const [nextDate, setNextDate] = useState("");
	const [show, setShow] = useState(false);
	const [payQuantity, setPayQuantity] = useState(0);
	const [modalStep, setModalStep] = useState(1);
	const [paymentMethod, setPaymentMethod] = useState(0);
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	const [formModalShow, setFormModalShow] = useState(false);
	const { paidSeminaries, nextSeminaries }: any = useSeminariesService();
	const location = useLocation<any>();
	const { acceptInvoice, invoiceSearch, downloadInvoice, cancelInvoice, cancelationValidation } =
		useInvoices();
	const [generateInvoiceModal, setGenerateInvoiceModal] = useState<boolean>();
	const [userInvoiceInfo, setUserInvoiceInfo] = useState<any>();
	const [invoiceAmount, setInvoiceAmount] = useState<number>();
	const [paymentId, setPaymentId] = useState<string>();

	if (user !== null) {
		name = user.name.split(" ");
	}

	const isFormAnswered = () => {
		if (user.formAnswered === false && user.generationId !== "") {
			setFormModalShow(true);
		} else {
			setFormModalShow(false);
		}
	};

	const getPayments = async () => {
		const q = await query(collection(db, "paymentHistory"), where("userId", "==", userId));
		var array = new Array();
		var amount = 0;
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			if (doc.data().verified === true) {
				let tempInfo = { id: doc.id, ...doc.data() };
				array.push(tempInfo);
				amount = amount + parseInt(doc.data().amount);
			}
		});
		array.sort(compare);
		setTotalPay(amount);
		setPayments(array);
		setLastPay(array[0]);
		setLoading(false);
	};
	function compare(a, b) {
		if (a.date > b.date) {
			return -1;
		}
		if (a.date < b.date) {
			return 1;
		}
		return 0;
	}

	const getNextPayment = () => {
		if (payments.length > 0) {
			if (
				((totalPay === 12000 || totalPay === 15000) && payments.length === 1) ||
				paidSeminaries === 60
			) {
				setNextPay(false);
			} else {
				if (nextSeminaries.length > 0) {
					setNextPay(true);

					let arrayIndex = nextSeminaries.findIndex((object: any) => {
						return object.num === paidSeminaries;
					});

					let dateString = nextSeminaries[arrayIndex + 1].date.split("/");

					const nextDate = new Date(Date.now());
					nextDate.setDate(dateString[0]);
					nextDate.setMonth(dateString[1]);
					nextDate.setFullYear(dateString[2]);
					nextDate.setDate(nextDate.getDate() - 3);
					setNextDate(
						nextDate.getDate() + "/" + (nextDate.getMonth() + 1) + "/" + nextDate.getFullYear()
					);
				}
			}
		}
	};

	const handlePay = async () => {
		let price;
		switch (payQuantity) {
			case 1:
				price = 150000;
				break;
			case 2:
				price = 300000;
				break;
			case 3:
				price = 450000;
				break;
			default:
				break;
		}

		let moduleName;
		let classes;
		switch (price) {
			case 150000:
				moduleName = "5 Sesiones";
				classes = 5;
				break;
			case 300000:
				moduleName = "10 Sesiones";
				classes = 10;
				break;
			case 450000:
				moduleName = "15 Sesiones";
				classes = 15;
				break;
			default:
				break;
		}

		if (paymentMethod === 2) {
			var date = new Date(Date.now());
			date.setDate(date.getDate() + 3);
			let tryToPay = await firebase.functions().httpsCallable("oxxoPay");
			tryToPay({
				client: user,
				nombre: moduleName,
				precio: price,
				expires_at: Math.round(date.getTime() / 1000),
				type: "oxxo_cash",
			}).then(async (res) => {
				if (user !== null) {
					const date = new Date(Date.now());
					const payAt = new Date(Date.now());
					const data = {
						name: user.name,
						amount: price / 100,
						date: payAt.valueOf(),
						image: "",
						concept: moduleName,
						verified: false,
						medio: "OXXO Pay",
						checked: false,
						orderId: res.data.id,
						createdAt: date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear(),
						userId: userId,
						email: user.email,
					};
					await db.collection("paymentHistory").add(data);
				}
				const course = {
					inversion: "$ " + (price / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
				};
				setTimeout(() =>
					history.push({
						pathname: "/oxxoref",
						state: {
							client: user,
							course: course,
							charge: res.data.charges.data[0],
						},
					})
				);
			});
		} else if (paymentMethod === 3) {
			var date = new Date(Date.now());
			date.setDate(date.getDate() + 3);
			let tryToPay = await firebase.functions().httpsCallable("oxxoPay");
			tryToPay({
				client: user,
				nombre: moduleName,
				precio: price,
				expires_at: Math.round(date.getTime() / 1000),
				type: "spei",
			}).then(async (res) => {
				if (user !== null) {
					const date = new Date(Date.now());
					const payAt = new Date(Date.now());
					const data = {
						name: user.name,
						amount: price / 100,
						date: payAt.valueOf(),
						image: "",
						concept: moduleName,
						verified: false,
						medio: "SPEI",
						orderId: res.data.id,
						createdAt: date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear(),
						userId: userId,
						email: user.email,
						checked: false,
					};
					await db.collection("paymentHistory").add(data);
				}
				const course = {
					inversion: "$ " + (price / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
				};
				setTimeout(() =>
					history.push({
						pathname: "/speiref",
						state: {
							client: user,
							course: course,
							charge: res.data.charges.data[0],
						},
					})
				);
			});
		} else if (paymentMethod === 1) {
			const course = {
				inversion: "$ " + (price / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
				nombre: moduleName,
				costo: "300 MXN",
				duracion: "60 seminarios (120 horas)",
				pagos: "",
				genModality: userGeneration.type === "in-person" ? "in-person" : "online",
				classes: classes,
				precio: price,
			};
			setTimeout(() =>
				history.push({
					pathname: "/checkout",
					state: {
						client: user,
						course: course,
					},
				})
			);
		} else if (paymentMethod === 4) {
			let url = "https://www.paypal.com/paypalme/academiadelenguas";
			window.open(url);
		}
	};

	useEffect(() => {
		isFormAnswered();
		getPayments();
		if (location.state !== undefined && location.state.openPayModal) {
			handleShow();
		}
	}, []);

	useEffect(() => {
		if (user.generationId !== "") {
			getNextPayment();
		}
	}, [lastPay]);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	if (loading)
		return (
			<div
				style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);

	return (
		<>
			<GenerateInvoiceModal
				show={generateInvoiceModal}
				onHide={() => setGenerateInvoiceModal(false)}
				userInvoiceInfo={userInvoiceInfo}
				invoiceAmount={invoiceAmount}
				autoGenerated={true}
				paymentId={paymentId}
				getPayments={getPayments}
			/>
			<div>
				<h1>¡Bienvenido, {name[0]}!</h1>
			</div>
			<ProgreesCard totalPay={totalPay} numberOfPayments={payments.length} user={user} />
			{payments.length === 0 && user.promotion === false && (
				<>
					<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<p>Comienza tu experiencia</p>
						<button
							onClick={() => history.push("/inscripcion")}
							className="tertiary return-btn-style"
						>
							Inscríbete
						</button>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginTop: 40,
						}}
					>
						<p>¿Ya realizaste tu inscripción? Actualiza tu pago</p>
						<button
							onClick={() => history.push("/micuenta/actualizar-pagos")}
							className="tertiary return-btn-style"
						>
							Actualizar pago
						</button>
					</div>
				</>
			)}
			<div>
				{payments.length > 0 && user.promotion === false ? (
					<>
						{nextPay && (
							<>
								<div
									className="new-payment-title-row"
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										marginBottom: 20,
									}}
								>
									<h2>Próximos pagos</h2>
									<button className="tertiary" style={{ padding: "0 10px" }} onClick={handleShow}>
										Hacer un nuevo pago
									</button>
								</div>
								<NextPaymentCard nextDate={nextDate} totalPay={totalPay} />
							</>
						)}
					</>
				) : (
					<></>
				)}
				<div style={{ marginBottom: 10 }}>
					<h2>Historial de pagos</h2>
					<br></br>
					¿Deseas facturar un pago que no aparece?{" "}
					<a id={"link-to-facturacion"} href="/facturacion">
						Haz click aquí
					</a>
				</div>
				{payments.length > 0 ? (
					payments.map((payment, i) => {
						return (
							<PaymentHistoryCard
								data={payment}
								setGenerateInvoiceModal={setGenerateInvoiceModal}
								user={user}
								setUserInvoiceInfo={setUserInvoiceInfo}
								setInvoiceAmount={setInvoiceAmount}
								cancelationValidation={cancelationValidation}
								setPaymentId={setPaymentId}
								key={i}
							/>
						);
					})
				) : (
					<div style={{ marginBottom: 100 }}></div>
				)}
			</div>
			<Modal
				show={show}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className="" closeButton>
					<div className="d-flex flex-column gap-2">
						<Modal.Title>Hacer un pago</Modal.Title>
						<h5>Selecciona una opción</h5>
					</div>
				</Modal.Header>
				<Modal.Body
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-around",
						height: 350,
					}}
				>
					{18000 - totalPay >= 1500 && modalStep === 1 && totalPay % 1000 === 500 && (
						<button
							className={"new-payment-button " + (payQuantity === 1 ? "active" : "")}
							onClick={() => setPayQuantity(1)}
						>
							$ 1,500 MXN
						</button>
					)}
					{18000 - totalPay >= 3000 && modalStep === 1 && (
						<button
							className={"new-payment-button " + (payQuantity === 2 ? "active" : "")}
							onClick={() => setPayQuantity(2)}
						>
							$ 3,000 MXN <br />
							<span style={{ fontSize: "0.75rem", margin: 0 }}>10 seminarios</span>
						</button>
					)}
					{18000 - totalPay >= 4500 && modalStep === 1 && (
						<button
							className={"new-payment-button " + (payQuantity === 3 ? "active" : "")}
							onClick={() => setPayQuantity(3)}
						>
							$ 4,500 MXN <br />
							<span>15 seminarios</span>
						</button>
					)}
					{modalStep === 2 && (
						<button
							className={"new-payment-button " + (paymentMethod === 1 ? "active" : "")}
							onClick={() => setPaymentMethod(1)}
						>
							Tarjeta
						</button>
					)}
					{modalStep === 2 && (
						<button
							className={"new-payment-button " + (paymentMethod === 2 ? "active" : "")}
							onClick={() => setPaymentMethod(2)}
						>
							OXXO Pay
						</button>
					)}
					{modalStep === 2 && (
						<button
							className={"new-payment-button " + (paymentMethod === 3 ? "active" : "")}
							onClick={() => setPaymentMethod(3)}
						>
							SPEI
						</button>
					)}
					{modalStep === 2 && (
						<button
							className={"new-payment-button " + (paymentMethod === 4 ? "active" : "")}
							onClick={() => setPaymentMethod(4)}
						>
							PayPal
						</button>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={handleClose} style={{ marginRight: "auto" }}>
						Cancelar
					</Button>
					{modalStep !== 1 && (
						<Button variant="secondary" onClick={() => setModalStep(1)}>
							Atrás
						</Button>
					)}
					{modalStep === 1 ? (
						<Button variant="primary" onClick={() => setModalStep(2)} disabled={payQuantity === 0}>
							Siguiente
						</Button>
					) : (
						<Button
							variant="primary"
							onClick={handlePay}
							disabled={payQuantity === 0 || paymentMethod === 0}
						>
							Pagar
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
}

function ProgreesCard({ totalPay, numberOfPayments, user }) {
	let total;
	let modulos;
	let clases;
	if (
		(numberOfPayments === 1 && (totalPay === 12000 || totalPay === 15000)) ||
		user.promotion === true
	) {
		total = totalPay === 0 && user.promotion ? 12000 : totalPay;
		modulos = 5;
		clases = 60;
	} else {
		total = 18000;
		clases = Math.floor(totalPay / 300);
		if (totalPay >= 3000 && totalPay < 7500) {
			modulos = 1;
		} else if (totalPay >= 7500 && totalPay < 12000) {
			modulos = 2;
		} else if (totalPay >= 12000 && totalPay < 15000) {
			modulos = 3;
		} else if (totalPay >= 15000 && totalPay < 18000) {
			modulos = 4;
		} else if (totalPay == 18000) {
			modulos = 5;
		} else {
			modulos = 0;
		}
	}
	const now =
		(((totalPay === 0 && user.promotion ? 12000 : totalPay) / total) * 100).toFixed(2) === "NaN"
			? 0
			: (((totalPay === 0 && user.promotion ? 12000 : totalPay) / total) * 100).toFixed(2);

	return (
		<div className="progress-card-container">
			<div>
				<p className="title-progress-card m-0">
					Programa de Alto Rendimiento en 8 Idiomas Simultáneos
				</p>
				<p className="subtitle-progress-card m-0" style={{ color: "#7b7b7b" }}>
					Progreso de pago
				</p>
				<p className="subtitle-progress-card mt-3 mb-0">
					Módulos cubiertos: {Math.floor(modulos)}/5
				</p>
				<p className="subtitle-progress-card">Seminarios cubiertos: {clases}/60</p>
			</div>
			<div className="progress-bar-container">
				<p className="progress-bar-outside-text">{now}%</p>
				<ProgressBarComponent now={now} totalPay={totalPay} type={"money"} />
				<p className="progress-bar-outside-text">
					${total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}MXN
				</p>
			</div>
			<div>
				<p style={{ margin: 0, marginTop: 20 }}>
					Consulta aquí el progreso del pago de tu plan y el historial de tus pagos anteriores.
				</p>
			</div>
		</div>
	);
}
export default PaymentHistoryContent;
