import React, { useEffect, useState } from "react";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { Link, useLocation, useHistory, useRouteMatch } from "react-router-dom";
import "./styles.scss";
import parse from "html-react-parser";
import { Croquis } from "assets/Imports";
import { getCurrentAnniversary } from "@utils/utilFunctions";
import { useCurrencyContext } from "contexts/CurrencyContext";

interface FaqOneProps {
	name: string;
	questions: any;
}

function FaqOne(props: FaqOneProps) {
	const location: any = useLocation();
	const [activeKey, setActiveKey] = useState(location.state !== undefined ? location.state!.option : "0");
	const [questions, setQuestions] = useState(props.questions);
	const { currencyCountry, prices } = useCurrencyContext();

	useEffect(() => {
		setActiveKey("0");
	}, []);

	const handleButtonClick = (index: string) => {
		setActiveKey(index);
		setQuestions(null);
	};

	useEffect(() => {
		if (questions === null) {
			setQuestions(props.questions);
		}
	}, [questions]);

	return (
		<div>
			<h1>{props.name}</h1>
			<div className="accordion__container">
				{questions?.length > 0 && (
					<Accordion preExpanded={[activeKey]}>
						{questions.map((faq, index) => {
							return (
								<AccordionItem uuid={String(index)}>
									<AccordionItemHeading>
										<AccordionItemButton>{faq.question}</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										{faq.url ? (
											<a href={faq.url}>
												<p>{faq.answer}</p>
											</a>
										) : (
											<>
												<p style={{ whiteSpace: "pre-line" }}>
													{parse(
														faq.answer
															.replaceAll("\\n", "\n")
															.replaceAll("\\", "")
															.replaceAll(
																"www.academiadelenguasinternacionales.mx/ingresar",
																`<a target="_blank" style="color: blue;" href='/ingresar'>https://www.academiadelenguasinternacionales.mx/ingresar</a>`
															)
															.replaceAll(
																"https://academiadelenguasinternacionales.mx/mobileAboutAPP",
																`<a target="_blank" style="color: blue;" href='/mobileAboutAPP'>https://academiadelenguasinternacionales.mx/mobileAboutAPP</a>`
															)
													)}
												</p>
												{faq.question === "¿Cómo se resuelven las dudas durante la semana?" && (
													<ol className="week-doubts-list" type="I">
														<li>
															<h3>Plataforma de aprendizaje</h3>
															<p>
																Dentro de la{" "}
																<Link style={{ color: "#0000EE" }} to="/ingresar">
																	plataforma de aprendizaje
																</Link>
																, en la sección “
																<Link style={{ color: "#0000EE" }} to="/micuenta/consultas">
																	Consultas
																</Link>
																”, podrás plantear cualquier duda que tengas y alguno de tus profesores o compañeros te ayudará a
																disiparla.
															</p>
														</li>
														<li>
															<h3>Grupo de apoyo en WhatsApp.</h3>
															<p>
																Una vez confirmada tu asistencia al curso, te integraremos a un grupo de WhatsApp con tus
																compañeros y profesores. Ahí podrás plantear cualquier duda que te surja y alguno de tus
																profesores o compañeros te ayudará a disiparla.
															</p>
														</li>
														<li>
															<h3>Contacto directo con tu profesor</h3>
															<p>
																Si así lo prefieres, puedes también contactar a tu profesor por medio de un mensaje privado para
																poder resolver cualquier duda.
															</p>
														</li>
													</ol>
												)}
												{faq.question === "¿Puedo facturar?" && (
													<ol className="week-doubts-list" type="I">
														<li>
															<Link to="/facturacion">
																<h3>Portal de facturación</h3>
															</Link>
														</li>
														<li>
															<div>
																<h3>WhatsApp</h3>
																<p>
																	Puedes enviarnos un mensaje por WhatsApp junto con tus datos fiscales, tu comprobante de pago y
																	una dirección de correo electrónico en donde te gustaría recibir tu factura.
																</p>
															</div>
														</li>
														<li>
															<h3>Correo electrónico</h3>
															<p>
																Puedes enviar un correo electrónico a{" "}
																<span style={{ color: "blue", textDecoration: "underline" }}>
																	facturacion@academiadelenguasinternacionales.mx
																</span>{" "}
																junto con tus datos fiscales y tu comprobante de pago.
															</p>
														</li>
													</ol>
												)}
												{faq.question === "¿Cómo puedo llegar en transporte colectivo?" && (
													<div style={{ width: "100%", textAlign: "center" }}>
														<img
															style={{ margin: "auto", width: "50%", maxWidth: 400, minWidth: 300 }}
															src={Croquis}
															alt="croquis de como llegar a la academia"
														/>
													</div>
												)}
												{faq.question === "¿Qué promociones manejan?" && (
													<div className="faq-promotions-container">
														<div className="header">
															<h2 className="title">Promociones {getCurrentAnniversary()} aniversario</h2>
															<p>
																<b style={{ whiteSpace: "pre-line" }}>
																	Costo por seminario: $
																	{currencyCountry === "mx" ? "300 MXN" : `${prices?.USD.online.regularClass} USD`} {"\n"}
																	Duración del programa: 60 seminarios
																</b>
															</p>
														</div>
														<div className="promotion-container">
															<h2>Presencial</h2>
															<div className="promotion-info-container">
																<h3>
																	PROMOCIÓN ${currencyCountry === "mx" ? "250 MXN" : `${prices?.USD["in-person"].promoClass} USD`}{" "}
																	POR SEMINARIO:
																</h3>
																<p>
																	Válida pagando el programa completo de 60 seminarios en una exhibición (60 x $
																	{currencyCountry === "mx" ? "250 MXN" : `${prices?.USD["in-person"].promoClass} USD`} = $
																	{currencyCountry === "mx"
																		? "15,000 MXN"
																		: `${
																				prices?.USD["in-person"].promoClass ? (prices.USD["in-person"].promoClass * 60).toLocaleString() : ""
																		  } USD`}{" "}
																	)
																</p>
															</div>
														</div>
														<div className="promotion-container">
															<h2>En línea</h2>
															<div className="promotion-info-container">
																<h3>PROMOCIÓN ${currencyCountry === "mx" ? "200 MXN" : `${prices?.USD.online.promoClass} USD`} POR SEMINARIO:</h3>
																<p>
																	Válida pagando el programa completo de 60 seminarios en una exhibición (60 x $
																	{currencyCountry === "mx" ? "200 MXN" : `${prices?.USD.online.promoClass} USD`} = $
																	{currencyCountry === "mx" ? "12,000 MXN" : `${prices?.USD.online.promoClass ? (prices.USD.online.promoClass * 60).toLocaleString(): "" } USD`})
																</p>
															</div>
														</div>
														<p className="promotion-legend-text">
															Al aprovechar cualquiera de estas promociones obtienes tu INSCRIPCIÓN Y MATERIAL SIN COSTO para todo
															el programa.
															{"\n\n"}
															No tendrás que comprar libros de texto,{" "}
															<span>nosotros te proporcionamos el material de trabajo.</span>
														</p>
													</div>
												)}
												{faq.question === "Después del primer pago, ¿cómo serán los siguientes?" && (
													<div className="faq-after-firstpay-container">
														<div className="paragraph-container">
															<p>
																<span>Modalidad en línea: </span>
																Si optas por la opción de pago en bloques de 10 sesiones, los siguientes pagos ($3,000MXN) lo
																realizarías cada 10 semanas (dos meses y medio aproximadamente).
															</p>
														</div>
														<div className="paragraph-container">
															<p>
																<span>Modalidad en presencial: </span>
																No es necesario realizar un pago adicional una vez confirmada tu inscripción al programa
																presencial.
															</p>
														</div>
													</div>
												)}
												{faq.question === "Somos dos (o más) estudiantes, ¿existe alguna reducción adicional?" && (
													<div className="faq-after-firstpay-container">
														<div className="paragraph-container">
															<p>Sí, en caso de inscribirse de manera grupal, pueden acceder a las siguientes reducciones:</p>
														</div>
														<div>
															<div className="paragraph-container">
																<p>
																	<span>2 estudiantes: </span>
																	cada estudiante recibe un 10% de descuento adicional sobre cualquier forma de pago.
																</p>
															</div>
															<div className="paragraph-container">
																<p>
																	<span>3 estudiantes: </span>
																	cada estudiante recibe un 15% de descuento adicional sobre cualquier forma de pago.
																</p>
															</div>
															<div className="paragraph-container">
																<p>
																	<span>4 o más estudiantes: </span>
																	contactar directamente al +52 56 2392 5453
																</p>
															</div>
														</div>
														<div className="paragraph-container">
															<p>(Aplica siempre que todos los involucrados sean estudiantes activos).</p>
														</div>
													</div>
												)}
												{faq.question === "¿Puedo pagar por sesión?" && (
													<div className="faq-after-firstpay-container">
														<div className="paragraph-container">
															<p>
																En la modalidad a distancia, los pagos se realizan en bloques de 10 sesiones o
																<span onClick={() => handleButtonClick("1")} className="open-promotions">
																	{" "}
																	cubriendo el curso en una sola exhibición.
																</span>
															</p>
														</div>
														<div className="paragraph-container">
															<p>En la modalidad presencial se realiza un único pago por todo el programa.</p>
														</div>
														<div className="paragraph-container">
															<p>La opción de pago por sesión por el momento no está disponible.</p>
														</div>
													</div>
												)}
											</>
										)}
									</AccordionItemPanel>
								</AccordionItem>
							);
						})}
					</Accordion>
				)}
			</div>
		</div>
	);
}

export default FaqOne;
