import React from "react";
import { EyeBlock, NextArrowScroll } from "assets/Imports";
import { getDate, getImage, romanize } from "functions/Functions";
import { useHistory } from "react-router-dom";
import { useRef, useState, useEffect } from "react";

export default function RowRecordings(props) {
	const { index2, recording, materialId, paidSeminaries, nextSeminaries, genId } = props;
	const containerRefs: any = useRef(null);

	const goToPay = () => {
		history.push({
			pathname: "/micuenta/historial-de-pagos",
			state: {
				openPayModal: true,
			},
		});
	};
	const history = useHistory();
	useEffect(() => {
		if (recording.id === materialId && containerRefs.current) {
		
			containerRefs.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest", offsetTop: -10 });

		}
	}, [recording.id, containerRefs, materialId]);
	return (
		<div
			className={`recording-card ${recording.id === materialId ? "highlight" : ""}`}
			key={"recording-card-" + index2}
			id={"recording-card-" + recording.id}
			ref={containerRefs}
		>
			<a
				style={{ cursor: "pointer" }}
				onClick={() => history.push({ pathname: "/micuenta/grabaciones/" + recording.id, state: { genId: genId } })}
			>
				<div className="image-container">
					<img className="seminary-image" src={getImage(recording.seminaryNum)} alt="imagen del seminario" />
				</div>
				<div className="card-content">
					<div className="title">
						<h2>
							Seminario <span className="patillas-font">{romanize(recording.seminaryNum)}</span>
						</h2>
					</div>
					<div className="date">
						<p>{getDate(recording.date)}</p>
					</div>
				</div>
				<div className="footer">
					<p>Ir a la grabación</p>
				</div>
			</a>
			{recording.seminaryNum > paidSeminaries && (
				<div className="block-seminary">
					<img src={EyeBlock} alt="ojo bloqueado" />
					<p style={{ userSelect: "none" }}>Aún no te has inscrito a este seminario.</p>
					<button className="action-btn linear-bg mt-4" onClick={goToPay}>
						Inscríbete
					</button>
				</div>
			)}
			{recording.seminaryNum <= paidSeminaries && nextSeminaries.findIndex((e) => e.num === recording.seminaryNum) !== -1 && (
				<div className="block-seminary">
					<img src={EyeBlock} alt="ojo bloqueado" />
					<p style={{ userSelect: "none", textAlign: "center" }}>Aún no se ha llevado a cabo este seminario.</p>
				</div>
			)}
			{recording.seminaryNum <= paidSeminaries &&
				recording.videoUrl.includes(".m3u8") === false &&
				nextSeminaries.findIndex((e) => e.num === recording.seminaryNum) === -1 && (
					<div className="block-seminary">
						<p style={{ userSelect: "none", textAlign: "center" }}>
							Aún no se encuentra disponible la grabación de este seminario.
						</p>
					</div>
				)}
		</div>
	);
}
