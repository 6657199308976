export function getSubtitle(name) {
	let splitName = name.split(" - ");
	let subtitle = "";

	for (let index = 1; index < splitName.length; index++) {
		subtitle = subtitle + splitName[index] + (index < splitName.length - 1 ? " - " : "");
	}
	return subtitle.length > 0 ? subtitle : name;
}

export function compareGenerations(a, b) {
	if (returnDayValue(a.classDay) < returnDayValue(b.classDay)) {
		return -1;
	}
	if (returnDayValue(a.classDay) > returnDayValue(b.classDay)) {
		return 1;
	}
	if (returnDayValue(a.classDay) === returnDayValue(b.classDay)) {
		if (a.startHour < b.startHour) {
			return -1;
		}
		if (a.startHour > b.startHour) {
			return 1;
		}
        if(a.startHour === b.startHour) {
            const aYear = a.initialDate.split("/")[2];
            const bYear = b.initialDate.split("/")[2];
            if (aYear < bYear) {
                return -1;
            }
            if (aYear > bYear) {
                return 1;
            }
        }
	}
	return 0;
}

function returnDayValue(day: string) {
	switch (day) {
		case "Lunes":
			return 0;
			break;

		case "Martes":
			return 1;
			break;

		case "Miércoles":
			return 2;
			break;

		case "Jueves":
			return 3;
			break;

		case "Viernes":
			return 4;
			break;

		case "Sábado":
			return 5;
			break;

		case "Domingo":
			return 7;
			break;
		default:
			return 0;
			break;
	}
}
