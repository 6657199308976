import {
	DocumentData,
	DocumentReference,
	DocumentSnapshot,
	addDoc,
	collection,
	doc,
	query,
	where,
	getDocs,
	getDoc,
	limit,
	setDoc,
	updateDoc,
} from "firebase/firestore";
import { db } from "initFirebase";
import { useLocation } from "react-router-dom";

export interface userGen {
	id: string;
	name: string;
	email: string;
}

export interface user {
	id: string;
	name: string;
}
export interface newGen {
	// genLog
	id: string;
	name: string;
}
export interface oldGen {
	// genLog
	id: string;
	name: string;
}

export interface collaboratorGen {
	id: string;
	name: string;
	role: string;
	email: string;
}

export interface collaborator {
	id: string;
	name: string;
	role: string;
}

export function useCollaboratorLogs() {
	const location = useLocation();

	const genLog = async (collaborator: collaboratorGen, user: userGen, newGen?: newGen, oldGen?: oldGen) => {
		try {
			const newLogRef = await addDoc(collection(db, "collaboratorsLogs"), {
				type: "generationAssignment",
				createdAt: new Date(),
				collaboratorName: collaborator.name,
				collaboratorId: collaborator.id,
				collaboratorRole: collaborator.role,
				collaboratorEmail: collaborator.email,
				userName: user.name,
				userEmail: user.email,
				userId: user.id,
				...(newGen ? { newGenName: newGen.name, newGenId: newGen.id } : {}),
				...(oldGen ? { oldGenName: oldGen.name, oldGenId: oldGen.id } : {}),
				...(newGen
					? { description: "Agregó a un estudiante a una nueva generación" }
					: { description: "Eliminó a un estudiante de su generación" }),
			});

			console.log("Log Document added successfully:", newLogRef.id);
		} catch (error) {
			console.error("Error adding Log document:", error);
		}
		return;
	};

	const paymentRequestLog = async (
		action: string,
		collaborator: collaborator,
		user: user,
		amount: number,
		paymentReceipt?: string,
		paymentId?: string,
		check?: boolean,
		rejectReason?: string
	) => {
		try {
			const newLogRef = await addDoc(collection(db, "collaboratorsLogs"), {
				type: "paymentRequest",
				createdAt: new Date(),
				action: action,
				collaboratorName: collaborator.name,
				collaboratorId: collaborator.id,
				collaboratorRole: collaborator.role,
				userName: user.name,
				userId: user.id,
				amount: amount,
				...(paymentId ? { paymentId: paymentId } : {}),
				...(check ? { check: check } : {}),
				...(action === "accept" ? { description: "Registró pago del usuario" } : { description: "Rechazó pago del usuario" }),
				...(paymentReceipt ? { paymentReceiptLink: paymentReceipt } : {}),
				...(rejectReason ? { rejectReason: rejectReason } : {}),
			});

			console.log("Log Document added successfully:", newLogRef.id);
		} catch (error) {
			console.error("Error adding Log document:", error);
		}
		return;
	};

	const invoiceLog = async (action: string, collaborator: collaborator, user: user, rejectReason?: string) => {
		try {
			let tempDescription;
			switch (action) {
				case "request":
					tempDescription = "Creó una nueva solicitud de factura";
					break;
				case "accept":
					tempDescription = "Aprobó una solicitud de factura";
					break;
				case "reject":
					tempDescription = "Rechazó una solicitud de factura";
					break;
				case "cancel":
					tempDescription = "Canceló una factura";
					break;
				case "deleted":
					tempDescription = "Borró una solicitud de  facturación";
					break;
			}

			const newLogRef = doc(collection(db, "collaboratorsLogs")); // Generar un nuevo documento con ID único
			await setDoc(newLogRef, {
				type: "invoice",
				createdAt: new Date(),
				action: action,
				collaboratorName: collaborator.name,
				collaboratorId: collaborator.id,
				collaboratorRole: collaborator.role,
				userName: user.name,
				userId: user.id,
				description: tempDescription,
				...(action === "reject" ? { rejectReason: rejectReason } : {}),
			});

			console.log("Log Document added successfully:", newLogRef.id);
		} catch (error) {
			console.error("Error adding Log document:", error);
		}
		return;
	};

	// 	Al contestar un mensaje por correo en la plataforma.
	// Mensaje enviado
	// Correo
	// Celular
	// colaborador
	const contactLog = async (
		collaborator: collaborator,
		replyMessage: string,
		emails: [string],
		phone: string,
		userName: string,
		contactId?: string, //para ir al mensaje
		check?: boolean //para ir al mensaje si  esta revisado nos manda a su filtro.
	) => {
		try {
			const newLogRef = await addDoc(collection(db, "collaboratorsLogs"), {
				type: "contactReply",
				createdAt: new Date(),
				collaboratorName: collaborator.name,
				collaboratorId: collaborator.id,
				replyMessage: replyMessage,
				recipientEmails: emails,
				phone: phone,
				description: `Contestó un mensaje a ${userName}`,
				contactId: contactId,
				checkedMessage: check,
			});

			if (contactId) {
				const letterRef = doc(db, "newsletter", contactId);
				await updateDoc(letterRef, {
					status: "resolved",
				}).then(async () => {
					const notificationQuery = query(collection(db, "notifications"), where("newsLetterId", "==", contactId));
					const snap = await getDocs(notificationQuery);
					console.log(snap.size);
					if (snap.size > 0) {
						const notificationRef = doc(db, "notifications", snap.docs[0].id);
						await updateDoc(notificationRef, {
							check: true,
						});
					}
				});
			}
			console.log("Log Document added successfully:", newLogRef.id);
		} catch (error) {
			console.error("Error adding Log document:", error);
		}
		return;
	};

	// Funcion para jalar los ultimos 10 logs
	const getLatestLogs = (callback: (logs: any) => void, onError: (error: any) => void) => {
		return (
			db
				.collection("collaboratorsLogs")
				.orderBy("createdAt", "desc")
				//.limit(10)
				.onSnapshot(
					(snapshot) => {
						let logs: any = [];
						snapshot.forEach((doc) => {
							logs.push({ idLog: doc.id, ...doc.data() }); // Agregar el ID del log al resultado
						});
						if (typeof callback === "function") {
							callback(logs);
						}
					},
					(error) => {
						if (typeof onError === "function") {
							onError(error);
						}
					}
				)
		);
	};

	// Función para obtener los logs por id de colaborador
	const getCollaboratorLogsById = (collaboratorId: string, callback: (logs: any) => void, onError: (error: any) => void) => {
		if (collaboratorId) {
			return db
				.collection("collaboratorsLogs")
				.where("collaboratorId", "==", collaboratorId)
				.orderBy("createdAt", "desc")
				.onSnapshot(
					(snapshot) => {
						let logs: any = [];
						snapshot.forEach((doc) => {
							logs.push({ logID: doc.id, ...doc.data() }); // Agregar el ID del log al resultado
						});
						if (typeof callback === "function") {
							callback(logs);
						}
					},
					(error) => {
						if (typeof onError === "function") {
							onError(error);
						}
					}
				);
		}
	};

	return { genLog, paymentRequestLog, invoiceLog, getLatestLogs, getCollaboratorLogsById, contactLog };
}

export default useCollaboratorLogs;
