import "./StudentReviewCard.scss";
import { ReactComponent as QuotesIcon } from "@assets/icons/quotes.svg";

interface StudentReviewCardPropsI {
	student: {
		name: string;
		desc: string;
	};
}

export const StudentReviewCard = ({ student }: StudentReviewCardPropsI) => {
	return (
		<div className="student-review-card">
			<div className="top">
				<QuotesIcon />
				<div className="image-container">
				</div>
			</div>
			<div className="opinion-container">{student.desc}</div>
			<div className="bottom">
				<h3>- {student.name}</h3>
				<QuotesIcon />
			</div>
		</div>
	);
};
