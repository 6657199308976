import { useEffect, useState } from "react";
import "./TestProgressBar.scss";

function TestProgressBar({ words, setWordIndex, index }) {
	const [progress, setProgress] = useState<number>(0);
	const [p, setP] = useState<number>(0);
	const [width, setWidth] = useState(0);

	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [setWidth]);

	useEffect(() => {
		setP(100 / words.length);
	}, [words]);

	return (
		<div className="test-progress-bar-component-container">
			<div className="test-progress-bar-container-2">
				{words.map((word, i) => {
					return (
						<div
							className={"progress-bar-circle " + (word.checked ? "active" : "")}
							style={width > 991 ? { left: p * (i + 1) + "%" } : { top: p * (i + 1) + "%" }}
							onClick={() => setWordIndex(i)}
							key={"circle-" + i}
						></div>
					);
				})}
				<div
					className="test-progress-bar"
					style={width > 991 ? { width: (index + 1) * p + "%" } : { height: (index + 1) * p + "%" }}
				></div>
			</div>
		</div>
	);
}

export default TestProgressBar;
