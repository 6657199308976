import { useHistory } from "react-router-dom";
import "./styles.scss";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { v4 as uuidv4 } from "uuid";
import FacturaImage from "./image/invoice.png";
import {
	InvoiceData,
	getInvoiceStatusByPaymentId,
	uploadInvoiceFireBase,
} from "@services/InvoiceServices";
import { useAuth } from "@hooks/useAuth";
import { InvoiceSentModal } from "@containers/Admin/InvoiceContainer/InvoiceSentModal";
import useNotification from "@hooks/useNotification";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "initFirebase";
import useInvoices from "@containers/Admin/InvoiceContainer/useInvoices";

function InvoiceContainer() {
	const history = useHistory();
	const user = useAuth().user ?? null;
	const [image, setImage] = useState<any>("");
	const [image2, setImage2] = useState<any>("");
	const [loading, setLoading] = useState(false);
	const [invoiceSentModal, setInvoiceSentModal] = useState(false);
	const [emailForInvoice, setEmailForInvoice] = useState<string>();
	const { invoiceRequestNotif } = useNotification();
	const { cancelationValidation } = useInvoices();

	const checkForPendingPayments = async () => {
		let pendingFound: boolean = false;

		const q = query(
			collection(db, "paymentHistory"),
			where("userId", "==", user.id),
			where("checked", "==", true),
			where("verified", "==", true),
			where("isInvoiceable", "!=", false)
		);
		const paymentsArray = new Array();
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(async (doc) => {
			const timestampObject = {
				value: doc.data().date,
				toDate() {
					return new Date(this.value);
				},
			};
			if (cancelationValidation(timestampObject)) {
				paymentsArray.push(doc.id);
			}
		});
		let tempStatus: string;
		Promise.all(
			paymentsArray.map(async (paymentId, index) => {
				let tempStatus = await getInvoiceStatusByPaymentId(paymentId);
				if (tempStatus !== "AutoGenerated") {
					return true;
				} else {
					return false;
				}
			})
		).then((results) => {
			pendingFound = results.some((value) => value === true);
			if (pendingFound) {
				history.push("/micuenta/historial-de-pagos");
			}
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const form = e.target;
		if (image === "") {
			alert("Favor de subir la imagen de su comprobante de pago");
			setLoading(false);
			return;
		} else {
			setEmailForInvoice(form.email.value);
			let link1 = "";
			let link2 = "";
			const upload = await firebase.storage().ref(`images/${uuidv4()}`).put(image);
			link1 = await upload.ref.getDownloadURL();
			if (image2 !== "") {
				const upload2 = await firebase.storage().ref(`images/${uuidv4()}`).put(image2);
				link2 = await upload2.ref.getDownloadURL();
			}

			let data: InvoiceData = {
				createdAt: new Date(),
				requestDate: new Date(),
				fiscalRegime: form.fiscalRegime.value,
				street: form.street.value,
				city: form.city.value,
				state: form.state.value,
				paymentReceiptLink: link1,
				fiscalSituationReceiptLink: link2 ?? "",
				name: form.name.value,
				email: form.email.value,
				paymentForm: form.paymentMethod.value,
				use: form.invoiceUse.value,
				rfc: form.rfc.value.toUpperCase(),
				zipCode: form.zipCode.value,
				corporateName: form.corporateName.value.toUpperCase(),
				amount: parseFloat(form.invoiceAmount.value),
				status: "Pending",
				netPrice: parseFloat((form.invoiceAmount.value / 1.16).toFixed(2)),
				...(user ? { userId: user.id } : {}),
				...(user?.generationId ? { userGen: user.generationId } : {}),
				...(user?.matricula ? { matricula: user.matricula } : {}),
			};
			uploadInvoiceFireBase(data).then(async (res) => {
				await invoiceRequestNotif(data.name, res ?? undefined,user.color);
			});
			setInvoiceSentModal(true);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (user?.id) {
			checkForPendingPayments();
		}
	}, [user]);

	return (
		<div className="header__checkout invoice-container-component" style={{ position: "relative" }}>
			<InvoiceSentModal
				show={invoiceSentModal}
				onHide={() => {
					setInvoiceSentModal(false);
				}}
				emailForInvoice={emailForInvoice}
			/>
			<img className="invoice-image" src={FacturaImage} />
			<div className="invoice-top-text-container">
				<div>
					<h2>Solicitar factura</h2>
				</div>
				<div>
					<p className="mb-2">Por favor, llena el siguiente formulario para solicitar tu factura.</p>
					<p className="mb-4">Te la haremos llegar a la dirección de correo electrónico que proporciones.</p>
				</div>
			</div>
			<div className="invoice-form-main-container">
				<form onSubmit={handleSubmit}>
					<div className="invoice-form-grid">
						<div className="invoice-form-group">
							<label htmlFor="name">Nombre</label>
							<input type="text" id="input-1" name="name" required maxLength={50} />
						</div>
						<div className="invoice-form-group">
							<label htmlFor="input-2">Estado</label>
							<input type="text" id="input-2" name="state" required maxLength={30} />
						</div>
						<div className="invoice-form-group">
							<label htmlFor="input-3">Correo</label>
							<input type="email" id="input-3" name="email" required maxLength={50} />
						</div>
						<div className="invoice-form-group">
							<label htmlFor="input-4">Calle</label>
							<input type="text" id="input-4" name="street" required maxLength={50} />
						</div>
						<div className="invoice-form-group">
							<label htmlFor="input-5">RFC</label>
							<input type="text" id="input-5" name="rfc" required maxLength={16} />
						</div>
						<div className="invoice-form-group">
							<label htmlFor="input-6">Código Postal</label>
							<input type="text" id="input-6" name="zipCode" required maxLength={11} />
						</div>
						<div className="invoice-form-group">
							<label htmlFor="input-7-1">
								Razón Social <br />
								<span className="note">
									*Nombre Fiscal o Razón Social del cliente. Sin el régimen societario (ej.: S.A. de C.V.).
								</span>
							</label>
							<input type="text" id="input-7-1" name="corporateName" required maxLength={70} />
						</div>
						<div className="invoice-form-group">
							<label htmlFor="input-7-2">Régimen Fiscal</label>
							<select id="input-7-2" name="fiscalRegime" defaultValue={""} required>
								<option value="" disabled>
									Selecciona una opción
								</option>
								<option value="601">(601) General de Ley Personas Morales</option>
								<option value="603">(603) Personas Morales con Fines no Lucrativos</option>
								<option value="605">(605) Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
								<option value="606">(606) Arrendamiento</option>
								<option value="608">(608) Demás ingresos</option>
								<option value="609">(609) Consolidación</option>
								<option value="610">(610) Residentes en el Extranjero sin Establecimiento Permanente en México</option>
								<option value="611">(611) Ingresos por Dividendos (socios y accionistas)</option>
								<option value="612">(612) Personas Físicas con Actividades Empresariales y Profesionales</option>
								<option value="614">(614) Ingresos por intereses</option>
								<option value="616">(616) Sin obligaciones fiscales</option>
								<option value="620">(620) Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
								<option value="621">(621) Incorporación Fiscal</option>
								<option value="622">(622) Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
								<option value="623">(623) Opcional para Grupos de Sociedades</option>
								<option value="624">(624) Coordinados</option>
								<option value="628">(628) Hidrocarburos</option>
								<option value="607">(607) Régimen de Enajenación o Adquisición de Bienes</option>
								<option value="629">(629) De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
								<option value="630">(630) Enajenación de acciones en bolsa de valores</option>
								<option value="615">(615) Régimen de los ingresos por obtención de premios</option>
								<option value="625">
									(625) Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas
								</option>
								<option value="626">(626) Régimen Simplificado de Confianza</option>
							</select>
						</div>
						<div className="invoice-form-group">
							<label htmlFor="input-8">Ciudad</label>
							<input type="text" id="input-8" name="city" required maxLength={50} />
						</div>
						<div className="invoice-form-group">
							<label htmlFor="payment-method">Método de Pago:</label>
							<select id="payment-method" name="paymentMethod" required defaultValue={""}>
								<option value="" disabled>
									Selecciona una opción
								</option>
								<option value="01">Efectivo</option>
								<option value="03">Oxxo Pay</option>
								<option value="03">SPEI</option>
								<option value="03">Transferencia</option>
								<option value="03">Depósito Bancario</option>
								<option value="04">Tarjeta</option>
								<option value="04">Paypal</option>
								<option value="03">Otro</option>
							</select>
						</div>
						<div className="invoice-form-group ">
							<label htmlFor="invoice-use">Uso de Factura:</label>
							<select id="invoice-use" name="invoiceUse" required defaultValue={""}>
								<option value="" disabled>
									Selecciona una opción
								</option>
								<option value="G03">Gastos en general</option>
								<option value="D10">Pagos por servicios educativos (colegiaturas)</option>
							</select>
						</div>
						<div className="invoice-form-group">
							<label htmlFor="invoice-amount">Monto Factura:</label>
							<input type="number" id="invoice-amount" name="invoiceAmount" required max={300000} />
						</div>
						<div className="payment-receipt-group receipts">
							<label>
								<h4>Comprobante de pago</h4>
								<p>Adjunta una imagen en donde se distinga la fecha, hora y monto de tu pago.</p>
							</label>
							<label htmlFor="payment-receipt" className="receipt-btn">
								{image == "" ? "Seleccionar imagen" : "Cambiar imagen"}
							</label>
							<label htmlFor="paymentReceipt" style={{ marginLeft: "10px" }}>
								{image.name}
							</label>
							<input
								type="file"
								name="paymentReceipt"
								id="payment-receipt"
								style={{ display: "none" }}
								accept="image/*"
								onChange={(e) => {
									setImage((e.target as HTMLInputElement).files![0]);
								}}
							/>
						</div>
						<div className="fiscal-situation-group receipts">
							<label htmlFor="fiscal-situation">
								<h4>Comprobante de situación fiscal</h4>
								<p>Para evitar errores tipográficos, te sugerimos compartir también tu constancia de situación fiscal.</p>
							</label>
							<div className="fiscal-receipt-btn-div">
								<label htmlFor="fiscal-receipt" className="receipt-btn">
									{image2 == "" ? "Seleccionar imagen" : "Cambiar imagen"}
								</label>
								<span>*No Obligatorio</span>
							</div>
							<label htmlFor="fiscalReceipt" style={{ marginLeft: "10px" }}>
								{image2.name}
							</label>
							<input
								type="file"
								name="fiscalReceipt"
								id="fiscal-receipt"
								style={{ visibility: "hidden" }}
								accept="image/*"
								onChange={(e) => {
									setImage2((e.target as HTMLInputElement).files![0]);
								}}
							/>
						</div>
					</div>
					<div className="goback-generate-btns-container">
						<button className="go-back-btn" onClick={() => history.goBack()}>
							Regresar{" "}
						</button>
						<button
							className={"generate-invoice-btn " + (loading === true ? "disabled-btn" : "")}
							type="submit"
							disabled={loading}
						>
							Solicitar factura
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

export default InvoiceContainer;
