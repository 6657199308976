import FaqsButton from "@components/FaqsButton/FaqsButton";
import KidsContainer from "@containers/KidsContainer/KidsContainer";
import ExclamationButton from "@components/ExclamationButton/ExclamationButton";

function KidsScreen() {
	return (
		<div style={{ overflow: "hidden", position: "relative" }}>
			<KidsContainer />
			<FaqsButton />
			<ExclamationButton />
		</div>
	);
}

export default KidsScreen;
