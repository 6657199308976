export function getPaymentMethodCode(method) {
	switch (method) {
		case "PayPal":
			return "PPL";
			break;
		case "Transferencia/Depósito bancario":
			return "TB/DEP";
			break;
		case "Transferencia/Deposito bancario":
			return "TB/DEP";
			break;
		case "Transferencia":
			return "TB";
			break;
		case "Depósito bancario":
			return "DEP";
		break;
		case "Efectivo":
			return "EF";
			break;
		case "OXXO Pay":
			return "OXX";
			break;
		case "SPEI":
			return "SPEI";
			break;
		case "Otro":
			return "OTRO";
			break;
		case "Tarjeta":
			return "TDC";
			break;
		default:
			return "";
			break;
	}
}
