import { useEffect, useState } from "react";
import { getLanguage } from "@api/Vocabulary";
import { useHistory, useParams } from "react-router-dom";
import "./VocabularyLanguageStyles.scss";
import { returnFlagByLanguage } from "functions/Functions";
import { NotesModal } from "../VocabularySeminaryContainer/components/NotesModal/NotesModal";
import { getDifficultColor } from "@components/Vocabulary/PersonalizedTest/functions/functions";
import { Spinner20vh } from "@components/Spinner/SpinnerMyAccountView";
import { LENLogo, Check } from "assets/Imports";
import { EditWordModal } from "@components/Vocabulary/EditWordModal/EditWordModal";
import { EditWordModalI } from "interfaces/Vocabulary/EditWordModal";
import EditSeminariesModal from "@components/Vocabulary/EditSeminariesModal/EditSeminariesModal";
import { Nullable } from "interfaces/Interfaces";
import { EditSeminariesWordI } from "interfaces/Vocabulary/EditSeminariesModal";
import { place_holder_vocabulary } from "@utils/PlaceHolders/place-holder-vocabulary";
function VocabularyLanguageContainer() {
	const { languageId, language } = useParams<any>();
	const [idiom, setIdiom] = useState("");
	const [words, setWords] = useState<Array<any>>();
	const [wordsBackup, setWordsBackup] = useState<Array<any>>(new Array());
	const [pagination, setPagination] = useState<Array<any>>(new Array());
	const [notesModal, setNotesModal] = useState({
		show: false,
		notes: "",
	});
	const history = useHistory();
	const [sort, setSort] = useState<string>("0");
	const [searchText, setText] = useState<string>("");
	const [debouncedText, setDebouncedText] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(true);
	const [difficultFilter, setDifficultFilter] = useState({
		veryEasy: true,
		easy: true,
		mediumLow: true,
		mediumHigh: true,
		hard: true,
		veryHard: true,
		none: true,
	});
	const [selectAll, setSelectAll] = useState(true);
	const [editWordModal, setEditWordModal] = useState<EditWordModalI>({ show: false, word: null });
	const [editSeminariesModal, setEditSeminariesModal] = useState<{ show: boolean; word: Nullable<EditSeminariesWordI> }>({
		show: false,
		word: null,
	});

	const { input_buscar_terminos } = place_holder_vocabulary;
	const getWords = () => {
		let words_temp;
		getLanguage(languageId).then((res) => {
			setWords(res.words);
			words_temp = res.words;
			setWordsBackup(res.words);
		});
	};

	const filterByAll = (wordsB?: Array<any>) => {
		let wordsTemp = wordsB === undefined ? JSON.parse(JSON.stringify(wordsBackup)) : JSON.parse(JSON.stringify(wordsB));
		let words_temp: any;
		wordsTemp = wordsTemp.filter(
			(word) =>
				(parseFloat(word.dificultad) <= 1 && parseFloat(word.dificultad) > 0.9 && difficultFilter.veryEasy) ||
				(parseFloat(word.dificultad) <= 0.9 && parseFloat(word.dificultad) > 0.8 && difficultFilter.easy) ||
				(parseFloat(word.dificultad) <= 0.8 && parseFloat(word.dificultad) > 0.7 && difficultFilter.mediumLow) ||
				(parseFloat(word.dificultad) <= 0.7 && parseFloat(word.dificultad) > 0.5 && difficultFilter.mediumHigh) ||
				(parseFloat(word.dificultad) <= 0.5 && parseFloat(word.dificultad) > 0.3 && difficultFilter.hard) ||
				(parseFloat(word.dificultad) <= 0.3 && difficultFilter.veryHard) ||
				(word.dificultad === null && difficultFilter.none)
		);
		words_temp = wordsTemp.filter(
			(word) =>
				word.palabra.toLowerCase().indexOf(debouncedText.toLowerCase()) !== -1 ||
				(word.palabra_opcional !== null && word.palabra_opcional.toLowerCase().indexOf(debouncedText.toLowerCase()) !== -1) ||
				word.categoria.toLowerCase().indexOf(debouncedText.toLowerCase()) !== -1 ||
				word.sub_categorías.toLowerCase().indexOf(debouncedText.toLowerCase()) !== -1 ||
				word.significados.toLowerCase().indexOf(debouncedText.toLowerCase()) !== -1 ||
				word.seminarios.split(", ").some((s) => s === debouncedText.trim())
		);
		if (sort === "1") {
			words_temp = [...words_temp].sort(function (a, b) {
				return a.palabra.toLowerCase() < b.palabra.toLowerCase() ? -1 : a.palabra.toLowerCase() > b.palabra.toLowerCase() ? 1 : 0;
			});
		}

		setWords(words_temp);
	};

	const handleEditWord = (word) => {
		const data = {
			...word,
			language: word.idioma,
			native_word: word.palabra,
			meaning: word.significados.split(", "),
			sub_category: word.sub_categorías === "sin subcategorías" ? [] : word.sub_categorías.split(", "),
			category: [word.category],
			language_Id: languageId,
		};

		setEditWordModal({ show: true, word: data });
	};

	const filterBy = (e) => {
		setWords(wordsBackup);
	};

	const handleEditSeminaries = (word) => {
		try {
			const data = {
				word_id: word.word_id,
				word_data_id: word.id,
				seminaries: word.seminarios === "sin seminario" ? [] : word.seminarios.split(", ").sort((a, b) => a - b),
				native_word: word.palabra,
				optional_word: word.palabra_opcional,
			};

			setEditSeminariesModal({ show: true, word: data });
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getWords();
	}, []);

	useEffect(() => {
		if (!loading && debouncedText.length > 2) {
			setLoading(true);
			filterByAll();
		}
		let selected = 0;
		for (const [key, value] of Object.entries(difficultFilter)) {
			if (value) {
				selected++;
			}
		}
		if (selected < 7) {
			setSelectAll(false);
		}
	}, [sort, debouncedText, difficultFilter, wordsBackup]);

	useEffect(() => {
		const timerId = setTimeout(() => {
			setDebouncedText(searchText);
		}, 500);

		return () => {
			clearTimeout(timerId);
		};
	}, [searchText]);

	useEffect(() => {
		if (loading && words !== undefined) {
			setLoading(false);
		}
	}, [words]);

	useEffect(() => {
		let selected = 0;
		for (const [key, value] of Object.entries(difficultFilter)) {
			if (value) {
				selected++;
			}
		}
		if (selectAll) {
			setDifficultFilter({
				veryEasy: true,
				easy: true,
				mediumLow: true,
				mediumHigh: true,
				hard: true,
				veryHard: true,
				none: true,
			});
		} else if (selected === 7) {
			setDifficultFilter({
				veryEasy: false,
				easy: false,
				mediumLow: false,
				mediumHigh: false,
				hard: false,
				veryHard: false,
				none: false,
			});
		}
	}, [selectAll]);

	return (
		<div className="vocabulary-language-admin-main-container">
			<EditWordModal
				show={editWordModal.show}
				onHide={() => setEditWordModal({ show: false, word: null })}
				word={editWordModal.word}
				getWords={getWords}
			/>
			<EditSeminariesModal
				show={editSeminariesModal.show}
				word={editSeminariesModal.word}
				onHide={() => setEditSeminariesModal({ show: false, word: null })}
				updateWords={getWords}
			/>
			<button
				className="goback-btn"
				onClick={() => history.push({ pathname: "/admin/vocabulario", state: { view: 2, options: "languages" } })}
			>
				Atrás
			</button>
			<div className="title">
				<h1>{idiom}</h1>
			</div>
			<div className="words-container">
				<div className="filter-container">
					<div className="left">
						<select
							value={sort}
							onChange={(t: any) => {
								filterBy(setSort(t.target.value));
							}}
						>
							<option value={0}>Ordenar Por</option>
							<option value={1}>Alfabético</option>
						</select>
						<div className="search-container">
							<img src={LENLogo} alt="" />
							<input type="text" placeholder={input_buscar_terminos} onChange={(e: any) => filterBy(setText(e.target.value))} />
						</div>
					</div>
				</div>
				<div className="difficult-filter-container">
					<div className="input-container">
						<input type="checkbox" name="all" id="all" checked={selectAll} onChange={(e) => setSelectAll(e.target.checked)} />
						<label htmlFor="all">Todas</label>
					</div>
					<div className="input-container">
						<input
							type="checkbox"
							name="very-easy"
							id="very-easy"
							checked={difficultFilter.veryEasy}
							onChange={(e) => setDifficultFilter({ ...difficultFilter, veryEasy: e.target.checked })}
						/>
						<label htmlFor="very-easy">Muy fácil</label>
					</div>
					<div className="input-container">
						<input
							type="checkbox"
							name="easy"
							id="easy"
							checked={difficultFilter.easy}
							onChange={(e) => setDifficultFilter({ ...difficultFilter, easy: e.target.checked })}
						/>
						<label htmlFor="easy">Fácil</label>
					</div>
					<div className="input-container">
						<input
							type="checkbox"
							name="medium-low"
							id="medium-low"
							checked={difficultFilter.mediumLow}
							onChange={(e) => setDifficultFilter({ ...difficultFilter, mediumLow: e.target.checked })}
						/>
						<label htmlFor="medium-low">Intermedia baja</label>
					</div>
					<div className="input-container">
						<input
							type="checkbox"
							name="medium-high"
							id="medium-high"
							checked={difficultFilter.mediumHigh}
							onChange={(e) => setDifficultFilter({ ...difficultFilter, mediumHigh: e.target.checked })}
						/>
						<label htmlFor="medium-high">Intermedia alta</label>
					</div>
					<div className="input-container">
						<input
							type="checkbox"
							name="hard"
							id="hard"
							checked={difficultFilter.hard}
							onChange={(e) => setDifficultFilter({ ...difficultFilter, hard: e.target.checked })}
						/>
						<label htmlFor="hard">Difícil</label>
					</div>
					<div className="input-container">
						<input
							type="checkbox"
							name="very-hard"
							id="very-hard"
							checked={difficultFilter.veryHard}
							onChange={(e) => setDifficultFilter({ ...difficultFilter, veryHard: e.target.checked })}
						/>
						<label htmlFor="very-hard">Muy difícil</label>
					</div>
					<div className="input-container">
						<input
							type="checkbox"
							name="none"
							id="none"
							checked={difficultFilter.none}
							onChange={(e) => setDifficultFilter({ ...difficultFilter, none: e.target.checked })}
						/>
						<label htmlFor="none">Sin dificultad</label>
					</div>
				</div>
				<NotesModal
					show={notesModal.show}
					onHide={() => setNotesModal({ ...notesModal, show: false })}
					notes={notesModal.notes}
				/>
				<table className="table">
					<thead>
						<tr>
							<th scope="col">Palabra</th>
							<th scope="col">Traducción</th>
							<th className="text-center" scope="col">
								Idioma
							</th>
							<th scope="col">Dificultad promedio</th>
							<th scope="col">Seminarios</th>
							<th scope="col">Categoría</th>
							<th scope="col">Subcategoría</th>
							<th scope="col"></th>
						</tr>
					</thead>
					{!loading && words !== undefined && (
						<tbody>
							{words.length > 0 && (
								<>
									{words.map((word, index) => {
										function deleteWordSeminary(word_seminary_id: any) {
											throw new Error("Function not implemented.");
										}

										function editWord(word: any) {
											throw new Error("Function not implemented.");
										}

										return (
											<tr key={"word-" + word.id}>
												<th>
													{word.bookmark === 1 && (
														<span
															style={{ color: "red", cursor: "pointer" }}
															onClick={() => setNotesModal({ notes: word.notes, show: true })}
														>
															*
														</span>
													)}{" "}
													{word.palabra} {word.palabra_opcional !== null && <span>({word.palabra_opcional})</span>}
												</th>
												<td>{word.significados}</td>
												<td>
													<p className="row-languages justify-content-center">{returnFlagByLanguage(word.idioma)}</p>
												</td>
												<td>
													{word.dificultad !== null && (
														<div
															className="difficult-color-circle"
															style={{ background: getDifficultColor(word.dificultad !== null ? word.dificultad : 1.01) }}
														></div>
													)}
													<p className="m-0 text-center">
														{word.dificultad !== null ? parseFloat(word.dificultad).toFixed(2) : "n/a"}
													</p>
												</td>
												<td className="seminaries-td">
													<span>{word.seminarios}</span>
													<img src={Check} alt="edit" onClick={() => handleEditSeminaries(word)} />
												</td>
												<td>
													<p>{word.categoria}</p>
												</td>
												<td>{word.sub_categorías !== null && word.sub_categorías}</td>
												<td>
													<img src={Check} alt="" onClick={() => handleEditWord(word)} />
												</td>
											</tr>
										);
									})}
								</>
							)}
						</tbody>
					)}
				</table>
				{loading && <Spinner20vh />}
			</div>
		</div>
	);
}
export default VocabularyLanguageContainer;
