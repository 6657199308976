import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from 'react-select';


function AddProfessorModalComponent (props) {

    const [users, setUsers]: any = useState([]);
    const [userSelected, setUserSelected] = useState('');

    const getUsers = async () => {
        let studentsArray: any = [];
        const q = query(collection(db, "users"), where('generationId', '!=', props.gen.id));

        const querySnapshot = await getDocs(q);
        let i = 0;
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            if(doc.data().userType === undefined || doc.data().userType !== 2 || doc.data().userType !== 3) {
                const data2 = {
                    label: doc.data().name + " - " + doc.data().email,
                    value: doc.id
                }
                studentsArray.push(data2);
                i++;
                if (i === querySnapshot.size) {
                    setUsers(studentsArray);
                }
            }
        });
    }

    const handleSelection = (e) => {
        setUserSelected(e.value);
    }

    useEffect(() => {
        if(props.gen !== null) {
            getUsers();
        }
    }, [props.refresh, props.gen])

    const save = async () => {
        const userRef = doc(db, "users", userSelected);

        // Set the "capital" field of the city 'DC'
        await updateDoc(userRef, {
            generationId: props.gen.id,
            userType: 3,
            addedAt: new Date()
        });

        props.onHide()
        props.saveGen()
    }

    return(
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Agregar profesor
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='setminheightmodalAddUser'>
                <p className='regular-text' style={{ marginBottom: '60px' }}>Selecciona al usuario que deseas agregar como profesor a esta generación.</p>
                <Select onChange={(e) => handleSelection(e)} options={users} />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onHide}>Cancelar</button>
                <button onClick={save}>Guardar</button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddProfessorModalComponent;