import { db } from "initFirebase";
import { useState, useEffect } from "react";
import { Image, Row } from "react-bootstrap";
import Container from "react-bootstrap/esm/Container";
import { Link, useHistory } from "react-router-dom";
import CounterContainer from "./CounterContainer/CounterContainer";
import "./styles.scss";
import logoHD from "../../screens/mobileAboutApp/screenIMG/image 3.png";
import { query, where, collection, onSnapshot } from "firebase/firestore";
import { Enrollment, InPersonClass, OnlineClass, Star, WaveOther } from "assets/Imports";
import { getCurrentAnniversary } from "@utils/utilFunctions";
import { GenTypes, TGeneration } from "interfaces/Generations";
import { getActiveOnlineGenerations } from "@services/GenerationService";

function EnrollmentContainer() {
	const [fechasInfo, setFechasInfo] = useState<any>({});
	const history = useHistory();
	const [inPersonGen, setInpersonGen] = useState<any>(null);
	const [inPersonPromoDate, setInPersonPromoDate] = useState<Date>(new Date());
	const [onlineGenerations, setOnlineGenerations] = useState<Array<TGeneration>>([]);

	const getCopie = async () => {
		db.collection("dynamic_copies")
			.doc("Hh06tq4Yk4XhTThIabUp")
			.collection("fechas")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setFechasInfo({
						...doc.data(),
						id: doc.id,
					});
				});
			});
	};

	const getActiveGenerations = async () => {
		//Fetch generacion en persona
		const q = query(
			collection(db, "generations"),
			where("type", "==", GenTypes.InPerson),
			where("active", "==", true)
		);

		onSnapshot(q, (querySnapshot) => {
			querySnapshot.forEach((doc) => {
				console.log(doc.data());
				const dateArray = doc.data().promoDate.split("/");
				const tempDate = new Date();
				tempDate.setFullYear(dateArray[2]);
				tempDate.setMonth(dateArray[1]);
				tempDate.setDate(dateArray[0]);
				tempDate.setHours(0, 0, 0);
				setInPersonPromoDate(tempDate);
				console.log(doc.data());
				setInpersonGen({ id: doc.id, ...doc.data() });
			});
		});

		// Fetch generaciones en linea
		try {
			const response = await getActiveOnlineGenerations();
			setOnlineGenerations(response);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getCopie();
		getActiveGenerations();
	}, []);

	return (
		<>
			<div className="header__enrollment">
				<Container>
					<h1>¿Listo para convertirte en políglota?</h1>
					<p>{fechasInfo.header}</p>
					<p>
						Si necesitas aclarar alguna duda para poder tomar una buena decisión,{" "}
						{"te invitamos a "}
						<Link to="/dudas">explorar la sección de dudas</Link> o{" "}
						<Link to="/contacto">contactarnos directamente.</Link>
					</p>
				</Container>
			</div>
			<img src={WaveOther} alt="Logo" className="wave" />
			<div className="section__enrollment">
				<Container>
					<Row>
						<CounterContainer fechasInfo={fechasInfo} />
					</Row>
					<Row style={{ marginTop: "0px", marginBottom: "40px" }}>
						<div className="receive-notice-container">
							<div>
								<h3>¿Ya eres parte y necesitas facturar?</h3>
							</div>
							<div style={{ marginBottom: "15px", fontWeight: "bold" }}>
								Haz click el botón inferior para solicitarla. Se te hará llegar por correo
								electrónico.
							</div>
							<div style={{ textAlign: "center" }}>
								<Link to={"/facturacion"}>
									<button className="tertiary request-invoice-btn">Solicitar factura</button>
								</Link>
							</div>
						</div>
					</Row>
					<h3 style={{ marginBottom: "30px" }}>Comienza tu aprendizaje ahora mismo</h3>
					{/* Contenedor de botones para inscribirse*/}
					<div className="enrollment-inscriptions-cards-container">
						{inPersonGen !== null && (
							<Link
								className={inPersonGen.limit === 0 ? "disable" : ""}
								to={
									inPersonGen.limit > 0
										? {
												pathname: "/inscribete",
												search: "?modalidad=presencial",
										  }
										: {}
								}
							>
								<div className="inscription-card in-person">
									{inPersonGen.limit === 0 && (
										<div className="full-card">
											<h3>
												<b>CUPO LLENO</b>
											</h3>
										</div>
									)}
									<div className="title-container">
										<h2>Presencial</h2>
										{inPersonGen.limit > 1 ? (
											<h3 className="title-type-inscription-enrollment">
												Espacios disponibles:{" "}
												<a style={{ fontSize: "2rem" }}>{inPersonGen.limit}</a>
											</h3>
										) : (
											inPersonGen.limit === 1 && (
												<h3 className="title-type-inscription-enrollment">
													<a>¡Último espacio disponible!</a>
												</h3>
											)
										)}
									</div>
									<img src={InPersonClass} alt="icono de clases presenciales" />
								</div>
							</Link>
						)}
						{onlineGenerations.length > 0 && (
							<Link
								to={{
									pathname: "/inscribete",
									search: "?modalidad=online",
								}}
							>
								<div className="inscription-card online">
									<div className="title-container">
										<h2>En línea</h2>
									</div>
									<img src={OnlineClass} alt="icono de clases en linea" />
								</div>
							</Link>
						)}
					</div>
					<div>
						<div
							className="android-header-container"
							style={{
								flexWrap: "wrap",
								paddingTop: "30px",
								maxWidth: 600,
								margin: "auto",
								marginBottom: 30,
								padding: 20,
								boxShadow: "0px 3.93189px 12.7786px rgba(0, 0, 0, 0.19)",
								borderRadius: 10,
							}}
						>
							<div className="image-container">
								<Image
									style={{ width: "99px", height: "auto" }}
									src={logoHD}
									alt=" "
									className=" "
								/>
							</div>
							<div className="title-container" style={{ width: "64%" }}>
								<h2 className="section1_Text1 text-start">Academia de Lenguas Internacionales</h2>
								<h3 className="section1_Text2" style={{ color: "#3478f6", textAlign: "start" }}>
									Plataforma de aprendizaje
								</h3>
								<div style={{ height: "23.53px", paddingLeft: "0" }}>
									<button
										type="button"
										className="obtain-mobile-btn"
										onClick={() => history.push("/mobileAboutAPP")}
									>
										Obtener
									</button>
								</div>
							</div>
							<div className="android-banner-container w-100">
								<div className="caracteristica-container">
									<h3 className="big-text">{getCurrentAnniversary()} años</h3>
									<h3 className="small-text">formando políglotas</h3>
								</div>
								<div className="line-divisor"></div>
								<div className="caracteristica-container">
									<h3 className="small-text">EDAD</h3>
									<h3 className="big-text">17+</h3>
									<h3 className="small-text">AÑOS</h3>
								</div>
								<div className="line-divisor"></div>
								<div className="caracteristica-container">
									<h3 className="small-text">Duración</h3>
									<h3 className="big-text">60</h3>
									<h3 className="small-text">Seminarios</h3>
								</div>
								<div className="line-divisor"></div>
								<div className="caracteristica-container">
									<h3 className="big-text">
										4.5{" "}
										<img
											src={Star}
											style={{
												filter:
													"invert(56%) sepia(6%) saturate(173%) hue-rotate(182deg) brightness(99%) contrast(91%)",
											}}
											alt="estrella"
										/>
									</h3>
									<h3 className="small-text">Calificación</h3>
								</div>
							</div>
						</div>
						<Image fluid src={Enrollment} alt="Enrollment" />
					</div>
				</Container>
			</div>
		</>
	);
}

export default EnrollmentContainer;
