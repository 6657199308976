import { Cardicon, CashIcon, OxxoIcon, PDFIcon, PaypalLogo, ReceiptIcon, SPEIIcon, WalletIcon } from "assets/Imports";
import { Link } from "react-router-dom";
import "./styles.scss";
import { useEffect, useState } from "react";
import { InvoiceData, downloadInvoicePDF, getFbInvoiceByPaymentId, getInvoiceStatusByPaymentId } from "@services/InvoiceServices";

function PaymentHistoryCard(props) {
	const [ableToCancel, setAbleToCancel] = useState<boolean>(false); // This state checks if the payment was done in the current month
	const [invoiceStatus, setInvoiceStatus] = useState<string>();
	const date = new Date(props.data.date);
	const [invoice, setInvoice] = useState<InvoiceData>();


	const handleDownloadPDF = async () => {
		if (invoice?.ApiId){
			await downloadInvoicePDF(invoice.ApiId, invoice.ticketNumber, invoice.matricula);
		}
	}

	const handleGenerateInvoice = () => {
		props.setGenerateInvoiceModal(true);
		props.setUserInvoiceInfo(props.user);
		props.setInvoiceAmount(props.data.amount);
		props.setPaymentId(props.data.id);
		let tempDate = new Date(props.data.date);
	};

	const getInvoiceStatus = async (paymentId: string) => {
		let tempStatus = await getInvoiceStatusByPaymentId(paymentId);
		if (tempStatus === "AutoGenerated") {
			await getInvoice();
		}
		setInvoiceStatus(tempStatus);
		 
	};

	const getInvoice = async () => {
		let tempInvoice = await getFbInvoiceByPaymentId(props.data.id);
		if (tempInvoice) {
			setInvoice(tempInvoice);
		}
	};

	useEffect(() => {
		if (props.data.date) {
			const timestampObject = {
				value: props.data.date,
				toDate() {
					return new Date(this.value);
				},
			};
			let tempValidation: boolean = props.cancelationValidation(timestampObject);
			setAbleToCancel(tempValidation);
		}
	}, [props.data.date]);



	useEffect(() => {
		if (props.data.id) {
			getInvoiceStatus(props.data.id);
		}
	}, [props.data]);

	return (
		<div className="payment-history-card-container">
			<div className="payment-history-card-row-container">
				<div className="payment-history-right-content-card-container">
					<div className="icon-payment-history-container">
						{props.data.medio === "OXXO Pay" && <img src={OxxoIcon} alt="oxxo-icon" />}
						{props.data.medio === "Efectivo" && <img src={CashIcon} alt="cash-icon" />}
						{props.data.medio === "Otro" && <img src={ReceiptIcon} alt="receipt-icon" />}
						{props.data.medio === "Tarjeta" && <img src={Cardicon} alt="card-icon" />}
						{props.data.medio === "SPEI" && <img src={SPEIIcon} alt="SPEI-icon" />}
						{props.data.medio === "PayPal" && <img src={PaypalLogo} alt="paypal-loco-icon" />}
						{(props.data.medio === "Transferencia/Deposito bancario" ||
							props.data.medio === "Transferencia/Depósito bancario" ||
							props.data.medio === "Transferencia" ||
							props.data.medio === "Depósito bancario") && <img src={WalletIcon} alt="wallet-icon" />}
					</div>
					<div className="text-payment-history-container">
						<p>
							<a className="title-red">{props.data.medio}</a>
						</p>
						<p className="amount-text">
							$
							{parseInt(props.data.amount)
								.toFixed(2)
								.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
							MXN
						</p>
						<p>{props.data.concept}</p>
						<div style={{ display: "flex" }}>
							<p className="realizacion-date">Realización:&nbsp; </p>
							<p className="date-text">{date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}</p>
						</div>
						{props.data.email !== undefined && (
							<>
								<p className="realizacion-date">Confirmación enviada al correo:</p>
								<p className="text-center" style={{ color: "black" }}>
									{props.data.email}.
								</p>
							</>
						)}
					</div>
				</div>
				<div className="payment-history-left-content-card-container">
					<div className="invoice-status">
						<span className="fixed-text">Estado Factura</span>
						{!ableToCancel ? (
							<>
								<span className="status red">Caducó el acceso</span>
								<div className="question-icon" style={{ lineHeight: "20px" }}>
									?
									<div className="info-box">
										<p>Sólo puedes facturar durante el mismo mes tu pago.</p>
									</div>
								</div>
							</>
						) : ableToCancel && invoiceStatus === "" && props.data.isInvoiceable !== false ?(
							<span className="status">Pendiente de generar</span>
						) : ableToCancel && invoiceStatus === "AutoGenerated" ? (
							<span className="status">Válida</span>
						) : ableToCancel && invoiceStatus === "Canceled" ? (
							<span className="status red">Cancelada</span>
						): props.data.isInvoiceable === false &&
							<span className="status red">Pago no disponible para facturar</span>
						}
					</div>
					{ableToCancel && invoiceStatus !== "AutoGenerated" && props.data.isInvoiceable !== false ? (
						<button className="generate-invoice-btn" style={{ padding: "0 10px" }} onClick={handleGenerateInvoice}>
							Generar factura
						</button>
					) : (
						ableToCancel && invoice?.ApiId &&(
							<button type="button" className="see-receipt-btn" onClick={handleDownloadPDF}>
								<img src={PDFIcon} alt="Icono de PDF"></img>
							</button>
						)
					)}
				</div>
			</div>
		</div>
	);
}

export default PaymentHistoryCard;
