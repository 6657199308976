import "./NotificationStyle.scss";
import parse from "html-react-parser";
import { useHistory, useRouteMatch } from "react-router-dom";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import {
	CashIcon,
	CheckmarIcon,
	CloseCircle,
	CommentIcon,
	ConsultsIcon,
	InvoiceAdminIcon,
	Logo2,
	NextArrow,
	OpenBook2,
	PeopleIcon,
	UserIcon,
} from "assets/Imports";

function NotificationCard({ notification, user }) {
	const history = useHistory();
	const [seminaryNum, setSeminaryNum] = useState<number>(notification.seminaryNum);

	let { url } = useRouteMatch();

	useEffect(() => {
		const validate = async () => {
			const docRef = doc(db, "consults", notification.consultId);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				if (seminaryNum !== docSnap.data().seminaryNum) {
					const docRef = doc(db, "notifications", notification.id);

					// Set the "capital" field of the city 'DC'
					await updateDoc(docRef, {
						seminaryNum: docSnap.data().seminaryNum,
					});
				}
			}
		};

		if (
			seminaryNum >= 0 &&
			(notification.type === "aprove" ||
				notification.type === "publication" ||
				notification.type === "aproveComentary" ||
				notification.type === "consult-new" ||
				notification.type === "editConsult")
		) {
			validate();
		}
	}, []);

	const navigateTo = async () => {
		if (url === "/") {
			url = "/micuenta";
		}
		const docRef = doc(db, "notifications", notification.id);

		// Set the "capital" field of the city 'DC'
		await updateDoc(docRef, {
			seen: true,
		});

		if (
			seminaryNum >= 0 &&
			(notification.type === "aprove" ||
				notification.type === "publication" ||
				notification.type === "aproveComentary" ||
				notification.type === "consult-new" ||
				notification.type === "editConsult")
		) {
			if (user.userType !== 2) {
				const docRef = doc(db, "consults", notification.consultId);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					history.push({
						pathname: "/micuenta/consultas/" + docSnap.data().seminaryNum,
						state: { consultId: notification.consultId },
					});
				} else {
					alert("Parece que la consulta que buscas ya no existe.");
					await deleteDoc(doc(db, "notifications", notification.id));
				}
			} else {
				const docRef = doc(db, "consults", notification.consultId);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					history.push({
						pathname: "/admin/consultas/" + docSnap.data().seminaryNum,
						state: { consultId: notification.consultId },
					});
				} else {
					alert("Parece que la consulta que buscas ya no existe.");
				}
			}
		} else if (notification.type === "aproved-payment" || notification.type === "denied-payment") {
			history.push("/micuenta/actualizar-pagos");
		} else if (notification.type === "conektaAutomaticPayment") {
			history.push({
				pathname: "/admin/actualizar-pagos",
				state: { paymentHistoryId: notification.paymentHistoryId, type: notification.type },
			});
		} else if (notification.type === "paymentReminder") {
			history.push("/micuenta/historial-de-pagos");
		} else if (notification.type === "materialReady" || notification.type === "recordingsReady") {
			history.push("/micuenta/materiales");
		} else if (notification.type === "newAnnouncement") {
			history.push("/micuenta");
		} else if (notification.type === "new-contact-form") {
			history.push({
				pathname: "/admin/contacto",
				state: { newsLetterId: notification.newsLetterId },
			});
		} else if (notification.type === "new-request-payment") {
			history.push({
				pathname: "/admin/actualizar-pagos",
				state: {
					paymentHistoryId: notification.paymentHistoryId,
					...(notification.check ? { check: notification.check } : notification.denied ? { denied: notification.denied } : {}),
				},
			});
		} else if (notification.type === "new-comment-report") {
			history.push({
				pathname: "/admin/vocabulario",
				state: { reportId: notification.reportId, view: 3, options: "vocabulary" },
			});
		} else if (notification.type === "invoice-reject") {
			history.push({
				pathname: "/facturacion",
			});
		} else if (notification.type === "invoice-accepted") {
			window.location.href = "https://www.gmail.com";
		} else if (notification.type === "invoice-request") {
			history.push({
				pathname: "/admin/invoice",
				state: { invoiceId: notification.invoiceFbId, cat: 2 },
			});
		} else if (notification.type === "invoice-autoGenerated") {
			history.push({
				pathname: "/admin/invoice",
				state: { invoiceId: notification.invoiceFbId, cat: 5 },
			});
		} else if (notification.type === "missing-material") {
			history.push({
				pathname: "/micuenta/grabaciones",
				state: { generationId: notification.generationId, materialId: notification.materialId },
			});
		} else {
			alert("Parece que la publicación ha sido eliminada");
			await deleteDoc(doc(db, "notifications", notification.id));
		}
	};

	function getInitials(name: string) {
		const initials = name.split(" ");
		let fullInitials = "";

		initials.forEach((word) => {
			if (word !== "") {
				fullInitials += word[0];
			}
		});

		return fullInitials[0] + "" + (fullInitials.length > 1 ? fullInitials[1] : "");
	}

	function romanize(num) {
		if (isNaN(num)) return NaN;
		var digits: any = String(+num).split(""),
			key = [
				"",
				"C",
				"CC",
				"CCC",
				"CD",
				"D",
				"DC",
				"DCC",
				"DCCC",
				"CM",
				"",
				"X",
				"XX",
				"XXX",
				"XL",
				"L",
				"LX",
				"LXX",
				"LXXX",
				"XC",
				"",
				"I",
				"II",
				"III",
				"IV",
				"V",
				"VI",
				"VII",
				"VIII",
				"IX",
			],
			roman = "",
			i = 3;
		while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
		return Array(+digits.join("") + 1).join("M") + roman;
	}

	function returnTextToNavigate(type) {
		if (type === "paymentReminder") {
			return "Ver";
		} else if (type === "materialReady" || type === "recordingsReady") {
			return "Ir a materiales";
		} else if (type === "new-request-payment") {
			return "Ver solicitud";
		} else if (type === "new-contact-form") {
			return "Ir a contacto";
		} else if (type === "conektaAutomaticPayment") {
			return "Ver transacción";
		} else if (type === "new-comment-report") {
			return "Ver comentario";
		} else if (type === "invoice-reject") {
			return "Crear nueva solicitud";
		} else if (type === "invoice-accepted") {
			return "Ir a correo";
		} else if (type === "invoice-request") {
			return "Revisar solicitud";
		} else if (type === "invoice-autoGenerated") {
			return "Revisar factura";
		} else if (type === "missing-material") {
			return "Ver material faltante";
		} else {
			return "Ir a la publicación";
		}
	}

	return (
		<>
			{notification.name !== "empty" ? (
				<div
					className={"notification-container" + (notification.seen === false ? " unseen" : "")}
					key={"notification-card-" + notification.id.slice(0, 4)}
					style={{ borderBottom: "1px solid #00000040" }}
				>
					{notification.check &&
						(user.userType === 2 || user.userType === 4) &&
						(notification.type === "new-request-payment" ||
							notification.type === "new-contact-form" ||
							notification.type === "invoice-request") && (
							<img className="notification-check" src={CheckmarIcon} alt="checkmark" />
						)}
					{notification.denied &&
						(user.userType === 2 || user.userType === 4) &&
						(notification.type === "new-request-payment" || notification.type === "invoice-request") && (
							<img className="notification-check" src={CloseCircle} style={{ width: 24, height: 24 }} alt="checkmark" />
						)}
					<div className="title-container">
						{notification.type !== undefined ? (
							<>
								{notification.type === "aprove" && <h3>Respondieron a tu pregunta - (Seminario {romanize(seminaryNum)})</h3>}
								{notification.type === "aproveComentary" && <h3>El administrador aprobó tu comentario</h3>}
								{notification.type === "publication" && (
									<h3>Se hizo una publicación nueva en el seminario {romanize(seminaryNum)}</h3>
								)}
								{notification.type === "editConsult" && <h3>Se editó tu consulta</h3>}
								{notification.type === "consult-new" && (
									<h3>
										Has recibido una nueva consulta en{" "}
										{seminaryNum === 0 ? "técnicas de aprovechamiento" : `el seminario ${romanize(seminaryNum)}`}
									</h3>
								)}
								{notification.type === "aproved-payment" && <h3>Tu solicitud de pago ha sido aprobada</h3>}
								{notification.type === "denied-payment" && <h3>Tu solicitud de pago ha sido rechazada</h3>}
								{notification.type === "didnotUnderstandConsultAnswer" && <h3>Un usuario no entendió tu respuesta</h3>}
								{notification.type === "paymentReminder" && <h3>¡No olvides tu aprendizaje!</h3>}
								{notification.type === "materialReady" && <h3>¡Material ya disponible!</h3>}
								{notification.type === "recordingsReady" && <h3>¡Grabación ya disponible!</h3>}
								{notification.type === "newAnnouncement" && <h3>¡Nuevo aviso!</h3>}
								{notification.type === "missing-material" && <h3>Grabación faltante</h3>}
								{notification.type === "new-contact-form" && <h3>¡Nueva solicitud de contacto!</h3>}
								{notification.type === "new-request-payment" && <h3>¡Nueva solicitud de pago!</h3>}
								{notification.type === "conektaAutomaticPayment" && <h3>Pago verificado</h3>}
								{notification.type === "new-comment-report" && <h3>¡Se ha creado un nuevo comentario!</h3>}
								{notification.type === "invoice-autoGenerated" && <h3>Nueva factura autogenerada</h3>}
								{notification.type === "invoice-request" && <h3>¡Nueva solicitud de facturación!</h3>}
								{(notification.type === "invoice-reject" || notification.type === "invoice-accepted") && (
									<h3>Actualización sobre solicitud de factura.</h3>
								)}
							</>
						) : (
							<h3>{notification.userType === 2 ? "El administrador te ha contestado" : "¡Han respondido a tu publicación!"}</h3>
						)}
					</div>
					<div className="notification-info-container">
						<div className="profile-pic-container notifications-container-page">
							<div className="profile-pic" style={{ background: notification.profileColor }}>
								{notification.userType === 2 ? (
									<img src={Logo2} alt="logo academia de lenguas internacionales" />
								) : (
									<p>{getInitials(notification.name)}</p>
								)}
								{(notification.type === "aprove" ||
									notification.type === "publication" ||
									notification.type === "aproveComentary" ||
									notification.type === "consult-new" ||
									notification.type === "editConsult") && (
									<img className="type-notifications-icon" src={ConsultsIcon} alt="icono de consultas" />
								)}
								{(notification.type === "aproved-payment" ||
									notification.type === "denied-payment" ||
									notification.type === "paymentReminder") && (
									<img className="type-notifications-icon" src={CashIcon} alt="icono de consultas" />
								)}
								{(notification.type === "materialReady" || notification.type === "recordingsReady") && (
									<img className="type-notifications-icon" src={OpenBook2} alt="icono de materiales" />
								)}
								{notification.type === "newAnnouncement" && (
									<img className="type-notifications-icon" src={UserIcon} alt="icono de usuario" />
								)}
								{notification.type === "new-contact-form" && (
									<img className="type-notifications-icon" src={PeopleIcon} alt="icono de usuario" />
								)}
								{notification.type === "new-request-payment" && (
									<img className="type-notifications-icon" src={CashIcon} alt="icono de usuario" />
								)}
								{notification.type === "new-comment-report" && (
									<img className="type-notifications-icon" src={CommentIcon} alt="icono de usuario" />
								)}
								{notification.type?.includes("invoice-") && (
									<img
										className="type-notifications-icon"
										src={InvoiceAdminIcon}
										alt="icono de factura"
										style={{ filter: "invert(1)" }}
									/>
								)}
							</div>
						</div>
						<div className="notification-user-info-contantainer">
							<div className="name-hour-container">
								<p
									className="name-text"
									style={{
										color:
											notification.userType === 2 || notification.name === "Academia de Lenguas Internacionales"
												? "#d32d2d"
												: "#000000",
									}}
								>
									{notification.userType === 2 ? "Academia de Lenguas Internacionales" : notification.name}
								</p>
								<p className="date-text">
									{new Date(notification.date.seconds * 1000).toLocaleDateString("en-gb")} -{" "}
									{new Date(notification.date.seconds * 1000).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })}
								</p>
							</div>
							<div className="text-container">{parse(notification.text)}</div>
						</div>
					</div>
					<div className="link-to-publication-container">
						<a onClick={navigateTo}>
							{returnTextToNavigate(notification.type)} {""}
							<img src={NextArrow} alt="siguiente flecha" />
						</a>
					</div>
				</div>
			) : (
				<div className="notification-container" style={{ display: "flex", alignItems: "center", marginTop: 30, width: 430 }}>
					<h2 style={{ fontSize: "1rem", color: "#000000ba" }}>No tienes notificaciones nuevas</h2>
				</div>
			)}
		</>
	);
}

export default NotificationCard;
