import { addDoc, collection } from "firebase/firestore";
import { db } from "initFirebase";

interface TestLog {
	userId: string;
	createdAt: Date;
	testId: string;
	message: string;
	timezone: string;
}

export const saveTestLog = async (testLog: TestLog) => {
	const docRef = await addDoc(collection(db, "testLogs"), testLog);
	return docRef.id;
};
