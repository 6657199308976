import { LogoBig, ReceiptLine } from "assets/Imports";
import { getPaymentMethodCode } from "functions/Receipt";
import { useEffect, useState } from "react";
import "./Receipt.scss";

export function Receipt(props) {
	return (
		<div className="receipt-main-container">
			<div className="receipt-component" id={"receipt"}>
				<img className="line" src={ReceiptLine} alt="" />
				<div className="header">
					<img src={LogoBig} alt="" />
					<h2>Academia de lenguas internacionales</h2>
				</div>
				<div className="content">
					<div className="left-info">
						<div className="student-info">
							<h3>Estudiante:</h3>
							<h3 className="name">{props.payment.name}</h3>
						</div>
						<div className="total-container">
							<h2>Total:</h2>
							<h2 className="amount">
								$
								{parseInt(props.payment.amount.toString().replace(/\$/g, "").replace(/,/g, ""))
									.toFixed(2)
									.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
								MXN
							</h2>
						</div>
					</div>
					<div className="right-info">
						<div className="receipt-row">
							{props.payment.acceptDate.length > 0 && <b>Fecha de aceptación:</b>}
							<p>{props.payment.acceptDate}</p>
						</div>
						<div className="receipt-row">
							<b>No. de recibo:</b>
							<p>
								{getPaymentMethodCode(props.payment.medio)}-{props.payment.NoReceipt}
							</p>
						</div>
						<div className="receipt-row">
							<b>Fecha de pago:</b>
							<p>{props.payment.createdAt}</p>
						</div>
						<div className="receipt-row">
							<b>Método de pago:</b>
							<p>{props.payment.medio}</p>
						</div>
						<div className="receipt-row">
							<b>Matrícula:</b>
							<p>{props.payment.matricula}</p>
						</div>
					</div>
				</div>
				<div className="details">
					<div className="desglose">
						<div className="quantity">
							<b>Cantidad</b>
							<p>{props.payment.seminaries}</p>
						</div>
						<div className="description">
							<b>Descripción</b>
							<p>{props.payment.description}</p>
						</div>
					</div>
					<div className="amount">
						<div className="unity">
							<b>Precio unitario</b>
							<p>
								$
								{parseInt(props.payment.unityPrice.toString().replace(/\$/g, "").replace(/,/g, ""))
									.toFixed(2)
									.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
								MXN
							</p>
						</div>
						<div className="total">
							<b>Importe</b>
							<p>
								$
								{parseInt(props.payment.amount.toString().replace(/\$/g, "").replace(/,/g, ""))
									.toFixed(2)
									.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
								MXN
							</p>
						</div>
					</div>
				</div>
				<div className="footer">
					<h2>RECIBO DE PAGO</h2>
				</div>
			</div>
		</div>
	);
}
