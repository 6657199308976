import { WordRowCard } from "@components/Vocabulary/WordRowCard/WordRowCard";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import parse from "html-react-parser";
import { getCorrectChars, getCorrectWord } from "../../../functions/TestFunctions";
import { getLangSpanishSimple } from "@components/Vocabulary/PersonalizedTest/functions/functions";
import { capitalizeFirstLetter } from "functions/Functions";

export function InfoModal(props) {
	const disableEdit = true;
	const [words, setWords] = useState(new Array());

	function returnTitle(title: string) {
		switch (title) {
			case "Términos incorrectos":
				if (words.length === 0) {
					return "<span>¡Genial!</span> No tuviste ningún error.";
				} else {
					return title;
				}
				break;
			case "Términos en los que me equivoqué":
				if (words.length === 0) {
					return "<span>¡Genial!</span> No tuviste ningún error.";
				} else {
					return title;
				}
				break;

			default:
				return title;
				break;
		}
	}

	function returnSubtitle(title: string) {
		switch (title) {
			case "Términos incorrectos":
				if (words.length === 0) {
					return "Continúa estudiando para hacerlo algo permanente.";
				} else {
					return title;
				}
				break;
			case "Términos en los que me equivoqué":
				if (words.length === 0) {
					return "Continúa estudiando para hacerlo algo permanente.";
				} else {
					return "Términos";
				}
				break;
			case "¡Felicidades!":
				return "En esta ronda dominaste los siguientes términos";
				break;

			default:
				return "Términos";
				break;
		}
	}

	const getColorWords = async (words) => {
		const tempArray: Array<any> = [];

		await Promise.all(
			words.map(async (word, index) => {
				const answer =
					props.quiz!.answerType === "normal"
						? await getCorrectChars(
								word,
								props.quiz!.answerType,
								props.quiz!.language,
								word.meaning,
								[word.answer!],
								"answer",
								props.subCatColores
						  )
						: props.quiz!.answerType === "selected-lang"
						? await getCorrectChars(
								word,
								props.quiz!.answerType,
								props.quiz!.language,
								props.quiz!.language === "japanese" ? [word.native_word!, word.optional_word] : [word.native_word!],
								[word.answer!],
								"answer",
								props.subCatColores
						  )
						: await getCorrectChars(
								word,
								props.quiz!.answerType,
								props.quiz!.language,
								[word.optional_word !== null ? word.optional_word! : word.native_word!],
								[word.answer!],
								"answer",
								props.subCatColores
						  );

				const data: any = {
					...word,
					answer: answer,
				};

				tempArray.push(data);
			})
		);
		setWords(tempArray);
	};
	useEffect(() => {
		setWords([]);
	}, [props.words]);

	useEffect(() => {
		if (words.length === 0) {
			getColorWords(props.words);
		}
	}, [words, props.words]);

	return (
		<Modal className="modal-quiz-results-info" show={props.show} onHide={props.onHide} centered>
			<Modal.Header>
				<Modal.Title>{parse(returnTitle(props.title))}</Modal.Title>
				{props.title === "Términos en los que me equivoqué" && (
					<>
						<h3>Términos en los que presioné "Acerté".</h3>
						<h3 style={{ color: "#8f8f8f" }}>
							<span className="acerte-word">*</span>(Esta respuesta no afectará tu nivel de dominio)
						</h3>
					</>
				)}
			</Modal.Header>
			<Modal.Body>
				<h3>{returnSubtitle(props.title)}</h3>
				{props.title !== "Términos en los que me equivoqué" ? (
					<div className="d-flex flex-column" style={{ gap: 20 }}>
						{words.map((word, index) => {
							return (
								<WordRowCard
									word={word}
									language={props.quiz.language}
									answerType={props.quiz.answerType}
									key={"word-middle-screen-" + index}
									index={index}
									setCountRefresh={props.setCountRefresh}
									countRefresh={props.countRefresh}
								/>
							);
						})}
					</div>
				) : (
					<div className="container p-0">
						<div className="row flex-nowrap mt-4">
							<div className="col-sm text-center">
								<b>{capitalizeFirstLetter(getLangSpanishSimple(props.quiz.language))}</b>
							</div>
							<div className="col-sm text-center">
								<b>Mi respuesta</b>
							</div>
							<div className="col-sm text-center">
								<b>Significado</b>
							</div>
						</div>
						{words.length > 0 &&
							words.map((word, index) => {
								return (
									<div className="row flex-nowrap m-0 mt-4" key={"errors-words-rows-" + index}>
										<div className="col-sm text-center p-0">
											<p>{word.native_word}</p>
										</div>
										<div className="col-sm text-center p-0">
											<p>
												<b>
													{parse(word.answer)}
													{props.quiz.acerteWords.findIndex((e) => e.id === word.id) !== -1 && (
														<span className="acerte-word">*</span>
													)}
												</b>
											</p>
										</div>
										<div className="col-sm text-center p-0">
											<p className="m-0">{getCorrectWord("normal", word)}</p>
										</div>
									</div>
								);
							})}
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				<button className="action-btn linear-bg" onClick={props.onHide}>
					Aceptar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
