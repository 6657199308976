import SpeiRef from "@containers/SpeiRef/SpeiRef";

function SpeiRefScreen() {
	return (
		<div style={{ overflow: "hidden" }}>
			<SpeiRef />
		</div>
	);
}

export default SpeiRefScreen;
