import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EditCategoryModal from "./Components/EditCategoryModal/EditCategoryModal";
import NewColorCategory from "./Components/NewColor/NewColorCategory";
import "./VocabularyCategoriesStyles.scss"

interface subCategory {
    index?: number;
    subcategory: string;
    color: string;
}

function VocabularyCategories(props) {

    const [modalShow, setModalShow] = useState<boolean>(false);
    const [newColorShow, setNewColorShow] = useState<boolean>(false);
    const history = useHistory();
    const [subCategories, setSubCategories] = useState<Array<subCategory>>(new Array());
    const [editSubCategory, setEditSubCategory] = useState({ index: 0, subcategory: "", color: "" })
    const subCategoriesRef = doc(db, "dictionaries", "subcategories");

    const getSubCategories = async () => {

        const snap = await getDoc(subCategoriesRef);

        if (snap.exists()) {
            setSubCategories(snap.data().subcategories);
        }
    }

    const edit = (subCat: subCategory, index: number) => {
        setEditSubCategory({
            index: index,
            subcategory: subCat.subcategory,
            color: subCat.color
        });
        setModalShow(true);
    }

    const save = async (subcat: subCategory) => {
        let subcategoriesTemp = JSON.parse(JSON.stringify(subCategories));
        if (subcat.index !== undefined) {
            subcategoriesTemp[subcat.index].color = subcat.color;
            await updateDoc(subCategoriesRef, {
                subcategories: subcategoriesTemp
            }).then(() => {
                setSubCategories(subcategoriesTemp);
                setEditSubCategory({ index: 0, subcategory: "", color: "" });
                setModalShow(false);
            })
        }
    }

    async function newColor(newSubcat: subCategory) {
        let subcategoriesTemp = JSON.parse(JSON.stringify(subCategories));
        subcategoriesTemp.push(newSubcat);
        await updateDoc(subCategoriesRef, {
            subcategories: subcategoriesTemp
        }).then(() => {
            setSubCategories(subcategoriesTemp);
            return true;
        })
    }

    useEffect(() => {
        getSubCategories();
    }, []);

    return (
        <>
            <NewColorCategory show={newColorShow} onHide={() => setNewColorShow(false)} newColor={newColor} />
            <EditCategoryModal show={modalShow} onHide={() => setModalShow(false)} subcategory={editSubCategory} save={save} />
            <div className="vocabulary-categories-main-container">
                <div className="goback-btn-container">
                    <button
                        className="goback-btn"
                        onClick={() => history.push("/admin/vocabulario")}
                    >
                        Atrás
                    </button>
                </div>
                <div className="title-container">
                    <h2>Subategorías</h2>
                    <button
                        className="action-btn linear-bg"
                        onClick={() => setNewColorShow(true)}
                    >
                        Nuevo color
                    </button>
                </div>
                <div className="line"></div>
                <div className="categories-cards-container">
                    {subCategories.length > 0 && subCategories.map((sub, index) => {
                        return (
                            <div className="category-card" key={"sub-category-card-" + index}>
                                <h2 style={{ color: sub.color }}>{sub.subcategory}</h2>
                                <button
                                    className="goback-btn"
                                    onClick={() => edit(sub, index)}
                                >
                                    Editar color
                                </button>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
}

export default VocabularyCategories;