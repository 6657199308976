import { collection, getDocs, query } from "firebase/firestore";
import { db } from "initFirebase";
const subLocation = ["location", "main", "navbar", "video", "kids"];

export async function retreiveDynamicVideoVimeo() {
	const q = query(collection(db, "dynamic_copies"));
	const querySnapshot = await getDocs(q);
	const dynamicText: any = [];
	const videoData: any = [];
	querySnapshot.forEach((doc) => {
		dynamicText.push({ id: doc.id, ...doc.data() });
	});
	const landing = dynamicText.filter((dyn) => dyn.name.includes("landing"));
	await Promise.all(
		subLocation.map(async (element) => {
			const querySnapshot2 = await getDocs(collection(db, "dynamic_copies", landing[0].id, element));
			if (element === "video") {
				querySnapshot2.forEach((doc2) => {
					if (doc2.data().type === "vimeo") {
						videoData.push({ ...doc2.data() });
					}
				});
			}
		})
	);
	if (videoData[0].titulo1) {
		return videoData[0].titulo1;
	} else {
		return "https://www.youtube.com/embed/Drp4G3YGTAE";
	}
}
