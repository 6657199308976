import { bookmarkWord } from "@api/Vocabulary";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getWord } from "../../functions/TestFunctions";
import "./NoteMarkStyles.scss";


export function NoteMark(props) {

    const [note, setNote] = useState<string>("");

    const markWork = (wordId: number) => {
        bookmarkWord(wordId, note)
            .then(res => {
                alert("¡Palabra marcada con éxito!");
                props.isMarked(wordId);
                setNote("");
                props.onHide();
            })
            .catch(error => {
                console.log(error)
            })
    }

    const closeModal = () => {
        setNote("");
        props.onHide();
    }

    useEffect(() => {
		const handleKeyDown = (e) => {

            if (e.key === "Enter") {
				if (props.show === true) {

					const element = document.getElementById("note-mark");
					if (element !== null) {
						element.click();
					}
                }
            }
		}
        if(props.show) {
            window.addEventListener('keydown', handleKeyDown);
        }


		return function cleanUpListener() {
			window.removeEventListener("keydown", handleKeyDown);
		}
	}, [props.show])
    return (
        <Modal
            show={props.show}
            onHide={closeModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="bookmark-word-note-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Marcar término para revisarla
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label htmlFor="bookmark-note">Nota</label>
                <div className="input-container">
                    <input
                        type="text"
                        name="bookmark-note"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        placeholder="Nota a revisar"
                        id="bookmark-note"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex">
                <button className="action-btn secondary-btn-bg" onClick={closeModal}>Cancelar</button>
                <button id="note-mark" className="action-btn linear-bg" onClick={() => markWork(props.wordId)}>Marcar</button>
            </Modal.Footer>
        </Modal>
    );
}