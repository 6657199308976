import { useCurrencyContext } from "contexts/CurrencyContext";
import "./styles.scss";

interface RedBoxCopieProps {
	price: any;
	copie: any;
}

function RedBoxCopie(props: RedBoxCopieProps) {
	const { currencyCountry, prices } = useCurrencyContext();
	const priceText = currencyCountry === "mx" ? props.price : `$${prices?.USD.online.regularClass} USD`;
	return (
		<>
			<div className="redbox__container d-none d-lg-block ">
				<h3>
					<b>{priceText}</b>
				</h3>
				<p>{props.copie}</p>
			</div>
			<div className="redbox-sm__container d-lg-none">
				<h3>
					<b>{priceText}</b>
				</h3>
				<p>{props.copie}</p>
			</div>
		</>
	);
}

export default RedBoxCopie;
