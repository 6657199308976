import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import InputMask from "react-input-mask";
import { CardsIcons } from "assets/Imports";
import { httpsCallable } from "firebase/functions";
import { functions } from "initFirebase";
import { useAuth } from "@hooks/useAuth";

const conektaPB = process.env.REACT_APP_CONEKTA_PUBLIC_KEY || "";

type CardDetailsT = {
	card: {
		number: string;
		name: string;
		exp_year: string;
		exp_month: string;
		cvc: string;
	};
};

function NewCard({ getCards }) {
	const { user } = useAuth();
	const [newCard, setNewCard] = useState(false);
	const [validated, setValidated] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const handleSubmit = async (e) => {
		const form = e.currentTarget;
		e.preventDefault();

		if (form.checkValidity() === false) {
			e.stopPropagation();
			setValidated(true);
		} else {
			setLoadingSubmit(true);
			const cardDetails = {
				card: {
					number: form.cardNumber.value,
					name: form.cardName.value,
					exp_year: form.exp_year.value,
					exp_month: form.exp_month.value,
					cvc: form.cvc.value,
				},
			};

			window.Conekta.Token.create(cardDetails, successCallback, errorCallback);
		}
	};

	const errorCallback = (data) => {
		alert("Ha habido un error, por favor verifica los datos de la tarjeta ingresada");
		setLoadingSubmit(false);
	};

	const successCallback = async (token) => {
		const tryNewCard = httpsCallable(functions, "newCard");
		tryNewCard({
			customerId: user.conektaId,
			token_id: token.id,
		})
			.then((res) => {
				setNewCard(false);
				getCards();
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setLoadingSubmit(false);
			});
	};

	useEffect(() => {
		if (window.Conekta) {
			window.Conekta.setPublicKey(conektaPB);
		}
	}, []);

	return (
		<>
			{!newCard ? (
				<div className="card-container new-card-container" onClick={() => setNewCard(true)}>
					<div style={{ display: "flex", alignItems: "center" }}>
						<p>Agregar nuevo método de pago</p>
					</div>
				</div>
			) : (
				<div className="new-card-form-container">
					<img style={{ marginBottom: "20px" }} src={CardsIcons} />
					<Form noValidate validated={validated} onSubmit={handleSubmit}>
						<Row className="input-row">
							<Col className="w-100 p-0">
								<Form.Group className="mb-3" controlId="formBasicName">
									<Form.Label>
										<b>Nombre del titular de la tarjeta</b>
									</Form.Label>
									<Form.Control
										required
										type="text"
										name="cardName"
										placeholder="Luke Skywalker"
										data-conekta="card[name]"
									/>
									<Form.Control.Feedback type="invalid">
										Por favor ingresa un nombre.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row className="input-row">
							<Col className="w-100 p-0">
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<Form.Label>
										<b>Correo electrónico</b>
									</Form.Label>
									<Form.Control
										required
										type="text"
										name="email"
										placeholder="ejemplo@ejemplo.com"
									/>
									<Form.Control.Feedback type="invalid">
										Por favor ingresa un número de tarjeta válido.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row className="input-row">
							<Col className="w-100 p-0">
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<Form.Label>
										<b>Número de tarjeta</b>
									</Form.Label>
									<InputMask
										required
										className="form-control"
										type="text"
										name="cardNumber"
										placeholder="4444 2222 3333 8888"
										mask="9999 9999 9999 9999 999"
										maskChar=""
									></InputMask>

									<Form.Control.Feedback type="invalid">
										Por favor ingresa un número de tarjeta válido.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row className="input-row">
							<Col className="w-100 pr-3 pl-0" style={{ display: "flex", alignItems: "flex-end" }}>
								<Form.Group className="mb-3" controlId="formBasicPhone">
									<Form.Label>
										<b>Mes de expiración</b>
									</Form.Label>
									<Form.Control
										required
										type="number"
										name="exp_month"
										placeholder="09"
										data-conekta="card[exp_month]"
									/>
									<Form.Control.Feedback type="invalid">
										Por favor ingresa un número de 2 dígitos.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col
								className="w-100 pl-3 pr-3"
								style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}
							>
								<Form.Group className="mb-3" controlId="formBasicPhone">
									<Form.Label>
										<b>Año de expiración</b>
									</Form.Label>
									<Form.Control
										required
										type="number"
										name="exp_year"
										placeholder="25"
										data-conekta="card[exp_year]"
									/>
									<Form.Control.Feedback type="invalid">
										Porf avor ingresa un número de 2 dígitos.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col
								className="w-100 pl-3 pr-0"
								style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}
							>
								<Form.Group className="mb-3" controlId="formBasicPhone">
									<Form.Label>
										<b>CVC</b>
									</Form.Label>
									<Form.Control
										required
										type="text"
										maxLength={4}
										name="cvc"
										placeholder="345"
										data-conekta="card[cvc]"
									/>
									<Form.Control.Feedback type="invalid">
										Por favor, ingresa un número de 3 o 4 dígitos.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								{!loadingSubmit ? (
									<button type="submit" className="tertiary add-btn-card return-btn-style">
										Añadir
									</button>
								) : (
									<div
										className="spinner-border text-danger"
										role="status"
										style={{ marginBottom: "1rem", float: "right", marginRight: 105 }}
									>
										<span className="sr-only">Loading...</span>
									</div>
								)}
							</Col>
						</Row>
					</Form>
				</div>
			)}
		</>
	);
}

export default NewCard;
