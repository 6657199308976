import { getDateData } from "@api/StudiedWords";
import { SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import { getLangSpanishSimple } from "@components/Vocabulary/PersonalizedTest/functions/functions";
import { useAuth } from "@hooks/useAuth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { capitalizeFirstLetter, returnFlagByLanguage } from "functions/Functions";
import { db } from "initFirebase";
import { dateData, dayData, dayInfoModalInterface } from "interfaces/StreakCalendar";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiFillMinusCircle } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import "./DayInfoModal.scss";
import { RocketIcon } from "assets/Imports";

interface dayInfoModalProps {
	show: boolean;
	setDayInfoModal: React.Dispatch<React.SetStateAction<dayInfoModalInterface>>;
	dayData: dayData | null;
	userProfile: { id: string; vocabularyUserId: number };
}

export function DayInfoModal(props: dayInfoModalProps) {
	const [dayInfo, setDayInfo] = useState<dateData | null>(null);
	const [quizzes, setQuizzes] = useState<Array<any> | null>(null);
	const [createdQuizzes, setCreatedQuizzes] = useState<Array<any> | null>(null);
	const [fold1, setFold1] = useState<boolean>(false);
	const [fold2, setFold2] = useState<boolean>(false);
	const updateDate = new Date("03-14-2023");
	updateDate.setHours(0);
	updateDate.setMinutes(0);
	updateDate.setSeconds(0);

	const userTemp = useAuth().user;
	const user = props.userProfile ?? userTemp;

	const onHide = () => {
		props.setDayInfoModal({ show: false, dayData: null });
		setDayInfo(null);
		setQuizzes(null);
	};

	const getDayData = async () => {
		if (props.dayData !== null) {
			await getDateData(props.dayData.dateString.replace(/\//g, "-"), user.vocabularyUserId).then((res) => {
				setDayInfo(res.dateInfo);
				//console.log("data",res);
			});

			const initDate = props.dayData.date;
			const finishDate = new Date(props.dayData.dateString);
			finishDate.setHours(23);
			finishDate.setMinutes(59);
			finishDate.setSeconds(59);

			const q = query(
				collection(db, "quiz"),
				where("userId", "==", user.id),
				where("finished", "==", true),
				where("finishedAt", ">=", initDate),
				where("finishedAt", "<=", finishDate)
			);

			const snap = await getDocs(q);

			const q2 = query(
				collection(db, "quiz"),
				where("userId", "==", user.id),
				where("createdAt", ">=", initDate),
				where("createdAt", "<=", finishDate)
			);

			const snap2 = await getDocs(q2);

			const tempArray: Array<any> = [];
			snap.docs.map((doc) => {
				const data = {
					id: doc.id,
					...doc.data(),
				};
				tempArray.push(data);
			});

			const tempArray2: Array<any> = [];
			snap2.docs.map((doc) => {
				const data = {
					id: doc.id,
					...doc.data(),
				};
				tempArray2.push(data);
			});

			setQuizzes(tempArray);
			setCreatedQuizzes(tempArray2);
		}
	};
	useEffect(() => {
		if (props.dayData !== null) {
			getDayData();
		}
	}, [props.dayData]);

	return (
		<Modal className="day-info-modal-main-container" show={props.show} onHide={onHide} size="lg">
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.dayData?.date.toLocaleString("es", { weekday: "long", day: "numeric", month: "long", year: "numeric" })}
				</Modal.Title>

				<AiFillMinusCircle className="close-modal" onClick={onHide} />
			</Modal.Header>
			<Modal.Body>
				{dayInfo === null || quizzes === null || createdQuizzes === null ? (
					<SpinnerCustom height={200} />
				) : (
					<div className="info-container">
						{props.dayData?.date! > updateDate && (
							<>
								<div className="info-row">
									<b>Tiempo total de estudio:</b>
									<p>
										{Math.ceil(dayInfo.studiedTime / 60).toFixed(0)}{" "}
										{Math.ceil(dayInfo.studiedTime / 60) === 1 ? "minuto" : "minutos"}
									</p>
								</div>
								<div className="info-row">
									<b>Total de términos estudiados:</b>
									<p>{dayInfo.studiedWords}</p>
								</div>
								<div className="info-row">
									<b>Total de términos dominados:</b>
									<p>{dayInfo.dominatedWords}</p>
								</div>
								<div className="info-row">
									<b>Idioma más estudiado:</b>
									<p>{capitalizeFirstLetter(getLangSpanishSimple(dayInfo.mostStudiedLang))}</p>
								</div>
							</>
						)}
						<div className="info-row quizzes">
							<button className="quizzes-btn" onClick={() => setFold1(!fold1)}>
								<b>Rondas de estudio creadas:</b>
								<IoIosArrowDown style={!fold1 ? { transform: "rotate(-90deg)" } : {}} />
							</button>
							{createdQuizzes.length > 0 && fold1
								? createdQuizzes.map((quiz, i) => {
										return (
											<div className="date-info-quiz-card" key={"date-info-quiz-card-" + i}>
												{returnFlagByLanguage(quiz.language)}
												<p>{quiz.name.replace(/-/g, "‑")}</p> {/*This prevents the text from breaking at the hyphen*/}
												{quiz?.default === true ? <img className="rocket" src={RocketIcon} /> : ""}
											</div>
										);
								  })
								: fold1 && <p>Sin registro</p>}
						</div>
						<div className="info-row quizzes">
							<button className="quizzes-btn" onClick={() => setFold2(!fold2)}>
								<b>Rondas de estudio finalizadas:</b>
								<IoIosArrowDown style={!fold2 ? { transform: "rotate(-90deg)" } : {}} />
							</button>
							{quizzes.length > 0 && fold2
								? quizzes.map((quiz, i) => {
										return (
											<div className="date-info-quiz-card" key={"date-info-quiz-card-" + i}>
												{returnFlagByLanguage(quiz.language)}
												<p>{quiz.name.replace(/-/g, "‑")}</p> {/*This prevents the text from breaking at the hyphen*/}
												{quiz?.default === true ? <img className="rocket" src={RocketIcon} /> : ""}
											</div>
										);
								  })
								: fold2 && <p>Sin registro</p>}
						</div>
					</div>
				)}
				<div></div>
			</Modal.Body>
		</Modal>
	);
}
