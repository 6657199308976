import { getAllWords, getUsers } from "@api/Vocabulary";
import { useEffect, useState } from "react";
import { Modal, Table, Form, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import "./VocabularyGenerationsStyles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft, faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "initFirebase";
import { capitalizeFirstLetter } from "functions/Functions";
import { StreakModal } from "@containers/VocabularySeminaryContainer/components/Test/components/StreakModal/StreakModal";
import { Check, LENLogo, StreakFlameIcon } from "assets/Imports";
import { StreakFlame } from "@containers/VocabularySeminaryContainer/components/Test/components/StreakModal/components/StreakFlame";
import { useStreak } from "@hooks/useStreak";

function VocabularyGenerationContainer() {
	const [modal, setModal] = useState<boolean>(false);
	const handleClose = () => setModal(false);
	const { generationId } = useParams<any>();
	const [generation, setGeneration] = useState<any>();
	const [user, setUser] = useState<any>();
	const [users, setUsers] = useState<Array<any>>(new Array());
	const [usersBackup, setUsersBackup] = useState<Array<any>>(new Array());
	const [words, setWords] = useState<Array<any>>(new Array());
	const [loading, setLoading] = useState<any>(false);
	const [page, setPage] = useState<any>(0);
	const [streakModal, setStreakModal] = useState<boolean>(false);
	const [sortBy, setSortBy] = useState<string>("name");
	const history = useHistory();
	const { setUserId, vocabularyStreak, isLoading, studiedDays } = useStreak();

	const getWords = () => {
		getAllWords().then((res) => {
			setWords(res.words);
		});
	};

	const sorts = {
		lastQuizStudy: compareLastQuizStudy,
		name: compareNameAsc,
		lastSeen: compareLastSeen,
		dominated: compareDominated,
		studied: compareStudied,
	};

	const getGeneration = async () => {
		const GenDocRef = doc(db, "generations", generationId);
		const GenDocSnap = await getDoc(GenDocRef);
		setGeneration(GenDocSnap.data());
		if (!loading) {
			getWords();
			let temp_users: any = [];
			const userDocRef = query(collection(db, "users"), where("generationId", "==", GenDocSnap.id));
			const usersQuerySnapshot = await getDocs(userDocRef);
			await Promise.all(
				usersQuerySnapshot.docs.map((user) => {
					temp_users.push({
						name: user.data().name,
						vocabularyStreak:
							user.data().vocabularyStreak !== undefined ? user.data().vocabularyStreak : 0,
						studiedDays: user.data().studiedDays === undefined ? [] : user.data().studiedDays,
						id: user.id,
						words: [],
						dominated: [],
						no_dominated: [],
						pages: 0,
						sections: [],
						uniqueWords: [],
						lastQuizStudy: user.data().lastQuizStudy,
						lastQuizStudySeconds:
							user.data().lastQuizStudy !== undefined
								? user.data().lastQuizStudy.seconds
								: undefined,
						lastSeen: user.data().lastSeen,
						lastSeenSeconds:
							user.data().lastSeen !== undefined ? user.data().lastSeen.seconds : undefined,
					});
				})
			);
			await Promise.all(
				temp_users.map(async (user: any) => {
					await getUsers(user.id).then(async (res) => {
						let users: any = [];
						users = res.user;
						if (users) {
							await Promise.all(
								users.map(async (element: any) => {
									if (element.progress == 3) {
										user.dominated.push(element.progress);
									}
									if (element.progress < 3) {
										user.no_dominated.push(element.progress);
									}
									user.words.push({ word: element.native_word, progress: element.progress });
									if (user.uniqueWords.findIndex((e) => e === element.native_word) === -1) {
										user.uniqueWords.push(element.native_word);
									}
								})
							);

							user.pages = Math.ceil(user.uniqueWords.length / 18);
							for (let i = 0; i < user.pages; i++) {
								user.sections.push([]);
							}
							let total = 18;
							let count = 0;
							for (let i = 0; i < user.pages; i++) {
								for (let w = 0; w <= user.words.length; w++) {
									if (w >= count && user.sections[i].length < total) {
										if (
											user.words[w] &&
											user.sections[i].findIndex((e) => e === user.words[w].word) === -1
										)
											user.sections[i].push(user.words[w]?.word);
										count++;
									}
								}
							}
						}
					});
				})
			);
			setUsers(temp_users.sort(sorts[sortBy]));
			setUsersBackup(temp_users.sort(sorts[sortBy]));
		}

		setLoading(true);
	};

	function compareNameAsc(a, b) {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	}

	function compareLastQuizStudy(a, b) {
		if (a.lastQuizStudySeconds > b.lastQuizStudySeconds || b.lastQuizStudySeconds === undefined) {
			return -1;
		}
		if (a.lastQuizStudySeconds < b.lastQuizStudySeconds || a.lastQuizStudySeconds === undefined) {
			return 1;
		}
		return 0;
	}
	function compareLastSeen(a, b) {
		if (a.lastSeenSeconds > b.lastSeenSeconds || b.lastSeenSeconds === undefined) {
			return -1;
		}
		if (a.lastSeenSeconds < b.lastSeenSeconds || a.lastSeenSeconds === undefined) {
			return 1;
		}
		return 0;
	}
	function compareDominated(a, b) {
		if (a.dominated.length > b.dominated.length || b.dominated.length === undefined) {
			return -1;
		}
		if (a.dominated.length < b.dominated.length || a.dominated.length === undefined) {
			return 1;
		}
		return 0;
	}
	function compareStudied(a, b) {
		if (a.words.length > b.words.length || b.words.length === undefined) {
			return -1;
		}
		if (a.words.length < b.words.length || a.words.length === undefined) {
			return 1;
		}
		return 0;
	}

	const changePage = (direction) => {
		let page_temp = page + 1;
		if (direction == "left" && page_temp !== 1) setPage(page - 1);
		if (direction == "right" && page_temp < user?.pages) setPage(page + 1);
	};

	function getTitle(name) {
		let splitName = name.split(" - ");
		return splitName[0];
	}

	const searchText = (e) => {
		let user_temp;
		user_temp = usersBackup.filter(
			(user) => user.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
		);
		setUsers(user_temp);
	};

	useEffect(() => {
		getGeneration();
	}, [loading]);

	useEffect(() => {
		setUsers([]);
		// setTimeout(())
		const timeout = setTimeout(() => {
			setUsers(JSON.parse(JSON.stringify(usersBackup)).sort(sorts[sortBy]));
		}, 100);

		return () => {
			clearTimeout(timeout);
		};
	}, [sortBy]);

	useEffect(() => {
		if (user) {
			setUserId(user.id);
		}
	}, [user]);

	return (
		loading && (
			<div className="Vocabulary-generation-admin">
				<div>
					<button
						className="goback-btn"
						onClick={() =>
							history.push({
								pathname: "/admin/vocabulario",
								state: { view: 2, options: "generations" },
							})
						}
					>
						Atrás
					</button>
				</div>
				<h1 className="title">{capitalizeFirstLetter(getTitle(generation.name))}</h1>
				<div className="generations-container">
					<div className="filter-content">
						<p>{users.length} estudiantes</p>
						<div className="filter-input">
							<img src={LENLogo} alt="" />
							<input type="text" placeholder="Buscar estudiante" onChange={searchText} />
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th scope="col" style={{ cursor: "pointer" }} onClick={() => setSortBy("name")}>
									Nombre
								</th>
								<th
									className="text-center"
									scope="col"
									style={{ cursor: "pointer" }}
									onClick={() => setSortBy("lastQuizStudy")}
								>
									Última vez que estudió
								</th>
								<th scope="col" style={{ cursor: "pointer" }} onClick={() => setSortBy("studied")}>
									Términos estudiados
								</th>
								<th
									scope="col"
									style={{ cursor: "pointer" }}
									onClick={() => setSortBy("dominated")}
								>
									Términos dominados
								</th>
								<th
									className="text-center"
									scope="col"
									style={{ cursor: "pointer" }}
									onClick={() => setSortBy("lastSeen")}
								>
									Última actividad
								</th>
								<th scope="col"></th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{users.map((user, index) => {
								return (
									<tr key={"user-" + index}>
										<td>{user.name}</td>
										<td className="text-center">
											{user.lastQuizStudy !== undefined ? (
												<span>
													{new Date(user.lastQuizStudy.seconds * 1000).toLocaleDateString("en-gb")}{" "}
													<br />{" "}
													{new Date(user.lastQuizStudy.seconds * 1000).toLocaleTimeString("en-US", {
														hour: "2-digit",
														minute: "2-digit",
													})}
												</span>
											) : (
												"Sin registro"
											)}
										</td>
										<td>{user.words?.length} términos</td>
										<td>{user.dominated?.length} términos</td>
										<td className="text-center">
											{user.lastSeen !== undefined ? (
												<span>
													{new Date(user.lastSeen.seconds * 1000).toLocaleDateString("en-gb")}{" "}
													<br />{" "}
													{new Date(user.lastSeen.seconds * 1000).toLocaleTimeString("en-US", {
														hour: "2-digit",
														minute: "2-digit",
													})}
												</span>
											) : (
												"Sin registro"
											)}
										</td>
										<td>
											<img
												src={Check}
												alt=""
												onClick={() => {
													setUser(user);
													setModal(!modal);
												}}
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<Modal className="modal-vocabulary-admin-gen" show={modal} onHide={handleClose}>
					{/* {user !== undefined && <StreakModal show={streakModal} onHide={() => setStreakModal(false)} vocabularyStreak={user.vocabularyStreak} />} */}
					<Modal.Header>
						<Modal.Title className="d-flex justify-content-between w-100 align-items-end">
							{user?.name}
							{console.log(user)}
							{user !== undefined && (
								<div className="streak-container ml-auto" onClick={() => setStreakModal(true)}>
									<StreakFlame
										user={user}
										vocabularyStreak={vocabularyStreak}
										isLoading={isLoading}
										studiedDays={studiedDays}
									/>
									{/* <img className="streak-icon" src={StreakFlameIcon} alt="icono de fuego" />
									<h2>{user.vocabularyStreak.length}</h2> */}
								</div>
							)}
						</Modal.Title>
						<p>Reporte de vocabulario</p>
					</Modal.Header>
					<Modal.Body>
						<div className="progress-container">
							<h1>Puntaje de la ronda</h1>
							<div className="progess-user-container">
								<div className="progress-row">
									<label>Términos contestados</label>
									<div className="flex">
										<div className="progress-bar-bg">
											<div
												className="progress-bar-fill"
												style={{ width: `${(user?.words.length * 100) / words.length}%` }}
											></div>
										</div>
										<p>{user?.words?.length}</p>
									</div>
								</div>
								<div className="progress-row">
									<label>Términos dominados</label>
									<div className="flex">
										<div className="progress-bar-bg">
											<div
												className="progress-bar-fill"
												style={{ width: `${(user?.dominated.length * 100) / words.length}%` }}
											></div>
										</div>
										<p>{user?.dominated?.length}</p>
									</div>
								</div>
								<div className="progress-row">
									<label>Términos no dominados</label>
									<div className="flex">
										<div className="progress-bar-bg">
											<div
												className="progress-bar-fill"
												style={{ width: `${(user?.no_dominated.length * 100) / words.length}%` }}
											></div>
										</div>
										<p>{user?.no_dominated.length}</p>
									</div>
								</div>
							</div>
							<div className="words-container">
								<div className="top-section-pagination">
									<h1>Términos aprendidos</h1>
									<div className="pagination">
										<FontAwesomeIcon
											icon={faAngleLeft as any}
											onClick={() => {
												changePage("left");
											}}
										/>
										<p>
											Ver más términos ({page + 1}/{user?.pages})
										</p>
										<FontAwesomeIcon
											icon={faAngleRight as any}
											onClick={() => {
												changePage("right");
											}}
										/>
									</div>
								</div>
								{user?.sections.map((section, index) => {
									return (
										page == index && (
											<div className="words-table">
												{section.map((word) => {
													return <p className="word">{word}</p>;
												})}
											</div>
										)
									);
								})}
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<button onClick={handleClose}>Entendido</button>
					</Modal.Footer>
				</Modal>
			</div>
		)
	);
}
export default VocabularyGenerationContainer;
