import "./StreakModalStyles.scss";
import Calendar from "react-calendar";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "react-day-picker/dist/style.css";
import es from "date-fns/locale/es";
import { DayPicker } from "react-day-picker";
import { DayInfoModal } from "./components/DayInfoModal";
import { dayInfoModalInterface } from "interfaces/StreakCalendar";

export function StreakModal(props) {

	return (
		<Modal
			className="streak-modal-main-container"
			show={props.show}
			onHide={props.onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			{props.children}
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Días de estudio</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					{props.value ? (
						<DayPicker showOutsideDays locale={es} mode="multiple" selected={props.value} onDayClick={props.onDayClick} />
					) : (
						<DayPicker locale={es} />
					)}
				</div>
			</Modal.Body>
			<Modal.Footer className="justify-content-center">
				<button className="action-btn linear-bg p-2" onClick={props.onHide}>
					Cerrar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
