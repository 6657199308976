import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "initFirebase";
import emailjs from 'emailjs-com';


export default function SendEmailContainer() {

    const [users, setUsers]: any = useState([]);


    // const getUsers = async () => {
    //     let usersToSendEmail: any = [];

    //     const q = query(collection(db, "users"), where("generationId", "==", 'oZUxX1S5u9R0965Oqb0y'));

    //     const querySnapshot = await getDocs(q);

    //     let index = 0;
    //     querySnapshot.forEach(async (user: any) => {
    //         // doc.data() is never undefined for query doc snapshots
    //         const qry = query(collection(db, 'paymentHistory'), where('userId', '==', user.id), where('verified', '==', true));
    //         const qrySnapshot = await getDocs(qry);

    //         let i = 0;
    //         let total = 0;

    //         qrySnapshot.forEach(async (doc) => {
    //             i++;
    //             total = total + parseInt(doc.data().amount);
    //             if (i === qrySnapshot.size) {
    //                 //sesiones pagadas, sesion actual,
    //                 if ((total === 12000 && qrySnapshot.size === 1) || user.promotion === true) {
    //                     total = 18000;
    //                 }

    //                 if (total < 3300) {
    //                     usersToSendEmail.push({ id: user.id, ...user.data(), totalPaid: total });
    //                 }

    //             }
    //         })
    //         if (qrySnapshot.size === 0 && user.promotion === true || user.userType === 2) {
    //             total = 18000;
    //         } else if (qrySnapshot.size === 0) {
    //             total = 0;
    //             usersToSendEmail.push({ id: user.id, ...user.data(), totalPaid: total });
    //         }
    //         index++;
    //         if (index === querySnapshot.size) {
    //             setUsers(usersToSendEmail);
    //         }
    //     });
    // }

    // const send = () => {

    //     users.forEach(async (user) => {
    //         await emailjs.send('service_1kfzhtl', 'template_33c8s4e', { email: user.email }, 'user_PPI0O8nofs9cbuJ3JRWlT').then(function (res) {
    //         }, function (error) {
    //         });
    //     });

    // }

    useEffect(() => {
        // getUsers();
    }, [])

    return (
        <>
            <h1>Send email</h1>
            {/* <button onClick={send}>Enviar</button> */}
            {/* {users.map((user, index) => {
                return (
                    <p>{user.name} f {user.totalPaid}</p>
                )
            })} */}
        </>
    );
}