import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "initFirebase";
import Select from "react-select";
import { getMatricula } from "functions/Functions";
import {
	collaboratorGen,
	collaborator,
	user,
	userGen,
	newGen,
	oldGen,
	useCollaboratorLogs,
} from "@hooks/collaborators/useCollaboratorLogs";
import { getGenerationNameById } from "@services/GenerationService";
import { isGenInPerson } from "interfaces/Generations";

export function NewUserModal(props) {
	const [users, setUsers]: any = useState([]);
	const [userSelected, setUserSelected] = useState("");
	const { genLog } = useCollaboratorLogs();

	const getUsers = async () => {
		let studentsArray: any = [];
		const q = query(collection(db, "users"), where("generationId", "!=", props.gen.id));

		const querySnapshot = await getDocs(q);
		let i = 0;
		querySnapshot.forEach((doc) => {
			const data2 = {
				label: doc.data().name + " - " + doc.data().email,
				value: doc.id,
			};
			studentsArray.push(data2);
			i++;
			if (i === querySnapshot.size) {
				setUsers(studentsArray);
			}
		});
	};

	const handleSelection = (e) => {
		setUserSelected(e.value);
	};

	useEffect(() => {
		getUsers();
	}, [props.refresh]);

	const save = async () => {
		let collaborator: collaboratorGen;
		let userToAssign: userGen;
		let oldGen: oldGen;
		let newGen: newGen = { id: props.gen.id, name: props.gen.name };
		if (props.user) {
			collaborator = {
				name: props.user.name,
				id: props.user.id,
				role: props.user.collaborator_rol ?? "",
				email: props.user.email,
			};
		}
		
		const userRef = doc(db, "users", userSelected);

		const snap = await getDoc(userRef);
		let user = snap.data();
		if (user && props.user) {
			userToAssign = { id: snap.id, name: user.name, email: user.email };
			if (user.generationId) {
				let tempGenName = await getGenerationNameById(user.generationId);
				oldGen = { id: user.generationId, name: tempGenName ?? "" };
			}
		}

		const splitGenDate = props.gen.initialDate.split("/");
		const date = new Date();
		const q = query(collection(db, "users"), where("generationId", "==", props.gen.id));
		const querySnapshot = await getDocs(q);

		let num = querySnapshot.size + 1;

		let matricula =
			snap.data()!.matricula === "" ? await getMatricula(num, props.gen.sede, splitGenDate) : snap.data()!.matricula;

		await updateDoc(userRef, {
			generationId: props.gen.id,
			matricula: matricula,
			schedule: props.gen.schedule,
			addedAt: new Date(),
		}).then(async () => {
			if (props.user) {
				await genLog(collaborator, userToAssign, newGen, oldGen);
			}
			updateGeneration();
		});

		await updateDoc(userRef, {
			generationId: props.gen.id,
			schedule: props.gen.schedule,
			addedAt: new Date(),
		});
	};

	const updateGeneration = async () => {
		// IMPROVE: Separacion de responsabilidades, decrementar el limite debe estar en su propia funcion.
		if (isGenInPerson(props.gen.type)) {
			const genRef = doc(db, "generations", props.gen.id);
			const genSnap = await getDoc(genRef);

			if (genSnap.exists()) {
				let numberLimit = genSnap.data().limit - 1;
				props.gen.limit = numberLimit;
				props.onHide();
				props.saveGen();
			}
		} else {
			props.onHide();
			// IMPROVE: De verdad se necesita ejecutar todo saveGen ? o solo checkGen para mostrar al nuevo estudiante?
			props.saveGen();
		}
	};

	return (
		<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Agregar usuario</Modal.Title>
			</Modal.Header>
			<Modal.Body className="setminheightmodalAddUser">
				<p className="regular-text" style={{ marginBottom: "60px" }}>
					Selecciona al usuario que deseas agregar a esta generación.
				</p>
				<Select onChange={(e) => handleSelection(e)} options={users} />
			</Modal.Body>
			<Modal.Footer>
				<button onClick={props.onHide}>Cancelar</button>
				<button onClick={save}>Guardar</button>
			</Modal.Footer>
		</Modal>
	);
}
