import { useAuth } from "@hooks/useAuth";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import {
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	Timestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import { db } from "initFirebase";
import "./styles.scss";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/mobile";
import es from "react-phone-number-input/locale/es.json";
import { useHistory } from "react-router";
import Countdown from "react-countdown";
import { compareGenerations } from "functions/Generations/FunctionsGenerations";
import { AdminGenSelector } from "@components/Admin/AdminGenSelector/AdminGenSelector";
import { useGenerations } from "@hooks/GenerationsFilters/useGenerations";
import { isGenInPerson } from "interfaces/Generations";
interface generation {
	active: boolean;
	asuetos: Array<asueto>;
	createdAt: Timestamp;
	initialDate: string;
	limit: number;
	name: string;
	promoDate?: string;
	schedule: string;
	sede: string;
	type: string;
}

interface asueto {
	date: string;
	name: string;
}

function MyAccountContent({ user }) {
	const userid = getAuth().currentUser?.uid ?? "";
	const userRef = doc(db, "users", userid);
	const [actaulUserName, setActualUserName] = useState(user.name);
	const [userName, setUserName] = useState(user.name);
	const [celular, setCelular] = useState(user.phone);
	const [phone, setPhone] = useState(celular);
	const [genId, setGenId] = useState<string>(user.generationId);
	const [actualSecondaryEmail, setActualSecondaryEmail] = useState(user.secondaryEmail);
	const [secondaryEmail, setSecondaryEmail] = useState(user.secondaryEmail);
	const history = useHistory();
	const [hideInscription, setHideInscription] = useState(false);
	const [loading, setLoading] = useState(true);
	const [showCard, setShowCard] = useState(false);
	const [horario, setHorario] = useState(user.schedule);
	const Completionist = () => <span>You are good to go!</span>;
	const [selected, setSelected] = useState(horario);
	const [fechasInfo, setFechasInfo] = useState<any>({});
	const inscriptionDate = new Date(fechasInfo.fechaInicioInscripcion);
	const date = new Date(Date.now());
	const name = actaulUserName?.split(" ");
	const [announcement, setAnnouncement] = useState<any>({ active: false });
	const [userGen, setUserGen] = useState<any>(null);

	const { gens, handleCheck, load, eyeColor, setEyeColor } = useGenerations(compareGenerations);

	const getAnnouncement = async () => {
		const q = query(collection(db, "announcement"), where("generationId", "==", genId));
		const snap = await getDocs(q);

		if (snap.size > 0) {
			setAnnouncement({ id: snap.docs[0].id, ...snap.docs[0].data() });
		}
	};

	const getPayments = async () => {
		if (user.generationId !== "") {
			setHideInscription(true);
		} else {
			setHideInscription(false);
		}
	};

	const getGen = async () => {
		if (!genId) {
			setLoading(false);
			return;
		}

		const genRef = doc(db, "generations", genId);
		const snap = await getDoc(genRef);

		if (snap.exists()) {
			setUserGen({ id: snap.id, ...snap.data() });
			setHorario(snap.data().schedule);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const getCopie = async () => {
		db.collection("dynamic_copies")
			.doc("Hh06tq4Yk4XhTThIabUp")
			.collection("fechas")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setFechasInfo({
						...doc.data(),
						id: doc.id,
					});
				});
			});
	};

	const handleSelect = async (e) => {
		await updateDoc(userRef, {
			schedule: e,
		});
		setSelected(e);
	};

	const handleChange = (e) => {
		setPhone(e);
	};

	const submitPhone = async () => {
		if (isPossiblePhoneNumber(phone) === true) {
			await updateDoc(userRef, {
				phone: phone,
			});
			setCelular(phone);
		} else {
			alert("Por favor ingresa un número de celular válido.");
		}
	};

	const submitSecondaryEmail = async () => {
		await updateDoc(userRef, {
			secondaryEmail: secondaryEmail,
		});
		setActualSecondaryEmail(secondaryEmail);
	};

	const submitUserName = async () => {
		await updateDoc(userRef, {
			name: userName,
		});
		history.go(0);
	};

	const asidebarTutorial = () => {
		db.collection("users")
			.doc(userid)
			.update({
				asidebarTutorial: false,
			})
			.then(() => {
				history.go(0);
			});
	};

	useEffect(() => {
		getPayments();
		getCopie();
		if (user.generationId !== "") {
			getAnnouncement();
			getGen();
		} else {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getGen();
		getAnnouncement();
	}, [genId]);

	if (loading) {
		return (
			<div
				style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	} else {
		return (
			<>
				{(user.asidebarTutorial === undefined || user.asidebarTutorial === true) && (
					<div className="tutorial-container">
						<h2>↑</h2>
						<p className="text-justify">
							Haz click en este icono para acceder al menú de navegación.
						</p>
						<div className="text-center">
							<button onClick={asidebarTutorial} className="return-btn-style">
								Entendido
							</button>
						</div>
					</div>
				)}
				<img
					style={{ position: "absolute", bottom: 0, right: 0, zIndex: -1 }}
					id="students-talking-img"
					src={"@img/students-table.png"}
				/>
				{(user.userType === 2 || user.userType === 4) && (
					<AdminGenSelector
						setGenId={setGenId}
						genId={genId}
						load={load}
						handleCheck={handleCheck}
						gens={gens}
						eyeColor={eyeColor}
						setEyeColor={setEyeColor}
					/>
				)}
				<div>
					{user.gender === undefined ? (
						<h1>¡Bienvenido, {name[0]}!</h1>
					) : (
						<>
							{user.gender !== "mujer" && <h1>¡Bienvenido, {name[0]}!</h1>}
							{user.gender === "mujer" && <h1>¡Bienvenida, {name[0]}!</h1>}
						</>
					)}
				</div>
				<div className="my-account-cards-container">
					<div className="my-account-container">
						<div>
							<h2>Mi perfil</h2>
						</div>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<label className="subtitle" htmlFor="name">
								Nombre
							</label>
							<input
								style={{
									padding: 10,
									borderRadius: 10,
									outline: "none",
									border: "1px solid #b3b3b3",
								}}
								value={userName}
								onChange={(e) => setUserName(e.target.value)}
								id="name"
								name="name"
								type="text"
							/>
							{actaulUserName !== userName && (
								<button
									className="tertiary"
									onClick={submitUserName}
									style={{ maxWidth: 300, margin: "auto", marginTop: 10 }}
								>
									Guardar
								</button>
							)}
						</div>
						<div>
							<p className="subtitle">Correo asociado a la cuenta</p>
							<p>{user.email}</p>
						</div>
						<div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
							<div style={{ display: "flex", margin: "0" }}>
								<label className="subtitle" htmlFor="secondaryEmail">
									Correo para grabaciones
								</label>
								<div className="question-icon2" style={{ margin: "5px 10px", lineHeight: "21px" }}>
									?
									<div className="info-box2">
										<p>
											<b className="tittle-youtube">
												¿Cómo acceder a las grabaciones mediante YouTube?
											</b>{" "}
											<br />
											<b>Paso I:</b> En YouTube, inicia sesión desde: {user.email}
											<br />
											<b>Paso II:</b> Una vez iniciada sesión, abre el enlace directo. Puedes hacer
											clic en él o copiar y pegar el vínculo en tu navegador.{" "}
										</p>
									</div>
								</div>
							</div>

							<input
								style={{
									padding: 10,
									borderRadius: 10,
									outline: "none",
									border: "1px solid #b3b3b3",
								}}
								value={secondaryEmail}
								onChange={(e) => setSecondaryEmail(e.target.value)}
								placeholder="¿Con qué correo inicias sesión en YouTube?"
								id="secondaryEmail"
								name="secondaryEmail"
								type="email"
							/>
							<div />
							{secondaryEmail !== actualSecondaryEmail && (
								<button
									className="tertiary"
									onClick={submitSecondaryEmail}
									style={{ maxWidth: 300, margin: "auto", marginTop: 10 }}
								>
									Guardar
								</button>
							)}
						</div>
						<div>
							<p className="subtitle">Matrícula</p>
							{user.matricula === "" ? (
								<p>Inscríbete para tener una matrícula</p>
							) : (
								<p>{user.matricula}</p>
							)}
						</div>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<label className="subtitle" htmlFor="phone">
								Celular
							</label>
							<PhoneInput
								placeholder="Ingresa un número de celular."
								labels={es}
								international={true}
								name="phone"
								id="phone"
								value={phone}
								onChange={handleChange}
							/>
							{phone !== celular && (
								<button
									className="tertiary"
									onClick={submitPhone}
									style={{ maxWidth: 300, margin: "auto", marginTop: 10 }}
								>
									Guardar
								</button>
							)}
						</div>
						<div style={{ display: "flex", flexDirection: "column" }}>
							{user.generationId !== "" && (
								<>
									<label className="subtitle">Sede</label>
									<div
										className="mb-4"
										style={{
											padding: 10,
											borderRadius: 10,
											outline: "none",
											border: "1px solid #b3b3b3",
											cursor: isGenInPerson(userGen.type) ? "pointer" : "initial",
										}}
										id="sede"
										onClick={() => {
											isGenInPerson(userGen.type) &&
												history.push({ pathname: "/dudas/4", state: { option: "6" } });
										}}
									>
										<p>{userGen.sede}</p>
									</div>
									<label className="subtitle">Horario de curso</label>
									<input
										style={{
											padding: 10,
											borderRadius: 10,
											outline: "none",
											border: "1px solid #b3b3b3",
										}}
										value={horario}
										disabled
										id="horario"
										name="horario"
										type="text"
									/>
									<label style={{ marginTop: 0, marginBottom: 20 }}>
										*Todos los horarios corresponden a la zona horaria de Ciudad de México.
									</label>
								</>
							)}
							{user.generationId === "" && (
								<p>Estos campos se llenarán automáticamente al confirmar tu inscripción.</p>
							)}
						</div>
					</div>
					{!hideInscription && (
						<div className="inscription-card-container">
							<div>
								<p className="title-inscription-card">{fechasInfo.red_copie3}</p>
								<p>{fechasInfo.red_copie2}</p>
								<p className="title-inscription-card">{fechasInfo.fecha_limite}</p>
								<p>{fechasInfo.fecha_limite2}</p>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									marginLeft: "auto",
									marginRight: "auto",
									marginBottom: "10px",
								}}
							>
								{date < inscriptionDate && (
									<Countdown
										date={inscriptionDate}
										onComplete={() => setShowCard(!showCard)}
										renderer={({ days, hours, minutes, seconds }) => (
											<span>
												{days} d {hours} h {minutes} m {seconds} s
											</span>
										)}
									>
										<Completionist />
									</Countdown>
								)}
							</div>
							<div style={{ alignSelf: "center" }}>
								<button
									className="tertiary"
									style={{ padding: 0 }}
									onClick={() => history.push("/inscripcion")}
								>
									Inscríbete
								</button>
							</div>
							<div
								style={{
									alignSelf: "center",
									marginTop: 20,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexWrap: "wrap",
								}}
							>
								<span style={{ fontSize: "1rem", marginBottom: 10 }}>
									¿Ya realizaste tu inscripción?
								</span>
								<button
									className="tertiary"
									style={{ padding: 0 }}
									onClick={() => history.push("/micuenta/actualizar-pagos")}
								>
									Actualizar pago
								</button>
							</div>
						</div>
					)}
					{hideInscription &&
						announcement.active === true &&
						(announcement.generationId === "all" ||
							announcement.generationId === user.generationId) && (
							<div className="inscription-card-container pb-2" style={{ overflow: "scroll" }}>
								<div>
									<h2 className="title-inscription-card">{announcement.title}</h2>
									<p style={{ whiteSpace: "pre-wrap" }}>{announcement.description}</p>
								</div>
							</div>
						)}
				</div>
			</>
		);
	}
}

export default MyAccountContent;
