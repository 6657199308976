import React, { createContext, useContext, useRef } from "react";

const HomeActionsContext = createContext<any>(null);

export const useScrollContext = () => {
	return useContext(HomeActionsContext);
};

export const HomeActionsProvider = ({ children }) => {
	const locationsRef: any = useRef();

	const scrollToLocations = () => {
		if (locationsRef.current) {
			locationsRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
		}
	};

	return <HomeActionsContext.Provider value={{ scrollToLocations, locationsRef }}>{children}</HomeActionsContext.Provider>;
};
