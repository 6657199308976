import "./AsideNav.scss";
import { BrowserRouter as Router, Switch, Route, useParams, useRouteMatch, Link, useHistory } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import { useAuth } from "@hooks/useAuth";
import { getAuth, signOut } from "firebase/auth";
import { collection, limit, onSnapshot, orderBy, query, where, getDocs } from "firebase/firestore";
import { db } from "../../initFirebase";
import { getWordReports } from "@api/Vocabulary";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MenusDataContext } from "@containers/Admin/CollaboratorContainer/context/menu-data.provider";
import {
	AllUsers,
	Cardicon,
	CollaboratorUser,
	CashIcon,
	Birrete,
	FinanzasIcon,
	ConsultasIcon,
	GeneracionesIcon,
	GrabacionesIcon,
	InvoiceAdminIcon,
	ListIcon,
	LogoutIcon,
	MaterialIconSoon,
	NotificationsIcon,
	OpenBook,
	OpenBookIcon,
	PeopleIcon,
	StudentView,
	TextIcon,
	ColaboradoresIcon,
	UserCol,
	Userx4,
	UserBlack,
	InvoicesWhite,
	DownArrow,
	Book_open_white,
} from "assets/Imports";
import { pendingInvoicesCount } from "@services/InvoiceServices";
import { handleOutsideClick } from "functions/HandleClicks";
import AsideNavLinks from "./component/AsideNavLinks";

function AdminAsideNav() {
	let { url } = useRouteMatch();
	const showMoreLocalStorage =
		localStorage.getItem("showMoreAdminAsideBar") === "true" || localStorage.getItem("showMoreAdminAsideBar") === null
			? true
			: false;
	const [active, setActive] = useState<any>(1);
	const history = useHistory();
	const [notSeenNotificationsCount, setNotSeenNotificationsCount] = useState<number>(0);
	const [notSeenContacts, setNotSeenContacts] = useState<number>(0);
	const [paymentsRequest, setPaymentsRequest] = useState<number>(0);
	const [uncheckedReports, setUncheckedReports] = useState<number>(0);
	const [pendingInvoices, setPendingInvoices] = useState<number>(0);
	const { ispreviewMode, rutasAccesibles, academicRoute, financeRoute } = useContext(MenusDataContext);
	const [showAside, setShowAside] = useState<boolean>(false);
	const { user, notificationOpen, setNotificationOpen, asideOpen, setAsideOpen, asideBtnRef, logout } = useAuth();
	let location = useLocation();
	const [userMode, setUserMode] = useState<boolean>(false);
	const asideContainerRef = useRef<any>();
	const [openSubmenu, setOpenSubMeno] = useState<boolean>(false);
	const [openSubmenuFinance, setOpenSubMenoFinances] = useState<boolean>(false);
	const { selectedUserPreview } = useAuth();
	const [showMore, setShowMore] = useState<boolean>(showMoreLocalStorage);
	const StatusTitleRoute = {
		NA: "NA",
	};
	const { NA } = StatusTitleRoute;

	const logOut = () => {
		const auth = getAuth();
		signOut(auth)
			.then(() => {
				// Sign-out successful.
				history.go(0);
			})
			.catch((error) => {
				// An error happened.
			});
	};

	function getIcon(value) {
		switch (value) {
			case "/colaboradores":
				return UserBlack;
			case "/galerias":
				return Book_open_white;
			case "/invoice":
				return InvoicesWhite;
			case "":
				return GeneracionesIcon;
			case "/material":
				return OpenBook;
			case "/actualizar-pagos":
				return CashIcon;
			case "/historial-de-pagos":
				return Cardicon;
			case "/contacto":
				return PeopleIcon;
			case "/textos":
				return TextIcon;
			case "/consultas":
				return ConsultasIcon;
			case "/vocabulario":
				return ListIcon;
			case "/formularios":
				return MaterialIconSoon;
			case "/formulariosAll":
				return MaterialIconSoon;
			case "/videos":
				return GrabacionesIcon;
			default:
				return undefined; // Devuelve undefined en caso de no coincidencia
		}
	}

	const getPendingInvoicesCount = async () => {
		let temp = await pendingInvoicesCount();
		setPendingInvoices(temp);
	};

	function getCountNotifications(value: any) {
		switch (value) {
			case "/colaboradores":
				return 0;
				break;
			case "/invoice":
				return pendingInvoices;
				break;
			case "":
				return 0;
				break;
			case "/material":
				return 0;
				break;
			case "/actualizar-pagos":
				return paymentsRequest;
				break;
			case "/historial-de-pagos":
				return 0;
				break;
			case "/contacto":
				return notSeenContacts;
				break;
			case "/vocabulario":
				return uncheckedReports;
				break;
			case "/colaboradores":
				return 0;
				break;
			default:
				return 0;
		}
	}
	const getAllWordReports = async () => {
		getWordReports().then((res) => {
			let count = 0;
			res?.reports?.forEach((e) => {
				if (e.checked === 0) {
					count++;
				}
			});
			setUncheckedReports(count);
		});
	};

	const getNotifications = async () => {
		let q;

		if (user.userType === 2 && !userMode) {
			q = query(collection(db, "notifications"), where("userId", "==", "admin"), orderBy("date", "desc"), limit(50));
		} else if (userMode) {
			q = query(collection(db, "notifications"), where("userId", "==", user.id), orderBy("date", "desc"), limit(50));
		} else if (user.userType === 4 && !userMode) {
			q = query(collection(db, "notifications"), where("userId", "==", "admin"), orderBy("date", "desc"), limit(50));
		}

		if (q) {
			const suscribe = onSnapshot(q, (querySnapshot) => {
				const notifications: any = [];
				const countArray: any = [];

				querySnapshot.forEach((doc) => {
					const data = {
						id: doc.id,
						...doc.data(),
					};

					if (data.seen === false) {
						countArray.push(data);
					}

					notifications.push(data);
				});

				const notSeenCount = countArray.length;

				setNotSeenNotificationsCount(notSeenCount);
			});
		}
	};

	const notificationOptions = () => {
		if (700 > 600) {
			setNotificationOpen(!notificationOpen);
		} else {
			history.push("/micuenta/notificaciones");
		}
	};

	const getContacts = async () => {
		const q = query(collection(db, "newsletter"), where("status", "==", "pending"), orderBy("created", "desc"));

		const suscribe = onSnapshot(q, (querySnapshot) => {
			setNotSeenContacts(querySnapshot.size);
		});
	};

	const getPaymentsRequest = async () => {
		let array: any = [];
		const q = query(
			collection(db, "paymentHistory"),
			where("checked", "==", false),
			where("verified", "==", false),
			orderBy("date", "desc")
		);
		const suscribe = onSnapshot(q, (querySnapshot) => {
			array = [];
			querySnapshot.forEach((snap) => {
				if (
					((snap.data().medio !== "SPEI" && snap.data().medio !== "OXXO Pay") ||
						(snap.data().medio === "SPEI" && snap.data().image !== "") ||
						(snap.data().medio === "OXXO Pay" && snap.data().image !== "")) &&
					snap.data().orderId === undefined &&
					(!snap.data().isArchived || snap.data().isArchived === undefined)
				) {
					array.push({ ...snap.data() });
				}
			});
			setPaymentsRequest(array.length);
		});
	};

	const handleOutsideClickAside = (event: any) => handleOutsideClick(event, asideBtnRef, setAsideOpen, asideContainerRef);

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClickAside);
		return () => {
			document.removeEventListener("mousedown", handleOutsideClickAside);
		};
	}, []);

	useEffect(() => {
		getNotifications();
		getContacts();
		getPaymentsRequest();
		getAllWordReports();
		getPendingInvoicesCount();
	}, []);

	useEffect(() => {
		getNotifications();
		getContacts();
		getPaymentsRequest();
		getAllWordReports();
	}, []);
	useEffect(() => {
		let currentPath = location.pathname.split("/")[2];
		let index = rutasAccesibles?.findIndex((route) => route.Ruta.slice(1) === currentPath);

		if (index !== -1) {
			setActive(index + 1);
		} else {
			setActive(3); // Comportamiento predeterminado si no se encuentra una coincidencia
		}
	}, [location, rutasAccesibles]);

	const hasPermissionForSection = (sectionRoutes) => {
		const previewUserPermissions = new Set(selectedUserPreview?.seccionsCollaboratorId);
		if (ispreviewMode) {
			// Lógica para el modo de vista previa
			return sectionRoutes.some((data) => previewUserPermissions.has(data.id));
		} else {
			// Lógica para el modo normal
			return sectionRoutes.some((data) => user?.permissions?.includes(data.id) || user.userType !== 4);
		}
	};
	return (
		<>
			{/*<div
				className="asidenav-active-btn"
				style={{
					width: 40,
					height: 40,
					borderRadius: 5,
					position: "fixed",
					top: 15,
					border: "2px solid white",
					overflow: "hidden",
					zIndex: 100005,
					left: 31,
					padding: 5,
				}}
				onClick={() => setShowAside(!showAside)}
				ref={asideBtnRef}
			>
				<img style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }} src={OpenBookIcon} />
			</div>*/}
			<aside className={"admin-aside-nav" + (asideOpen === true ? " admin-aside-nav-responsive" : "")} ref={asideContainerRef}>
				<ul>
					<Link className={`${active === 99 ? "active" : ""}`} to={`/micuenta`} onClick={() => setActive(99)}>
						<li>
							<div style={{ width: 30, marginRight: 20 }}>
								<img src={StudentView} alt="text-icon" />
							</div>
							Vista usuario
						</li>
					</Link>
					<li
						className="notification-btn responsive"
						style={{ cursor: "pointer" }}
						onClick={() => {
							setNotificationOpen(true);
							setShowAside(false);
						}}
					>
						<div style={{ width: 30, marginRight: 20, position: "relative" }}>
							{notSeenNotificationsCount > 0 && <div className="number-icon">{notSeenNotificationsCount}</div>}
							<img
								style={{ filter: "invert(0%) sepia(1%) saturate(3%) hue-rotate(326deg) brightness(96%) contrast(104%)" }}
								src={NotificationsIcon}
								alt="notification icon"
							/>
						</div>
						Notificaciones
					</li>
					<li
						className="notification-btn desk"
						style={{ cursor: "pointer" }}
						onClick={() => history.push("/micuenta/notificaciones")}
					>
						<div style={{ width: 30, marginRight: 20, position: "relative" }}>
							{notSeenNotificationsCount > 0 && <div className="number-icon">{notSeenNotificationsCount}</div>}
							<img
								style={{ filter: "invert(0%) sepia(1%) saturate(3%) hue-rotate(326deg) brightness(96%) contrast(104%)" }}
								src={NotificationsIcon}
								alt="notification icon"
							/>
						</div>
						Notificaciones
					</li>
					{hasPermissionForSection(academicRoute) && (
						<div className={"sub-menu-item " + (openSubmenu ? "sub-menu-open" : "")}>
							<li className="sub-menu-action" onClick={() => setOpenSubMeno(!openSubmenu)}>
								<div style={{ width: 30 }}>
									<img src={Birrete} />
								</div>
								<span className={showMore ? "" : "d-none"}>Académico</span>
								<img
									className={"arrow-icon" + (showMore ? "" : " d-none")}
									src={DownArrow}
									alt="flecha para abrir sub menu"
									style={{ position: "relative", left: "-2px", filter: "invert(100%)" }}
								/>
							</li>
							<div className={"sub-menu-container" + (openSubmenu ? "" : " pl-0 d-none")}>
								<AsideNavLinks
									routes={academicRoute}
									getCountNotifications={getCountNotifications}
									getIcon={getIcon}
									NA={NA}
									setActive={setActive}
									active={active}
								/>
							</div>
						</div>
					)}

					{hasPermissionForSection(financeRoute) && (
						<div className={"sub-menu-item " + (openSubmenuFinance ? "sub-menu-open" : "")}>
							<li className="sub-menu-action" onClick={() => setOpenSubMenoFinances(!openSubmenuFinance)}>
								<div style={{ width: 30 }}>
									<img src={FinanzasIcon} />
								</div>
								<span className={showMore ? "" : "d-none"}>Finanzas</span>
								<img
									style={{ position: "relative", left: "22px", filter: "invert(100%)" }}
									className={"arrow-icon" + (showMore ? "" : " d-none")}
									src={DownArrow}
									alt="flecha para abrir sub menu"
								/>
							</li>
							<div className={"sub-menu-container" + (openSubmenuFinance ? "" : " pl-0 d-none")}>
								<AsideNavLinks
									routes={financeRoute}
									getCountNotifications={getCountNotifications}
									getIcon={getIcon}
									NA={NA}
									setActive={setActive}
									active={active}
								/>
							</div>
						</div>
					)}

					<AsideNavLinks
						routes={rutasAccesibles}
						getCountNotifications={getCountNotifications}
						getIcon={getIcon}
						NA={NA}
						setActive={setActive}
						active={active}
					/>

					<li style={{ marginBottom: 150, cursor: "pointer", color: "#cecece" }} onClick={logOut}>
						<div style={{ width: 30, marginRight: 20 }}>
							<img src={LogoutIcon} alt="log-out-icon" />
						</div>
						Cerrar sesión
					</li>
				</ul>
			</aside>
		</>
	);
}

export default AdminAsideNav;
