import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import './styles.scss';

interface PaymentCardProps {
  selectedCard: boolean;
  cardInfo: any;
}

function PaymentCardB(props: PaymentCardProps) {
  const [onHover, setOnHover] = useState(false);

  useEffect(() => {
    setOnHover(props.selectedCard);
  }, [props.selectedCard]);

  return (
    <div className="payment__card text-md-left ">
      <Card
        // style={{ width: '24.5rem', height: '29rem' }}
        className={onHover ? 'hover shadow' : 'shadow'}
      >
        <Card.Body>
          <Card.Title className="text-center">
            {props.cardInfo.nombre}
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
          <Card.Text>
            <div className="d-none d-sm-block">
              <p>Selecciona esta opción para:</p>
              <ul>
                <li>{props.cardInfo.copie1}</li>
                <li>{props.cardInfo.copie2}</li>
                <li>{props.cardInfo.copie3}</li>
                <li>{props.cardInfo.copie4}</li>
                <li>{props.cardInfo.copie5}</li>
              </ul>
            </div>
          </Card.Text>
          {/* <a
            href={props.cardInfo.url}
            target="_blank"
            rel="noopener noreferrer"
            className="stretched-link"
          >
            {}
          </a> */}
        </Card.Body>
      </Card>
    </div>
  );
}

export default PaymentCardB;
