import { SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import { useStreak } from "@hooks/useStreak";
import { StreakFlameIcon } from "assets/Imports";
import { useEffect, useState } from "react";
import { StreakModal } from "../StreakModal";
import { dayInfoModalInterface } from "interfaces/StreakCalendar";
import { DayInfoModal } from "./DayInfoModal";

interface StreakFlameProps {
	user: any;
	vocabularyStreak: Array<string>;
	isLoading: boolean;
	studiedDays: Array<string>;
}

export function StreakFlame({ user, vocabularyStreak, isLoading, studiedDays }: StreakFlameProps) {
	const [streakModal, setStreakModal] = useState(false);
	const [value, onChange] = useState(new Array());
	const [dayInfoModal, setDayInfoModal] = useState<dayInfoModalInterface>({
		show: false,
		dayData: null,
	});

	const getStreakArray = async () => {
		const temp = new Array();
		studiedDays.map((date) => {
			temp.push(new Date(date));
		});
		onChange(temp.reverse());
	};

	const onDayClick = (date: Date) => {
		const index = value.findIndex((e) => e.toISOString() === date.toISOString());
		if (index !== -1) {
			const dateString = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

			const data = {
				date: date,
				dateString: dateString,
			};

			setDayInfoModal({ show: true, dayData: data });
		}
	};

	useEffect(() => {
		if (studiedDays.length > 0) {
			getStreakArray();
		}
	}, [studiedDays]);



	return (
		<>
			<StreakModal
				value={value}
				show={streakModal}
				onHide={() => setStreakModal(false)}
				vocabularyStreak={studiedDays}
				onDayClick={onDayClick}
			>
				<DayInfoModal
					userProfile={{ id: user.id, vocabularyUserId: user.vocabularyUserId }}
					show={dayInfoModal.show}
					setDayInfoModal={setDayInfoModal}
					dayData={dayInfoModal.dayData}
				/>
			</StreakModal>
			<div className="streak-container" onClick={() => setStreakModal(true)}>
				<img className="streak-icon" src={StreakFlameIcon} alt="icono de fuego" />
				<h2>{isLoading ? <SpinnerCustom height={25} /> : vocabularyStreak.length}</h2>
			</div>
		</>
	);
}
