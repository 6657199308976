import { capitalizeFirstLetter, getImage, returnFlagByLanguage, romanize } from "functions/Functions";
import { language, languagesArrays, quizWords } from "interfaces/Interfaces";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import "./DetailSeminaryCardStyles.scss";
import { filterArray, getLang, getLangArray } from "./functions/DetailSeminaryCardFunctions";
import MultiSelect from "./MultiSelect";
import {
	getArmByDifficultByNumber,
	getDifficultColor,
	getLangSpanishSimple,
} from "@components/Vocabulary/PersonalizedTest/functions/functions";
import { Progress, Tooltip } from "antd";
import "react-circular-progressbar/dist/styles.css";
import { languagesEnglish } from "functions/DefaultValues";
import { WordInfoModal } from "@components/Vocabulary/WordInfoModal/WordInfoModal";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { place_holder_vocabulary } from "@utils/PlaceHolders/place-holder-vocabulary";
import {
	ArrowBack,
	CheckmarkCircle,
	Dominated3,
	Dominated3n,
	DownArrow,
	Icono1,
	Icono2,
	Icono3,
	Icono4,
	NextArrow,
	SearchIcon,
	UpArrow,
} from "assets/Imports";

import { useVocabularySeminaryContext } from "contexts/VocabularySeminaryContext";

function DetailSeminaryCard({
	categories,
	num,
	gKey,
	languagesProgress,
	setLang,
	lang,
	view,
	setView,
	setCountRefresh,
	countRefresh,
	filterWord,
	searchWord,
	isShown,
	handleChange,
	handleClick,
}) {
	const { words } = useVocabularySeminaryContext();
	const [subMenu, setSubMenu] = useState<number>(1);
	const [langProgressView, setLangProgressView] = useState<number>(1);
	const [allWordsArray, setAllWordsArray] = useState([
		...words.french,
		...words.german,
		...words.italian,
		...words.russian,
		...words.portuguese,
		...words.chinese,
		...words.japanese,
		...words.english,
	]);
	const [selectedLang, setSelectedLang] = useState(["all"]);
	const [detailedArray, setDetailedArray] = useState<Array<language>>(new Array());
	const languages = ["all", "french", "german", "italian", "russian", "portuguese", "chinese", "japanese", "english"];
	const [numberLangs, setNumberLangs] = useState<number>(0);
	const [wordInfoModal, setWordInfoModal] = useState({ show: false, word: {}, index: 0 });
	const [sortEs, setSortEs] = useState<string>("Alfabético asc.");
	const [sortValue, setSortValue] = useState<string>("nameUp");
	const [loadArray, setLoadArray] = useState<boolean>(true);
	const { input_buscar_terminos } = place_holder_vocabulary;
	
	const handleSelect = async (sortValue) => {
		switch (sortValue) {
			case "nameUp":
				setSortValue("nameUp");
				setSortEs("Alfabético asc.");
				break;
			case "nameDown":
				setSortValue("nameDown");
				setSortEs("Alfabético desc.");
				break;
			case "difficultyUp":
				setSortValue("difficultyUp");
				setSortEs("Dificultad asc.");
				break;
			case "difficultyDown":
				setSortValue("difficultyDown");
				setSortEs("Dificultad desc.");
				break;
			case "dominatedUp":
				setSortValue("dominatedUp");
				setSortEs("Dominio asc.");
				break;
			case "dominatedDown":
				setSortValue("dominatedDown");
				setSortEs("Dominio desc.");
				break;
			default:
				break;
		}
	};

	const changeView = (eLang, sub?) => {
		setView(1);
		setLang(eLang);
		setSelectedLang(languages.includes(eLang) ? eLang : "all");

		if (sub !== undefined) {
			setSubMenu(sub);
		} else {
			setSubMenu(1);
		}
	};


	function getDominatedCategory(category) {
		const count = allWordsArray.filter((e) => {
			if (e.category.includes(category) && e.dominated === 3) {
				return true;
			} else {
				return false;
			}
		}).length;

		return count;
	}

	function getWordsWithCategory(category) {
		return allWordsArray.filter((e) => e.category.includes(category)).length;
	}

	const numberOfLanguagesInSeminary = async () => {
		let count = 0;

		await Promise.all(
			languagesEnglish.map((lang) => {
				if (words[lang].length > 0) {
					count++;
				}
			})
		);

		setNumberLangs(count);
	};
	useEffect(() => {
		if (searchWord.length > 0 && view !== 2) {
			setView(2);
		} else if (searchWord.length === 0 && view === 2) {
			setView(0);
		}
	}, [searchWord]);

	useEffect(() => {
		setView(0);
	}, [isShown]);

	useEffect(() => {
		numberOfLanguagesInSeminary();
		setAllWordsArray([
			...words.french,
			...words.german,
			...words.italian,
			...words.russian,
			...words.portuguese,
			...words.chinese,
			...words.japanese,
			...words.english,
		]);
	}, [words]);

	useEffect(() => {
		setDetailedArray([]);
	}, [subMenu, lang, selectedLang, sortValue, words]);

	useEffect(() => {
		let filteredWords = filterArray(subMenu, getLangArray(lang, words)!);
		if (detailedArray.length === 0 && lang !== "" && filteredWords.length !== 0) {
			setDetailedArray(
				(selectedLang.includes("all")
					? filteredWords
					: filteredWords.filter((e) => selectedLang.includes(e.language))
				).sort((a, b) => {
					switch (sortValue) {
						case "nameUp":
							var nameA = a.native_word.toLowerCase().replace(/"/g, ""),
								nameB = b.native_word.toLowerCase().replace(/"/g, "");
							if (nameA < nameB)
								//sort string ascending
								return -1;
							if (nameA > nameB) return 1;
							return 0; //default return value (no sorting)
							break;
						case "nameDown":
							var nameA = a.native_word.toLowerCase().replace(/"/g, ""),
								nameB = b.native_word.toLowerCase().replace(/"/g, "");
							if (nameA > nameB)
								//sort string ascending
								return -1;
							if (nameA < nameB) return 1;
							return 0; //default return value (no sorting)
							break;
						case "difficultyUp":
							var nameA2 = a.custom_difficulty ? parseFloat(a.custom_difficulty) : 0,
								nameB2 = b.custom_difficulty ? parseFloat(b.custom_difficulty) : 0;
							if (nameA2 < nameB2)
								//sort string ascending
								return -1;
							if (nameA2 > nameB2) return 1;

							return 0; //default return value (no sorting)
							break;
						case "difficultyDown":
							var nameA2 = a.custom_difficulty ? parseFloat(a.custom_difficulty) : 0,
								nameB2 = b.custom_difficulty ? parseFloat(b.custom_difficulty) : 0;
							if (nameA2 > nameB2)
								//sort string ascending
								return -1;
							if (nameA2 < nameB2) return 1;

							return 0; //default return value (no sorting)
							break;
						case "dominatedUp":
							if (a.dominated === null) {
								a.dominated = 0;
							}
							if (b.dominated === null) {
								b.dominated = 0;
							}
							var nameA2 = a.dominated,
								nameB2 = b.dominated;
							if (nameA2 < nameB2)
								//sort string ascending
								return -1;
							if (nameA2 > nameB2) return 1;
							return 0; //default return value (no sorting)
							break;
						case "dominatedDown":
							if (a.dominated === null) {
								a.dominated = 0;
							}
							if (b.dominated === null) {
								b.dominated = 0;
							}
							var nameA2 = a.dominated,
								nameB2 = b.dominated;
							if (nameA2 > nameB2)
								//sort string ascending
								return -1;
							if (nameA2 < nameB2) return 1;
							return 0; //default return value (no sorting)
							break;
						default:
							return 0;
							break;
					}
				})
			);
		} else if (wordInfoModal.show) {
			setWordInfoModal({ ...wordInfoModal, word: detailedArray[wordInfoModal.index] });
		}
	}, [detailedArray, selectedLang, subMenu, lang, words, sortValue, wordInfoModal.show, wordInfoModal.index]);

	return (
		<div className="detail-vocabulary-seminary-card">
			<div className="main-container">
				<div className="searchWord-container" style={{ display: "none" }}>
					<input value={searchWord} type="text" placeholder={input_buscar_terminos} onChange={handleChange} />
				</div>
				<div className="header">
					<div className="image-container">
						<img src={getImage(parseInt(num))} alt="" />
					</div>
					<div className="title-container">
						<h2>
							Seminario <span style={{ fontFamily: "initial" }}>{romanize(parseInt(num))}</span>
						</h2>
					</div>
				</div>
				{view === 0 && (
					<>
						<div className="content-container">
							<div className="detail-section-container top-content">
								<div className="text-row">
									<p className="detail" onClick={() => changeView("all")}>
										Total de términos
									</p>
									<p className="detail" onClick={() => changeView("all")}>
										{allWordsArray.length}
									</p>
								</div>
								<div className="text-row">
									<p className="detail" onClick={() => changeView("all", 2)}>
										Términos nuevos
									</p>
									<p className="detail" onClick={() => changeView("all", 2)}>
										{allWordsArray.filter((e) => e.dominated === null).length}
									</p>
								</div>
							</div>
							<div className="detail-section-container progress-lang-container">
								<div className="subtitle-container">
									<h3>Progreso por idioma</h3>
									<img
										className={langProgressView === 1 ? "" : "active"}
										src={NextArrow}
										alt="icono de flecha"
										onClick={() => setLangProgressView(langProgressView === 1 ? 2 : 1)}
									/>
								</div>
								{langProgressView === 1 ? (
									<div
										className="circle-progress-flags-container"
										style={numberLangs > 6 || numberLangs === 4 ? { gap: 5 } : { gap: 10 }}
									>
										{languagesProgress &&
											languagesEnglish.map((lang, index) => {
												const percentage = languagesProgress[lang].dominated;
												const newPercentage = languagesProgress[lang].seen;
												return (
													words[lang].length > 0 && (
														<div className="circle-container" key={"circle-languages-progress-" + index}>
															<h2 className="m-0">{Math.floor(percentage)}%</h2>
															<div className="progress-container" onClick={() => changeView(lang)}>
																<Tooltip>
																	<Progress
																		percent={newPercentage}
																		success={{
																			percent: percentage,
																			strokeColor: percentage < 100 ? "#ca5050" : "#efc900",
																		}}
																		type="circle"
																		width={70}
																		strokeColor="#959595"
																		strokeWidth={10}
																		trailColor="#e7e7e7"
																		showInfo={false}
																	/>
																</Tooltip>
																{returnFlagByLanguage(lang)}
															</div>
														</div>
													)
												);
											})}
									</div>
								) : (
									languages.map((lang, index) => {
										if (lang !== "all") {
											return (
												words[lang].length > 0 && (
													<div className="text-row" key={"text-row-languages-" + index}>
														<p className="detail" onClick={() => changeView(lang)}>
															{returnFlagByLanguage(lang)} {capitalizeFirstLetter(getLangSpanishSimple(lang))}
														</p>
														<p
															className={
																"detail " +
																(words[lang].filter((e) => e.dominated === 3).length === words[lang].length ? "completed" : "")
															}
															onClick={() => changeView(lang)}
														>
															{words[lang].filter((e) => e.dominated === 3).length === words[lang].length && (
																<img src={CheckmarkCircle} alt="palomita" />
															)}{" "}
															{words[lang].filter((e) => e.dominated === 3).length}/{words[lang].length}
														</p>
													</div>
												)
											);
										}
									})
								)}
							</div>
							<div className="detail-section-container progress-category-container">
								<div className="subtitle-container">
									<h3>Progreso por categoría</h3>
								</div>
								{categories.map((category, index) => {
									return (
										<div className="text-row" key={"categoria-" + index}>
											<p className="detail" onClick={() => changeView(category)}>
												{capitalizeFirstLetter(category)}
											</p>
											<p
												className={
													"detail " + (getDominatedCategory(category) === getWordsWithCategory(category) ? "completed" : "")
												}
												onClick={() => changeView(category)}
											>
												{getDominatedCategory(category) === getWordsWithCategory(category) && (
													<img src={CheckmarkCircle} alt="palomita" />
												)}{" "}
												{getDominatedCategory(category)}/{getWordsWithCategory(category)}
											</p>
										</div>
									);
								})}
							</div>
						</div>
						<div className="footer">
							<div className="title">
								<h3>Dominados</h3>
								<h3>por dominar</h3>
							</div>
							<div className="bar-container">
								<p className="words-completed">{allWordsArray.filter((e) => e.dominated === 3).length}</p>
								<div className="words-progress-bar">
									<div
										className="word-progress"
										style={{
											width: (allWordsArray.filter((e) => e.dominated === 3).length / allWordsArray.length) * 100 + "%",
										}}
									></div>
								</div>
								<p className="left-words">{allWordsArray.length}</p>
							</div>
						</div>
					</>
				)}
				{view === 1 && (
					<>
						<WordInfoModal
							show={wordInfoModal.show}
							onHide={() => setWordInfoModal({ ...wordInfoModal, show: false, index: 0 })}
							word={wordInfoModal.word}
							gKey={gKey}
							setState={setDetailedArray}
							state={detailedArray}
							index={wordInfoModal.index}
							setCountRefresh={setCountRefresh}
							countRefresh={countRefresh}
						/>
						<div className="content-container">
							<div className="detail-section-container details">
								<div className="subtitle-container">{getLang(lang, languagesProgress, words)}</div>
								<div className="detail-sub-menu">
									<ul>
										<li onClick={() => setSubMenu(1)} className={subMenu === 1 ? "selected" : ""}>
											Todos
										</li>
										<li onClick={() => setSubMenu(2)} className={subMenu === 2 ? "selected" : ""}>
											Nuevos
										</li>
										<li onClick={() => setSubMenu(3)} className={subMenu === 3 ? "selected" : ""}>
											Dominados
										</li>
										<li onClick={() => setSubMenu(4)} className={subMenu === 4 ? "selected" : ""}>
											Por dominar
										</li>
									</ul>
									<div className="sorting">
										<p className="sorting-label">Ordenar por:</p>
										<DropdownButton title={sortEs} className="dropdown-container" id="word-sort" onSelect={handleSelect}>
											<Dropdown.Item eventKey={"nameUp"}>
												<img src={Icono2} alt="" style={{ width: "1rem" }} /> Alfabético <img src={UpArrow} alt="" />
											</Dropdown.Item>
											<Dropdown.Item eventKey={"nameDown"}>
												<img src={Icono1} alt="" style={{ width: "1rem" }} /> Alfabético <img src={DownArrow} alt="" />
											</Dropdown.Item>
											<Dropdown.Item eventKey={"difficultyUp"}>
												<img src={Dominated3n} alt="-3" style={{ width: "1rem" }} /> Dificultad <img src={UpArrow} alt="" />
											</Dropdown.Item>
											<Dropdown.Item eventKey={"difficultyDown"}>
												<img src={Dominated3} alt="3" style={{ width: "1rem" }} /> Dificultad <img src={DownArrow} alt="" />
											</Dropdown.Item>
											<Dropdown.Item eventKey={"dominatedUp"}>
												<img src={Icono4} alt="" style={{ width: "1rem" }} /> Dominio <img src={UpArrow} alt="" />
											</Dropdown.Item>
											<Dropdown.Item eventKey={"dominatedDown"}>
												<img src={Icono3} alt="" style={{ width: "1rem" }} /> Dominio <img src={DownArrow} alt="" />
											</Dropdown.Item>
										</DropdownButton>
									</div>
									{(categories.includes(lang) || lang === "all") && (
										<div className="select-lang-filter-container">
											<label htmlFor="lang-selector">Idioma</label>
											<div className="select-container">
												<MultiSelect words={words} selectedLanguages={selectedLang} setSelectedLang={setSelectedLang} />
											</div>
										</div>
									)}
								</div>
								<div className="words-container-main">
									<div className="words-container">
										{detailedArray.length > 0 &&
											detailedArray.map((word, index) => {
												return (
													<div className="text-row w-100" key={"word-dominated-" + index}>
														<div className="d-flex w-100" style={{ gap: 7 }}>
															{/* word.optional_word !== null ? { color: '#00000080' } */}
															<p
																className={
																	word.dominated === 3
																		? "dominated"
																		: word.optional_word !== null && word.optional_word !== ""
																		? "gray"
																		: ""
																}
																onClick={() => setWordInfoModal({ show: true, word: word, index: index })}
																style={{ cursor: "pointer" }}
															>
																{word.dominated === 3 && <img src={CheckmarkCircle} alt="palomita" />}
																<div
																	className="difficult-color-circle"
																	style={{
																		background: getDifficultColor(
																			word.times_answered === 0 ? 1.01 : parseFloat(word.custom_difficulty)
																		),
																	}}
																></div>
																{word.optional_word !== null && word.optional_word !== "" && (
																	<span className={word.dominated === 3 ? "" : "black"} style={{ fontSize: "0.875rem" }}>
																		{word.optional_word}
																	</span>
																)}
																{parse(
																	`<span>${
																		(word.optional_word !== null && word.optional_word !== "" && word.language === "japanese"
																			? "(" + word.native_word + ")"
																			: word.native_word) + `<sup>${word.dominated === null ? 0 : word.dominated}</sup>`
																	}<span>`
																)}
															</p>
														</div>
													</div>
												);
											})}
									</div>
								</div>
							</div>
						</div>
						<div className="footer">
							<div className="go-back-container">
								<p onClick={() => setView(0)}>
									<img src={ArrowBack} alt="flecha pauntando hacia la izquierda" /> Detalles del Seminario
								</p>
							</div>
							<div className="title mt-4">
								<h3>Dominados</h3>
								<h3>Por dominar</h3>
							</div>
							<div className="bar-container">
								<p className="words-completed">{getLangArray(lang, words)!.filter((e) => e.dominated === 3).length}</p>
								<div className="words-progress-bar">
									<div
										className="word-progress"
										style={{
											width:
												(getLangArray(lang, words)!.filter((e) => e.dominated === 3).length / getLangArray(lang, words)!.length) *
													100 +
												"%",
										}}
									></div>
								</div>
								<p className="left-words">{getLangArray(lang, words)!.length}</p>
							</div>
						</div>
					</>
				)}
				{view === 2 && (
					<>
						<WordInfoModal
							show={wordInfoModal.show}
							onHide={() => setWordInfoModal({ ...wordInfoModal, show: false, index: 0 })}
							word={wordInfoModal.word}
							gKey={gKey}
							setState={setDetailedArray}
							state={detailedArray}
							index={wordInfoModal.index}
							setCountRefresh={setCountRefresh}
							countRefresh={countRefresh}
						/>
						<div className="content-container">
							{searchWord.length > 0 &&
								filterWord.map((word, index) => {
									return (
										<div className="text-row w-100" key={"word-dominated-" + index}>
											<div className="w-full">
												<div
													className={`searchtable ${
														word.dominated === 3
															? "dominated"
															: word.optional_word !== null && word.optional_word !== ""
															? "gray"
															: ""
													}`}
													onClick={() => setWordInfoModal({ show: true, word: word, index: index })}
													style={{ cursor: "pointer", textAlign: "center" }}
												>
													<p className="cell-languages justify-content-center">{returnFlagByLanguage(word.language)}</p>
													<div
														className="difficult-color-circle"
														style={{
															background: getDifficultColor(
																word.times_answered === 0 ? 1.01 : parseFloat(word.custom_difficulty)
															),
														}}
													></div>
													<p className={word.dominated === 3 ? "red" : "black"}>
														{word.optional_word !== null &&
														word.optional_word !== "" &&
														(word.language === "japanese" || word.language === "chinese")
															? word.optional_word
															: word.native_word}{" "}
														<span
															className={word.dominated === 3 ? "dominated" : "gray"}
															style={
																word.language === "japanese" || word.language === "chinese"
																	? { color: "gray" }
																	: { color: "black" }
															}
														>
															{word.optional_word !== null &&
																word.optional_word !== "" &&
																(word.language === "japanese" || word.language === "chinese") &&
																"(" + word.native_word + ")"}{" "}
															<sup>{word.dominated === null ? 0 : word.dominated}</sup>
														</span>
													</p>
													<p className="meaning-container">{word.meaning.map((meaning, index) => meaning).join(", ")}</p>
												</div>
											</div>
										</div>
									);
								})}
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default DetailSeminaryCard;
