import { Link } from "react-router-dom";
import "./GenerationsCardStyle.scss";

function GenerationsCard({data}) {

    function getTitle(name) {
        let splitName = name.split(' - ');
        return splitName[0];
    }

    function getSubtitle(name) {
        let splitName = name.split(' - ');
        let subtitle = "";
        for (let index = 1; index < splitName.length; index++) {
            subtitle = subtitle + splitName[index] + (index < splitName.length - 1 ? " - " : "")
        }
        return subtitle;
    }

  return (
    <div className="generations-card">
      <div className="generations-card-left text-center m-auto">
      <h2>{getTitle(data.name)}</h2>
      <h2 style={{ color: '#000000' }}>{getSubtitle(data.name)}</h2>
        <p>{data.numStudents} estudiantes</p>
      </div>
      <div className="generations-card-right">
      <Link to={`/admin/vocabulario/generacion/${data.id}`}>
      <button>Revisar</button>
      </Link>
      </div>
    </div>
  );
}
export default GenerationsCard;
