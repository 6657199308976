import { areWordsDominated } from "@api/Vocabulary";
import { Spinner20vh } from "@components/Spinner/SpinnerMyAccountView";
import { CheckmarkGreen, ListIconVocabulary } from "assets/Imports";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getImage, returnFlag, romanize } from "functions/Functions";
import { db } from "initFirebase";
import { quizWords, segmentsQuiz } from "interfaces/Interfaces";
import { useEffect, useState } from "react";
import { getLangSpanish } from "../../../../components/Vocabulary/PersonalizedTest/functions/functions";
import {
  getUniqueTotalWords,
  getUniqueTotalWordsArray,
} from "../Test/functions/TestFunctions";
import "./DefaultQuizzesStyles.scss";
import { Progress, Tooltip } from "antd";
import "react-circular-progressbar/dist/styles.css";

interface params {
  num: number;
  setView: React.Dispatch<React.SetStateAction<number>>;
  user: any;
  setTestId: React.Dispatch<React.SetStateAction<string>>;
  defaultQuizzes: Array<defaultQuiz>;
  totalDefaultsCompleted: number;
  totalDefaults: number;
}

export interface counts{
  language: string,
  total: number,
  completed: number
}

export interface defaultQuiz {
  id: string;
  categories: Array<string>;
  difficult: string;
  language: string;
  subcategories: Array<string>;
  quizWords: segmentsQuiz;
  seminary: string;
  completed: boolean;
  answerType: string;
  name: string;
  version: number;
  differentVersionCompleted?: boolean;
  dominated?: number;
  totalWords?: number;
}

export function DefaultQuizzes(props: params) {
  const [quizzes, setQuizzes] = useState<Array<defaultQuiz>>(new Array());
  const [loading, setLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<number>(1);
  const [languagesCount, setLanguagesCount] = useState<Array<counts>>();

  const getDefaultQuizzes = async () => {
    setQuizzes(props.defaultQuizzes);
    setLoading(false);
  };
  const createDefaultQuiz = async (quiz: defaultQuiz) => {
    const docRef = await addDoc(collection(db, "quiz"), {
      answerType: quiz.answerType,
      difficult: new Array(),
      quizWords: quiz.quizWords,
      userId: props.user.id,
      language: quiz.language,
      name:
        quiz.name !== "Ronda personalizada"
          ? quiz.name
          : "Ronda de estudio predeterminada",
      seminaryNum: parseInt(quiz.seminary),
      correctWords: new Array(),
      incorrectWords: new Array(),
      index: 0,
      actualSegment: 0,
      finished: false,
      createdAt: new Date(),
      finishedAt: null,
      default: true,
      finishedDefault: false,
      defaultVersion: quiz.version,
      defaultId: quiz.id,
    });

    props.setTestId(docRef.id);
    props.setView(3);
  };

  const filtering = (filter:number) => {
    setFilter(filter);
    let tempQuizzes:Array<defaultQuiz> = props.defaultQuizzes;
    let tempQuizzes2;
    switch(filter){
      case 1: // All quizzes
        tempQuizzes2 = tempQuizzes;
        break;

      case 3: // Quizzes left to study
        tempQuizzes2 = tempQuizzes.filter((e) => {
          return !e.completed;
        })
        break;
      default:
        break;
    }
    setQuizzes(tempQuizzes2)
  }

  const handleSingleLanguageFilter = (language:string) => {
    setFilter(4);
    let tempQuizzes:Array<defaultQuiz> = props.defaultQuizzes;
    let tempQuizzes2 = tempQuizzes.filter((e) => {
          return e.language === language;
        })
    setQuizzes(tempQuizzes2)
  }

  const handleLanguageFilter = () => {
    setFilter(2);
    return;
  }

  const getDefaultQuizzesCounts = () => {
    let tempLanguagesCount:Array<counts> = props.defaultQuizzes.reduce((accumulator:any, current) => {
      let existing = accumulator.find((e) => e.language === current.language)
      if (existing){
        existing.total += 1;
        if (current.completed){
          existing.completed += 1;
        }
      } else {
        accumulator.push({
          language: current.language,
          completed: current.completed ? 1 : 0,
          total: 1
        })
      }
    
      return accumulator;
    }, []);
    setLanguagesCount(tempLanguagesCount);
  }

  useEffect(() => {
    getDefaultQuizzes();
    getDefaultQuizzesCounts();
  }, [props.defaultQuizzes]);

  return (
    <div className="vocabulary-seminary-main-container default-quizzes-main-container">
      <div className="header-container">
        <div className="top">
          <button className="goback-btn" onClick={() => props.setView(1)}>
            Atrás
          </button>
          <div className="default-quizzes-progress-counter">
            <h3>{props.totalDefaultsCompleted}<span>/</span>{props.totalDefaults}</h3>
            <h4>terminadas</h4>
          </div>
        </div>
        <div className="title-container">
          <h1 className="d-flex align-items-center">
            <img src={ListIconVocabulary} alt="icono de lista" />{" "}
            Seminario {romanize(props.num)}
          </h1>
        </div>
      </div>
      <div className="default-quizzes-cards-container">
       {/* <div className="title-container">
          <p>Selecciona la ronda de estudio del idioma que quieres resolver.</p>
        </div>*/}
        {loading || quizzes.length === 0 ? (
          <Spinner20vh />
        ) : (
        <>
          <div className="default-quizzes-filter-btns">
            <div
                className={"btn-1" + (filter === 1 ? ' active' : '')}
                onClick={() => filtering(1)}  
              >
                <h3>Todas</h3>
            </div>
            <div
                className={"btn-2" + (filter === 2 ? ' active' : '')}
                onClick={handleLanguageFilter}
              >
                <h3>Por idioma</h3>
            </div>
            <div
                className={"btn-3" + (filter === 3 ? ' active' : '')}
                onClick={() => filtering(3)}
              >
                <h3>Por estudiar</h3>
            </div>
          </div>
          <div className="cards-container">
              {filter !== 2 ? (quizzes.map((quiz, index) => {
                return (

                  <div
                    className="card-quiz"
                    key={"default-quiz-card-" + index}
                    onClick={() => createDefaultQuiz(quiz)}
                  >
                    <div className="flag">{returnFlag(quiz.language)}</div>
                    <div className="info">
                      <h2>
                        {quiz.name !== undefined && quiz.name.length > 0
                          ? quiz.name
                          : "Ronda de estudio"}{" "}
                        - {getLangSpanish(quiz.language, quiz.answerType)}
                      </h2>
                      <h3>{quiz.totalWords} términos</h3>
                      <h3 className="progress-hover ">{!quiz.completed ? quiz.dominated + '/' + quiz.totalWords + " términos dominados" : ""}</h3>
                    </div>
                    <div className="completed">
                      {quiz.completed && (
                        <>
                          <img
                            src={CheckmarkGreen}
                            alt="icono de completado" />
                          <p>completado</p>
                        </>
                      )}
                      {!quiz.completed && quiz.differentVersionCompleted && (
                        <>
                          <img
                            src={CheckmarkGreen}
                            alt="icono de completado" />
                          <p className="text-center" style={{ maxWidth: 130 }}>
                            Completaste una versión anterior
                          </p>
                        </>
                      )}
                    </div>
                    <div className="words-progress-bar-container">
                      <div className="words-progress-bar dominated">
                        <div
                          className="words-progress"
                          style={{
                            width: (quiz.dominated! / quiz.totalWords!) * 100 + "%",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              })): languagesCount?.map((lang) => {
                let progress:number = lang.completed/lang.total * 100;
                return (
                  <div className="card-quiz" onClick={() => handleSingleLanguageFilter(lang.language)}>
                    <div className="flag">
                        <Progress
                            percent={parseInt(progress.toFixed(0))}
                            // success={{
                            //   percent: progress,
                            //   strokeColor: progress < 100 ? "#ca5050" : "#efc900",
                            // }}
                            type="circle"
                            width={70}
                            strokeColor="#ca5050"
                            strokeWidth={10}
                            trailColor="#999999"
                            showInfo={true}
                          />
                      {returnFlag(lang.language)}
                    </div>
                    <div className="info">
                      <h2>
                        {getLangSpanish(lang.language)}
                      </h2>
                      <h3>{lang.completed} / {lang.total} listas completadas</h3>
                    </div>
                  </div>
                )
              })}
            </div>
        </>
        )}
      </div>
    </div>
  );
}
