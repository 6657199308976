import { useEffect, useState } from "react";
import "./styles.scss";
import { NewGenModal } from "./components/NewGenModal/NewGenModal";
import { Link, useHistory } from "react-router-dom";
import { compareGenerations, getSubtitle } from "functions/Generations/FunctionsGenerations";
import { EyeIcon } from "assets/Imports";
import FilterGenerations from "@components/FilterGenerations/FilterGenerations";
import { useGenerations } from "@hooks/GenerationsFilters/useGenerations";

function GenerationsContainer({ user }) {
	const [modalShow, setModalShow] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const history = useHistory();
	const { gens, eyeColor, handleSelect, filterEs, getGenerations } = useGenerations(compareGenerations);
	function getTitle(name) {
		let splitName = name.split(" - ");

		return splitName[0];
	}

	return (
		<>
			<NewGenModal show={modalShow} onHide={() => setModalShow(false)} setRefresh={setRefresh} getGenerations={getGenerations} />
			<h1>Generaciones</h1>
			<div className="generations-subtitle-container">
				<p>Grupos de estudiantes especializados.</p>
				<FilterGenerations
					EyeIcon={EyeIcon}
					eyeColor={eyeColor}
					filterEs={filterEs}
					handleSelect={handleSelect}
					setModalShow={setModalShow}
				/>
			</div>
			{gens.length > 0 && (
				<>
					{gens.map((gen, index) => {
						return (
							<>
								<div className="generations-card" key={"generations-cards-" + index}>
									<div className="generations-card-left text-center m-auto">
										<h2>{getTitle(gen.name)}</h2>
										<h2 style={{ color: "#000000" }}>{gen.name.includes("-") ? getSubtitle(gen.name) : ""}</h2>
										<p>{gen.numStudents} estudiantes</p>
									</div>
									<div className="generations-card-right">
										<h3>{gen.cirilic || ""}</h3>
										<Link to={"/admin/generacion/" + gen.id}>
											<button>Revisar</button>
										</Link>
									</div>
								</div>
							</>
						);
					})}
				</>
			)}
		</>
	);
}

export default GenerationsContainer;
