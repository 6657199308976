export const languagesCodes = {
    french: {
        langCode: "fr-FR",
        name: "fr-FR-Wavenet-E"
    },
    german: {
        langCode: "de-DE",
        name: "de-DE-Standard-A"
    },
    italian: {
        langCode: "it-IT",
        name: "it-IT-Wavenet-A"
    },
    russian: {
        langCode: "ru-RU",
        name: "ru-RU-Standard-A"
    },
    portuguese: {
        langCode: "pt-BR",
        name: "pt-BR-Wavenet-A"
    },
    english: {
        langCode: "en-US",
        name: "en-US-Standard-A"
    },
    spanish: {
        langCode: "es-ES",
        name: "es-ES-Wavenet-C"
    },
    chinese: {
        langCode: "cmn-CN",
        name: "cmn-CN-Wavenet-D"
    },
    japanese: {
        langCode: "ja-JP",
        name: "ja-JP-Neural2-C"
    }
}