import PeapleReading from "../images/person-reading.png";
import PersonLaptop from "../images/person-laptop.png";
import BgModule1 from "../images/bg-module-1.svg";
import Module2Bg from "../images/circles-bg.svg";
import ThreePeople from "../images/three-people.png";
import TopSquares from "../images/top-squares.svg";
import MiddleSquares from "../images/middle-squares.svg";
import BottomSquares from "../images/bottom-squares.svg";
import GirlWithBooks from "../images/girl-with-books.png";
import CirclesRow from "../images/circles-row.svg";
import TwoGirls from "../images/two-girls.png";
import RightWaves from "../images/waves-right.svg";
import MiddleWaves from "../images/middle-waves.svg";
import TopWaves from "../images/top-waves.svg";

export const modules = [
	{
		number: 1,
		title: "Habla, lee, escribe y traduce en 8 idiomas",
		romanNumber: "I",
		duration: "10 sesiones (20 horas)",
		skills: [
			"Comprender  usar expresiones cotidianas de uso frecuente.",
			"Presentarse a ti mismo y a otros.",
			"Describir el día a día con oraciones y palabras básicas.",
			"Comunicar necesidades, pedir y dar información básica.",
		],
		images: [
			{
				class: "module-1-person-reading",
				url: PeapleReading,
			},
			{
				class: "module-1-person-bg",
				url: BgModule1,
			},
		],
	},
	{
		number: 2,
		title: "Pensar e interpretar en 8 idiomas",
		romanNumber: "II",
		duration: "15 sesiones (30 horas)",
		skills: [
			"Opinar sobre las situaciones cotidianas.",
			"Describir eventos, situaciones y experiencias pasadas con oraciones de baja complejidad gramatical.",
			"Uso modesto de oraciones subordinadas.",
			"Expresarse con un lenguaje cotidiano y simple.",
		],
		images: [
			{
				class: "module-2-person-laptop",
				url: PersonLaptop,
			},
			{
				class: "module-2-bg",
				url: Module2Bg,
			},
		],
	},
	{
		number: 3,
		title: "Dominar 8 idiomas simultáneamente",
		romanNumber: "III",
		duration: "15 sesiones (30 horas)",
		skills: [
			"Expresar eventos hipotéticos.",
			"Empleo común de oracinoes subordinadas con mediana complejidad gramatical.",
			"Mantener una conversación social breve con un hablante extranjero.",
			"Capacidad de enseñar lo aprendido a un tercero.",
			"Utilizar paralelismos entre idiomas similares.",
		],
		images: [
			{
				class: "module-3-main-image",
				url: ThreePeople,
			},
			{
				class: "top-squares",
				url: TopSquares,
			},
			{
				class: "middle-squares",
				url: MiddleSquares,
			},
			{
				class: "bottom-squares",
				url: BottomSquares,
			},
		],
	},
	{
		number: 4,
		title: "El beneficio de ser políglota",
		romanNumber: "IV",
		duration: "10 sesiones (20 horas)",
		skills: [
			"Expresarse con vocabulario variado.",
			"Cambiar de idioma al conversar.",
			"Uso común de oraciones subordinadas.",
			"Uso de expresiones idiomáticas.",
		],
		images: [
			{
				class: "module-4-main-image",
				url: GirlWithBooks,
			},
			{
				class: "row-right circles-row",
				url: CirclesRow,
			},
			{
				class: "row-left circles-row",
				url: CirclesRow,
			},
			{
				class: "row-right circles-row",
				url: CirclesRow,
			},
			{
				class: "row-left circles-row",
				url: CirclesRow,
			},
			{
				class: "row-right circles-row",
				url: CirclesRow,
			},
			{
				class: "row-left circles-row",
				url: CirclesRow,
			},
			{
				class: "row-right circles-row",
				url: CirclesRow,
			},
			{
				class: "row-left circles-row",
				url: CirclesRow,
			},
			{
				class: "row-right circles-row",
				url: CirclesRow,
			},
			{
				class: "row-left circles-row",
				url: CirclesRow,
			},
			{
				class: "row-right circles-row",
				url: CirclesRow,
			},
			{
				class: "row-left circles-row",
				url: CirclesRow,
			},
			{
				class: "row-right circles-row",
				url: CirclesRow,
			},
			{
				class: "row-left circles-row",
				url: CirclesRow,
			},
		],
	},
	{
		number: 5,
		title: "Traducción e interpretación simultánea de 8 idiomas",
		romanNumber: "V",
		duration: "10 sesiones (20 horas)",
		skills: ["Traducción e interpretación de 8 idiomas.", "Comunicarse con firmeza en 8 idiomas."],
		images: [
			{
				class: "module-5-main-image",
				url: TwoGirls,
			},
			{
				class: "right-waves",
				url: RightWaves,
			},
			{
				class: "middle-waves",
				url: MiddleWaves,
			},
			{
				class: "top-waves",
				url: TopWaves,
			},
		],
	},
];
