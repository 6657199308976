import { useAuth } from "@hooks/useAuth";
import { differenceInCalendarDays, format, parse, startOfDay } from "date-fns";
import { DocumentData, DocumentSnapshot, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";

type StreakContextType = {
	vocabularyStreak: Array<string>;
	studiedDays: Array<string>;
	isLoading: boolean;
	validateStreak: () => void;
	addDateStreak: () => void;
};

const StreakContext = createContext<StreakContextType>({
	vocabularyStreak: [],
	studiedDays: [],
	isLoading: false,
	validateStreak: () => {},
	addDateStreak: () => {},
});

export const useStreakContext = () => {
	return useContext(StreakContext);
};

const VocabularyStreakContextProvider = ({ children }: { children: ReactNode }) => {
	const [vocabularyStreak, setVocabularyStreak] = useState<Array<string>>([]);
	const [studiedDays, setStudiedDays] = useState<Array<string>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { user } = useAuth();

	useEffect(() => {
		if (user) {
			getStreak();
		}
	}, [user]);

	useEffect(() => {
		if (vocabularyStreak.length > 0) {
			validateStreak();
		} else {
			setIsLoading(false);
		}
	}, [vocabularyStreak]);

	const getStreak = async () => {
		const userDocRef = doc(db, "users", user.id);
		setIsLoading(true);
		const qSnap: DocumentSnapshot<DocumentData> = await getDoc(userDocRef);
		if (qSnap.exists()) {
			const data: any = {
				id: qSnap.id,
				...qSnap.data(),
			};
			if (data.vocabularyStreak !== undefined) {
				setVocabularyStreak(data.vocabularyStreak);
			} else {
				await updateDoc(userDocRef, { vocabularyStreak: [] });
			}

			if (data.studiedDays !== undefined) {
				setStudiedDays(data.studiedDays);
			} else {
				await updateDoc(userDocRef, { studiedDays: [] });
				setIsLoading(false);
			}
		}
	};

	const clearStreak = async () => {
		const userDocRef = doc(db, "users", user.id);
		await updateDoc(userDocRef, { vocabularyStreak: [] });
		setVocabularyStreak([]);
	};

	const validateStreak = async () => {
		const tempStreak = [...vocabularyStreak];
		if (tempStreak.length === 0) {
			setIsLoading(false);
			return;
		}

		// Ensure the date format matches your stored date strings
		const dateFormat = "MM/dd/yyyy";
		const lastDateString = tempStreak[tempStreak.length - 1];

		// Parse the last date in local time
		const lastDate = startOfDay(parse(lastDateString, dateFormat, new Date()));

		// Get today's date at the start of the day in local time
		const today = startOfDay(new Date());

		// Optional: Log the dates for debugging
		console.log("Last studied date (local):", lastDate);
		console.log("Today's date (local):", today);

		// Calculate the difference in calendar days
		const differenceDays = differenceInCalendarDays(today, lastDate);

		console.log("Days without studying:", differenceDays);

		if (differenceDays >= 2) {
			console.log("You have lost your streak");
			// Only clear the streak if it hasn't already been cleared
			if (vocabularyStreak.length > 0) {
				await clearStreak();
				// Optionally, update local state to reflect the cleared streak
				setVocabularyStreak([]);
			}
		}

		setIsLoading(false);
	};

	const addDateStreak = async () => {
		let tempStreak = [...vocabularyStreak];
		let tempStudied = [...studiedDays];

		const dateFormat = "MM/dd/yyyy";
		const date = startOfDay(new Date());
		const stringDate = format(date, dateFormat); // "mm/dd/yyyy"
		console.log("today string date", stringDate);

		let updated = false; // Flag to track if any updates are made

		// Ensure that we don't add duplicate dates for the same day to studiedDays
		if (!tempStudied.includes(stringDate)) {
			tempStudied.push(stringDate);
			updated = true;
		}

		let lastDate: Date | null = null;
		if (tempStreak.length > 0) {
			lastDate = startOfDay(parse(tempStreak[tempStreak.length - 1], dateFormat, new Date()));
		}

		console.log("lastDate", lastDate);
		let differenceDays: number | null = null;
		if (lastDate) {
			differenceDays = differenceInCalendarDays(date, lastDate);
		}

		if (differenceDays === null || differenceDays === 1) {
			// Continue the streak
			if (!tempStreak.includes(stringDate)) {
				tempStreak.push(stringDate);
				updated = true;
			}
		} else if (differenceDays === 0) {
			// Same day, no action needed
		} else if (differenceDays >= 2) {
			// Missed at least one day, reset the streak
			console.log("restarted streak");
			tempStreak = [stringDate];
			updated = true;
		}

		if (updated) {
			// Only update the database if there are changes
			const userDocRef = doc(db, "users", user.id);
			await updateDoc(userDocRef, {
				vocabularyStreak: tempStreak,
				studiedDays: tempStudied,
			});
			setVocabularyStreak(tempStreak);
			setStudiedDays(tempStudied);
		}
	};

	return (
		<StreakContext.Provider
			value={{
				vocabularyStreak,
				isLoading,
				studiedDays,
				validateStreak,
				addDateStreak,
			}}
		>
			{children}
		</StreakContext.Provider>
	);
};

export default VocabularyStreakContextProvider;
