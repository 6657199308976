import axios from "axios";
import { SimpleDate } from "interfaces/Generations";

const randomString = (length) => {
	const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
	var result = "";
	for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
	return result;
};

export default randomString;

// Esta funcion nos regresa el año correspondiente al aniversario actual, en string
// Con esta función cambiamos texto en la plataforma, en imagenes correspondientes
export const getCurrentAnniversary = (): string => {
	const today = new Date();
	const anniversaryDate = new Date(1989, 5, 13); // June 13th of the current year

	// Check if today's date is before the anniversary date
	if (today < anniversaryDate) {
		anniversaryDate.setFullYear(today.getFullYear() - 1); // If the anniversary hasn't occurred yet, subtract one year
	}

	const anniversaryYear = today.getFullYear() - anniversaryDate.getFullYear();
	return anniversaryYear.toString();
};

// Funcion que recibe un objeto de tipo fecha y regresa la fecha en formato "DD/MM/YYYY" (como los asuetos de las generaciones)
export const getSimpleDate = (date: Date): SimpleDate => {
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = String(date.getFullYear());

	return `${day}/${month}/${year}` as SimpleDate;
};

export const getUserCountryByIP = async (): Promise<string> => {
	try {
		const response = await axios.get("https://api.country.is/");
		return response.data.country as string;
	} catch (error) {
		console.error(error);
		return "MX";
	}
};
