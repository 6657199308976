import CustomCard from "@components/Shared/CustomCard/CustomCard";
import { Testimonials, WaveOther } from "assets/Imports";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import { db } from "../../initFirebase";
import "./styles.scss";

function TestimonialsContainer() {
  const [testimonials, setTestimonials] = useState<any[]>([]);
  const [testimonials2, setTestimonials2] = useState<any[]>([]);
  // const [lengthTestimonials, setLengthTestimonials] = useState<number>(0);
  // const [randomNumbers, setRandomNumbers] = useState<number[]>([]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 2000 },
      items: 3,
    },
    laptop: {
      breakpoint: { max: 2000, min: 1316 },
      items: 3,
    },
    minitablet: {
      breakpoint: { max: 1316, min: 880 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 888, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const getTestimonials = async () => {
    db.collection("testimonials").onSnapshot((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setTestimonials(data);
    });

    db.collection("testimonials2").onSnapshot((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setTestimonials2(data);
    });
  };

  // const getThreeRandomNum = () => {
  //   var arr: number[] = [];
  //   let variable = lengthTestimonials;
  //   while (arr.length < 3) {
  //     var r = Math.floor(Math.random() * variable) + 1;
  //     if (arr.indexOf(r) === -1) arr.push(r);
  //   }
  //   setRandomNumbers(arr);
  // };

  useEffect(() => {
    getTestimonials();
    // setLengthTestimonials(testimonials.length);
    // getThreeRandomNum();
  }, []);

  return (
    <div>
      <div className="header__testimonials">
        <Container>
          <h1>Lo que nuestros alumnos opinan</h1>
        </Container>
      </div>
      <img src={WaveOther} alt="Logo" className="wave" />
      <Container className="carousel__container">
        <h2 className="d-block d-sm-none">
          Desliza hacia la derecha para ver más testimonios <FaArrowRight color={"#000"} />
        </h2>
        <Carousel responsive={responsive} arrows={false} infinite={true} autoPlay={true}>
          {React.Children.toArray(
            testimonials.map((item) => {
              return (
                <div>
                  <CustomCard key={item.id} name={item.name} desc={item.desc} />
                </div>
              );
            })
          )}
        </Carousel>
        <div className="section__two">
          <h3>Otras historias de estudiantes</h3>
          <Row>
            <Col lg={9} xs={12} md={12}>
              {React.Children.toArray(
                testimonials2.slice(0, 3).map((item, index) => {
                  return (
                    <div>
                      <Col xl={12} lg={12} xs={12} md={12}>
                        <Card style={{ width: "56rem", height: "auto" }} className={`card__${index} shadow`}>
                          <Card.Body>
                            <Card.Text>
                              <p>{item.desc}</p>
                              <p>
                                <b>{item.name}</b>
                              </p>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </div>
                  );
                })
              )}
            </Col>
            <Col lg={3} xs={12} md={12}>
              <div className="image__container">
                <Image fluid src={Testimonials} alt="Graduados img" />
              </div>
            </Col>
            <div className="image__container-xs">
              <Image fluid src={Testimonials} alt="Graduados img" />
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default TestimonialsContainer;
