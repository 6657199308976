import "./AsideNav.scss";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useParams,
	useRouteMatch,
	Link,
	useHistory,
	useLocation,
} from "react-router-dom";
import { useEffect, useRef, useState, useContext } from "react";
import { MenusDataContext } from "@containers/Admin/CollaboratorContainer/context/menu-data.provider";
import { useAuth } from "@hooks/useAuth";
import { getAuth, signOut } from "firebase/auth";
import { propTypes } from "react-bootstrap/esm/Image";
import {
	collection,
	doc,
	getDoc,
	limit,
	onSnapshot,
	orderBy,
	getDocs,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { db } from "../../initFirebase";
import {
	BrainIcon,
	Cardicon,
	CashIcon,
	ConsultsIcon,
	DownArrow,
	FinanzasIcon,
	GrabacionesIcon,
	HistoryPayments,
	IconUserWhite,
	InvoiceIcon,
	ListIcon,
	LogoutIcon,
	MinusIcon,
	NotificationsIcon,
	OpenBook,
	PlusIcon,
	ProgresoIcon,
	StreakFlameIcon,
	TemarioIcon,
	UserIcon,
} from "assets/Imports";
import { handleOutsideClick } from "functions/HandleClicks";
import { useStreakContext } from "contexts/VocabularyStreakContext";

interface AsideNavI {
	user: any;
	className?: any;
	path?: any;
	rutasAccesibles?: any;
}

function AsideNav({ user, className = "", path, rutasAccesibles }: AsideNavI) {
	let { url } = useRouteMatch();
	if (url === "/") {
		url = "/micuenta";
	}

	const showMoreLocalStorage =
		localStorage.getItem("showMoreAsideBar") === "true" ||
		localStorage.getItem("showMoreAsideBar") === null
			? true
			: false;
	const [active, setActive] = useState<any>(null);
	const history = useHistory();
	const [notSeenNotificationsCount, setNotSeenNotificationsCount] = useState<number>(0);

	const [openSubmenu, setOpenSubMeno] = useState<boolean>(false);
	const [showMore, setShowMore] = useState<boolean>(showMoreLocalStorage);
	const [studiedDays, setStudiedDays] = useState<Array<Date>>(user.studiedDays);
	const userDocRef = doc(db, "users", user.id);
	const { notificationOpen, setNotificationOpen, asideOpen, setAsideOpen, asideBtnRef, logout } =
		useAuth();
	const asideContainerRef = useRef<any>();
	const location = useLocation();
	const [userMode, setUserMode] = useState<boolean>(false);
	const { vocabularyStreak } = useStreakContext();

	const logOut = () => {
		localStorage.removeItem("showMoreAsideBar");
		const auth = getAuth();
		signOut(auth)
			.then(() => {
				// Sign-out successful.
				logout();
			})
			.catch((error) => {
				// An error happened.
			});
	};

	const getNotifications = async () => {
		let q;

		if (user.userType === 2 || user.userType === 4) {
			if (user.userType === 2 && !userMode) {
				q = query(
					collection(db, "notifications"),
					where("userId", "==", "admin"),
					orderBy("date", "desc"),
					limit(50)
				);
			} else if (userMode) {
				q = query(
					collection(db, "notifications"),
					where("userId", "==", user.id),
					orderBy("date", "desc"),
					limit(50)
				);
			} else if (user.userType === 4 && !userMode) {
				q = query(
					collection(db, "notifications"),
					where("userId", "==", "admin"),
					orderBy("date", "desc"),
					limit(50)
				);
			}

			if (q) {
				const suscribe = onSnapshot(q, (querySnapshot) => {
					const notifications: any = [];
					const countArray: any = [];

					querySnapshot.forEach((doc) => {
						const data = {
							id: doc.id,
							...doc.data(),
						};

						if (data.seen === false) {
							countArray.push(data);
						}

						notifications.push(data);
					});

					const notSeenCount = countArray.length;
					setNotSeenNotificationsCount(notSeenCount);
				});
			}
		} else {
			const q = query(
				collection(db, "notifications"),
				where("userId", "in", [user.id, user.userType !== 2 ? "all" : "admin"]),
				where("seen", "==", false),
				orderBy("date", "desc"),
				limit(50)
			);

			const suscribe = onSnapshot(q, (querySnapshot) => {
				setNotSeenNotificationsCount(querySnapshot.size);
			});
		}
	};

	const handleOutsideClickAside = (event: any) =>
		handleOutsideClick(event, asideBtnRef, setAsideOpen, asideContainerRef);

	useEffect(() => {
		getNotifications();
	}, []);

	useEffect(() => {
		if (window.location.href.indexOf("/metodos-de-pago") !== -1) {
			setActive(2);
		} else if (window.location.href.indexOf("/historial-de-pagos") !== -1) {
			setActive(3);
		} else if (window.location.href.indexOf("/actualizar-pagos") !== -1) {
			setActive(4);
		} else if (window.location.href.indexOf("/materiales") !== -1) {
			setActive(5);
		} else if (window.location.href.indexOf("/temario") !== -1) {
			setActive(6);
		} else if (window.location.href.indexOf("/consultas") !== -1) {
			setActive(7);
		} else if (window.location.href.indexOf("/notificaciones") !== -1) {
			setActive(8);
		} else if (window.location.href.indexOf("/vocabulario") !== -1) {
			setActive(9);
		} else if (window.location.href.indexOf("/grabaciones") !== -1) {
			setActive(13);
		} else {
			setActive(1);
		}
	}, [url]);

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClickAside);
		return () => {
			document.removeEventListener("mousedown", handleOutsideClickAside);
		};
	}, []);

	useEffect(() => {
		localStorage.setItem("showMoreAsideBar", JSON.stringify(showMore));
	}, [showMore]);

	useEffect(() => {
		setAsideOpen(false);
	}, [location.pathname]);

	const getFirstAccessibleRoutePath = () => {
		if (rutasAccesibles) {
			const accessibleRoutes: any = rutasAccesibles.filter(
				(ruta: any) => user?.permissions && user?.permissions?.includes(ruta.id)
			);

			if (accessibleRoutes.length > 0) {
				return `${"/admin"}${accessibleRoutes[0]?.Ruta}`;
			}
			// En caso de que no haya rutas accesibles, puedes manejar esto de acuerdo a tus necesidades
			return "/";
		}
		return "/";
	};

	return (
		<>
			<div className={"backdrop-asidenav " + (asideOpen ? "aside-nav-responsive" : "")}></div>
			<aside
				className={
					`aside-nav ${className} ` +
					(asideOpen === true ? " aside-nav-responsive " : "") +
					(showMore === true ? "" : " aside-nav-close ")
				}
				ref={asideContainerRef}
			>
				<ul>
					<li className="shuffle-aside-nav" onClick={() => setShowMore(!showMore)}>
						<div style={{ width: 30 }}>
							{showMore ? <img src={MinusIcon} /> : <img src={PlusIcon} />}
						</div>
						<span className={showMore ? "" : "d-none"}>Minimizar</span>
					</li>

					{(user.userType === 2 || user.userType === 4) && (
						<Link
							className={`${active === 11 ? "active" : ""}`}
							to={
								user?.permissions?.includes("mSu2DGYBzR6K1upbkaje") || user.userType === 2
									? "/admin"
									: getFirstAccessibleRoutePath()
							}
							onClick={() => setActive(11)}
						>
							<li>
								<div style={{ width: 30 }}>
									<img src={UserIcon} />
								</div>
								<span className={showMore ? "" : "d-none"}>Admin</span>
							</li>
						</Link>
					)}
					<Link
						className={`${active === 1 ? "active" : ""}`}
						to={`${url}`}
						onClick={() => setActive(1)}
					>
						<li>
							<div style={{ width: 30 }}>
								<img src={UserIcon} />
							</div>
							<span className={showMore ? "" : "d-none"}>Mi perfil</span>
						</li>
					</Link>
					<li
						className="notification-btn responsive"
						style={{ cursor: "pointer" }}
						onClick={() => {
							setNotificationOpen(true);
							setAsideOpen(false);
						}}
					>
						<div style={{ width: 30 }}>
							{notSeenNotificationsCount > 0 && (
								<div className="number-icon">{notSeenNotificationsCount}</div>
							)}
							<img
								style={{
									filter:
										"invert(100%) sepia(100%) saturate(0%) hue-rotate(48deg) brightness(104%) contrast(101%)",
								}}
								src={NotificationsIcon}
								alt="notification icon"
							/>
						</div>
						<span className={showMore ? "" : "d-none"}>Notificaciones</span>
					</li>
					<li
						className="notification-btn desk"
						style={{ cursor: "pointer" }}
						onClick={() => history.push("/micuenta/notificaciones")}
					>
						<div style={{ width: 30 }}>
							{notSeenNotificationsCount > 0 && (
								<div className="number-icon">{notSeenNotificationsCount}</div>
							)}
							<img
								style={{
									filter:
										"invert(100%) sepia(100%) saturate(0%) hue-rotate(48deg) brightness(104%) contrast(101%)",
								}}
								src={NotificationsIcon}
								alt="notification icon"
							/>
						</div>
						<span className={showMore ? "" : "d-none"}>Notificaciones</span>
					</li>
					<Link
						className={`${active === 5 ? "active" : ""}`}
						to={`${url}/materiales`}
						onClick={() => setActive(5)}
					>
						<li>
							<div style={{ width: 30 }}>
								<img src={OpenBook} />
							</div>
							<span className={showMore ? "" : "d-none"}>Materiales</span>
						</li>
					</Link>
					<Link
						className={`${active === 7 ? "active" : ""}`}
						to={`${url}/consultas`}
						onClick={() => setActive(7)}
					>
						<li>
							<div style={{ width: 30 }}>
								<img src={ConsultsIcon} />
							</div>
							<span className={showMore ? "" : "d-none"}>Consultas</span>
						</li>
					</Link>
					<Link
						className={`${active === 9 ? "active" : ""}`}
						to={`${url}/vocabulario`}
						onClick={() => setActive(9)}
					>
						<li>
							<div style={{ width: 30 }}>
								<img src={ListIcon} alt="" />
								{vocabularyStreak?.length !== undefined && vocabularyStreak.length > 0 && (
									<div className="streak-area">
										<img className="streak-icon" src={StreakFlameIcon} alt="icono de fuego" />
										<h2>{vocabularyStreak.length}</h2>
									</div>
								)}
							</div>
							<span className={"tex" + (showMore ? "" : " d-none")}>Vocabulario</span>
							<span
								className={showMore ? "" : "d-none"}
								style={{
									fontSize: "0.875rem",
									color: "#ca5050",
									textDecoration: "none",
									alignSelf: "end",
									marginLeft: 5,
								}}
							>
								BETA
							</span>
						</li>
					</Link>
					<Link
						className={`${active === 6 ? "active" : ""}`}
						to={`${url}/temario`}
						onClick={() => setActive(6)}
					>
						<li>
							<div style={{ width: 30 }}>
								<img src={TemarioIcon} />
							</div>
							<span className={showMore ? "" : "d-none"}>Temario</span>
						</li>
					</Link>
					<Link
						className={`${active === 13 ? "active" : ""}`}
						to={`${url}/grabaciones`}
						onClick={() => setActive(13)}
					>
						<li>
							<div style={{ width: 30 }}>
								<img src={GrabacionesIcon} />
							</div>
							<span className={showMore ? "" : "d-none"}>Grabaciones</span>
						</li>
					</Link>
					<div className={"sub-menu-item " + (openSubmenu ? "sub-menu-open" : "")}>
						<li className="sub-menu-action" onClick={() => setOpenSubMeno(!openSubmenu)}>
							<div style={{ width: 30 }}>
								<img src={FinanzasIcon} />
							</div>
							<span className={showMore ? "" : "d-none"}>Finanzas</span>
							<img
								className={"arrow-icon" + (showMore ? "" : " d-none")}
								src={DownArrow}
								alt="flecha para abrir sub menu"
							/>
						</li>
						<div className={"sub-menu-container" + (showMore ? "" : " pl-0")}>
							<Link
								className={`${active === 3 ? "active" : ""}`}
								to={`${url}/historial-de-pagos`}
								onClick={() => setActive(3)}
							>
								<li>
									<div style={{ width: 30 }}>
										<img src={HistoryPayments} />
									</div>
									<span className={showMore ? "" : "d-none"}>Historial de pagos</span>
								</li>
							</Link>
							<Link
								className={`${active === 4 ? "active" : ""}`}
								to={`${url}/actualizar-pagos`}
								onClick={() => setActive(4)}
							>
								<li>
									<div style={{ width: 30 }}>
										<img src={CashIcon} />
									</div>
									<span className={showMore ? "" : "d-none"}>Actualizar pagos</span>
								</li>
							</Link>
							<Link
								className={`${active === 2 ? "active" : ""}`}
								to={`${url}/metodos-de-pago`}
								onClick={() => setActive(2)}
							>
								<li>
									<div style={{ width: 30 }}>
										<img src={Cardicon} />
									</div>
									<span className={showMore ? "" : "d-none"}>Métodos de pago</span>
								</li>
							</Link>
							<Link
								className={`${active === 12 ? "active" : ""}`}
								to={`/facturacion`}
								onClick={() => setActive(12)}
							>
								<li>
									<div style={{ width: 30 }}>
										<img src={InvoiceIcon} />
									</div>
									<span className={showMore ? "" : "d-none"}>Facturación</span>
								</li>
							</Link>
						</div>
					</div>
					<li style={{ flexDirection: "column", alignItems: "flex-start", color: "gray" }}>
						<span className={showMore ? "" : "d-none"}>Próximamente...</span>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: 20,
								filter:
									"invert(56%) sepia(0%) saturate(0%) hue-rotate(164deg) brightness(90%) contrast(91%)",
							}}
						>
							<div style={{ width: 30 }}>
								<img src={ProgresoIcon} />
							</div>
							<p className={showMore ? "" : "d-none"} style={{ margin: 0 }}>
								Consulta tu progreso
							</p>
						</div>
						<div
							className="mt-2"
							style={{
								display: "flex",
								alignItems: "center",
								gap: 20,
								filter:
									"invert(56%) sepia(0%) saturate(0%) hue-rotate(164deg) brightness(90%) contrast(91%)",
							}}
						>
							<div style={{ width: 30 }}>
								<img src={BrainIcon} style={{ width: 30 }} />
							</div>
							<p className={showMore ? "" : "d-none"} style={{ margin: 0 }}>
								Pon a prueba tus conocimientos
							</p>
						</div>
					</li>

					<li style={{ cursor: "pointer" }} className="logout-btn-style" onClick={logOut}>
						<div style={{ width: 30 }}>
							<img src={LogoutIcon} />
						</div>
						<span className={showMore ? "" : "d-none"}>Cerrar sesión</span>
					</li>
				</ul>
			</aside>
		</>
	);
}

export default AsideNav;
