import "./Navbar.scss";
import FlagLogo from "@assets/logos/logo-academia.png";
import { ReactComponent as WhatsAppIcon } from "@assets/icons/whatsapp.svg";
import { ReactComponent as UserNav } from "@assets/icons/user-nav.svg";
import { ReactComponent as UserNavDesk } from "@assets/icons/user-nav-desk.svg";
import { NavLink, useLocation } from "react-router-dom";
import { BurgerBtn } from "@components/BugerBtn/BurgerBtn";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "@hooks/useAuth";
import { handleOutsideClick } from "functions/HandleClicks";
import { collection, limit, onSnapshot, orderBy, query, where, getDocs } from "firebase/firestore";
import { db } from "initFirebase";
import { Helmet } from "react-helmet";
import NotificationsContainer from "@containers/NotificationsContainer/NotificationsContainer";
import NotificationIcon from "@img/notifications-icon.svg";
import useTitleFromPath from "@hooks/useGetTitle";
import CurrencySelector from "./CurrencySelector/CurrencySelector";

//TODO: Agregar boton para cambiar divisa en vista responsive
function Navbar() {
	const [openNav, setOpenNav] = useState<boolean>(false);
	const [notifications, setNotifications] = useState<Array<any>>([]);
	const [notSeenNotificationsCount, setNotSeenNotificationsCount] = useState<number>(0);
	const navBtnRef = useRef<any>();
	const [userMode, setUserMode] = useState<boolean>(false);
	const navContainerRef = useRef<any>();
	const location = useLocation();
	const { setAsideOpen, asideOpen, asideBtnRef, user, notificationOpen, setNotificationOpen } = useAuth();
	const notificationBtnRef = useRef<any>();
	const notificationContainerRef = useRef<any>();

	const handleOpenNav = () => {
		setOpenNav(!openNav);
	};

	const title = useTitleFromPath(location.pathname);

	const handleOutsideClickAside = (event: any) => handleOutsideClick(event, navBtnRef, setOpenNav, navContainerRef);

	const handleOutsideClickNotifications = (event: any) =>
		handleOutsideClick(event, notificationBtnRef, setNotificationOpen, notificationContainerRef);

	const [searchActive, setSearchActive] = useState(false);
	const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 992);

	const handleResize = () => {
		setIsResponsive(window.innerWidth <= 992);
		setOpenNav(false);
	};
	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		const handleKeyUp = (event) => {
			if (event.key === "Escape") {
				setSearchActive(false);
			}
		};
		//document.addEventListener("keydown", handleKeyDown);
		document.addEventListener("keyup", handleKeyUp);

		return () => {
			//document.removeEventListener("keydown", handleKeyDown);
			document.removeEventListener("keyup", handleKeyUp);
		};
	}, []);

	const getNotifications = async () => {
		let q;

		if (user.userType === 2 || user.userType === 4) {
			if (user.userType === 2 && !userMode) {
				q = query(collection(db, "notifications"), where("userId", "==", "admin"), orderBy("date", "desc"), limit(50));
			} else if (userMode) {
				q = query(collection(db, "notifications"), where("userId", "==", user.id), orderBy("date", "desc"), limit(50));
			} else if (user.userType === 4 && !userMode) {
				q = query(collection(db, "notifications"), where("userId", "==", "admin"), orderBy("date", "desc"), limit(50));
			}

			if (q) {
				const suscribe = onSnapshot(q, (querySnapshot) => {
					const notifications: any = [];
					const countArray: any = [];

					querySnapshot.forEach((doc) => {
						const data = {
							id: doc.id,
							...doc.data(),
						};

						if (data.seen === false) {
							countArray.push(data);
						}

						notifications.push(data);
					});

					const notSeenCount = countArray.length;
					setNotSeenNotificationsCount(notSeenCount);
				});
			}
		} else {
			const q = query(
				collection(db, "notifications"),
				where("userId", "in", [user.id, user.userType !== 2 ? "all" : "admin"]),
				where("seen", "==", false),
				orderBy("date", "desc"),
				limit(50)
			);

			const suscribe = onSnapshot(q, (querySnapshot) => {
				setNotSeenNotificationsCount(querySnapshot.size);
			});
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClickAside);
		document.addEventListener("mousedown", handleOutsideClickNotifications);
		return () => {
			document.removeEventListener("mousedown", handleOutsideClickAside);
			document.removeEventListener("mousedown", handleOutsideClickNotifications);
		};
	}, []);

	useEffect(() => {
		setOpenNav(false);
	}, [location.pathname]);

	useEffect(() => {
		if (user) {
			getNotifications();
			//getPayments();
		}
	}, [user]);

	return (
		<nav className="navbar-main-container">
			<Helmet>
				<title>
					{!user || !location.pathname.startsWith("/admin")
						? "Academia de Lenguas Internacionales"
						: `(${notSeenNotificationsCount === 0 ? "..." : notSeenNotificationsCount}) ${title}`}
				</title>
			</Helmet>
			<div className="navbar-left">
			{user && <UserNav className="user-nav-icon" role="button" onClick={() => setAsideOpen(!asideOpen)} ref={asideBtnRef} />}
			{(isResponsive && !user) && <CurrencySelector/>}
			</div>
			{(location.pathname.startsWith("/admin") || location.pathname.startsWith("/micuenta")) && !isResponsive ? null : (
				<div className={`logo-container ${user ? (!user.generationId ? "" : "logo-container-no-user") : ""}`}>
					<img
						className={user ? (!user.generationId ? "" : "flag-logo-no-user") : ""}
						src={FlagLogo}
						alt="academia de lenguas internacionales"
					/>
				</div>
			)}

			<div className="menus-container">
				<div
					className={user ? (!user.generationId ? "contact-container" : "contact-container-no-generation") : "contact-container"}
				>
					<div className="phones-container">
						<div className="phone-number">+52 56 2392 5453</div>
						<div className="phone-number">+52 55 5184 8431</div>
						<div className="whatsapp">
							<WhatsAppIcon
								role="button"
								onClick={() => {
									window.open("https://api.whatsapp.com/send?phone=5623925453&text=Buenas tardes, tengo interés en...");
								}}
							/>
						</div>
					</div>
					<NavLink className="register-btn" to={"/inscripcion"}>
						¡INSCRÍBETE YA!
					</NavLink>
					{!user && <CurrencySelector/>}
				</div>
				<BurgerBtn openNav={openNav} onClick={handleOpenNav} ref={navBtnRef} />
				<ul
					className={
						openNav
							? `open `
							: `${
									location.pathname.startsWith("/admin") || location.pathname.startsWith("/micuenta")
										? "navbar-without-white-container"
										: ""
							  }`
					}
					ref={navContainerRef}
				>
					<div
						className={
							location.pathname.startsWith("/admin") || location.pathname.startsWith("/micuenta")
								? " white-container-myaccount-admin"
								: "white-container"
						}
					></div>

					<NavLink
						exact
						to={"/"}
						className={`${
							(!location.pathname.startsWith("/admin") && !location.pathname.startsWith("/micuenta")) || openNav
								? "normal-home-container "
								: "home-container"
						}`}
					>
						<li>Inicio</li>
					</NavLink>

					{openNav && isResponsive ? (
						<>
							<NavLink to={"/programa"}>
								<li>Nuestro Programa</li>
							</NavLink>
							<NavLink to={"/ninos"}>
								<li>Niños</li>
							</NavLink>
							<NavLink to={"/testimonios"}>
								<li>Testimonios</li>
							</NavLink>
							<NavLink to={"/dudas"}>
								<li>¿Dudas?</li>
							</NavLink>
							<NavLink to={"/contacto"}>
								<li>Contacto</li>
							</NavLink>
						</>
					) : location.pathname.startsWith("/admin") || location.pathname.startsWith("/micuenta") ? (
						<img
							style={{
								backgroundColor: "white",
								borderRadius: "20px",
								padding: "5px",
								maxWidth: "39px",
								height: "39px",
								position: "absolute",
								top: `${user ? (!user.generationId ? "78%" : "50%") : "50%"}`,
								left: "50%",
								transform: " translate(-50%, -50%)",
								objectFit: "contain",
							}}
							src={FlagLogo}
							alt="academia de lenguas internacionales"
						/>
					) : (
						<>
							<NavLink to={"/programa"} style={{ flex: "3" }}>
								<li>Nuestro Programa</li>
							</NavLink>
							<NavLink to={"/ninos"} style={{ flex: "2" }}>
								<li>Niños</li>
							</NavLink>
							<NavLink to={"/testimonios"} style={{ flex: "2" }}>
								<li>Testimonios</li>
							</NavLink>
							<NavLink to={"/dudas"} style={{ flex: "2" }}>
								<li>¿Dudas?</li>
							</NavLink>
							<NavLink to={"/contacto"} style={{ flex: "2" }}>
								<li>Contacto</li>
							</NavLink>
						</>
					)}

					{user !== null && (
						<div
							className="notifications-container"
							onClick={() => setNotificationOpen(!notificationOpen)}
							ref={notificationBtnRef}
						>
							<img src={NotificationIcon} alt="notification icon" />
							{notSeenNotificationsCount > 0 && <div className="number-icon">{notSeenNotificationsCount}</div>}
						</div>
					)}

					<NavLink className={"auth"} to={"/micuenta"}>
						<li>
							<UserNavDesk />
						</li>
					</NavLink>

					{!user ? (
						<div className="responsive-auth-options">
							<NavLink className={"login"} to={"ingresar"}>
								INICIAR SESIÓN
							</NavLink>
							<NavLink className={"sign-up"} to={"inscripcion"}>
								¡INSCRÍBETE YA!
							</NavLink>
						</div>
					) : !user.generationId ? (
						<div className="responsive-auth-options">
							<NavLink className={"sign-up"} to={"inscripcion"}>
								¡INSCRÍBETE YA!
							</NavLink>
						</div>
					) : null}
				</ul>
			</div>
			{user && (
				<div
					className={"notifications-modal" + (notificationOpen ? " notifications-modal-open" : "")}
					style={{
						overflow: searchActive === false && !notificationOpen ? "hidden" : "scroll",
						top: user ? (user.generationId ? "55px" : "128px") : "128px",
						height: user ? (user.generationId ? "calc(113% - 55px)" : "calc(100% - 128px)") : "calc(100% - 128px)",
						maxHeight: notificationOpen ? "calc(122% - 128px)" : "",
					}}
				>
					<NotificationsContainer user={user} notificationContainerRef={notificationContainerRef} />
				</div>
			)}
		</nav>
	);
}

export default Navbar;
