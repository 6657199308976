import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Eye_white } from "assets/Imports";

const FooterPreviewMode = ({ onClick }) => (
	<footer className="fixed-bottom" style={{ background: "linear-gradient(to bottom, #ee1c34, #500b28)", height: "50px" }}>
		<Container>
			<Row>
				<Col>
					<div
						style={{
							marginRight: "auto",
							marginLeft: "auto",
							textAlign: "center",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginTop: "1%",
							flexDirection: "row",
						}}
					>
						<img src={Eye_white} style={{ marginRight: "10px", fontWeight: "bold" }} alt="Eye Icon" />
						<span style={{ color: "white", cursor: "pointer" }}>Regresar al modo admin</span>
						<button
							style={{
								border: "none",
								background: "transparent",
								borderRadius: "50%",
								width: "25px",
								height: "25px",
								marginLeft: "10px",
								outline: "none",
								boxShadow: "0 0 0 1px white",
								cursor: "pointer",
								color: "white",
								fontSize: "10px",
								fontWeight: "lighter",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							onClick={onClick}
						>
							Salir
						</button>
					</div>
				</Col>
			</Row>
		</Container>
	</footer>
);

export default FooterPreviewMode;
