
import './TemaryContainerStyles.scss';
import { modulo1, modulo2, modulo3, modulo4, modulo5, downloads } from '../../Temary/Temary';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from 'initFirebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons'
import parse from 'html-react-parser';
import { romanize, getModuleNumber, randomIntFromInterval } from "../../functions/Functions";
import { randomSearchTexts } from 'constants/SearchWords';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BrazilFlagPng, BrazilFlagSVG, ChinaFlagPng, ChinaFlagSVG, DocumentAttachIcon, DownArrow, FranceFlagPng, FranceFlagSVG, GermanyFlagPng, GermanyFlagSVG, ItalyFlagPng, ItalyFlagSVG, JapanFlagPng, JapanFlagSVG, RussiaFlagPng, RussiaFlagSVG, SearchIcon, UnitedStatesFlagPng, UnitedStatesFlagSVG, UpArrow } from 'assets/Imports';

const searchPlaceHolder = randomSearchTexts[randomIntFromInterval(0, 8)];

function TemaryContainer({ user }) {

    const name = user.name.split(" ");
    const [tem, setTem] = useState([{
        '5' : modulo5.sort(compare),
        '4' : modulo4.sort(compare),
        '3' : modulo3.sort(compare),
        '2' : modulo2.sort(compare),
        '1' : modulo1.sort(compare)
    }])
    const [orderBy, setOrderBy] = useState('mayoramenor');
    const [select, setSelect] = useState('all');
    const [temario, setTemario] = useState(orderBy === 'mayoramenor' ? [modulo5.sort(compare), modulo4.sort(compare), modulo3.sort(compare), modulo2.sort(compare), modulo1.sort(compare)] : []);
    const [searchWord, setSearchWord] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [selection, setSelection]: any = useState(["all"]);
    const [showLangSelector, setShowLangSelector] = useState(false);
    const [total, setTotal] = useState(0);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [french, setFrench] = useState(false);
    const [german, setGerman] = useState(false);
    const [japanese, setJapanese] = useState(false);
    const [italian, setItalian] = useState(false);
    const [russian, setRussian] = useState(false);
    const [chinese, setChinese] = useState(false);
    const [portuguese, setPortuguese] = useState(false);
    const [english, setEnglish] = useState(false);
    const [all, setAll] = useState(true);
    const [countLangs, setCountLangs] = useState(0)
    const [materiales, setMateriales]: any = useState([]);
    const [pastMaterial, setPastMateriales]: any = useState([]);
    const [seminariosPagados, setSeminariosPagados] = useState(0);
    const [sesionesActuales, setSesionesActuales] = useState(0);
    const [gen, setGen]: any = useState(null);
    const [moduleSelector, setModuleSelector] = useState<number>(0);

    useEffect(() => {
        if (french === false && german === false && japanese === false && italian === false && russian === false && chinese === false && portuguese === false && english === false) {
            setAll(true);
            setCountLangs(0);
        } else {
            setAll(false);
            let myCounter = 0;
            let array = [french, german, japanese, italian, russian, chinese, portuguese, english]
            array.forEach((a) => {
                if (a === true) {
                    myCounter++;
                }
            })
            setCountLangs(myCounter);
        }
    }, [french, german, japanese, italian, russian, chinese, portuguese, english]);

    const insertSelection = (select) => {
        let selectiontemp = [...selection]
        if (!selectiontemp.includes(select)) {
            if (select === "all") {
                selectiontemp = [];
            } else if (selectiontemp.includes("all")) {
                const index = selectiontemp.findIndex(e => e === "all");
                selectiontemp.splice(index, 1);
            }
            selectiontemp.push(select);
        } else {
            const index = selectiontemp.findIndex(e => e === select);
            selectiontemp.splice(index, 1);
        }

        if (selectiontemp.length === 0 || selectiontemp.length === 5) {
            selectiontemp = [];
            selectiontemp.push("all");
        }
        setSelection(selectiontemp);
    }
    /* const insertSelection = (select: any, index: any) => {

        let selectiontemp = [...selection];
        let tempIndex = 0;

        if (select.length === 5) {
            tempIndex = selectiontemp.findIndex((x: any) => x === select)
            selectiontemp.splice(tempIndex, 6)
            select.forEach(e => {
                selectiontemp.push(e)
            })
        }
        if (selectiontemp.some((e: any) => e === select)) {
            if (select.length === 5) {
                selectiontemp.push(select)
            } else if (selectiontemp.includes("all")) {
                tempIndex = selectiontemp.findIndex((x: any) => x === select)
                selectiontemp.splice(tempIndex,1);
            }
            selectiontemp.push(select)
        } else {
            tempIndex = selectiontemp.findIndex((x: any) => x === select)
            selectiontemp.splice(tempIndex,1);
            selectiontemp.push(select)
        }
    } */
    useEffect(() => {
        let tempTemario:any
        let nuModul = selection.toString();
        selection.sort();
        selection.reverse();
        if(nuModul === "all"){
            tempTemario = [
                modulo5.sort(compare), 
                modulo4.sort(compare), 
                modulo3.sort(compare), 
                modulo2.sort(compare), 
                modulo1.sort(compare)
            ]
            setTemario(tempTemario)
        }
        else{
            tempTemario = [];
            selection.map((val,index)=>{
                if (val === "modulo1"){
                    tempTemario.push(modulo1);
                }
                if (val === "modulo2"){
                    tempTemario.push(modulo2);
                }
                if (val === "modulo3"){
                    tempTemario.push(modulo3);
                }
                if (val === "modulo4"){
                    tempTemario.push(modulo4);
                }
                if (val === "modulo5"){
                    tempTemario.push(modulo5);
                }
            })
            setTemario(tempTemario)
        }
    }, [selection]) 

    const getMateriales = async (data) => {
        let nextMaterial: any = [];
        let pastMaterial: any = [];
        const q = query(collection(db, "generations", data.id, 'material'));

        const querySnapshot = await getDocs(q);

        let i = 0;
        const date = new Date();

        querySnapshot.forEach((doc) => {
            let materialDate = new Date();
            i++;
            // doc.data() is never undefined for query doc snapshots
            const data2: any = {
                ...doc.data(),
                id: doc.id
            }

            let materialDateSplit = data2.date.split('/');
            materialDate.setDate(materialDateSplit[0]);
            materialDate.setMonth(materialDateSplit[1]);
            materialDate.setFullYear(materialDateSplit[2]);
            materialDate.setHours(0, 0, 0);


            if (user.userType === 2) {
                pastMaterial.push(data2);
            } else {
                if (date < materialDate) {
                    nextMaterial.push(data2);
                } else {
                    pastMaterial.push(data2);
                }
            }
            if (i === querySnapshot.size) {
                nextMaterial.sort(compare2)
                pastMaterial.sort(compare2);
                setMateriales(nextMaterial);

                getPayments(pastMaterial);
                setGen(data);
            }
        });
        // setCheck(true);
        // setRefresh(!refresh)
    }

    const getPayments = async (pastMaterial) => {
        const qry = query(collection(db, 'paymentHistory'), where('userId', '==', user.id), where('verified', '==', true));
        const qrySnapshot = await getDocs(qry);

        let i = 0;
        let total = 0;
        let sesionesPagadas = 0;
        let sesionesActuales = pastMaterial.length;

        qrySnapshot.forEach(async (doc) => {
            i++;
            total = total + parseInt(doc.data().amount);
            if (i === qrySnapshot.size) {
                //sesiones pagadas, sesion actual,


                if ((total === 12000 && qrySnapshot.size === 1) || user.promotion === true || user.userType === 2) {
                    total = 18000;
                }

                sesionesPagadas = Math.floor(total / 300);
                setSeminariosPagados(sesionesPagadas);
                setTotal(total);
                setSesionesActuales(sesionesActuales);
                setPastMateriales(pastMaterial.sort(compare))

                setLoading(false);


            }
        })
        if (qrySnapshot.size === 0 && user.promotion === true || user.userType === 2) {
            total = 18000;
            sesionesPagadas = Math.floor(total / 300);
            setSeminariosPagados(sesionesPagadas);
            setTotal(total);
            setSesionesActuales(sesionesActuales);
            setPastMateriales(pastMaterial.sort(compare))

            setLoading(false)
        } else if (qrySnapshot.size === 0 && user.promotion === false) {
            setLoading(false);
        }

    }

    function compare2(a, b) {
        if (a.num < b.num) {
            return -1;
        }
        if (a.num > b.num) {
            return 1;
        }
        return 0;
    }

    function compare(a, b) {
        if (a.num > b.num) {
            return -1;
        }
        if (a.num < b.num) {
            return 1;
        }
        return 0;
    }

    const getGenerations = async () => {
        let array: any = [];

        if (user.generationId !== undefined && user.generationId !== '') {
            const docRef = doc(db, "generations", user.generationId);
            const docSnap = await getDoc(docRef);

            getMateriales(docSnap.data());
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        getGenerations();
    }, [])

    function isVocal(e) {
        if (e === 'a' || e === 'e' || e === 'o' || e === 'u' || e === 'i') {
            return true;
        } else {
            return false;
        }
    }



    //Metodo para buscar la palabra ingresada en el input, donde se normaliza el texto quitandole cualquier acento que esta tenga
    //y nos regresa el objeto dentro del array que la contenga
    const arraySearch = (array, keyword) => {
        const searchTerm = keyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/’/g, '').replace(/ß/g, 'ss').replace(/\(/g, '').replace(/\)/g, '').replace(/\?/g, '').replace(/,/g, '').replace(/\./g, '')
        let arrayTerm = searchTerm.split(" ")
        let searchTerm2 = "";
        arrayTerm.forEach((word, index) => {
            if (isVocal(word[word.length - 1])) {
                searchTerm2 += word + "s" + (index === (arrayTerm.length - 1) ? "" : " ");
            } else if (word[word.length - 1] === 'n' || word[word.length - 1] === 'l') {
                searchTerm2 += word + "es" + (index === (arrayTerm.length - 1) ? "" : " ");
            } else {
                searchTerm2 += word + (index === (arrayTerm.length - 1) ? "" : " ");
            }
        });
        return array.filter(value => {


            return value.name.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
                (all || french) && searchTerm.split(" ").every(word => value.lang1.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || french) && searchTerm.split(" ").every(word => value.sub1.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || french) && searchTerm.split(" ").every(word => value.desc1.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || german) && searchTerm.split(" ").every(word => value.lang2.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || german) && searchTerm.split(" ").every(word => value.sub2.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || german) && searchTerm.split(" ").every(word => value.desc2.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || japanese) && searchTerm.split(" ").every(word => value.lang3.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || japanese) && searchTerm.split(" ").every(word => value.sub3.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || japanese) && searchTerm.split(" ").every(word => value.desc3.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || italian) && searchTerm.split(" ").every(word => value.lang4.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || italian) && searchTerm.split(" ").every(word => value.sub4.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || italian) && searchTerm.split(" ").every(word => value.desc4.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || russian) && searchTerm.split(" ").every(word => value.lang5.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || russian) && searchTerm.split(" ").every(word => value.sub5.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || russian) && searchTerm.split(" ").every(word => value.desc5.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || chinese) && searchTerm.split(" ").every(word => value.lang6.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || chinese) && searchTerm.split(" ").every(word => value.sub6.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || chinese) && searchTerm.split(" ").every(word => value.desc6.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || portuguese) && searchTerm.split(" ").every(word => value.lang7.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || portuguese) && searchTerm.split(" ").every(word => value.sub7.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || portuguese) && searchTerm.split(" ").every(word => value.desc7.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || english) && searchTerm.split(" ").every(word => value.lang8.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || english) && searchTerm.split(" ").every(word => value.sub8.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || english) && searchTerm.split(" ").every(word => value.desc8.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || french) && searchTerm2.split(" ").every(word => value.lang1.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || french) && searchTerm2.split(" ").every(word => value.sub1.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || french) && searchTerm2.split(" ").every(word => value.desc1.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || german) && searchTerm2.split(" ").every(word => value.lang2.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || german) && searchTerm2.split(" ").every(word => value.sub2.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || german) && searchTerm2.split(" ").every(word => value.desc2.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || japanese) && searchTerm2.split(" ").every(word => value.lang3.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || japanese) && searchTerm2.split(" ").every(word => value.sub3.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || japanese) && searchTerm2.split(" ").every(word => value.desc3.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || italian) && searchTerm2.split(" ").every(word => value.lang4.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || italian) && searchTerm2.split(" ").every(word => value.sub4.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || italian) && searchTerm2.split(" ").every(word => value.desc4.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || russian) && searchTerm2.split(" ").every(word => value.lang5.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || russian) && searchTerm2.split(" ").every(word => value.sub5.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || russian) && searchTerm2.split(" ").every(word => value.desc5.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || chinese) && searchTerm2.split(" ").every(word => value.lang6.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || chinese) && searchTerm2.split(" ").every(word => value.sub6.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || chinese) && searchTerm2.split(" ").every(word => value.desc6.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || portuguese) && searchTerm2.split(" ").every(word => value.lang7.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || portuguese) && searchTerm2.split(" ").every(word => value.sub7.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || portuguese) && searchTerm2.split(" ").every(word => value.desc7.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || english) && searchTerm2.split(" ").every(word => value.lang8.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || english) && searchTerm2.split(" ").every(word => value.sub8.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) ||
                (all || english) && searchTerm2.split(" ").every(word => value.desc8.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word))
        })
    }

    const handleOnChange = (e) => {
        setSearchValue(e.target.value);
        if (e.target.value.length === 0) {
            setSearchWord('')
            if (orderBy === 'mayoramenor') {
                setTemario([modulo5, modulo4, modulo3, modulo2, modulo1])
            } else {
                setTemario([modulo1, modulo2, modulo3, modulo4, modulo5])
            }
        }
    }

    const search = async (e) => {
        e.preventDefault();
        if (searchValue.length >= 2) {
            if (select === 'all') {
                let search = await arraySearch(modulo1, searchValue);
                let search2 = await arraySearch(modulo2, searchValue);
                let search3 = await arraySearch(modulo3, searchValue);
                let search4 = await arraySearch(modulo4, searchValue);
                let search5 = await arraySearch(modulo5, searchValue);
                setSearchWord(searchValue.toLowerCase().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/’/g, '').replace(/ß/g, 'ss').replace(/\(/g, '').replace(/\)/g, '').replace(/\?/g, '').replace(/,/g, '').replace(/\./g, ''))

                if (orderBy === 'mayoramenor') {
                    setTemario([search5, search4, search3, search2, search])
                } else {
                    setTemario([search, search2, search3, search4, search5])
                }
            } else if (select === 'modulo1') {
                let search = await arraySearch(modulo1, searchValue);

                setSearchWord(searchValue.toLowerCase().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/’/g, '').replace(/ß/g, 'ss').replace(/\(/g, '').replace(/\)/g, '').replace(/\?/g, '').replace(/,/g, '').replace(/\./g, ''))

                setTemario([search])
            } else if (select === 'modulo2') {
                let search = await arraySearch(modulo2, searchValue);
                setSearchWord(searchValue.toLowerCase().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/’/g, '').replace(/ß/g, 'ss').replace(/\(/g, '').replace(/\)/g, '').replace(/\?/g, '').replace(/,/g, '').replace(/\./g, ''))

                setTemario([search])
            } else if (select === 'modulo3') {
                let search = await arraySearch(modulo3, searchValue);
                setSearchWord(searchValue.toLowerCase().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/’/g, '').replace(/ß/g, 'ss').replace(/\(/g, '').replace(/\)/g, '').replace(/\?/g, '').replace(/,/g, '').replace(/\./g, ''))

                setTemario([search])
            } else if (select === 'modulo4') {
                let search = await arraySearch(modulo4, searchValue);
                setSearchWord(searchValue.toLowerCase().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/’/g, '').replace(/ß/g, 'ss').replace(/\(/g, '').replace(/\)/g, '').replace(/\?/g, '').replace(/,/g, '').replace(/\./g, ''))

                setTemario([search])
            } else if (select === 'modulo5') {
                let search = await arraySearch(modulo5, searchValue);
                setSearchWord(searchValue.toLowerCase().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/’/g, '').replace(/ß/g, 'ss').replace(/\(/g, '').replace(/\)/g, '').replace(/\?/g, '').replace(/,/g, '').replace(/\./g, ''))

                setTemario([search])
            }
        } else {
            setSearchWord('')
            if (orderBy === 'mayoramenor') {
                setTemario([modulo5, modulo4, modulo3, modulo2, modulo1])
            } else {
                setTemario([modulo1, modulo2, modulo3, modulo4, modulo5])
            }
        }
    }

    function highlight(text) {
        if (searchWord !== '') {
            let arrayTerm = searchWord.split(" ")
            let searchTerm2 = "";
            arrayTerm.forEach((word, index) => {
                if (isVocal(word[word.length - 1])) {
                    searchTerm2 += word + "s" + (index === (arrayTerm.length - 1) ? "" : " ");
                } else if (word[word.length - 1] === 'n' || word[word.length - 1] === 'l') {
                    searchTerm2 += word + "es" + (index === (arrayTerm.length - 1) ? "" : " ");
                } else {
                    searchTerm2 += word + (index === (arrayTerm.length - 1) ? "" : " ");
                }
            });
            if (searchWord.split(" ").every(word => text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/[\.(')?,-]/g, '').replace(/\n/g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word)) || searchTerm2.split(" ").every(word => text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\?/g, '').replace(/,/g, '').replace(/\./g, '').replace(/\./g, '').replace(/<\/u>/g, '').replace(/<u>/g, '').replace(/<\/em>/g, '').replace(/<em>/g, '').split(" ").includes(word))) {
                return true;
            } else {
                return false;
            }
        }
    }

    const handleSelected = async (e) => {
        if (e === 'mayoramenor') {
            setOrderBy(e)
        } else if (e === 'menoramayor') {
            setOrderBy(e)
        } else if (e === "all") {
            setSelect(e)
        } else {
            setSelect(e)
        }
    }
//posiblemente borrar
    function getModuleNumber(num) {
       let returnValue:any = '';
       if (51 <= num && num <= 60){
           returnValue = 'V'
       }
       if (41 <= num && num <= 50){
           returnValue = 'IV'
       }
       if (26 <= num && num <= 40){
           returnValue = 'III'
       }
       if ( 11 <= num && num <= 25){
           returnValue = 'II'
       }
       if (1 <= num && num <= 10){
           returnValue = 'I'
       }
      return returnValue
    }

    function showModule(num) {
        let returnValue: boolean = true;
        if (num === 60){
            if ((pastMaterial.length + 1) >= 51) {
                returnValue = false;
            } else {
                returnValue = true;
            }
        }
        if (num === 50){
            if ((pastMaterial.length + 1) >= 41) {
                returnValue = false;
            } else {
                returnValue = true;
            }
        }
        if (num === 40){
            if ((pastMaterial.length + 1) >= 26) {
                returnValue = false;
            } else {
                returnValue = true;
            }
        }
        if (num === 25){
            if ((pastMaterial.length + 1) >= 11) {
                returnValue = false;
            } else {
                returnValue = true;
            }
        }
        if (num === 10){
            if ((pastMaterial.length + 1) >= 1) {
                returnValue = false;
            } else {
                returnValue = true;
            }
        }
        return returnValue;
    }
    function showDownload(num) {
        switch (selection) {
            case 'all':
                break;
            case 'modulo1':
                num = 1;
                break;
            case 'modulo2':
                num = 2;
                break;
            case 'modulo3':
                num = 3;
                break;
            case 'modulo4':
                num = 4;
                break;
            case 'modulo5':
                num = 5;
                break;
            default:
                break;
        }
        switch (num) {
            case 1:
                if ((pastMaterial.length + 1) >= 10) {
                    return true;
                } else {
                    return false;
                }
                break;
            case 2:
                if ((pastMaterial.length + 1) >= 25) {
                    return true;
                } else {
                    return false;
                }
                break;
            case 3:
                if ((pastMaterial.length + 1) >= 40) {
                    return true;
                } else {
                    return false;
                }
                break;
            case 4:
                if ((pastMaterial.length + 1) >= 50) {
                    return true;
                } else {
                    return false;
                }
                break;
            case 5:
                if ((pastMaterial.length + 1) >= 60) {
                    return true;
                } else {
                    return false;
                }
                break;

            default:
                break;
        }
    }
    function getSeminariesNumber(num) {
        let returnValue:any = '';
        if (51 <= num && num <= 60){
            returnValue = 'LX - LI'
        }
        if (41 <= num && num <= 50){
            returnValue = 'L - XLI'
        }
        if (26 <= num && num <= 40){
            returnValue = 'XL - XXVI'
        }
        if ( 11 <= num && num <= 25){
            returnValue = 'XXV -  XI'
        }
        if (1 <= num && num <= 10){
            returnValue = 'X - I'
        }
       return returnValue
    }

    function downloadLink(modulo) {
        switch (modulo) {
            case 'I':
                return downloads.modulo1;
                break;
            case 'II':
                return downloads.modulo2;
                break;
            case 'III':
                return downloads.modulo3;
                break;
            case 'IV':
                return downloads.modulo4;
                break;
            case 'V':
                return downloads.modulo5;
                break;
            default:
                break;
        }
    }

    if (loading) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    height: "60vh",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <div>
                <h1>Temario</h1>
            </div>
            <div>
                <h3 className='text-center temary-subtitle'>Nota: los temas impartidos pueden variar ligeramente con aquellos presentados en esta sección.</h3>
            </div>
            {total === 0 ? (
                <>
                    <h2 style={{ marginTop: 80, textAlign: 'center' }}>Aún no te encuentras inscrito a ningún programa</h2>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40 }}>
                        <p>Comienza tu experiencia</p>
                        <button onClick={() => history.push("/inscripcion")} className="tertiary return-btn-style">Inscríbete</button>
                    </div>
                </>
            ) : (
                <>
                    <form className='search-section-container'>
                        <div className='search-container'>
                            <div className='lang-selector-container' onClick={() => setShowLangSelector(!showLangSelector)}>
                                <p>Idiomas</p>
                                {showLangSelector === false ? (
                                    <img src={DownArrow} alt="down-arrow-icon" />
                                ) : (
                                    <img src={UpArrow} alt="up-arrow-icon" />
                                )}
                            </div>
                            <div className='search-input-container'>
                                <img src={SearchIcon} alt="search-icon" />
                                <input type="text" value={searchValue} onChange={handleOnChange} placeholder={searchPlaceHolder} />
                            </div>
                        </div>
                        <button type='submit' id='search-temary-button' onClick={search} className='return-btn-style'>Buscar</button>
                    </form>
                    <div className={'langs-selection-container' + (showLangSelector ? " show" : "")}>
                        <div>
                            <p>Idioma</p>
                        </div>
                        <div className='checkboxes-container'>
                            <div className='checkbox-container'>
                                <input id='lang1' name='lang1' checked={french} onChange={(e) => setFrench(e.target.checked)} type="checkbox" />
                                <label htmlFor="lang1"><img src={FranceFlagPng} alt="bandera de francia" /></label>
                            </div>
                            <div className='checkbox-container'>
                                <input id='lang2' name='lang2' checked={german} onChange={(e) => setGerman(e.target.checked)} type="checkbox" />
                                <label htmlFor="lang2"><img src={GermanyFlagPng} alt="bandera de alemania" /></label>
                            </div>
                            <div className='checkbox-container'>
                                <input id='lang3' name='lang3' checked={japanese} onChange={(e) => setJapanese(e.target.checked)} type="checkbox" />
                                <label htmlFor="lang3"><img src={JapanFlagPng} alt="bandera de japón" /></label>
                            </div>
                            <div className='checkbox-container'>
                                <input id='lang4' name='lang4' checked={italian} onChange={(e) => setItalian(e.target.checked)} type="checkbox" />
                                <label htmlFor="lang4"><img src={ItalyFlagPng} alt="bandera de italia" /></label>
                            </div>
                            <div className='checkbox-container'>
                                <input id='lang5' name='lang5' checked={russian} onChange={(e) => setRussian(e.target.checked)} type="checkbox" />
                                <label htmlFor="lang5"><img src={RussiaFlagPng} alt="bandera de rusia" /></label>
                            </div>
                            <div className='checkbox-container'>
                                <input id='lang6' name='lang6' checked={chinese} onChange={(e) => setChinese(e.target.checked)} type="checkbox" />
                                <label htmlFor="lang6"><img src={ChinaFlagPng} alt="bandera de china" /></label>
                            </div>
                            <div className='checkbox-container'>
                                <input id='lang7' name='lang7' checked={portuguese} onChange={(e) => setPortuguese(e.target.checked)} type="checkbox" />
                                <label htmlFor="lang7"><img src={BrazilFlagPng} alt="bandera de brasil" /></label>
                            </div>
                            <div className='checkbox-container'>
                                <input id='lang8' name='lang8' checked={english} onChange={(e) => setEnglish(e.target.checked)} type="checkbox" />
                                <label htmlFor="lang8"><img src={UnitedStatesFlagPng} alt="bandera de estados unidos" /></label>
                            </div>
                        </div>
                    </div>
                    <div className="module-selector-bar-container" >
                        <h3>Módulos</h3>
                        <div className="module-selector-bar" >
                            <div className={"cell " + (selection.includes("modulo1") ? 'active' : '')} onClick={() => insertSelection("modulo1")}>
                                I
                            </div>
                            <div className={"cell " + (selection.includes("modulo2") ? 'active' : '')} onClick={() =>{ if(11 <=pastMaterial.length + 1){insertSelection("modulo2")}}} >
                                II
                            </div>
                            <div className={"cell " + (selection.includes("modulo3") ? 'active' : '')} onClick={() => { if(26 <=pastMaterial.length + 1){insertSelection("modulo3")}}}>
                                III
                            </div>
                            <div className={"cell " + (selection.includes("modulo4") ? 'active' : '')} onClick={() => { if(41 <=pastMaterial.length + 1){insertSelection("modulo4")}}}>
                                IV
                            </div>
                            <div className={"cell " + (selection.includes("modulo5") ? 'active' : '')} onClick={() => { if(51 <=pastMaterial.length + 1){insertSelection("modulo5")}}}>
                                V
                            </div>
                            <div className={"cell " + (selection.includes("all") ? 'active' : '')} onClick={() => insertSelection("all")} style={{ borderRight: 0 }}>
                                Todos
                            </div>
                        </div>
                    </div>
                    {temario.map((modulo, index) => {
                        return (
                            <>
                                {modulo.length > 0 ? (
                                    <div>
                                        <>
                                            {orderBy === "mayoramenor" ? (
                                                <h2 className={'module-title-temary ' + ((english && modulo.every(seminary => seminary.lang8 === "") || showModule(modulo[0].num)) ? "d-none" : "")}>Módulo  <span style={{ fontFamily: 'initial' }}>{getModuleNumber(modulo[0].num)}</span></h2>
                                            ) : (
                                                <h2 className={'module-title-temary ' + ((english && modulo.every(seminary => seminary.lang8 === "") || showModule(modulo[0].num)) ? "d-none" : "")}>Módulo <span style={{ fontFamily: 'initial' }}>{getModuleNumber(modulo[0].num)}</span></h2> 
                                            )}
                                            {orderBy === "mayoramenor" ? (
                                                <>
                                                    <p className={'module-seminaries ' + ((english && modulo.every(seminary => seminary.lang8 === "") || showModule(modulo[0].num)) ? "d-none" : "")}><span style={{ fontFamily: 'initial' }}>{getSeminariesNumber(modulo[0].num)}</span></p>
                                                    {showDownload(temario.length - index) && (
                                                        <a style={{ textDecoration: 'none', display: "block", width: "fit-content" }} download href={downloadLink(getModuleNumber(modulo[0].num))} target="_blank">
                                                            <button className='download-temary-pdf-button mb-4'>Descargar temario del módulo</button>
                                                        </a>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <p className={'module-seminaries ' + ((english && modulo.every(seminary => seminary.lang8 === "") || showModule(modulo[0].num)) ? "d-none" : "")}>{getSeminariesNumber(modulo[0].num)}</p>
                                                    {showDownload(index + 1) && (
                                                        <a style={{ textDecoration: 'none', display: "block", width: "fit-content" }} download href={downloadLink(getModuleNumber(modulo[0].num))} target="_blank">
                                                            <button className='download-temary-pdf-button mb-4'>Descargar temario del módulo</button>
                                                        </a>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                {!selection.includes("all") && (
                                    showModule(modulo[0].num) && (
                                        <div className='w-100 d-flex align-items-center justify-content-center' style={{ height: 300 }}>
                                            <h2 className='text-center'>Encuentra aquí el temario una vez hayas cursado el módulo anterior.</h2>
                                        </div>
                                    )
                                )}
                                {modulo.map((seminario, index) => {
                                    if (seminario.num <= pastMaterial.length + 1) {
                                        if (seminario.num <= seminariosPagados) {
                                            return (
                                                <>
                                                    {(english && seminario.lang8 === "") ? (
                                                        <></>
                                                    ) : (
                                                        <div className='temary-card'>
                                                            <div className='seminary-header-container'>
                                                                <h3 style={{ color: '#ca5050', margin: 0 }}>Seminario</h3>
                                                                <h2 className="seminaryNumberMateriales">{romanize(seminario.num)}</h2>
                                                            </div>
                                                            <div className={'langs-main-container ' + (countLangs === 1 ? "justify-content-center" : ' justify-content-between')}>
                                                                {(all || french) && (
                                                                    <div className='lang-info-container french'>
                                                                        <img src={FranceFlagSVG} alt="france flag" />
                                                                        <div className='lang-text-container'>
                                                                            <p className='temary-sub' style={(highlight(seminario.lang1)) ? { backgroundColor: 'yellow' } : {}}><a>{seminario.lang1}</a></p>
                                                                            <p className='lang-subtitle' style={highlight(seminario.sub1) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.sub1)}</p>
                                                                            <p className='temary-desc-style' style={highlight(seminario.desc1) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.desc1)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {(all || german) && (
                                                                    <div className='lang-info-container german'>
                                                                        <img src={GermanyFlagSVG} alt="germany flag" />
                                                                        <div className='lang-text-container'>
                                                                            <p className='temary-sub' style={(highlight(seminario.lang2)) ? { backgroundColor: 'yellow' } : {}}><a>{seminario.lang2}</a></p>
                                                                            <p className='lang-subtitle' style={highlight(seminario.sub2) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.sub2)}</p>
                                                                            <p className='temary-desc-style' style={highlight(seminario.desc2) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.desc2)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {(all || japanese) && (
                                                                    <div className='lang-info-container japanese'>
                                                                        <img src={JapanFlagSVG} alt="japan flag" />
                                                                        <div className='lang-text-container'>
                                                                            <p className='temary-sub' style={(highlight(seminario.lang3)) ? { backgroundColor: 'yellow' } : {}}><a>{seminario.lang3}</a></p>
                                                                            <p className='lang-subtitle' style={highlight(seminario.sub3) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.sub3)}</p>
                                                                            <p className='temary-desc-style' style={highlight(seminario.desc3) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.desc3)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {(all || italian) && (
                                                                    <div className='lang-info-container italian'>
                                                                        <img src={ItalyFlagSVG} alt="italy flag" />
                                                                        <div className='lang-text-container'>
                                                                            <p className='temary-sub' style={(highlight(seminario.lang4)) ? { backgroundColor: 'yellow' } : {}}><a>{seminario.lang4}</a></p>
                                                                            <p className='lang-subtitle' style={highlight(seminario.sub4) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.sub4)}</p>
                                                                            <p className='temary-desc-style' style={highlight(seminario.desc4) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.desc4)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {(all || russian) && (
                                                                    <div className='lang-info-container russian'>
                                                                        <img src={RussiaFlagSVG} alt="russia flag" />
                                                                        <div className='lang-text-container'>
                                                                            <p className='temary-sub' style={(highlight(seminario.lang5)) ? { backgroundColor: 'yellow' } : {}}><a>{seminario.lang5}</a></p>
                                                                            <p className='lang-subtitle' style={highlight(seminario.sub5) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.sub5)}</p>
                                                                            <p className='temary-desc-style' style={highlight(seminario.desc5) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.desc5)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {(all || chinese) && (
                                                                    <div className='lang-info-container chinese'>
                                                                        <img src={ChinaFlagSVG} alt="china flag" />
                                                                        <div className='lang-text-container'>
                                                                            <p className='temary-sub' style={(highlight(seminario.lang6)) ? { backgroundColor: 'yellow' } : {}}><a>{seminario.lang6}</a></p>
                                                                            <p className='lang-subtitle' style={highlight(seminario.sub6) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.sub6)}</p>
                                                                            <p className='temary-desc-style' style={highlight(seminario.desc6) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.desc6)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {(all || portuguese) && (
                                                                    <div className='lang-info-container portuguese'>
                                                                        <img src={BrazilFlagSVG}  alt="portugal flag" />
                                                                        <div className='lang-text-container'>
                                                                            <p className='temary-sub' style={(highlight(seminario.lang7)) ? { backgroundColor: 'yellow' } : {}}><a>{seminario.lang7}</a></p>
                                                                            <p className='lang-subtitle' style={highlight(seminario.sub7) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.sub7)}</p>
                                                                            <p className='temary-desc-style' style={highlight(seminario.desc7) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.desc7)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {((all || english) && seminario.lang8 !== '') && (
                                                                    <div className='lang-info-container english'>
                                                                        <img src={UnitedStatesFlagSVG} alt="portugal flag" />
                                                                        <div className='lang-text-container'>
                                                                            <p className='temary-sub' style={(highlight(seminario.lang8)) ? { backgroundColor: 'yellow' } : {}}><a>{seminario.lang8}</a></p>
                                                                            <p className='lang-subtitle' style={highlight(seminario.sub8) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.sub8)}</p>
                                                                            <p className='temary-desc-style' style={highlight(seminario.desc8) ? { backgroundColor: 'yellow' } : {}}>{parse(seminario.desc8)}</p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='seminary-header-container justify-content-end'>
                                                                <button className='material-btn' onClick={() => history.push('/micuenta/materiales?num=' + seminario.num)}><img src={DocumentAttachIcon} alt="document-attach-icon" style={{ marginRight: 10 }} />Ver materiales</button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            );
                                        } else if (seminario.num > seminariosPagados) {
                                            return (
                                                <div className='temary-card'>
                                                    <div className='seminary-header-container'>
                                                        <h4>{seminario.name}</h4>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20 }}>
                                                        <FontAwesomeIcon icon={faLock as IconProp} style={{ width: 50, height: 50 }} />
                                                    </div>
                                                    <div className='text-center'>
                                                        <button className='return-btn-style' onClick={() => history.push('/micuenta/historial-de-pagos')}>Inscríbete</button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    } else if (seminario.num > seminariosPagados) {
                                        return (
                                            <></>
                                        );
                                    }
                                })
                                }
                            </>
                        )
                    })
                    }
                </>
            )
            }
        </>
    );
}

export default TemaryContainer;