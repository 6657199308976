import { useEffect, useState } from "react";
import { DropdownButton, Dropdown, Modal, Form, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { db } from "initFirebase";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import "./FormModal.scss";
import { whichIsWrongForm1 } from "functions/Forms/FormsFunctions";
import { FotoPortada } from "assets/Imports";
import { getFormInfoByUserId } from "@services/FormServices";


function FormModal(props) {
	const [validated, setValidated] = useState(false);
	const [step, setStep] = useState<number>(0);
	const history = useHistory();
	const [hover, setHover] = useState(false);
	const [haveStudied, setHaveStudied] = useState("false");
	const [speakOtherLang, setSpeakOtherLang] = useState("false");
	const [haveCertification, setHaveCertification] = useState("false");
	const name:string = (props.user?.name !== undefined) ? props.user?.name.split(" "): '';
	const [loading, setLoading] = useState(false);
	const [maxDate, setMaxDate] = useState<Date>(new Date());
	const [form1Answers, setForm1Answers] = useState<any>(null);

	const handleSubmit = async (event) => {
		setLoading(true);
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			setLoading(false);
			event.stopPropagation();
			whichIsWrongForm1(form);
		} else {
			event.preventDefault();
			const genRef = doc(db, "generations", props.user.generationId);
			const docSnap: any = await getDoc(genRef);
			const docRef = await addDoc(collection(db, "forms"), {
				email: form.email.value,
				name: form.name.value,
				firstLastName: form.firstLastName.value,
				secondLastName: form.secondLastName.value,
				profession: form.profession.value,
				cellphone: form.cellphone.value,
				secondaryEmail: form.secondaryEmail.value,
				birthdate: form.birthdate.value,
				gender: form.gender.value,
				haveStudied: form.haveStudied.value === "true" ? true : false,
				whichOnes: form.whichOnes.value,
				whereStudied: form.whereStudied.value,
				speakOtherLang: form.speakOtherLang.value === "true" ? true : false,
				whichLangs: form.whichLangs.value,
				haveCertification: form.haveCertification.value === "true" ? true : false,
				whichCertification: form.whichCertification.value,
				academiaKnown: form.academiaKnown.value,
				reasonToLearn: form.reasonToLearn.value,
				favoritesLangs: form.favoritesLangs.value,
				timeOfStudy: form.timeOfStudy.value,
				userId: props.user.id,
				generationId: props.user.generationId,
				generationName: docSnap.data().name,
				matricula: props.user.matricula,
				createdAt: new Date().toLocaleString(),
			}).then(async () => {
				const userRef = doc(db, "users", props.user.id);

				await updateDoc(userRef, {
					formAnswered: true,
					gender: form.gender.value,
					name: form.name.value + " " + form.firstLastName.value + " " + form.secondLastName.value + " ",
					secondaryEmail: form.secondaryEmail.value,
					whaPhone: form.cellphone.value,
				});

				alert(
					"¡Listo!\r\n\nHas completado el proceso de registro.\r\n\n¡" +
						(form.gender.value === "mujer" ? "Bienvenida" : "Bienvenido") +
						" a la Academia de Lenguas Internacionales!"
				);

				history.go(0);
			});
		}

		setValidated(true);
	};

	function getName(name) {
		if (name.length === 4) {
			return name[0] + " " + name[1];
		} else if (name.length <= 3) {
			return name[0];
		} else if (name.length === 5) {
			return name[0] + " " + name[1] + " " + name[2];
		}
	}

	function getFirstLastName(name) {
		if (name.length === 4) {
			return name[2];
		} else if (name.length <= 3) {
			return name[1];
		} else if (name.length >= 5) {
			return name[name.length - 2];
		}
	}

	function getSecondLastName(name) {
		if (name.length === 4) {
			return name[3];
		} else if (name.length == 3) {
			return name[2];
		} else if (name.length >= 5) {
			return name[name.length - 1];
		} else {
			return "";
		}
	}

	const getFormsAnswers = async () => {
		let tempForm1Answers = await getFormInfoByUserId(props.user.id);
		if (tempForm1Answers !== null && tempForm1Answers !== undefined){
			setForm1Answers(tempForm1Answers);
		}
	}
	
	useEffect(() => {
		let today:Date = new Date(); 
		maxDate.setFullYear(today.getFullYear() - 17);
		setMaxDate(maxDate);
		if (props.read){
			getFormsAnswers();
			setStep(1);
		}
	}, []);

	return (
		<>
			<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="form-modal">
				{step > 0 && !props.read && (
					<>
						<div className="info-form-modal">
							<h2>¡Te damos la bienvenida a la Academia de Lenguas Internacionales!</h2>
							<p>Por favor, ayúdanos a llenar el siguiente formulario para acceder a tu cuenta.</p>
						</div>
						<div className="info-form-modal-card">
							<h2>¡Te damos la bienvenida a la Academia de Lenguas Internacionales!</h2>
							<p>Por favor, ayúdanos a llenar el siguiente formulario para acceder a tu cuenta.</p>
						</div>
					</>
				)}
				{step > 0 ? (
					<Modal.Header>
						<div className="modal-form-header-container">
							<Modal.Title id="contained-modal-title-vcenter" className="form-title">
								Formulario de registro
							</Modal.Title>
							{step > 0 && (
								<div className="steps-circles-container">
									<div onClick={() => setStep(1)} className={"circle " + (step >= 1 ? "active" : "")}>
										<h3>1</h3>
									</div>
									<div onClick={() => setStep(2)} className={"circle " + (step >= 2 ? "active" : "")}>
										<h3>2</h3>
									</div>
									<div onClick={() => setStep(3)} className={"circle " + (step >= 3 ? "active" : "")}>
										<h3>3</h3>
									</div>
								</div>
							)}
						</div>
					</Modal.Header>
				) : (
					<></>
				)}
				<Modal.Body>
					{step === 0 && (
						<div className="step0-text-container" style={{ minHeight: 400 }}>
							<img src={FotoPortada} alt="foto con texto de bienvenido en diferentes idiomas" />
							<p className="text-center">Por favor, llena el siguiente formulario para acceder a tu perfil</p>
						</div>
					)}
					{step > 0 && (
						<p style={{ margin: 0, textAlign: "end" }}>
							<a className="obligatory">*</a> Campos obligatorios.
						</p>
					)}
					{step === 1 && <p className="font-weight-bold text-center">Datos personales</p>}
					{step === 2 && <p className="font-weight-bold text-center">Datos de contacto</p>}
					{step === 3 && <p className="font-weight-bold text-center">Cuéntanos sobre ti</p>}
					<Form id="my-form" noValidate validated={validated} onSubmit={handleSubmit}>
						<Row style={step === 1 ? { display: "flex" } : { display: "none" }}>
							<Col>
								<Row>
									<Form.Group as={Col} controlId="validationCustom02">
										<Form.Label>
											<a className="obligatory">*</a>Nombre:
										</Form.Label>
										<Form.Control required type="text" name="name" defaultValue={getName(name)} placeholder={props.read ? '' : "Nombre"} readOnly={props.read}/>
										<Form.Control.Feedback type="invalid">Por favor ingresa un nombre.</Form.Control.Feedback>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group as={Col} controlId="validationCustom03">
										<Form.Label>
											<a className="obligatory">*</a>Apellido paterno:
										</Form.Label>
										<Form.Control required type="text" name="firstLastName" defaultValue={getFirstLastName(name)} placeholder={props.read ? '' : "Apellido paterno"} readOnly={props.read}/>
										<Form.Control.Feedback type="invalid">Por favor ingresa tu apellido paterno.</Form.Control.Feedback>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group as={Col} controlId="validationCustom04">
										<Form.Label>
											<a className="obligatory">*</a>Apellido materno:
										</Form.Label>
										<Form.Control required type="text" name="secondLastName" placeholder={props.read ? '' : "Apellido materno"} defaultValue={getSecondLastName(name)} readOnly={props.read}/>
										<Form.Control.Feedback type="invalid">Por favor ingresa tu apellido materno.</Form.Control.Feedback>
									</Form.Group>
								</Row>
							</Col>
							<Col>
								<Row>
									<Form.Group as={Col} controlId="validationCustom05">
										<Form.Label>Ocupación:</Form.Label>
										<Form.Control 
											type="text" 
											name="profession" 
											placeholder={props.read ? '' : "Profesión"}
											className="notObligatoryInput"
											defaultValue={form1Answers ? form1Answers.profession : ''}
											readOnly={props.read}
										/>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group as={Col} controlId="validationCustom08">
										<Form.Label>Fecha de nacimiento:</Form.Label>
										<Form.Control
											type="date"
											name="birthdate"
											max={
												maxDate.getFullYear() +
												"-" +
												(maxDate.getMonth() + 1 < 10 ? "0" + (maxDate.getMonth() + 1) : maxDate.getMonth() + 1) +
												"-" +
												maxDate.getDate()
											}
											className="notObligatoryInput"
											defaultValue={props.user.birthdate ?? ''}
											readOnly={props.read}
										/>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group className="check-container-form1" as={Col} controlId="validationCustom18">
										<Form.Label>
											<a className="obligatory">*</a>Género:
										</Form.Label>
										<Form.Check id="radioVC18-hombre" label="Hombre" type="radio" value="hombre" 
											required name="gender" defaultChecked={form1Answers?.gender === 'hombre'} 
											disabled={props.read}
										/>
										<Form.Check id="radioVC18-mujer" label="Mujer" type="radio" value="mujer" 
											required name="gender" defaultChecked={form1Answers?.gender === 'mujer'} 
											disabled={props.read}
										/>
										<Form.Check id="radioVC18-sin-especificar" label="Sin especificar" type="radio" value="sin especificar" 
											required name="gender" defaultChecked={form1Answers?.gender === 'sin especificar'} 
											disabled={props.read}
										/>
									</Form.Group>
								</Row>
							</Col>
						</Row>
						<Row style={step === 2 ? { display: "flex" } : { display: "none" }}>
							<Col>
								<Row>
									<Form.Group as={Col} controlId="validationCustom01">
										<Form.Label>
											<a className="obligatory">*</a>Correo:
										</Form.Label>
										<Form.Control required type="email" name="email" defaultValue={props.user.email} placeholder={props.read ? '' : "Correo"} readOnly={props.read}/>
										<Form.Control.Feedback type="invalid">Ingresa un correo.</Form.Control.Feedback>
									</Form.Group>
								</Row>
								<Row>
									{/*  Añadiremos este número a un grupo de WhatsApp, en donde te compartiremos los enlaces de Zoom y el material de trabajo */}
									<Form.Group as={Col} controlId="validationCustom06">
										<Form.Label style={{ width: "100%" }}>
											<div style={{ display: "flex", gap: 10, position: "relative" }}>
												<p className="cellularStyle">
													<a className="obligatory">*</a>Teléfono de contacto (WhatsApp)
												</p>
												<div className="question-icon" style={{lineHeight:'21px'}} >
													?
													<div className="info-box">
														<p>
															Añadiremos este número a un grupo de WhatsApp, en donde te compartiremos los enlaces de Zoom y el material de trabajo
														</p>
													</div>
												</div>
											</div>
										</Form.Label>
										<Form.Control
											required
											type="text"
											minLength={10}
											name="cellphone"
											defaultValue={props.user.phone}
											placeholder={props.read ? '' : "Numero de celular"}
											readOnly={props.read}
										/>
										<Form.Control.Feedback type="invalid">Por favor ingresa un celular válido.</Form.Control.Feedback>
									</Form.Group>
								</Row>
							</Col>
							<Col>
								<Row>
									{/* Daremos de alta esta cuenta para que tengas acceso a las grabaciones de los seminarios */}
									<Form.Group as={Col} controlId="validationCustom07">
										<Form.Label style={{ width: "100%" }}>
											<div style={{ display: "flex", gap: 10, position: "relative" }}>
												<p className="cellularStyle">
													<a className="obligatory">*</a>Correo Gmail
												</p>
												<div className="question-icon" style={{lineHeight:'21px'}} >
													?
													<div className="info-box">
														<p>Daremos de alta esta cuenta para que tengas acceso a las grabaciones de los seminarios.</p>
													</div>
												</div>
											</div>
										</Form.Label>
										<Form.Control
											required
											type="email"
											name="secondaryEmail"
											placeholder= {props.read ? '' : "Correo electrónico (GMAIL)"}
											defaultValue={props.user.secondaryEmail}
											readOnly={props.read}
										/>
										<Form.Control.Feedback type="invalid">Por favor ingresa un correo.</Form.Control.Feedback>
									</Form.Group>
								</Row>
							</Col>
						</Row>
						<Row style={step === 3 ? { display: "flex" } : { display: "none" }}>
							<Col>
								<Row>
									<Form.Group as={Col} controlId="validationCustom15">
										<Form.Label>
											<a className="obligatory">*</a>¿Cómo conociste la Academia?
										</Form.Label>
										<Form.Control required 
											type="text" 
											placeholder= {props.read ? '' : "Tu respuesta"}
											name="academiaKnown" 
											defaultValue={form1Answers?.academiaKnown}
											readOnly={props.read}
										/>
										<Form.Control.Feedback type="invalid">Por favor ingresa una respuesta.</Form.Control.Feedback>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group as={Col} controlId="validationCustom16">
										<Form.Label>
											<a className="obligatory">*</a>Razón por la que te interesa convertirte en políglota
										</Form.Label>
										<Form.Control required type="text" placeholder= {props.read ? '' : "Tu razón"} 
										name="reasonToLearn" 
										defaultValue={form1Answers?.reasonToLearn}
										readOnly={props.read}
									/>
										<Form.Control.Feedback type="invalid">Por favor ingresa una respuesta.</Form.Control.Feedback>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group as={Col} controlId="validationCustom17">
										<Form.Label>Idioma(s) favorito(s)</Form.Label>
										<Form.Control type="text" placeholder= {props.read ? '' : "Tu(s) favorito(s)"} 
											name="favoritesLangs" className="notObligatoryInput"
											defaultValue={form1Answers?.favoritesLangs}
											readOnly={props.read}
										/>
									</Form.Group>
								</Row>
							</Col>
							<Col>
								<Row>
									<Form.Group as={Col} controlId="validationCustom09">
										<Form.Label>
											<a className="obligatory">*</a>¿Has estudiado algún idioma?
										</Form.Label>
										<Form.Check
											id="radioVC09-si"
											label="Sí"
											type="radio"
											value="true"
											required
											onChange={(e) => setHaveStudied(e.target.value)}
											name="haveStudied"
											defaultChecked={form1Answers?.haveStudied === true} 
											disabled={props.read}
										/>
										<Form.Check
											id="radioVC09-no"
											label="No"
											type="radio"
											value="false"
											required
											onChange={(e) => setHaveStudied(e.target.value)}
											name="haveStudied"
											defaultChecked={form1Answers?.haveStudied === false} 
											disabled={props.read}
										/>
									</Form.Group>
								</Row>
								<Row style={(haveStudied === "true" || form1Answers?.haveStudied === true) ? { display: "flex" } : { display: "none" }}>
									<Form.Group as={Col} controlId="validationCustom19">
										<Form.Label>¿Cuáles?</Form.Label>
										<Form.Control type="text" placeholder= {props.read ? '' : "Tu respuesta"} 
										name="whichOnes" className="notObligatoryInput"
										defaultValue={form1Answers?.whichOnes}
										readOnly={props.read}
									/>
									</Form.Group>
								</Row>
								<Row style={(haveStudied === "true" || form1Answers?.haveStudied === true) ? { display: "flex" } : { display: "none" }}>
									<Form.Group as={Col} controlId="validationCustom10">
										<Form.Label>¿Dónde?</Form.Label>
										<Form.Control type="text" placeholder= {props.read ? '' : "Tu respuesta"} 
										name="whereStudied" className="notObligatoryInput"
										defaultValue={form1Answers?.whereStudied}
										readOnly={props.read}
									/>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group as={Col} controlId="validationCustom11">
										<Form.Label>
											<a className="obligatory">*</a>¿Hablas algún idioma extranjero?
										</Form.Label>
										<Form.Check
											id="radioVC11-si"
											label="Sí"
											type="radio"
											value="true"
											required
											onChange={(e) => setSpeakOtherLang(e.target.value)}
											name="speakOtherLang"
											defaultChecked={form1Answers?.speakOtherLang === true} 
											disabled={props.read}
										/>
										<Form.Check
											id="radioVC11-no"
											label="No"
											type="radio"
											value="false"
											required
											onChange={(e) => setSpeakOtherLang(e.target.value)}
											name="speakOtherLang"
											defaultChecked={form1Answers?.speakOtherLang === false} 
											disabled={props.read}
										/>
									</Form.Group>
								</Row>
								<Row style={(speakOtherLang === "true" || form1Answers?.speakOtherLang === true)? { display: "flex" } : { display: "none" }}>
									<Form.Group as={Col} controlId="validationCustom12">
										<Form.Label>¿Cuáles?</Form.Label>
										<Form.Control type="text" placeholder= {props.read ? '' : "Cuáles"} 
										name="whichLangs" className="notObligatoryInput" 
										defaultValue={form1Answers?.whichLangs}
										readOnly={props.read}
									/>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group as={Col} controlId="validationCustom13">
										<Form.Label>
											<a className="obligatory">*</a>¿Cuentas con alguna certificación?
										</Form.Label>
										<Form.Check
											id="radioVC12-si"
											label="Sí"
											type="radio"
											value="true"
											required
											onChange={(e) => setHaveCertification(e.target.value)}
											name="haveCertification"
											defaultChecked={form1Answers?.haveCertification === true} 
											disabled={props.read}
										/>
										<Form.Check
											id="radioVC12-no"
											label="No"
											type="radio"
											value="false"
											required
											onChange={(e) => setHaveCertification(e.target.value)}
											name="haveCertification"
											defaultChecked={form1Answers?.haveCertification === false} 
											disabled={props.read}
										/>
									</Form.Group>
								</Row>
								<Row style={(haveCertification === "true" || form1Answers?.haveCertification === true)? { display: "flex" } : { display: "none" }}>
									<Form.Group as={Col} controlId="validationCustom14">
										<Form.Label>¿Cuáles?</Form.Label>
										<Form.Control type="text" placeholder= {props.read ? '' : "Cuáles"} 
										name="whichCertification" className="notObligatoryInput"
										defaultValue={form1Answers?.whichCertification}
										disabled={props.read}
									/>
									</Form.Group>
								</Row>
							</Col>
						</Row>
						<Row style={step === 3 ? { display: "flex" } : { display: "none" }}>
							<Form.Group as={Col} controlId="validationCustom20">
								<Form.Label>¿Cuánto tiempo de estudio tienes planeado dedicarle al estudio de los idiomas entre semana?</Form.Label>
								<Form.Control type="text" placeholder={props.read ? '' : "Tu respuesta"} 
								className="notObligatoryInput" name="timeOfStudy"
								defaultValue={form1Answers?.timeOfStudy}
								disabled={props.read}
								/>
							</Form.Group>
						</Row>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					{step === 0 && (
						<button className="tertiary form1-btn" style={{ marginRight: "auto", width: 200, maxWidth: "none" }} onClick={props.onHide}>
							En otro momento
						</button>
					)}
					{step > 1 && (
						<button className="tertiary form1-btn" style={{ marginRight: "auto" }} onClick={() => setStep(step - 1)}>
							Atrás
						</button>
					)}
					{props.read &&
						<button className="tertiary form1-btn" onClick={props.onHide}>
								Cerrar
						</button>
					}
					{step < 3 && (
						<button className="tertiary form1-btn" onClick={() => setStep(step + 1)}>
							Continuar
						</button>
					)}
					{step === 3 && (
						<>
							{!loading && !props.read && (
								<button form="my-form" className="tertiary return-btn-style form1-btn" type="submit">
									Finalizar
								</button>
							)}
							{loading && (
								<div className="spinner-border text-danger" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							)}
						</>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default FormModal;
