import { getParentsCategorys } from "@components/Vocabulary/PersonalizedTest/functions/functions";
import { EasyDifficult, Expert, HardDifficult, NormalDifficult, VeryEasy } from "assets/Imports";
import { capitalizeFirstLetter } from "functions/Functions";

function AdvanceConfigQuiz(props) {
	return (
		<>
			{props.langSelected.langSelected !== "none" && (
				<>
					<div className="test-row">
						<h3>Selecciona de igual forma si quieres categorías específicas en la ronda de estudio</h3>
					</div>
					<div className="test-row option ">
						{props.categoriesSelection.avaibleCategories.map((category, index) => {
							return (
								<div className="category" key={"categoria-" + index}>
									<input
										type="checkbox"
										name={category}
										id={"category-" + category}
										checked={props.categoriesSelection.selectedCategories.includes(category)}
										onChange={props.onChangeCategory}
									/>
									<label htmlFor={"category-" + category}>{capitalizeFirstLetter(category)}</label>
									<span
										className="modal-quantity-words-label-categories"
										onClick={() => props.handleSeeWordsModal(category, "category")}
									>
										({props.langSelected.langArrayBase?.filter((e) => e.category.includes(category)).length})
									</span>
								</div>
							);
						})}
					</div>
				</>
			)}
			{props.langSelected.langSelected !== "none" && props.categoriesSelection.avaibleSubCategories.length > 0 && (
				<>
					<div className="test-row">
						<h3>Selecciona de igual forma si quieres subcategorías específicas en la ronda de estudio</h3>
					</div>
					<div className="test-row option ">
						{props.categoriesSelection.avaibleSubCategories.map((subCategory, index) => {
							if (subCategory.subCategory !== null) {
								return (
									<div className="sub-category-container">
										<div className="category" key={"subcategoria-" + index}>
											<input
												type="checkbox"
												name={subCategory.subCategory}
												id={"subCategory-" + subCategory.subCategory}
												checked={props.categoriesSelection.selectedSubCategories.includes(subCategory.subCategory)}
												onChange={props.onChangeSubCategory}
											/>
											<label htmlFor={"subCategory-" + subCategory.subCategory}>
												{capitalizeFirstLetter(subCategory.subCategory)}
											</label>
											<span
												className="modal-quantity-words-label-categories"
												onClick={() => props.handleSeeWordsModal(subCategory.subCategory, "subCategory", props.categoriesSelection.selectedCategories)}
											>
												(
												{
													props.langSelected.langArrayBase?.filter(
														(e) =>
															e.category.some((c) => props.categoriesSelection.selectedCategories.includes(c)) &&
															e.sub_category.includes(subCategory.subCategory)
													).length
												}
												)
											</span>
										</div>
										<span>{getParentsCategorys(subCategory.parents)}</span>
									</div>
								);
							}
						})}
					</div>
				</>
			)}
			{(props.langSelected.langArray === undefined ||
				(props.categoriesSelection.selectedCategories.length === 0 &&
					props.categoriesSelection.selectedSubCategories.length === 0) ||
				props.langSelected.langArray.filter((e) => e.custom_difficulty === null).length !==
					props.langSelected.langArray.length) && (
				<div className="test-row">
					<h3>Selecciona la dificultad de los términos en la ronda de estudio (Puedes escoger más de una)</h3>
				</div>
			)}
			<div className="test-row option">
				{((props.categoriesSelection.selectedCategories.length === 0 &&
					props.categoriesSelection.selectedSubCategories.length === 0) ||
					props.allWordsArray.some(
						(e) =>
							parseFloat(e.custom_difficulty) <= 1 &&
							parseFloat(e.custom_difficulty) > 0.9 &&
							e.language === props.langSelected.langSelected &&
							(e.category.some((e) => props.categoriesSelection.selectedCategories.includes(e)) ||
								e.sub_category.some((e) => props.categoriesSelection.selectedSubCategories.includes(e)))
					)) && (
					<div className="lang">
						<img src={VeryEasy} alt="icono de facil" />
						<div>
							<input
								onChange={props.onChangeDifficult}
								id="very-easy"
								checked={props.selectedDifficult.includes("very-easy")}
								name="very-easy"
								type="checkbox"
								disabled={
									props.categoriesSelection.selectedCategories.length === 0 &&
									props.categoriesSelection.selectedSubCategories.length === 0
								}
							/>
							<label htmlFor="very-easy">Muy fácil</label>
						</div>
					</div>
				)}
				{((props.categoriesSelection.selectedCategories.length === 0 &&
					props.categoriesSelection.selectedSubCategories.length === 0) ||
					props.allWordsArray.some(
						(e) =>
							parseFloat(e.custom_difficulty) <= 0.9 &&
							parseFloat(e.custom_difficulty) > 0.8 &&
							e.language === props.langSelected.langSelected &&
							(e.category.some((e) => props.categoriesSelection.selectedCategories.includes(e)) ||
								e.sub_category.some((e) => props.categoriesSelection.selectedSubCategories.includes(e)))
					)) && (
					<div className="lang">
						<img src={EasyDifficult} alt="icono de facil" />
						<div>
							<input
								onChange={props.onChangeDifficult}
								id="easy"
								checked={props.selectedDifficult.includes("easy")}
								name="easy"
								type="checkbox"
								disabled={
									props.categoriesSelection.selectedCategories.length === 0 &&
									props.categoriesSelection.selectedSubCategories.length === 0
								}
							/>
							<label htmlFor="easy">Fácil</label>
						</div>
					</div>
				)}
				{((props.categoriesSelection.selectedCategories.length === 0 &&
					props.categoriesSelection.selectedSubCategories.length === 0) ||
					props.allWordsArray.some(
						(e) =>
							parseFloat(e.custom_difficulty) <= 0.8 &&
							parseFloat(e.custom_difficulty) > 0.7 &&
							e.language === props.langSelected.langSelected &&
							(e.category.some((e) => props.categoriesSelection.selectedCategories.includes(e)) ||
								e.sub_category.some((e) => props.categoriesSelection.selectedSubCategories.includes(e)))
					)) && (
					<div className="lang">
						<img src={NormalDifficult} alt="icono de dificultad mediana" />
						<div>
							<input
								onChange={props.onChangeDifficult}
								id="normal"
								checked={props.selectedDifficult.includes("normal")}
								name="normal"
								type="checkbox"
								disabled={
									props.categoriesSelection.selectedCategories.length === 0 &&
									props.categoriesSelection.selectedSubCategories.length === 0
								}
							/>
							<label htmlFor="normal">Intermedia baja</label>
						</div>
					</div>
				)}
				{((props.categoriesSelection.selectedCategories.length === 0 &&
					props.categoriesSelection.selectedSubCategories.length === 0) ||
					props.allWordsArray.some(
						(e) =>
							parseFloat(e.custom_difficulty) <= 0.7 &&
							parseFloat(e.custom_difficulty) > 0.5 &&
							e.language === props.langSelected.langSelected &&
							(e.category.some((e) => props.categoriesSelection.selectedCategories.includes(e)) ||
								e.sub_category.some((e) => props.categoriesSelection.selectedSubCategories.includes(e)))
					)) && (
					<div className="lang">
						<img src={NormalDifficult} alt="icono de dificultad mediana" />
						<div>
							<input
								onChange={props.onChangeDifficult}
								id="normal-high"
								checked={props.selectedDifficult.includes("normal-high")}
								name="normal-high"
								type="checkbox"
								disabled={
									props.categoriesSelection.selectedCategories.length === 0 &&
									props.categoriesSelection.selectedSubCategories.length === 0
								}
							/>
							<label htmlFor="normal">Intermedia alta</label>
						</div>
					</div>
				)}
				{((props.categoriesSelection.selectedCategories.length === 0 &&
					props.categoriesSelection.selectedSubCategories.length === 0) ||
					props.allWordsArray.some(
						(e) =>
							parseFloat(e.custom_difficulty) <= 0.5 &&
							parseFloat(e.custom_difficulty) > 0.3 &&
							e.language === props.langSelected.langSelected &&
							(e.category.some((e) => props.categoriesSelection.selectedCategories.includes(e)) ||
								e.sub_category.some((e) => props.categoriesSelection.selectedSubCategories.includes(e)))
					)) && (
					<div className="lang">
						<img src={HardDifficult} alt="icono de dificultad dificil" />
						<div>
							<input
								onChange={props.onChangeDifficult}
								id="hard"
								checked={props.selectedDifficult.includes("hard")}
								name="hard"
								type="checkbox"
								disabled={
									props.categoriesSelection.selectedCategories.length === 0 &&
									props.categoriesSelection.selectedSubCategories.length === 0
								}
							/>
							<label htmlFor="hard">Difícil</label>
						</div>
					</div>
				)}
				{((props.categoriesSelection.selectedCategories.length === 0 &&
					props.categoriesSelection.selectedSubCategories.length === 0) ||
					props.allWordsArray.some(
						(e) =>
							parseFloat(e.custom_difficulty) <= 0.3 &&
							e.language === props.langSelected.langSelected &&
							(e.category.some((e) => props.categoriesSelection.selectedCategories.includes(e)) ||
								e.sub_category.some((e) => props.categoriesSelection.selectedSubCategories.includes(e)))
					)) && (
					<div className="lang">
						<img src={Expert} alt="icono de dificultad dificil" />
						<div>
							<input
								onChange={props.onChangeDifficult}
								id="expert"
								checked={props.selectedDifficult.includes("expert")}
								name="expert"
								type="checkbox"
								disabled={
									props.categoriesSelection.selectedCategories.length === 0 &&
									props.categoriesSelection.selectedSubCategories.length === 0
								}
							/>
							<label htmlFor="expert">Muy difícil</label>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default AdvanceConfigQuiz;
