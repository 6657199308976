import InvoiceContainer from "@containers/InvoiceContainer/InvoiceContainer";

function InvoiceScreen() {
	return (
		<div style={{ overflow: "hidden" }}>
			<InvoiceContainer />
		</div>
	);
}

export default InvoiceScreen;
