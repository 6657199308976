import AudioRecorder from "@components/AudioRecorder/AudioRecorder";
import TextEditor from "@components/TextEditor/TextEditor";
import { faWindows } from "@fortawesome/free-brands-svg-icons";
import {
	AnswerIcon,
	BrazilFlagSVG,
	ChinaFlagSVG,
	EditPostIcon,
	FranceFlagSVG,
	GermanyFlagSVG,
	ItalyFlagSVG,
	JapanFlagSVG,
	RussiaFlagSVG,
	TrashPostIcon,
	UnitedStatesFlagSVG,
} from "assets/Imports";
import { collection, addDoc, doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import parse from "html-react-parser";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { consults, langsBoolean } from "../Interfaces/Interfaces";

function RevisionCard({ consult, index, user, seminaryNum, seminarios, setLoading, aScroll, setAScroll }) {
	const [consultSeminaryNum, setConsultSeminaryNum] = useState<number>(seminaryNum);
	const history = useHistory();
	const [consultPost, setConsultPost] = useState<string>("");
	const [send, setSend] = useState<boolean>(false);
	const [consultLangs, setConsultLangs] = useState<langsBoolean>({
		french: false,
		german: false,
		italian: false,
		russian: false,
		chinese: false,
		portuguese: false,
		english: false,
		japanese: false,
	});
	const [answerConsult, setAnswerConsult] = useState<boolean>(false);
	const [editConsult, setEditConsult] = useState<boolean>(false);
	const [hashtags, setHashtags] = useState<Array<string>>(consult.hashtags);
	const [postIn, setPostIn] = useState<Array<number>>([seminaryNum]);
	const [seminaryNumCheck, setSeminaryNumCheck] = useState<boolean>(true);
	const location = useLocation<any>();
	const [answerWith, setAnswerWith] = useState<number>(0);
	const [voiceNote, setVoiceNote] = useState<string>("");
	const [sendVn, setSendVn] = useState<boolean>(false);

	if (location.state !== undefined) {
		let scroll = window.document.getElementById("revisionCard-" + location.state.consultId);

		if (scroll !== null && !aScroll) {
			scroll.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
			setAScroll(true);
		}
	}

	const SeminaryPosting = (e, sem) => {
		console.log(e + " - " + sem);
		let array = postIn;

		if (e === true) {
			array.push(sem);
			setPostIn(array);
			if (sem === seminaryNum) {
				setSeminaryNumCheck(true);
			}
		} else {
			let index = array.indexOf(sem);

			if (index !== -1) {
				array.splice(index, 1);
				setPostIn(array);

				if (sem === seminaryNum) {
					setSeminaryNumCheck(false);
				}
			}
		}

		console.log(array);
	};

	function getInitials(name: string) {
		const initials = name.split(" ");
		let fullInitials = "";

		initials.forEach((word) => {
			if (word !== "") {
				fullInitials += word[0];
			}
		});

		return fullInitials[0] + "" + (fullInitials.length > 1 ? fullInitials[1] : "");
	}

	const answerWithVn = () => {
		setSendVn(true);
	};

	useEffect(() => {
		if (sendVn) {
			consultAnswer();
		}
	}, [sendVn]);

	const consultAnswer = async () => {
		setLoading(true);
		let resC;
		postIn.forEach(async (num, index) => {
			console.log(index);
			if (num !== seminaryNum) {
				await addDoc(collection(db, "consults"), {
					name: consult.name,
					postText: consult.postText,
					hashtags: consult.hashtags,
					french: consult.french,
					german: consult.german,
					japanese: consult.japanese,
					italian: consult.italian,
					chinese: consult.chinese,
					russian: consult.russian,
					english: consult.english,
					portuguese: consult.portuguese,
					date: new Date(),
					userId: consult.userId,
					seminaryNum: num,
					verified: true,
					numberOfReplies: 1,
					userType: consult.userType,
					profileColor: consult.profileColor,
				}).then(async (resConsult) => {
					await addDoc(collection(db, "consultAnswer"), {
						name: user.name,
						answerText: consultPost,
						date: new Date(),
						userId: user.id,
						consultId: resConsult.id,
						verified: user.userType === 2 || user.userType === 4 ? true : false,
						userType: user.userType,
						profileColor: user.color,
						didnotUnderstand: [],
						didUnderstand: [],
						voiceNoteUrl: voiceNote,
						voiceNote: sendVn,
					}).then(async (consultAnswer: any) => {
						const docRef = doc(db, "consultAnswer", consultAnswer.id);
						const docSnap = await getDoc(docRef);
						if (user.userType === 2 || user.userType === 4) {

							await addDoc(collection(db, "notifications"), {
								name: "Academia de Lenguas Internacionales",
								text: !sendVn ? consultPost : "Nota de audio",
								date: new Date(),
								userId: consult.userId,
								consultId: resConsult.id,
								consultAnswerId: docSnap.data()?.consultId,
								userType: user.userType === 2 || user.userType === 4 ? user.userType : 1,
								seen: false,
								profileColor: user.color,
								type: "aprove",
								seminaryNum: num,
							});
						}
					});
				});
			} else {
				const docRef = doc(db, "consults", consult.id);

				await updateDoc(docRef, {
					verified: true,
					numberOfReplies: consult.numberOfReplies + 1,
				});

				await addDoc(collection(db, "consultAnswer"), {
					name: user.name,
					answerText: consultPost,
					date: new Date(),
					userId: user.id,
					consultId: consult.id,
					verified: user.userType === 2 || user.userType === 4 ? true : false,
					userType: user.userType,
					profileColor: user.color,
					seminaryNum: seminaryNum,
					didnotUnderstand: [],
					didUnderstand: [],
					voiceNoteUrl: voiceNote,
					voiceNote: sendVn,
				}).then(async (consultAnswer: any) => {
					console.log(consultAnswer.id);
					const docRef = doc(db, "consultAnswer", consultAnswer.id);
					const docSnap = await getDoc(docRef);
					console.log(docSnap.data());
					if (user.userType === 2 || user.userType === 4) {
						console.log("notifications");

						await addDoc(collection(db, "notifications"), {
							name: "Academia de Lenguas Internacionales",
							text: !sendVn ? consultPost : "Nota de audio",
							date: new Date(),
							userId: consult.userId,
							consultId: consult.id,
							consultAnswerId: docSnap.data()?.consultId,
							userType: user.userType === 2 || user.userType === 4 ? user.userType : 1,
							seen: false,
							profileColor: user.color,
							type: "aprove",
							seminaryNum: num,
						});
					}
				});
			}

			if (index === postIn.length - 1) {
				setAnswerConsult(false);
				setConsultPost("");
				setHashtags([]);
				setConsultLangs({
					french: false,
					german: false,
					italian: false,
					russian: false,
					chinese: false,
					portuguese: false,
					english: false,
					japanese: false,
				});

				if (postIn.indexOf(num) === -1) {
					await deleteDoc(doc(db, "consults", consult.id));
				}
				setLoading(false);
			}
		});
	};

	const deleteConsult = async () => {
		if (window.confirm("¿Estas seguro que deseas eliminar esta consulta?")) {
			await deleteDoc(doc(db, "consults", consult.id)).then(() => {});
		}
	};

	const setEditPost = () => {
		setConsultPost(consult.postText);
		setAnswerConsult(false);
		setConsultLangs({
			french: consult.french,
			german: consult.german,
			italian: consult.italian,
			russian: consult.russian,
			chinese: consult.chinese,
			portuguese: consult.portuguese,
			english: consult.english,
			japanese: consult.japanese,
		});
		setEditConsult(!editConsult);
	};

	const setAnswerPost = () => {
		setConsultPost("");
		setConsultLangs({
			french: false,
			german: false,
			italian: false,
			russian: false,
			chinese: false,
			portuguese: false,
			english: false,
			japanese: false,
		});
		setEditConsult(false);
		setAnswerConsult(!answerConsult);
	};

	useEffect(() => {
		let isMounted = true;
		if (!editConsult) {
			setConsultSeminaryNum(seminaryNum);
		}
		return () => {
			isMounted = false;
		};
	}, [editConsult]);

	useEffect(() => {
		let isMounted = true;
		const editConsultText = async () => {
			const docRef = doc(db, "consults", consult.id);
			await updateDoc(docRef, {
				postText: consultPost,
				french: consultLangs.french,
				german: consultLangs.german,
				japanese: consultLangs.japanese,
				italian: consultLangs.italian,
				chinese: consultLangs.chinese,
				russian: consultLangs.russian,
				english: consultLangs.english,
				portuguese: consultLangs.portuguese,
				hashtags: hashtags,
				seminaryNum: consultSeminaryNum,
			}).then(() => {
				setConsultPost("");
				setConsultLangs({
					french: false,
					german: false,
					italian: false,
					russian: false,
					chinese: false,
					portuguese: false,
					english: false,
					japanese: false,
				});
				setEditConsult(false);
				if (seminaryNum !== consultSeminaryNum) {
					history.push("/micuenta/consultas/" + consultSeminaryNum);
				}
			});
		};

		if (send) {
			if (answerConsult) {
				consultAnswer();
			} else if (editConsult) {
				editConsultText();
			}
			setSend(false);
		}
		return () => {
			isMounted = false;
		};
	}, [send]);

	return (
		<div className="consultpost-card" key={"revisionCard- " + index} id={"revisionCard-" + consult.id}>
			<div className="content">
				<div className="post-container">
					<div className="profile-pic-container" style={{ backgroundColor: consult.profileColor }}>
						<h3>{getInitials(consult.name)}</h3>
					</div>
					<div className="post-text-container">
						<div className="post-info-container">
							<h3>{consult.name}</h3>
							{(user.userType === 2 || user.userType === 4) && (
								<p>
									{new Date(consult.date.seconds * 1000).toLocaleDateString("en-gb")} -{" "}
									{new Date(consult.date.seconds * 1000).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })}
								</p>
							)}
						</div>
						<div className="text-container">{parse(consult.postText)}</div>
						<div className="hashtag-flag-container">
							<div className="hashtag-container">
								{consult.hashtags.map((hashtag, index) => {
									return <p key={"hashtag-" + index}>#{hashtag}</p>;
								})}
							</div>
							<div className="flags-container">
								{consult.french && <img src={FranceFlagSVG} alt="bandera de francia" />}
								{consult.german && <img src={GermanyFlagSVG} alt="bandera de alemania" />}
								{consult.italian && <img src={ItalyFlagSVG} alt="bandera de italia" />}
								{consult.russian && <img src={RussiaFlagSVG} alt="bandera de rusia" />}
								{consult.japanese && <img src={JapanFlagSVG} alt="bandera de japon" />}
								{consult.chinese && <img src={ChinaFlagSVG} alt="bandera de china" />}
								{consult.english && <img src={UnitedStatesFlagSVG} alt="bandera de estados unidos" />}
								{consult.portuguese && <img src={BrazilFlagSVG} alt="bandera de portugal" />}
							</div>
						</div>
						<div className="admin-options-container">
							{(user.userType === 2 || user.userType === 4) && (
								<div className="option-btn-container" onClick={setAnswerPost}>
									<img src={AnswerIcon} alt="icono de responder" />
									<h3>Responder</h3>
								</div>
							)}
							<div className="option-btn-container" onClick={setEditPost}>
								<img src={EditPostIcon} alt="icono de editar" />
								<h3>Editar</h3>
							</div>
							{(user.userType === 2 || user.userType === 4) && (
								<div className="option-btn-container" onClick={deleteConsult}>
									<img src={TrashPostIcon} alt="icono de eliminar" />
									<h3>Eliminar</h3>
								</div>
							)}
						</div>
						{user.userType !== 2 && user.userType !== 4 && (
							<div className="check-container">
								<p>Tu consulta está siendo respondida.</p>
							</div>
						)}

						<div className={"text-editor-container" + (answerConsult || editConsult ? " text-editor-container-open" : "")}>
							{answerConsult && (
								<>
									{user.userType === 2 || user.userType === 4 ? (
										<>
											<div className="select-answer-type-consult">
												<button className={answerWith === 1 ? "active" : ""} onClick={() => setAnswerWith(1)}>
													Audio
												</button>
												<button className={answerWith === 2 ? "active" : ""} onClick={() => setAnswerWith(2)}>
													Texto
												</button>
											</div>
											{answerWith === 1 && (
												<>
													{voiceNote === "" ? (
														<AudioRecorder setVoiceNote={setVoiceNote} />
													) : (
														<div className="consult-answer-voice-note-container">
															<audio controls src={voiceNote}>
																<code>audio</code> element.
															</audio>
															<button onClick={answerWithVn} className="return-btn-style">
																Enviar
															</button>
														</div>
													)}
												</>
											)}
											{answerWith === 2 && (
												<TextEditor
													seminaryNum={seminaryNum}
													seminaryNumCheck={seminaryNumCheck}
													name={"revision-card-" + index}
													seminarios={seminarios}
													postIn={postIn}
													SeminaryPosting={SeminaryPosting}
													user={user}
													langDirection={"down"}
													consultLangs={consultLangs}
													consultPost={""}
													setConsultPost={setConsultPost}
													setSend={setSend}
													setConsultLangs={setConsultLangs}
													type={"answer"}
													id={consult.id}
												/>
											)}
										</>
									) : (
										<TextEditor
											seminaryNum={seminaryNum}
											seminaryNumCheck={seminaryNumCheck}
											name={"revision-card-" + index}
											seminarios={seminarios}
											postIn={postIn}
											SeminaryPosting={SeminaryPosting}
											user={user}
											langDirection={"down"}
											consultLangs={consultLangs}
											consultPost={""}
											setConsultPost={setConsultPost}
											setSend={setSend}
											setConsultLangs={setConsultLangs}
											type={"answer"}
											id={consult.id}
										/>
									)}
								</>
							)}
							{editConsult && (
								<>
									{(user.userType === 2 || user.userType === 4) && (
										<div>
											<label htmlFor="seminarynum" style={{ marginRight: 10 }}>
												Seminario
											</label>
											<select
												name="seminary"
												value={consultSeminaryNum}
												onChange={(e) => setConsultSeminaryNum(parseInt(e.target.value))}
												id="seminarynum"
											>
												{seminarios.map((num) => {
													return <option value={num}>{num}</option>;
												})}
											</select>
										</div>
									)}
									<TextEditor
										langDirection={"down"}
										consultPost={consultPost}
										setConsultPost={setConsultPost}
										setSend={setSend}
										consultLangs={consultLangs}
										setConsultLangs={setConsultLangs}
										setHashtags={setHashtags}
										hashtags={hashtags}
										id={consult.id}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RevisionCard;
