import { useSeminariesService } from "../../services/SeminariesService";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { getDate, getImage, romanize } from "functions/Functions";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import { recordings } from "./interfaces/RecordingsSectionInterfaces";
import "./RecordingsSectionStyles.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import { alerModal } from "@containers/VocabularySeminaryContainer/components/Test/Test";
import { AlertModal } from "@components/AlertModal/AlertModal";
import { AdminGenSelector } from "@components/Admin/AdminGenSelector/AdminGenSelector";
import { el } from "date-fns/locale";
import { CardsContainer } from "./component/CardsContainer";
import { compareGenerations } from "functions/Generations/FunctionsGenerations";
import { useGenerations } from "@hooks/GenerationsFilters/useGenerations";

function RecordingsSection({ user }) {
	const location: any = useLocation();
	const [loading, setLoading] = useState<boolean>(true);
	const [recordings, setRecordings] = useState<Array<Array<recordings>>>(new Array());
	const [recordingsCopy, setRecordingsCopy] = useState<Array<Array<recordings>>>(new Array());
	const { paidSeminaries, nextSeminaries }: any = useSeminariesService();
	const history = useHistory();
	const genIdFromLocationState = location.state ? location.state.genId : null;
	const [genId, setGenId] = useState<string>(genIdFromLocationState || user.generationId);

	const [MaterialId, setMaterialId] = useState("");
	const [order, setOrder] = useState<string>("modules");
	const [alertModal, setAlerModal] = useState<alerModal>({
		show: false,
		title: "Mantenimiento",
		message: "Grabaciones en mantenimiento.",
	});
	const { gens, handleCheck, load, eyeColor, setGens, setEyeColor } = useGenerations(compareGenerations);


	
	const getRecordings = async () => {
		const q = query(collection(db, "generations", genId, "material"), orderBy("num"));
		let module1 = new Array();
		let module2 = new Array();
		let module3 = new Array();
		let module4 = new Array();
		let module5 = new Array();

		const snapshot = await getDocs(q);

		let index = 0;


		snapshot.forEach((doc) => {
			index++;

			const data: recordings = {
				id: doc.id,
				seminaryNum: doc.data().num,
				date: doc.data().date,
				videoUrl: doc.data().videoUrl,
			};

			if (data.seminaryNum <= 10) {
				module1.push(data);
			} else if (data.seminaryNum <= 25 && data.seminaryNum > 10) {
				module2.push(data);
			} else if (data.seminaryNum <= 40 && data.seminaryNum > 25) {
				module3.push(data);
			} else if (data.seminaryNum <= 50 && data.seminaryNum > 40) {
				module4.push(data);
			} else if (data.seminaryNum <= 60 && data.seminaryNum > 50) {
				module5.push(data);
			}

			if (index === snapshot.size) {
				let tempRecordings = new Array();
				[module1, module2, module3, module4, module5].map((module) => {
					if (module.length > 0) tempRecordings.push(module);
				});
				setRecordings(tempRecordings);
				setRecordingsCopy(tempRecordings);
				setLoading(false);
			}
		});
	};

	const getGenerationsAdmin = async () => {
		const q = query(collection(db, "generations"));
		const qs = await getDocs(q);

		const genArray = new Array();

		qs.docs.map((gen) => {
			genArray.push({ ...gen.data(), id: gen.id });
		});

		setGens(genArray);
	};

	function compare(a, b) {
		if (a.seminaryNum > b.seminaryNum) {
			return -1;
		}
		if (a.seminaryNum < b.seminaryNum) {
			return 1;
		}
		return 0;
	}

	const orderRecent = () => {
		let tempArray = new Array();
		let module1 = new Array();
		let module2 = new Array();
		let module3 = new Array();
		let module4 = new Array();
		let module5 = new Array();

		let allTemp = new Array(
			...(recordings[0] !== undefined ? recordings[0].sort(compare) : []),
			...(recordings[1] !== undefined ? recordings[1].sort(compare) : []),
			...(recordings[2] !== undefined ? recordings[2].sort(compare) : []),
			...(recordings[3] !== undefined ? recordings[3].sort(compare) : []),
			...(recordings[4] !== undefined ? recordings[4].sort(compare) : [])
		);

		allTemp.map((seminary) => {
			let index = nextSeminaries.findIndex((e) => e.num === seminary.seminaryNum);
			if (index === -1) {
				if (seminary.seminaryNum <= 10) {
					module1.push(seminary);
				} else if (seminary.seminaryNum <= 25 && seminary.seminaryNum > 10) {
					module2.push(seminary);
				} else if (seminary.seminaryNum <= 40 && seminary.seminaryNum > 25) {
					module3.push(seminary);
				} else if (seminary.seminaryNum <= 50 && seminary.seminaryNum > 40) {
					module4.push(seminary);
				} else if (seminary.seminaryNum <= 60 && seminary.seminaryNum > 50) {
					module5.push(seminary);
				}
			}
		});

		[module5, module4, module3, module2, module1].map((module) => {
			if (module.length > 0) tempArray.push(module);
		});

		setRecordings(tempArray);
	};

	useEffect(() => {
		if (user.generationId !== "" && user.generationId !== undefined) {
			getRecordings();
		}
	}, []);

	useEffect(() => {
		getRecordings();
	}, [genId]);

	useEffect(() => {
		if (
			(location.state !== undefined && location.state.genId !== genId) ||
			(location.state === undefined && genId !== user.generationId)
		) {
			// getRecordings();
			history.push({ pathname: "/micuenta/grabaciones", state: { genId: genId } });
		}
	}, [genId]);

	useEffect(() => {
		console.log("sksjsjsjssjksj")
		if (location?.state?.materialId !== undefined && location?.state?.generationId !== undefined) {
			setMaterialId(location?.state?.materialId);
			setGenId(location?.state?.generationId);
		}
	}, [location?.state?.materialId, location?.state?.generationId]);

	useEffect(() => {
		switch (order) {
			case "modules":
				setRecordings(recordingsCopy);
				break;

			case "asc":
				orderRecent();
				break;
			default:
				break;
		}
	}, [order]);

	if (user.generationId === "" || user.generationId === undefined) {
		<div style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
			Aún no te encuentras inscrito a un programa.
		</div>;
	}

	if (loading) {
		return (
			<div style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<AlertModal
					show={alertModal.show}
					onHide={() => setAlerModal({ ...alertModal, show: false })}
					title={alertModal.title}
					message={alertModal.message}
				/>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	return (
		<div className="recordings-section-main-container">
			{user.userType === 2 && (
				<AdminGenSelector
					setGenId={setGenId}
					genId={genId}
					load={load}
					handleCheck={handleCheck}
					gens={gens}
					eyeColor={eyeColor}
					setEyeColor={setEyeColor}
				/>
			)}
			<div className="title mb-4">
				<h1>Grabaciones</h1>
			</div>
			<div className="recordings-section-select-order-container">
				<select
					name="select-order-recording-modules"
					id="select-order-recording-modules"
					value={order}
					onChange={(e) => setOrder(e.target.value)}
				>
					<option value="modules">Módulos</option>
					<option value="asc">Más reciente</option>
				</select>
			</div>
			<div className="main-container">
				{recordings.map((module: any, index) => {
					return (
						<CardsContainer
							nextSeminaries={nextSeminaries}
							module={module}
							index={index}
							order={order}
							recordings={recordings}
							genId={genId}
							materialId={MaterialId}
							paidSeminaries={paidSeminaries}
						/>
					);
				})}
			</div>
		</div>
	);
}

export default RecordingsSection;
