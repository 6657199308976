import React, { useState, useEffect } from "react";

const Countdown = (props: { date: Date }): JSX.Element => {
	const date = props.date;
	const [countdown, setCountdown] = useState(calculateCountdown(date));

	useEffect(() => {
		setCountdown(calculateCountdown(date));
	}, [date]);

	return (
		<div>
			Falta {countdown.days} d, {countdown.hours} h, {countdown.minutes} m, {countdown.seconds} s
		</div>
	);
};

const calculateCountdown = (date) => {
	const targetDate = new Date(date).getTime();
	const now = new Date().getTime();
	const difference = targetDate - now;

	if (difference < 0) {
		return { days: 0, hours: 0, minutes: 0, seconds: 0 };
	}

	const days = Math.floor(difference / (1000 * 60 * 60 * 24));
	const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((difference % (1000 * 60)) / 1000);

	return { days, hours, minutes, seconds };
};

export default Countdown;
