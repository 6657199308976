import { Modal } from "react-bootstrap";

export function NotesModal(props) {

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="bookmark-word-note-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Notas
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2>
                    {props.notes !== "" ? props.notes : "No hay notas para esta términos."}
                </h2>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <button className="action-btn linear-bg" onClick={props.onHide}>Cerrar</button>
            </Modal.Footer>
        </Modal>
    );
}