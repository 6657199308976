import { Spinner20vh } from '@components/Spinner/SpinnerMyAccountView';
import { doc, getDoc } from 'firebase/firestore';
import { db } from 'initFirebase';
import { userViews } from 'interfaces/Interfaces';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

interface Students {
    name: string;
    id: string;
    views: number;
}

function UsersViewsModal(props) {
    const usersIds: Array<userViews> = props.usersIds;
    const [students, setStudents] = useState<Array<Students>>(new Array())
    const [loading, setLoading] = useState<boolean>(true);

    const getUsers = async () => {
        setLoading(true);
        const tempStudents = new Array();
        if (usersIds !== undefined) {
            await Promise.all(usersIds.map(async (userView, index) => {
                if (typeof userView === "string") {
                    usersIds[index] = { userId: userView, views: 1 }
                }
                const userRef = doc(db, "users", usersIds[index].userId);
                const snapUser = await getDoc(userRef);

                if (snapUser.exists()) {
                    const student = {
                        name: snapUser.data().name,
                        id: snapUser.id,
                        views: usersIds[index].views
                    };

                    tempStudents.push(student);
                }
            }));
        }

        setStudents(tempStudents);
        setLoading(false);

    }


    useEffect(() => {
        if (props.show) {
            getUsers();
        }
    }, [props.show]);

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Visto por los estudiantes:
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && (
                    <Spinner20vh />
                )}

                {!loading && (students.map((student, index) => {
                    return (
                        <p key={"student-name-" + index}>
                            {student.name} - {student.views}
                        </p>
                    );
                }))}
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <button
                    onClick={props.onHide}
                    className='action-btn linear-bg'
                >
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default UsersViewsModal;