import { useAuth } from "@hooks/useAuth";
import { getAuth } from "firebase/auth";
import CheckoutContainer from "@containers/CheckoutContainer/CheckoutContainer";

function CheckoutScreen() {

	let user = useAuth().user;

	let userId;
	if (user !== null) {
		userId = getAuth().currentUser!.uid;
		user = { ...user, id: getAuth().currentUser!.uid };
	}

	return (
		<div style={{ overflow: "hidden" }}>
			<CheckoutContainer />
		</div>
	);
}

export default CheckoutScreen;
