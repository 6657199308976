import {
	BrazilFlagPng,
	ChinaFlagPng,
	DownArrow,
	DropdownArrow,
	FranceFlagPng,
	GermanyFlagPng,
	ItalyFlagPng,
	JapanFlagPng,
	RussiaFlagPng,
	SendIcon,
	UnitedStatesFlagPng,
} from "assets/Imports";
import { user } from "firebase-functions/lib/providers/auth";
import { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "./styles.scss";

interface Options {
	langDirection?;
	setConsultPost?;
	setSend?;
	setConsultLangs?;
	consultPost?;
	consultLangs?;
	setHashtags?;
	hashtags?;
	id?;
	type?;
	popularHashtags?;
	seminarios?;
	seminaryNum?;
	SeminaryPosting?;
	setSeminaryNumCheck?;
	seminaryNumCheck?;
	user?;
	postIn?;
	setPostIn?;
	name?;
}

function TextEditor(props: Options) {
	const [selectLangOpen, setSelectLangOpen] = useState<boolean>(false);
	const [value, setValue] = useState<string>(props.consultPost);
	const [french, setFrench] = useState(props.consultLangs.french);
	const [german, setGerman] = useState(props.consultLangs.german);
	const [japanese, setJapanese] = useState(props.consultLangs.japanese);
	const [italian, setItalian] = useState(props.consultLangs.italian);
	const [russian, setRussian] = useState(props.consultLangs.russian);
	const [chinese, setChinese] = useState(props.consultLangs.chinese);
	const [portuguese, setPortuguese] = useState(props.consultLangs.portuguese);
	const [english, setEnglish] = useState(props.consultLangs.english);
	const [hashtagInput, setHashtagInput] = useState<string>("");
	const [showSeminaries, setShowSeminaries] = useState<boolean>(false);
	const [width, setWidth] = useState(0);

	useEffect(() => {
		if (props.consultPost !== "") {
			setValue(props.consultPost);
		}
	}, [props.consultPost]);

	var Font = Quill.import("formats/font");
	Font.whitelist = ["cirilico"];
	Quill.register(Font, true);
	var Block = Quill.import("blots/block");
	Block.tagName = "p";
	Block.className = "pre";
	Quill.register(Block, true);

	var toolbarOptions = [
		[{ size: ["small", false, "large", "huge"] }], // custom dropdown
		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		["bold", "italic", "underline", "strike"], // toggled buttons

		[{ list: "ordered" }, { list: "bullet" }],
		[{ script: "sub" }, { script: "super" }], // superscript/subscript
		["link"],

		[{ font: Font.whitelist }],
		["clean"], // remove formatting button
	];
	var toolbarOptions2 = [
		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		["bold", "italic", "underline", "strike"], // toggled buttons
	];

	const send = () => {
		props.setConsultPost(value);
		props.setConsultLangs({
			french: french,
			german: german,
			italian: italian,
			japanese: japanese,
			russian: russian,
			chinese: chinese,
			english: english,
			portuguese: portuguese,
		});
		props.setSend(true);
	};

	const createHashtag = (e) => {
		let word = hashtagInput;
		e.preventDefault();
		let newArray = new Array();
		props.hashtags.forEach((word) => {
			newArray.push(word);
		});
		if (word.trim() !== "") {
			while (word.charAt(0) === "#") {
				word = word.substring(1);
			}
			let add: boolean = false;
			let arrayWord = "";
			props.popularHashtags.forEach((wordA: string) => {
				if (
					wordA
						.toLocaleLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "") ===
					word
						.toLocaleLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
				) {
					add = true;
					arrayWord = wordA;
				}
			});
			if (add) {
				newArray.push(arrayWord);
			} else {
				newArray.push(word);
			}
			props.setHashtags(newArray);
			setHashtagInput("");
		}
	};

	const deleteHashtag = (index) => {
		let deleteArray = new Array();
		props.hashtags.forEach((word) => {
			deleteArray.push(word);
		});
		if (index > -1) {
			deleteArray.splice(index, 1); // 2nd parameter means remove one item only
		}
		props.setHashtags(deleteArray);
	};

	const addHashtag = (hashtag) => {
		let array = new Array();

		props.hashtags.forEach((word) => {
			array.push(word);
		});
		if (array.indexOf(hashtag) !== -1) {
			const index = array.indexOf(hashtag);
			array.splice(index, 1);
			props.setHashtags(array);
		} else {
			array.push(hashtag);
			props.setHashtags(array);
		}
	};

	useEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [setWidth]);
	return (
		<>
			{selectLangOpen && (
				<div
					style={{ height: "100vh", width: "100%", position: "fixed", top: 77, left: 300, zIndex: 1019 }}
					onClick={() => setSelectLangOpen(false)}
				></div>
			)}
			<div className="consult-text-editor-container">
				<div
					className={
						"dropdown-lang-checkbox-container " +
						(selectLangOpen ? "dropdown-lang-checkbox-container-open " : "") +
						(props.langDirection === "down" && "down")
					}
					onClick={() => false}
				>
					<div className="checkbox-row">
						<input
							name="french"
							id={"french" + props.id}
							checked={french}
							onChange={(e) => setFrench(e.target.checked)}
							type="checkbox"
						/>
						<label htmlFor={"french" + props.id}>Francés</label>
						<img style={{ width: "24px", height: "24px", marginLeft: "auto" }} src={FranceFlagPng} alt="bandera de francia" />
					</div>
					<div className="checkbox-row">
						<input
							name="german"
							id={"german" + props.id}
							checked={german}
							onChange={(e) => setGerman(e.target.checked)}
							type="checkbox"
						/>
						<label htmlFor={"german" + props.id}>Alemán</label>
						<img style={{ width: "24px", height: "24px", marginLeft: "auto" }} src={GermanyFlagPng} alt="bandera de alemania" />
					</div>
					<div className="checkbox-row">
						<input
							name="japanese"
							id={"japanese" + props.id}
							checked={japanese}
							onChange={(e) => setJapanese(e.target.checked)}
							type="checkbox"
						/>
						<label htmlFor={"japanese" + props.id}>Japonés</label>
						<img style={{ width: "24px", height: "24px", marginLeft: "auto" }} src={JapanFlagPng} alt="bandera de japon" />
					</div>
					<div className="checkbox-row">
						<input
							name="italian"
							id={"italian" + props.id}
							checked={italian}
							onChange={(e) => setItalian(e.target.checked)}
							type="checkbox"
						/>
						<label htmlFor={"italian" + props.id}>Italiano</label>
						<img style={{ width: "24px", height: "24px", marginLeft: "auto" }} src={ItalyFlagPng} alt="bandera de italia" />
					</div>
					<div className="checkbox-row">
						<input
							name="russian"
							id={"russian" + props.id}
							checked={russian}
							onChange={(e) => setRussian(e.target.checked)}
							type="checkbox"
						/>
						<label htmlFor={"russian" + props.id}>Ruso</label>
						<img style={{ width: "24px", height: "24px", marginLeft: "auto" }} src={RussiaFlagPng} alt="bandera de rusia" />
					</div>
					<div className="checkbox-row">
						<input
							name="chinese"
							id={"chinese" + props.id}
							checked={chinese}
							onChange={(e) => setChinese(e.target.checked)}
							type="checkbox"
						/>
						<label htmlFor={"chinese" + props.id}>Chino</label>
						<img style={{ width: "24px", height: "24px", marginLeft: "auto" }} src={ChinaFlagPng} alt="bandera de china" />
					</div>
					<div className="checkbox-row">
						<input
							name="portuguese"
							id={"portuguese" + props.id}
							checked={portuguese}
							onChange={(e) => setPortuguese(e.target.checked)}
							type="checkbox"
						/>
						<label htmlFor={"portuguese" + props.id}>Portugués</label>
						<img style={{ width: "24px", height: "24px", marginLeft: "auto" }} src={BrazilFlagPng} alt="bandera de portugal" />
					</div>
					<div className="checkbox-row">
						<input
							name="english"
							id={"english" + props.id}
							checked={english}
							onChange={(e) => setEnglish(e.target.checked)}
							type="checkbox"
						/>
						<label htmlFor={"english" + props.id}>Inglés</label>
						<img
							style={{ width: "24px", height: "24px", marginLeft: "auto" }}
							src={UnitedStatesFlagPng}
							alt="bandera de estados unidos"
						/>
					</div>
				</div>
				{props.type !== "answer" && (
					<div
						className={"dropdown-lang-select " + (selectLangOpen ? "dropdown-lang-select-open" : "")}
						onClick={() => setSelectLangOpen(!selectLangOpen)}
					>
						<p>Seleccionar idioma</p>
						<img src={DropdownArrow} alt="flecha de dropdown" />
					</div>
				)}
				<button onClick={send} className="consult-send-btn">
					Enviar <img src={SendIcon} alt="enviar icono" />
				</button>
				{width > 500 || props.type !== "answer" ? (
					<ReactQuill modules={{ toolbar: toolbarOptions }} theme="snow" value={value} onChange={setValue} />
				) : (
					<div className="answers-editor">
						<ReactQuill modules={{ toolbar: toolbarOptions2 }} theme="snow" value={value} onChange={setValue} />
					</div>
				)}
			</div>
			{(props.type !== "answer" || (props.user !== undefined && props.user.userType === 2)) && (
				<div style={{ border: "1px solid #00000033", borderTop: 0, borderRadius: "0px 0px 10px 10px", padding: 20 }}>
					<>
						{props.type !== "answer" && (
							<>
								{props.popularHashtags !== undefined && (
									<div className="hashtags-container-selection">
										<div className="header">
											<h2>Etiquetas más usadas</h2>
										</div>
										{props.popularHashtags.map((hashtag, index) => {
											return (
												<div
													className={"hashtag" + (props.hashtags.indexOf(hashtag) !== -1 ? " active" : "")}
													onClick={() => addHashtag(hashtag)}
													key={"popularHashtag-" + index}
												>
													#{hashtag}
												</div>
											);
										})}
									</div>
								)}
								<div className="hashtag-creator-container">
									<form onSubmit={createHashtag} style={{ display: "flex", gap: 10, height: 34 }}>
										<div className="hashtag-input-container">
											<input
												type="text"
												value={hashtagInput}
												onChange={(e) => setHashtagInput(e.target.value)}
												placeholder="Añade una etiqueta"
											/>
										</div>
										<div className="hashtag-btn-create">
											<button type="submit">Agregar</button>
										</div>
									</form>
									<div className="hashtags-container">
										{props.hashtags !== undefined && (
											<>
												{props.hashtags.map((hashtag, index) => {
													return (
														<div className="hashtag" key={"hashtag-" + index} onClick={() => deleteHashtag(index)}>
															<span className="hashtag-text">#{hashtag}</span>
															<span className="delete-hashtag">x</span>
														</div>
													);
												})}
											</>
										)}
									</div>
								</div>
							</>
						)}
					</>
					{props.postIn !== undefined && props.user.userType === 2 && (
						<>
							<div className="header-seminaries-checkboxes">
								<h2>Seleccionar seminarios donde se publicará:</h2>
							</div>
							<div className={"seminaries-selection-container"} onClick={() => setShowSeminaries(!showSeminaries)}>
								<p>Seleccionar seminarios</p>
								<img className={"icon1" + (showSeminaries ? " show" : "")} src={DownArrow} alt="down-arrow-icon" />
							</div>
							<div
								className={"seminary-checkboxes-main-container" + (showSeminaries ? " show" : "")}
								style={{ zIndex: 1020, width: "calc(100% - 40px)" }}
							>
								<div className="seminary-checkboxs-container">
									{props.seminarios.map((seminary) => {
										return (
											<>
												{seminary !== props.seminaryNum ? (
													<div className="item-seminary">
														<input
															id={"seminaryrevision-" + props.name + seminary}
															type="checkbox"
															onChange={(e) => props.SeminaryPosting(e.target.checked, seminary)}
														/>
														<label htmlFor={"seminaryrevision-" + props.name + seminary}>{seminary}</label>
													</div>
												) : (
													<div className="item-seminary">
														<input
															id={"seminary-" + props.name + seminary}
															type="checkbox"
															checked={props.seminaryNumCheck}
															onChange={(e) => props.SeminaryPosting(e.target.checked, seminary)}
														/>
														<label htmlFor={"seminary-" + props.name + seminary}>{seminary}</label>
													</div>
												)}
											</>
										);
									})}
								</div>
							</div>
						</>
					)}
				</div>
			)}
			{showSeminaries && (
				<div
					style={{ height: "100vh", width: "100%", position: "fixed", top: 77, left: 300, zIndex: 1019 }}
					onClick={() => setShowSeminaries(false)}
				></div>
			)}
		</>
	);
}

export default TextEditor;
