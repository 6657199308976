import { GenerateReceiptModal } from "@components/GenerateReceiptModal/GenerateReceiptModal";
import { Cardicon, CashIcon, OxxoIcon, PaypalLogo, ReceiptIcon, SPEIIcon, WalletIcon } from "assets/Imports";
import { useEffect, useState } from "react";
import "./styles.css";

function AdminPaymentHistoryCard({ data, index }) {
	const date = new Date(data.date);
	const [check, setCheck] = useState(false);
	const [receiptModal, setReceiptModal] = useState<boolean>(false);

	function getDate(materialDate) {
		let stringSplit = materialDate.split("/");
		let month = "";
		switch (parseInt(stringSplit[1])) {
			case 0:
				month = "enero";
				break;
			case 1:
				month = "febrero";
				break;
			case 2:
				month = "marzo";
				break;
			case 3:
				month = "abril";
				break;
			case 4:
				month = "mayo";
				break;
			case 5:
				month = "junio";
				break;
			case 6:
				month = "julio";
				break;
			case 7:
				month = "agosto";
				break;
			case 8:
				month = "septiembre";
				break;
			case 9:
				month = "octubre";
				break;
			case 10:
				month = "noviembre";
				break;
			case 11:
				month = "diciembre";
				break;
		}

		let date = stringSplit[0] + " de " + month + " de " + stringSplit[2];
		return date;
	}

	const seeReceipt = () => {
		window.open(data.image, "_blank");
	};



	return (
		<div className="payment-history-card-container" style={{ flexDirection: "column" }}>
            {check === true && (
                <GenerateReceiptModal payment={data} show={receiptModal} onHide={() => setReceiptModal(false)} />
            )}
			<div className="payment-history-card-container-box">
				<div className="payment-history-right-content-card-container">
					<div className="icon-payment-history-container">
						{data.medio === "OXXO Pay" && <img src={OxxoIcon} />}
						{data.medio === "Efectivo" && <img src={CashIcon} />}
						{data.medio === "Otro" && <img src={ReceiptIcon} />}
						{data.medio === "Tarjeta" && <img src={Cardicon} />}
						{data.medio === "SPEI" && <img src={SPEIIcon} />}
						{data.medio === "PayPal" && <img src={PaypalLogo} alt="paypal-loco-icon" />}
						{(data.medio === "Transferencia/Deposito bancario" || 
							data.medio === "Transferencia/Depósito bancario" ||
								data.medio === "Transferencia" ||
									data.medio === "Depósito bancario" ) && 
										(<img src={WalletIcon} alt="wallet-icon" />
						)}
					</div>
					<div className="text-payment-history-container">
						{check === true ? (
							<p>
								<a className="title-red">
									$
									{parseInt(data.amount)
										.toFixed(2)
										.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
									MXN
								</a>
							</p>
						) : (
							<p style={{ fontWeight: 500, color: "#000000" }}>
								$
								{parseInt(data.amount)
									.toFixed(2)
									.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
								MXN
							</p>
						)}
						<p className="realizacion-date">
							Realización: {getDate(date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear())}
						</p>
						<p>{data.email}</p>
					</div>
				</div>
				{check === false ? (
					<div className="payment-history-left-content-card-container">
						<button
							className="tertiary"
							style={{ height: 45, padding: "10px 15px", minWidth: 90 }}
							onClick={() => setCheck(!check)}
						>
							Revisar
						</button>
					</div>
				) : (
					<div className="payment-history-left-content-card-container" style={{ alignSelf: "flex-start" }}>
						<button className="close-btn-history-card" onClick={() => setCheck(!check)}>
							x
						</button>
						<p>{data.name}</p>
					</div>
				)}
			</div>
			{check === true ? (
				<>
					<div style={{ width: "100%" }}>
						<div style={{ marginLeft: 65 }}>
							<p style={{ margin: 0, marginTop: 10 }}>Concepto:</p>
							<p>{data.concept}</p>
						</div>
					</div>
					<div className="buttons-container-request-card">
						{data.image !== "" && <button onClick={seeReceipt}>Ver comprobante</button>}
						<button onClick={() => setReceiptModal(true)}>Ver recibo</button>
					</div>
				</>
			) : (
				<></>
			)}
		</div>
	);
}

export default AdminPaymentHistoryCard;
