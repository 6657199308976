import { useEffect, useState } from "react";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from "initFirebase";

export const useUpdateCollaborator = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const updateCollaborator = async (collaboratorId, updatedData, newSections) => {
		setLoading(true);
		setError(null);
		try {
			// Referencia al colaborador
			const collaboratorRef = doc(db, "users", collaboratorId);
			// Actualizar el campo "permissions" del colaborador
			await updateDoc(collaboratorRef, updatedData);

			setLoading(false);
		} catch (error: any) {
			setError(error.message);
			setLoading(false);
		}
	};

	return { loading, error, updateCollaborator };
};
