import { ChangeEvent, useEffect, useState } from "react";
import { ModalitiesPrices, Currency, PricesModality, PricesType, Prices } from "../PricesTypes";
import { fetchPricesByCurrencyAndModality, updatePrices } from "../PricesService";

const initialPrices: ModalitiesPrices = {
	online: {
		regularClass: 0,
		promoClass: 0,
	},
	"in-person": {
		regularClass: 0,
		promoClass: 0,
	},
};

// El currency que se obtiene en props es algo como prices-MXN esto para acomodar el tipo de uso al componente de textos dinamicos
const PricesEdit = (props: { currency: string }) => {
	const [prices, setPrices] = useState<ModalitiesPrices>(initialPrices);
	const [updating, setUpdating] = useState(false);

	const currency: Currency | null = props.currency.includes("MXN")
		? Currency.Mxn
		: props.currency.includes("USD")
		? Currency.Usd
		: null;

	const getCurrencyPrices = async () => {
		if (currency != null) {
			const inPersonResponse = await fetchPricesByCurrencyAndModality(currency, PricesModality.InPerson);
			const onlineResponse = await fetchPricesByCurrencyAndModality(currency, PricesModality.Online);
			if (inPersonResponse && onlineResponse) {
				setPrices({ "in-person": inPersonResponse, online: onlineResponse });
			}
		}
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const [modality, priceType] = name.split("_");
		if (prices != undefined) {
			setPrices((prevPrices) => ({
				...prevPrices,
				[modality]: {
					...prevPrices[modality],
					[priceType]: Number(value),
				},
			}));
		}
	};

	const handleSave = async () => {
		setUpdating(true);
		if (currency != null) {
			await updatePrices(currency, prices);
		}
		setUpdating(false);
	};

	useEffect(() => {
		getCurrencyPrices();
	}, [currency]);

	return (
		<div>
			<p>En línea:</p>

			<label htmlFor="online_regularClass">Precio regular por seminario:</label>
			<div className="editDynamicTextInputContainer">
				<input
					type="number"
					value={prices?.online.regularClass ?? "Cargando..."}
					name="online_regularClass"
					id="online_regularClass"
					onChange={handleInputChange}
				/>
			</div>

			<label htmlFor="online_promoClass">Precio de promoción por seminario:</label>
			<div className="editDynamicTextInputContainer">
				<input
					type="number"
					value={prices?.online.promoClass}
					name="online_promoClass"
					id="online_promoClass"
					onChange={handleInputChange}
				/>
			</div>

			<p>Presencial:</p>

			<label htmlFor="in-person_regularClass">Precio regular por seminario:</label>
			<div className="editDynamicTextInputContainer">
				<input
					type="number"
					value={prices?.["in-person"].regularClass}
					name="in-person_regularClass"
					id="in-person_regularClass"
					onChange={handleInputChange}
				/>
			</div>

			<label htmlFor="in-person_promoClass">Precio de promoción por seminario:</label>
			<div className="editDynamicTextInputContainer">
				<input
					type="number"
					value={prices?.["in-person"].promoClass}
					name="in-person_promoClass"
					id="in-person_promoClass"
					onChange={handleInputChange}
				/>
			</div>

			<div className="mt-3">
				<button className="btn-save-dynamic-copies" onClick={handleSave} disabled={updating}>
					Guardar
				</button>
			</div>
		</div>
	);
};

export default PricesEdit;
