import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

function FaqsButton() {
  return (
    <div>
      <Link to={`/dudas`}>
        <div className="faqs__button">
          <button className="question__icon">
            <span className="text">?</span>
          </button>
        </div>
      </Link>
    </div>
  );
}

export default FaqsButton;
