import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where,
} from "firebase/firestore";
import "./styles.scss";
import { isTypeOnlyImportOrExportDeclaration } from "typescript";
import { db, functions } from "../../initFirebase";
import { Link, useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import { useAuth } from "@hooks/useAuth";
import "react-phone-number-input/style.css";
import PhoneInput, {
	isPossiblePhoneNumber,
	isValidPhoneNumber,
} from "react-phone-number-input/mobile";
import es from "react-phone-number-input/locale/es.json";
import emailjs from "emailjs-com";
import { BigBen, Rectangle15, Students2, Vector } from "assets/Imports";
import { randDarkColor } from "utils/GeneralFunctions";
import { FirebaseError } from "firebase/app";
import { HttpsCallableResult, httpsCallable } from "firebase/functions";
import { ConektaCustomerData, ConektaResponse } from "interfaces/Conekta/Conekta";
import { StripeResponse } from "interfaces/Stripe/Stripe";

type PaymentCustomerData = { name: string; email: string; phone: string };

function SignUpContent() {
	const [validated, setValidated] = useState(false);
	const [loadingRegister, setLoadingRegister] = useState(false);
	const history = useHistory();
	const { updateUser } = useAuth();
	const [stripeData, setStripeData] = useState<StripeResponse>();
	const handleSubmit = async (e) => {
		setLoadingRegister(true);
		const form = e.currentTarget;
		e.preventDefault();

		let format = /[0-9`!@#$%^&*()_+\-\[\]={};':"\\|,\.<>\/?~]/;

		if (!form.checkValidity()) {
			e.stopPropagation();
			setValidated(true);
			setLoadingRegister(false);
			return;
		}

		if (form.email.value.trim() !== form.email2.value.trim()) {
			alert("Los correos no coinciden.");
			setLoadingRegister(false);
			return;
		}

		if (form.name.value.match(format)) {
			alert(
				"El nombre que ingresaste no es válido. \n\nPor favor, asegúrate de que no contenga números o alguno de los siguientes caracteres: ! # $ % & ( ) * + , - . / : ; < = > ? @ [  ] ^ ` { | } ~ "
			);
			setLoadingRegister(false);
			return;
		}

		if (form.password.value !== form.password2.value) {
			alert("Las contraseñas no coinciden.");
			setLoadingRegister(false);
			return;
		}

		if (!isPossiblePhoneNumber(form.phone.value)) {
			alert("El número de celular es inválido.");
			setLoadingRegister(false);
			return;
		}

		try {
			const auth = getAuth();
			const userCredential = await createUserWithEmailAndPassword(
				auth,
				form.email.value.trim().toLowerCase(),
				form.password.value
			);

			const user = userCredential.user;

			const userData = {
				name: form.name.value,
				email: form.email.value.trim().toLowerCase(),
				phone: form.phone.value,
			};

			const getStripeCustomer = httpsCallable<PaymentCustomerData, StripeResponse>(
				functions,
				"getStripeCustomer"
			);

			const conektaRegister = httpsCallable<PaymentCustomerData, ConektaCustomerData>(
				functions,
				"createCustomerConekta"
			);

			const stripeCustomerResponse: HttpsCallableResult<StripeResponse> = await getStripeCustomer(
				userData.email
			);

			const conektaResponse: HttpsCallableResult<ConektaCustomerData> = await conektaRegister(
				userData
			);

			if (!stripeCustomerResponse.data) {
				const createStripeCustomer = httpsCallable<PaymentCustomerData, StripeResponse>(
					functions,
					"createStripeCustomer"
				);
				const stripeResponse: HttpsCallableResult<StripeResponse> = await createStripeCustomer(
					userData
				);
				setStripeData(stripeResponse.data);
			}

			const conektaData = conektaResponse.data;

			const userRef = doc(db, "users", user.uid);
			const q = query(
				collection(db, "users"),
				where("email", "==", conektaData.email.trim().toLowerCase())
			);
			const snap = await getDocs(q);

			if (snap.size === 1) {
				const existingUser = snap.docs[0];
				await setDoc(userRef, {
					name: conektaData.name,
					email: conektaData.email.trim().toLowerCase(),
					phone: conektaData.phone,
					conektaId: conektaData.id,
					stripeId: stripeData?.id
						? stripeData?.id
						: stripeCustomerResponse?.data[0]?.id
						? stripeCustomerResponse?.data[0]?.id
						: "",
					generationId: existingUser.data().generationId || "",
					matricula: existingUser.data().matricula || "",
					schedule: existingUser.data().schedule || "",
					promotion: false,
					formAnswered: false,
					color: randDarkColor(),
				});

				const payments = query(
					collection(db, "paymentHistory"),
					where("userId", "==", existingUser.id)
				);
				const phSnap = await getDocs(payments);

				phSnap.docs.forEach(async (payment) => {
					const payRef = doc(db, "paymentHistory", payment.id);
					await updateDoc(payRef, { userId: user.uid });
				});

				const preUserRef = doc(db, "users", existingUser.id);
				await deleteDoc(preUserRef);
			} else {
				await setDoc(userRef, {
					name: conektaData.name,
					email: conektaData.email.trim().toLowerCase(),
					phone: conektaData.phone,
					conektaId: conektaData.id,
					stripeId: stripeData?.id
						? stripeData?.id
						: stripeCustomerResponse?.data[0]?.id
						? stripeCustomerResponse?.data[0]?.id
						: "",
					generationId: "",
					matricula: "",
					schedule: "",
					promotion: false,
					formAnswered: false,
					color: randDarkColor(),
				});
			}

			const emailData = {
				name: conektaData.name,
				email: conektaData.email,
			};

			await emailjs.send(
				"service_kznboar",
				"template_r303fk1",
				emailData,
				"user_PPI0O8nofs9cbuJ3JRWlT"
			);

			await updateUser(user.uid);
			setLoadingRegister(false);
		} catch (error) {
			if (error instanceof FirebaseError) {
				const errorCode = error.code;
				const errorMessage = error.message;
				switch (errorCode) {
					case "auth/invalid-email":
						alert("El correo ingresado es inválido.");
						break;
					case "auth/email-already-in-use":
						alert("El correo ya se encuentra registrado");
						break;
					case "auth/weak-password":
						alert("La contraseña debe de contener un mínimo de 6 caracteres.");
						break;
					case "auth/wrong-password":
						alert("La contraseña ingresada es incorrecta.");
						break;
					default:
						alert(`Error: ${errorMessage}`);
				}
				setLoadingRegister(false);
			} else {
				console.error("Unexpected error:", error);
				setLoadingRegister(false);
			}
		}
	};

	return (
		<div className="signup-main-container">
			<div className="signup-header-container">
				<h2 style={{ position: "absolute" }}>Registrarse</h2>
				<img src={Rectangle15} alt="Logo" className="wave" style={{ position: "static" }} />
				<img
					src={Vector}
					alt="Logo"
					className="wave wave-2-signup"
					style={{ position: "static", marginTop: -10 }}
				/>
			</div>
			<div className="signup-content-container">
				<div className="signup-students-image-container">
					<img src={BigBen} alt="Torre eiffel" className="bigben-image" />
					<img src={Students2} alt="Students" />
				</div>
				<div className="signup-form-container">
					<Form noValidate validated={validated} onSubmit={handleSubmit} method="POST">
						<Row className="input-row" style={{ margin: "auto", marginBottom: 0, maxWidth: 630 }}>
							<Col className="w-100 p-0">
								<Form.Group className="" controlId="formBasicName">
									<Form.Label>
										<b style={{ fontSize: 20 }}>Nombre</b>
									</Form.Label>
									<Form.Control required type="text" name="name" placeholder="Juan Vélez" />
									<Form.Control.Feedback type="invalid">
										Por favor, ingresa un nombre.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row className="input-row" style={{ margin: "auto", marginBottom: 0, maxWidth: 630 }}>
							<Col className="w-100 p-0">
								<Form.Group className="" controlId="formBasicPhone">
									<Form.Label>
										<b style={{ fontSize: 20 }}>Celular</b>
									</Form.Label>
									<PhoneInput
										placeholder="Ingresa un número de celular."
										labels={es}
										defaultCountry="MX"
										international={true}
										name="phone"
										id="phone"
										onChange={() => {}}
									/>
									<Form.Control.Feedback type="invalid">
										Por favor, ingresa un numero de telefono.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row className="input-row" style={{ margin: "auto", marginBottom: 0, maxWidth: 630 }}>
							<Col className="w-100 p-0">
								<Form.Group className="" controlId="formBasicEmail">
									<Form.Label>
										<b style={{ fontSize: 20 }}>Correo electrónico</b>
									</Form.Label>
									<Form.Control
										required
										type="text"
										name="email"
										placeholder="ejemplo@ejemplo.com"
									/>
									<Form.Control.Feedback type="invalid">
										Por favor, ingresa un correo válido.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row className="input-row" style={{ margin: "auto", marginBottom: 0, maxWidth: 630 }}>
							<Col className="w-100 p-0">
								<Form.Group className="" controlId="formBasicEmail2">
									<Form.Label>
										<b style={{ fontSize: 20 }}>Confirmar correo electrónico</b>
									</Form.Label>
									<Form.Control
										required
										type="text"
										name="email2"
										placeholder="ejemplo@ejemplo.com"
									/>
									<Form.Control.Feedback type="invalid">
										Por favor, ingresa el mismo correo.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row className="input-row" style={{ margin: "auto", marginBottom: 0, maxWidth: 630 }}>
							<Col className="w-100 p-0">
								<Form.Group className="" controlId="formBasicPassword">
									<Form.Label>
										<b style={{ fontSize: 20 }}>Contraseña</b>
									</Form.Label>
									<Form.Control required type="password" name="password" placeholder="Contraseña" />
									<Form.Control.Feedback type="invalid">
										Por favor, ingresa una contraseña
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row className="input-row" style={{ margin: "auto", marginBottom: 0, maxWidth: 630 }}>
							<Col className="w-100 p-0">
								<Form.Group className="" controlId="formBasicPassword2">
									<Form.Label>
										<b style={{ fontSize: 20 }}>Confirmar contraseña</b>
									</Form.Label>
									<Form.Control
										required
										type="password"
										name="password2"
										placeholder="Contraseña"
									/>
									<Form.Control.Feedback type="invalid">
										Por favor, ingresa una contraseña
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<Row
							className="input-row"
							style={{ marginBottom: 0, justifyContent: "center", minHeight: 71 }}
						>
							{!loadingRegister ? (
								<button className="tertiary return-btn-style signup-api-btn">Registrarse</button>
							) : (
								<div
									className="spinner-border text-danger"
									role="status"
									style={{ marginBottom: "1rem" }}
								>
									<span className="sr-only">Loading...</span>
								</div>
							)}
						</Row>
						<Row className="input-row" style={{ marginBottom: 0, justifyContent: "center" }}>
							<p style={{ fontSize: "1rem" }}>
								¿Ya tienes una cuenta? <Link to="/ingresar">Accede aquí</Link>
							</p>
						</Row>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default SignUpContent;
