import CheckoutForm from "./PaymentForm/CheckoutForm";
import { useHistory, useLocation } from "react-router-dom";
import "./styles.scss";
import { useEffect } from "react";
import StripeProviderWrapper from "./StripeCheckoutModal/StripeCheckoutModal";
function CheckoutContainer() {
	const location = useLocation<any>();
	const history = useHistory();

	useEffect(() => {
		if (!location.state) {
			history.push("/");
		}
	}, []);

	const client = location.state?.client;
	const course = location.state?.course;

	const onlineInvestment = {
		Promotion: "$12,000 MXN",
		Standar: "$3,000 MXN",
	};

	const msi = {
		msi: 0,
	};

	if (!location.state) {
		return <></>;
	}

	if (course.inversion === onlineInvestment.Promotion) {
		msi.msi = 6;
	} else {
		msi.msi = 3;
	}

	return (
		<div className="header__checkout checkout-container-component">
			<StripeProviderWrapper>
				<CheckoutForm {...client} {...course} {...msi} />
			</StripeProviderWrapper>
		</div>
	);
}

export default CheckoutContainer;
