import { Col, Container, Image, Row } from "react-bootstrap";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import { db } from "initFirebase";
import KidsCounterContainer from "./KidsCounterContainer/KidsCounterContainer";
import { Kids } from "assets/Imports";
import { useCurrencyContext } from "contexts/CurrencyContext";

interface KidsSecondSectionProps {
	data: any;
}

function KidsSecondSection(props: KidsSecondSectionProps) {
	const kidsSecondInfo = props.data;
	const [idiomsInfo, setIdiomsInfo] = useState<any>({});
	const [extraInfo, setExtraInfo] = useState<any>({});
	const { currencyCountry,  prices } = useCurrencyContext();

	const getIdiomsSectionInfo = async () => {
		db.collection("dynamic_copies")
			.doc("VD4rog46SD2Z3xgBEVRE")
			.collection("kids")
			.doc("4dDygMVEMj7p61GTNSlH")
			.get()
			.then((doc) => {
				setIdiomsInfo({
					...doc.data(),
					id: doc.id,
				});
			});
	};

	const getExtraInfo = async () => {
		db.collection("dynamic_copies")
			.doc("PZawOf0iPkuFe5Ldx8DU")
			.collection("segunda")
			.doc("NaNwKyozX0ljfDnuNsil")
			.collection("extraInfo")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setExtraInfo({
						...doc.data(),
						id: doc.id,
					});
				});
			});
	};

	useEffect(() => {
		getIdiomsSectionInfo();
		getExtraInfo();
	}, []);

	return (
		<div className="kidssecond__section">
			<Container>
				<Row>
					<Col xs={{ span: 12, order: 2 }} xl={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }}>
						<p>{kidsSecondInfo.paragraph}</p>
						<p>{kidsSecondInfo.long_paragraph}</p>
						<div className="embed-responsive embed-responsive-16by9">
							<iframe
								src={kidsSecondInfo.video_link}
								frameBorder="0"
								allow="autoplay; encrypted-media"
								allowFullScreen
								title="video"
								className="embed-responsive-item"
							/>
						</div>
						<p className="text-center">{kidsSecondInfo.video_copie}</p>
						<div className="small-section">
							<h2>{idiomsInfo.titulo ? idiomsInfo.titulo : "¡Aprende 8 idiomas simultáneos!"}</h2>
							<p>
								<b>Costo por sesión: </b>
								{currencyCountry === "mx" ? idiomsInfo.costo : `$${prices?.USD.online.regularClass} USD`}
							</p>
							<p>
								<b>Duración: </b>
								{idiomsInfo.duracion}
							</p>
							<p>
								<b>Fecha de inicio: </b>
								{idiomsInfo.fecha}
							</p>
							<p>
								<b>Horario: </b>
								<ul>
									<li>{idiomsInfo.horario}</li>
									<li>{idiomsInfo.horario2}</li>
								</ul>
							</p>
							<div className="inscripcion d-lg-none">
								<p>
									<b>Para reservar un espacio: </b> {extraInfo.inscribirse}
								</p>
								<p>
									<b>WhatsApp: </b>
									<a href={extraInfo.wa_link}> {extraInfo.whatsapp} </a>
								</p>
							</div>
						</div>
					</Col>
					<Col xs={{ span: 12, order: 1 }} xl={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }} className="second-col">
						<h3>{kidsSecondInfo.right_title ? kidsSecondInfo.right_title : "¡A partir de 5 años!"}</h3>
						<Image fluid src={Kids} alt="Niños aprendiendo" className="kids-img" />
						<KidsCounterContainer fechasInfo={kidsSecondInfo} />
						<br />
						<div className="inscripcion d-none d-lg-block">
							<p>
								<b>Para reservar un espacio: </b> {extraInfo.inscribirse}
							</p>
							<p>
								<b>WhatsApp: </b>
								<a href={extraInfo.wa_link}> {extraInfo.whatsapp} </a>
							</p>
						</div>
					</Col>
					{/* <Col xs={{ span: 12, order: 2 }} xl={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }} className="d-none d-lg-block"> */}
				</Row>
			</Container>
		</div>
	);
}

export default KidsSecondSection;
