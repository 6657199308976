import { ArrowRed, BrazilFlagSVG, ChinaFlagSVG, FranceFlagSVG, GermanyFlagSVG, ItalyFlagSVG, JapanFlagSVG, RussiaFlagSVG, UnitedStatesFlagSVG } from 'assets/Imports';
import parse from 'html-react-parser';
import { useState } from 'react';
import "./TemaryCardStyles.scss";

function TemaryCard(props) {

    const [openTemary, setOpenTemary] = useState<boolean>(false);
    
    return (
        <div className={'temary-card temary-card-recordings ' + (openTemary ? "open-temary-card" : "")}>
            <div className="header" onClick={() => setOpenTemary(!openTemary)}>
                <h2>Temas abordados en esta sesión</h2>
                <img src={ArrowRed} alt="flecha icono hacia abajo" />
            </div>
            <div className={'langs-main-container justify-content-between'}>
                <div className='lang-info-container french'>
                    <img src={FranceFlagSVG} alt="france flag" />
                    <div className='lang-text-container'>
                        <p className='temary-sub' ><a>{props.seminario.lang1}</a></p>
                        <p className='lang-subtitle' >{parse(props.seminario.sub1)}</p>
                        <p className='temary-desc-style' >{parse(props.seminario.desc1)}</p>
                    </div>
                </div>
                <div className='lang-info-container german'>
                    <img src={GermanyFlagSVG} alt="germany flag" />
                    <div className='lang-text-container'>
                        <p className='temary-sub' ><a>{props.seminario.lang2}</a></p>
                        <p className='lang-subtitle' >{parse(props.seminario.sub2)}</p>
                        <p className='temary-desc-style' >{parse(props.seminario.desc2)}</p>
                    </div>
                </div>
                <div className='lang-info-container japanese'>
                    <img src={JapanFlagSVG} alt="japan flag" />
                    <div className='lang-text-container'>
                        <p className='temary-sub' ><a>{props.seminario.lang3}</a></p>
                        <p className='lang-subtitle' >{parse(props.seminario.sub3)}</p>
                        <p className='temary-desc-style' >{parse(props.seminario.desc3)}</p>
                    </div>
                </div>
                <div className='lang-info-container italian'>
                    <img src={ItalyFlagSVG} alt="italy flag" />
                    <div className='lang-text-container'>
                        <p className='temary-sub' ><a>{props.seminario.lang4}</a></p>
                        <p className='lang-subtitle' >{parse(props.seminario.sub4)}</p>
                        <p className='temary-desc-style' >{parse(props.seminario.desc4)}</p>
                    </div>
                </div>
                <div className='lang-info-container russian'>
                    <img src={RussiaFlagSVG} alt="russia flag" />
                    <div className='lang-text-container'>
                        <p className='temary-sub' ><a>{props.seminario.lang5}</a></p>
                        <p className='lang-subtitle' >{parse(props.seminario.sub5)}</p>
                        <p className='temary-desc-style' >{parse(props.seminario.desc5)}</p>
                    </div>
                </div>
                <div className='lang-info-container chinese'>
                    <img src={ChinaFlagSVG} alt="china flag" />
                    <div className='lang-text-container'>
                        <p className='temary-sub' ><a>{props.seminario.lang6}</a></p>
                        <p className='lang-subtitle' >{parse(props.seminario.sub6)}</p>
                        <p className='temary-desc-style' >{parse(props.seminario.desc6)}</p>
                    </div>
                </div>
                <div className='lang-info-container portuguese'>
                    <img src={BrazilFlagSVG} alt="portugal flag" />
                    <div className='lang-text-container'>
                        <p className='temary-sub' ><a>{props.seminario.lang7}</a></p>
                        <p className='lang-subtitle' >{parse(props.seminario.sub7)}</p>
                        <p className='temary-desc-style' >{parse(props.seminario.desc7)}</p>
                    </div>
                </div>
                {(props.seminario.lang8 !== '') && (
                    <div className='lang-info-container english'>
                        <img src={UnitedStatesFlagSVG} alt="portugal flag" />
                        <div className='lang-text-container'>
                            <p className='temary-sub' ><a>{props.seminario.lang8}</a></p>
                            <p className='lang-subtitle' >{parse(props.seminario.sub8)}</p>
                            <p className='temary-desc-style' >{parse(props.seminario.desc8)}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TemaryCard;