import PoliticasContent from "@containers/PoliticasContainer/PoliticasContent";

function PoliticasScreen() {
	return (
		<div style={{ overflow: "hidden" }}>
			<PoliticasContent />
		</div>
	);
}

export default PoliticasScreen;
