import React from "react";
import "./styles.scss";

export interface RedHeader {
  titulo: string;
}

function RedHeader(props: RedHeader) {
  return (
    <div className="header__red">
      <button className="button2 text-uppercase d-none d-sm-block">
        <span className="text">{props.titulo ? props.titulo : "PROGRAMA DE ALTO RENDIMIENTO EN 8 IDIOMAS SIMULTÁNEOS"}</span>
      </button>
    </div>
  );
}

export default RedHeader;
