import { ReactNode, SetStateAction, useEffect, useState } from "react";
import "./MainLayout.scss";
import NavbarCustom from "@components/NavbarCustom/NavbarCustom";
import Footer from "@components/Footer/Footer";
import { useLocation } from "react-router-dom";
import { useAuth } from "@hooks/useAuth";
import AsideNav from "@components/AsideNav/AsideNav";
import Navbar from "@components/Navbar/Navbar";

interface MainLayoutPropsI {
	children: ReactNode;
}

function MainLayout(props: MainLayoutPropsI) {
	const { children } = props;
	const location = useLocation();
	const { user, quizOn } = useAuth();

	useEffect(() => {
		const rootElement = document.getElementById("root");
		if (rootElement) {
			rootElement.scrollTop = 0;
		}
	}, [location.pathname]);

	// Este listener se implemento, ya que en PWA (progressive web app)
	// al momento de hacer scroll y estar al final, la asidenav en position fixed, se "salia de la pantalla"
	// y el cliente no quería que esto sucediera, y ya que era un comportamiento del cual no teniamos control, se opto por esta solución
	// useEffect(() => {
	// 	const rootElement = document.getElementById("root");
	// 	if (rootElement) {
	// 		let startY = 0; // To track the start Y position of the touch

	// 		const handleTouchStart = (e) => {
	// 			startY = e.touches[0].clientY;
	// 		};

	// 		const handleTouchMove = (e) => {
	// 			const touchY = e.touches[0].clientY;
	// 			// Check if trying to scroll up when already at the top
	// 			if (e.cancelable && rootElement.scrollTop === 0 && touchY > startY) {
	// 				e.preventDefault();
	// 			}
	// 		};

	// 		// Attach event listeners
	// 		rootElement.addEventListener("touchstart", handleTouchStart, { passive: false });
	// 		rootElement.addEventListener("touchmove", handleTouchMove, { passive: false } as EventListenerOptions);

	// 		// Cleanup function
	// 		return () => {
	// 			rootElement.removeEventListener("touchstart", handleTouchStart, { passive: false } as EventListenerOptions);
	// 			rootElement.removeEventListener("touchmove", handleTouchMove, { passive: false } as EventListenerOptions);
	// 		};
	// 	}
	// }, []);

	return (
		<div className="main-layout-main-container">
			{!quizOn && <Navbar />}
			{!quizOn && user !== null && location.pathname.indexOf("admin") === -1 && <AsideNav user={user} className="responsive" />}
			{children}
			{location.pathname.indexOf("admin") === -1 && !quizOn && <Footer />}
		</div>
	);
}

export default MainLayout;
