import { useState } from "react";
import { doc, getDoc, collection, getDocs, query, where, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";

const useCounter = () => {
	const [counters, setCounters] = useState({});

	const initCounter = async (routeId) => {
		try {
			// Obtener todos los usuarios con userType = 4
			const usersCollection = collection(db, "users");
			const userType4Query = query(usersCollection, where("userType", "==", 4));
			const userType4UsersSnapshot = await getDocs(userType4Query);

			let count = 0;

			// Contar cuántos usuarios tienen la ruta en su campo permissions
			userType4UsersSnapshot.forEach((userDoc) => {
				if (userDoc.data().permissions && userDoc.data().permissions.includes(routeId)) {
					count++;
				}
			});

			setCounters((prevCounters) => ({ ...prevCounters, [routeId]: count }));
		} catch (error) {
			console.error("Error al obtener el contador:", error);
		}
	};

	const incrementCounter = (routeId) => {
		setCounters((prevCounters) => {
			const currentCount = prevCounters[routeId] || 0;
			return { ...prevCounters, [routeId]: currentCount + 1 };
		});
	};

	const decrementCounter = (routeId) => {
		setCounters((prevCounters) => {
			const currentCount = prevCounters[routeId] || 0;
			return { ...prevCounters, [routeId]: Math.max(currentCount - 1, 0) };
		});
	};

	return { counters, initCounter, incrementCounter, decrementCounter };
};

export default useCounter;
