import TextEditor from "@components/TextEditor/TextEditor";
import { addDoc, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState, Suspense, lazy } from "react";
import ReactQuill from "react-quill";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./ConsultPostStyles.scss";
import { db } from "initFirebase";
import RevisionCard from "./components/RevisionCard";
import { consults, langsBoolean } from "./Interfaces/Interfaces";
import emailjs from "emailjs-com";
import { useConsultService } from "../../services/ConsultsService";
import { randomIntFromInterval, romanize } from "functions/Functions";
import { NotifyUsersConsultPost } from "../../services/NotifyUsersConsultPost";
import { randomSearchTexts } from "constants/SearchWords";
import {
	BrazilFlagPng,
	ChinaFlagPng,
	CloseCircleIcon,
	DownArrowIcon,
	FranceFlagPng,
	GermanyFlagPng,
	ItalyFlagPng,
	JapanFlagPng,
	RussiaFlagPng,
	SearchIcon,
	UnitedStatesFlagPng,
} from "assets/Imports";

const searchPlaceHolder = randomSearchTexts[randomIntFromInterval(0, 8)];

const VerifiedConsultCard = lazy(() => import("./components/VerifiedConsultCard"));

function ConsultPostContainer({ user }) {
	const { num } = useParams<any>();

	const seminaryNum = parseInt(num);
	const [seminaryNumCheck, setSeminaryNumCheck] = useState<boolean>(true);
	const [openFilterDropdown, setOpenFilterDropdown] = useState<boolean>(false);
	const [consultPost, setConsultPost] = useState<string>("");
	const [send, setSend] = useState<boolean>(false);
	const [consultLangs, setConsultLangs] = useState<langsBoolean>({
		french: false,
		german: false,
		italian: false,
		russian: false,
		chinese: false,
		portuguese: false,
		english: false,
		japanese: false,
	});
	const [hashtags, setHashtags] = useState<Array<string>>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [french, setFrench] = useState<boolean>(false);
	const [german, setGerman] = useState<boolean>(false);
	const [japanese, setJapanese] = useState<boolean>(false);
	const [italian, setItalian] = useState<boolean>(false);
	const [russian, setRussian] = useState<boolean>(false);
	const [chinese, setChinese] = useState<boolean>(false);
	const [portuguese, setPortuguese] = useState<boolean>(false);
	const [english, setEnglish] = useState<boolean>(false);
	const [myConsults, setMyConsults] = useState<boolean>(false);
	const [popularHashtags, setPopularHashtags] = useState<Array<string>>([]);
	const [popularHashtags2, setPopularHashtags2] = useState<Array<string>>([]);
	const [showMore, setShowMore] = useState<boolean>(false);
	const [hashtagFilter, setHashtagFilter] = useState<Array<string>>([]);
	const [filterText, setFilterText] = useState<string>("");
	const [isSearching, setIsSearching] = useState<boolean>(false);
	const [aScroll, setAScroll] = useState<boolean>(false);
	const [showNotificationBar, setShowNotificationBar] = useState<boolean>(false);
	const seminarios = [
		60, 59, 58, 57, 56, 55, 54, 53, 52, 51, 50, 49, 48, 47, 46, 45, 44, 43, 42, 41, 40, 39, 38, 37, 36, 35, 34, 33, 32, 31, 30,
		29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1,
	];
	const [postIn, setPostIn] = useState<Array<number>>([seminaryNum]);
	const [notifyAll, setNotifyAll] = useState<boolean>(true);
	const [newConsult, setNewConsult] = useState<boolean>(false);
	const { unVerifiedConsults, personalConsults, verifiedConsults } = useConsultService();
	const [revisionOpen, setRevisionOpen] = useState<boolean>(false);
	const history = useHistory();

	useEffect(() => {
		if (!loading && send === true) {
			sendConsult();
		}
	}, [send]);

	const sendEmail = () => {
		const dataemail = {
			subject: "Nueva consulta",
			message: "Tienes una nueva consulta en Academia de Lenguas Internacionales",
		};

		emailjs.send("service_kznboar", "template_33c8s4e", dataemail, "user_PPI0O8nofs9cbuJ3JRWlT").then(
			function (res) {},
			function (error) {
				console.log(error);
			}
		);
	};

	const sendConsult = async () => {
		let array = postIn;
		setLoading(true);

		array.forEach(async (num, index) => {
			await addDoc(collection(db, "consults"), {
				name: user.name,
				postText: consultPost,
				hashtags: hashtags,
				french: consultLangs.french,
				german: consultLangs.german,
				japanese: consultLangs.japanese,
				italian: consultLangs.italian,
				chinese: consultLangs.chinese,
				russian: consultLangs.russian,
				english: consultLangs.english,
				portuguese: consultLangs.portuguese,
				date: new Date(),
				userId: user.id,
				seminaryNum: num,
				verified: user.userType === 2 || user.userType === 4 ? true : false,
				numberOfReplies: 0,
				userType: user.userType === 2 || user.userType === 4 ? user.userType : 1,
				profileColor: user.color,
			}).then(async (res) => {
				if (user.userType === 2 || user.userType === 4) {
					if (notifyAll) {
						NotifyUsersConsultPost(num, consultPost, res.id, user.color);
					} else {
						//
					}
				}
				if (user.userType !== 2 && user.userType !== 4) {
					await addDoc(collection(db, "notifications"), {
						type: "consult-new",
						seminaryNum: seminaryNum,
						name: user.name,
						text: consultPost,
						date: new Date(),
						userId: "admin",
						consultId: res.id,
						consultAnswerId: null,
						userType: 1,
						seen: false,
						profileColor: user.color,
						genId: null,
						sectionId: "G7LYiZinvaxFmvs5ZiPR",
					});
					sendEmail();
				}
			});

			if (index === array.length - 1) {
				setHashtags([]);
				setConsultLangs({
					french: false,
					german: false,
					italian: false,
					russian: false,
					chinese: false,
					portuguese: false,
					english: false,
					japanese: false,
				});
				setConsultPost("");
				setSend(false);
				setShowNotificationBar(true);
				setLoading(false);
			}
		});
	};

	const getHashtags = async () => {
		const docRef = doc(db, "consultsHashtags", "hashtags");
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setPopularHashtags(docSnap.data().hashtags);
			setPopularHashtags2(docSnap.data().hashtags2);
		} else {
			// doc.data() will be undefined in this case
			console.log("No such document!");
		}
	};

	useEffect(() => {
		if (
			filterText.length > 0 ||
			hashtagFilter.length > 0 ||
			french ||
			japanese ||
			italian ||
			russian ||
			chinese ||
			english ||
			german ||
			portuguese ||
			myConsults
		) {
			setIsSearching(true);
		} else {
			setIsSearching(false);
		}
	}, [filterText, hashtagFilter, french, japanese, italian, russian, chinese, english, german, portuguese, myConsults]);

	useEffect(() => {
		getHashtags();
		setLoading(false);
	}, []);

	const addHashtagFilter = (hashtag) => {
		let array = new Array();

		hashtagFilter.forEach((word) => {
			array.push(word);
		});
		if (array.indexOf(hashtag) !== -1) {
			const index = array.indexOf(hashtag);
			array.splice(index, 1);
			setHashtagFilter(array);
		} else {
			array.push(hashtag);
			setHashtagFilter(array);
		}
	};

	function searching(consult) {
		if (
			(french === true && consult.french === true) ||
			(german === true && consult.german === true) ||
			(japanese === true && consult.japanese === true) ||
			(italian === true && consult.italian === true) ||
			(chinese === true && consult.chinese === true) ||
			(russian === true && consult.russian === true) ||
			(english === true && consult.english) ||
			(portuguese === true && consult.portuguese) ||
			(filterText.length > 0 &&
				consult.postText
					.toLowerCase()
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "")
					.replace(/'/g, "")
					.replace(/\(/g, "")
					.replace(/\)/g, "")
					.replace(/\?/g, "")
					.replace(/,/g, "")
					.replace(/\./g, "")
					.includes(
						filterText
							.toLowerCase()
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.replace(/'/g, "")
							.replace(/\(/g, "")
							.replace(/\)/g, "")
							.replace(/\?/g, "")
							.replace(/,/g, "")
							.replace(/\./g, "")
					))
		) {
			if (hashtagFilter.length > 0) {
				let tempArray = new Array();
				hashtagFilter.forEach((item) => {
					if (consult.hashtags.indexOf(item) !== -1) {
						tempArray.push(item);
					}
				});

				if (tempArray.length > 0) {
					return true;
				}
			} else {
				return true;
			}
		} else if (
			!french &&
			!german &&
			!japanese &&
			!italian &&
			!chinese &&
			!russian &&
			!english &&
			!portuguese &&
			filterText.length === 0
		) {
			if (hashtagFilter.length > 0) {
				let tempArray = new Array();
				hashtagFilter.forEach((item) => {
					if (consult.hashtags.indexOf(item) !== -1) {
						tempArray.push(item);
					}
				});

				if (tempArray.length > 0) {
					return true;
				}
			} else {
				return true;
			}
		} else {
			return false;
		}
	}

	const prevent = (e) => {
		e.preventDefault();
	};

	const SeminaryPosting = (e, sem) => {
		let array = postIn;

		if (e === true) {
			array.push(sem);
			setPostIn(array);
			if (sem === seminaryNum) {
				setSeminaryNumCheck(true);
			}
		} else {
			let index = array.indexOf(sem);

			if (index !== -1) {
				array.splice(index, 1);
				setPostIn(array);

				if (sem === seminaryNum) {
					setSeminaryNumCheck(false);
				}
			}
		}
	};

	if (loading)
		return (
			<div style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);

	return (
		<>
			<div className={"consult-send-notification-container" + (showNotificationBar ? " open-consult-notification" : "")}>
				<div>
					{user.userType !== 2 && user.userType !== 4 && (
						<h3 style={{ textAlign: "center" }}>
							¡Tu duda fue enviada con éxito! <br />
							Te enviaremos una notificación en cuanto sea contestada.
						</h3>
					)}
					{(user.userType === 2 || (user.userType === 4 && notifyAll)) && (
						<h3>Publicación enviada con éxito, se ha notificado a todos los usuarios.</h3>
					)}
					{(user.userType === 2 || (user.userType === 4 && !notifyAll)) && (
						<h3>Publicación enviada con éxito. Ningún alumno ha sido notificado.</h3>
					)}
					<h3 style={{ cursor: "pointer" }} onClick={() => setShowNotificationBar(false)}>
						X
					</h3>
				</div>
			</div>
			<div className="go-back-container">
				<button onClick={() => history.goBack()} className="goback-btn">
					{"Atrás"}
				</button>
			</div>
			<div className="consult-post-title-container">
				{seminaryNum === 0 ? (
					<h1>Técnicas de aprovechamiento</h1>
				) : (
					<h1>
						Seminario {romanize(seminaryNum)}{" "}
						{user.userType === 2 || (user.userType === 4 && <span className="admin-mode">Admin mode</span>)}
					</h1>
				)}
				<div className="right-text">
					<h3> {verifiedConsults.length} consultas</h3>
				</div>
			</div>
			{openFilterDropdown && (
				<div
					style={{ height: "100vh", width: "100%", position: "fixed", top: 77, left: 300, zIndex: 1019 }}
					onClick={() => setOpenFilterDropdown(false)}
				></div>
			)}
			<form onSubmit={prevent} className="search-section-container consult-search-section-container">
				<div className="search-container">
					<div
						className={"lang-selector-container " + (openFilterDropdown ? "langs-selectior-container-open" : "")}
						onClick={() => setOpenFilterDropdown(!openFilterDropdown)}
					>
						<p>Todo</p>
						<div className="icons-container">
							<img className="icon1" src={DownArrowIcon} alt="down-arrow-icon" />
							<img className="icon2" src={CloseCircleIcon} alt="down-arrow-icon" />
						</div>
					</div>
					<div className="search-input-container">
						<img src={SearchIcon} alt="search-icon" />
						<input
							type="text"
							placeholder={searchPlaceHolder}
							value={filterText}
							onChange={(e) => setFilterText(e.target.value)}
						/>
					</div>
					<div className={"filters-container " + (openFilterDropdown ? "filters-container-open" : "")}>
						<div className="lang-filter-container">
							<h3>Por idioma(s)</h3>
							<div className="checkboxes-container">
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={french}
										onChange={(e) => setFrench(e.target.checked)}
										id="french"
										name="french"
									/>
									<label htmlFor="french">Francés</label>
									<img
										style={{ width: "24px", height: "24px", marginLeft: "auto" }}
										src={FranceFlagPng}
										alt="bandera de francia"
									/>
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={german}
										onChange={(e) => setGerman(e.target.checked)}
										id="german"
										name="german"
									/>
									<label htmlFor="german">Alemán</label>
									<img
										style={{ width: "24px", height: "24px", marginLeft: "auto" }}
										src={GermanyFlagPng}
										alt="bandera de alemania"
									/>
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={japanese}
										onChange={(e) => setJapanese(e.target.checked)}
										id="japanese"
										name="japanese"
									/>
									<label htmlFor="japanese">Japonés</label>
									<img style={{ width: "24px", height: "24px", marginLeft: "auto" }} src={JapanFlagPng} alt="bandera de japon" />
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={italian}
										onChange={(e) => setItalian(e.target.checked)}
										id="italian"
										name="italian"
									/>
									<label htmlFor="italian">Italiano</label>
									<img style={{ width: "24px", height: "24px", marginLeft: "auto" }} src={ItalyFlagPng} alt="bandera de italia" />
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={russian}
										onChange={(e) => setRussian(e.target.checked)}
										id="russian"
										name="russian"
									/>
									<label htmlFor="russian">Ruso</label>
									<img style={{ width: "24px", height: "24px", marginLeft: "auto" }} src={RussiaFlagPng} alt="bandera de rusia" />
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={chinese}
										onChange={(e) => setChinese(e.target.checked)}
										id="chinese"
										name="chinese"
									/>
									<label htmlFor="chinese">Chino</label>
									<img style={{ width: "24px", height: "24px", marginLeft: "auto" }} src={ChinaFlagPng} alt="bandera de china" />
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={portuguese}
										onChange={(e) => setPortuguese(e.target.checked)}
										id="portuguese"
										name="portuguese"
									/>
									<label htmlFor="portuguese">Portugués</label>
									<img
										style={{ width: "24px", height: "24px", marginLeft: "auto" }}
										src={BrazilFlagPng}
										alt="bandera de portugal"
									/>
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										checked={english}
										onChange={(e) => setEnglish(e.target.checked)}
										id="english"
										name="english"
									/>
									<label htmlFor="english">Inglés</label>
									<img
										style={{ width: "24px", height: "24px", marginLeft: "auto" }}
										src={UnitedStatesFlagPng}
										alt="bandera de estados unidos"
									/>
								</div>
							</div>
						</div>
						<div className="hashtag-filter-container">
							<h3>Por etiquetas populares</h3>
							<div className="hashtags-container">
								{popularHashtags.length > 0 && (
									<>
										{!showMore ? (
											<>
												{popularHashtags.map((hashtag, index) => {
													return (
														<div
															className={"hashtag" + (hashtagFilter.indexOf(hashtag) !== -1 ? " active" : "")}
															onClick={() => addHashtagFilter(hashtag)}
															key={"popularHashtag-" + index}
														>
															#{hashtag}
														</div>
													);
												})}
											</>
										) : (
											<>
												{popularHashtags2.map((hashtag, index) => {
													return (
														<div
															className={"hashtag" + (hashtagFilter.indexOf(hashtag) !== -1 ? " active" : "")}
															onClick={() => addHashtagFilter(hashtag)}
															key={"popularHashtag2-" + index}
														>
															#{hashtag}
														</div>
													);
												})}
											</>
										)}
									</>
								)}
								{!showMore && (
									<span
										style={{ textDecoration: "underline", color: "#ca5050", cursor: "pointer", fontWeight: "bolder" }}
										onClick={() => setShowMore(true)}
									>
										Mostrar más
									</span>
								)}
							</div>
						</div>
						<div className="alone-filter mb-0">
							<input
								type="checkbox"
								id="myconsults"
								name="myconsults"
								checked={myConsults}
								onChange={(e) => setMyConsults(e.target.checked)}
							/>
							<label htmlFor="myconsults">Buscar en mis preguntas</label>
						</div>
					</div>
				</div>
			</form>
			{!isSearching && (
				<>
					<div className={"doubt-container"}>
						{user.userType === 2 || user.userType === 4 ? (
							<h3>Haz una publicación para todos los estudiantes</h3>
						) : (
							<h3>¿Cuál es tu duda?</h3>
						)}
						{user.userType === 2 ||
							(user.userType === 4 && (
								<div style={{ display: "flex", gap: 10, alignItems: "center", marginBottom: 10 }}>
									<input
										type="checkbox"
										checked={notifyAll}
										onChange={(e) => setNotifyAll(e.target.checked)}
										name="notifyall"
										id="notifyall"
									/>
									<label style={{ margin: 0 }} htmlFor="notifyall">
										Notificar a todos los usuarios
									</label>
								</div>
							))}
						<TextEditor
							name="new-consult"
							type={"post"}
							setPostIn={setPostIn}
							postIn={postIn}
							user={user}
							SeminaryPosting={SeminaryPosting}
							setSeminaryNumCheck={setSeminaryNumCheck}
							seminaryNumCheck={seminaryNumCheck}
							seminaryNum={seminaryNum}
							seminarios={seminarios}
							popularHashtags={popularHashtags2}
							langDirection={"down"}
							consultPost={consultPost}
							consultLangs={consultLangs}
							setConsultPost={setConsultPost}
							setSend={setSend}
							setConsultLangs={setConsultLangs}
							setHashtags={setHashtags}
							hashtags={hashtags}
							id={"0"}
						/>
					</div>
				</>
			)}
			{!isSearching && (
				<div className="consult-post-revision-container">
					<h3>Consultas en revisión</h3>
					{unVerifiedConsults.map((consult, index) => {
						return (
							<RevisionCard
								aScroll={aScroll}
								setAScroll={setAScroll}
								setLoading={setLoading}
								seminarios={seminarios}
								seminaryNum={seminaryNum}
								consult={consult}
								index={index}
								user={user}
								key={"revisionCard" + index}
							/>
						);
					})}
					{unVerifiedConsults.length === 0 && (
						<h2 style={{ fontSize: "1rem", color: "#00000060", fontWeight: "lighter" }}>
							No hay consultas en revisión por el momento
						</h2>
					)}
				</div>
			)}
			<div className="consult-post-revision-container">
				{isSearching ? <h3>Consultas encontradas</h3> : <h3>Consultas anteriores</h3>}

				<>
					{!myConsults ? (
						<>
							{verifiedConsults.map((consult, index) => {
								return (
									<>
										{searching(consult) && (
											<Suspense fallback={<Spinner height={200} />}>
												<VerifiedConsultCard
													seminarios={seminarios}
													seminaryNum={seminaryNum}
													aScroll={aScroll}
													setAScroll={setAScroll}
													user={user}
													consult={consult}
													index={index}
													mounted={true}
													key={"verifiedConsultCard" + index}
												/>
											</Suspense>
										)}
									</>
								);
							})}
							{verifiedConsults.length === 0 && (
								<h2 style={{ fontSize: "1rem", color: "#00000060", fontWeight: "lighter" }}>No hay consultas por el momento</h2>
							)}
						</>
					) : (
						<>
							{personalConsults.map((consult, index) => {
								return (
									<>
										{searching(consult) && (
											<Suspense fallback={<Spinner height={200} />}>
												<VerifiedConsultCard
													seminarios={seminarios}
													seminaryNum={seminaryNum}
													aScroll={aScroll}
													setAScroll={setAScroll}
													user={user}
													consult={consult}
													index={index}
													mounted={true}
													key={"verifiedConsultCard2" + index}
												/>
											</Suspense>
										)}
									</>
								);
							})}
							{personalConsults.length === 0 && (
								<h2 style={{ fontSize: "1rem", color: "#00000060", fontWeight: "lighter" }}>
									No hay consultas tuyas por el momento
								</h2>
							)}
						</>
					)}
				</>
			</div>
		</>
	);
}

function Spinner({ height }) {
	return (
		<div style={{ height: height, display: "flex", justifyContent: "center", alignItems: "center" }}>
			<div className="spinner-border text-danger" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
}

export default ConsultPostContainer;
