import axios, { InternalAxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from "initFirebase";

export const apiInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

let currentUser: User | null = null;

onAuthStateChanged(auth, (user) => {
	if (user) {
		currentUser = user;
	} else {
		currentUser = null;
		removeAuthToken();
	}
});

const removeAuthToken = () => {
	delete apiInstance.defaults.headers.common["Authorization"];
};

apiInstance.interceptors.request.use(
	async (config: InternalAxiosRequestConfig) => {
		if (currentUser) {
			// Get the current token, force refresh if needed
			const token = await currentUser.getIdToken(false);
			if (!config.headers) {
				config.headers = {} as AxiosRequestHeaders;
			}
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default apiInstance;
