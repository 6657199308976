import { useEffect, useState } from "react";
import "./VocabularySeminaryStyles.scss";
import { Modal } from "react-bootstrap";
import { capitalizeFirstLetter, getImage, returnFlag, returnFlagByLanguage } from "functions/Functions";
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "initFirebase";
import {
	wordsSeminary,
	getAllCategories,
	addWordDb,
	getAllWords,
	getSubCategories,
	deleteWord,
	updateWord,
	allMeanings,
	bookmarkWordFalse,
} from "@api/Vocabulary";
import { Link, useHistory, useParams } from "react-router-dom";
import { exit } from "process";
import { NotesModal } from "./components/NotesModal/NotesModal";
import { QuizDefaultInfoModal } from "./components/QuizDefaultInfo/QuizDefaultInfoModal";
import { returnWarningTypeImage } from "@containers/VocabularySeminaryContainer/functions/VocabularyFunctions";
import { getLangSpanishSimple, getDifficultColor2 } from "@components/Vocabulary/PersonalizedTest/functions/functions";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { WordsModal } from "@containers/VocabularySeminaryContainer/components/Test/components/WordsModal";
import { Check, LENLogo, RocketIcon, Trash } from "assets/Imports";
import ReactQuill, { Quill } from "react-quill";
import { CustomSpinner } from "@components/Spinner/SpinnerMyAccountView";
import { place_holder_vocabulary } from "@utils/PlaceHolders/place-holder-vocabulary";
function VocabularySeminaryContainer({ user }) {
	const [modal, setModal] = useState<boolean>(false);
	const [update, setUpdate] = useState<boolean>(false);
	const [word_added, setWordAdded] = useState<boolean>(false);
	const handleClose = () => setModal(false);
	const { seminaryNum } = useParams<any>();
	const [words, setWords] = useState<Array<any>>(new Array());
	const [allWords, setAllWords] = useState<Array<any>>(new Array());
	const [meanings, setMeanings] = useState<Array<any>>(new Array());
	const [categories, setCategories] = useState<Array<any>>(new Array());
	let categories_opt: any = [];
	const [subcategories, setSubcategories] = useState<Array<any>>(new Array());
	const [wordsBackup, setWordsBackup] = useState<Array<any>>(new Array());
	const [word, setWord] = useState<any>({});
	const [searchText, setText] = useState<string>("");
	const [sort, setSort] = useState<string>("0");
	const [noteType, setNoteType] = useState<string>("warning");
	const [value, setValue] = useState<string>("");
	const [warn, setwarn] = useState([
		"advertencia",
		"memoria",
		"gramatica",
		"consejo",
		"falso_amigo",
		"similitud",
		"pronunciacion",
		"sabias_que",
	]);
	const [warningType, setWarningType] = useState<string>("advertencia");
	const { input_buscar_terminos, input_palabra_original, input_palabra_en_español, Kanji, Kanzi } = place_holder_vocabulary;
	const [showQuiz, setShowQuiz] = useState<boolean>(false);
	const [quizIndex, setQuizIndex] = useState<number>(0);

	const [quiz, setQuiz] = useState<Array<any>>(new Array());
	const [notesModal, setNotesModal] = useState({
		show: false,
		notes: "",
	});
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingWords, setLoadingWords] = useState<boolean>();
	const history = useHistory();
	const languages = ["french", "german", "italian", "russian", "japanese", "chinese", "portuguese", "english"];

	var Font = Quill.import("formats/font");
	Font.whitelist = ["cirilico"];
	Quill.register(Font, true);
	var Block = Quill.import("blots/block");
	Block.tagName = "p";
	Block.className = "pre";
	Quill.register(Block, true);
	var toolbarOptions = [
		[{ size: ["small", false, "large", "huge"] }], // custom dropdown
		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		["bold", "italic", "underline", "strike"],
		[{ indent: "-1" }, { indent: "+1" }], // toggled buttons

		["clean"], // remove formatting button
	];

	const getMeanings = () => {
		let temp: any = [];
		allMeanings().then((res) => {
			res.seminaries.forEach((element) => {
				if (!temp.some((meaning) => meaning.spanish_word == element.spanish_word)) {
					temp.push(element);
				}
			});
			setMeanings(temp);
		});
	};

	const allWordsSeminaries = () => {
		getAllWords().then((res) => {
			setAllWords(res.words);
		});
	};

	const getWords = async () => {
		setLoadingWords(true);
		const q = query(collection(db, "defaultQuizzes"), where("seminary", "==", parseInt(seminaryNum)));
		const docSnap = await getDocs(q);
		if (docSnap.size > 0) {
			let tempArray: any = [];

			await Promise.all(
				docSnap.docs.map((quiz) => {
					tempArray.push({ id: quiz.id, ...quiz.data() });
				})
			);

			setQuiz(tempArray);
		}

		let temp_words: any = [];
		await wordsSeminary(seminaryNum)
			.then(async (res) => {
				await Promise.all(
					res.seminary.map((element) => {
						element.categoria = [element.categoria];
						element.significado = [element.significado];
						element.id_significado = [element.id_significado];
						if (!temp_words.some((el) => el.word_data_id === element.word_data_id)) {
							if (typeof element.sub_categoria === "string") {
								element.sub_categoria = [element.sub_categoria];
							}
							temp_words.push(element);
						} else {
							temp_words.some((el) => {
								if (el.word_data_id === element.word_data_id) {
									if (!el.significado.includes(element.significado[0])) {
										el.significado.push(element.significado[0]);
									}
									if (!el.categoria.includes(element.categoria[0])) {
										el.categoria.push(element.categoria[0]);
									}
									if (element.sub_categoria) {
										if (!el.sub_categoria.includes(element.sub_categoria)) {
											el.sub_categoria.push(element.sub_categoria);
										}
									}
								}
							});
						}
					})
				);

				await Promise.all(
					temp_words.map((element) => {
						if (element.idioma == "french") return (element.idioma = { idioma: element.idioma, id: 1 });
						if (element.idioma == "german") return (element.idioma = { idioma: element.idioma, id: 2 });
						if (element.idioma == "japanese") return (element.idioma = { idioma: element.idioma, id: 3 });
						if (element.idioma == "italian") return (element.idioma = { idioma: element.idioma, id: 4 });
						if (element.idioma == "russian") return (element.idioma = { idioma: element.idioma, id: 5 });
						if (element.idioma == "chinese") return (element.idioma = { idioma: element.idioma, id: 6 });
						if (element.idioma == "portuguese") return (element.idioma = { idioma: element.idioma, id: 7 });
						if (element.idioma == "english") return (element.idioma = { idioma: element.idioma, id: 8 });
					})
				);
			})
			.then(() => {
				setWords(temp_words);
				setWordsBackup(temp_words);
				getCategoriesSeminary(temp_words);
				if (searchText.length > 1 || sort !== "0") {
					filterByAll(temp_words);
				}
				setLoadingWords(false);
				setLoading(false);
			});
	};

	const getCategoriesSeminary = (temp_words) => {
		getAllCategories()
			.then((res) => {
				setCategories(res.categories);
				res.categories.sort((a: any, b: any) => (a.family > b.family ? 1 : a.family < b.family ? -1 : 0));
				temp_words.forEach((element) => {
					element.categories_ids = [];
					element.categories_ids_del = [];
					res.categories.forEach((category) => {
						if (element.categoria.includes(category.family)) {
							element.categories_ids.push(parseInt(category.id));
							element.categories_ids_del.push(parseInt(category.id));
						}
					});
				});
			})
			.then(() => {
				getSubcategoriesSeminary(temp_words);
			});
	};

	const getSubcategoriesSeminary = (temp_words) => {
		getSubCategories().then((res) => {
			setSubcategories(res.categories);
			res.categories.sort((a: any, b: any) => (a.sub_family > b.sub_family ? 1 : a.sub_family < b.sub_family ? -1 : 0));
			temp_words.forEach((element) => {
				element.subcategories_ids = [];
				element.subcategories_ids_del = [];
				res.categories.forEach((subcategory) => {
					if (element.sub_categoria && element.sub_categoria.includes(subcategory.sub_family)) {
						element.subcategories_ids.push(parseInt(subcategory.id));
						element.subcategories_ids_del.push(parseInt(subcategory.id));
					}
				});
			});
		});
	};

	const filterByAll = (wordsB?: Array<any>) => {
		let wordsTemp = wordsB === undefined ? JSON.parse(JSON.stringify(words)) : JSON.parse(JSON.stringify(wordsB));
		let words_temp: any;
		words_temp = wordsTemp.filter(
			(word) =>
				word.palabra.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 ||
				(word.palabra_opcional !== null && word.palabra_opcional.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) ||
				word.significado.some(
					(e) =>
						e
							.toLowerCase()
							.normalize("NFD")
							.toLocaleLowerCase()
							.replace(/[\u0300-\u036f]/g, "") ===
						searchText
							.toLowerCase()
							.normalize("NFD")
							.toLocaleLowerCase()
							.replace(/[\u0300-\u036f]/g, "")
				)
		);
		if (sort === "1") {
			words_temp = [...words_temp].sort(function (a, b) {
				return a.palabra.toLowerCase() < b.palabra.toLowerCase() ? -1 : a.palabra.toLowerCase() > b.palabra.toLowerCase() ? 1 : 0;
			});
		}
		if (sort === "2") {
			words_temp = [...words_temp].sort((a: any, b: any) => {
				return a.dificultad - b.dificultad;
			});
		}
		if (sort === "3") {
			words_temp = [...words_temp].sort((a: any, b: any) => {
				return b.dificultad - a.dificultad;
			});
		}
		if (sort === "4") {
			words_temp = [...words_temp].filter((x) => x.bookmark == 1);
		}
		if (languages.includes(sort)) {
			words_temp = [...words_temp].filter((x) => x.idioma.idioma === sort);
		}
		setWords(words_temp);
	};

	const filterBy = (e) => {
		setWords(wordsBackup);
	};

	const seeDefaultQuizInfo = (index) => {
		setQuizIndex(index);
		setShowQuiz(true);
	};

	const setWordCategory = (e) => {
		categories_opt = [];
		var temp_categories = [...e.target.selectedOptions];
		temp_categories.forEach((element) => {
			categories_opt.push(parseInt(element.value));
		});
		setWord({ ...word, categories_ids: categories_opt });
	};

	const setWordSubcategory = (e) => {
		var subTemp_categories: any = [];
		if (word.subsubcategories_ids) {
			word.subcategories_ids.map((id) => {
				subTemp_categories.push(id.toString());
			});
		}
		var options = e.target.selectedOptions;

		for (var i = 0, l = options.length; i < l; i++) {
			if (options[i].selected && subTemp_categories.findIndex((e) => e === options[i].value) === -1) {
				subTemp_categories.push(options[i].value);
			} else {
				let index = subTemp_categories.findIndex((e) => e === options[i].value);
				if (index !== -1) {
					subTemp_categories.splice(index, 1);
				}
			}
		}
		setWord({ ...word, subcategories_ids: subTemp_categories });
	};

	// Function to validate word
	const validateWord = (word) => {
		let someEmpty = false;
		let messageError = "";

		if (typeof word.significado == "string") {
			word.significado = word.significado.split("|");
			word.significado.forEach((element, index) => {
				if (element.replace(/ *\([^)]*\) */g, "").length === 0) {
					someEmpty = true;
				}
				word.significado[index] = element.trim();
			});
		}

		if (word.significado === undefined || someEmpty) {
			messageError += "Uno de los significados esta vacío. \n";
		}
		if (word.palabra === undefined || word.palabra.trim() === "") {
			messageError += "La palabra en lengua de origen no debe de estar vacío. \n";
		}

		return messageError;
	};

	// Function to process word
	const processWord = (word) => {
		if (!word.palabra_opcional) {
			word.palabra_opcional = null;
		}
		if (word.idioma.id) {
			word.idioma = word.idioma.id;
		}
		if (!word.advertencia) {
			word.advertencia = "";
		}
		word.idioma = parseInt(word.idioma);
		word.paper = parseInt(word.paper);
		word.id_significado = [];

		word.significado.forEach((element) => {
			meanings.forEach((meaning) => {
				if (element == meaning.spanish_word) {
					word.id_significado.push(meaning.id);
				}
			});
			if (!meanings.some((meaning) => meaning.spanish_word == element)) {
				word.id_significado.push(element);
			}
		});

		if (!("subcategories_ids" in word)) {
			word.subcategories_ids = [];
		}

		return word;
	};

	// Function to handle quote in word
	const handleQuoteInWord = (word) => {
		if (word.palabra.startsWith('"') && word.palabra.endsWith('"')) {
			word.palabra = word.palabra.replace(/"/g, '\\"');
		}
		return word;
	};

	// Function to process add word to DB
	const processAddWordToDb = async (word) => {
		await addWordDb(word)
			.then((res) => {
				setWordAdded(!word_added);
				setModal(!modal);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const addWord = async () => {
		// Validate word
		let wordT = word;
		const messageError = validateWord(wordT);
		if (messageError.length > 0) {
			alert(messageError);
			return;
		}

		// Process word
		wordT = processWord(wordT);

		// Handle quote in word
		wordT = handleQuoteInWord(wordT);

		// Process add word to db
		await processAddWordToDb(word);
	};

	const deleteWordSeminary = (id) => {
		if (window.confirm("¿Está seguro de que desea eliminar este término?")) {
			deleteWord(id).then((res) => {
				getWords();
			});
		}
	};

	const updateWordSeminary = () => {
		try {
			let someEmpty = false;
			if (typeof word.significado == "string") {
				word.significado = word.significado.split("|");
				word.significado.forEach((element, index) => {
					if (element.replace(/ *\([^)]*\) */g, "").length === 0) {
						someEmpty = true;
					}
					word.significado[index] = element.trim();
				});
			}
			if (someEmpty) {
				if (someEmpty) return alert("Uno de los significados esta vacío.");
			} else {
				if (!word.palabra_opcional) {
					word.palabra_opcional = null;
				}
				if (word.idioma.id) {
					word.idioma = word.idioma.id;
				}
				word.idioma = parseInt(word.idioma);
				word.paper = parseInt(word.paper);
				word.id_significado = [];

				word.significado.forEach((element) => {
					if (element == "") {
						alert("Este campo no puede estar vacio");
						throw "exit";
					}
					meanings.forEach((meaning) => {
						if (element == meaning.spanish_word) {
							word.id_significado.push(meaning.id);
						}
					});
					if (!meanings.some((meaning) => meaning.spanish_word == element)) {
						word.id_significado.push(element);
					}
				});
				if (word.palabra.startsWith('"') && word.palabra.endsWith('"')) {
					word.palabra = word.palabra.replace(/"/g, '\\"');
				}
				updateWord(word).then(async (res) => {
					if (word.bookmark === 1) {
						await bookmarkWordFalse(word.id);
					}
					setWordAdded(!word_added);
					setModal(!modal);
				});
			}
		} catch (error) {
			console.log(error);
			return error;
		}
	};

	const previousSeminary = () => {
		setQuiz(new Array());
		if (+seminaryNum !== 0) {
			history.push({ pathname: "/admin/vocabulario/seminario/" + (+seminaryNum - 1) });
		}
	};

	const nextSeminary = () => {
		setQuiz(new Array());
		if (+seminaryNum !== 60) {
			history.push({ pathname: "/admin/vocabulario/seminario/" + (+seminaryNum + 1) });
		}
	};

	const editWord = async (word) => {
		let ret = "";

		await Promise.all(
			word.significado.map((meaning, index) => {
				ret += meaning + (word.significado.length > 1 && index <= word.significado.length - 2 ? "|" : "");
			})
		);

		let editWordCategorie = categories.filter((e) => e.singular === word.singular_category);

		let editWordSubCategories: any = [];
		if (word.sub_categoria !== null) {
			word.sub_categoria.map((sbc) => {
				editWordSubCategories.push(subcategories.filter((e) => e.sub_family === sbc)[0].id);
			});
		}

		setWord({ ...word, significado: ret, categories_ids: [editWordCategorie[0].id], subcategories_ids: editWordSubCategories });
		setModal(true);
		setUpdate(true);
	};

	useEffect(() => {
		getWords();
		getMeanings();
		allWordsSeminaries();
	}, []);

	useEffect(() => {
		getWords();
	}, [seminaryNum]);

	useEffect(() => {
		filterByAll();
	}, [sort, searchText]);

	useEffect(() => {
		getWords();
	}, [word_added]);

	useEffect(() => {
		setWord({ ...word, [warningType]: value });
	}, [value]);

	if (loading) {
		return (
			<div style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	return (
		<div className="vocabulary-seminary-admin-main-container">
			<div className="button-container">
				<button
					className="goback-btn"
					style={{ height: "fit-content" }}
					onClick={() => history.push({ pathname: "/admin/vocabulario" })}
				>
					Atrás
				</button>
				<div className="move-btn">
					<p className="title-sem">Seminario</p>
					<div className="buttons-back-for">
						<button className={seminaryNum !== "0" ? "btn-back" : "btn-back dis-back"} onClick={previousSeminary}>
							<FaArrowLeft />
							Anterior
						</button>
						<button className={seminaryNum !== "60" ? "btn-forward" : "btn-forward dis-for"} onClick={nextSeminary}>
							Siguiente
							<FaArrowRight />
						</button>
					</div>
				</div>
			</div>
			{quiz.length > 0 && <QuizDefaultInfoModal show={showQuiz} onHide={() => setShowQuiz(false)} quiz={quiz[quizIndex]} />}
			<div className="title">
				<h1>Seminario {seminaryNum}</h1>
			</div>
			<div className="subtitle">
				<h3>Revisa las listas de vocabularios para las clases y los progresos de los estudiantes</h3>
			</div>
			<Link to={`/admin/vocabulario/quiz/${seminaryNum}`}>
				<div className="row-container">
					<div className="config-test-card">
						<div className="title">
							<h3>Configurar ronda de estudio predeterminada</h3>
						</div>
						<div className="icon">
							{loadingWords ? (
								<CustomSpinner size={50} height={"auto"} borderSize={4} />
							) : (
								languages.map((language, index) => {
									if (quiz.filter((e) => e.language === language).length > 0) {
										return (
											<div className="default-quizzes-container">
												{returnFlagByLanguage(language)}
												<div className="number-of-default-quizzes-container">
													<h3>{quiz.filter((e) => e.language === language).length}</h3>
												</div>
											</div>
										);
									}
								})
							)}
							<img src={RocketIcon} alt="icono de cohete" />
						</div>
					</div>
				</div>
			</Link>
			<div className="words-container">
				<div className="filter-container">
					<div className="left">
						<select
							value={sort}
							onChange={(t: any) => {
								filterBy(setSort(t.target.value));
							}}
						>
							<option value={0}>Ordenar Por</option>
							<option value={1}>Alfabetico</option>
							<option value={4}>No Resueltas</option>
							{languages.map((lang, i) => {
								if (wordsBackup.filter((w) => w.idioma.idioma === lang).length > 0) {
									return (
										<option value={lang} key={"lang-option-filter-" + i}>
											{capitalizeFirstLetter(getLangSpanishSimple(lang))}
										</option>
									);
								}
							})}
						</select>
						<div className="search-container">
							<img src={LENLogo} alt="" />
							<input type="text" placeholder={input_buscar_terminos} onChange={(e: any) => filterBy(setText(e.target.value))} />
						</div>
					</div>
					<button
						onClick={() => {
							setModal(!modal);
							setWord({ seminario: parseInt(seminaryNum) });
							setUpdate(false);
						}}
					>
						Añadir palabra
					</button>
				</div>
				<NotesModal
					show={notesModal.show}
					onHide={() => setNotesModal({ ...notesModal, show: false })}
					notes={notesModal.notes}
				/>
				<table className="table">
					<thead>
						<tr>
							<th scope="col">Palabra</th>
							<th scope="col">Traducción</th>
							<th scope="col" className="text-center">
								Dificultad
							</th>
							<th className="text-center" scope="col">
								Idioma
							</th>
							<th scope="col">Categoría</th>
							<th scope="col">Subcategoría</th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{loadingWords ? (
							<tr>
								<td colSpan={8} className="text-center">
									<div className="d-flex align-items-center justify-content-center" style={{ minHeight: "200px" }}>
										<CustomSpinner size={50} height={"auto"} borderSize={4} />
									</div>
								</td>
							</tr>
						) : (
							words.length > 0 && (
								<>
									{words.map((word, index) => {
										return (
											<tr key={"word-" + index}>
												<th>
													{word.bookmark === 1 && (
														<span
															style={{ color: "red", cursor: "pointer" }}
															onClick={() => setNotesModal({ notes: word.notes, show: true })}
														>
															*
														</span>
													)}{" "}
													{word.palabra} {word.palabra_opcional !== null && <span>({word.palabra_opcional})</span>}
												</th>
												<td>
													{word.significado.map((meaning, index) => {
														return meaning + (word.significado.length > 1 && index <= word.significado.length - 2 ? ", " : "");
													})}
												</td>
												<td>
													<div className="difficult-circle" style={{ backgroundColor: getDifficultColor2(word.dificultad) }}>
														<p className="difficult-circle-message" style={word.dificultad !== null ? { display: "none" } : {}}>
															Sin datos de estudio
														</p>
													</div>
												</td>
												<td>
													<p className="row-languages justify-content-center">{returnFlagByLanguage(word.idioma.idioma)}</p>
												</td>
												<td>
													<p>{word.singular_category}</p>
												</td>
												<td>
													{word.sub_categoria !== null &&
														word.sub_categoria.map((subcategory, i) => {
															return <p key={"sub-categoria-" + i}>{subcategory}</p>;
														})}
												</td>
												<td>
													<img
														src={Check}
														alt=""
														onClick={() => {
															editWord(word);
														}}
													/>
												</td>
												<td>
													<img
														src={Trash}
														alt=""
														onClick={() => {
															deleteWordSeminary(word.word_data_id);
														}}
													/>
												</td>
											</tr>
										);
									})}
								</>
							)
						)}
					</tbody>
				</table>
			</div>
			<Modal className="seminary-word-modal" show={modal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Nueva Palabra</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h6>Ingresa estos datos para poder crear la nueva palabra</h6>
					{word.notes !== undefined && word.notes.length > 0 && (
						<div>
							<label htmlFor="" className="m-0 font-weight-bold">
								Comentarios:
							</label>
							<p className="m-0">
								<span className="text-danger">*</span> {word.notes}
							</p>
						</div>
					)}
					<div className="modal-form">
						<div className="modal-row">
							<div className="modal-col">
								<label>Palabra en lengua origen</label>
								<input
									defaultValue={word.palabra}
									onChange={(e) => {
										setWord((word) => ({ ...word, palabra: e.target.value }));
									}}
									type="text"
									placeholder={input_palabra_original}
								/>
							</div>
							<div className="modal-col">
								<label>Traducción al español</label>
								<input
									value={word.significado}
									onChange={(e) => {
										setWord((word) => ({
											...word,
											significado: e.target.value,
										}));
									}}
									type="text"
									placeholder={input_palabra_en_español}
								/>
								<legend className="mt-2">El carácter | es el separador de palabras.</legend>
							</div>
						</div>
						<div className="modal-row">
							<div className="modal-col">
								{word.idioma !== undefined && (word.idioma.id === 6 || word.idioma.id === 3) && (
									<>
										<label htmlFor="">Carácter</label>
										<input
											type="text"
											placeholder={word.idioma.id === 3 ? Kanji : Kanzi}
											defaultValue={word.palabra_opcional}
											onChange={(e) => {
												setWord((word) => ({
													...word,
													palabra_opcional: e.target.value,
												}));
											}}
										/>
									</>
								)}
							</div>
							<div className="modal-col"></div>
						</div>
						<div className="modal-row">
							<div className="modal-col">
								<label>Categoría</label>
								<select
									value={word.categories_ids}
									onChange={(e) => {
										setWordCategory(e);
									}}
								>
									<option value="">Seleccionar categoría</option>
									{categories.map((category, index) => {
										if (
											category.singular !== "" &&
											((parseInt(seminaryNum) > 0 && category.zero === 0) || parseInt(seminaryNum) === 0)
										) {
											return (
												<option value={category.id} key={"category-" + index}>
													{category.singular}
												</option>
											);
										}
									})}
								</select>
							</div>
							<div className="modal-col">
								<label className="d-flex w-100 justify-content-between">
									Subcategoría
									<a style={{ cursor: "pointer" }} onClick={() => setWord({ ...word, subcategories_ids: [] })}>
										Limpiar
									</a>
								</label>
								<select
									value={word.subcategories_ids}
									size={5}
									multiple
									onChange={(e) => {
										setWordSubcategory(e);
									}}
								>
									<option value="">Seleccionar Subcategoría</option>
									{subcategories.map((subcategory, index) => {
										return (
											<option value={subcategory.id} key={"subcategory-" + index}>
												{subcategory.sub_family}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className="modal-row">
							<div className="modal-col">
								<label>Idioma</label>
								<select
									value={word.idioma?.id}
									onChange={(e) => {
										setWord((word) => ({ ...word, idioma: { id: parseInt(e.target.value) } }));
									}}
								>
									<option value="">Seleccionar Idioma</option>
									<option value="1">Francés</option>
									<option value="2">Alemán</option>
									<option value="3">Japonés</option>
									<option value="4">Italiano</option>
									<option value="5">Ruso</option>
									<option value="6">Chino</option>
									<option value="7">Portugués</option>
									<option value="8">Inglés</option>
								</select>
							</div>
							<div className="modal-col">
								<label>Aparece en material</label>
								<select
									value={word.paper}
									onChange={(e) => {
										setWord((word) => ({
											...word,
											paper: e.target.value,
										}));
									}}
								>
									<option value="Seleccionar opción"></option>
									<option value={1}>Si</option>
									<option value={0}>No</option>
								</select>
							</div>
						</div>
						<div className="modal-row">
							<div className="modal-col">
								<label>Nota</label>
								<div className="warning-note-inputs-container">
									{returnWarningTypeImage(warningType)}
									<select
										className="form-control"
										value={warningType}
										onChange={(e) => setWarningType(e.target.value)}
										name="warningType"
										id="warningType"
									>
										<option value="advertencia">Advertencia</option>
										<option value="memoria">Memoria</option>
										<option value="gramatica">Gramática</option>
										<option value="consejo">Consejo</option>
										<option value="falso_amigo">Falso amigo</option>
										<option value="similitud">Similitud</option>
										<option value="pronunciacion">Pronunciación</option>
										<option value="sabias_que">¿Sabías que...?</option>
									</select>
								</div>
								<div>
									{warn.map((val: any, index: number) => {
										if (val === warningType) {
											return (
												<ReactQuill
													modules={{ toolbar: toolbarOptions }}
													theme="snow"
													defaultValue={word[warningType]}
													onChange={setValue}
													key={"textQuill " + index}
												/>
											);
										}
									})}
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="buttons">
						<button className="close" onClick={handleClose}>
							Cerrar
						</button>
						{!update && (
							<button className="continue" onClick={addWord}>
								Continuar
							</button>
						)}
						{update && (
							<button className="continue" onClick={updateWordSeminary}>
								Editar
							</button>
						)}
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default VocabularySeminaryContainer;
