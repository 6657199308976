import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./LinksHistoryModal.scss";
import useLinksHistory from "@hooks/LinkHistory/useLinksHistory";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { AiOutlineCopy } from "react-icons/ai";

interface LinksHistoryModalProps {
	onHide: () => void;
	show: boolean;
	db: any;
	genId: string;
	materialId: string;
}

function LinksHistoryModal({ onHide, show, db, genId, materialId }: LinksHistoryModalProps) {
	const { linksHistory, getMaterialLinksHistory, setLinksHistory } = useLinksHistory();
	const [copySuccess, setCopySuccess] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [isCopyClicked, setIsCopyClicked] = useState(false);

	const itemsPerPage = 3;
	const maxPage = Math.ceil(linksHistory.length / itemsPerPage);

	useEffect(() => {
		// Call the function to get the material links history
		const unsubscribe = getMaterialLinksHistory(db, genId, materialId);

		// Cleanup function
		return () => {
			unsubscribe();
			setLinksHistory([]); // Clear the links history state
		};
	}, [db, genId, materialId]);

	const currentData = () => {
		const begin = (currentPage - 1) * itemsPerPage;
		const end = begin + itemsPerPage;
		return linksHistory.slice(begin, end);
	};

	const nextPage = () => {
		setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
	};

	const prevPage = () => {
		setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
	};

	function getPartName(value) {
		switch (value) {
			case "videoUrl":
				return "Parte 1";
				break;
			case "videoUrl12":
				return "Parte 2";
				break;
			case "videoUrl13":
				return "Parte 3";
				case "videoUrl2":
					return "Youtube";
				break;
		}
	}

	return (
		<Modal
			className="linksHistoryModal"
			size="lg"
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Historial de Links</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h4>Total de links: {linksHistory.length}</h4>
				{linksHistory.length > 0 && <p>Se muestran ordenados por fecha</p>}

				<div className="pagination">
					<BsArrowLeft
						className={`pagination-icon ${currentPage === 1 ? "disabled" : ""}`}
						onClick={() => currentPage !== 1 && prevPage()}
					/>
					<BsArrowRight
						className={`pagination-icon ${currentPage === maxPage ? "disabled" : ""}`}
						onClick={() => currentPage !== maxPage && nextPage()}
					/>
				</div>
				<div className="linkHistoryContainer">
					{linksHistory.length <= 0 ? (
						<h1>Aun no hay links previos</h1>
					) : (
						currentData().map((data, index) => {
							return (
								<div className="linkHistoryCard" key={index}>
									<p>
										<strong>Parte previa asignada: </strong>
										{getPartName(data.videoPart)}
									</p>

									<LinkHistoryCard data={data} key={index} />
									<br></br>
									<p>
										<strong>Fecha de sobrescritura: </strong>
										{/* Assuming that lastChange is a Date object */}
										{data.lastChange?.toDate().toLocaleString()}
									</p>
								</div>
							);
						})
					)}
				</div>
			</Modal.Body>

			<Modal.Footer>
				<button onClick={onHide}>Close</button>
			</Modal.Footer>
		</Modal>
	);
}

function LinkHistoryCard({ data }) {
	const [copySuccess, setCopySuccess] = useState("");

	function handleCopy() {
		setCopySuccess("¡Copiado!");
		setTimeout(() => {
			setCopySuccess("");
		}, 2000);
	}

	return (
		<div className="footerLink">
			<h3>{data.previousLink}</h3>
			<CopyToClipboard text={data.previousLink} onCopy={handleCopy}>
				{copySuccess ? (
					<p className={copySuccess ? "copySuccess active" : "copySuccess"}>{copySuccess}</p>
				) : (
					<AiOutlineCopy className="AiOutlineCopy" size="1.5em" />
				)}
			</CopyToClipboard>
		</div>
	);
}

export default LinksHistoryModal;
