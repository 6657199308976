import { CardIcon } from "assets/Imports";
import { CardI } from "interfaces/Conekta/Conekta";

type CardContainerProps = {
	card: CardI;
	handleClick: () => void;
};

function CardContainer({ card, handleClick }: CardContainerProps) {
	return (
		<>
			<div className="card-container">
				<div style={{ width: 50, height: 50, marginRight: 20 }}>
					<img style={{ width: "100%" }} src={CardIcon} />
				</div>
				<div>
					<p style={{ marginBottom: 0, color: "#CA5050" }}>
						{card.brand.toUpperCase()} terminada en {card.last4}
					</p>
					<p>
						Exp {card.exp_month}/{card.exp_year}
					</p>
				</div>
				<div className="btn-olvidar-container">
					<button className="tertiary" onClick={handleClick}>
						Olvidar
					</button>
				</div>
			</div>
		</>
	);
}

export default CardContainer;
