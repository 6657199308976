import "./PronunciationStyles.scss";
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import axios from "axios";
import { languagesCodes } from "functions/Pronunciation/Pronunciation";
import { returnFlag, returnFlagByLanguage } from "functions/Functions";

function Pronunciation(props) {

    const [word, setWord] = useState<string>("");
    const [lang, setLang] = useState<string>("none");
    const [key, setKey] = useState<string>("");
    const [audio, setAudio] = useState<string>("");
    const [loadAudio, setLoadAudio] = useState<boolean>(false);

    const getKey = async () => {
        let tryKey = await firebase.functions().httpsCallable("getAudio");
        tryKey().then((res) => {
            setKey(res.data);
        })
    }

    const pronunciateWord = async () => {
        if (word.length === 0 || lang === "none") {
            let errors = (word.length === 0 ? "Escribe un término.\n" : "") + (lang === "none" ? "Selecciona un idioma." : "");

            alert(errors);
        } else {
            setLoadAudio(true);
            if (audio === "") {
                axios.post(`https://texttospeech.googleapis.com/v1beta1/text:synthesize`, {
                    "audioConfig": {
                        "audioEncoding": "MP3",
                        "pitch": 0,
                        "speakingRate": 1
                    },
                    "input": {
                        "text": `${word}`
                    },
                    "voice": {
                        "languageCode": `${languagesCodes[lang].langCode}`,
                        "name": `${languagesCodes[lang].name}`
                    }
                }, {
                    headers: {
                        "Authorization": `Bearer ${key}`,
                        "X-Goog-User-Project": 'academia-de-lenguas-f6f35',
                        "Content-Type": 'application/json'
                    }
                }).then((res) => {
                    setAudio(res.data.audioContent);
                    setLoadAudio(false);
                });
            } else {
                setLoadAudio(false);
                let audio: any = document.getElementById('listen');
                if (audio !== null) {
                    audio.play();
                };
            }
        }
    }

    useEffect(() => {
        setAudio("");
    }, [lang, word])

    useEffect(() => {
        let audio: any = document.getElementById('listen');
        if (audio !== null) {
            audio.play();
        };
    }, [audio])

    useEffect(() => {
        getKey();
    }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pronunciation-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Pronunciación
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="pronunciation-input-container">
                        <label htmlFor="pronunciation-word">Escriba el término a pronunciar:</label>
                        <div>
                            <input id="pronunciation-word" name="pronunciation-word" type="text" value={word} onChange={(e) => setWord(e.target.value)} />
                        </div>
                    </div>
                    <div className="pronunciation-input-container">
                        <label htmlFor="pronunciation-word-lang">Selecciona el idioma:</label>
                        <div>
                            {returnFlagByLanguage(lang)}
                            <select id="pronunciation-word-lang" name="pronunciation-word-lang" value={lang} onChange={(e) => setLang(e.target.value)}>
                                <option value="none">Selecciona un idioma</option>
                                <option value="spanish">Español</option>
                                <option value="french">Francés</option>
                                <option value="german">Alemán</option>
                                <option value="japanese">Japonés</option>
                                <option value="chinese">Chino</option>
                                <option value="russian">Ruso</option>
                                <option value="portuguese">Portugués</option>
                                <option value="italian">Italiano</option>
                                <option value="english">Inglés</option>
                            </select>
                        </div>
                    </div>
                    {audio.length > 0 && (
                        <audio style={{ position: 'absolute', zIndex: -100, opacity: 0 }} id="listen" controls >
                            <source src={"data:audio/mp3;base64," + audio} />
                        </audio>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button className="close-modal-pronunciation" onClick={props.onHide}>Cerrar</button>
                    {!loadAudio ? (
                        <button className="pronunciate-word" onClick={pronunciateWord}>Pronunciar</button>
                    ) : (
                        <div className="text-center" style={{ width: 130, height: 55 }}>
                            <div className="spinner-border text-danger text-center" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Pronunciation;