import { Container } from 'react-bootstrap';
import CheckoutForm from './PaymentForm/CheckoutForm';
import { useLocation } from "react-router-dom";
import './styles.scss';

function CheckoutContainer() {
    const location = useLocation<any>();
    const client = {
        name: "inowu testing",
        email: "inowudev@gmail.com",
        phone: "5577774444"
    };
    const course = {
        nombre: 'testing inowu',
        precio: 300
    };
    const msi = {
        msi: 0
    };

    if(course.nombre == "Promoción 35 Aniversario"){
        msi.msi = 6;
    } else {
        msi.msi = 3;
    }


    return (
        <div className="header__checkout checkout-container-component">
                <CheckoutForm {...client} {...course} {...msi} />
        </div>
    );
}

export default CheckoutContainer;