import { Container } from 'react-bootstrap';
import PaymentForm from './PaymentForm/PaymentForm';
import './styles.scss';

function PaymentContainer() {
  return (
    <div className="header__payment" style={{marginBottom: 80}}>
      <Container>
        <PaymentForm />
      </Container>
    </div>
  );
}

export default PaymentContainer;
