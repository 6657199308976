import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { languagesArrays, quizWords, Quizzes, segmentsQuiz } from "interfaces/Interfaces";
import { db } from "initFirebase";
import {
	AdviceIcon,
	ArrowDoubleHorizontal,
	BlueCircleIcon,
	Idea,
	GrammarChineseIcon,
	WarningIcon,
	WarningRedIcon,
	PronunciatinoIcon,
} from "assets/Imports";

export function toPlural(word: string) {
	if (word[word.length - 1] !== "n") {
		if (word.indexOf("í") !== -1) {
			return word.normalize("NFD") + "s";
		} else {
			return word.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + "s";
		}
	} else {
		if (word.indexOf("í") !== -1) {
			return word.normalize("NFD") + "s";
		} else {
			return word.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + "es";
		}
	}
}

export async function repeatQuiz(quiz: any, segmentsArray: segmentsQuiz, incorrects?: boolean) {
	const quizRef = doc(db, "quiz", quiz.id);

	return await updateDoc(quizRef, {
		answerType: quiz.answerType,
		difficult: quiz.difficult,
		quizWords: segmentsArray,
		userId: quiz.userId,
		name: quiz.name + (incorrects !== undefined && !quiz.name.includes("Repaso") ? " - Repaso de incorrectas" : ""),
		seminaryNum: quiz.seminaryNum,
		correctWords: new Array(),
		incorrectWords: new Array(),
		index: 0,
		actualSegment: 0,
		finished: false,
		createdAt: new Date(),
		finishedAt: null,
		timeToAnswer: 0,
		errorsCounter: 0,
		acerteClicks: 0,
		errorsWords: new Array(),
		dominatedWords: new Array(),
	}).then(() => {
		return quiz.id;
	});
}

export function getLangQuizzesWords(language: string, languagesArray: languagesArrays) {
	switch (language) {
		case "french":
			return languagesArray.french;
			break;
		case "japanese":
			return languagesArray.japanese;
			break;
		case "chinese":
			return languagesArray.chinese;
			break;
		case "english":
			return languagesArray.english;
			break;
		case "german":
			return languagesArray.german;
			break;
		case "italian":
			return languagesArray.italian;
			break;
		case "russian":
			return languagesArray.russian;
			break;
		case "portuguese":
			return languagesArray.portuguese;
			break;

		default:
			break;
	}
}

export function getSegmentsArray(words: Array<any>) {
	const segments: any = {
		0: [],
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
		7: [],
	};

	words.map((word, index) => {
		if (word.timesSeen === undefined) {
			word.timesSeen = 1;
		}

		word.times_answered = 0;
		word.times_correct_answer = 0;
	});

	for (let i = 0; i < 8; i++) {
		for (let j = 0; j < 8; j++) {
			if (words.length > 0) {
				segments[i].push(words.pop());
			}
		}
	}

	return segments;
}

export function blockDate(date: string) {
	const date1 = new Date();
	const date2 = new Date();
	const date3 = new Date();

	date1.setUTCMonth(10);
	date1.setUTCDate(1);
	date1.setUTCFullYear(2022);
	date1.setUTCHours(0, 0, 0);

	date2.setUTCMonth(9);
	date2.setUTCDate(31);
	date2.setUTCFullYear(2022);
	date2.setUTCHours(15, 0, 0);

	date3.setUTCMonth(9);
	date3.setUTCDate(23);
	date3.setUTCFullYear(2022);
	date3.setUTCHours(5, 59, 59);

	const dates = {
		date1: date1,
		date2: date2,
		date3: date3,
	};

	return dates[date];
}

export function returnWarningTypeImage(warningType: string) {
	switch (warningType) {
		case "warning":
			return <img className="warning-type-image" src={WarningIcon} alt="icono de advertencia" />;
			break;
		case "advertencia":
			return <img className="warning-type-image" src={WarningIcon} alt="icono de advertencia" />;
			break;
		case "memory":
			return <img className="warning-type-image" src={BlueCircleIcon} alt="imagen de un circulo azul" />;
			break;
		case "memoria":
			return <img className="warning-type-image" src={BlueCircleIcon} alt="imagen de un circulo azul" />;
			break;
		case "advice":
			return <img className="warning-type-image" src={AdviceIcon} alt="icono de una carita guiñando el ojo derecho" />;
			break;
		case "consejo":
			return <img className="warning-type-image" src={AdviceIcon} alt="icono de una carita guiñando el ojo derecho" />;
			break;
		case "grammar":
			return <img className="warning-type-image" src={GrammarChineseIcon} alt="imagen de caracteres chinos" />;
			break;
		case "gramatica":
			return <img className="warning-type-image" src={GrammarChineseIcon} alt="imagen de caracteres chinos" />;
			break;
		case "fake_friend":
			return <img className="warning-type-image" src={WarningRedIcon} alt="imagen de caracteres chinos" />;
			break;
		case "falso_amigo":
			return <img className="warning-type-image" src={WarningRedIcon} alt="imagen de caracteres chinos" />;
			break;
		case "similarity":
			return <img className="warning-type-image" src={ArrowDoubleHorizontal} alt="imagen de caracteres chinos" />;
			break;
		case "similitud":
			return <img className="warning-type-image" src={ArrowDoubleHorizontal} alt="imagen de caracteres chinos" />;
			break;
		case "did_YouKnow" || "sabias_que":
			return <img className="warning-type-image" src={Idea} alt="imagen de caracteres chinos" />;
			break;
			case "sabias_que":
			return <img className="warning-type-image" src={Idea} alt="imagen de caracteres chinos" />;
			break;
		case "pronunciation" || "pronunciacion":
			return <img className="warning-type-image" src={PronunciatinoIcon} alt="imagen de caracteres chinos" />;
			break;
			case "pronunciacion":
			return <img className="warning-type-image" src={PronunciatinoIcon} alt="imagen de caracteres chinos" />;
			break;
		default:
			break;
	}
}

export function returnWarningTypeTitle(warningType: string) {
	switch (warningType) {
		case "warning":
			return "Advertencia";
			break;
		case "memory":
			return "Memoria";
			break;
		case "advice":
			return "Consejo";
			break;
		case "grammar":
			return "Gramática";
			break;
		case "fake_friend":
			return "Falso amigo";
			break;
		case "similarity":
			return "Similitud";
			break;
		case "pronunciation":
			return "Pronunciación";
			break;
		case "did_YouKnow":
			return "¿Sabías que...?";
			break;
		default:
			break;
	}
}

export function returnWarningBorderColor(warningType: string) {
	switch (warningType) {
		case "warning":
			return "#ffe600";
			break;
		case "memory":
			return "#1193f1";
			break;
		case "advice":
			return "rgba(0, 64, 255, 0.5)";
			break;
		case "grammar":
			return "rgba(255, 0, 0, 0.5)";
			break;
		case "fake_friend":
			return "#ff0000";
			break;
		case "pronunciation":
			return "#CA5050";
			break;

		case "did_YouKnow":
			return "#229954";
			break;
		default:
			return "#1193f1";
			break;
	}
}
