import { WordRowCard } from "@components/Vocabulary/WordRowCard/WordRowCard";
import { Modal } from "react-bootstrap";
import { getWord } from "../functions/TestFunctions";
import "./WordsModalStyles.scss";
import { useEffect, useState } from "react";

export function WordsModal(props) {
	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="see-words-corrects-incorrects-modal-test"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Términos {props.seeWords.type}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{props.seeWords.words.length > 0 ? (
					props.seeWords.words.map((word, index) => {
						return (
							<WordRowCard
								word={word}
								language={props.quiz.language}
								answerType={props.quiz.answerType}
								index={index}
								key={"word-middle-screen-" + index}
								countRefresh={props.countRefresh}
								setCountRefresh={props.setCountRefresh}
							/>
							// <p key={"word-modal-" + index}>{getWord(props.quizType, word)}</p>
						);
					})
				) : (
					<h2>No hay términos {props.seeWords.type}</h2>
				)}
			</Modal.Body>
			<Modal.Footer>
				<button className="tertiary" onClick={props.onHide}>
					Cerrar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
