import { Faq1, Faq3, Faq4, Faq5, InPersonClassFAQicon, Logo, WaveOther } from "assets/Imports";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CardItem from "./CardItem/CardItem";
import "./styles.scss";


function FAQContainer() {
  return (
    <>
      <div className="header__FAQ">
        <Container>
          <h1>Preguntas Frecuentes (FAQ)</h1>
          <p>
            ¿Tienes alguna duda? A continuación se encuentran las respuestas a las preguntas más frecuentes sobre el proceso de admisión, desarrollo y
            metodología del programa, modalidades de pago y mucho más.
          </p>
          <p>
            ¡Siéntete libre de explorar! Si no encuentras la respuesta que buscabas, no dudes en{" "}
            <Link to="/contacto" className="text-white" style={{ textDecoration: "underline" }}>
              contactar con nosotros.
            </Link>
          </p>
        </Container>
      </div>
      <div className="wave_container">
        <img src={WaveOther} alt="Logo" className="wave" />
      </div>
      <div className="section__FAQ">
        <Container>
          <Row>
            <Col lg={1} xs={0}></Col>
            <Col lg={5} xs={12}>
              <Link to={`/dudas/1`}>
                <CardItem name="Generales" img={Faq1} />
              </Link>
            </Col>
            <Col lg={5} xs={12}>
              <Link to={`/dudas/2`}>
                <CardItem name="Inscripción y métodos de pago" img={Faq4} />
              </Link>
            </Col>
            <Col lg={1} xs={0}></Col>
            <Col lg={1} xs={0}></Col>
            <Col lg={5} xs={12}>
              <Link to={`/dudas/3`}>
                <CardItem name="En línea" img={Faq3} />
              </Link>
            </Col>
            <Col lg={5} xs={12}>
              <Link to={`/dudas/4`}>
                <CardItem name="Presencial" img={InPersonClassFAQicon} />
              </Link>
            </Col>
            <Col lg={1} xs={0}></Col>
            <Col lg={1} xs={0}></Col>
            <Col lg={5} xs={12} className="text-center">
              <Link to={`/contacto`}>
                <CardItem name="Contacto y redes sociales" img={Faq5} />
              </Link>
            </Col>
            <Col lg={5} xs={12} className="text-center">
              <Link to={`/dudas/6`}>
                <CardItem name="Plataforma de aprendizaje" img={Logo} />
              </Link>
            </Col>
            <Col lg={2} xs={0}></Col>

            {/* <Col lg={1} xs={0}></Col>
            <Col lg={5} xs={12} className="text-center">
              <Link to={`/contacto`}>
                <CardItem name="Contacto y redes sociales" img="faq5" />
              </Link>
            </Col>
            <Col lg={5} xs={12} className="text-center">
              <Link to={`/dudas/5`}>
                <CardItem name="Niños" img="faq6" />
              </Link>
            </Col>
            <Col lg={1} xs={0}></Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default FAQContainer;
