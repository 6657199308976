import './CalificationsStyles.scss';
import starImg from '../screenIMG/star-android.svg';
import starHalfImg from '../screenIMG/star-half.svg';
import arrowImg from '../screenIMG/arrow_back.svg';
import { Link } from 'react-router-dom';

function Califications({ OS }) {
    return (
        <div className='calfications-mobile-about-app-container'>
            <div className='title-container'>
                <h2>Reseñas</h2>
                <Link to="/testimonios">
                    <img src={arrowImg} alt="flecha" />
                </Link>
            </div>
            <Link to="/testimonios">
                <div className='content-container'>
                    <div className='calification-container'>
                        <h2 className='calification-number'>4.5</h2>
                        <div className='stars-container'>
                            <img src={starImg} alt="estrella" className={'' + (OS === "ios" ? 'ios-stars' : '')} />
                            <img src={starImg} alt="estrella" className={'' + (OS === "ios" ? 'ios-stars' : '')} />
                            <img src={starImg} alt="estrella" className={'' + (OS === "ios" ? 'ios-stars' : '')} />
                            <img src={starImg} alt="estrella" className={'' + (OS === "ios" ? 'ios-stars' : '')} />
                            <img src={starHalfImg} alt="estrella" className={'' + (OS === "ios" ? 'ios-stars' : '')} />
                        </div>
                        <h3 className='quantity-califications'>98</h3>
                    </div>
                    <div className='califications-bars-container'>
                        <div className='calification-bar-container'>
                            <p className='number'>5</p>
                            <div className='calification-bar'>
                                <div className={'calification-bar-progress' + (OS === "ios" ? " blue-color" : "")} style={{ width: '90%' }}>

                                </div>
                            </div>
                        </div>
                        <div className='calification-bar-container'>
                            <p className='number'>4</p>
                            <div className='calification-bar'>
                                <div className={'calification-bar-progress' + (OS === "ios" ? " blue-color" : "")} style={{ width: '7%' }}>

                                </div>
                            </div>
                        </div>
                        <div className='calification-bar-container'>
                            <p className='number'>3</p>
                            <div className='calification-bar'>
                                <div className={'calification-bar-progress' + (OS === "ios" ? " blue-color" : "")} style={{ width: '2%' }}>

                                </div>
                            </div>
                        </div>
                        <div className='calification-bar-container'>
                            <p className='number'>2</p>
                            <div className='calification-bar'>
                                <div className={'calification-bar-progress' + (OS === "ios" ? " blue-color" : "")} style={{ width: '1%' }}>

                                </div>
                            </div>
                        </div>
                        <div className='calification-bar-container'>
                            <p className='number'>1</p>
                            <div className='calification-bar'>
                                <div className={'calification-bar-progress' + (OS === "ios" ? " blue-color" : "")} style={{ width: '0%' }}>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default Califications;