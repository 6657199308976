export type Prices = {
    // Pago por clase/seminario regular
    regularClass: number,
    // Pago por clase/seminario promo
    promoClass: number
};

export enum PricesType {
    RegularClass = "regularClass",
    PromoClass = "promoClass"
}

export enum PricesModality {
    // Precios en modalidades en línea
    Online = "online",
    // Precios para modalidad en persona
    InPerson = "in-person"
}

// Enum para divisas
export enum Currency {
	Mxn = "MXN",
	Usd = "USD",
}

export type ModalitiesPrices = {
    "online": Prices,
    "in-person": Prices
}

export type AllPrices = {
    "MXN": ModalitiesPrices,
    "USD": ModalitiesPrices
}

export const initialValues: AllPrices = {
    "MXN": {
        "online": {
            regularClass: 0,
            promoClass: 0
        },
        "in-person": {
            regularClass: 0,
            promoClass: 0
        }
    },
    "USD": {
        "online": {
            regularClass: 0,
            promoClass: 0
        },
        "in-person": {
            regularClass: 0,
            promoClass: 0
        }
    }
};