import {
	AllPrices,
	initialValues,
} from "@containers/Admin/DynamicTextContainer/components/Prices/PricesTypes";
import { getUserCountryByIP } from "@utils/utilFunctions";
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { fetchAllPrices } from "@containers/Admin/DynamicTextContainer/components/Prices/PricesService";
import { useAuth } from "@hooks/useAuth";

type CurrencyContextProps = {
	children: React.ReactNode;
};

type CurrencyContext = {
	currencyCountry: string;
	setCurrencyCountry: Dispatch<SetStateAction<string>>;
	prices: AllPrices;
	userCountry: string;
};

export const CurrencyContext = createContext<CurrencyContext | null>(null);

export default function CurrencyContextProvider({ children }: CurrencyContextProps) {
	const [currencyCountry, setCurrencyCountry] = useState<string>("mx");
	const [userCountry, setUserCountry] = useState<string>("");
	const [prices, setPrices] = useState<AllPrices>(initialValues);
	const { user } = useAuth();

	const getCountry = async () => {
		const countryCode = (await getUserCountryByIP()).toLowerCase();
		if (user) {
			setCurrencyCountry("mx");
		} else {
			setCurrencyCountry(countryCode === "mx" ? "mx" : "us");
		}
	};

	const getuserCountry = async () => {
		const countryCode = (await getUserCountryByIP()).toLowerCase();
		setUserCountry(countryCode);
	};

	const getPrices = async () => {
		const pricesResponse = await fetchAllPrices();
		if (pricesResponse != null) {
			setPrices(pricesResponse);
		}
	};

	useEffect(() => {
		if (user) {
			setCurrencyCountry("mx");
		}
	}, [user]);

	useEffect(() => {
		getCountry();
		getPrices();
		getuserCountry();
	}, []);

	return (
		<CurrencyContext.Provider value={{ currencyCountry, setCurrencyCountry, prices, userCountry }}>
			{children}
		</CurrencyContext.Provider>
	);
}

export function useCurrencyContext() {
	const context = useContext(CurrencyContext);
	if (!context) {
		throw new Error("useCurrencyContext debe usarse dentro de CurrencyContextProvider");
	}
	return context;
}
