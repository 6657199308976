import { collection, doc, getDoc, getDocs, writeBatch } from "firebase/firestore";
import { db } from "initFirebase";
import { ModalitiesPrices, Currency, Prices, PricesModality, PricesType, AllPrices } from "./PricesTypes";

const MISCELLANEOUS_COLLECTION = "miscellaneous";
const PRICES = "prices";
const PRICES_FB_DOC_REF = doc(db, MISCELLANEOUS_COLLECTION, PRICES);

// Esta funcion toma como argumento la divisa de la cual se hara fetch de precios y el tipo de modalidad
export const fetchPricesByCurrencyAndModality = async (currency: Currency, modality: PricesModality): Promise<Prices | null> => {
	try {
		const pricesRef = doc(PRICES_FB_DOC_REF, currency, modality);
		const docSnap = await getDoc(pricesRef);
		if (docSnap.exists()) {
			return docSnap.data() as Prices;
		} else {
			return null;
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

// Esta funcion regresa todos los precios, de todas las divisas un objeto de tipo [AllPrices]
export const fetchAllPrices = async () => {
	try {
		const pricesMxnCollectionRef = collection(PRICES_FB_DOC_REF, Currency.Mxn);
		const pricesUsdCollectionRef = collection(PRICES_FB_DOC_REF, Currency.Usd);

		const pricesMxnSnapshot = await getDocs(pricesMxnCollectionRef);
		const pricesUsdSnapshot = await getDocs(pricesUsdCollectionRef);

		if (!pricesMxnSnapshot.empty && !pricesUsdSnapshot.empty) {
			const pricesMxn: ModalitiesPrices = {
				online: pricesMxnSnapshot.docs.filter((obj) => obj.id === PricesModality.Online)[0].data() as Prices,
				"in-person": pricesMxnSnapshot.docs.filter((obj) => obj.id === PricesModality.InPerson)[0].data() as Prices,
			};
			const pricesUsd: ModalitiesPrices = {
				online: pricesUsdSnapshot.docs.filter((obj) => obj.id === PricesModality.Online)[0].data() as Prices,
				"in-person": pricesUsdSnapshot.docs.filter((obj) => obj.id === PricesModality.InPerson)[0].data() as Prices,
			};
			const prices: AllPrices = { MXN: pricesMxn, USD: pricesUsd };
			return prices;
		}
		return null;
	} catch (error) {
		console.error(error);
		return null;
	}
};

// Funcion para actualizar todos los precios de golpe (en linea y presenciales)
export const updatePrices = async (currency: Currency, prices: ModalitiesPrices): Promise<{ message: string; code: number }> => {
	try {
		const batch = writeBatch(db);

		const inPersonDocRef = doc(PRICES_FB_DOC_REF, currency, Object.keys(prices)[0]);
		const onlineDocRef = doc(PRICES_FB_DOC_REF, currency, Object.keys(prices)[1]);

		batch.update(inPersonDocRef, prices["in-person"]);
		batch.update(onlineDocRef, prices.online);

		await batch.commit();

		return { message: "Precios actualizados con exito", code: 200 };
	} catch (error: any) {
		return { message: error.message, code: 400 };
	}
};
