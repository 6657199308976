import { capitalizeFirstLetter } from "functions/Functions";
import { useEffect, useState } from "react";
import { difficults, getDifficultColor, getDifficultString, returnSubCategories, sortNativeWord } from "../functions/functions";
import { Scroll } from "react-scroll-component";
import parse from "html-react-parser";
import { ArrowDoubleHorizontal, ScrollArrows, ChevronDownIcon } from "assets/Imports";

const dominationFilter = ["dominated", "notDominated", "news"];

export function CategoryCard(props) {
	const [subCategorySelected, setSubCategorySelected] = useState<string>("all");
	const [selectAll, setSelectAll] = useState<boolean>(true);
	const [hasScroll, setHasScroll] = useState<boolean>(false);
	const [hasScrollUp, setHasScrollUp] = useState<boolean>(false);
	const [fullScroll, setFullScroll] = useState<number>(0);
	const [wordsArray, setWordsArray] = useState<Array<any>>([]);
	const [holding, setHolding] = useState<boolean>(false);
	const [holdingUp, setHoldingUp] = useState<boolean>(false);
	const [difficultSelected, setDifficultSelected] = useState<Array<string>>(difficults);
	const [open, setOpen] = useState<boolean>(true);

	const config = {
		direction: "vertical",
	};

	const showCard = () => {
		setOpen(!open);
	};
	const handleSelect = (e) => {
		setSelectAll(e.target.checked);
		props.handleSelectAll(e, props.cat);
	};

	const handleDifficultFilter = (difficult: string) => {
		const tempDifficultArray: Array<string> = JSON.parse(JSON.stringify(difficultSelected));
		const index = difficultSelected.findIndex((e) => e === difficult);

		if (index !== -1) {
			tempDifficultArray.splice(index, 1);
			setDifficultSelected(tempDifficultArray);
		} else {
			tempDifficultArray.push(difficult);
			setDifficultSelected(tempDifficultArray);
		}
	};

	const filterWords = (subCategory) => {
		return props.langSelected.langArrayBase.filter((e) => {
			const category = e.category ? e.category : e.categoria;
			if (!category.includes(props.cat)) return false;

			switch (subCategory) {
				case "dominated":
					return e.dominated === 3;
				case "notDominated":
					return e.dominated !== 3;
				default:
					return e.dominated === null;
			}
		});
	};

	useEffect(() => {
		if (props.langSelected.langArrayBase) {
			let totalCat = 0;

			try {
				props.langSelected.langArrayBase
					.filter(
						(e) =>
							(subCategorySelected === "all" && (e.category ? e.category : e.categoria).includes(props.cat)) ||
							(subCategorySelected !== "all" &&
								(e.category ? e.category : e.categoria).includes(props.cat) &&
								(e.sub_category ? e.sub_category : e.sub_categoria).includes(subCategorySelected))
					)
					.sort(sortNativeWord)
					.map((word) => {
						if (props.langSelected.langArray?.findIndex((e) => e.id === word.id) !== -1) {
							totalCat++;
						}
					});

				const totalCatWords = props.langSelected.langArrayBase
					.filter(
						(e) =>
							(subCategorySelected === "all" && (e.category ? e.category : e.categoria).includes(props.cat)) ||
							(subCategorySelected !== "all" &&
								(e.category ? e.category : e.categoria).includes(props.cat) &&
								(e.sub_category ? e.sub_category : e.sub_categoria).includes(subCategorySelected))
					)
					.sort(sortNativeWord).length;
				if (totalCat === totalCatWords) {
					setSelectAll(true);
				} else {
					setSelectAll(false);
				}
			} catch (error) {
				console.log(error);
			}
		}
	}, [props.langSelected, subCategorySelected]);

	const scrollDown = (direction: string) => {
		if (direction === "down") {
			setHolding(true);
		} else {
			setHoldingUp(true);
		}
	};

	const handleMouseUp = () => {
		setHolding(false);
		setHoldingUp(false);
	};

	function compareAsc(a, b) {
		if (parseFloat(a.custom_difficulty) > parseFloat(b.custom_difficulty)) {
			return -1;
		}
		if (parseFloat(a.custom_difficulty) < parseFloat(b.custom_difficulty)) {
			return 1;
		}
		return 0;
	}

	function compareDesc(a, b) {
		if (parseFloat(a.custom_difficulty) < parseFloat(b.custom_difficulty)) {
			return -1;
		}
		if (parseFloat(a.custom_difficulty) > parseFloat(b.custom_difficulty)) {
			return 1;
		}
		return 0;
	}

	useEffect(() => {
		let interval;
		if (holding || holdingUp) {
			interval = setInterval(() => {
				const element = window.document.getElementById("category-words-container-" + props.cat);
				if (element !== null) {
					if (holding) {
						element.scroll({ top: element.scrollTop + 15 });
					} else if (holdingUp) {
						element.scroll({ top: element.scrollTop - 15 });
					}
				}
			}, 50);
			return () => {
				clearInterval(interval);
			};
		}
	}, [holding, holdingUp]);

	useEffect(() => {
		const element = window.document.getElementById("category-words-container-" + props.cat);

		const listenScroll = () => {
			if (element !== null) {
				if (element.scrollTop === fullScroll) {
					setHasScroll(false);
					setHolding(false);
				} else if (element.scrollTop < fullScroll) {
					setHasScroll(true);

					if (element.scrollTop > 0) {
						setHasScrollUp(true);
					} else if (element.scrollTop <= 0) {
						setHasScrollUp(false);
						setHoldingUp(false);
					}
				}
			}
		};

		if (element !== null) {
			if (element.scrollHeight > element.offsetHeight) {
				setHasScroll(true);
			} else {
				setHasScroll(false);
				setHasScrollUp(false);
				setFullScroll(element.scrollHeight - element.offsetHeight);
			}
			if (fullScroll > 0 && hasScroll) {
				element.addEventListener("scroll", listenScroll);
			} else {
				setFullScroll(element.scrollHeight - element.offsetHeight);
			}
			return () => {
				element.removeEventListener("scroll", listenScroll);
			};
		}
	}, [fullScroll, wordsArray, open]);

	useEffect(() => {
		if (props.langSelected.langArrayBase !== undefined || subCategorySelected !== "all") {
			if (subCategorySelected === "difficult-asc" || subCategorySelected === "difficult-desc") {
				setWordsArray(
					props.langSelected.langArrayBase
						.filter((e) => {
							const difficult = getDifficultString(e.custom_difficulty);
							if ((e.category ? e.category : e.categoria).includes(props.cat)) {
								if (difficultSelected.includes(difficult)) {
									return true;
								} else {
									return false;
								}
							} else {
								return false;
							}
						})
						.sort(subCategorySelected === "difficult-asc" ? compareAsc : compareDesc)
				);
			} else if (dominationFilter.includes(subCategorySelected)) {
				const filteredWords = filterWords(subCategorySelected);
				setWordsArray(filteredWords);
			} else {
				setDifficultSelected(difficults);
				setWordsArray(
					props.langSelected.langArrayBase
						.filter(
							(e) =>
								(subCategorySelected === "all" && (e.category ? e.category : e.categoria).includes(props.cat)) ||
								(subCategorySelected !== "all" &&
									(e.category ? e.category : e.categoria) !== undefined &&
									(e.sub_category ? e.sub_category : e.sub_categoria) !== undefined &&
									(e.category ? e.category : e.categoria).includes(props.cat) &&
									(e.sub_category ? e.sub_category : e.sub_categoria).includes(subCategorySelected))
						)
						.sort(sortNativeWord)
				);
			}
		}
	}, [props.avaibleCategories, props.langSelected.langArrayBase, subCategorySelected, difficultSelected]);

	if (props.langSelected.langArrayBase === undefined) {
		return <></>;
	}
	return (
		<div className={"category-card " + (!open ? "closed-card" : "")}>
			<div className="header" id="header">
				<p className="number-of-words-category">
					{props.langSelected.langArrayBase?.filter((e) => (e.category ? e.category : e.categoria).includes(props.cat)).length}
				</p>
				<div className="collapse-category-card-container">
					<button onClick={showCard}>
						<img className={"" + (!open ? "closed" : "")} src={ChevronDownIcon} alt="arrow" />
					</button>
				</div>
				<div className="title">
					<h2>{capitalizeFirstLetter(props.cat)}</h2>
				</div>
				<div className="category-progress-bar-container">
					<div className="category-progress-bar">
						<div
							className="category-progress"
							style={{
								width:
									(props.langSelected.langArrayBase?.filter(
										(e) => (e.category ? e.category : e.categoria).includes(props.cat) && e.dominated === 3
									).length /
										props.langSelected.langArrayBase?.filter((e) => (e.category ? e.category : e.categoria).includes(props.cat))
											.length) *
										100 +
									"%",
							}}
						></div>
					</div>
				</div>
			</div>
			<div className="category-card-subcat-selector-container">
				<label htmlFor="subCategory-category-card-sort">Filtrar por</label>
				<select
					name="subCategory-category-card-sort"
					id="subCategory-category-card-sort"
					value={subCategorySelected}
					onChange={(e) => setSubCategorySelected(e.target.value)}
				>
					<option value="all">Todas</option>
					<option value="difficult-asc">{"Dificultad (fácil -> difícil)"}</option>
					<option value="difficult-desc">{"Dificultad (difícil -> fácil)"}</option>
					<option value="dominated">Dominados</option>
					<option value="notDominated">Por dominar</option>
					<option value="news">Nuevos</option>
					{props.langSelected.langArrayBase !== undefined &&
						returnSubCategories(
							props.langSelected.langArrayBase.filter((e) => (e.category ? e.category : e.categoria).includes(props.cat))
						).map((word, index) => {
							if (word !== null) {
								return (
									<option value={word} key={"sub-categories-option-" + index}>
										{word}
									</option>
								);
							}
						})}
				</select>
			</div>
			{(subCategorySelected === "difficult-asc" || subCategorySelected === "difficult-desc") && (
				<div className="difficult-selector-filter-container">
					{difficults.map((difficult, index) => {
						return (
							<div
								className={"difficult-ball-filter " + difficult + (difficultSelected.includes(difficult) ? " selected" : "")}
								onClick={() => handleDifficultFilter(difficult)}
								key={"difficult-ball-filter-" + index}
							></div>
						);
					})}
				</div>
			)}
			<div className="category-card-subcat-selector-container">
				<input
					type="checkbox"
					id={"select-all" + props.cat}
					name={"select-all" + props.cat}
					onChange={(e) => {
						handleSelect(e);
					}}
					checked={selectAll}
				/>
				<label htmlFor={"select-all" + props.cat}>Marcar todas</label>
			</div>
			{((props.langSelected.langArray.filter((e) => (e.category ? e.category : e.categoria).includes(props.cat)).length > 0 &&
				!open) ||
				open) && (
				<Scroll {...config}>
					<div className="category-words-container" id={"category-words-container-" + props.cat}>
						{hasScroll && (
							<img
								src={ScrollArrows}
								className={"scroll-down-icon" + (holding ? " no-animation" : "")}
								alt="chevron arrows icon"
								onMouseDown={() => scrollDown("down")}
								onMouseUp={() => handleMouseUp()}
								onMouseLeave={() => handleMouseUp()}
							/>
						)}
						{hasScrollUp && (
							<img
								src={ScrollArrows}
								className={"scroll-up-icon" + (holdingUp ? " no-animation" : "")}
								alt="chevron arrows icon"
								onMouseDown={() => scrollDown("up")}
								onMouseUp={() => handleMouseUp()}
								onMouseLeave={() => handleMouseUp()}
							/>
						)}
						{wordsArray.length > 0 &&
							wordsArray.map((word, index) => {
								return open || (!open && props.langSelected.langArray?.findIndex((e) => e.id === word.id) !== -1) ? (
									<div className="category-selector-container" key={"category-selector2--container-" + index}>
										{open && (
											<input
												id={"category-" + word.id}
												type="checkbox"
												checked={props.langSelected.langArray?.findIndex((e) => e.id === word.id) !== -1}
												onChange={(e) => {
													props.handleCategorySelector(e, word);
												}}
											/>
										)}
										<label
											style={
												props.langSelected.langSelected === "chinese" ||
												(props.langSelected.langSelected === "japanese" && word.optional_word !== null)
													? { color: "#00000080" }
													: {}
											}
											htmlFor={"category-" + word.id}
										>
											<span className="d-flex align-items-center justify-content-start">
												<div
													className="difficult-color-circle mr-2"
													style={
														word.custom_difficulty !== null
															? {
																	background: getDifficultColor(
																		parseInt(word.times_answered) === 0 ? 1.01 : parseFloat(word.custom_difficulty)
																	),
															  }
															: {}
													}
												></div>{" "}
												{word.optional_word !== null && (
													<span style={{ color: "#000000" }}>
														{word.optional_word ? word.optional_word : word.palabra_opcional}
														&ensp;
													</span>
												)}
												{props.langSelected.langSelected === "chinese" && (
													<>
														{word.native_word ? (
															<span> {word.native_word.replace(/"/g, "")}</span>
														) : (
															<span> {word.palabra.replace(/"/g, "")}</span>
														)}
													</>
												)}
												{props.langSelected.langSelected === "japanese" && word.optional_word !== null && (
													<span>{"(" + (word.native_word ? word.native_word : word.palabra).replace(/"/g, "") + ")"}</span>
												)}
												{props.langSelected.langSelected === "japanese" && word.optional_word === null && (
													<span>{(word.native_word ? word.native_word : word.palabra).replace(/"/g, "")}</span>
												)}
												{props.langSelected.langSelected !== "chinese" && props.langSelected.langSelected !== "japanese" && (
													<span>{(word.native_word ? word.native_word : word.palabra).replace(/"/g, "")}</span>
												)}
												{props.showCustomDifficulty &&
													parse(
														`<sup>${
															word.custom_difficulty !== null ? parseFloat(word.custom_difficulty).toFixed(2) : "n/a"
														}</sup>`
													)}
											</span>
										</label>
									</div>
								) : (
									<></>
								);
							})}
					</div>
				</Scroll>
			)}
		</div>
	);
}
