import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import "./PaymentHistoryStyles.css";
import { db } from "initFirebase";
import PaymentHistoryCard from "./AdminPaymentHistoryCard/AdminPaymentHistoryCard";
import { SearchIcon } from "assets/Imports";

function PaymentHistoryContainer({ user }) {
	const [payments, setPayments]: any = useState([]);
	const [payments2, setPayments2] = useState([]);
	const [selectedMonth, setSelectedMonth] = useState("-1");
	const [selectedYear, setSelectedYear] = useState("-1");

	const getPayments = async () => {
		const q = await query(collection(db, "paymentHistory"), where("verified", "==", true));
		var array: any = [];
		// var amount = 0;
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			const nDate = new Date(doc.data().date);
			let date = nDate.getDate() + "/" + nDate.getMonth() + "/" + nDate.getFullYear();
			let dateA = date.split("/");
			if (selectedYear === "-1" && selectedMonth === "-1") {
				array.push({ id: doc.id, ...doc.data() });
			} else if (selectedYear !== "-1" && selectedMonth !== "-1") {
				if (selectedMonth === dateA[1] && selectedYear === dateA[2]) {
					array.push({ id: doc.id, ...doc.data() });
				}
			} else if (selectedYear === "-1" && selectedMonth !== "-1") {
				if (selectedMonth === dateA[1]) {
					array.push({ id: doc.id, ...doc.data() });
				}
			} else if (selectedYear !== "-1" && selectedMonth === "-1") {
				if (selectedYear === dateA[2]) {
					array.push({ id: doc.id, ...doc.data() });
				}
			}
		});
		array.sort(compare);
		setPayments(array);
		setPayments2(array);
	};

	function compare(a, b) {
		if (a.date > b.date) {
			return -1;
		}
		if (a.date < b.date) {
			return 1;
		}
		return 0;
	}

	const arraySearch = (array, keyword) => {
		const searchTerm = keyword.toLowerCase();
		return array.filter((value) => {
			return value.name.toLowerCase().match(new RegExp(searchTerm, "g"));
		});
	};

	const handleOnChange = async (e) => {
		let value = e.target.value;
		if (value.length > 2) {
			let search = await arraySearch(payments, value);
			setPayments(search);
		} else {
			setPayments(payments2);
		}
	};

	useEffect(() => {
		getPayments();
	}, []);

	const handleMonthSelected = (e) => {
		setSelectedMonth(e.target.value);
	};

	const handleYearSelected = (e) => {
		setSelectedYear(e.target.value);
	};

	return (
		<>
			<div className="payment-history-title-container">
				<h1>Historial de pagos</h1>
			</div>
			<div className="payment-history-subtitle-container">
				<p style={{ margin: 0 }}>Historial de todos los pagos hechos.</p>
				<div style={{ border: "2px solid #7b7b7e", width: 300, borderRadius: 10, padding: "10px 20px", display: "flex" }}>
					<img src={SearchIcon} alt="search-icon" />
					<input
						style={{ border: "none", paddingLeft: 15, width: "100%" }}
						onChange={handleOnChange}
						placeholder="Buscar"
						type="text"
					/>
				</div>
			</div>
			<div className="payment-history-subtitle-container-box">
				<div>
					<label htmlFor="month-selector">Mes</label>
					<select
						className="filter-date-selector"
						name="month-selector"
						value={selectedMonth}
						onChange={handleMonthSelected}
						id="month-selector"
					>
						<option value="-1">Todos</option>
						<option value="0">Enero</option>
						<option value="1">Febrero</option>
						<option value="2">Marzo</option>
						<option value="3">Abril</option>
						<option value="4">Mayo</option>
						<option value="5">Junio</option>
						<option value="6">Julio</option>
						<option value="7">Agosto</option>
						<option value="8">Septiembre</option>
						<option value="9">Octubre</option>
						<option value="10">Noviembre</option>
						<option value="11">Diciembre</option>
					</select>
				</div>
				<div>
					<label htmlFor="year-selector">Año</label>
					<select
						className="filter-date-selector"
						name="year-selector"
						value={selectedYear}
						id="year-selector"
						onChange={handleYearSelected}
					>
						<option value="-1">Todos</option>Î<option value="2022">2022</option>
						<option value="2023">2023</option>
						<option value="2024">2024</option>
						<option value="2025">2025</option>
						<option value="2026">2026</option>
						<option value="2027">2027</option>
						<option value="2028">2028</option>
						<option value="2029">2029</option>
						<option value="2030">2030</option>
					</select>
				</div>
				<div>
					<button className="tertiary" style={{ width: "auto", padding: 10, minWidth: "auto" }} onClick={getPayments}>
						Buscar
					</button>
				</div>
			</div>
			<div className="mt-4">
				{payments.length > 0 ? (
					payments.map((payment, i) => {
						return <PaymentHistoryCard data={payment} index={i} key={"payment-history-card-" + i} />;
					})
				) : (
					<></>
				)}
			</div>
		</>
	);
}

export default PaymentHistoryContainer;
