import React, { useEffect, useState } from "react";
import { Col, Form, Row, DropdownButton, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router";
import firebase from "firebase/compat/app";
import chip from "./images/chip-card.png";
import visaLogo from "./images/visa-icon-1.png";
import mastercardLogo from "./images/mastercard-logo.png";
import americanLogo from "./images/american-express.png";
import poweredConekta from "./images/powered_by_conekta.svg";
import securePayment from "./images/Secure-Payment-Icon.png";
import "./styles.scss";
import InputMask from "react-input-mask";
import { useAuth } from "@hooks/useAuth";
import { getAuth } from "firebase/auth";
import { db, functions } from "initFirebase";
import { addDoc, collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { getMatricula } from "functions/Functions";
import { LogoBig } from "assets/Imports";
import { CardI } from "interfaces/Conekta/Conekta";
import { httpsCallable } from "firebase/functions";
import StripeProviderWrapper from "../StripeCheckoutModal/StripeCheckoutModal";
import { StripeCheckoutModal } from "../StripeCheckoutModal/StripeCheckoutModal";
import { randDarkColor } from "utils/GeneralFunctions";
import { useCurrencyContext } from "contexts/CurrencyContext";
import { processConektaOrStripePayment } from "../processPaymentUtils";

import { Alert } from "react-bootstrap";
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from "@stripe/react-stripe-js";
const conektaPB = process.env.REACT_APP_CONEKTA_PUBLIC_KEY;
const errorMessages = {
	"conekta.errors.processing.bank.insufficient_funds":
		"Fondos insuficientes. Intente con otro método de pago.",
	"conekta.errors.processing.bank.declined":
		"Transacción no realizada. Verifica que los datos ingresados sean correctos. Si el problema persiste, ponte en contacto con tu institución bancaria",
	"conekta.errors.parameter_validation.charge.monthly_installments_not_supported_for_debit":
		"Tarjetas de debito no permiten meses sin intereses.",
	"conekta.errors.processing.charge.card_payment.suspicious_behaviour":
		"Pago declinado. Favor de verificar tus datos o intentar con otro método de pago.",
	"conekta.errors.processing.charge.card_payment.country_restriction":
		"Esta tarjeta fue emitida fuera de México y no permite compras a meses sin intereses.",
};

declare let window: any;
function CheckoutForm(props) {
	const initialStateValues = {
		name: props.name,
		email: props.email.trim().toLowerCase(),
		phone: props.phone,
		cardName: props.name,
		cardNumber: "",
		cvc: "",
		expDate: "", // Cambiado de exp_month y exp_year a expDate
	};

	const history = useHistory();
	const [firstPay, setFirstPay] = useState(false);
	const [gen, setGen] = useState<any>(null);
	const useQuery = () => new URLSearchParams(useLocation().search);
	const urlParams = useQuery();
	const inscriptionType = urlParams.get("modalidad");
	const [values, setValues] = useState(initialStateValues);
	const [validated, setValidated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [selected, setSelected] = useState("Pago en una exhibición");
	const [selectedCard, setSelectedCard] = useState("new");
	const precio = props.precio;
	const [checked, setChecked] = useState(true);
	const [selectChecked, setSelectChecked] = useState("Sí");
	const [cards, setCards] = useState<Array<CardI>>([]);
	const user = useAuth().user;
	const [defaultSource, setDefaultSource] = useState("");
	const [conektaCardError, setConektaCardError] = useState(false);
	const { prices, currencyCountry, userCountry } = useCurrencyContext();
	const showDollars = props.classes && props.genModality && currencyCountry === "us";
	const typeOfPrice = props.classes && props.classes > 10 ? "promoClass" : "regularClass";
	const dollarCost = showDollars ? prices.USD[props.genModality][typeOfPrice] * props.classes : 0;
	const stripe = useStripe();
	const elements = useElements();
	const [error, setError] = useState("");
	const [openModalStripe, setOpenModalStripe] = useState(false);
	const [isASuspiciousBehaviour, setIsASuspiciousBehaviour] = useState(false);
	const [iscountryRestricted, setIscountryRestricted] = useState(false);

	let userId;
	if (user !== null) {
		userId = getAuth().currentUser!.uid;
	}

	const estudiante = {
		name: props.name,
		email: props.email.trim().toLowerCase(),
		phone: props?.phone || "",
	};

	const isFirstPay = async () => {
		const q = await query(
			collection(db, "paymentHistory"),
			where("verified", "==", true),
			where("userId", "==", userId)
		);
		const querySnapshot = await getDocs(q);
		if (querySnapshot.size === 0) {
			setFirstPay(true);
			getGen();
		}
	};

	const getGen = async () => {
		const modality = inscriptionType === "presencial" ? "in-person" : inscriptionType;
		const q = await query(
			collection(db, "generations"),
			where("type", "==", modality),
			where("active", "==", true)
		);
		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			querySnapshot.forEach((doc) => {
				setGen({ id: doc.id, ...doc.data() });
			});
		});
	};

	let checkedValues: any = [];
	let dropdownValues: any = [];
	if (props.msi === 6) {
		dropdownValues = ["Pago en una exhibición", "3 Meses sin intereses", "6 Meses sin intereses"];
	} else if (props.msi === 3) {
		dropdownValues = ["Pago en una exhibición", "3 Meses sin intereses"];
	}

	checkedValues = ["Sí", "No"];

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setValues({ ...values, [name]: value });
	};

	// ESTE METODO ES PARA CUANDO SE CREA UNA NUEVA TARJETA
	const successCallback = async (token) => {
		if (user) {
			const createNewCard = httpsCallable<{ customerId: string; token_id: string }, CardI>(
				functions,
				"newCard"
			);
			const newCardResponse = await createNewCard({
				customerId: user?.conektaId,
				token_id: token.id,
			});

			handlePay(newCardResponse.data.id);
		} else {
			handlePay(token.id);
		}
	};

	const errorCallback = (data) => {
		alert("Por favor verifica los datos proporcionados de la tarjeta e intente de nuevo.");
		setRefresh(false);
		setLoading(false);
	};

	const handleSubmit = async (e, isConektaPayment: boolean) => {
		let typePayment: "conekta" | "stripe" = "conekta";
		let params = {};

		switch (isConektaPayment) {
			case true:
				typePayment = "stripe";
				params = {
					e,
					stripe,
					elements,
					setError,
					setLoading,
					CardCvcElement,
					CardExpiryElement,
					CardNumberElement,
					functions,
					msiSelected: selected,
					price: precio,
					itemName: props.nombre,
					student: estudiante,
					handleSuccessPayment: handleSuccessPaymentStripe,
					setOpenModalStripe,
					isASuspiciousBehaviour,
					setConektaCardError,
					user,
					userCountry,
				};
				break;
			case false:
				typePayment = "conekta";
				params = {
					e,
					setRefresh,
					setConektaCardError,
					setValidated,
					values,
					handlePay,
					selectedCard,
					successCallback,
					errorCallback,
					setLoading,
				};
				break;
			default:
				break;
		}
		//e.preventDefault();
		await processConektaOrStripePayment(typePayment, params);
	};

	const handlePay = async (cardId: string) => {
		try {
			//setLoading(true);
			let msi;

			if (selected == "3 Meses sin intereses" && iscountryRestricted === false) {
				msi = 3;
			} else if (selected == "6 Meses sin intereses" && iscountryRestricted === false) {
				msi = 6;
			} else {
				msi = 0;
			}

			if (cardId !== defaultSource && user) {
				const newDefaultCard = httpsCallable(functions, "newDefaultCard");
				const newDefaultCardResponse = await newDefaultCard({
					customerId: user.conektaId,
					card_id: cardId,
				});
			}
			let tryToPay = httpsCallable(functions, "payConecta");
			tryToPay({
				client: user ? user : estudiante,
				token: cardId,
				msi: msi,
				nombre: props.nombre,
				precio: precio,
				inscriptionType: inscriptionType === "presencial" ? "in-person" : inscriptionType,
			})
				.then(async (res: any) => {
					if (res.data.object === "error") {
						if (res.data.details) {
							const errorCode = res.data.details[0]?.code;
							const message = errorMessages[errorCode];

							if (
								errorCode === "conekta.errors.processing.charge.card_payment.suspicious_behaviour"
							) {
								setIsASuspiciousBehaviour(true);
							}

							if (
								errorCode === "conekta.errors.processing.charge.card_payment.country_restriction"
							) {
								setIscountryRestricted(true);
							}

							if (message) {
								setError(message);
								setLoading(false);
							} else if (
								errorCode.indexOf("conekta.errors.parameter_validation.customer_info") !== -1
							) {
								setError("Hace falta nombre, correo o celular.");
							}
						} else {
							setError(res.data.error.message);
						}

						setConektaCardError(true);

						openStripeModal();
						setRefresh(false);
					} else {
						setValidated(false);
						setRefresh(!refresh);
						registerPayment(res.data, "conekta");
					}
				})
				.catch((error) => {
					console.log(error);

					setLoading(false);
				});
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	};

	const openStripeModal = () => {
		setOpenModalStripe(true);
	};

	const handleSuccessPaymentStripe = (stripeOrderData) => {
		registerPayment(stripeOrderData, "stripe");
	};

	const registerPayment = async (orderData, type: "conekta" | "stripe") => {
		estudiante.email = values.email;
		let preUserRegisterRef;
		let userColor = "#000000";

		const userExist = await db
			.collection("users")
			.where("email", "==", props.email.trim().toLowerCase())
			.limit(1)
			.get()
			.then((querySnapshot) => {
				if (!querySnapshot.empty) {
					const userData = querySnapshot.docs[0].data();
					const userId = querySnapshot.docs[0].id;
					return { ...userData, id: userId };
				} else {
					return null;
				}
			});

		if (user === null && userExist === null) {
			userColor = randDarkColor();
			preUserRegisterRef = await addDoc(collection(db, "users"), {
				email: props.email.trim().toLowerCase(),
				name: props.name,
				phone: props.phone,
				generationId: "",
				matricula: "",
				type: "pre-register",
				color: userColor,
			});
		}
		const date = new Date(Date.now());
		const payAt = new Date(Date.now());
		const data = {
			name: user ? user.name : estudiante.name,
			amount: precio / 100,
			date: payAt.valueOf(),
			image: "",
			concept: props.nombre,
			verified: true,
			medio: "Tarjeta",
			createdAt: date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear(),
			email: estudiante.email.trim().toLowerCase(),
			userId: user ? user?.id : preUserRegisterRef?.id ? preUserRegisterRef?.id : userExist?.id,
			checked: true,
			type: `pago con tarjeta, verificado por ${type}`,
			orderId: orderData.id,
			payIn: type,
		};
		const paymentDoc = await db.collection("paymentHistory").add(data);
		await db.collection("notifications").add({
			name: user ? user.name : estudiante.name,
			text: `Pago ${
				type === "conekta" ? "automatizado por Conekta de" : "automatizado por Stripe de"
			}
		    $ ${data.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
				 MXN`,
			date: new Date(),
			userId: "admin",
			userType: 1,
			seen: false,
			profileColor: user ? user.color : userColor,
			type: type === "conekta" ? "conektaAutomaticPayment" : "stripeAutomaticPayment",
			paymentHistoryId: paymentDoc.id,
			sectionId: "NYKrjrHTHAj4Cp1KF32A",
		});
		if (firstPay === true || user === null) {
			const q = query(collection(db, "users"), where("generationId", "==", gen.id));
			const querySnapshot = await getDocs(q);
			const splitGenDate = gen.initialDate.split("/");

			let num = querySnapshot.size + 1;

			let matricula = await getMatricula(num, gen.sede, splitGenDate);

			await db
				.collection("users")
				.doc(user ? user.id : preUserRegisterRef?.id ? preUserRegisterRef.id : userExist?.id)
				.update({
					generationId: gen.id,
					matricula: matricula,
					schedule: gen.schedule,
					addedAt: new Date(),
				});

			await db
				.collection("generations")
				.doc(gen.id)
				.update({
					limit: gen.limit - 1,
				});
		}
		setTimeout(
			() =>
				history.push({
					pathname: "/gracias",
					state: {
						order: orderData,
						student: estudiante,
						firstPay: firstPay,
					},
				}),
			1000
		);
	};

	const handleSelect = (e) => {
		setSelected(e);
	};
	const handleSelectCard = (e) => {
		const { value } = e.target;
		setSelectedCard(value);
	};

	const handleChecked = (e) => {
		if (e == "Sí") {
			setChecked(true);
			setSelectChecked(e);
			values.cardName = initialStateValues.cardName;
			values.email = initialStateValues.email;
		} else {
			setChecked(false);
			setSelectChecked(e);
			values.cardName = "";
			values.email = "";
		}
	};

	useEffect(() => {
		if (gen !== null && gen.limit === 0) {
			alert("Se terminaron los espacios de clases presenciales.");
			history.push("inscripcion");
		}

		if (gen === null) {
			getGen();
		}
	}, [gen]);

	useEffect(() => {
		window.Conekta.locale = "es";
		window.Conekta.setPublicKey(conektaPB); //llave de prodccion
		// window.Conekta.setPublicKey("key_NNPFvV1T7nggMV1cfrqmH2g") //llave de pruebas
		if (user !== null) {
			let customerConektaInfo = firebase.functions().httpsCallable("getCustomer");
			customerConektaInfo({
				customerId: user.conektaId,
			}).then((res) => {
				if (res.data.payment_sources !== undefined) {
					const tempCards = res.data.payment_sources.data;
					setCards(tempCards);
				}
				if (res.data?.default_payment_source_id)
					setDefaultSource(res.data.default_payment_source_id);
				setLoading(false);
			});
			isFirstPay();
		}
	}, []);

	/*
	useEffect(() => {
		if (!refresh) return setLoading(false);
		setLoading(!loading);
	}, [refresh]);
*/

	const closeModal = () => {
		setOpenModalStripe(false);
		setLoading(false);
	};

	return (
		<div
			className="d-flex flex-column mt-5 checkout-main-container"
			style={{
				marginBottom: "80px",
				flexWrap: "wrap",
				width: "90%",
				marginRight: "auto",
				marginLeft: "auto",
			}}
		>
			<div
				className="d-flex justify-content-between align-items-center"
				style={{ marginBottom: "2rem", flexWrap: "wrap" }}
			>
				<h1>Proceso de compra</h1>
				<h2 className="total-pay-card">
					Total a pagar: <a>{props.inversion}</a>
				</h2>
			</div>
			<div
				className="d-flex form-card-container"
				style={{ justifyContent: "space-between", flexWrap: "wrap" }}
			>
				<Form
					className="w-50"
					noValidate
					validated={validated}
					onSubmit={(e) => handleSubmit(e, openModalStripe)}
					id="card-form"
				>
					<div className="visible-card">
						<div className="card" style={{ borderRadius: "15px" }}>
							<p className="top-left-card">Academia de Lenguas</p>
							<div className="red-circle"></div>
							<div className="yellow-circle"></div>
							<img src={chip} alt="" />
							<InputMask
								className="card-number"
								placeholder="XXXX&emsp;XXXX&emsp;XXXX&emsp;XXXX"
								mask="9999 9999 9999 99999"
								maskChar=""
								value={values.cardNumber}
								disabled
							></InputMask>
							<input
								className="input-name"
								placeholder="Nombre"
								value={values.cardName}
								type="text"
								disabled
							/>
							<InputMask
								className="expire-date"
								placeholder="mm/yy"
								mask="99/99"
								value={values.expDate}
								type="text"
								disabled
							/>
						</div>
						<div className="cards-icons">
							<img className="icon" src={visaLogo} alt="" />
							<img className="icon" src={mastercardLogo} alt="" />
							<img className="icon" src={americanLogo} alt="" />
						</div>
					</div>
					<div className="mb-3">
						<span className="ml-1" style={{ fontWeight: 500 }}>
							¿El nombre del estudiante es el mismo que el titular de tarjeta?
						</span>
						<DropdownButton
							alignRight
							title={selectChecked}
							id="dropdown-menu-align-right"
							onSelect={handleChecked}
							style={{ marginTop: ".5rem" }}
						>
							{checkedValues.map((item, index) => (
								<Dropdown.Item eventKey={item} className="w-100">
									{item}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</div>

					{!checked && (
						<>
							<Row className="input-row">
								<Col className="w-100 p-0">
									<Form.Group className="mb-3" controlId="formBasicName">
										<Form.Label>
											<b>Nombre completo</b>
										</Form.Label>
										<Form.Control
											required
											onChange={handleInputChange}
											type="text"
											name="cardName"
											value={values.cardName}
											placeholder="Luke Skywalker"
											data-conekta="card[name]"
											className={conektaCardError ? "border-danger" : ""}
										/>
										<Form.Control.Feedback type="invalid">
											Por favor ingresa un nombre.
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>

							<Row className="input-row">
								<Col className="w-100 p-0">
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<Form.Label>
											<b>Correo electrónico</b>
										</Form.Label>
										<Form.Control
											required
											onChange={handleInputChange}
											type="text"
											name="email"
											value={values.email}
											placeholder="ejemplo@ejemplo.com"
											className={conektaCardError ? "border-danger" : ""}
										/>
										<Form.Control.Feedback type="invalid">
											Por favor ingresa un número de tarjeta válido.
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
						</>
					)}

					<Row className="input-row">
						<Col className="w-100 p-0">
							<label style={{ fontWeight: "bolder" }} htmlFor="">
								Seleccionar tarjeta
							</label>
							<select
								className={`select-card ${conektaCardError ? "border-danger" : ""}`}
								name="card"
								style={{ borderRadius: "10px" }}
								onChange={handleSelectCard}
							>
								<option value="new">Nueva</option>
								{cards.map((pm: CardI) => (
									<option value={pm.id}>
										{pm.brand} •••• {pm.last4}
									</option>
								))}
							</select>
						</Col>
					</Row>
					{error && (
						<div>
							<Alert variant="danger">{error}</Alert>
						</div>
					)}
					{selectedCard === "new" && !openModalStripe ? (
						<>
							<Row className="input-row">
								<Col className="w-100 p-0">
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<Form.Label>
											<b>Número de tarjeta</b>
										</Form.Label>
										<InputMask
											required
											className={`form-control ${conektaCardError ? "border-danger" : ""}`}
											onChange={handleInputChange}
											type="text"
											name="cardNumber"
											value={values.cardNumber}
											placeholder="4444 2222 3333 8888"
											mask="9999 9999 9999 9999 999"
											maskChar=""
										></InputMask>

										<Form.Control.Feedback type="invalid">
											Por favor ingresa un número de tarjeta válido.
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							<Row className="input-row">
								<Col
									className="w-100 pr-3 pl-0"
									style={{ display: "flex", alignItems: "flex-end" }}
								>
									<Form.Group className="mb-3" controlId="formBasicPhone">
										<Form.Label>
											<b> Mes y año de expiración</b>
										</Form.Label>
										<InputMask
											mask="99/99"
											maskChar={null}
											value={values.expDate}
											onChange={handleInputChange}
											className={`form-control ${conektaCardError ? "border-danger" : ""}`}
											required
											placeholder="mm/yy"
											name="expDate"
										>
											{(inputProps) => (
												<Form.Control
													{...inputProps}
													type="text" // Cambia esto a text si date no funciona
												/>
											)}
										</InputMask>
										<Form.Control.Feedback type="invalid">
											Por favor ingresa el mes y año en formato MM/YY.
										</Form.Control.Feedback>
									</Form.Group>
								</Col>

								{/*<Col
									className="w-100 pl-3 pr-3"
									style={{ display: "flex", alignItems: "flex-end", justifyContent: "center" }}
								>
									<Form.Group className="mb-3" controlId="formBasicPhone">
										<Form.Label>
											<b>Año de expiración</b>
										</Form.Label>
										<Form.Control
											required
											onChange={handleInputChange}
											type="number"
											name="exp_year"
											value={values.exp_year}
											placeholder="25"
											data-conekta="card[exp_year]"
											className={conektaCardError ? "border-danger" : ""}
										/>
										<Form.Control.Feedback type="invalid">
											Porf avor ingresa un número de 2 dígitos.
										</Form.Control.Feedback>
									</Form.Group>
					</Col>*/}
								<Col
									className="w-100 pl-3 pr-0"
									style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}
								>
									<Form.Group className="mb-3" controlId="formBasicPhone">
										<Form.Label>
											<b>CVC</b>
										</Form.Label>
										<Form.Control
											required
											onChange={handleInputChange}
											type="text"
											maxLength={4}
											name="cvc"
											value={values.cvc}
											placeholder="345"
											data-conekta="card[cvc]"
											className={conektaCardError ? "border-danger" : ""}
										/>
										<Form.Control.Feedback type="invalid">
											Por favor, ingresa un número de 3 o 4 dígitos.
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
						</>
					) : (
						<div>
							{/* El resto de tu formulario y lógica */}
							<StripeCheckoutModal
								isOpen={openModalStripe}
								error={conektaCardError}
								setError={setError}
								setConektaCardError={setConektaCardError}
							/>
						</div>
					)}
					{iscountryRestricted === false ? (
						<Row className="input-row">
							<Col className="w-100 p-0">
								<Form.Group>
									<Form.Label>
										<b>Meses sin intereses</b>{" "}
										<span>(válido con tarjetas de crédito mexicanas)</span>
									</Form.Label>
									<DropdownButton
										alignRight
										title={selected}
										id="dropdown-menu-align-right"
										onSelect={handleSelect}
										className={conektaCardError ? "border-danger" : ""}
									>
										{dropdownValues.map((item, index) => (
											<Dropdown.Item eventKey={item} className="w-100">
												{item}
											</Dropdown.Item>
										))}
									</DropdownButton>
								</Form.Group>
							</Col>
						</Row>
					) : null}
				</Form>
				<div className="card-subscription-container">
					<div className="caracteristicas-card" style={{ position: "relative" }}>
						<h3>
							<b>Programa de Alto Rendimiento en 8 Idiomas Simultáneos</b>
						</h3>
						<h3 className="title-card-sub">{props.nombre}</h3>
						<img src={LogoBig} style={{ position: "absolute", right: 0, width: 250 }} />
						<ul>
							<li>Costo por seminario:</li>
							<li className="m-0">• {props.costo}.</li>
							<li>Duración del programa:</li>
							<li className="m-0">• {props.duracion}.</li>
							{props.pagos !== "" && <li className="">• {props.pagos}.</li>}
						</ul>
						<table className="card-table">
							<tr>
								<th scope="col">Servicio</th>
								<th scope="col">Precio</th>
							</tr>
							<tr>
								<td scope="row">{props.nombre}</td>
								<td className="text-right">
									{props.inversion}
									<br />
									{showDollars && `(${dollarCost} USD)`}
								</td>
							</tr>
						</table>
						<table className="card-table-2">
							<tr className="d-flex justify-content-between">
								<th>Total:</th>
								<th className="">
									{props.inversion}
									<br />
									{showDollars && `(${dollarCost} USD)`}
								</th>
							</tr>
						</table>
						{showDollars && (
							<p className="text-center mt-4" style={{ fontSize: "1rem" }}>
								<span className="text-danger" style={{ fontWeight: "bold" }}>
									*
								</span>
								Se hará un cargo de {dollarCost} USD. Debido al cambio de divisa, puede haber
								ligeras variaciones en el precio final.
							</p>
						)}
						<div className="checkout-secure-image-container">
							<img src={securePayment} alt="" />
						</div>
						<div className="checkout-conekta-image-container">
							<img src={poweredConekta} alt="" />
						</div>
					</div>
				</div>
			</div>
			<div className="button__container text-center" style={{ height: "55px", marginTop: "30px" }}>
				{!loading && (
					<button
						className="tertiary checkout-inscribirme-btn"
						type="submit"
						id="submit-btn"
						onClick={(e) => {
							// Desactivar el botón al instante
							setLoading(true);
							handleSubmit(e, openModalStripe);
						}}
						disabled={loading}
					>
						Realizar pago.
					</button>
				)}
				{loading && (
					<div className="spinner-border text-danger" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				)}
			</div>
		</div>
	);
}

export default CheckoutForm;
