import { useEffect, useState } from "react";
import { Modal, Row, Form, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { collection, doc, getDocs, orderBy, query, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import "./stylesModal.css";
import "../../CollaboratorContainer.scss";
import "../../CollaboratorContainer.css";

export function AddRolModal(props) {
	const { showmodal, Hide, AddRole,fetchCollaborators } = props;
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [Isopen, setIsopen] = useState(false);
	const history = useHistory();

	const form = useForm({
		defaultValues: {
			roleName: "",
		},
	});

	const {
		register,
		handleSubmit,
		reset,
		control,
		getValues,
		setValue,
		watch,
		formState: { errors, isSubmitSuccessful },
	} = form;

	const onSubmit = (data) => {
		AddRole(data);
		fetchCollaborators();
		props.Hide();
	};

	useEffect(() => {
		if (isSubmitSuccessful) {
			reset(); // Resetear los campos del formulario
		}
	}, [isSubmitSuccessful, reset]);

	return (
		<Modal
			show={showmodal}
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			onHide={Hide}
			className="custom-modal"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Nuevo Rol</Modal.Title>
			</Modal.Header>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body className="custom-modal">
					<Row className="input-row">
						<Col>
							<Form.Group className="form-group">
								<Form.Label>
									<b>Escribe el nombre que tendra el rol</b>
								</Form.Label>
								<Form.Control
									className={`form-control ${errors.roleName ? "is-invalid" : ""} `}
									type="text"
									placeholder="Escribe el nombre"
									{...register("roleName", { required: "Rol requerido" })}
								/>
								{errors.roleName && <Form.Text className="text-danger">{errors.roleName?.message}.</Form.Text>}
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-secondary secondary-action" onClick={Hide} type="button">
						Cerrar
					</button>
					<button className="btn btn-primary primary-action" type="submit">
						Guardar
					</button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}
