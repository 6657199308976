import { toPlural } from "@containers/VocabularySeminaryContainer/functions/VocabularyFunctions";
import { languagesEnglish } from "functions/DefaultValues";
import { capitalizeFirstLetter, returnFlagByLanguage } from "functions/Functions";
import { languagesArrays, language } from "interfaces/Interfaces";
import { Progress, Tooltip } from "antd";

export function getLang(language, languagesProgress?, words?) {
	if (languagesEnglish.includes(language)) {
		const percentage = languagesProgress[language].dominated;
		const newPercentage = languagesProgress[language].seen;
		return (
			<div className="circle-progress-flags-container ">
				<div className="circle-container">
					<h2 className="m-0">{Math.floor(percentage)}%</h2>
					<div className="progress-container">
						<Tooltip>
							<Progress
								percent={newPercentage}
								success={{
									percent: percentage,
									strokeColor: percentage < 100 ? "#ca5050" : "#efc900",
								}}
								type="circle"
								width={70}
								strokeColor="#959595"
								strokeWidth={10}
								trailColor="#e7e7e7"
								showInfo={false}
							/>
						</Tooltip>
						{returnFlagByLanguage(language)}
					</div>
				</div>
			</div>
		);
	} else if (language === "all") {
		return <h3>Palabras del seminario</h3>;
	} else {
		return <h3>{capitalizeFirstLetter(language)}</h3>;
	}
}

export function getLangArray(language: string, array: languagesArrays) {
	//  language puede ser tambien una categoria, que se utiliza en el default para filtrar los arrays en esa categoria
	switch (language) {
		case "french":
			return array.french;
			break;
		case "german":
			return array.german;
			break;
		case "italian":
			return array.italian;
			break;
		case "japanese":
			return array.japanese;
			break;
		case "chinese":
			return array.chinese;
			break;
		case "english":
			return array.english;
			break;
		case "russian":
			return array.russian;
			break;
		case "portuguese":
			return array.portuguese;
			break;
		case "all":
			return [
				...array.chinese,
				...array.english,
				...array.french,
				...array.german,
				...array.italian,
				...array.japanese,
				...array.portuguese,
				...array.russian,
			];
		default:
			return [
				...array.chinese,
				...array.english,
				...array.french,
				...array.german,
				...array.italian,
				...array.japanese,
				...array.portuguese,
				...array.russian,
			].filter((word) => word.category.includes(language));
			break;
	}
}

export function filterArray(filter: number, array: Array<language>) {
	switch (filter) {
		case 1:
			return array;
			break;
		case 2:
			return array.filter((e) => e.dominated === null);
			break;
		case 3:
			return array.filter((e) => e.dominated === 3);
			break;
		case 4:
			return array.filter((e) => e.dominated! < 3);
			break;

		default:
			return array;
			break;
	}
}

export function randomSubCategoryColor(index: number) {
	switch (index) {
		case 0:
			return "#0c9bc1";
			break;
		case 1:
			return "#c10c0c";
			break;
		case 2:
			return "#28c10c";
			break;
		case 3:
			return "#720cc1";
			break;
		case 4:
			return "#cccc08";
			break;
		case 5:
			return "#d94ea2";
			break;
		case 6:
			return "#4ed9a4";
			break;
		case 7:
			return "#0015cf";
			break;
		case 8:
			return "#7d86ff";
			break;
		case 9:
			return "#ab6262";
			break;
		default:
			return "#000000";
			break;
	}
}
