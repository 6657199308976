import React, { useEffect, useState } from "react";
import FaqOne from "../FaqItems/FaqOne";
import { Link } from "react-router-dom";
import "./styles.scss";
import { Container } from "react-bootstrap";
import { db } from "initFirebase";
import { CustomSpinner } from "@components/Spinner/SpinnerMyAccountView";

export interface Sections {
	[id: string]: string;
	// name: string;
}

export interface SectionObject {
	name?: string;
	id?: string;
}
export interface FAQObject {
	[id: string]: string;
}

function FAQSection() {
	const pathname = window.location.pathname;
	const routeNumber = parseInt(pathname.charAt(pathname.length - 1));
	const [showSection, setShowSection] = useState(routeNumber);
	const [sections, setSections] = useState<Sections[]>([]);
	const [questions, setQuestions] = useState<Sections[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const getSections = async () => {
		db.collection("faq").onSnapshot((querySnapshot) => {
			const docs: Sections[] = [];
			querySnapshot.forEach((doc) => {
				docs.push({
					...doc.data(),
					id: doc.id,
				});
			});
			setSections(docs);
		});
	};

	const getQuestions = async (section) => {
		db.collection("faq")
			.doc(section)
			.collection("questions")
			.orderBy("order", "asc")
			.get()
			.then((querySnapshot) => {
				const docs: FAQObject[] = [];
				querySnapshot.forEach((doc) => {
					docs.push({
						...doc.data(),
						id: doc.id,
					});
				});
				setQuestions(docs);
				setLoading(false);
			});
	};

	useEffect(() => {
		const pathname = window.location.pathname;
		const routeNumber = parseInt(pathname.charAt(pathname.length - 1));
		setShowSection(routeNumber);
		getSections();
		getQuestions(String(showSection));
	}, []);

	if (loading) {
		return <CustomSpinner height={"70vh"} size={100} borderSize={5} />;
	}

	return (
		<div className="container__questions">
			<Container>
				<div className="linkto">
					<Link to={`/dudas`}>
						<a>Regresar</a>
					</Link>
				</div>
				{React.Children.toArray(
					sections.map((item, key) => {
						return showSection === parseInt(item.id) && <FaqOne name={item.name} questions={questions} />;
					})
				)}
			</Container>
		</div>
	);
}

export default FAQSection;
