import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import './styles.scss';

interface CardItemProps {
  name: string;
  img: string;
}

function CardItem(props: CardItemProps) {
  return (
    <div className="card__item shadow-lg">
      <Row>
        <Col xs={5} lg={6} md={6} xl={6} className="first__col">
          <h3 className="">{props.name}</h3>
        </Col>
        <Col xs={7} lg={6} md={6} xl={6}>
          <Image fluid src={props.img} alt={props.name} style={props.name === "Clases presenciales" ? {width: "100%", maxHeight: 200} : {}} />
        </Col>
      </Row>
    </div>
  );
}

export default CardItem;
