import { getLangSpanish } from "@components/Vocabulary/PersonalizedTest/functions/functions";
import { WrenchIcon } from "assets/Imports";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./EditQuizModal.scss";
import { returnFlagByLanguage } from "functions/Functions";

export function EditQuizModal(props) {
	const [editAnswerType, setEditAnswerType] = useState<boolean>(false);
	const [answerType, setAnswerType] = useState<string>(props.quiz.answerType);

	const saveConfiguration = async () => {
		if (answerType !== props.quiz.answerType) {
			console.log("save");
			const docRef = doc(db, "quiz", props.quiz.id);

			await updateDoc(docRef, {
				answerType: answerType,
			}).then(() => {
				props.getQuiz(true);
        setEditAnswerType(false);
				props.onHide();
			});
		} else {
			setAnswerType(props.quiz.answerType);
			closeModal();
		}
	};

	const closeModal = () => {
		setEditAnswerType(false);
		setAnswerType(props.quiz.answerType);
		props.onHide();
	};

	return (
		<Modal
			className="edit-quiz-modal-component"
			show={props.show}
			onHide={closeModal}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Configuración</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="configuration-main-container">
					<div className="c-column one">
						<div className="c-row">
							<div className="title">
								<h3>Idioma de respuesta</h3>
								<img
									src={WrenchIcon}
									alt="imagen de una llave"
									role={"button"}
									onClick={() => setEditAnswerType(!editAnswerType)}
								/>
							</div>
							<div className="checkboxes-container">
								<div className="checkbox-container">
									<input
										type="checkbox"
										name="spanish"
										id="spanish-lang"
										value="normal"
										checked={answerType === "normal"}
										disabled={!editAnswerType}
										onChange={(e) => setAnswerType(e.target.value)}
									/>
									<label htmlFor="spanish-lang">{returnFlagByLanguage("spanish")}</label>
								</div>
								<div className="checkbox-container">
									<input
										type="checkbox"
										name="selected-lang"
										id="selected-lang"
										value="selected-lang"
										checked={answerType === "selected-lang"}
										disabled={!editAnswerType}
										onChange={(e) => setAnswerType(e.target.value)}
									/>
									<label htmlFor="selected-lang">{returnFlagByLanguage(props.quiz.language)} {props.quiz.language === "chinese" ? '(Pinyin)':''}</label>
								</div>
								{props.quiz.language === "chinese" && (
									<div className="checkbox-container">
										<input
											type="checkbox"
											name="kanji-lang"
											id="kanji-lang"
											value="kanji"
											checked={answerType === "kanji"}
											disabled={!editAnswerType}
											onChange={(e) => setAnswerType(e.target.value)}
										/>
										<label htmlFor="kanji-lang">{returnFlagByLanguage("chinese")} (Kanzi)</label>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="action-btn secondary-btn-bg" onClick={closeModal}>Cancelar</button>
				<button className="action-btn linear-bg" onClick={saveConfiguration}>
					Guardar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
