import { MenusDataContext } from "@containers/Admin/CollaboratorContainer/context/menu-data.provider";
import { useAuth } from "@hooks/useAuth";
import React, { useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useLocation, matchPath } from "react-router-dom";

const AsideNavLinks = ({ routes, getCountNotifications, getIcon, NA, setActive, active }) => {
	const { ispreviewMode, rutasAccesibles, academicRoute, financeRoute } = useContext(MenusDataContext);
	const location = useLocation();
	const defaultState = { myValue: null };

	const { selectedUserPreview, user } = useAuth();
	return (
		<>
			{routes.map((data, i) => {
				if (ispreviewMode) {
					if (selectedUserPreview?.seccionsCollaboratorId?.includes(data?.id)) {
						return (
							<React.Fragment key={i}>
								{data.Titulo === NA ? null : (
									<NavLink to={`/admin${data.Ruta}`} key={data.id} exact>
										<li>
											<div style={{ width: 30, marginRight: 20, position: "relative" }}>
												{getCountNotifications(data.Ruta) > 0 && (
													<div className="number-icon" style={{ top: 35, right: -8, position: "relative" }}>
														{getCountNotifications(data.Ruta)}
													</div>
												)}
												<img src={getIcon(data.Ruta)} alt="book-icon" />
											</div>
											{data.Titulo}
										</li>
									</NavLink>
								)}
							</React.Fragment>
						);
					}
				} else {
					if (user?.permissions?.includes(data?.id) || user.userType !== 4) {
						return (
							<React.Fragment key={i}>
								{data.Titulo === NA ? null : (
									<NavLink to={`/admin${data.Ruta}`} key={data?.id} exact>
										<li>
											<div style={{ width: 30, marginRight: 20, position: "relative" }}>
												{getCountNotifications(data.Ruta) > 0 && (
													<div className="number-icon" style={{ top: 35, right: -8, position: "relative" }}>
														{getCountNotifications(data.Ruta)}
													</div>
												)}
												<img src={getIcon(data.Ruta)} alt="book-icon" />
											</div>
											{data.Titulo}
										</li>
									</NavLink>
								)}
							</React.Fragment>
						);
					}
				}
			})}
		</>
	);
};

export default AsideNavLinks;
