import { Star } from "assets/Imports";
import React from "react";
import { Container, Image } from "react-bootstrap";
import Countdown from "react-countdown";
import "./styles.scss";

interface KidsCounterContainerProps {
	fechasInfo: any;
}

function KidsCounterContainer(props: KidsCounterContainerProps) {
	const [showCard, setShowCard] = React.useState(false);
	const Completionist = () => <span>You are good to go!</span>;

	return (
		<>
			<div className="section__counter kids">
				<Image fluid src={Star} alt="Promocion" className="star-img d-none d-md-block" />
				<Container className={"shadow-lg p-4 mb-5 bg-white rounded " + (showCard ? "hide" : "")}>
					<div className="text-center">
						<h4 className="text-center">
							<br />
							<b>{props.fechasInfo.fechaTitulo}</b>
						</h4>
						<Countdown
							date={new Date(props.fechasInfo.fechaInicioInscripcion)}
							onComplete={() => setShowCard(!showCard)}
							renderer={({ days, hours, minutes, seconds }) => (
								<span className="text-center">
									<b>
										{days} d {hours} h {minutes} m {seconds} s{" "}
									</b>
								</span>
							)}
						>
							<Completionist />
						</Countdown>
					</div>
					<p className="mt-4">
						<b>{props.fechasInfo.fechaCopie1}</b>
					</p>
					<p>
						<b>{props.fechasInfo.fechaCopie2}</b>
					</p>
				</Container>
				<div className="image__container">
					<Image fluid src={Star} alt="Promocion" className="star-img-sm d-sm-none" />
				</div>
			</div>
		</>
	);
}

export default KidsCounterContainer;
