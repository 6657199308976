import Footer from '@components/Footer/Footer';
import NavbarCustom from '@components/NavbarCustom/NavbarCustom';
import ContactContainer from '@containers/ContactContainer/ContactContainer';

function ContactScreen() {

  return (
    <div style={{ overflow: "hidden" }}>
      <ContactContainer />
    </div>
  );
}

export default ContactScreen;
