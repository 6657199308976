import Footer from "@components/Footer/Footer";
import NavbarCustom from "@components/NavbarCustom/NavbarCustom";
import SignUpContent from "@containers/SignUpContainer/SignUpContent";
import React, { useState } from "react";

function SignUpScreen() {
	const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);

	return (
		<div style={{ overflow: "hidden", minHeight: "100%" }}>
			<SignUpContent />
		</div>
	);
}

export default SignUpScreen;
