import { useEffect, useRef, useState } from "react";
import "./InvoiceAdminContainer.scss";
import { BellRed, BellWhite, InvoicesLeftTooltip, SearchIcon } from "assets/Imports";
import { InvoiceAdmincard } from "./InvoiceAdminCard";
import { InvoiceData, getAllInvoices, invoicesLeftCount, pendingInvoicesCount } from "@services/InvoiceServices";
import useInvoices from "./useInvoices";
import { RejectInvoiceModal } from "./RejectInvoiceModal";
import RequestInvoiceModal from "./RequestInvoiceModal";
import { getDocs, updateDoc, limit, collection, where, doc, query } from "firebase/firestore";
import toast, { Toaster } from "react-hot-toast";
import useCollaboratorLogs from "@hooks/collaborators/useCollaboratorLogs";
import { db } from "initFirebase";
import { CustomSpinner } from "@components/Spinner/SpinnerMyAccountView";
export default function InvoiceAdminContainer({ user }) {
	const [requestInvoiceModal, setRequestInvoiceModal] = useState<boolean>(false);
	const [pendingInvoices, setPendingInvoices] = useState<number>(0);
	const [categorie, setCategorie] = useState<number>(1);
	const {
		invoices,
		filterInvoices,
		getInvoices,
		invoiceSearch,
		acceptInvoice,
		addGeneration,
		downloadInvoice,
		cancelInvoice,
		rejectInvoice,
		cancelationValidation,
		sendRejectEmail,
		deleteInvoiceById,
		loading,
	} = useInvoices();

	const { invoiceLog } = useCollaboratorLogs();
	const [invoicesLeft, setInvoicesLeft] = useState<number>();
	const [invoiceToReject, setInvoiceToReject] = useState<InvoiceData>();
	const [rejectInvoiceModal, setRejectInvoiceModal] = useState<boolean>();

	const childRefs = useRef([]);

	const getPendingInvoicesCount = async () => {
		let tempPendingInvoicesCount: number = await pendingInvoicesCount();
		setPendingInvoices(tempPendingInvoicesCount);
	};

	const handleCategorieChange = (cat: number) => {
		setCategorie(cat);
	};
	const getInvoicesLeft = async () => {
		let tempInvoicesLeft = await invoicesLeftCount();
		setInvoicesLeft(tempInvoicesLeft);
	};

	const setContactNotificationSeen = async (contactInfoId) => {
		// When answering a contact message, the notification associated with the contact is set as seen.
		try {
			const q = query(collection(db, "notifications"), where("invoiceFbId", "==", contactInfoId), limit(1));
			const querySnapshot = await getDocs(q);
			let contactNotificationId = querySnapshot.docs[0].id ?? "";
			const docRef = doc(db, "notifications", contactNotificationId);
			await updateDoc(docRef, {
				seen: true,
				check: true,
			});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		filterInvoices(categorie);
	}, [categorie, pendingInvoices]);

	useEffect(() => {
		getPendingInvoicesCount();
		getInvoices();
		getInvoicesLeft();
	}, []);

	useEffect(() => {
		getPendingInvoicesCount();
	}, [invoices]);

	return (
		<>
			<div className="invoice-admin-main-container">
				<Toaster />
				<RequestInvoiceModal
					show={requestInvoiceModal}
					onHide={() => setRequestInvoiceModal(false)}
					getInvoices={getInvoices}
					setCategorie={setCategorie}
					collaborator={user}
				/>
				<div className="invoice-header-container">
					<div className="title-container">
						<h1>Facturación</h1> <span className="pending-invoices-count">( {pendingInvoices} )</span>
						<div>
							<span className="hover-pending-invoices">Cantidad de solicitudes pendientes</span>
						</div>
					</div>
					<div className="invoice-header-right-part">
						<button onClick={() => setRequestInvoiceModal(true)}>Nueva solicitud</button>
						<div className="invoice-search-bar">
							<img src={SearchIcon} />
							<input placeholder="Nombre/matrícula/correo/generación" onChange={invoiceSearch} />
						</div>
					</div>
				</div>
				*Puedes encontrar las facturas canceladas en la sección "Todas".
				<div className="invoice-types-container">
					<button
						className={"invoice-type-selector " + (categorie === 1 ? "active" : "")}
						onClick={() => handleCategorieChange(1)}
					>
						<p>Todas</p>
					</button>
					<button
						className={"invoice-type-selector " + (categorie === 2 ? "active" : "")}
						onClick={() => handleCategorieChange(2)}
					>
						<p>Solicitudes ({pendingInvoices})</p>
					</button>
					<button
						className={"invoice-type-selector " + (categorie === 3 ? "active" : "")}
						onClick={() => handleCategorieChange(3)}
					>
						<p>Aceptadas</p>
					</button>
					<button
						className={"invoice-type-selector " + (categorie === 4 ? "active" : "")}
						onClick={() => handleCategorieChange(4)}
					>
						<p>Rechazadas</p>
					</button>
					<button
						className={"invoice-type-selector " + (categorie === 5 ? "active" : "")}
						onClick={() => handleCategorieChange(5)}
					>
						<p>Facturas Autogeneradas</p>
					</button>
					{/* Se canceló la utilizacion de la cagetoria siguiente >>> */}
					{/* <button
						className={"invoice-type-selector " + (categorie === 6 ? "active" : "")}
						onClick={() => handleCategorieChange(6)}
					>
						<p>Facturas Generadas</p>
					</button> */}
				</div>
				{loading && <CustomSpinner height={400} size={100} borderSize={5} />}
				{!loading &&
					invoices?.map((invoice: InvoiceData, index) => {
						return (
							<InvoiceAdmincard
								invoice={invoice}
								key={"invoice-card-" + invoice.id}
								cat={categorie}
								acceptInvoice={acceptInvoice}
								addGeneration={addGeneration}
								downloadInvoice={downloadInvoice}
								cancelInvoice={cancelInvoice}
								setRejectInvoiceModal={setRejectInvoiceModal}
								setInvoiceToReject={setInvoiceToReject}
								cancelationValidation={cancelationValidation}
								setContactNotificationSeen={setContactNotificationSeen}
								getInvoices={getInvoices}
								setCategorie={setCategorie}
								deleteInvoiceById={deleteInvoiceById}
								collaborator={user}
								invoiceLog={invoiceLog}
							></InvoiceAdmincard>
						);
					})}
				<RejectInvoiceModal
					show={rejectInvoiceModal}
					onHide={() => setRejectInvoiceModal(false)}
					invoice={invoiceToReject}
					rejectInvoice={rejectInvoice}
					sendRejectEmail={sendRejectEmail}
					collaborator={user}
				/>
			</div>
			<div className="remaining-invoices-indicator">
				<img src={BellRed} alt="Icono de facturas restantes"></img>
				<span>{invoicesLeft}</span>
			</div>
			<div className="remaining-invoices-indicator-2">
				<div className="invoices-left-tooltip">
					Timbres restantes en este periodo. <br />
					Fecha de renovación: 8 de cada mes.
				</div>
				<img src={BellWhite} alt="Icono de facturas restantes"></img>
				<span>{invoicesLeft}</span>
			</div>
		</>
	);
}
