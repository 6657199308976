import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './styles.scss';

function ExclamationButton() {
  return (
    <div className="exc__button">
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={`tooltip-top`} className="shadow">
            Debido a la actual contingencia sanitaria, todas nuestras
            actividades presenciales se mantienen suspendidas. Los programas se
            impartirán en línea a traves de la plataforma Zoom.
          </Tooltip>
        }
      >
        <button className="question__icon">
          <span className="text">!</span>
        </button>
      </OverlayTrigger>
    </div>
  );
}

export default ExclamationButton;
