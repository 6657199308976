import React from 'react';
import { Card } from 'react-bootstrap';
import './styles.scss';

interface CustomCardProps {
  name: string;
  desc: string;
}

function CustomCard(props: CustomCardProps) {
  return (
    <div className="section__card">
      <Card style={{ width: '26rem', height: '30rem' }} className="shadow">
        <Card.Body>
          <Card.Title>“</Card.Title>
          <Card.Text>
            <p>{props.desc}</p>
            <p>
              <b>{props.name}</b>
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CustomCard;
