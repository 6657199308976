import { useState } from "react";
import { doc, getDoc, updateDoc, onSnapshot } from "@firebase/firestore";
import { FirebaseFirestore } from "@firebase/firestore-types";
import { Unsubscribe } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";

export interface hookParams {
	db: any;
	genId: string;
	seminaryId: string;
	newLink: string;
	videoPart: string;
	seminaryCurrent?: any;
}

export interface HistorialData {
	previousLink: string;
	videoPart: string;
	lastChange: any;
}

function useLinksHistory(): {
	SaveLinksHistory: ({ db, genId, seminaryId, videoPart, newLink, seminaryCurrent }: hookParams) => Promise<any>;
	ManualLinksHistory: ({ db, genId, seminaryId, videoPart, newLink }: hookParams) => Promise<any[]>;
	linksHistory: any[];
	setLinksHistory: React.Dispatch<React.SetStateAction<HistorialData[]>>;
	getMaterialLinksHistory: (db: any, genId: string, materialId: string) => Unsubscribe;
} {
	const [linksHistory, setLinksHistory] = useState<HistorialData[]>([]);

	const SaveLinksHistory = async ({ db, genId, seminaryId, videoPart, newLink, seminaryCurrent }: hookParams) => {
		try {
			
			const docRef = doc(db, "generations", genId, "material", seminaryId);
			const materialDoc = await getDoc(docRef);
			const materialData = materialDoc.data();

			if (materialData) {
				let updatedHistory = materialData.Historylinks || [];
				let dontResetField = true;
				if (materialData[videoPart] && materialData[videoPart] !== "" && materialData[videoPart] !== newLink) {
					
					updatedHistory.push({
						previousLink: materialData[videoPart],
						videoPart: videoPart,
						lastChange: new Date(),
					});
				}

				if (seminaryCurrent.id && seminaryCurrent.num) {
					const docRefA = doc(db, "generations", genId, "material", seminaryCurrent.id); // Seminario A
					const docA = await getDoc(docRefA);
					const dataA = docA.data();
					if (dataA) {
						let updatedHistoryA = dataA.Historylinks || [];
						if (seminaryCurrent.num !== materialData.num) {
							
							if (updatedHistoryA.length > 0) {
								// Obtén el enlace más reciente de la historia de enlaces del seminario A
								const latestLinkA = updatedHistoryA[updatedHistoryA.length - 1].previousLink;
								// Actualiza el enlace del seminario A con el último enlace de su historia
								{/*console.log("videopart", [videoPart]);
								console.log(latestLinkA);*/}
								await updateDoc(docRefA, { ...dataA, [videoPart]: latestLinkA });
								console.log("se guardo el video");
								dontResetField = false;
							} else {
								console.log("el historial de este seminario estaba vacio");
							}
						}
					}
				}
				return { updatedHistory, dontResetField };
			}
		} catch (e) {
			console.error(e);
			return [];
		}
	};

	async function ManualLinksHistory({ db, genId, seminaryId, videoPart, newLink }: hookParams) {
		try {
			const docRef = doc(db, "generations", genId, "material", seminaryId);
			const materialDoc = await getDoc(docRef);
			const materialData = materialDoc.data();
			let updatedHistory = materialData?.Historylinks || [];

			if (materialData) {
				for (let i = 0; i < videoPart.length; i++) {
					let videoParts = videoPart[i];
					let newLinks = newLink[i];

					if (materialData[videoParts] && materialData[videoParts] !== newLinks) {
						updatedHistory.push({
							previousLink: materialData[videoParts],
							videoPart: videoParts,
							lastChange: new Date(),
						});
						materialData[videoParts] = newLinks;
					}
				}
			}

			return updatedHistory;
		} catch (e) {
			console.error(e);
		}
	}

	const getMaterialLinksHistory = (db: any, genId: string, materialId: string): Unsubscribe => {
		const docRef = doc(db, "generations", genId, "material", materialId);

		const unsubscribe = onSnapshot(
			docRef,
			(doc) => {
				const materialData = doc.data();

				if (materialData && Array.isArray(materialData.Historylinks)) {
					const sortedLinks = materialData.Historylinks.sort((a: HistorialData, b: HistorialData) => {
						return b.lastChange - a.lastChange;
					});
					setLinksHistory(sortedLinks);
				}
			},
			(error) => {
				console.error("Error al obtener Historylinks:", error);
			}
		);

		// Clean up the listener when the component unmounts
		return unsubscribe;
	};

	return { linksHistory, getMaterialLinksHistory, SaveLinksHistory, setLinksHistory, ManualLinksHistory };
}

export default useLinksHistory;
