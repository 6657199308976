import apiInstance from "@api/apiInstance";

export const fetchVocabularyProgress = async (startSeminary: number, endSeminary: number) => {
	return apiInstance
		.get(`vocabulary/progress?startSeminary=${startSeminary}&endSeminary=${endSeminary}`)
		.then((res) => {
			return res.data;
		});
};

export const fetchProgressLanguageBySeminary = async (startSeminary: number, endSeminary) => {
	return apiInstance
		.get(`vocabulary/progress-languages?startSeminary=${startSeminary}&endSeminary=${endSeminary}`)
		.then((res) => {
			return res.data;
		});
};

export const fetchWordsBySeminaryAndLanguage = async (
	language: string,
	startSeminary: number,
	endSeminary: number
) => {
	return apiInstance
		.get(
			`vocabulary/language-words?language=${language}&startSeminary=${startSeminary}&endSeminary=${endSeminary}`
		)
		.then((res) => {
			return res.data;
		});
};

export const fetchWordProgress = async (wordDataId: number) => {
	return apiInstance.get(`vocabulary/word-progress/${wordDataId}`).then((res) => {
		return res.data;
	});
};
