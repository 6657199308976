import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Dropdown } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Pagination from "react-bootstrap/Pagination";
import ListGroup from "react-bootstrap/ListGroup";
import { BsTypeH2 } from "react-icons/bs";
import "./ShowContactModal.scss";
import { addDays, format, subDays } from "date-fns";
import { MailIcon, CloseCircleIcon, Fecha } from "assets/Imports";
import { DateRange } from "react-date-range";
const ITEMS_PER_PAGE = 3;
const MAX_EMAILS_TO_SHOW = 1;

const ShowReplyModal = ({ show, reply, onHide }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [showMoreEmails, setShowMoreEmails] = useState(false);
	const [dateRange, setDateRange] = useState<any>([
		{
			startDate: null,
			endDate: null,
			key: "selection",
		},
	]);

	const handleSelect = (ranges) => {
		setDateRange([ranges.selection]);
		setCurrentPage(1); // Restablece la página actual a 1 cada vez que cambias el filtro
	};

	const startDate = dateRange[0]?.startDate ? new Date(dateRange[0].startDate) : null;
	const endDate = dateRange[0]?.endDate ? addDays(new Date(dateRange[0].endDate), 1) : null;

	if (endDate) {
		endDate.setHours(0, 0, 0, 0);
	}
	const filteredReplies = reply?.response?.filter((response) => {
		const responseDate = new Date(response.createdAt.seconds * 1000);
		if (startDate && responseDate < startDate) return false;
		if (endDate && responseDate > endDate) return false;
		return true;
	});

	const pageCount = Math.ceil((filteredReplies?.length || 0) / ITEMS_PER_PAGE);
	const replies = filteredReplies
		?.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)
		.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

	return (
		<Modal show={show} onHide={onHide} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>Tus respuestas para: {reply?.name}</Modal.Title>
				<Dropdown style={{ marginTop: "-1%" }}>
					<Dropdown.Toggle variant="link" id="dropdown-date" className="dropdown-toggle" style={{ caret: "none" }}>
						<img src={Fecha} alt="Fecha" />
					</Dropdown.Toggle>

					<Dropdown.Menu style={{ minWidth: "max-content" }} className="date-range-picker-dropdown">
						<DateRange ranges={dateRange} onChange={handleSelect} rangeColors={["#f33e5b", "#3ecf8e", "#fed14c"]} />
					</Dropdown.Menu>
				</Dropdown>
			</Modal.Header>
			<Modal.Body>
				{replies?.map((response, index) => (
					<Card key={index} className="mb-3 email-card">
						<Card.Header className="email-card-header">
							<div className="grid-container">
								<Card.Title className="mb-1 text-muted from-to" style={{ fontSize: "16px" }}>
									<span style={{ textDecoration: "underline" }}>Asunto: {response.subject}</span>
								</Card.Title>
								<div className="info">
									{/*<Card.Subtitle className="mb-1 text-muted from-to">from: {response.from}</Card.Subtitle>*/}
									<Card.Subtitle className="mb-1 text-muted from-to">
										to:{" "}
										{response.email
											.slice(0, showMoreEmails ? response.email.length : MAX_EMAILS_TO_SHOW)
											.map((emailData, emailIndex) => (
												<span key={emailIndex} style={{ fontSize: "0.8rem", marginRight: "5px" }}>
													{emailData}
												</span>
											))}
										{response.email.length > MAX_EMAILS_TO_SHOW && (
											<span
												onClick={() => setShowMoreEmails(!showMoreEmails)}
												style={{
													fontSize: "0.7rem",
													fontWeight: "normal",
													color: "blue",
													cursor: "pointer",
												}}
												className="more-emails"
											>
												{showMoreEmails ? "Mostrar menos" : `+${response.email.length - MAX_EMAILS_TO_SHOW} más`}
											</span>
										)}
									</Card.Subtitle>
									<Card.Subtitle className="mb-2 text-muted date" style={{ fontSize: "0.8rem", marginTop: "2%" }}>
										{response?.collaboratorName && <span>Contestado por: {response?.collaboratorName}</span>}
									</Card.Subtitle>
								</div>
								<Card.Subtitle className="mb-2 text-muted date" style={{ fontSize: "0.8rem" }}>
									{new Date(response.createdAt.seconds * 1000).toLocaleString()}
								</Card.Subtitle>
							</div>
						</Card.Header>
						<Card.Body className="email-card-body">
							<Card.Text className="message-section">
								<b>Mensaje:</b> {response.question}
							</Card.Text>
							<hr className="divider" />
							<Card.Text className="reply-section">
								<b>Tu respuesta:</b>
								<div dangerouslySetInnerHTML={{ __html: response.replyText }} />
							</Card.Text>
							<hr className="divider" />
							{response.files.length <= 0 ? (
								<span>No enviaste archivos en este correo</span>
							) : (
								<>
									<Card.Text className="reply-section">
										<b>Archivos:</b>
									</Card.Text>
									<ListGroup variant="flush">
										<div className="file-container">
											{response.files.map((file, fileIndex) => (
												<div className="file-item" key={fileIndex}>
													<Button
														className="btn-sm"
														style={{ background: "transparent", borderColor: "gray", display: "flex", alignItems: "center" }}
													>
														<a
															target="_blank"
															rel="noopener noreferrer"
															href={file.link}
															style={{ display: "flex", alignItems: "center", gap: "10px" }}
														>
															{file.name}
														</a>
													</Button>
												</div>
											))}
										</div>
									</ListGroup>
								</>
							)}
						</Card.Body>
					</Card>
				))}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onHide}>
					Cerrar
				</Button>
				<Pagination>
					<Pagination.Prev disabled={currentPage === 1} onClick={() => currentPage > 1 && setCurrentPage((prev) => prev - 1)} />
					<Pagination.Next
						disabled={currentPage === pageCount}
						onClick={() => currentPage < pageCount && setCurrentPage((prev) => prev + 1)}
					/>
				</Pagination>
			</Modal.Footer>
		</Modal>
	);
};

export default ShowReplyModal;
