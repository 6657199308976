import {
	DocumentData,
	DocumentReference,
	DocumentSnapshot,
	doc,
	getDoc,
	updateDoc,
} from "firebase/firestore";
import { db } from "../initFirebase";
import { useEffect, useState } from "react";
import { dayInfoModalInterface } from "interfaces/StreakCalendar";
import { differenceInCalendarDays } from "date-fns";
// The intention with this hook, is to add the vocabularyStreak and studiedDays fields to the user in case
// they are not present, and correct the streak days, or erase them in case the streak has been lost.
export function useStreak() {
	const [vocabularyStreak, setVocabularyStreak] = useState<Array<string>>([]);
	const [studiedDays, setStudiedDays] = useState<Array<string>>([]);
	const [userId, setUserId] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [userDocRef, setUserDocRef] = useState<any>();

	useEffect(() => {
		if (userId !== undefined && userId.length > 0) {
			setUserDocRef(doc(db, "users", userId));
		}
	}, [userId]);

	useEffect(() => {
		if (userDocRef !== undefined) {
			getStreak();
		}
	}, [userDocRef]);

	useEffect(() => {
		if (vocabularyStreak.length > 0) {
			validateStreak();
		} else {
			setIsLoading(false);
		}
	}, [vocabularyStreak]);

	const getStreak = async () => {
		setIsLoading(true);
		const qSnap: DocumentSnapshot<DocumentData> = await getDoc(userDocRef);
		if (qSnap.exists()) {
			const data: any = {
				id: qSnap.id,
				...qSnap.data(),
			};
			if (data.vocabularyStreak !== undefined) {
				setVocabularyStreak(data.vocabularyStreak);
			} else {
				await updateDoc(userDocRef, { vocabularyStreak: [] });
			}

			if (data.studiedDays !== undefined) {
				setStudiedDays(data.studiedDays);
			} else {
				await updateDoc(userDocRef, { studiedDays: [] });
				setIsLoading(false);
			}
		}
	};

	const clearStreak = async () => {
		await updateDoc(userDocRef, { vocabularyStreak: [] });
		setVocabularyStreak([]);
	};

	const validateStreak = async () => {
		const temp = [...vocabularyStreak];
		const today = new Date();
		const lastDate = new Date(temp[temp.length - 1]);
		console.log("today", today);
		console.log("lastDate", lastDate);
		const differenceDays = differenceInCalendarDays(today, lastDate);
		console.log("days without studying", differenceDays);
		if (differenceDays >= 2) {
			console.log("you have lost your streak");
			clearStreak();
		}
		setIsLoading(false);
	};

	return {
		vocabularyStreak,
		setUserId,
		isLoading,
		studiedDays,
		setVocabularyStreak,
		validateStreak,
	};
}
