import { getInitials } from "functions/Functions";

export function Annotation({ annotation, profileColor, playerRef }) {

    function time(time) {
        var t = new Date(annotation.time * 1000).toISOString().substr(11, 8);

        return t
    }

    const setTime = (time) => {
        playerRef.current.currentTime(time)
    }

    return (
        <div className="annotation-row">
            <div className="profile-pic" style={{ backgroundColor: profileColor }}>
                <p>{getInitials(annotation.name)}</p>
            </div>
            <div className="annotation-text-container" onClick={() => setTime(annotation.time)} style={{ cursor: 'pointer' }}>
                <p className="annotation-text">{annotation.annotationText}</p>
                <p className="annotation-time-text">{time(annotation.time)}</p>
            </div>
        </div>
    )
}