
import FaqsButton from "@components/FaqsButton/FaqsButton";
import EnrollmentContainer from "@components/EnrollmentContainer/EnrollmentContainer";

function EnrollScreen() {

	return (
		<div style={{ overflow: "hidden" }}>
			<EnrollmentContainer />
			<FaqsButton />
		</div>
	);
}

export default EnrollScreen;
