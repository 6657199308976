// useTitleFromPath.js
import { useEffect, useState } from "react";

const useTitleFromPath = (path) => {
	const [title, setTitle] = useState("Academia de Lenguas Internacionales");

	useEffect(() => {
		switch (path) {
			case "/admin/colaboradores":
				setTitle("Colaboradores");
				break;
			case "/admin/invoice":
				setTitle("Facturación");
				break;
			case "/admin/generacion/:id":
				setTitle("Generaciones");
				break;
			case "/admin/material":
				setTitle("Materiales");
				break;
			case "/admin/videos":
				setTitle("Grabaciones");
				break;
			case "/admin":
				setTitle("Generaciones");
				break;
			case "/admin/actualizar-pagos":
				setTitle("Solicitudes de pago");
				break;
			case "/admin/historial-de-pagos":
				setTitle("Historial de pagos");
				break;
			case "/admin/contacto":
				setTitle("Contacto");
				break;
			case "/admin/textos":
				setTitle("Textos");
				break;
			case "/admin/consultas":
				setTitle("Consultas");
				break;
			case "/admin/vocabulario":
				setTitle("Vocabulario");
				break;
			case "/admin/vocabulario/subcategorias":
				setTitle("Vocabulario-Subcategorias");
				break;
			case "/admin/vocabulario/seminario/:seminaryNum":
				setTitle("Vocabulario-Seminarios");
				break;
			case "/admin/vocabulario/idioma/:language/:languageId":
				setTitle("Vocabulario-Idioma");
				break;
			case "/admin/vocabulario/generacion/:generationId":
				setTitle("Vocabulario-generación");
				break;
			case "/admin/vocabulario/quiz/:seminaryNum":
				setTitle("Vocabulario-Quiz");
				break;
			case "/admin/formularios":
				setTitle("Formularios");
				break;
			case "/consultas/:num":
				setTitle("Consultas");
				break;
		}
	}, [path]);

	return title;
};

export default useTitleFromPath;
