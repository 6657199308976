import { SetStateAction, useEffect, useState, useContext } from "react";
import { Modal, Row, Col, Form, InputGroup, FormControl } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import {
	EditIcon2,
	Arrow,
	Registro_Actividad,
} from "assets/Imports";
import { MenusDataContext } from "../../context/menu-data.provider";
import "./stylesModal.scss";
import "../../CollaboratorContainer.scss";
import EventLog from "./eventsModal";

export function MainUserModal(props) {
	const { show, Hide, selectedMainUser } = props;
	const [sessionsModal, setsessionsModal] = useState<boolean>(false);
	const [showEvents, setshowEvents] = useState(false);
	const { rutasAccesibles } = useContext(MenusDataContext);
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			border: "none",
			boxShadow: "none", // Elimina la sombra del select
			//width: '100%',Ajusta el ancho del select
		}),
		indicatorSeparator: () => ({
			display: "none",
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			paddingRight: "5px", // Aumenta este valor para mover el icono hacia la derecha
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "#000000", // Establece el color del texto como negro oscuro
			fontWeight: "bolder",
		}),
	};

	const customSelectComponents = {
		DropdownIndicator: () => <BsPlus style={{ fontSize: "35px", color: "black" }} />,
	};

	if (!selectedMainUser) return <></>;
	return (
		<>
			<Modal show={show} onHide={Hide} dialogClassName="user-profile-modal collaborator modal-content">
				<EventLog
					show={showEvents}
					selectedUser={selectedMainUser}
					Hide={() => {
						setshowEvents(false);
					}}
				/>
				<Modal.Header className="header" closeButton>
					<Modal.Title className="title">
						<h3 style={{ fontSize: "2rem !important" }}>Detalles</h3>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="modal-body">
					<div>
						{/*<Toaster />*/}
						<div className="name-streak">
							<h2>{selectedMainUser?.name}</h2>
						</div>

						<label className="modal-labels" htmlFor="student-name">
							Nombre:
						</label>
						<div className="position-relative">
							<FormControl
								className={"input1"}
								defaultValue={selectedMainUser?.name}
								id="student-name"
								type="text"
								readOnly
								placeholder="Nombre del  super administrador"
							/>
						</div>

						<label className="modal-labels" htmlFor="student-email">
							Correo:
						</label>
						<div>
							<input
								className="input1"
								defaultValue={selectedMainUser?.email}
								id="student-email"
								type="email"
								style={{ border: "2px solid transparent" }}
								readOnly
							/>
						</div>
						<label className="modal-labels" htmlFor="student-secondaryEmail">
							Secciones asigndas:
						</label>
						<div className="collaborator-sections" onClick={() => setsessionsModal(true)}>
							{rutasAccesibles.length === 0
								? "Sin secciones asignadas"
								: rutasAccesibles?.map((section, i) => {
										return section.Titulo === "NA"
											? null
											: section.Titulo +
													(i === rutasAccesibles.length - 2 ? " y " : i === rutasAccesibles.length - 1 ? "." : ", ");
								  })}

							<div className="top-0 end-0 text-right w-100" style={{ marginLeft: "90%" }}>
								<img src={EditIcon2} className="m-0"></img>
							</div>
						</div>
						{/*<div>
							{roles.length > 0 && (
								<select
									className="input1"
									placeholder={"super admin"}
									value={selectedRole}
									// Establece el valor seleccionado en el campo
									onChange={(e) => setSelectedRole(e.target.value)}
								>
									<option value="" disabled>
										Sin asignar
									</option>
									{roles.map((role) => {
										return <option value={role.value}>{role.label}</option>;
									})}
								</select>
							)}
						</div>*/}

						<div
							className="d-flex align-items-center"
							style={{ marginTop: "25px" }}
							onClick={() => {
								setshowEvents(true);
							}}
						>
							<img src={Registro_Actividad}></img>
							<img src={Arrow} className="fa fa-icono" style={{ marginLeft: "8px" }} />
						</div>
					</div>
				</Modal.Body>
				{/*	<Modal.Footer className="modal-footer">
					<div
						className="d-flex justify-content-start align-items-center"
						style={{ cursor: "pointer", marginRight: "50%" }}
						onClick={() => {
							isprevieMode(selectedUser?.seccionsCollaborator.length > 0);
						}}
					>
						<img src={Eye} className="fa fa-icono" />
						<span className="span-collaborator">Vista de colaborador</span>
					</div>

					<div className="d-flex justify-content-between w-100">
						<button
							className="btn btn-secondary secondary-action text-truncate"
							style={{ maxWidth: "100%", marginRight: "10px", display: "flex" }}
							type="button"
							onClick={async () => {
								const result = await Swal.fire({
									title: `¿Eliminar a ${selectedUser.NameCollaborator} como tu colaborador?`,
									text: "No podrás revertir esta acción!",
									icon: "warning",
									showCancelButton: true,
									confirmButtonColor: "#3085d6",
									cancelButtonColor: "#d33",
									confirmButtonText: "Sí, eliminar!",
									backdrop: false,
								});

								if (result.isConfirmed) {
									deleteCollaborator(selectedUser?.id);
									props.Hide();
									toast.success("Se ha removido este colaborador!");
									fetchCollaborators(); // recarga los usuarios después de eliminar
								}
							}}
						>
							Eliminar colaborador
						</button>

						<button className="btn btn-secondary secondary-action" type="submit" onClick={save}>
							Guardar
						</button>
					</div>
				</Modal.Footer>*/}
			</Modal>
		</>
	);
}
