import axios from "axios";

export const editWordSeminaries = async (seminaries: Array<number>, wordData) => {
	return axios
		.post(process.env.REACT_APP_API_URL + `words/editWordSeminaries`, { seminaries: seminaries, wordData: wordData })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			console.log(error);
			return error;
		});
};
