import "./StudentsReviews.scss";
import WorldFlagsMap from "@assets/images/world-flags-map.png";
import { Students } from "./utils/Students";
import { StudentReviewCard } from "./components/StudentReviewCard/StudentReviewCard";
import { useEffect, useState } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "initFirebase";

export const StudentsReviews = () => {
	const [reviews, setReviews] = useState<Array<any>>([]);
	const getReviews = async () => {
		const testimonialsRef = collection(db, "testimonials");
		const snap = await getDocs(testimonialsRef);
		const temp: Array<any> = [];

		snap.docs.map((doc) => {
			const data = {
				desc: doc.data().desc,
				name: doc.data().name,
				id: doc.id,
			};

			temp.push(data);
		});

		setReviews(temp);
	};

	useEffect(() => {
		getReviews();
	}, []);

	return (
		<div className="students-reviews-main-container">
			<h2>Qué opinan nuestros estudiantes</h2>
			<div className="content-container">
				<div className="wrapper">
					<div className={"reviews-cards-container " + (reviews.length ? "slide-active" : "")}>
						{reviews.length > 0 &&
							reviews.map((review, i) => {
								return <StudentReviewCard student={review} key={review.name + i} />;
							})}
					</div>
					<div className={"reviews-cards-container " + (reviews.length ? "slide-active" : "")}>
						{reviews.length > 0 &&
							reviews.map((review, i) => {
								return <StudentReviewCard student={review} key={review.name + i + "-2"} />;
							})}
					</div>
				</div>
				<img className="bg" src={WorldFlagsMap} alt="world flags location" />
			</div>
		</div>
	);
};
