import "./Card.scss";

interface CardPropsI {
	language: {
		spanish: string;
		description: string;
		img: any;
		language: string;
	};
}

export const Card = (props: CardPropsI) => {
	const { language } = props;
	return (
		<div className={"home-lang-card " + language.language}>
			<div className="content-container">{language.img}</div>
			<div className="bottom-rectangle">
				<span>{language.spanish}</span>
				{language.description && <span className="description">-{language.description}-</span>}
			</div>
		</div>
	);
};
