import { Modal } from "react-bootstrap";
import "./InvoiceSentModal.scss"

export function InvoiceSentModal(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            dialogClassName="invoice-sent-modal-dialog"
            contentClassName="invoice-sent-modal-content"
        >
            <Modal.Header closeButton className="">
                <Modal.Title className="">Se ha enviado tu solicitud.</Modal.Title>
            </Modal.Header>
            <Modal.Body className="invoice-sent-body">Recibirás un correo a la dirección {props.emailForInvoice} en cuanto esta haya sido procesada.</Modal.Body>
            <Modal.Footer className="is-modal-footer">
                <button
                    className="close-popup-btn"
                    onClick={props.onHide}
                >
                    Cerrar
                </button>
            </Modal.Footer>
		</Modal>
    )
}