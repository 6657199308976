import { doc, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import "./AdminGenSelectorStyles.scss";
import { CustomSpinner } from "@components/Spinner/SpinnerMyAccountView";
import { useRouteMatch } from "react-router-dom";
import { useAuth } from "@hooks/useAuth";
import { EyeIcon } from "assets/Imports";

export function AdminGenSelector(props) {
	const user = useAuth().user;

	const [IwantAllGens, setAllGens] = useState(
		(props?.gen && props?.gen?.futureMaterials <= 0) || props?.gen?.futureMaterials === 60 ? true : false
	);
	const handleToggleShowAdditionalGens = async () => {
		setAllGens(!IwantAllGens);
	};

	useEffect(() => {
		if (IwantAllGens === false) {
			props.handleCheck("active");
		} else {
			props.handleCheck("all");
			props.setEyeColor("orange");
		}
	}, [IwantAllGens]);

	let { url } = useRouteMatch();

	const changeGenAdmin = async (genId) => {
		const docRef = doc(db, "users", user.id);
		props.setGenId(genId);

		await updateDoc(docRef, {
			generationId: genId,
		});
	};

	function getName(name: string) {
		let nameTemp = name.replace("Programa de Alto Rendimiento en 8 Idiomas Simultáneos - ", "");
		let str = nameTemp.split("");

		let index = nameTemp.indexOf("-");
		if (nameTemp[index + 1] === " ") {
			str.splice(index + 1, 1);
		}
		if (nameTemp[index - 1] === " ") {
			str.splice(index - 1, 1);
		}

		return str.join("");
	}

	if (props.load) {
		return <CustomSpinner size={50} height={"auto"} borderSize={4} />; // O lo que sea que desees mostrar durante la carga.
	}
	return (
		<div className={url === "/micuenta" ? "admin-profile" : "admin-gen-selector-bar-container"}>
			<h3>Programa de alto rendimiento en 8 idiomas</h3>

			<div className="admin-gen-selector-bar">
				{props.gens.map((gen, index) => {
					const name = getName(gen.name).split(" ");
					return (
						<div
							className={
								"cell d-flex flex-column " +
								(props.genId === gen.id ? "active " : "") +
								(props.gens.length - 1 === index ? "border-0" : "")
							}
							onClick={() => changeGenAdmin(gen.id)}
							key={"GenOption-" + index}
						>
							<span>{name[0]}</span>
							<span>{name[1]}</span>
							<span>{name[2]}</span>
						</div>
					);
				})}

				<div className="row">
					<div className="col-auto" style={{ position: "relative" }}>
						<img
							src={EyeIcon}
							className={props.eyeColor}
							alt="Descripción de la imagen"
							title={IwantAllGens ? "Click para mostrar generaciones activas" : "Click para mostrar todas las generaciones"} // Aquí está tu tooltip
							style={{
								cursor: "pointer",
								zIndex: 2,
							}}
							onClick={handleToggleShowAdditionalGens}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
