import { useEffect, useState } from "react";
import "./Video.scss";
import { retreiveDynamicVideoVimeo } from "@services/DynamicTextService";
import ReactPlayer from "react-player";

export const Video = () => {
	const [video, setVideo] = useState("");
	const retreiveVideo = async () => {
		const video = await retreiveDynamicVideoVimeo();
		setVideo(video);
	};
	useEffect(() => {
		retreiveVideo();
	}, []);

	return (
		<div className="video-home-main-container">
			<h3>Te invitamos a conocer nuestra dinámica de trabajo</h3>
			<div className="embed-responsive embed-responsive-16by9">
				<ReactPlayer url={video} className="react-player" controls={true} width="100%" height="100%" />
				{/* <iframe
					src={video}
					frameBorder="0"
					allow="autoplay; encrypted-media"
					allowFullScreen
					title="video"
					className="embed-responsive-item"
				/> */}
			</div>
		</div>
	);
};
