import { getAuth } from "firebase/auth";
import { query, collection, where, getDocs, getCountFromServer } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./VocabularyDetailSeminaryCard.scss";
import { db } from "initFirebase";
import { NextArrow } from "assets/Imports";
import { fetchProgressLanguageBySeminary } from "@services/VocabularyService";
import { languagesFlags, languagesSpanish } from "utils/VocabularyConstants";
import { useSeminaryVocabularyInfoModalContext } from "contexts/SeminaryVocabularyInfoModalContext";

const detailSeminary = {};

function VocabularyDetailSeminaryCard({ seminary, gKey }) {
	const [quizzes, setQuizzes] = useState<number>(0);
	const [detailData, setDetailData] = useState<
		{ language: string; total: number; dominatedWords: number }[]
	>([]);
	const user = getAuth();
	const { openModal } = useSeminaryVocabularyInfoModalContext();

	const getNumberOfQuizzes = async () => {
		const q = query(
			collection(db, "quiz"),
			where("userId", "==", user.currentUser?.uid),
			where("seminaryNum", "==", seminary)
		);

		const qs = await getCountFromServer(q);

		detailSeminary[seminary] = {
			...detailSeminary[seminary],
			quizzes: qs.data().count,
		};
		setQuizzes(qs.data().count);
	};

	const getSeminaryDetail = async () => {
		try {
			const res = await fetchProgressLanguageBySeminary(seminary, seminary);
			setDetailData(res);
			detailSeminary[seminary] = { ...detailSeminary[seminary], data: res };
			if (detailSeminary[seminary].quizzes === undefined) getNumberOfQuizzes();
		} catch (error) {
			console.error(" error fetching seminary details", error);
		}
	};

	useEffect(() => {
		if (detailSeminary[seminary] === undefined || detailSeminary[seminary].data === undefined) {
			getSeminaryDetail();
		} else {
			setQuizzes(detailSeminary[seminary].quizzes);
			setDetailData(detailSeminary[seminary].data);
		}
	}, []);

	return (
		<>
			<div className={"vocabulary-detail-seminary-card-container open-vocabulary-detail"}>
				<div className="content-container">
					<div className="header">
						<h3>Vista general</h3>
						<p className="test-progress-text">{quizzes} rondas de estudio</p>
					</div>
					<div className="languages-main-container">
						<div className="languages-container">
							{detailData.map((language) => (
								<div
									key={language.language}
									className="lang-container"
									onClick={() => openModal(seminary, language.language)}
								>
									<img
										src={languagesFlags[language.language]}
										alt={`a country flag with that speak ${language.language}`}
									/>
									<div className="lang-info">
										<h3>{languagesSpanish[language.language]}</h3>
										<p
											className={
												(language.dominatedWords / language.total) * 100 === 100 ? "complete" : ""
											}
										>
											{language.dominatedWords}/{language.total} <span>términos dominados</span>
										</p>
									</div>
								</div>
							))}
						</div>
						<div className="number-of-tests-text-container">
							<p className="test-progress-text">{quizzes} rondas de estudio</p>
						</div>
					</div>
				</div>
				<div className="footer">
					<Link to={"/micuenta/vocabulario/" + seminary}>
						<p>
							Estudiar este seminario <img src={NextArrow} alt="flecha apuntando a la derecha" />
						</p>
					</Link>
				</div>
			</div>
		</>
	);
}

export default VocabularyDetailSeminaryCard;
