import { useCallback, useEffect, useRef, useState } from "react";
import "./ModulesSlider.scss";
import { Slide } from "./Slide/Slide";
import { modules } from "./utils/Modules";

const INTERVAL_TIME = 5000;
const MODULES_LENGTH = modules.length;

export const ModulesSlider = () => {
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const slidesRef = useRef<HTMLDivElement | null>(null);

	const goToSlide = useCallback((index: number) => {
		setActiveIndex(index);
		if (slidesRef.current) {
			const slideWidth = slidesRef.current.clientWidth;
			const newPosition = index * slideWidth * 0.92;
			slidesRef.current.scrollTo({
				left: newPosition,
				behavior: "smooth",
			});
		}
	}, []);

	useEffect(() => {
		const changeSlideInterval: NodeJS.Timeout = setInterval(() => {
			const newIndex = activeIndex === MODULES_LENGTH - 1 ? 0 : activeIndex + 1;
			goToSlide(newIndex);
		}, INTERVAL_TIME);

		return () => {
			clearInterval(changeSlideInterval);
		};
	}, [activeIndex]);
	
	return (
		<div className="modules-slider-main-container">
			<div className="navigation-container">
				{Array.from({ length: modules.length }).map((_, index) => {
					return (
						<div
							className={`nav-circle ${activeIndex === index ? "active" : ""}`}
							onClick={() => goToSlide(index)}
							key={"slide-" + index}
						></div>
					);
				})}
			</div>
			<div className="slides-container" ref={slidesRef}>
				{modules.map((module) => {
					return <Slide module={module} key={module.romanNumber} />;
				})}
			</div>
		</div>
	);
};
