import "./Home.scss";
import { InstitutionDescription } from "./components/IntitutionDescription/InstitutionDescription";
import { LanguagesCards } from "./components/LanguagesCards/LanguagesCards";
import { Locations } from "./components/Locations/Locations";
import { Modes } from "./components/Modes/Modes";
import { ModulesSlider } from "./components/ModulesSlider/ModulesSlider";
import { ProgramMainInfo } from "./components/ProgramMainInfo/ProgramMainInfo";
import { StudentsReviews } from "./components/StudentsReviews/StudentsReviews";
import { Video } from "./components/Video/Video";
import { Contact } from "./components/contact/Contact";
import MainSlider from "./components/mainSlider/MainSlider";

function Home() {
	return (
		<div className="home-main-container">
			<MainSlider />
			<ProgramMainInfo />
			<LanguagesCards />
			<InstitutionDescription />
			<Video />
			<ModulesSlider />
			<Modes />
			<StudentsReviews />
			<Locations />
			<Contact />
		</div>
	);
}

export default Home;
