const form2Required = [
	{ input: "email", text: "Correo", step: "1" },
	{ input: "q14", text: "¿Qué mejorarías en la plataforma web?", step: "3" },
	{ input: "q13", text: "Comentario sobre el programa", step: "3" },
];

export const whichIsWrongForm2 = (form, user?) => {
	let errors = "Faltaron de llenarse los siguientes campos:\n";
	form2Required.map((at) => {
		if (user !== undefined && at.input !== "email" || user === undefined) {
			if (form.elements[at.input].type === undefined && form.elements[at.input][0].checkValidity() === false) {
				errors += `\n- ${at.text}. En el paso ${at.step}.`;
			} else if (form.elements[at.input].type !== undefined && form.elements[at.input].checkValidity() === false) {
				errors += `\n- ${at.text}. En el paso ${at.step}.`;
			}
		}
	});
	alert(errors);
};

const form1Required = [
	{ input: "name", text: "Nombre", step: "1" },
	{ input: "firstLastName", text: "Apellido paterno", step: "1" },
	{ input: "secondLastName", text: "Apellido materno", step: "1" },
	{ input: "gender", text: "Género", step: "1" },
	{ input: "email", text: "Correo", step: "2" },
	{ input: "cellphone", text: "Teléfono de contacto", step: "2" },
	{ input: "haveStudied", text: "¿Has estudiado algún idioma?", step: "3" },
	{ input: "haveCertification", text: "¿Cuentas con alguna certificación?", step: "3" },
	{ input: "academiaKnown", text: "¿Cómo conociste la Academia?", step: "3" },
	{ input: "reasonToLearn", text: "Razón por la que te interesa convertirte en políglota", step: "3" },
	{ input: "speakOtherLang", text: "¿Hablas algún idioma extranjero?", step: "3" },
];

export const whichIsWrongForm1 = (form) => {
	let errors = "Faltaron de llenarse los siguientes campos:\n";
	form1Required.map((at) => {
		if (form.elements[at.input].type === undefined && form.elements[at.input][0].checkValidity() === false) {
			errors += `\n- ${at.text}. En el paso ${at.step}.`;
		} else if (form.elements[at.input].type !== undefined && form.elements[at.input].checkValidity() === false) {
			errors += `\n- ${at.text}. En el paso ${at.step}.`;
		}
	});
	alert(errors);
};
