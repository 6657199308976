"use client";
import React, { useEffect } from "react";
import { Howl, Howler } from "howler";
import firebase from "firebase/compat/app";

const useAudios = () => {
	let sound;

	const playWordAudio = (audioString: string) => {
		try {
			if (sound) {
				sound.unload();
			}

			sound = new Howl({
				src: ["data:audio/mpeg;base64," + audioString],
				format: ["mp3"],
			});
			Howler.mute(false);
			sound.play();
		} catch (error) {
			console.log("play word audio", error);
			throw error;
		}
	};

	const getGKey = async () => {
		let tryKey = firebase.functions().httpsCallable("getAudio");
		return tryKey()
			.then((res) => {
				return res.data;
			})
			.catch((error) => {
				console.log(error);
				throw error;
			});
	};

	useEffect(() => {
		if (sound) {
			sound.unload();
			sound = null;
		}
		Howler.unload(); // Descarga todos los sonidos de Howler
		Howler.mute(false);
	}, []);

	useEffect(() => {
		// Manejador para cuando la página vuelva al foco
		const handleFocus = () => {
			if (sound) {
				sound.unload();
				sound = null;
			}
			Howler.unload(); // Descarga todos los sonidos de Howler
			Howler.mute(false); // Desmutea el sonido globalmente
			// Aquí podrías incluso auto-reproducir el audio si eso es lo que deseas
		};

		// Añadir y remover el event listener
		window.addEventListener("focus", handleFocus);
		return () => {
			window.removeEventListener("focus", handleFocus);
			if (sound) {
				sound.unload();
			}
			Howler.unload();
			Howler.mute(false);
		};
	}, []);
	return {
		playWordAudio,
		getGKey,
	};
};

export default useAudios;
