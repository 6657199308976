import { Table } from "react-bootstrap";
import "./styles.scss";

function Teachers({ teachers, setShowAddProfessorModal }) {
    return (
        <div className='edit-gen-card' style={{ minHeight: 600 }}>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <button onClick={() => setShowAddProfessorModal(true)}>Nuevo profesor</button>
            </div>


            {(teachers.length > 0) ? (
                <Table hover>
                    <thead style={{ borderTop: 'none' }}>
                        <tr style={{ borderTop: 'none' }}>
                            <th style={{ borderTop: 'none' }}>Nombre</th>
                            <th style={{ borderTop: 'none' }}>Correo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teachers.map((teacher, index) => {
                            return (
                                <tr>
                                    <td>{teacher.name}</td>
                                    <td>{teacher.email}</td>
                                </tr>
                            );

                        })}
                    </tbody>
                </Table>

            ) : (
                <h2>No hay ninguno</h2>
            )}
        </div>
    );
}

export default Teachers;