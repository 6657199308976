import React, { useEffect, useState } from "react";
import "../../CollaboratorContainer.css";
import "../../CollaboratorContainer.scss";
import { collection, collectionGroup, getDocs, query, where } from "firebase/firestore";
import { db } from "initFirebase";
import { Link, useHistory } from "react-router-dom";
import { compareGenerations, getSubtitle } from "functions/Generations/FunctionsGenerations";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { EyeIcon } from "assets/Imports";
import { SearchIcon, CheckmarIcon, CheckmarkCircle, EditIcon, Bottletrash, CloseCircle, Nofound } from "assets/Imports";
import { Table } from "react-bootstrap";
import { EditCollaboratorModal } from "../modals/EditCollaboratorModal";
import { UseRole } from "@hooks/collaborators/UseRole";
import { UpdateRolModal } from "../modals/UpdateRolModal";
import { UseCollaborator } from "@hooks/collaborators/UseCollaborator";
import { AddRolModal } from "../../components/modals/AddRolModal";
import Swal from "sweetalert2";
import { AddCollaboratorModal } from "../../components/modals/AddCollaboratorModal";
import { CustomSpinner } from "@components/Spinner/SpinnerMyAccountView";
import toast, { Toaster } from "react-hot-toast";
function TableRolCollaborator(props) {
	const { NameContainer, SubTitle, NameTh1, NameTh2, NameTh3, NameButton, user } = props;
	const [showModalEdit, setModalEdit] = useState<boolean>(false);
	const [showModalAdd, setshowModalAdd] = useState(false);
	const [showModalRol, setModalRol] = useState(false);
	const [showModalUpdateRol, setModalUpdateRol] = useState(false);
	const [selectedRol, setselectedRol] = useState([]);
	const { GetRoles, AddRole, updateRole, deleteRole } = UseRole;
	const [roles, setRoles] = useState<any>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchRoles = async () => {
		try {
			setLoading(true);
			const rolesData = await GetRoles();
			setRoles(rolesData);
			setLoading(false);
		} catch (error) {
			console.error("Error al obtener los roles:", error);
			setLoading(true);
		}
	};

	useEffect(() => {
		fetchRoles();
	}, []);

	const handleAddRole = (newRole) => {
		try {
			AddRole(newRole);
			const updatedRoles = [...roles, newRole]; // Agrega el nuevo rol a los roles existentes
			setRoles(updatedRoles);
		} catch (error) {
			console.error("Error al agregar el nuevo rol:", error);
		}
	};

	// Función para filtrar los roles
	const filterRoles = (role) => {
		return role.roleName.toLowerCase().includes(searchTerm.toLowerCase());
	};

	return (
		<>
			<AddRolModal
				show={showModalRol}
				Hide={() => {
					setModalRol(false);
				}}
				AddRole={handleAddRole}
				fetchCollaborators={fetchRoles}
			/>
			<Toaster />
			<UpdateRolModal
				show={showModalUpdateRol}
				Hide={() => {
					setModalUpdateRol(false);
				}}
				selectedRol={selectedRol}
				updateRole={updateRole}
				fetchRoles={fetchRoles}
			/>
			<div className="collaborator-title-container">
				<h1>{NameContainer}</h1>
			</div>

			<div className="collaborator-subtitle-container">
				<p className="label-dark" style={{ margin: 0 }}>
					{SubTitle}.
				</p>
				<div style={{ border: "2px solid #7b7b7e", width: 300, borderRadius: 10, padding: "10px 20px", display: "flex" }}>
					<img src={SearchIcon} alt="search-icon" />
					<input
						style={{ border: "none", paddingLeft: 15, width: "100%" }}
						placeholder="Buscar Rol"
						type="text"
						onChange={(event) => setSearchTerm(event.target.value)}
					/>
				</div>
			</div>

			<div className="edit-gen-card">
				<div className="button-container d-flex justify-content-end w-100 ">
					<button
						className="tertiary return-btn-style"
						onClick={() => {
							setModalRol(true);
						}}
					>
						{NameButton}
					</button>
				</div>
				{loading === true ? (
					<CustomSpinner size={100} height={"100%"} borderSize={4} />
				) : (
					<Table hover responsive>
						<thead className="border-0 ">
							<tr className="">
								<th className="border-0">{NameTh1}</th>

								<th className="border-0">{NameTh3}</th>
							</tr>
						</thead>
						<tbody>
							{roles.length > 0 ? (
								roles.map((data, index) => {
									if (filterRoles(data)) {
										return (
											<tr key={index}>
												<td style={{ fontWeight: "bold" }}>{data?.roleName}</td>
												<td className="d-flex justify-content-end" style={{ gap: 10 }}>
													<img
														src={EditIcon}
														alt="edit-icon"
														style={{ cursor: "pointer" }}
														onClick={() => {
															setselectedRol(data);
															setModalUpdateRol(true);
														}}
														className="me-2"
													/>

													<img
														src={Bottletrash}
														alt="edit-icon"
														style={{ cursor: "pointer" }}
														onClick={() => {
															Swal.fire({
																title: `¿Eliminar el rol ${data?.roleName}?`,
																text: "Esto ya no se podrá revertir!",
																icon: "warning",
																showCancelButton: true,
																confirmButtonColor: "#3085d6",
																cancelButtonColor: "#d33",
																confirmButtonText: "Si, eliminalo!",
																backdrop: false,
															}).then(async (result) => {
																if (result.isConfirmed) {
																	try {
																		await deleteRole(data?.id); // Esperar a que se complete la operación de eliminación
																		fetchRoles(); // Llama a fetchRoles solo después de que se haya completado la operación de eliminación
																	} catch (error) {
																		console.error("Hubo un error al eliminar el rol:", error);
																	}
																}
															});
														}}
													/>
												</td>
											</tr>
										);
									}
									return null;

									// Retornar null si el role no pasa el filtro.
								})
							) : (
								<tr>
									<td colSpan={3}>
										<div className="d-flex flex-column justify-content-center align-items-center">
											<img src={Nofound} alt="No data" className="img-fluid" />
											<p className="text-center text-muted fw-bold" style={{ fontWeight: "bolder" }}>
												Aún no existen roles
											</p>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				)}
			</div>
		</>
	);
}

export default TableRolCollaborator;
