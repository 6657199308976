import { Modal } from "react-bootstrap";
import "./EditSeminariesModal.scss";
import { CustomSpinner } from "@components/Spinner/SpinnerMyAccountView";
import { EditSeminariesWordI } from "interfaces/Vocabulary/EditSeminariesModal";
import { Nullable } from "interfaces/Interfaces";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { object, array, string } from "yup";
import { seminariesNumber } from "functions/DefaultValues";
import { editWordSeminaries } from "@api/EditWordSeminaries";

interface EditSeminariesModalPropsI {
	show: boolean;
	onHide: () => void;
	word: Nullable<EditSeminariesWordI>;
	updateWords: () => void;
}

const EditSeminariesSchema = object({
	seminaries: array().of(string()),
});

function EditSeminariesModal(props: EditSeminariesModalPropsI) {
	const { show, onHide, word, updateWords } = props;
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

	const closeModal = () => {
		onHide();
	};

	const formik = useFormik({
		initialValues: {
			seminaries: [],
		},
		validationSchema: EditSeminariesSchema,
		onSubmit: async (values) => {
			setLoadingSubmit(true);
			const seminaries = values.seminaries.map((seminary) => parseInt(seminary, 10));
			editWordSeminaries(seminaries, word)
				.then((res) => {
					updateWords();
					setLoadingSubmit(false);
					onHide();
				})
				.catch((error) => {
					console.log(error);
				});
		},
	});

	const cleanSelection = () => {
		formik.setValues({ seminaries: [] });
	};

	const handleOptionClick = (seminary: string) => {
		const selectedSeminaries: Array<string> = [...formik.values.seminaries];

		const index = selectedSeminaries.indexOf(seminary);
		if (index !== -1) {
			selectedSeminaries.splice(index, 1);
		} else {
			selectedSeminaries.push(seminary);
		}

		formik.setFieldValue(
			"seminaries",
			selectedSeminaries.sort((a, b) => parseInt(a) - parseInt(b))
		);
	};

	useEffect(() => {
		if (show && word) {
			formik.setFieldValue("seminaries", word.seminaries);
			setLoading(false);
		} else {
			setLoading(true);
		}
	}, [show]);

	return (
		<Modal
			show={show}
			onHide={closeModal}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			className="edit-word-seminaries-modal"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Editar seminarios</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<CustomSpinner size={60} borderSize={4} height={300} />
				) : (
					<form onSubmit={formik.handleSubmit}>
						<div className="c-row">
							<div className="header">
								<label htmlFor="seminaries">Seminarios:</label>
								<h4 role="button" onClick={cleanSelection}>
									Limpiar
								</h4>
							</div>
							<select name="seminaries" id="seminaries" multiple size={10} value={formik.values.seminaries}>
								{seminariesNumber
									.sort((a, b) => a - b)
									.map((seminary) => {
										return (
											<option value={seminary} key={"option-" + seminary} onClick={() => handleOptionClick(seminary.toString())}>
												{seminary}
											</option>
										);
									})}
							</select>
						</div>
						<div className="actual-selection-container">
							<h2>Selección actual: </h2>
							<p>
								{formik.values.seminaries.length > 0
									? formik.values.seminaries.map((seminary, i) => {
											return `${seminary}${i + 1 < formik.values.seminaries.length ? ", " : "."}`;
									  })
									: "Sin seminarios"}
							</p>
						</div>
					</form>
				)}
			</Modal.Body>
			<Modal.Footer className="justify-content-center justify-content-sm-between">
				<button className="action-btn secondary-btn-bg" onClick={closeModal}>
					Cerrar
				</button>
				<button className={`action-btn linear-bg`} onClick={formik.submitForm} disabled={loadingSubmit}>
					{loadingSubmit ? <CustomSpinner height={"auto"} size={"1.6rem"} borderSize={3} /> : "Guardar"}
				</button>
			</Modal.Footer>
		</Modal>
	);
}

export default EditSeminariesModal;
