import { useEffect, useState } from "react";
import { fetchPricesByCurrencyAndModality } from "../PricesService";

const PricesStep = (props) => {
	const [prices, setPrices] = useState<any>();
	const { setEdit } = props;

	return (
		<>
			<li onClick={() => setEdit("prices-USD")}>USD</li>
			{/* IMPROVE: Agregar opcion para divisa mexicana? */}
			<li onClick={() => setEdit("prices-MXN")}>MXN</li>
		</>
	);
};

export default PricesStep;
