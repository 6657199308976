import { getDate } from "functions/Functions";
import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import "./styles.scss";
import { useCurrencyContext } from "contexts/CurrencyContext";
import { Currency } from "@containers/Admin/DynamicTextContainer/components/Prices/PricesTypes";

interface PaymentCardProps {
	selectedCard: boolean;
	cardInfo: any;
	modality: string;
	isPromo: boolean;
	amountOfClasses?: number;
}

function PaymentCardA(props: PaymentCardProps) {
	const [onHover, setOnHover] = useState(false);
	const { currencyCountry, prices } = useCurrencyContext();
	const actualDate = new Date();
	const pricesForCountry = currencyCountry === "MXN" ? prices.MXN : prices.USD;
	const pricesForModality =
		props.modality === "online" ? pricesForCountry.online : pricesForCountry["in-person"];

	
	useEffect(() => {
		setOnHover(props.selectedCard);
	}, [props.selectedCard]);

	return (
		<div className="payment__card text-left">
			<Card
				// style={{ width: '24.5rem', height: '29rem' }}
				className={
					"" +
					(onHover ? " hover shadow" : " shadow") +
					(props.cardInfo.promoDate !== null && actualDate > props.cardInfo.promoDate
						? " disable"
						: "")
				}
			>
				<Card.Body>
					<Card.Title>{props.cardInfo.nombre}</Card.Title>
					<Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
					<Card.Text>
						<div className="d-none d-sm-block">
							{/* <span>-</span> */}
							<p className="d-flex align-items-center">
								<span
									style={{
										fontWeight: "bolder",
										fontSize: "2rem",
										color: "red",
										marginRight: "5px",
									}}
								>
									-
								</span>{" "}
								Costo por seminario:
							</p>
							<p className="highlight-text-info-card">
								{currencyCountry === "mx"
									? props.cardInfo.costo
									: `$${
											props.isPromo ? pricesForModality.promoClass : pricesForModality.regularClass
									  } USD`}
							</p>
							<p></p>
							<p className="d-flex align-items-center">
								<span
									style={{
										fontWeight: "bolder",
										fontSize: "2rem",
										color: "red",
										marginRight: "5px",
									}}
								>
									-
								</span>{" "}
								Duración del programa:
							</p>
							<p
								className="highlight-text-info-card"
								style={{ fontWeight: "bolder", fontFamily: "Lato", textAlign: "center" }}
							>
								{props.cardInfo.duracion}
							</p>
							<p className="d-flex align-items-center">
								<span
									style={{
										fontWeight: "bolder",
										fontSize: "2rem",
										color: "red",
										marginRight: "5px",
									}}
								>
									-
								</span>{" "}
								{props.cardInfo.pagos}
							</p>
						</div>
						<div className="text-center">
							<p className="d-none">Inversión total: </p>
							<p className="price highlight-text-info-card">
								{currencyCountry === "mx"
									? props.cardInfo.inversion
									: `$${
											props.isPromo
												? (
														pricesForModality.promoClass * (props.amountOfClasses ?? 60)
												  ).toLocaleString()
												: (
														pricesForModality.regularClass * (props.amountOfClasses ?? 60)
												  ).toLocaleString()
									  } USD`}
							</p>
							<p className="d-block d-sm-none">{props.cardInfo.pagos}</p>
							<p className="d-none d-sm-block">{props.cardInfo.final}</p>
							{props.cardInfo.extra && <p className="extra">{props.cardInfo.extra}</p>}
						</div>
					</Card.Text>
					{/* <a
            href={props.cardInfo.url}
            rel="noopener noreferrer"
            target="_blank"
            className="stretched-link"
          >
            {}
          </a> */}
					{props.cardInfo.inversion === "$12,000 MXN" && (
						<p
							className={
								"text-center color-limit-time" + (onHover ? " active-text-limit-time" : "")
							}
						>
							<b style={{ fontSize: "1rem" }}>
								<a>Válida por tiempo limitado</a>
							</b>
						</p>
					)}
					{props.cardInfo.promoDate !== null && props.cardInfo.promoDate !== undefined && (
						<p
							className={
								"text-center color-limit-time" + (onHover ? " active-text-limit-time" : "")
							}
						>
							<a style={{ fontSize: "0.9rem" }}>
								Válida hasta el {getDate(props.cardInfo.promoDateString)}
							</a>
						</p>
					)}
				</Card.Body>
			</Card>
		</div>
	);
}

export default PaymentCardA;
