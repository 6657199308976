import { Link } from "react-router-dom";

export function SolicitudCard({ data }) {
	const seeReceipt = () => {
		window.open(data.image, "_blank");
	};

	function getDate(materialDate) {
		let stringSplit = materialDate.split("/");
		let month = "";
		switch (parseInt(stringSplit[1])) {
			case 0:
				month = "enero";
				break;
			case 1:
				month = "febrero";
				break;
			case 2:
				month = "marzo";
				break;
			case 3:
				month = "abril";
				break;
			case 4:
				month = "mayo";
				break;
			case 5:
				month = "junio";
				break;
			case 6:
				month = "julio";
				break;
			case 7:
				month = "agosto";
				break;
			case 8:
				month = "septiembre";
				break;
			case 9:
				month = "octubre";
				break;
			case 10:
				month = "noviembre";
				break;
			case 11:
				month = "diciembre";
				break;
		}

		let date = stringSplit[0] + " de " + month + " de " + stringSplit[2];
		return date;
	}

	let num = parseInt(data.amount);
	const date = new Date(data.date);

	const date2 = new Date();
	let dateSplit = data.createdAt.split("/");
	date2.setDate(dateSplit[0]);
	date2.setMonth(dateSplit[1]);
	date2.setFullYear(dateSplit[2]);

	return (
		<div className="solicitud-card-container">
			<div className="content-container-solicitud-card">
				<div className="right">
					<p className="text-up card-amount">
						<a>${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} MXN</a>
					</p>
					<p className="text-down card-name">{data.name}</p>
					<p className="text-down card-date">Fecha de pago:</p>
					<p className="text-down card-date text-center" style={{ color: "#959595" }}>
						{date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}
					</p>
					<p className="text-down card-date">Fecha de solicitud:</p>
					<p className="text-down card-date text-center" style={{ color: "#959595" }}>
						{date2.getDate() + "/" + (date2.getMonth() + 1) + "/" + date2.getFullYear()}
					</p>
					<p className="card-concept">{data.concept}</p>
					{data.message !== undefined && data.message !== null && data.message.length > 0 && (
						<div className="denied-message-container">
							<h3>
								<b>Comentarios:</b>
							</h3>
							<p>{data.message}</p>
						</div>
					)}
				</div>
				<div className="left">
					<p className="text-up">Estado</p>
					{data.checked === true && data.verified === false ? (
						<p className="text-down" style={{ color: "#B13536" }}>
							Solicitud negada
						</p>
					) : (
						<p className="text-down" style={data.verified ? {} : { color: "#FE9A12" }}>
							{data.verified ? "Solicitud aceptada" : "Solicitud en revisión"}
						</p>
					)}
					{data.image.length > 0 && (
						<button id="btn-voucher" className="action-btn secondary-btn-bg" onClick={seeReceipt}>Ver comprobante</button>
					)}
				</div>
			</div>
			{data.verified && (
				<div className="text-center">
					<Link to={"/facturacion"}>
						<button className="return-btn-style" style={{ padding: "0 10px" }}>
							Solicitar factura
						</button>
					</Link>
				</div>
			)}
		</div>
	);
}
