import React from 'react';
import { Modulo } from '@models/module';
import { Col, Image, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/esm/Container';
import './styles.scss';

interface ModulesProps {
  data: Modulo;
  image: string;
}

function ModuleA(props: ModulesProps) {
  return (
    <div className="module__container">
      <Container>
        <Row>
          <Col lg={1} md={1} xs={0}></Col>
          <Col lg={5} md={4} xs={12}>
            <Image
              fluid
              src={props.image}
              alt={props.image}
              className="module1"
            />
          </Col>
          <Col lg={5} md={6} xs={12}>
            <h4>{props.data.titulo}</h4>
            <p>
              <b>Duración: </b> {props.data.duracion}
            </p>
            <p>
              <b>Habilidades al finalizarlo: </b>
              <ul>
                {props.data.habilidades &&
                  props.data.habilidades.map((i) => {
                    return <li>{i}</li>;
                  })}
              </ul>
            </p>
          </Col>
          <Col lg={1} md={1} xs={0}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default ModuleA;
