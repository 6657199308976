import "./FormsStyles.scss";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import ExportToExcel from "../../../components/Admin/ExportToExcel";
import StudentsTable from "./components/StudentsTable/StudentsTable";
import { compareGenerations } from "functions/Generations/FunctionsGenerations";

interface FomrsI{
	user:any;
	Menu?:number;
}
function FormsContainer({ user,Menu }:FomrsI) {
	const keysToLabelsForm1 = [
		["id", "ID de Formulario"],
		["userId", "ID de Usuario"],
		["createdAt", "Fecha"],
		["matricula", "Matrícula"],
		["generationId", "ID de Generación"],
		["generationName", "Nombre de generación"],
		["email", "Correo"],
		["name", "Nombre más largo para ver si se acomoda"],
		["firstLastName", "Apellido Paterno"],
		["secondLastName", "Apellido Materno"],
		["profession", "Profesión"],
		["cellphone", "Teléfono (WhatsApp)"],
		["secondaryEmail", "Dirección de correo (GMAIL)"],
		["birthdate", "Fecha de nacimiento"],
		["gender", "Género"],
		["haveStudied", "¿Has estudiado algún idioma?"],
		["whichOnes", "¿Cuáles?"],
		["whereStudied", "¿Dónde?"],
		["speakOtherLang", "¿Hablas algún idioma extranjero?"],
		["whichLangs", "¿Cuáles?"],
		["haveCertification", "¿Cuentas con alguna certificación?"],
		["whichCertification", "¿Cuáles?"],
		["academiaKnown", "¿Cómo conociste la Academia?"],
		["reasonToLearn", "Razón por la que te interesa"],
		["favoritesLangs", "Idioma(s) favorito(s)"],
		["timeOfStudy", "¿Cuánto tiempo de estudio tienes planeado dedicarle al estudio de los idiomas entre semana?"],
	];

	const keysToLabelsForm2 = [
		["id", "ID de Formulario"],
		["userId", "ID de Usuario"],
		["createdAt", "Fecha"],
		["matricula", "Matrícula"],
		["generationId", "ID de Generación"],
		["generationName", "Nombre de generación"],
		//
		["email", "Correo"],
		["name", "Nombre más largo para ver si se acomoda"],
		//
		["multipleQ1", "¿Tuviste dificultades para acceder a las grabaciones?"],
		["multipleQ1_Other", "Cuéntanos qué ocurrió. Hacemos lo mejor por mejorar."],
		["multipleQ2", "¿Viste las sesiones grabadas como forma de repaso?"],
		["multipleQ3", "¿Cuántas veces consultaste las grabaciones?"],
		["timeStudyDuringWeek", "¿Cuánto tiempo le dedicaste al estudio de los idiomas entre semana?"],
		//
		["multipleQ4", "Del 1 al 10, ¿Cómo evaluarías el contenido del curso?"],
		["multipleQ5", "Del 1 al 10, ¿Cómo evaluarías a tu profesor@?"],
		["multipleQ6", "Del 1 al 10, ¿Cómo calificarías el material de trabajo?"],
		["multipleQ7", "Del 1 al 10, ¿Cómo calificarías la atención de los administradores de WhatsApp?"],
		["multipleQ8", "Del 1 al 10, ¿Cómo calificarías tu experiencia en el programa?"],
		["multipleQ9", "Del 1 al 10, ¿Cuánto consideras que has aprendido?"],
		["multipleQ15", "Del 1 al 10, ¿Cómo calificarías tu experiencia usando la plataforma web?"],
		["multipleQ10", "¿Recomendarías el programa?"],
		["multipleQ10_Other", "¿Recomendarías el programa? (Otro)"],
		["q11", "¿Qué fue lo que más te agradó del programa?"],
		["q12", "¿Qué mejorarías en el programa?"],
		["q13", "Comentario sobre el programa"],
		["q14", "¿Qué mejorarías en la plataforma web?"],
		["mostImportantLearning", "¿Qué consideras lo más importante que has aprendido hasta el momento?"],
	];

	// Guardar la información en un Array de objetos
	const [data, setData] = useState(new Array());
	const [gens, setGens] = useState(new Array());
	const [loading, setLoading] = useState(true);
	const [menu, setMenu] = useState(Menu || 1);
	const [form, setForm] = useState("form1");
	console.log(menu)
	const getAllForms = () => {
		const newData = new Array(); // Estructura auxiliar
		var formNum = "";
		if (form == "form1") {
			formNum = "forms";
		} else if (form == "form2") {
			formNum = "forms2";
		}
		db.collection(formNum)
			.get()
			.then((snap) => {
				snap.docs.forEach((doc) => {
					const formItem = doc.data();

					newData.push({
						...formItem,
						id: doc.id, // En caso de ocuparse el ID del documento de firebase
						// Reemplazar cuando sea vacío, por un -
						userId: formItem.userId !== "" ? formItem.userId : "-",
						generationId: formItem.generationId !== "" ? formItem.generationId : "-",
					});
				});
			})
			.then(() => {
				setData([...newData]);
			});
	};

	const getGenerations = async () => {
		let array: any = [];

		const q = query(collection(db, "generations"));
		const querySnapshot = await getDocs(q);
		let i = 0;
		querySnapshot.forEach(async (doc) => {
			const newData = new Array();
			var formNum = "";
			if (form == "form1") {
				formNum = "forms";
			} else if (form == "form2") {
				formNum = "forms2";
			}
			await db
				.collection(formNum)
				.where("generationId", "==", doc.id)
				.get()
				.then((snap) => {
					snap.docs.forEach((doc) => {
						const formItem = doc.data();
						newData.push({
							...formItem,
							id: doc.id, // En caso de ocuparse el ID del documento de firebase
							// Reemplazar cuando sea vacío, por un -
							userId: formItem.userId !== "" ? formItem.userId : "-",
							generationId: formItem.generationId !== "" ? formItem.generationId : "-",
						});
					});
				})
				.then(() => {
					const data = {
						...doc.data(),
						id: doc.id,
						formData: [...newData],
					};
					array.push(data);
				});

			i++;

			if (i === querySnapshot.size) {
				array.sort(compareGenerations);
				setGens(array);
				setLoading(false);
			}
		});
	};

	function compare(a, b) {
		if (a.createdAt > b.createdAt) {
			return -1;
		}
		if (a.createdAt < b.createdAt) {
			return 1;
		}
		return 0;
	}

	// Cargar la información desde firebase
	useEffect(() => {
		getGenerations();
		getAllForms();
		console.log(form);
	}, [form]);

	if (loading) {
		return (
			<div
				style={{
					width: "100%",
					display: "flex",
					height: "80vh",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	return (
		<div className="forms-admin-container">
			<h1>{menu !== 3 ? "Formularios" : "Todos los usuarios"}</h1>
			{menu === 1 && (
				<>
					<div className="forms-btn-see-users-container">
						<button
							className="link-form-btn"
							onClick={() => {
								navigator.clipboard.writeText("https://academiadelenguasinternacionales.mx/formulario");
							}}
						>
							Copiar link del formulario de registro
						</button>
						<button className="formNumber formON" onClick={() => setMenu(1)}>
							Formulario de registro
						</button>
						<button
							className="formNumber formOFF"
							onClick={() => {
								setMenu(2);
								setForm("form2");
							}}
						>
							Encuesta de experiencia
						</button>

						<button className="return-btn-style" onClick={() => setMenu(3)}>
							Ver usuarios
						</button>
					</div>
					<h4 className="gen-forms-subtitle">Descargar todos los formularios</h4>
					<div className="forms-card">
						<div className="forms-card-row">
							<p>Descargar todos los formularios</p>
							<ExportToExcel csvData={data} headers={keysToLabelsForm1} fileName="Todos los Usuarios" />
						</div>
					</div>
					<h4 className="gen-forms-subtitle">Descargar por generación</h4>
					{gens.length > 0 &&
						gens.map((gen, index) => {
							return (
								<div className="forms-card">
									<div className="forms-card-row">
										<p>{gen.name}</p>
										<button
											className="link-form-btn"
											onClick={() => {
												navigator.clipboard.writeText("https://academiadelenguasinternacionales.mx/formulario?id=" + gen.id);
											}}
										>
											Link del formulario de registro
										</button>
										<ExportToExcel csvData={gen.formData} headers={keysToLabelsForm1} fileName={`${gen.name}`}>
											<button className="tertiary">Descargar</button>
										</ExportToExcel>
									</div>
								</div>
							);
						})}
				</>
			)}
			{menu === 2 && (
				<>
					<div className="forms-btn-see-users-container">
						<button
							className="link-form-btn"
							onClick={() => {
								navigator.clipboard.writeText("https://academiadelenguasinternacionales.mx/formulario2");
							}}
						>
							Copiar link de encuesta de experiencia
						</button>
						<button
							className="formNumber formOFF"
							onClick={() => {
								setMenu(1);
								setForm("form1");
							}}
						>
							Formulario de registro
						</button>
						<button className="formNumber formON" onClick={() => setMenu(2)}>
							Encuesta de experiencia
						</button>

						<button className="return-btn-style" onClick={() => setMenu(3)}>
							Ver usuarios
						</button>
					</div>
					<h4 className="gen-forms-subtitle">Descargar todos los formularios</h4>
					<div className="forms-card">
						<div className="forms-card-row">
							<p>Descargar todos los formularios</p>
							<ExportToExcel csvData={data} headers={keysToLabelsForm2} fileName="Todos los Usuarios" />
						</div>
					</div>
					<h4 className="gen-forms-subtitle">Descargar por generación</h4>
					{gens.length > 0 &&
						gens.map((gen, index) => {
							return (
								<div className="forms-card" key={"excel-gen-card-" + index}>
									<div className="forms-card-row">
										<p>{gen.name}</p>
										<button
											className="link-form-btn"
											onClick={() => {
												navigator.clipboard.writeText("https://academiadelenguasinternacionales.mx/formulario2?id=" + gen.id);
											}}
										>
											Link de encuesta de experiencia
										</button>
										<ExportToExcel csvData={gen.formData} headers={keysToLabelsForm2} fileName={`${gen.name}`}>
											<button className="tertiary">Descargar</button>
										</ExportToExcel>
									</div>
								</div>
							);
						})}
				</>
			)}
			{menu === 3 && <StudentsTable setMenu={setMenu} />}
		</div>
	);
}

export default FormsContainer;
