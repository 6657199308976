import 'react-quill/dist/quill.snow.css';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useSeminariesService } from '../../../services/SeminariesService';
import { BrazilFlagSVG, Calendar, ChinaFlagSVG, EyeBlock, FranceFlagSVG, GermanyFlagSVG, ItalyFlagSVG, JapanFlagSVG, NextArrow, RussiaFlagSVG, UnitedStatesFlagSVG } from 'assets/Imports';


function SearchCard({ getModuleNumber, moduleSelector, user, actualSeminary, consult, myConsults, isSearching, portuguese, german, japanese, english, french, italian, chinese, russian, hashtagFilter, filterText, index, numPaid }) {

    const [max, setMax] = useState<number>(0);
    const [min, setMin] = useState<number>(0);
    const { paidSeminaries }: any = useSeminariesService();
    let { path, url } = useRouteMatch();

    useEffect(() => {
        if (moduleSelector === 1) {
            setMin(0);
            setMax(10);
        } else if (moduleSelector === 2) {
            setMin(10);
            setMax(25);
        } else if (moduleSelector === 3) {
            setMin(25);
            setMax(40);
        } else if (moduleSelector === 4) {
            setMin(40);
            setMax(50);
        } else if (moduleSelector === 5) {
            setMin(50);
            setMax(60);
        } else {
            setMin(0);
            setMax(0);
        }
    }, [moduleSelector])

    function searching(consults: Array<any>, index) {
        if (isSearching) {
            return consults.some((consult, sIndex) => {
                if (consult.seminaryNum >= paidSeminaries) {
                    return false;
                } else {
                    if (moduleSelector === 0) {
                        if (french === true && consult.french === true || german === true && consult.german === true || japanese === true && consult.japanese === true || italian === true && consult.italian === true || chinese === true && consult.chinese === true || russian === true && consult.russian === true || english === true && consult.english || portuguese === true && consult.portuguese) {

                            return true;
                        } else if (hashtagFilter.length > 0) {
                            let tempArray = new Array();

                            hashtagFilter.forEach((word) => {
                                if (consult.hashtags.indexOf(word) !== -1) {
                                    tempArray.push(word);
                                }
                            });

                            if (tempArray.length > 0) {
                                return true;
                            }
                        } else if (filterText.length > 0 && consult.postText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\?/g, '').replace(/,/g, '').replace(/\./g, '').includes(filterText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\?/g, '').replace(/,/g, '').replace(/\./g, ''))) {
                            return true;
                        } else if (french === false && german === false && japanese === false && italian === false && chinese === false && russian === false && english === false && portuguese === false && myConsults === true) {
                            return true;
                        }
                    } else if (moduleSelector !== 0 && consult.seminaryNum > min && consult.seminaryNum <= max) {
                        if (french === true && consult.french === true || german === true && consult.german === true || japanese === true && consult.japanese === true || italian === true && consult.italian === true || chinese === true && consult.chinese === true || russian === true && consult.russian === true || english === true && consult.english || portuguese === true && consult.portuguese) {

                            return true;
                        } else if (hashtagFilter.length > 0) {
                            let tempArray = new Array();

                            hashtagFilter.forEach((word) => {
                                if (consult.hashtags.indexOf(word) !== -1) {
                                    tempArray.push(word);
                                }
                            });

                            if (tempArray.length > 0) {
                                return true;
                            }
                        } else if (filterText.length > 0 && consult.postText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\?/g, '').replace(/,/g, '').replace(/\./g, '').includes(filterText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\?/g, '').replace(/,/g, '').replace(/\./g, ''))) {
                            return true;
                        } else if (french === false && german === false && japanese === false && italian === false && chinese === false && russian === false && english === false && portuguese === false && myConsults === true) {
                            return true;
                        }
                    }
                }
            })
        } else {
            let realIndex = (index - 60) * (-1);

            if (moduleSelector !== 0 && (realIndex >= min && realIndex <= max)) {

                return true;
            } else if (moduleSelector === 0) {

                return true;
            }

        }

    }


    function romanize(num) {
        if (isNaN(num))
            return NaN;
        var digits: any = String(+num).split(""),
            key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
                "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
                "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
            roman = "",
            i = 3;
        while (i--)
            roman = (key[+digits.pop() + (i * 10)] || "") + roman;
        return Array(+digits.join("") + 1).join("M") + roman;
    }

    function getIndex(consults: Array<any>) {
        let temp = new Array<number>();

        if (consults.length > 0) {


            if (isSearching) {
                consults.forEach((consult, ind) => {

                    if (filterText.length > 0 && consult.postText.toLowerCase().includes(filterText.toLowerCase())) {
                        temp.push(ind)
                    } else if (french === true && consult.french === true || german === true && consult.german === true || japanese === true && consult.japanese === true || italian === true && consult.italian === true || chinese === true && consult.chinese === true || russian === true && consult.russian === true || english === true && consult.english || portuguese === true && consult.portuguese) {

                        temp.push(ind)
                    } else if (hashtagFilter.length > 0) {
                        let tempArray = new Array();

                        hashtagFilter.forEach((word) => {
                            if (consult.hashtags.indexOf(word) !== -1) {
                                tempArray.push(word);
                            }
                        });

                        if (tempArray.length > 0) {
                            temp.push(ind)
                        }
                    }
                });
                if (temp.length > 0) {
                    return temp[0];
                } else {
                    return 0;
                }
            } else {
                return 0;
            }

        } else {
            return 0;
        }

    }

    // useEffect(() => {
    //     if(!isSearching) {
    //         setSearchIndex(0)
    //     }
    // }, [isSearching]);

    return (
        <>
            {searching((myConsults ? consult.personalConsults : consult.lastConsult), index) && (
                <>
                    {(myConsults && consult.personalConsults.length > 0) || !myConsults ? (

                        <div className='consult-seminary-card' key={index}>
                            <div className='header'>
                                <h3>Seminario {romanize(consult.num)}</h3>
                                <div className='sub-container'>
                                    <p>{consult.consultsSize} consultas</p>
                                    {consult.num > actualSeminary && (
                                        <div className='next-class-container'>
                                            <img src={Calendar} alt="icono de calendario" />
                                            <p className='next-class'>Próxima sesión</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {(user.userType === 2 && consult.remain > 0) && (
                                <div className='remain'>
                                    <div className='remain-description'>
                                        <p>
                                            Todavía tienes consultas por revisar.
                                        </p>
                                    </div>
                                </div>
                            )}
                            {((myConsults && consult.personalConsults.length > 0) || !myConsults && consult.lastConsult.length > 0) ? (
                                <div className='post-content'>
                                    {(consult.num <= actualSeminary && consult.num <= numPaid) && (
                                        <>
                                            <div className='last-post-label'>
                                                {myConsults ? (
                                                    <p>Tus publicaciones</p>
                                                ) : (
                                                    <p>Última consulta</p>
                                                )}
                                                <div className='flags-container'>
                                                    {(myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].french : consult.lastConsult[getIndex(consult.lastConsult)].french) && (<img src={FranceFlagSVG} alt="bandera de francia" />)}
                                                    {(myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].german : consult.lastConsult[getIndex(consult.lastConsult)].german) && (<img src={GermanyFlagSVG} alt="bandera de alemania" />)}
                                                    {(myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].italian : consult.lastConsult[getIndex(consult.lastConsult)].italian) && (<img src={ItalyFlagSVG} alt="bandera de italia" />)}
                                                    {(myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].russian : consult.lastConsult[getIndex(consult.lastConsult)].russian) && (<img src={RussiaFlagSVG} alt="bandera de rusia" />)}
                                                    {(myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].japanese : consult.lastConsult[getIndex(consult.lastConsult)].japanese) && (<img src={JapanFlagSVG} alt="bandera de japon" />)}
                                                    {(myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].chinese : consult.lastConsult[getIndex(consult.lastConsult)].chinese) && (<img src={ChinaFlagSVG} alt="bandera de china" />)}
                                                    {(myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].english : consult.lastConsult[getIndex(consult.lastConsult)].english) && (<img src={UnitedStatesFlagSVG} alt="bandera de estados unidos" />)}
                                                    {(myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].portuguese : consult.lastConsult[getIndex(consult.lastConsult)].portuguese) && (<img src={BrazilFlagSVG}  alt="bandera de portugal" />)}
                                                </div>
                                            </div>
                                            <div className='post-info-container'>
                                                {(myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].userType === 2 : consult.lastConsult[getIndex(consult.lastConsult)].userType === 2) ? (
                                                    <h3 className='admin'>Academia De Lenguas Internacionales</h3>
                                                ) : (
                                                    <h3>{myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].name : consult.lastConsult[getIndex(consult.lastConsult)].name}</h3>
                                                )}
                                                <p>{new Date((myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].date.seconds : consult.lastConsult[getIndex(consult.lastConsult)].date.seconds) * 1000).toLocaleDateString('en-gb')} - {new Date((myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].date.seconds : consult.lastConsult[getIndex(consult.lastConsult)].date.seconds) * 1000).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</p>
                                            </div>
                                            <div className='text-container'>
                                                {parse(myConsults ? consult.personalConsults[getIndex(consult.personalConsults)].postText : consult.lastConsult[getIndex(consult.lastConsult)].postText)}
                                            </div>
                                            <div className='hashtag-flag-container'>
                                                <div className='hashtag-container'>
                                                    <>
                                                        {myConsults ? (
                                                            <>
                                                                {consult.personalConsults[getIndex(consult.personalConsults)].hashtags.map((hashtag, index) => {
                                                                    return (
                                                                        <p key={'hashtags-' + index}>#{hashtag}</p>
                                                                    )
                                                                })}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {consult.lastConsult[getIndex(consult.lastConsult)].hashtags.map((hashtag, index) => {
                                                                    return (
                                                                        <p key={'hashtags-' + index}>#{hashtag}</p>
                                                                    )
                                                                })}
                                                            </>
                                                        )}
                                                    </>
                                                </div>

                                            </div>

                                        </>
                                    )}
                                </div>
                            ) : (
                                <div className='post-content'>
                                    <div className='last-post-label'>
                                        {myConsults ? (
                                            <p>Tus publicaciones</p>
                                        ) : (
                                            <p>Última consulta</p>
                                        )}
                                    </div>
                                    <div className='post-info-container'>
                                        <h3></h3>
                                        <p></p>
                                    </div>
                                    {consult.num <= numPaid && (
                                        <div className='text-container' style={{ marginTop: "15%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {myConsults ? (
                                                <p>No tienes publicaciones en este seminario</p>
                                            ) : (
                                                <p>No hay consultas todavía en este seminario</p>
                                            )}
                                        </div>
                                    )}
                                    <div className='hashtag-flag-container'>
                                        <div className='hashtag-container'>

                                        </div>
                                        <div className='flags-container'>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(consult.num > actualSeminary && consult.num <= numPaid) && (
                                <div className='block-seminary'>
                                    <span style={{ fontSize: '2rem', color: "white" }}>
                                        <FontAwesomeIcon icon={faLock as any} />
                                    </span>
                                    <p>Aún no se ha llevado a cabo este seminario.</p>
                                </div>
                            )}
                            {consult.num > numPaid && (
                                <div className='block-seminary'>
                                    <img src={EyeBlock} alt="ojo bloqueado" />
                                    <p style={{ userSelect: 'none' }}>Aún no te has inscrito al módulo {getModuleNumber(consult.num)}</p>
                                </div>
                            )}
                            {consult.num <= numPaid ? (
                                <div className='footer'>
                                    <Link to={`${path}/` + consult.num}>
                                        <p>
                                            Leer el resto de consultas
                                        </p>
                                    </Link>
                                    <img src={NextArrow} alt="siguiente flecha" />
                                </div>
                            ) : (
                                <div className='footer'>
                                    <p>
                                        Leer el resto de consultas
                                    </p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )
            }
        </>

    );
}

export default SearchCard;