import FAQSection from "@containers/FAQContainer/FAQSection/FAQSection";

function QuestionsScreen() {
	return (
		<div style={{ overflow: "hidden", position: "relative" }}>
			<FAQSection />
		</div>
	);
}

export default QuestionsScreen;
