import { Modal } from "react-bootstrap";
import "./AlertModalStyles.scss";

export function AlertModal(props) {

    const hide = () => {
        if(props.nextAnswer !== undefined && props.title === "¡Término correcto!") {
            props.nextAnswer();
        }
        props.onHide()
    }

    return (
        <Modal
            show={props.show}
            onHide={hide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="alert-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{props.message}</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="action-btn linear-bg m-auto" onClick={hide}>Cerrar</button>
            </Modal.Footer>
        </Modal>
    );
}