import AdminAsideNav from "@components/AdminAsideNav/AsideNav";
import Footer from "@components/Footer/Footer";
import NavbarCustom from "@components/NavbarCustom/NavbarCustom";
import PaymentMethodsContent from "@containers/PaymentMethodsContainer/PaymentMethodsContent";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useParams,
	useRouteMatch,
	useHistory,
	BrowserRouter,
	useLocation,
	Redirect,
} from "react-router-dom";
import { addDoc, collection, deleteDoc, getDocs, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import { db } from "initFirebase";
import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { useAuth } from "../../hooks/useAuth";
import GenerationsContainer from "@containers/Admin/GenerationsContainer.tsx/GenerationsContainer";
import MaterialContainer from "@containers/Admin/MaterialContainer/MaterialContainer";
import PaymentRequestContainer from "@containers/Admin/PaymentRequestContainer/PaymentRequestContainer";
import PaymentHistoryContainer from "@containers/Admin/PaymentHistoryContainer/PaymentHistoryContainer";
import ContactsContainer from "@containers/Admin/ContactsContainer";
import ContactContainer from "@containers/Admin/ContactContainer/ContactContainer";
import DynamicText from "@containers/Admin/DynamicTextContainer/DynamicTextContainer";
import FormsContainer from "@containers/Admin/FormsContainer/FormsContainer";
import ConsultServiceProvider from "../../services/ConsultsService";
import IsSeminaryPaid from "../validateRoute/IsSeminaryPaid";
import { SeminariesServiceContext } from "../../services/SeminariesService";
import ConsultPostContainer from "@containers/ConsultPostContainer/ConsultPostContainer";
import ConsultsContainer from "@containers/ConsultsContainer/ConsultsContainer";
import VocabularyAdmin from "@containers/Admin/VocabularyContainer/VocabularyAdmin";
import VocabularySeminaryContainer from "@containers/Admin/VocabularySeminaryContainer/VocabularySeminaryContainer";
import VocabularyLanguageContainer from "@containers/Admin/VocabularyLanguageContainer/VocabularyLanguageContainer";
import VocabularyGenerationContainer from "@containers/Admin/VocabularyGenerationContainer/VocabularyGenerationContainer";
import VocabularyQuizContainer from "@containers/Admin/VocabularyQuizContainer/VocabularyQuizContainer";
import { AddWordsVocabulary } from "@containers/Admin/Develop/AddWordsVocabulary";
import { GenerationDetailsContainer } from "@containers/Admin/GenerationDetailsContainer/GenerationDetailsContainer";
import VocabularyCategories from "@containers/Admin/VocabularyCategories/VocabularyCategories";
import { VideosUpload } from "@containers/Admin/VideosUploadContainer/VideosUpload";
import CollaboratorContainer from "@containers/Admin/CollaboratorContainer/CollaboratorContainer";
import { MenusDataContext } from "../../containers/Admin/CollaboratorContainer/context/menu-data.provider";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { Container, Row, Col } from "react-bootstrap";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import { Eye, Eye_white } from "assets/Imports";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
import InvoiceAdminContainer from "@containers/Admin/InvoiceContainer/InvoiceAdminContainer";
import FooterPreviewMode from "@components/Admin/FooterPreviewMode";
import { CustomSpinnerContainer } from "@components/Spinner/SpinnerMyAccountView";
import { Galleries } from "@containers/Admin/Galleries/Galleries";
function AdminScreen() {
	let { path, url } = useRouteMatch();
	const history = useHistory();
	const location = useLocation();

	const { ispreviewMode, PreviewMode } = useContext(MenusDataContext);
	const [rutasAccesibles, setRutasAccesibles] = useState<any>([]);
	const [asideOpen, setAsideOpen] = useState<boolean>(false);
	const [withpermissions, setpermissions] = useState(false);
	const [navbarOpen, setNavbarOpen] = useState<boolean>(false);
	const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);
	const user = useAuth().user;
	const { selectedUserPreview, togglePreviewModeUser } = useAuth();
	const [isLoading, setIsLoading] = useState(true);

	if (user === null) {
		history.push("/ingresar");
	}

	useEffect(() => {
		if (asideOpen) {
			if (navbarOpen) {
				setNavbarOpen(false);
			}
			if (notificationsOpen) {
				setNotificationsOpen(false);
			}
		}
	}, [asideOpen]);

	useEffect(() => {
		if (navbarOpen) {
			if (asideOpen) {
				setAsideOpen(false);
			}
			if (notificationsOpen) {
				setNotificationsOpen(false);
			}
		}
	}, [navbarOpen]);

	useEffect(() => {
		if (notificationsOpen) {
			if (navbarOpen) {
				setNavbarOpen(false);
			}
			if (asideOpen) {
				setAsideOpen(false);
			}
		}
	}, [notificationsOpen]);

	const getComponent = (value) => {
		switch (value) {
			case "/colaboradores":
				return <CollaboratorContainer user={user} />;
				break;
			case "/galerias":
				return <Galleries />;
				break;
			case "/invoice":
				return <InvoiceAdminContainer user={user} />;
				break;
			case "/generacion/:id":
				return <GenerationDetailsContainer user={user} />;
				break;
			case "/material":
				return <MaterialContainer user={user} />;
				break;
			case "/videos":
				return <VideosUpload user={user} />;
				break;
			case "":
				return <GenerationsContainer user={user} />;
				break;
			case "/actualizar-pagos":
				return <PaymentRequestContainer user={user} />;
				break;
			case "/historial-de-pagos":
				return <PaymentHistoryContainer user={user} />;
				break;
			case "/contacto":
				return <ContactContainer user={user} />;
				break;
			case "/textos":
				return <DynamicText user={user} />;
				break;
			case "/consultas":
				return <ConsultsContainer user={user} />;
				break;
			case "/vocabulario":
				return <VocabularyAdmin user={user} />;
				break;
			case "/vocabulario/subcategorias":
				return <VocabularyCategories user={user} />;
				break;
			case "/vocabulario/seminario/:seminaryNum":
				return <VocabularySeminaryContainer user={user} />;
				break;
			case "/vocabulario/idioma/:language/:languageId":
				return <VocabularyLanguageContainer />;
				break;
			case "/vocabulario/generacion/:generationId":
				return <VocabularyGenerationContainer />;
				break;
			case "/vocabulario/quiz/:seminaryNum":
				return <VocabularyQuizContainer />;
				break;
			case "/formularios":
				return <FormsContainer user={user} />;
				break;
			case "/formulariosAll":
				return <FormsContainer user={user} Menu={3} />;
				break;
			case "/consultas/:num":
				return (
					<>
						<ConsultServiceProvider>
							<IsSeminaryPaid>
								<ConsultPostContainer user={user} />
							</IsSeminaryPaid>
						</ConsultServiceProvider>
					</>
				);
				break;
		}
	};

	useEffect(() => {
		const obtenerDatosColeccion = async () => {
			setIsLoading(true);
			try {
				const colRef = collection(db, "admin_routes");
				const querySnapshot = await getDocs(colRef);

				const datos: any = [];
				querySnapshot.forEach((doc) => {
					const dataWithId = { id: doc.id, ...doc.data() };
					datos.push(dataWithId);
				});
				setRutasAccesibles(datos);
				const rutasAccesiblesNames = datos.filter((ruta) => user.permissions.includes(ruta.id)).map((ruta) => ruta.Titulo);

				setpermissions(rutasAccesiblesNames);
			} catch (error) {
				console.log("Error al obtener los datos de la colección:", error);
			}
			setIsLoading(false);
		};

		obtenerDatosColeccion();
	}, []);

	const NoAuthorized = () => {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
					textAlign: "center",
					backgroundColor: "#f2f2f2",
				}}
			>
				<h1
					style={{
						color: "red",
						marginBottom: "20px",
					}}
				>
					Esta página no existe o no estás autorizado para verla.
				</h1>
				<p
					style={{
						color: "black",
						fontSize: "18px",
					}}
				>
					Ponte en contacto con el administrador para que te asigne los permisos que requieres.
				</p>
			</div>
		);
	};

	return (
		<>
			<div style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}>
				<div style={{ display: "flex", position: "relative", flex: 1, width: "100vw", maxWidth: "2100px" }}>
					<AdminAsideNav />

					<div className="my-account-content-container">
						{isLoading === true ? (
							<CustomSpinnerContainer size={100} height={"100%"} borderSize={4} />
						) : (
							<Switch>
								{rutasAccesibles.map((data) => {
									let routesToRender: any = [];

									const userPermissions = new Set(user?.permissions);
									const previewUserPermissions = new Set(selectedUserPreview?.seccionsCollaboratorId);
									if (ispreviewMode) {
										if (previewUserPermissions.has(data.id)) {
											routesToRender.push(
												<Route key={data.id} exact path={`${path}${data.Ruta}`}>
													{getComponent(data.Ruta)}
												</Route>
											);
										}
										if (data.childrens) {
											data.childrens.forEach((childRoute) => {
												if (previewUserPermissions.has(childRoute)) {
													routesToRender.push(
														<Route key={childRoute.id} exact path={`${path}${data.Ruta}/${childRoute}`}>
															{getComponent(`${data.Ruta}/${childRoute}`)}
														</Route>
													);
												}
											});
										}
									} else {
										if (userPermissions.has(data.id) || user.userType !== 4) {
											routesToRender.push(
												<Route key={data.id} exact path={`${path}${data.Ruta}`}>
													{getComponent(data.Ruta)}
												</Route>
											);
											if (data.childrens) {
												data.childrens.forEach((childRoute) => {
													const childRouteToPush = rutasAccesibles.filter((e) => e.id === childRoute)[0];
													routesToRender.push(
														<Route key={childRouteToPush.id} exact path={`${path}${childRouteToPush.Ruta}`}>
															{getComponent(childRouteToPush.Ruta)}
														</Route>
													);
												});
											}
										}
									}
									return routesToRender;
								})}
								<Route exact path={"/admin/addWords"} component={AddWordsVocabulary} />
								<Route component={NoAuthorized} />
							</Switch>
						)}
					</div>
				</div>
				{ispreviewMode ? (
					<FooterPreviewMode
						onClick={() => {
							history.push("/admin/colaboradores");
							PreviewMode();
							togglePreviewModeUser();
						}}
					/>
				) : null}
				{/* <Footer /> */}
			</div>
		</>
	);
}

function Url() {
	let { pageid } = useParams<any>();
	console.log(pageid);
	return <h2></h2>;
}

export default AdminScreen;
