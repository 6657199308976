import React from "react";
import "./Pagination.scss";

type PaginationProps = {
	nextPage: () => void;
	prevPage: () => void;
	currentPage: number;
	isLastPage: boolean;
	isLoading: boolean;
};

const Pagination = ({
	nextPage,
	prevPage,
	currentPage,
	isLastPage,
	isLoading,
}: PaginationProps) => {
	const handleNextPage = () => {
		nextPage();
	};

	const handlePrevPage = () => {
		prevPage();
	};

	return (
		<div className="pagination-component">
			<button
				className="pagination-action-btn"
				onClick={handlePrevPage}
				disabled={currentPage === 1 || isLoading}
			>
				Anterior
			</button>
			<button
				className="pagination-action-btn"
				onClick={handleNextPage}
				disabled={isLastPage || isLoading}
			>
				Siguiente
			</button>
		</div>
	);
};

export default Pagination;
