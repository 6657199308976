import { returnWarningBorderColor, returnWarningTypeImage } from "@containers/VocabularySeminaryContainer/functions/VocabularyFunctions";
import "./WarningTypeSelector.scss";

export function WarningTypeSelector(props) {
  const warnings = ["warning", "memory", "grammar", "advice", "fake_friend", "similarity","pronunciation","did_YouKnow"];


  return (
		<div className="warning-type-selector">
			{warnings.map((message, i) => {
				if (props.word[message] !== undefined && props.word[message] !== "undefined" && props.word[message].length > 0 && props.word[message] !=='<p class="pre"><br></p>') {
          return (
						<div
							className="warning-type-circle"
							key={"warning-type-circle-" + i}
							style={{ borderColor: message === props.warningType ? returnWarningBorderColor(props.warningType) : "#cacaca" }}
                            onClick={() => props.setWarningType(message)}
						>
							{returnWarningTypeImage(message)}
						</div>
					);
				}
			})}
		</div>
	);
}
