import { getWordSeminaryInfo } from "@api/Vocabulary";

function useVocabulary() {
	async function getWordData(wordDataId: number) {
		return await getWordSeminaryInfo(wordDataId)
			.then((res) => {
				const wordData: any = {
					id: res[0].id,
					singular_category: res[0].singular,
					native_word: res[0].native_word,
					category: [res[0].family],
					meaning: res[0].meanings.split("|"),
					optional_word: res[0].optional_word,
					warning: res[0].warning,
					memory: res[0].memory,
					grammar: res[0].grammar,
					advice: res[0].advice,
					language: res[0].language,
					fake_friend: res[0].fake_friend,
					similarity: res[0].similarity,
					sub_category: res[0].sub_categorías === "sin subcategorías" ? [null] : res[0].sub_categorías.split("|"),
					word_id: res[0].word_id,
					pronunciation: res[0].pronunciation,
					did_YouKnow: res[0].did_YouKnow,
					language_Id: res[0].language_Id,
				};

				if (typeof wordData.meaning === "string") {
					wordData.meaning = [wordData.meaning];
				}
				return wordData;
			})
			.catch((error) => {
				return error;
			});
	}

	return { getWordData };
}

export default useVocabulary;
