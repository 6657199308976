import { getAllCategories, importWordsZero } from "@api/Vocabulary";
import { CreateIcon, ListIconVocabulary } from "assets/Imports";
import { languagesEnglish } from "functions/DefaultValues";
import { capitalizeFirstLetter } from "functions/Functions";
import { category } from "interfaces/Vocabulary/VocabularyInterfaces";
import { useEffect, useState } from "react";
import { getLangSpanishSimple } from "../PersonalizedTest/functions/functions";
import { WordRowCard } from "../WordRowCard/WordRowCard";
import { ToolTipModal } from "./component/ToolTipModal/ToolTipModal";
import "./SuperPersonalizedStyles.scss";

export function SuperPersonalized(props) {
	const [values, setValues] = useState({
		category: "none",
		imports: "",
		language: "none",
		customCategory: "",
	});
	const [words, setWords] = useState(new Array());
	const [categories, setCategories] = useState<Array<category>>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [readyToImport, setReadyToImport] = useState<boolean>(false);
	const [importLoading, setImportLoading] = useState<boolean>(false);
	const [toolTipModalShow, setToolTipModalShow] = useState<boolean>(false);
	const disableEdit: boolean = true;

	const handleImportsChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValues({ ...values, imports: e.target.value });
		const imports = e.target.value.split("\n");
		const finalImports: any = [];
		if (imports.length > 0) {
			try {
				await Promise.all(
					imports.map((imp) => {
						const wordSplit = imp.split("\t");
						const data = {
							native_word: wordSplit[0],
							meaning: wordSplit[1] !== undefined ? wordSplit[1].split(",") : [""],
							optional_word: wordSplit[2] !== undefined ? wordSplit[2] : null,
						};

						finalImports.push(data);
					})
				);
				setWords(finalImports);
			} catch (error) {
				console.log(error);
				alert("El texto ingresado no cumple con el formato establecido.");
			}
		} else {
			setWords(new Array());
		}
	};

	function sortCategories(a, b) {
		if (a.singular < b.singular) {
			return -1;
		}
		if (a.singular > b.singular) {
			return 1;
		}
		return 0;
	}

	const getCategories = async () => {
		await getAllCategories().then((res) => {
			setCategories(res.categories.sort(sortCategories));
			setLoading(false);
		});
	};

	function returnPlaceHolder(language: string) {
		switch (language) {
			case "none":
				return `Selecciona un idioma`;
				break;
			case "chinese":
				return `Palabra en ${getLangSpanishSimple(
					language
				)}  1\tPalabra en español  1\tKanzi  1(obligatorio)\nPalabra en ${getLangSpanishSimple(
					language
				)} 2\tPalabra en español 2\tKanzi 2(obligatorio)\nPalabra en ${getLangSpanishSimple(
					language
				)} 3\tPalabra en español 3\tKanzi 3(obligatorio)\n`;
				break;
			case "japanese":
				return `Palabra en ${getLangSpanishSimple(
					language
				)}  1\tPalabra en español  1\tKanji  1(opcional)\nPalabra en ${getLangSpanishSimple(
					language
				)} 2\tPalabra en español 2\tKanji 2(opcional)\nPalabra en ${getLangSpanishSimple(
					language
				)} 3\tPalabra en español 3\tKanji 3(opcional)\n`;
				break;
			default:
				return `Palabra en ${getLangSpanishSimple(
					language
				)}  1\tPalabra en español  1\nPalabra en ${getLangSpanishSimple(
					language
				)} 2\tPalabra en español 2\nPalabra en ${getLangSpanishSimple(
					language
				)} 3\tPalabra en español 3\n`;
				break;
		}
	}

	const importWords = async () => {
		setImportLoading(true);

		const temp: any = [];

		await Promise.all(
			words.map((word) => {
				if (word.native_word !== "" && word.meaning.every((e) => e.length > 0)) {
					const data = {
						native_word: word.native_word,
						meaning: word.meaning,
						optional_word: word.optional_word,
						category: values.category !== "new" ? values.category : values.customCategory,
						language: values.language,
					};

					temp.push(data);
				}
			})
		);

		try {
			const res = await importWordsZero(
				temp,
				values.category !== "new" ? values.category : values.customCategory
			);
			props.setView(1);
		} catch (error) {
			console.error(error);
			setImportLoading(false);
			alert(
				"Hubo un error al momento de importar, favor de validar que se cumplan correctamente los requisitos para realizar una importación."
			);
		}
	};

	useEffect(() => {
		if (
			((values.category !== "none" && values.category !== "new") ||
				(values.category === "new" && values.customCategory !== "")) &&
			((values.language !== "none" && values.language !== "chinese") ||
				(values.language === "chinese" && words.every((e) => e.optional_word !== null))) &&
			words.length > 0
		) {
			setReadyToImport(true);
		} else {
			setReadyToImport(false);
		}
	}, [values, words]);

	useEffect(() => {
		getCategories();
	}, []);

	if (loading) {
		return (
			<div
				style={{
					height: "60vh",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	return (
		<div className="super-personalized-component-main-container">
			<ToolTipModal show={toolTipModalShow} onHide={() => setToolTipModalShow(false)} />
			<div className="d-flex justify-content-start w-100">
				<button className="goback-btn m-0" onClick={() => props.setView(1)}>
					Atrás
				</button>
			</div>
			<div className="header-container">
				<div className="title-container">
					<h1 className="d-flex align-items-center" style={{ gap: 10, color: "#ca5050" }}>
						<img src={ListIconVocabulary} alt="icono de lista" />
						Seminario
						<span style={{ fontFamily: "initial", alignSelf: "end", lineHeight: "52px" }}>
							Zero
						</span>
					</h1>
				</div>
			</div>
			<div className="super-personalized-test-card">
				<div className="content">
					<div className="test-row justify-content-between">
						<h2>Añadir palabras</h2>
						<button className="action-btn linear-bg" onClick={() => setToolTipModalShow(true)}>
							Guía
						</button>
					</div>
					<div className="test-row">
						<div className="selects-container">
							<label htmlFor="language">
								<b>Idioma</b>
							</label>
							<div className="select-input-container">
								<select
									name="language"
									id="language"
									value={values.language}
									onChange={(e) => setValues({ ...values, language: e.target.value })}
								>
									<option value="none">Selecciona un idioma</option>
									{languagesEnglish.map((language, index) => {
										return (
											<option value={language} key={"language-option-" + index}>
												{capitalizeFirstLetter(getLangSpanishSimple(language))}
											</option>
										);
									})}
								</select>
							</div>
						</div>
					</div>
					<div className="test-row">
						<div className="selects-container">
							<label htmlFor="language">
								<b>Categoría</b>
							</label>
							<div className="select-input-container">
								<select
									name="language"
									id="language"
									value={values.category}
									onChange={(e) => setValues({ ...values, category: e.target.value })}
								>
									<option value="none">Selecciona una categoría</option>
									<option value="new">Nueva</option>
									{categories.map((category, index) => {
										if (category.singular !== "") {
											return (
												<option value={category.family} key={"category-option-" + index}>
													{capitalizeFirstLetter(category.singular)}
												</option>
											);
										}
									})}
								</select>
							</div>
						</div>
					</div>
					{values.category === "new" && (
						<div className="test-row">
							<div className="input-container category-input-container">
								<input
									type="text"
									id="quiz-customCategory"
									name="quiz-customCategory"
									value={values.customCategory}
									onChange={(e) => setValues({ ...values, customCategory: e.target.value })}
									placeholder="Categoría personalizada"
								/>
								<label htmlFor="quiz-customCategory" style={{ cursor: "pointer" }}>
									<img src={CreateIcon} alt="" />
								</label>
							</div>
						</div>
					)}
					<div className="test-row">
						<div className="input-container imports-input-container">
							<label htmlFor="imports">
								<b>Importa tus datos.</b> Copia y pega tus datos aquí (desde Word, Excel, Google
								Docs, etc.)
							</label>
							<div className="input-container text-area-container">
								<textarea
									disabled={values.language === "none"}
									name="imports"
									id="imports"
									rows={10}
									value={values.imports}
									onChange={(e) => handleImportsChange(e)}
									placeholder={returnPlaceHolder(values.language)}
								></textarea>
							</div>
						</div>
					</div>
					<div className="test-row last">
						<button
							className="action-btn linear-bg"
							style={importLoading || !readyToImport ? { background: "#cacaca" } : {}}
							onClick={!importLoading && readyToImport ? importWords : () => {}}
						>
							Importar
						</button>
					</div>
					<div className="words-row-cards-container">
						{words.map((word, index) => {
							if (word.native_word !== "") {
								return (
									<WordRowCard
										word={word}
										language={values.language}
										answerType={"normal"}
										index={index}
										gKey={props.gKey}
										key={"word-row-card-super-personalized-quiz-" + index}
										disableEdit={disableEdit}
									/>
								);
							}
						})}
					</div>
				</div>
			</div>
		</div>
	);
}
