import React, { useEffect, useState } from "react";
import { db } from "initFirebase";
import Container from "react-bootstrap/esm/Container";
import Nav from "react-bootstrap/esm/Nav";
import Navbar from "react-bootstrap/esm/Navbar";
import ModuleA from "../Module/ModuleA";
import ModuleB from "../Module/ModuleB";
import "./styles.scss";
import ExclamationButton from "@components/ExclamationButton/ExclamationButton";
import { Link } from "react-router-dom";
import { Module1, Module2, Module3, Module4, Module5 } from "assets/Imports";

function ModulesContainer() {
	const [showModule, setShowModule] = useState("Module1");

	const [moduleInfo1, setModuleInfo1] = useState<any>([]);
	const [moduleInfo2, setModuleInfo2] = useState<any>({});
	const [moduleInfo3, setModuleInfo3] = useState<any>({});
	const [moduleInfo4, setModuleInfo4] = useState<any>({});
	const [moduleInfo5, setModuleInfo5] = useState<any>({});
	const [secondSection, setSecondSection] = useState<any>({});
	const [file, setFile] = useState<any>(null);
	const [fallCalendar, setFallCalendar] = useState<any>(null);

	const getModulesInfo = async () => {
		db.collection("dynamic_copies")
			.doc("AseQzYW4e5SuvVSU4E3r")
			.collection("modulo1")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setModuleInfo1({
						...doc.data(),
						id: doc.id,
					});
				});
			});
		db.collection("dynamic_copies")
			.doc("AseQzYW4e5SuvVSU4E3r")
			.collection("modulo2")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setModuleInfo2({
						...doc.data(),
						id: doc.id,
					});
				});
			});
		db.collection("dynamic_copies")
			.doc("AseQzYW4e5SuvVSU4E3r")
			.collection("modulo3")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setModuleInfo3({
						...doc.data(),
						id: doc.id,
					});
				});
			});
		db.collection("dynamic_copies")
			.doc("AseQzYW4e5SuvVSU4E3r")
			.collection("modulo4")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setModuleInfo4({
						...doc.data(),
						id: doc.id,
					});
				});
			});
		db.collection("dynamic_copies")
			.doc("AseQzYW4e5SuvVSU4E3r")
			.collection("modulo5")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setModuleInfo5({
						...doc.data(),
						id: doc.id,
					});
				});
			});
		db.collection("dynamic_copies")
			.doc("AseQzYW4e5SuvVSU4E3r")
			.collection("segunda")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setSecondSection({
						...doc.data(),
						id: doc.id,
					});
				});
			});
		db.collection("dynamic_copies")
			.doc("Y4En66vAtJp7qti4zVcb")
			.get()
			.then((querySnapshot) => {
				setFile({
					...querySnapshot.data(),
					id: querySnapshot.id,
				});
			});
		db.collection("dynamic_copies")
			.doc("tdzeOn9Zx6VSVVCNkgZ3")
			.get()
			.then((querySnapshot) => {
				setFallCalendar({
					...querySnapshot.data(),
					id: querySnapshot.id,
				});
			});
	};

	useEffect(() => {
		getModulesInfo();
	}, []);

	return (
		<div className="container__modules">
			<Navbar collapseOnSelect expand="lg" className="modules__navbar">
				<Container>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" className="button2">
						<span>Ver todos los módulos</span>
					</Navbar.Toggle>
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="me-auto" activeKey={showModule}>
							<Nav.Link eventKey="Module1" onClick={() => setShowModule("Module1")}>
								Módulo I
							</Nav.Link>
							<Nav.Link eventKey="Module2" onClick={() => setShowModule("Module2")}>
								Módulo II
							</Nav.Link>
							<Nav.Link eventKey="Module3" onClick={() => setShowModule("Module3")}>
								Módulo III
							</Nav.Link>
							<Nav.Link eventKey="Module4" onClick={() => setShowModule("Module4")}>
								Módulo IV
							</Nav.Link>
							<Nav.Link eventKey="Module5" onClick={() => setShowModule("Module5")}>
								Módulo V
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<div className="section__modules">
				{showModule === "Module1" && <ModuleA image={Module1} data={moduleInfo1} />}
				{showModule === "Module2" && <ModuleB image={Module2} data={moduleInfo2} />}
				{showModule === "Module3" && <ModuleA image={Module3} data={moduleInfo3} />}
				{showModule === "Module4" && <ModuleB image={Module4} data={moduleInfo4} />}
				{showModule === "Module5" && <ModuleA image={Module5} data={moduleInfo5} />}
			</div>
			<div className="call-to-action">
				{file !== null && file.fileUrl !== "" && (
					<Container className="mb-4 files-section-container">
						<a href={file.fileUrl} target="_blank">
							<button className="return-btn-style">Ver programa</button>
						</a>
						{fallCalendar !== null && (
							<a href={fallCalendar?.FileUrl ?? ""} target="_blank">
								<button className="return-btn-style">Ver {fallCalendar?.name}</button>
							</a>
						)}
					</Container>
				)}
				<Container>
					<h3>{secondSection.titulo}</h3>
					<p>
						<b>{secondSection.paragraph1}</b>
					</p>
					<p>
						<b>{secondSection.paragraph2}</b>
					</p>
					<Link to={`/inscripcion`}>
						<div className="button__container text-center">
							<button className="secondary" style={{ display: "flex", alignItems: "center" }}>
								{secondSection.boton}
							</button>
						</div>
					</Link>
				</Container>
			</div>
			<ExclamationButton />
		</div>
	);
}

export default ModulesContainer;
