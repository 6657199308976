import { doc, getDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import "./VocabularyStyles.scss";
import { seminariesNumber } from "functions/DefaultValues";
import { ClockIcon, ListIconVocabulary } from "assets/Imports";
import { StreakFlame } from "@containers/VocabularySeminaryContainer/components/Test/components/StreakModal/components/StreakFlame";
import { useStreakContext } from "contexts/VocabularyStreakContext";
import LastQuizzesContainer from "./components/LastQuizzesContainer/LastQuizzesContainer";
import SeminaryVocabularyInfoModalProvider from "contexts/SeminaryVocabularyInfoModalContext";
import ModulesContainer from "./components/ModulesContainer/ModulesContainer";

interface propsI {
	user?: any;
}

function VocabularyContainer({ user }: propsI) {
	const [totalHours, setTotalHours] = useState<number>(0);
	const { isLoading, vocabularyStreak, studiedDays } = useStreakContext();

	const getTimes = async () => {
		try {
			let totalSeconds = 0;
			const promises = seminariesNumber.map(async (seminaryNum) => {
				const timeRef = doc(db, "quizTimes", `${user.id}-${seminaryNum}`);
				const snapDoc = await getDoc(timeRef);
				if (snapDoc.exists()) {
					const data = snapDoc.data();

					totalSeconds +=
						data.english +
						data.japanese +
						data.russian +
						data.italian +
						data.german +
						data.chinese +
						data.portuguese +
						data.french;
				}
			});

			await Promise.all(promises);

			setTotalHours(parseFloat((totalSeconds / 60 / 60).toFixed(1)));
		} catch (error) {
			console.error(error);
			// Manejar errores aquí si es necesario
		}
	};

	useEffect(() => {
		getTimes();
	}, []);

	return (
		<div className="vocabulary-main-container">
			{/* <StreakModal show={streakModal} onHide={() => setStreakModal(false)} vocabularyStreak={studiedDays} /> */}
			<div className="header-container">
				<div className="title-and-time">
					<h1 className="d-flex align-items-center">
						<img src={ListIconVocabulary} alt="icono de lista" />
						Vocabulario{" "}
						<span
							style={{
								fontSize: "0.875rem",
								color: "#ca5050",
								alignSelf: "end",
							}}
						>
							BETA
						</span>
					</h1>
					<div className="time-container">
						<StreakFlame
							user={user}
							vocabularyStreak={vocabularyStreak}
							studiedDays={studiedDays}
							isLoading={isLoading}
						/>
						{/* <div className="streak-container" onClick={() => setStreakModal(true)}>
							<img className="streak-icon" src={StreakFlameIcon} alt="icono de fuego" />
							{vocabularyStreak !== undefined && <h2>{vocabularyStreak.length}</h2>}
						</div> */}
						<h3>
							<img src={ClockIcon} alt="icono de relog" />
							{totalHours} {totalHours == 1 ? "hora" : "horas"}
						</h3>
						<p>Tiempo total de estudio</p>
					</div>
				</div>
				<p>
					En esta sección puedes estudiar el vocabulario de cada seminario y mejorar tus habilidades
					para cada idioma.
				</p>
			</div>
			<LastQuizzesContainer />
			{/* {seminaries !== undefined && (
				<SeminariesCardsContainer
					seminaries={seminaries}
					gKey={key}
					setCountRefresh={setCountRefresh}
					countRefresh={countRefresh}
				/>
			)} */}
			<SeminaryVocabularyInfoModalProvider>
				<ModulesContainer />
			</SeminaryVocabularyInfoModalProvider>
		</div>
	);
}

export default VocabularyContainer;
