// En tu archivo sendNotification.ts
import { addDoc, collection } from "firebase/firestore";
import { db } from "initFirebase";

export const sendNotificationMissingMaterials = async (material): Promise<void> => {
	try {
		const generationName = material.name;

		// Utilizar una expresión regular para extraer la parte deseada del texto
		const match = generationName.match(/Simultáneos - (.+)$/);
		let generationPart;
		if (match && match[1]) {
			generationPart = match[1];
			//console.log(desiredPart);
		} else {
			console.error("No se pudo encontrar la parte deseada en el texto.");
		}

		// Iterar sobre los materiales faltantes y enviar una notificación para cada uno
		for (const missingMaterial of material.missingMaterials) {
			
			const seminarNumber = missingMaterial.num;
			await addDoc(collection(db, "notifications"), {
				name: "Academia de Lenguas Internacionales",
				text: `<p>Los estudiantes de la generación ${generationPart} no tienen aún acceso a la grabación  del seminario ${seminarNumber}.</p>`,
				date: new Date(),
				userId: "admin",
				userType: 2,
				seen: false,
				type: "missing-material",
				title: "Material faltante.",
				materialId: missingMaterial.materialId,
				generationId: material.generationId,
				seminaryNum: missingMaterial.num,
			});
		} 
	} catch (error) {
		console.error("Error al enviar notificaciones:", error);
	}
};
