import { useAuth } from "@hooks/useAuth";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "initFirebase";
import { Quizzes } from "interfaces/Interfaces";
import React, { useCallback, useEffect, useState } from "react";
import { BackArrowIcon, ClockIcon, ListIconVocabulary } from "assets/Imports";
import LastQuizzes from "../LastQuizzes/LastQuizzes";
import { get } from "jquery";

const LastQuizzesContainer = () => {
	const { user } = useAuth();
	const [lastQuizzes, setLastQuizzes] = useState<Quizzes[]>([]);
	const [maxPages, setMaxPages] = useState(0);
	const [page, setPage] = useState<number>(0);

	const handleNextPage = () => {
		setPage(page + 1);
	};

	const handlePrevPage = () => {
		setPage(page - 1);
	};

	const getLastQuizzes = useCallback(async () => {
		//  obtener los ultimos 18 rondas de estudio
		const q = query(
			collection(db, "quiz"),
			where("userId", "==", user.id),
			orderBy("createdAt", "desc"),
			limit(18)
		);
		const snap = await getDocs(q);

		if (snap.size > 0) {
			let temp: any[] = [];

			snap.docs.map((quiz) => {
				temp.push({ id: quiz.id, ...quiz.data() });
			});


			setMaxPages(Math.ceil(snap.size / 6) - 1);
			setLastQuizzes(temp);
		}
	}, [user]);

	useEffect(() => {
		getLastQuizzes();
	}, [getLastQuizzes]);

	if (lastQuizzes.length === 0) return null;

	return (
		<div className="last-quizzes-main-container">
			<div className="header d-flex justify-content-between mb-4 align-items-center">
				<div style={{ minWidth: 20 }}>
					{page > 0 && (
						<img
							src={BackArrowIcon}
							alt="arrow icon"
							style={{ cursor: "pointer" }}
							onClick={handlePrevPage}
						/>
					)}
				</div>
				<h2 className="m-0">Rondas de estudio más recientes</h2>
				<div style={{ minWidth: 20 }}>
					{page < maxPages && lastQuizzes.slice(6 * page, 6 * (page + 1)).length === 6 && (
						<img
							src={BackArrowIcon}
							alt="arrow icon"
							style={{ transform: "rotate(180deg)", cursor: "pointer" }}
							onClick={handleNextPage}
						/>
					)}
				</div>
			</div>
			<div className="last-quizzes-cards-container" style={{ minHeight: 300 }}>
				{lastQuizzes.slice(6 * page, 6 * (page + 1)).map((quiz, index) => {
					return <LastQuizzes user={user} quiz={quiz} key={"last-quiz-component-" + quiz.id} />;
				})}
			</div>
		</div>
	);
};

export default LastQuizzesContainer;
