import "./styles.scss";
import CardContainer from "./CardContainer";
import NewCard from "./NewCard";
import { useCallback, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { CardI } from "interfaces/Conekta/Conekta";
import DeleteCardModal from "@components/DeleteCardModal/DeleteCardModal";
import { httpsCallable } from "firebase/functions";
import { functions } from "initFirebase";

function PaymentMethodsContent({ user }) {
	let name;
	const [loading, setLoading] = useState(true);
	const [cardToDelete, setCardToDelete] = useState<CardI | null>(null);

	if (user !== null) {
		name = user.name.split(" ");
	}
	const [cards, setCards] = useState<Array<CardI>>([]);

	const handleDelete = (card: CardI) => {
		setCardToDelete(card);
	};

	const getCards = useCallback(() => {
		setLoading(true);
		let customerConektaInfo = httpsCallable(functions, "getCustomer");
		customerConektaInfo({
			customerId: user.conektaId,
		}).then((res: any) => {
			if (res.data.payment_sources !== undefined) {
				setCards(res.data.payment_sources.data);
			}
			setLoading(false);
		});
	}, []);

	const handleHide = (refresh: boolean) => {
		setCardToDelete(null);
		if (refresh) getCards();
	};

	useEffect(() => {
		getCards();
	}, []);

	if (loading)
		return (
			<div
				style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);

	return (
		<>
			<div>
				<h1>¡Bienvenido, {name[0]}!</h1>
			</div>
			<div style={{ marginTop: 40, marginBottom: 30 }}>
				<h2>Métodos de pago</h2>
			</div>
			<div className="saved-cards-container">
				{cards.map((card, i) => {
					return (
						<CardContainer
							card={card}
							key={`card-container-${card.id}`}
							handleClick={() => handleDelete(card)}
						/>
					);
				})}
				<NewCard getCards={getCards} />
			</div>
			<DeleteCardModal show={cardToDelete !== null} onHide={handleHide} card={cardToDelete} />
		</>
	);
}

export default PaymentMethodsContent;
