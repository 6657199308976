import { CustomSpinner } from "@components/Spinner/SpinnerMyAccountView";
import { useAuth } from "@hooks/useAuth";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function NotAuthRoute({ children }) {
	const { user } = useAuth();
	const history = useHistory();

	useEffect(() => {
		if (user !== null) {
			history.push("/micuenta");
		}
	}, [user]);

  
  if (user !== null) return <CustomSpinner height={"70vh"} borderSize={5} size={100} />;
  
	return children;
}

export default NotAuthRoute;
