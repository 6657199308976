
import React, { useEffect, useState } from 'react';
import useRecorder from './AudioR';
import './styles.css';
import { v4 as uuidv4 } from 'uuid';
import { MicrophoneIcon, StopIcon, TrashIcon } from 'assets/Imports';

function AudioRecorder({ setVoiceNote }) {

    let [audioURL, isRecording, startRecording, stopRecording, handleAudioUpload]: any = useRecorder();

    const [audioUrl, setAudioUrl] = useState(audioURL);
    const [time, setTime] = useState<number>(0);
    const [second, setSecond] = useState('00');
    const [minute, setMinute] = useState('00');
    const [isActive, setIsActive] = useState(false);
    const [counter, setCounter] = useState(0);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setAudioUrl(audioURL);
        if(audioURL.includes("blob") === false) {
            setVoiceNote(audioURL);
        }
    }, [audioURL]);

    const stop = () => {
        setSecond('00');
        setMinute('00');
        setCounter(0);
        stopRecording();
    };

    const deleteRecord = () => {
        if(window.confirm("¿Estás seguro que deseas eliminar la grabación?")) {
            setAudioUrl("");
        }
    }

    const uploadRecord = () => {
        setLoading(true);
        handleAudioUpload();
    }

    useEffect(() => {
        let intervalId;

        if (isRecording) {
            intervalId = setInterval(() => {
                const secondCounter = counter % 60;
                const minuteCounter = Math.floor(counter / 60);

                const computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
                const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

                setSecond(computedSecond.toString());
                setMinute(computedMinute.toString());

                setCounter(counter => counter + 1);
            }, 1000)
        }

        return () => clearInterval(intervalId);
    }, [isRecording, counter])



    return (
        <>
        <div className='preview-audio-and-upload-btn-container'>
            <audio
                controls
                src={audioUrl}
                preload="auto"
                typeof='audio/webm'
            >
                <code>audio</code> element.
            </audio>
            <button className='upload-btn' onClick={uploadRecord} disabled={loading || audioUrl === ""}>Subir</button>
        </div>

            <div className="time">
                <span className="minute">{minute}</span>
                <span>:</span>
                <span className="second">{second}</span>
            </div>

            <div className='record-buttons-container'>
                <button className='record' onClick={startRecording} disabled={isRecording}>
                    <img src={MicrophoneIcon} alt="microfono icono" />
                </button>
                <button className='stop-record' onClick={stop} disabled={!isRecording}>
                    <img src={StopIcon} alt="stop-recording icon" />
                </button>
                <button className='delete-record' onClick={deleteRecord} disabled={audioUrl === ""}>
                    <img src={TrashIcon} alt="stop-recording icon" />
                </button>
            </div>

        </>
    )
}



export default AudioRecorder;
