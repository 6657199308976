import { useEffect, useState } from 'react';
import { Modal} from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { collection, doc, getDocs, orderBy, query, updateDoc } from 'firebase/firestore';
import { db } from 'initFirebase';
import { useHistory } from 'react-router-dom';

export function AsuetosModal(props) {

    const [numAsuetos, setNumAsuetos] = useState(props.gen.asuetos.length);
    const [asuetosArray, setAsuetosArray]: any = useState(props.gen.asuetos);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const history = useHistory();


    function compare(a, b) {
        const dateSplitA = a.date.split("/")
        const dateSplitB = b.date.split("/")

        const dateStringA = dateSplitA[2] + dateSplitA[1] + dateSplitA[0];
        const dateStringB = dateSplitB[2] + dateSplitB[1] + dateSplitB[0];
		if (dateStringA < dateStringB) {
			return -1;
		}
		if (dateStringA > dateStringB) {
			return 1;
		}
		return 0;
	}

    const moreAsuetos = () => {
        setNumAsuetos(numAsuetos + 1)
        asuetosArray.push({ ['name']: 'asueto' + numAsuetos, date: '' })
    }

    const deletAsueto = () => {
        setNumAsuetos(numAsuetos - 1)
        asuetosArray.pop();
    }

    const updateAsuetos = async () => {
        setLoading(true);
        const tempArray = JSON.parse(JSON.stringify(asuetosArray)).sort(compare);
        
        const q2 = query(collection(db, 'generations', props.gen.id, 'material'), orderBy('num'));
        const querySnapshot2 = await getDocs(q2);

        let initialDateSplit = props.gen.initialDate.split('/');
        let lastDate = initialDateSplit[0] + '/' + (initialDateSplit[1] - 1) + '/' + initialDateSplit[2];

        let index = 0;
        const date = new Date();

        querySnapshot2.forEach(async (material) => {
            let dateSplit = lastDate.split('/');
            date.setDate(parseInt(dateSplit[0]));
            date.setMonth(parseInt(dateSplit[1]));
            date.setFullYear(parseInt(dateSplit[2]));

            let dateString = '';

            index = index + 1;

            if (index > 1) {
                date.setDate(date.getDate() + 7);

                let asuetosIndex = 0;
                if (tempArray.length === 0) {
                    tempArray.push({ date: "01/01/1970" });
                }
                tempArray.forEach(async day => {
                    if (date.getMonth() < 9) {
                        if (date.getDate() < 10) {
                            dateString = '0' + date.getDate() + '/0' + (date.getMonth() + 1) + '/' + (date.getFullYear());
                        } else {
                            dateString = date.getDate() + '/0' + (date.getMonth() + 1) + '/' + (date.getFullYear());
                        }
                    } else {
                        if (date.getDate() < 10) {
                            dateString = '0' + date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear());
                        } else {
                            dateString = date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear());
                        }
                    }
                    if (dateString === day.date) {
                        date.setDate(date.getDate() + 7);
                    }
                    if (asuetosIndex === tempArray.length - 1) {
                        lastDate = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();

                        const materialRef = doc(db, 'generations', props.gen.id, 'material', material.id)
                        await updateDoc(materialRef, {
                            date: lastDate
                        });

                    }
                    asuetosIndex++;
                });
            } else {
                lastDate = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();

                const materialRef = doc(db, 'generations', props.gen.id, 'material', material.id)
                await updateDoc(materialRef, {
                    date: lastDate
                })

            }
            if (index === querySnapshot2.size) {
                if (tempArray[0].date === '01/01/1970') {
                    tempArray.pop()
                }
                const genRef = doc(db, 'generations', props.gen.id);
                await updateDoc(genRef, {
                    asuetos: asuetosArray
                })
                history.go(0)
            }
        })

    }
    const handleChange = (e, index) => {
        let tempArray = JSON.parse(JSON.stringify(asuetosArray));
        tempArray[index].date = e.target.value

        setAsuetosArray(tempArray)
    }


    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Nueva generación
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='setminheightmodal'>
                <>
                    <p>
                        Si hay algún asueto o fecha vacacional durante el transcurso de la generación, ingrésalo aquí
                    </p>
                    {asuetosArray.length > 0 ? (
                        <>
                            {asuetosArray.map((asueto, index) => {
                                return (
                                    <div key={"asueto-row-" + index} className="asueto-row">
                                        <label htmlFor={asueto.name} style={{ fontWeight: 700 }}>Fecha de {index + 1} </label>
                                        <div className='input-new-gen-container'>
                                            <InputMask
                                                required
                                                className="input-new-gen"
                                                type="text"
                                                name={asueto.name}
                                                id={asueto.name}
                                                value={asueto.date}
                                                onChange={(e) => handleChange(e, index)}
                                                placeholder="dd/MM/aaaa"
                                                mask="99/99/9999"
                                                maskChar=""
                                            >
                                            </InputMask>
                                        </div>
                                    </div>
                                )
                            })}
                            <button onClick={deletAsueto} >Remover</button>
                        </>
                    ) : (
                        <></>
                    )}
                    <button onClick={moreAsuetos}>Agregar asueto</button>

                </>
            </Modal.Body>
            <Modal.Footer>
                {!loading ? (
                    <button onClick={updateAsuetos} >Guardar</button>
                ) : (
                    <div className="spinner-border text-danger" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                )}

            </Modal.Footer>
        </Modal>
    );
}