import React, { useEffect, useMemo, useState } from "react";
import "./MainSlider.scss";
import PromotionSlide from "./slides/PromotionSlide/PromotionSlide";
import { retreiveGalleries } from "@services/GalleriesService";
import { IGalleries } from "interfaces/Interfaces";
import { useCurrencyContext } from "contexts/CurrencyContext";

function MainSlider() {
	const { currencyCountry } = useCurrencyContext();
	const isMxn = currencyCountry === "mx";

	const [currentSlide, setCurrentSlide] = useState(0);
	const [galleries, setGalleries] = useState<Array<IGalleries>>([]);
	const [secondInterval, setSecondInterval] = useState<number>(1);

	const slides = useMemo(() => {
		const list: Array<React.ReactElement> = [<PromotionSlide />];
		galleries.forEach((gall, index) => {
			list.push(
				<div key={index}>
					<img
						src={isMxn ? gall.image : gall.imageUSD || gall.image}
						alt={gall.title}
						className={`gall-image ${isMxn ? "mxn" : "usd"}`}
					/>
					<img
						src={isMxn ? gall.imageResponsive : gall.imageResponsiveUSD || gall.imageResponsive}
						alt={`${gall.title} responsive`}
						className={`gall-image-responsive ${isMxn ? "mxn" : "usd"}`}
					/>
				</div>
			);
		});
		return list;
	}, [galleries, isMxn]);

	const onRetreiveGalleries = async () => {
		const data = await retreiveGalleries();
		const { galleries, settings } = data;
		setSecondInterval(settings.interval);
		setGalleries(galleries);
	};

	useEffect(() => {
		onRetreiveGalleries();
	}, []);

	useEffect(() => {
		const intervalId = setInterval(
			() => setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length),
			secondInterval * 2000
		);
		return () => clearInterval(intervalId);
	}, [secondInterval, slides.length]);

	const menu = useMemo(() => ["Inicio", ...galleries.map((gall) => gall.title)], [galleries]);

	return (
		<div className="main-slider-main-container">
			<div className={"content-container " + (currencyCountry + "-ctn")}>
				{slides[currentSlide]}
			</div>
			<ul>
				{menu.map((option, i) => (
					<React.Fragment key={option + i}>
						<li
							className={`web slide-${i}` + (currentSlide === i ? " active " : "")}
							onClick={() => setCurrentSlide(i)}
						>
							{option}
						</li>
						<li
							style={{ transform: `translateX(-${currentSlide * 100}%)` }}
							className={`responsive slide-${i}` + (currentSlide === i ? " active " : "")}
							onClick={() => setCurrentSlide(i)}
						>
							{option}
						</li>
					</React.Fragment>
				))}
			</ul>
		</div>
	);
}

export default MainSlider;
