
import OxxoRef from "@containers/OxxoRef/OxxoRef";

function OxxoRefScreen() {

	return (
		<div style={{ overflow: "hidden" }}>
			<OxxoRef />
		</div>
	);
}

export default OxxoRefScreen;
