import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Col, Dropdown, DropdownButton, Form, Modal, Row } from "react-bootstrap";
import { db } from "initFirebase";
import InputMask from "react-input-mask";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { GenTypes, GenerationTypesToSpanish, isValidGenType } from "interfaces/Generations";
import useActiveGenerations from "@hooks/Modes/useFetchActiveModes.hook";
function NewPaymentRequest(props) {
	const [validated, setValidated] = useState(false);
	const [selected, setSelected] = useState("Seleccionar");
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [image, setImage] = useState<any>("");
	const [users, setUsers]: any = useState([]);
	const [userSelected, setUserSelected]: any = useState("");
	const history = useHistory();
	const [typeGen, setTypeGen] = useState<GenTypes>(GenTypes.InPerson);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [invoiceable, setInvoiceable] = useState<boolean>(true);
	const { activeGenerations } = useActiveGenerations();
	let dropdownValues: any = [];
	dropdownValues = [
		"Tarjeta",
		"Efectivo",
		"OXXO Pay",
		"SPEI",
		"PayPal",
		"Transferencia",
		"Depósito bancario",
		"Otro",
	];

	const phoneFormat = () => {
		let phone = "";
		if (userSelected.phone) {
			phone = userSelected.phone.replace(/\s+/g, "");
			switch (phone.length) {
				case 12:
					phone = phone.replace(/(\S{2})(\S{2})(\S{4})(\S{4})/, "$1 $2 $3 $4");
					break;
				case 13:
					phone = phone.replace(/(\S{3})(\S{2})(\S{4})(\S{4})/, "$1 $2 $3 $4");
					break;
				default:
					phone = phone.replace(/(\S{2})(\S{4})(\S{1,})/, "$1 $2 $3");
			}
		}
		setPhoneNumber(phone);
	};

	const handleSelect = async (e) => {
		if (isValidGenType(e)) {
			setTypeGen(e);
		} else {
			setSelected(e);
		}
		if (e === "Otro") {
			setInvoiceable(false);
		}
	};
	const getUsers = async () => {
		let studentsArray: any = [];
		const q = query(collection(db, "users"));

		const querySnapshot = await getDocs(q);
		let i = 0;
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			const data2 = {
				label: doc.data().name + " - " + doc.data().email,
				value: {
					id: doc.id,
					name: doc.data().name,
					phone: doc.data().phone,
					matricula: doc.data().matricula,
				},
			};
			studentsArray.push(data2);
			i++;
			if (i === querySnapshot.size) {
				setUsers(studentsArray);
			}
		});
	};

	useEffect(() => {
		getUsers();
	}, []);

	const handleSubmit = async (e) => {
		setLoadingSubmit(true);
		const form = e.currentTarget;
		e.preventDefault();

		if (form.checkValidity() === false) {
			e.stopPropagation();
			setValidated(true);
			setLoadingSubmit(false);
		} else {
			if (form.date.value.length !== 10) {
				alert("Ingresar una fecha correcta.");
				setLoadingSubmit(false);
			} else {
				if (selected === "Seleccionar") {
					alert("Selecciona un medio de pago");
					setLoadingSubmit(false);
				} else {
					const date = new Date(Date.now());
					const payAt = new Date(Date.now());
					let datePay = form.date.value.split("/");
					payAt.setDate(datePay[0]);
					payAt.setMonth(datePay[1] - 1);
					payAt.setFullYear(datePay[2]);
					const data = {
						name: userSelected.name,
						amount: form.amount.value,
						date: payAt.valueOf(),
						image: "",
						concept: form.concept.value,
						verified: false,
						medio: selected,
						createdAt: date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear(),
						dateTime: new Date(),
						checked: false,
						userId: userSelected.id,
						type: "solicitado por administrador",
						modalidad: typeGen,
						isInvoiceable: invoiceable,
					};
					await db.collection("paymentHistory").add(data);
					alert("Solicitud enviada con éxito.");
					history.go(0);
				}
			}
		}
	};

	const handleSelection = (e) => {
		setUserSelected(e.value);
	};

	const handleInvoiceable = (e) => {
		let tempSelected = e.target.value;
		if (tempSelected === "yes" && selected !== "Otro") {
			setInvoiceable(true);
		} else {
			setInvoiceable(false);
		}
	};

	const customFilter = (option, searchText) => {
		const labelMatch = option.label.toLowerCase().includes(searchText.toLowerCase());
		const matriculaMatch = option.value.matricula.toLowerCase().includes(searchText.toLowerCase());
		const phoneMatch = option.value.phone.includes(searchText);

		return labelMatch || matriculaMatch || phoneMatch;
	};

	useEffect(() => {
		phoneFormat();
	}, [userSelected]);

	return (
		<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Nueva solicitud de pago</Modal.Title>
			</Modal.Header>
			<Modal.Body className="setminheightmodalAddUser">
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
					<Row className="input-row">
						<Col className="w-100 p-0">
							<Form.Group className="" style={{ margin: 0 }} controlId="formBasicName">
								<Form.Label>
									<b>Nombre del estudiante</b>
								</Form.Label>
								<Select
									onChange={(e) => handleSelection(e)}
									options={users}
									placeholder="Nombre/correo/celular/matrícula"
									filterOption={customFilter}
								/>
								<Form.Control.Feedback type="invalid">
									Por favor ingresa un nombre.
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					{userSelected !== "" && (
						<>
							<Row className="input-row">
								<Col className="w-100 p-0">
									<Form.Group className="" style={{ margin: 0 }} controlId="formBasicPhone">
										<Form.Label>
											<b>Celular</b>
										</Form.Label>
										<Form.Control
											type="text"
											name="phone"
											placeholder="55 9384 5984"
											disabled
											value={phoneNumber}
										/>
										<Form.Control.Feedback type="invalid">
											Por favor ingresa un numero válido.
										</Form.Control.Feedback>
									</Form.Group>
								</Col>
							</Row>
							{userSelected.matricula === "" && (
								<Row className="input-row">
									<Col className="w-100 p-0">
										<Form.Group className="" style={{ margin: 0 }} controlId="formBasicImport">
											<Form.Label>
												<b>Modalidad</b>
											</Form.Label>
											<DropdownButton
												alignRight
												title={"Asigna una modalidad"}
												className="dropdown-container"
												id="dropdown-menu-align-right"
												onSelect={handleSelect}
											>
												{activeGenerations.map((gen: any) => (
													<Dropdown.Item eventKey={gen} className="w-100">
														{GenerationTypesToSpanish[gen]}
													</Dropdown.Item>
												))}
											</DropdownButton>
											<Form.Control.Feedback type="invalid">
												Por favor ingresa un importe válido.
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
							)}
						</>
					)}
					<Row className="input-row">
						<Col className="w-100 p-0">
							<Form.Group className="" style={{ margin: 0 }} controlId="formBasicImport">
								<Form.Label>
									<b>Medio de pago</b>
								</Form.Label>
								<DropdownButton
									alignRight
									title={selected}
									className="dropdown-container"
									id="dropdown-menu-align-right"
									onSelect={handleSelect}
								>
									{dropdownValues.map((item, index) => (
										<Dropdown.Item eventKey={item} className="w-100">
											{item}
										</Dropdown.Item>
									))}
								</DropdownButton>
								<Form.Control.Feedback type="invalid">
									Por favor ingresa un importe válido.
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row className="input-row">
						<Col className="w-100 p-0">
							<Form.Group className="" style={{ margin: 0 }} controlId="formBasicImport">
								<Form.Label>
									<b>Importe (en MXN)</b>
								</Form.Label>
								<Form.Control required type="text" name="amount" placeholder="$0.00" />
								<Form.Control.Feedback type="invalid">
									Por favor ingresa un importe válido.
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row className="input-row">
						<Col className="w-100 p-0">
							<Form.Group className="" style={{ margin: 0 }} controlId="formBasicDate">
								<Form.Label>
									<b>Fecha de pago</b>
								</Form.Label>
								<InputMask
									required
									className="form-control"
									type="text"
									name="date"
									placeholder="dd/MM/aaaa"
									mask="99/99/9999"
									maskChar=""
								></InputMask>
								<Form.Control.Feedback type="invalid">
									Por favor ingresa un año.
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row className="input-row">
						<Col className="w-100 p-0">
							<Form.Group className="" style={{ margin: 0 }} controlId="formBasicInvoiceable">
								<Form.Label>
									<b>¿Este pago es facturable?</b>
								</Form.Label>
								<Form.Check
									checked={invoiceable === true}
									type="checkbox"
									id="checkbox1"
									label="Si"
									value="yes"
									name="invoiceable"
									onChange={handleInvoiceable}
								/>
								<Form.Check
									checked={invoiceable === false}
									type="checkbox"
									id="checkbox2"
									label="No"
									value="no"
									name="invoiceable"
									onChange={handleInvoiceable}
								/>
								<Form.Control.Feedback type="invalid">
									Por favor selecciona una opción.
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row className="input-row">
						<Col className="w-100 p-0">
							<Form.Group className="" style={{ margin: 0 }} controlId="formBasicConcept">
								<Form.Label>
									<b>Concepto</b>
								</Form.Label>
								<Form.Control
									required
									as="textarea"
									rows={3}
									name="concept"
									placeholder="Pago por 10 sesiones"
									data-conekta="card[name]"
								/>
								<Form.Control.Feedback type="invalid">
									Por favor ingresa un concepto.
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col style={{ textAlign: "center" }}>
							{!loadingSubmit ? (
								<button
									type="submit"
									className="tertiary return-btn-style"
									style={{ margin: "auto" }}
								>
									Enviar
								</button>
							) : (
								<div
									className="spinner-border text-danger"
									role="status"
									style={{ marginBottom: "1rem" }}
								>
									<span className="sr-only">Loading...</span>
								</div>
							)}
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
}

export default NewPaymentRequest;
