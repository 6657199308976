import { useFormik } from "formik";
import "./Contact.scss";
import { object, string } from "yup";
import { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "initFirebase";
import useNotification from "@hooks/useNotification";
const newLetterSchema = object({
	email: string().email("Ingresa un correo valido.").required("Ingresa un correo"),
	name: string().required("Ingresa tu nombre completo"),
	description: string().required("Es necesario que agregues un comentario."),
	phone: string().required("Es necesario que agregues un nùmero de celular."),
	prefered: string().required("Es necesario seleccionar un método de contacto preferido."),
});
const messageNotificationFromContacts = {
	succesmessage: "!Te has suscrito con exito,enviaremos una notifacion al administrador!",
	errormessage: "!Ah ocurrido un error al notificar al administrador!",
};

export const Contact = () => {
	const [loadingSend, setLoadingSend] = useState<boolean>(false);
	const { SendNotificationFromContact } = useNotification();

	const formik = useFormik({
		initialValues: {
			email: "",
			name: "",
			description: "",
			phone: "",
			prefered: "correo",
		},

		validationSchema: newLetterSchema,
		onSubmit: async (values) => {
			setLoadingSend(true);

			await addDoc(collection(db, "newsletter"), {
				email: values.email,
				name: values.name,
				phone: values.phone,
				prefered: values.prefered,
				description: values.description,
				created: new Date(),
				status: "pending",
			})
				.then((res) => {
					SendNotificationFromContact(
						res.id,
						values.email,
						messageNotificationFromContacts.succesmessage,
						messageNotificationFromContacts.errormessage
					);
				})
				.catch((error) => {
					console.log(error);
					alert("Ha ocurrido un error, favor de intentar de nuevo más tarde.");
				})
				.finally(() => {
					setLoadingSend(false);
					formik.resetForm();
				});

		},
	});

	const { touched, errors } = formik;

	return (
		<div className="home-contact-main-container">
			<div className="head">
				<h3>Queremos saber de ti</h3>
				<p>
					Envía tus comentarios, sugerencias y contribuciones para estos boletines, incluyendo aspectos destacados de clases y
					asesorías publicadas.
				</p>
			</div>
			<div className="new-letter-container">
				<h2>
					<b>¿DESEAS PERMANECER</b>
				</h2>
				<h2>
					EN <b>CONTACTO?</b>
				</h2>
				<p>Compártenos tus datos y un asesor te contactará a la brevedad.</p>
				<form onSubmit={formik.handleSubmit}>
					<div className="form-row">
						<label htmlFor="name">Nombre completo</label>
						<input
							type="text"
							name="name"
							id="name"
							value={formik.values.name}
							onChange={formik.handleChange}
							placeholder="Políglota en progreso"
						/>
						{touched.name && errors.name && <p className="form-error-msg">{errors.name}</p>}
					</div>
					<div className="form-row">
						<label htmlFor="phone">Número de teléfono</label>
						<input
							type="text"
							name="phone"
							id="phone"
							value={formik.values.phone}
							onChange={formik.handleChange}
							placeholder="Número de teléfono"
						/>
						{touched.phone && errors.phone && <p className="form-error-msg">{errors.phone}</p>}
					</div>
					<div className="form-row">
						<label htmlFor="email">Correo electrónico</label>
						<input
							type="email"
							name="email"
							id="email"
							value={formik.values.email}
							onChange={formik.handleChange}
							placeholder="Ingresa tu dirección de email"
						/>
						{touched.email && errors.email && <p className="form-error-msg">{errors.email}</p>}
					</div>
					<div className="form-row">
						<label htmlFor="prefered">Medio de contacto preferido</label>
						<select name="prefered" id="prefered" value={formik.values.prefered} onChange={formik.handleChange}>
							<option value="whatsapp">WhatsApp</option>
							<option value="correo">Correo</option>
						</select>
						{touched.prefered && errors.prefered && <p className="form-error-msg">{errors.prefered}</p>}
					</div>
					<div className="form-row">
						<label htmlFor="description">Comentarios</label>
						<textarea
							name="description"
							id="description"
							rows={4}
							placeholder="¡Me encantan sus cursos!"
							value={formik.values.description}
							onChange={formik.handleChange}
						></textarea>
						{touched.description && errors.description && <p className="form-error-msg">{errors.description}</p>}
					</div>
					<button disabled={loadingSend}>Enviar</button>
				</form>
			</div>
		</div>
	);
};
