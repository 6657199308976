import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState,  } from "react";
import "./PaymentRequestContainerStyle.css";
import PaymentHistoryCard from "./components/PaymentRequestCard/PaymentRequestCard";
import { db } from "initFirebase";
import { v4 as uuidv4 } from "uuid";
import { useHistory, useLocation } from "react-router-dom";
import NewPaymentRequest from "./components/NewPaymentRequest/NewPaymentRequest";
import { Spinner20vh } from "@components/Spinner/SpinnerMyAccountView";
import { SearchIcon } from "assets/Imports";

function PaymentRequestContainer({ user }) {
	const [payments, setPayments] = useState<any[]>([]);
	const [payments2, setPayments2] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [newRequestModal, setNewRequestModal] = useState(false);
	const [aScroll, setAScroll] = useState<boolean>(false);
	const location = useLocation<any>();
	const [scrollElement, setScrollElement]: any = useState(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [checked, setChecked] = useState(
		location?.state?.check === true || location?.state?.checkedPayment === true ? "read" : "unread"
	);
	const [isArchived, setIsArchived] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [paymentId, setpaymentId] = useState(null);

	const [PaymentIdNotif, setpaymentIdNotif] = useState(null);
	const [isviewPayment, setviewPayment] = useState(false);
	const history = useHistory();

	const getArchived = async () => {
		const q = await query(collection(db, "paymentHistory"), where("isArchived", "==", true));

		const array: any = [];
		const querySnapshot = await getDocs(q);

		querySnapshot.docs.forEach((doc: any) => {
			const data: any = {
				id: doc.id,
				...doc.data(),
			};
			array.push(data);
		});

		array.sort(compare);
		setPayments(array);
		setPayments2(array);
		setLoading(false);
	};

	const getVerified = async () => {
		const q = await query(
			collection(db, "paymentHistory"),
			where("verified", "==", true),
			where("type", "in", ["pago con tarjeta, verificado por conekta", "verificado por webhook Conekta oxxo y spei"])
		);
		var array: any = [];
		// var amount = 0;
		const querySnapshot = await getDocs(q);
		querySnapshot.docs.map((doc) => {
			// doc.data() is never undefined for query doc snapshots
			const data = {
				id: doc.id,
				...doc.data(),
			};
			array.push(data);
		});
		array.sort(compare);
		setPayments(array);
		setPayments2(array);
		setLoading(false);
	};

	const getPayments = async () => {
		const q = await query(collection(db, "paymentHistory"), where("verified", "==", false), where("checked", "==", false));

		const array: any = [];
		const querySnapshot = await getDocs(q);

		querySnapshot.forEach((doc: any) => {
			if (doc.data().dateTime !== undefined) {
				const data: any = {
					id: doc.id,
					...doc.data(),
				};

				if (!data.isArchived || data.isArchived !== true) {
					array.push(data);
				}
			}
		});

		array.sort(compare2);
		setPayments(array);
		setPayments2(array);
		setLoading(false);
	};

	const getPaymentsChecked = async () => {
		const q = await query(collection(db, "paymentHistory"), where("checked", "==", true));
		var array: any = [];
		// var amount = 0;
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc: any) => {
			// doc.data() is never undefined for query doc snapshots
			const data: any = {
				id: doc.id,
				message: doc.data().message !== undefined ? doc.data().message : "",
				...doc.data(),
			};
			if (!data.isArchived || data.isArchived !== true) {
				array.push(data);
			}
		});
		array.sort(compare);
		setPayments(array);
		setPayments2(array);
		setLoading(false);
	};

	const getDeniedPayments = async () => {
		const q = await query(collection(db, "paymentHistory"), where("checked", "==", true), where("verified", "==", false));
		var array: any = [];
		// var amount = 0;
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			const data = {
				id: doc.id,
				message: doc.data().message !== undefined ? doc.data().message : "",
				...doc.data(),
			};
			array.push(data);
		});
		array.sort(compare);
		setPayments(array);
		setPayments2(array);
		setLoading(false);
	};
	const getApprovedPayments = async () => {
		const q = await query(collection(db, "paymentHistory"), where("checked", "==", true), where("verified", "==", true));
		var array: any = [];
		// var amount = 0;
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			const data = {
				id: doc.id,
				message: doc.data().message !== undefined ? doc.data().message : "",
				...doc.data(),
			};
			array.push(data);
		});
		array.sort(compare);
		setPayments(array);
		setPayments2(array);
		setLoading(false);
	};

	const getPaymentsOxxoSpei = async () => {
		const q = await query(
			collection(db, "paymentHistory"),
			where("medio", "in", ["SPEI", "OXXO Pay"]),
			where("checked", "==", false),
			where("image", "==", "")
		);
		var array: any = [];
		// var amount = 0;
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			const data = {
				id: doc.id,
				...doc.data(),
			};
			array.push(data);
		});
		array.sort(compare);
		console.log(array);
		setPayments(array);
		setPayments2(array);
		setLoading(false);
	};

	function compare(a, b) {
		let x = a.createdAt.split("/");
		let y = b.createdAt.split("/");
		const date1 = new Date();
		const date2 = new Date();
		date1.setDate(x[0]);
		date1.setMonth(x[1]);
		date1.setFullYear(x[2]);
		date2.setDate(y[0]);
		date2.setMonth(y[1]);
		date2.setFullYear(y[2]);

		if (date1 > date2) {
			return -1;
		}
		if (date1 < date2) {
			return 1;
		}
		return 0;
	}
	function compare2(a, b) {
		if (a.dateTime.seconds > b.dateTime.seconds) {
			return -1;
		}
		if (a.dateTime.seconds < b.dateTime.seconds) {
			return 1;
		}
		return 0;
	}

	const arraySearch = (array, keyword) => {
		const searchTerm = keyword
			.toLowerCase()
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "")
			.split(" ");
		return array.filter((value) => {
			return searchTerm.every((e) => {
				return (
					value.name
						.toLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
						.includes(e) ||
					value.matricula
						?.toLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
						.includes(e) ||
					value.phone?.replace(/\s/g, "").includes(e)
				);
			});
		});
	};

	const handleOnChange = async (e) => {
		setLoading(true);
		let value = e.target.value;
		if (value.length > 2) {
			let search = await arraySearch(payments2, value);
			setPayments(search);
			setLoading(false);
		} else {
			setPayments(payments2);
			setLoading(false);
		}
	};

	const scrollToNotification = () => {
		let scroll = window.document.getElementById("request-card-" + location?.state?.paymentHistoryId);
		if (scroll !== null && !aScroll) {
			setScrollElement(scroll);
			scroll.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
			scroll.style.border = "2px solid #ca5050";
			setAScroll(true);
		} else {
			if (scrollElement !== null) {
				let e = scrollElement;
				e.style.border = "none";
			}
		}
	};

	useEffect(() => {
		if (location?.state !== undefined && payments.length > 0 && !aScroll && !loading && location?.state?.type) {
			setChecked("verified");
			//scrollToNotification();
		} else if (location?.state?.check === true || location?.state?.checkedPayment === true) {
			setChecked("read");
			//scrollToNotification//();
		} else if (location?.state?.denied === true) {
			setChecked("denied");
		} else if (checked === "unread") {
			scrollToNotification();
			//console.log(checked);
		}
	}, [loading]);

	useEffect(() => {
		if (aScroll) {
			location.state = undefined;
		}
	}, [aScroll]);

	useEffect(() => {
		setLoading(true);
		setPayments([]);
		setPayments2([]);
		if (checked === "unread") {
			getPayments();
		} else if (checked === "read") {
			getPaymentsChecked();
		} else if (checked === "oxxo") {
			getPaymentsOxxoSpei();
		} else if (checked === "denied") {
			getDeniedPayments();
		} else if (checked === "approved") {
			getApprovedPayments();
		} else if (checked === "verified") {
			getVerified();
		} else if (checked === "Archived") {
			getArchived();
		}
	}, [checked, refresh, location.state, isArchived]);

	useEffect(() => {
		if (location?.state?.paymentId || location?.state?.paymentHistoryId) {
			//console.log("entre");
			setpaymentId(location?.state?.paymentId);
			setpaymentIdNotif(location?.state?.paymentHistoryId);
			setviewPayment(true);
		}
	}, [location.state]);

	const handleClickBackToPayments = () => {
		setLoading(true);
		setviewPayment(false);

		// Eliminar el paymentId del location.state para evitar que se muestre al recargar la página
		history.push({
			...location,
			state: undefined,
		});
		setpaymentIdNotif(null);
		setpaymentId(null);
	};

	return (
		<>
			<div className="payment-history-title-container">
				<h1>Solicitudes de pago</h1>
			</div>
			<div className="payment-history-subtitle-container">
				<p style={{ margin: 0 }}>Historial de todos los pagos hechos.</p>
				<div>
					{isviewPayment && (
						<span
							style={{
								margin: 0,
								marginBottom: "4%",
								cursor: "pointer",
								color: "#ca5050",
								fontWeight: "bold",
								textDecoration: "underline",
							}}
							onClick={handleClickBackToPayments}
						>
							click para volver a ver todos tus pagos
						</span>
					)}
					<div
						style={{
							border: "2px solid #7b7b7e",
							width: 450,
							borderRadius: 10,
							padding: "10px 20px",
							display: "flex",
						}}
					>
						<img src={SearchIcon} alt="search-icon" />
						<input
							style={{ border: "none", paddingLeft: 15, width: "100%" }}
							onChange={handleOnChange}
							placeholder="Buscar por nombre/correo/celular/matrícula"
							type="text"
						/>
					</div>
				</div>
			</div>

			<div>
				<button className="tertiary return-btn-style" onClick={() => setNewRequestModal(true)}>
					Nueva solicitud
				</button>
			</div>

			<div style={{ marginBottom: 20, display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: "wrap" }}>
				<button
					className={"contact-type-selector-btn" + (checked === "unread" ? " active" : "")}
					onClick={() => setChecked("unread")}
					disabled={loading}
				>
					No revisados
				</button>
				<button
					className={"contact-type-selector-btn" + (checked === "read" ? " active" : "")}
					onClick={() => setChecked("read")}
					disabled={loading}
				>
					Revisados
				</button>
				<button
					className={"contact-type-selector-btn" + (checked === "oxxo" ? " active" : "")}
					onClick={() => setChecked("oxxo")}
					disabled={loading}
				>
					OXXO/SPEI
				</button>
				<button
					className={"contact-type-selector-btn" + (checked === "denied" ? " active" : "")}
					onClick={() => setChecked("denied")}
					disabled={loading}
				>
					Negados
				</button>
				<button
					className={"contact-type-selector-btn" + (checked === "approved" ? " active" : "")}
					onClick={() => setChecked("approved")}
					disabled={loading}
				>
					Aprobados
				</button>
				<button
					className={"contact-type-selector-btn" + (checked === "verified" ? " active" : "")}
					onClick={() => setChecked("verified")}
					disabled={loading}
				>
					Verificados
				</button>

				<div style={{ display: "flex", justifyContent: "flex-end", width: "35%" }}>
					<button
						className={"contact-type-selector-btn" + (checked === "Archived" ? " active" : "")}
						onClick={() => setChecked("Archived")}
						disabled={loading}
					>
						Archivadas
					</button>
				</div>
			</div>
			{!loading ? (
				<div>
					{payments.length > 0 ? (
						<>
							{(paymentId !== null || PaymentIdNotif != null
								? payments.filter((payment) => payment.id === paymentId || payment.id === PaymentIdNotif)
								: payments
							).map((payment: any, i) => {
								return (
									<PaymentHistoryCard
										data={payment}
										setIsArchived={setIsArchived}
										isArchived={isArchived}
										//paymentId={paymentId}
										handleClickBackToPayments={handleClickBackToPayments}
										index={i}
										highlighted={payment.id === paymentId || payment.id === PaymentIdNotif}
										setRefresh={setRefresh}
										refresh={refresh}
										collaborator={user}
										key={payment.id}
									/>
								);
							})}

							{/* Verificar si no se encontró ningún pago */}
							{PaymentIdNotif != null
								? payments.every((payment) => payment.id !== paymentId && payment.id !== PaymentIdNotif) && (
										<h2>Esta solicitud de pago ya no está disponible y posiblemente ha sido eliminada.</h2>
								  )
								: null}
						</>
					) : (
						<h2>No hay solicitudes nuevas</h2>
					)}
				</div>
			) : (
				<Spinner20vh />
			)}
			<NewPaymentRequest show={newRequestModal} onHide={() => setNewRequestModal(false)} key={uuidv4()} />
		</>
	);
}

export default PaymentRequestContainer;
