import Footer from "@components/Footer/Footer";
import NavbarCustom from "@components/NavbarCustom/NavbarCustom";
import CheckoutContainer from "@containers/CheckoutContainerTesting/CheckoutContainer";
import React, { useState } from "react";

function CheckoutScreenTesting() {


    return (
        <div style={{overflow: "hidden"}}>
            <CheckoutContainer />
        </div>
    )
}

export default CheckoutScreenTesting;