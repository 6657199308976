import "../../../DynamicTextContainer/DynamixText.css";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import { Interface } from "readline";
import firebase from "firebase/compat/app";
import { v4 as uuidv4 } from "uuid";
import { getSubtitle } from "functions/Generations/FunctionsGenerations";
import { CloseCircleIcon, DocumentAttachFileIcon, FolderIcon, TextIcon, DinamicTextBack } from "assets/Imports";
import { FaCheck } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";

function DynamicalText({ back, setSelectedItems, selectedItems }) {
	const [step, setStep] = useState(0);
	const [option, setOption] = useState(0);
	const [edit, setEdit] = useState("");
	const [editLocation, setEditLocation] = useState<any>({ name: "" });
	const [editMain, setEditMain] = useState<any>({ text: "" });
	const [editVideo, setEditVideo] = useState<any>({ titulo1: "" });
	const [editNavbar, setEditNavbar] = useState<any>({ name: "" });
	const [location, setLocation] = useState<any>(null);

	const [fechas, setFechas] = useState<any>(null);
	const [editFechas, setEditFechas] = useState<any>({ text: "" });
	const [main, setMain] = useState<any>(null);
	const [navbar, setNavbar] = useState<any>(null);
	const [video, setVideo] = useState<any>(null);
	const [program1, setProgram1] = useState<any>(null);
	const [program2, setProgram2] = useState<any>(null);
	const [program3, setProgram3] = useState<any>(null);
	const [gens, setGens]: any = useState([]);

	const subLocation = ["location", "main", "navbar", "video", "kids"];
	const subInscription = ["program1", "program2", "program3"];
	const subNiños = ["primera", "segunda"];
	const subInformacion = ["fechas"];
	const subPrograma = ["modulo1", "modulo2", "modulo3", "modulo4", "modulo5"];
	const subInscriptionDiscount = ["program1Coupon", "program2Coupon", "program3Coupon"];
	const [testimonials, setTestimonials] = useState<any>(null);
	const [editTestimonial, setEditTestimonial] = useState<any>({ name: "", desc: "" });
	const [antesEmpezar, setAntesEmpezar] = useState<any>([]);
	const [temario, setTemario] = useState<any>([]);
	const [seminario, setSeminario] = useState<any>([]);
	const [metodos, setMetodos] = useState<any>([]);
	const [ninos, setNinos] = useState<any>([]);
	const [announcements, setAnnouncements] = useState(new Array());
	const [editFaq, setEditFaq] = useState<any>({ question: "", answer: "" });

	const items = [
		{ name: "Homepage", img: FolderIcon },
		{ name: "Testimonios", img: FolderIcon },
		{ name: "FAQ", img: FolderIcon },
		{ name: "Inscripción", img: FolderIcon },
		{ name: "Pagos", img: FolderIcon },
		{ name: "Aviso", img: FolderIcon },
		{ name: "Nuestro programa", img: FolderIcon },
	];

	const handleCheck = (item) => {
		if (selectedItems.includes(item?.name)) {
			setSelectedItems(selectedItems.filter((i) => i !== item?.name));
		} else {
			setSelectedItems([...selectedItems, item?.name]);
		}
	};

	return (
		<>
			<div className="dynamic-text-card-mainbox">
				<div className="dynamic-text-card show">
					<div style={{ display: "flex", alignItems: "center", marginBottom: "20px", columnGap:"15px" }} onClick={back}>
						<img src={DinamicTextBack} alt="Imagen" />
						<h5 style={{marginBottom:"0", color:"#CA5050"}}>Textos dinámicos</h5>
					</div>

					<ul className="list-unstyled">
						{items.map((item, index) => (
							<li key={index} className="row align-items-center mb-1">
								<div className="col-12 col-md-8 d-flex align-items-center">
									<img src={item.img} alt={item.name} />
									<p style={{ marginLeft: "10px", marginBottom: "0px", color: "gray", fontWeight: "bold" }}>{item.name}</p>
								</div>
								<div className={`col-12 col-md-4 d-flex justify-content-md-end`} onClick={() => handleCheck(item)}>
									<div
										className={`checkbox d-flex justify-content-center align-items-center ${
											selectedItems.includes(item.name) ? "checked" : ""
										}`}
									>
										{selectedItems.includes(item.name) && <FaCheck />}
									</div>
								</div>
							</li>
						))}
						
					</ul>
				</div>
			</div>
		</>
	);
}

export default DynamicalText;
