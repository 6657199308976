import { useEffect, useState } from "react";
import './styles.css';

function ProgressBarComponent({now, totalPay, type}) {
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        if(now > 0) {
            setDisplay(true);
        }

    }, [now])

    function romanize(num) {
        if (isNaN(num))
            return NaN;
        var digits: any = String(+num).split(""),
            key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
                "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
                "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
            roman = "",
            i = 3;
        while (i--)
            roman = (key[+digits.pop() + (i * 10)] || "") + roman;
        return Array(+digits.join("") + 1).join("M") + roman;
    }

    return(
        <div style={{width: '100%', height: 10, boxShadow: 'rgb(50 50 93 / 25%) -5px 2px 4px 0px inset', borderRadius: 10, margin: '0 20px'}}>
            <div style={{width: `${now}%`, background: 'linear-gradient(to right, #ee1c34, #500b28)', height: 10, borderRadius: 10, position: 'relative'}}>
                <div className={`${!display ? 'not-show' : ''}`} style={{width: 25, height: 25, background: '#500b28', position: 'absolute', right: 0, top:'50%', borderRadius: '50%', transform: 'translate(50%, -50%)'}} >
                    {type === 'money' ? (
                        <p className="progress-bar-text-ball">${totalPay.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}MXN</p>
                    ) : (
                        <p className="progress-bar-text-ball sesiones-text-progress-bar" >Seminario {romanize(totalPay)}</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ProgressBarComponent;