export enum LogTypes {
	PaymentRequest = "paymentRequest",
	GenerationAssignment = "generationAssignment",
	Invoice = "invoice",
	contactReply = "contactReply",
}

export enum LogActions {
	Accept = "accept",
	Reject = "reject",
	Request = "request",
	Cancel = "cancel",
}
