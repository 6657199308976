import {
	BrazilFlagSVG,
	ChinaFlagSVG,
	FranceFlagSVG,
	GermanyFlagSVG,
	ItalyFlagSVG,
	JapanFlagSVG,
	NextArrow,
	RussiaFlagSVG,
	UnitedStatesFlagSVG,
} from "assets/Imports";

export const vocabularyModules = [
	{ number: 1, initialSeminary: 1, endSeminary: 10 },
	{ number: 2, initialSeminary: 11, endSeminary: 25 },
	{ number: 3, initialSeminary: 26, endSeminary: 40 },
	{ number: 4, initialSeminary: 41, endSeminary: 50 },
	{ number: 5, initialSeminary: 51, endSeminary: 60 },
];

export const languagesFlags = {
	french: FranceFlagSVG,
	german: GermanyFlagSVG,
	italian: ItalyFlagSVG,
	japanese: JapanFlagSVG,
	portuguese: BrazilFlagSVG,
	russian: RussiaFlagSVG,
	chinese: ChinaFlagSVG,
	english: UnitedStatesFlagSVG,
};

export const languagesSpanish = {
	english: "Inglés",
	japanese: "Japonés",
	russian: "Ruso",
	italian: "Italiano",
	german: "Alemán",
	chinese: "Chino",
	portuguese: "Portugués",
	french: "Francés",
};
