import { Container } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import oxxopayLogo from './images/oxxopay.png';
import { useHistory } from "react-router";
import './OxxoRef.scss';

function OxxoRef() {

    const location = useLocation<any>();
    const history = useHistory();
    if (location.state == undefined) {
        history.push({
            pathname: "/",
        })
    }
    const client = location.state.client;
    const course = location.state.course;
    const charge = location.state.charge;
    var date = new Date((charge.payment_method.expires_at * 1000));

    var month;

    switch (date.getMonth()) {
        case 0:
            month = "Enero";
            break;
        case 1:
            month = "Febrero";
            break;
        case 2:
            month = "Marzo";
            break;
        case 3:
            month = "Abril";
            break;
        case 4:
            month = "Mayo";
            break;
        case 5:
            month = "Junio";
            break;
        case 6:
            month = "Julio";
            break;
        case 7:
            month = "Agosto";
            break;
        case 8:
            month = "Septiembre";
            break;
        case 9:
            month = "Octubre";
            break;
        case 10:
            month = "Noviembre";
            break;
        case 11:
            month = "Diciembre";
            break;
    }


    return (
        <div className="container-oxxoref">
            <div>
                <h1>Referencia para pago en tiendas OXXO</h1>
            </div>
            <div className="card-oxxoref">
                <div>
                    <img src={oxxopayLogo} alt="" />
                </div>
                <div>
                    <h4>Comprador:</h4>
                    <p>{client.name}</p>
                </div>
                <div>
                    <h4>Nombre de la empresa:</h4>
                    <p>Academia de Lenguas Internacionales</p>
                </div>
                <div>
                    <h4>Monto a pagar:</h4>
                    <h2>{course.inversion}</h2>
                    <p>*Más comisión de oxxo</p>
                </div>
                <div>
                    <h4>Referencia de pago:</h4>
                    <div className="oxxoref-num-container">
                        <h2>{charge.payment_method.reference}</h2>
                    </div>
                </div>
                <div>
                    <h4>Fecha de expiración:</h4>
                    <p>{date.getDate()} de {month} de {date.getFullYear()}</p>
                </div>
                <div>
                    <h4>Número de orden:</h4>
                    <p>{charge.order_id}</p>
                </div>
                <br />
                <div style={{wordBreak: "break-word"}}>
                    <p className="instrucciones-text" style={{ fontSize: "1rem" }}>
                        Instrucciones:
                    </p>
                    <p style={{ marginTop: "10px" }}>
                        1. Acude a la tienda OXXO más cercana.
                    </p>
                    <p>
                        2. Indica en caja que quieres realizar un pago de OXXO Pay.
                    </p>
                    <p>
                        3. Dicta al cajero el número de referencia en esta ficha para que la teclee directamente en la pantalla de venta.
                    </p>
                    <p>
                        4. Realiza el pago correspondiente con dinero en efectivo.
                    </p>
                    <p>
                        5. Al confirmar tu pago, el cajero te entregará un comprobante impreso. En él podrás verificar que la transacción se haya realizado correctamente. Conserva este comprobante de pago.
                    </p>
                    <p>
                        6. Opción A: Envía tu comprobante de inscripción por WhatsApp al teléfono <a href="https://wa.me/525623925453">+52 56 2392 5453</a>, al <a href="https://wa.me/525551848431">+52 55 5184 8431</a> o a la dirección de correo electrónico <a className="contact-email-text">contacto@academiadelenguasinternacionales.mx</a> para continuar con el proceso de registro.
                        <br />
                        <br />
                        opcion B: Actualiza tu pago en el siguiente link al tener una cuenta creada: <Link to="/micuenta/actualizar-pagos" style={{textDecoration: 'underline', color: 'blue', cursor: 'pointer'}}>https://academiadelenguasinternacionales.mx/micuenta/actualizar-pagos</Link>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default OxxoRef;