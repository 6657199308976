import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	query,
	setDoc,
	updateDoc,
	where,
} from "firebase/firestore";
import { randDarkColor } from "utils/GeneralFunctions";
import emailjs from "emailjs-com";
export const preRegisterUser = async (
	docRef,
	conektaData,
	user,
	db,
	existingUser,
	history,
	stripeData,
	stripeCustomerResponse
) => {
	await setDoc(docRef, {
		name: conektaData.name,
		email: conektaData.email,
		phone: conektaData.phone,
		conektaId: conektaData.id,
		stripeId: stripeData?.id
			? stripeData?.id
			: stripeCustomerResponse?.data[0]?.id
			? stripeCustomerResponse?.data[0]?.id
			: "",
		generationId: existingUser.data().generationId || "",
		matricula: existingUser.data().matricula || "",
		schedule: existingUser.data().schedule || "",
		promotion: false,
		formAnswered: false,
		color: randDarkColor(),
	});

	const payments = query(collection(db, "paymentHistory"), where("userId", "==", existingUser.id));
	const phSnap = await getDocs(payments);

	phSnap.docs.forEach(async (payment) => {
		const payRef = doc(db, "paymentHistory", payment.id);
		await updateDoc(payRef, { userId: user.uid });
	});

	const preUserRef = doc(db, "users", existingUser.id);

	await deleteDoc(preUserRef);

	history.go(0);
};

export const registerUser = async (
	res,
	user,
	userType,
	db,
	history,
	stripeData,
	stripeCustomerResponse
) => {
	const userDocRef = doc(db, "users", user.uid); // Esto crea la referencia al documento

	await setDoc(userDocRef, {
		name: res.data.name,
		email: res.data.email,
		phone: res.data.phone,
		conektaId: res.data.id,
		stripeId: stripeData?.id
			? stripeData?.id
			: stripeCustomerResponse?.data[0]?.id
			? stripeCustomerResponse?.data[0]?.id
			: "",
		generationId: "",
		matricula: "",
		schedule: "",
		promotion: false,
		formAnswered: false,
		color: randDarkColor(),
		userType: 1,
	});
	const docSnap = await getDoc(userDocRef);

	const data = {
		name: res.data.name,
		email: res.data.email,
	};

	await emailjs
		.send("service_kznboar", "template_r303fk1", data, "user_PPI0O8nofs9cbuJ3JRWlT")
		.then(
			function (res) {},
			function (error) {
				console.log(error);
			}
		);

	history.go(0);
};
