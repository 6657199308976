import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";


function NewColorCategory(props) {

    const [category, setCategory] = useState({ subcategory: "", color: "#000000" })

    const closeModal = () => {
        props.onHide();
    }

    const save = () => {
        if(category.subcategory !== "mf" && category.subcategory !== "fm") {
            props.newColor(category).then(() => {
                setCategory({ subcategory: "", color: "#000000" });
                props.onHide();
            });
        } else {
            alert("Esa subcategoría no se puede editar, esta definida por default.")
        }
        
    }

    return (
        <Modal
            show={props.show}
            onHide={closeModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="bookmark-word-note-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Crear color
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label htmlFor="bookmark-note">Subcategoría</label>
                <div className="input-container">
                    <input
                        type="text"
                        value={category.subcategory}
                        onChange={(e) => setCategory({...category, subcategory: e.target.value})}
                    />
                </div>
                <label htmlFor="bookmark-note">Color</label>
                <div className="input-container" style={{ width: 100, height: 50 }}>
                    <input
                        type="color"
                        name="bookmark-note"
                        value={category.color}
                        onChange={(e) => setCategory({ ...category, color: e.target.value })}
                        placeholder="Nota a revisar"
                        id="bookmark-note"
                        style={{ height: "100%" }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex">
                <button className="action-btn secondary-btn-bg" onClick={closeModal}>Cancelar</button>
                <button className="action-btn linear-bg" onClick={save}>Guardar</button>
            </Modal.Footer>
        </Modal>
    );
}

export default NewColorCategory;