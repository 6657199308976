import { RepeatArrow, VolumeIconWhite } from "assets/Imports";

export const specialCharacters = {
	french: ["À", "â", "é", "è", "ê", "î", "ô", "ù", "û", "œ", "ç"],
	german: ["Ä", "ö", "ü", "ß"],
	italian: ["À", "è", "ì", "ò", "ù"],
	russian: ["Ё"],
	chinese: ["ǎ", "à", "á", "ā", "è", "ě", "ē", "é", "ǐ", "ì", "í", "ī", "ò", "ó", "ò", "ō", "ǒ", "ù", "ú", "ǔ", "ū", "ǚ"],
	portuguese: ["Ã", "â", "à", "ê", "õ", "ô", "ç"],
	japanese: [],
	english: [],
};

// const convert = ["ĭă"]

export const spanishTest = {
	correcto: ["¡CORRECTO!"],
	incorrecto: ["¡INCORRECTO!"],
	termino: ["Término"],
	middleScreen: [""],
	finalScreen: [""],
	respuestaCorrecta: "Respuesta correcta:",
	tuRespuesta: "Tu respuesta:",
	buttons: {
		acerte: "Acerté",
		continuar: "Continuar",
		revisar: "Revisar",
		nolose: "No lo sé",
		repetir: "Repetir",
		terminar: "Terminar",
		saltar: "Saltar",
	},
	escribeTerminoCorrectamente: "Escribe el término correctamente",
	finalScreenSubtitle: [""],
	terminosBloque: "Términos en el siguiente bloque",
	escribeEn: "Escribe en <b>español</b>",
};

export const ignoreWords = ["la", "el", "las", "los", "un", "una", "unos", "unas"];
export const ignoredSings = '¿?¿! ¡*}{¡¨*][_\\:"-';

export const proTips = [
	"<span>Al responder en español, no se distinguen mayúsculas</span>",
	`<span className="with-image">${`Pulsa <div className="listen-to-word-container protip">
<img className="speaker-image" src="${VolumeIconWhite}" alt="icono de volumen" />
<img className="arrow-image" src="${RepeatArrow}" alt="icono de de flecha en forma de circulo" />
</div> 2 veces para escuchar`}</span>`,
];

export const langTest = {
	french: {
		correcto: ["Correct", "Bravo !"],
		incorrecto: ["à réviser"],
		termino: ["terme"],
		middleScreen: [
			"Beau travail, vous faites des progrès.",
			"Super, continuez comme ça !",
			"Beau travail, vous assurez.",
			"Quelle maîtrise ! Continuez comme ça.",
			"Vous avancez bien, vous allez y arriver.",
			"Bravo !",
		],
		finalScreen: [" Bravo ! Vous avez revu tous les termes."],
		finalScreenSubtitle: ["Continuez à étudier en enchaînant les séries pour vous entraîner encore plus."],
		respuestaCorrecta: "Réponse correcte:",
		tuRespuesta: "Vous avez dit:",
		buttons: {
			acerte: "J'avais raison",
			continuar: "Continuer",
			revisar: "Vérifier",
			nolose: "Je ne sais pas",
			repetir: "Étudier à nouveau",
			terminar: "Terminer le mode apprendre",
			saltar: "Passer",
		},
		terminosBloque: "Termes à étudier",
		escribeEn: "Répondez en <b>français</b>",
		escribeTerminoCorrectamente: "Écrivez la bonne réponse",
	},
	german: {
		correcto: ["Richtig!", "Prima!", "Super!"],
		incorrecto: ["Das solltest du wiederholen!"],
		termino: ["Begriff"],
		tuRespuesta: "Deine Antwort:",
		respuestaCorrecta: "Richtige Antwort:",
		middleScreen: [
			"Du wirst immer besser, du schaffst das!",
			"Gut gemacht, immer weiter so!; Mach weiter so!",
			"Du machst richtig tolle Fortschritte!",
			"Das machst du wirklich super!",
		],
		finalScreen: ["Toll gemacht! Du hast alle Begriffe gelernt."],
		finalScreenSubtitle: ["Lerne noch ein paar Runden, um mehr Übung zu bekommen."],
		buttons: {
			acerte: "Meine Antwort war richtig",
			continuar: "Weiter",
			revisar: "Überprüfen",
			nolose: "Ich weiß nicht",
			repetir: "Nochmal lernen",
			terminar: "Lernmodus beenden",
			saltar: "Überspringen",
		},
		terminosBloque: "Begriffe aus dieser Runde",
		escribeEn: "Auf <b>Deutsch</b> schreiben",
		escribeTerminoCorrectamente: "Schreib die richtige Antwort",
	},
	portuguese: {
		correcto: ["Correto!"],
		incorrecto: ["Isto precisa de revisão!"],
		termino: ["Termo"],
		respuestaCorrecta: "Resposta correta:",
		tuRespuesta: "Você disse:",
		middleScreen: [
			"Continue com seu bom trabalho!",
			"Siga firme, você consegue",
			"Bom trabalho",
			"você está progredindo.",
			"Bom trabalho! Você está arrasando.",
		],
		finalScreen: ["Parabéns! Você estudou todos os termos."],
		finalScreenSubtitle: ["Continue estudando com rodadas adicionais para praticar ainda mais."],
		buttons: {
			acerte: "Eu estava certo",
			continuar: "Continuar",
			revisar: "Verificar",
			nolose: "Não sei",
			repetir: "Estudar novamente",
			terminar: "Fechar Aprender",
			saltar: "Pular",
		},
		terminosBloque: "Termos desta rodada",
		escribeEn: "Digite em <b>português</b>",
		escribeTerminoCorrectamente: "Escreva a resposta correta",
	},
	italian: {
		correcto: ["Corretto"],
		incorrecto: ["Studialo!"],
		termino: ["Termine"],
		respuestaCorrecta: "Risposta corretta:",
		tuRespuesta: "Hai detto:",
		middleScreen: [
			"Ottimo lavoro, stai facendo progressi.",
			"Ce la farai, continua così.",
			"Stai progredendo.",
			"Stai andando forte, ce la farai.",
			"Continua così!",
			"stai andando alla grande",
			"Ottimo lavoro!",
		],
		finalScreen: ["Ben fatto! Hai studiato tutti i termini!"],
		finalScreenSubtitle: ["Continua a studiare un turno dopo l'altro per asercitarti ancora di più."],
		buttons: {
			acerte: "Avevo ragione",
			continuar: "Continua",
			revisar: "Verifica",
			nolose: "Non lo so",
			repetir: "Devo ristudiarla",
			terminar: "Termina",
			saltar: "Salta",
		},
		terminosBloque: "Termini da questo turno",
		escribeEn: "Rispondi in <b>italiano</b>",
		escribeTerminoCorrectamente: "Copia la risposta corretta",
	},
	english: {
		correcto: ["Correct!"],
		incorrecto: ["Study this one!"],
		termino: ["Term"],
		tuRespuesta: "You said:",
		respuestaCorrecta: "Correct answer:",
		buttons: {
			continuar: "Continue",
			repetir: "Study again",
			terminar: "Finish",
			nolose: "I don't know",
			acerte: "I was correct",
			revisar: "Check",
			saltar: "Skip",
		},
		middleScreen: [
			"Nice job!",
			"You've got this, keep going.",
			"Keep up the good work.",
			"Great job, you're making progress.",
			"Nice work, you're crushing it.",
		],
		finalScreen: ["Way to go! You've studied all the terms."],
		finalScreenSubtitle: ["Keep studying with continuous rounds to get even more practice."],
		terminosBloque: "Ternms in this round",
		escribeEn: "Type in <b>English</b>",
		escribeTerminoCorrectamente: "Write the correct answer",
	},
	russian: {
		correcto: ["Правильно"],
		incorrecto: ["Выичите этот термин!"],
		termino: ["Термин"],
		tuRespuesta: "Вы ответили:",
		respuestaCorrecta: "Правильный ответ:",
		buttons: {
			continuar: "Продолжить",
			repetir: "Изучать ещё ",
			terminar: "Завершить учебу",
			nolose: "Не знаю",
			acerte: "Я ответил правильно",
			revisar: "Проверить",
			saltar: "Пропустить",
		},
		middleScreen: ["Продолжайте в том же духе!", "Так держать, у вас все получится!", "Превосходно, вы делаете успехи!"],
		finalScreen: ["Поздравляем! Вы изучили все термины."],
		finalScreenSubtitle: ["Продолжайте учиться раунд за раундом для дополнительной практики."],
		terminosBloque: "Термины из этого этапа",
		escribeEn: "Ответ <b>(Русский)</b>",
		escribeTerminoCorrectamente: "Копировать правильный ответ",
	},
};
