import { Receipt } from "@components/Receipt/Receipt";
import { collection, doc, orderBy, query, getDocs, updateDoc, getDoc, where } from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import "./GenerateReceiptModal.scss";
import { getPaymentMethodCode } from "functions/Receipt";
import { Spinner20vh } from "@components/Spinner/SpinnerMyAccountView";

export function GenerateReceiptModal(props) {
	const [payment, setPayment] = useState<any>(null);
	const docRef = doc(db, "paymentHistory", props.payment.id);
	const [loading, setLoading] = useState<boolean>(false);

	const generateReceipt = async () => {
		let paymentData = { ...props.payment };

		const date = new Date();
		const createdAt = paymentData.createdAt.split("/");
		date.setMonth(createdAt[1]);
		date.setDate(createdAt[0]);
		date.setFullYear(createdAt[2]);
		paymentData.description = "Seminario - Programa de Alto Rendimiento en 8 Idiomas Simultáneos";

		paymentData.createdAt = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

		const docSnap = await getDoc(docRef);

		const userRef = doc(db, "users", props.payment.userId);
		const userSnap = await getDoc(userRef);
		if (userSnap.exists()) {
			paymentData.matricula = userSnap.data()!.matricula;
			paymentData.name = userSnap.data()!.name;
		} else {
			paymentData.matricula = "";
		}
		if (paymentData.acceptDate === undefined) {
			paymentData.acceptDate = "";
		}

		const pQ = query(collection(db, "paymentHistory"), where("userId", "==", userSnap.id));
		const paymentsSnap = await getDocs(pQ);

		if (paymentData.unityPrice === undefined && parseInt(paymentData.amount) === 12000 && paymentsSnap.size === 1) {
			paymentData.unityPrice = 200;
			paymentData.seminaries = 60;
		} else if (paymentData.unityPrice === undefined) {
			paymentData.unityPrice = 300;
			paymentData.seminaries = Math.floor(parseInt(paymentData.amount) / 300);
		}

		if (docSnap.exists()) {
			if (docSnap.data().NoReceipt === undefined || docSnap.data().acceptDate === undefined) {
				const q = query(collection(db, "paymentHistory"), orderBy("NoReceipt", "asc"));
				const snap = await getDocs(q);

				let noReceipt;
				if (docSnap.data().NoReceipt === undefined) {
					noReceipt = snap.size + 1 + 100;
					paymentData.NoReceipt = noReceipt;
				}

				await updateDoc(docRef, {
					...(docSnap.data().NoReceipt === undefined && { NoReceipt: noReceipt }),
					...(docSnap.data().acceptDate === undefined && { acceptDate: paymentData.acceptDate }),
				});
			}
			if (docSnap.data().seminaries !== undefined) paymentData.seminaries = docSnap.data().seminaries;
			if (docSnap.data().acceptDate !== undefined) paymentData.acceptDate = docSnap.data().acceptDate;
			if (docSnap.data().unityPrice !== undefined) paymentData.unityPrice = docSnap.data().unityPrice;
			if (docSnap.data().description !== undefined && docSnap.data().description.indexOf("Simultánmeos") === -1) {
				paymentData.description = docSnap.data().description;
			}
			paymentData.name = docSnap.data().name;
			if (docSnap.data().NoReceipt !== undefined && docSnap.data().NoReceipt < 100) {
				await updateDoc(docRef, {
					NoReceipt: docSnap.data()!.NoReceipt + 100,
				}).then((res) => {
					paymentData.NoReceipt = docSnap.data()!.NoReceipt + 100;
				});
			} else if (docSnap.data().NoReceipt !== undefined) {
				paymentData.NoReceipt = docSnap.data()!.NoReceipt;
			}
		}

		setPayment(paymentData);
	};

	const getReceipt = async () => {
		setLoading(true);
		await updateDoc(docRef, {
			...payment,
		});

		const receiptElement = document.getElementById("receipt");
		if (receiptElement !== null) {
			const canvas = await html2canvas(receiptElement);
			const image = canvas.toDataURL("image/png", 1.0);

			let doc = new jsPDF("l", "mm", [297, 210]);
			doc.addImage(image, "PNG", 0, 0, 297, 210);
			doc.save(getPaymentMethodCode(payment!.medio) + "-" + payment!.NoReceipt + "-" + payment!.matricula);
		}

		setLoading(false);
	};

	useEffect(() => {
		if (props.show) {
			generateReceipt();
		} else {
			setPayment(null);
		}
	}, [props.show]);

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			className="generate-receipt-modal"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Recibo de pago</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{payment === null && <Spinner20vh />}
				{props.show && payment !== null && <Receipt payment={payment} />}
				{payment !== null && (
					<div className="modify-receipt-container">
						<div className="row-container">
							<h2>Editar recibo</h2>
						</div>
						<div className="row-container">
							<label htmlFor="unityPrice">Precio unitario</label>
							<div className="input-container">
								<input
									type="number"
									value={payment.unityPrice}
									name={"unityPrice"}
									onChange={(e) => setPayment({ ...payment, [e.target.name]: e.target.value })}
								/>
							</div>
						</div>
						<div className="row-container">
							<label htmlFor="seminaries">Cantidad</label>
							<div className="input-container">
								<input
									type="text"
									value={payment.seminaries}
									name={"seminaries"}
									onChange={(e) => setPayment({ ...payment, [e.target.name]: e.target.value })}
								/>
							</div>
						</div>
						<div className="row-container">
							<label htmlFor="amount">Fecha de aceptación</label>
							<div className="input-container">
								<input
									type="text"
									value={payment.acceptDate}
									name={"acceptDate"}
									onChange={(e) => setPayment({ ...payment, [e.target.name]: e.target.value })}
								/>
							</div>
						</div>
						<div className="row-container">
							<label htmlFor="description">Descripción:</label>
							<div className="input-container">
								<input
									type="text"
									value={payment.description}
									name={"description"}
									onChange={(e) => setPayment({ ...payment, [e.target.name]: e.target.value })}
								/>
							</div>
						</div>
						<div className="row-container">
							<label htmlFor="description">Nombre:</label>
							<div className="input-container">
								<input
									type="text"
									value={payment.name}
									name={"name"}
									onChange={(e) => setPayment({ ...payment, [e.target.name]: e.target.value })}
								/>
							</div>
						</div>
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				<button className="action-btn secondary-btn-bg" onClick={props.onHide}>
					Cerrar
				</button>
				<button className={"action-btn linear-bg " + (loading ? "disabled" : "")} onClick={getReceipt} disabled={loading}>
					Descargar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
