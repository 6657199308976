import { getLangSpanishSimple } from "@components/Vocabulary/PersonalizedTest/functions/functions";
import { getUniqueTotalWordsArray } from "@containers/VocabularySeminaryContainer/components/Test/functions/TestFunctions";
import { Modal } from "react-bootstrap";
import "./QuizDefaultInfoModalStyles.scss";

export function QuizDefaultInfoModal(props) {

    function compare(a, b) {
        if (a.native_word.toLowerCase() < b.native_word.toLowerCase()) {
            return -1;
        }
        if (a.native_word.toLowerCase() > b.native_word.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="quiz-default-info-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Ronda de estudio predeterminada
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="quiz-info-main-container">
                    <div className="row-quiz-default-info-modal">
                        <h3>Nombre:</h3>
                        <p>{props.quiz.name}</p>
                    </div>
                    <div className="row-quiz-default-info-modal">
                        <h3>Tipo de respuesta:</h3>
                        <p>{props.quiz.answerType === "normal" ? "En español" : `En ${getLangSpanishSimple(props.quiz.language)}`}</p>
                    </div>
                    <div className="row-quiz-default-info-modal">
                        <h3>Términos:</h3>
                        <div
                            className="word-quiz-default-container"
                        >
                            {getUniqueTotalWordsArray(props.quiz.quizWords).sort(compare).map((word, index) => {
                                return (
                                    <div
                                        className="word-quiz-default"
                                        key={"word-quiz-default-" + index}
                                    >
                                        {(props.quiz.language === "chinese" || (props.quiz.language === "japanese" && word.optional_word !== null)) ? (
                                            <p
                                                style={{ color: '#00000080' }}
                                            >
                                                <span style={{color: '#000000', marginRight: 10}}>
                                                    {word.optional_word}
                                                </span>
                                                {props.quiz.language === "japanese" ? "(" + word.native_word + ")" : word.native_word}
                                            </p>
                                        ) : (
                                            <p>
                                                {word.native_word}
                                            </p>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <button className="action-btn linear-bg" onClick={props.onHide}>Cerrar</button>
            </Modal.Footer>
        </Modal >
    );
}