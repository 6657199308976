const seminario1 = {
    num: 1,
    name: 'Seminario I',
    lang1: 'Francés',
    sub1: 'Les nombres',
    desc1: '- Reglas de pronunciación, números',
    lang2: 'Alemán',
    sub2: 'Die Zahlen',
    desc2: '- Reglas de pronunciación, números',
    lang3: 'Japonés',
    sub3: 'Trazos para escribir',
    desc3: '- Hiragana, Katakana',
    lang4: 'Italiano',
    sub4: 'Forme irregolari',
    desc4: '- Reglas de pronunciación, pronombres personales (sujeto), verbo <em>essere</em> (ser), artículos indefinidos',
    lang5: 'Ruso',
    sub5: 'Алфави́т',
    desc5: '- Alfabeto cirílico, escritura de molde y manuscrita',
    lang6: 'Chino',
    sub6: '数字 (shùzì)',
    desc6: '- Reglas de pronunciación, números',
    lang7: 'Portugués',
    sub7: 'Ser - Presente simples',
    desc7: '- Reglas de pronunciación, pronombres personales (sujeto), verbo <em>ser, estar.</em>',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario2 = {
    num: 2,
    name: 'Seminario II',
    lang1: 'Francés',
    sub1: 'Les verbes irrréguliers',
    desc1: '- Pronombres personales (sujeto), verbo être, avoir. Saludos, artículos indefinidos, conjunción <em>et</em>',
    lang2: 'Alemán',
    sub2: 'Ich bin gücklich - sehr glücklich',
    desc2: '- Pronombres personales (sujeto), verbo <em>sein</em> (ser), saludos, formación de números, conjunción <em>und</em>.',
    lang3: 'Japonés',
    sub3: 'Trazos para escribir (diptongos)',
    desc3: '- Hiragana, Katakana - grupo diptongos + Tenten (") y Maru (°)',
    lang4: 'Italiano',
    sub4: 'Avere - indicativo presente',
    desc4: '- Verbo <em>avere</em> (haber/tener), negación de verbos (<em>non</em>), formación de plurales (sustantivos + adjetivos)',
    lang5: 'Ruso',
    sub5: 'Алфави́т',
    desc5: '- Alfabeto cirílico, escritura de molde y manuscrita, pronombres personales (sujeto), conjunción A',
    lang6: 'Chino',
    sub6: '你好! (nǐ hǎo)',
    desc6: '- Pronombres personales, oraciones simples (afirmativa, interrogativa), verbo 是 (<em>shì</em> - ser), 叫 (<em>jiào</em> - llamarse)',
    lang7: 'Portugués',
    sub7: 'Como vai?',
    desc7: '- Reglas de pronunciación, saludos, conjugación de verbos regulares en presente indicativo (-ar)',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario3 = {
    num: 3,
    name: 'Seminario III',
    lang1: 'Francés',
    sub1: 'L\'heure',
    desc1: '- La hora (formal + informal), saludos, despedidas.',
    lang2: 'Alemán',
    sub2: 'Artikel- Wörter und Substantiv: Deklination',
    desc2: '- Artículos determinados + determinados (nominativo), oraciones interrogativas, negación de objeto (<em>kein</em>), negación de verbo (<em>nicht</em>)',
    lang3: 'Japonés',
    sub3: 'わたしははやしです',
    desc3: '- Pronombres personales, verbo です <em>desu (ser)</em>, partícula は <em>(wa)</em> oraciones interrogativas con か <em>ka.</em> ',
    lang4: 'Italiano',
    sub4: 'Per dire l\'ora + Adjetivos numerales',
    desc4: '- Números, la hora (formal + informal)',
    lang5: 'Ruso',
    sub5: 'номер - число',
    desc5: '- Números',
    lang6: 'Chino',
    sub6: '日期 (rìqí)',
    desc6: '- La fecha (año, mes, día), los meses del año',
    lang7: 'Portugués',
    sub7: 'Onde o senhor mora?',
    desc7: '- Preposiciones de + <em>em</em> (lugar), artículos definidos + contracciones.',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario4 = {
    num: 4,
    name: 'Seminario IV',
    lang1: 'Francés',
    sub1: 'Conjugación de verbos',
    desc1: '- Conjugación de verbos regulares (- er) + reflexivos',
    lang2: 'Alemán',
    sub2: 'Los complementos facultativos del tiempo',
    desc2: '- La hora (formal + informal)',
    lang3: 'Japonés',
    sub3: 'Numeración',
    desc3: '- Números',
    lang4: 'Italiano',
    sub4: 'L\'indicativo presente dei verbi regolari + Possesivi',
    desc4: '- Conjugación de verbos regulares (presente indicativo)\n- Adjetivos posesivos',
    lang5: 'Ruso',
    sub5: 'Здравствуйте + Как тебя зовут?',
    desc5: '- Nombres, patronímico, apellido.\n- Saludos, pronombres personales (acusativo), "yo me llamo..."',
    lang6: 'Chino',
    sub6: '人民币 (rénmínbì)',
    desc6: '- Sistema monetario chino 元, 角, 分 (yuán, jiǎo, fēn)\n- Uso de 零 (líng - cero)',
    lang7: 'Portugués',
    sub7: 'Meu, minha, nosso, nossa',
    desc7: '- Adjetivos posesivos',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario5 = {
    num: 5,
    name: 'Seminario V',
    lang1: 'Francés',
    sub1: 'La négation',
    desc1: '- Oraciones negativas (ne... pas, ne... plus, ne...rien, ne... jamais)',
    lang2: 'Alemán',
    sub2: 'Verbkonjugation',
    desc2: '- Conjugación de verbos regulares (presente indicativo)',
    lang3: 'Japonés',
    sub3: 'Verbos + Tiempo, estaciones, clima',
    desc3: '- "Conjugación" de verbos, presente, pasado, futuro, afirmativo, negativo\n- Partícula de objeto directo を (o)',
    lang4: 'Italiano',
    sub4: 'Verbos reflexivos',
    desc4: '- Conjugación de verbos reflexivos',
    lang5: 'Ruso',
    sub5: 'Чей это рюкзак?',
    desc5: '- ¿De quién? con чей, чья, чьё.\n- Adjetivos posesivos - singular nominativo',
    lang6: 'Chino',
    sub6: '多少钱? (duō shǎo qián)',
    desc6: '- ¿Cuánto?, ¿Cuánto cuesta esta manzana?\n- Demostrativo 这 (zhè), numerales 个 (gè)',
    lang7: 'Portugués',
    sub7: 'Na estação / Que horas são? + Números',
    desc7: '- Números\n- La hora (formal + informal), saludos',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario6 = {
    num: 6,
    name: 'Seminario VI',
    lang1: 'Francés',
    sub1: 'Fiche d\'état civil de Monsieur Saule',
    desc1: '- Artículos determinados (<em>le, la, les</em>), profesiones, meses del año, concordancia de género (adjetivos), interrogativos (<em>quel, quelle</em>)',
    lang2: 'Alemán',
    sub2: 'Possessiv- Pronomen',
    desc2: '- Adjetivos posesivos (nominativo)\n- Preguntas por datos personales con <em>wie, wo, woher...</em>',
    lang3: 'Japonés',
    sub3: 'Verbos + Tiempo, estaciones, clima',
    desc3: '- "Conjugación" de verbos, presente, pasado, futuro, afirmativo, negativo\n- Complemento circunstancial de tiempo con partícula に',
    lang4: 'Italiano',
    sub4: 'Sai dov\'è il telefono?',
    desc4: '- Preposiciones de lugar + artículos definidos\n- Verbo de existencia <em>esserci</em> (c\'è, ci sono - "hay"), días de la semana',
    lang5: 'Ruso',
    sub5: 'У тебя есть собака? - Какая красивая квартира!',
    desc5: '- Expresar posesión (yo tengo...), pronombres personales (genitivo)\n- Adjetivos (masculino, femenino, neutro - singular nominativo)',
    lang6: 'Chino',
    sub6: '你要什么? (nǐ yào shén me)',
    desc6: '- Verbo 要 (yào - <em>querer</em>), interrogativo 什么 (<em>shén me</em> - ¿qué?)\n- Uso de 两 (liǎng)',
    lang7: 'Portugués',
    sub7: 'Preposições de lugar',
    desc7: '- Preposiciones de lugar, verbo <em>estar</em>\n- Preposiciones articuladas (do, da, dos, das)',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario7 = {
    num: 7,
    name: 'Seminario VII',
    lang1: 'Francés',
    sub1: 'Verbos reflexivos',
    desc1: '- Conjugación de verbos reflexivos (presente indicativo)',
    lang2: 'Alemán',
    sub2: 'Repaso general',
    desc2: '- Repaso general',
    lang3: 'Japonés',
    sub3: 'Repaso general',
    desc3: '- Repaso general',
    lang4: 'Italiano',
    sub4: 'Pronomi diretti',
    desc4: '- Pronombres directos (lo, la, le, li, etc.)',
    lang5: 'Ruso',
    sub5: 'Repaso general',
    desc5: '- Repaso general',
    lang6: 'Chino',
    sub6: 'Repaso general',
    desc6: '- Repaso general\n- Uso de 不 (bù) para negar',
    lang7: 'Portugués',
    sub7: 'Futuro imediato + Ir',
    desc7: '- Verbo ir + preposición <em>de</em> para indicar medio de transporte\n- Futuro inmediato',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario8 = {
    num: 8,
    name: 'Seminario VIII',
    lang1: 'Francés',
    sub1: 'Repaso general',
    desc1: '- Repaso general',
    lang2: 'Alemán',
    sub2: 'Modalverben',
    desc2: '- Conjugación de verbos modales (presente indicativo)\n- Oraciones negativas e interrogativas con verbos modales',
    lang3: 'Japonés',
    sub3: 'Pronombres demostrativos',
    desc3: '- Pronombres y adjetivos demostrativos (este, ese, aquél)\n- Partícula の para indicar posesión',
    lang4: 'Italiano',
    sub4: 'Repaso general',
    desc4: '- Repaso general',
    lang5: 'Ruso',
    sub5: 'Вы говорите по-русски? - Conjugación de verbos',
    desc5: '- Conjugación de verbos regulares - ать, - ить (presente)\n- Prefijo по- para idiomas',
    lang6: 'Chino',
    sub6: '你学习什么? (nǐ xué xí shén me)',
    desc6: '- Uso de 语 (yǔ) y 文 (wén) para el idioma\n- Uso de 人 (rén) para formar gentilicio',
    lang7: 'Portugués',
    sub7: 'Repaso general',
    desc7: '- Repaso general',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario9 = {
    num: 9,
    name: 'Seminario IX',
    lang1: 'Francés',
    sub1: 'Plus fort que... La comparaison',
    desc1: '- La comparación (plus, moins, ausi... que)\n- Adjetivos irregulares (bon / meilleur, etc.)\n- Pronombnres tónicos',
    lang2: 'Alemán',
    sub2: 'Modalverben',
    desc2: '- Oraciones afirmativas y negativas con verbos modales\n- Declinación de artículos y adjetivos + declinación mixta en acusativo',
    lang3: 'Japonés',
    sub3: 'Pronombres demostrativos',
    desc3: '- Uso de いくら para preguntar precio + Uso de ください para comprar',
    lang4: 'Italiano',
    sub4: 'Andare - indicativo presente',
    desc4: '- Conjugación del verbo <em>andare</em> (ir) + preposiciones de dirección (<em>in,a</em>)\n- Uso de preposición final <em>per</em>',
    lang5: 'Ruso',
    sub5: 'Вы работаете или учитесь?',
    desc5: '- Conjugación y uso de verbos учиться, заниматься, изучать, учить\n- Conjugación de verbos reflexivos + Introducción al caso preposicional',
    lang6: 'Chino',
    sub6: '我住在中国 (wǒ zhù zài zhōng guó)',
    desc6: '- Verbo 在 (zài) como marcador de lugar y preposición.',
    lang7: 'Portugués',
    sub7: 'Pretérito perfeito',
    desc7: '- Pasado simple de verbos regulares en indicativo (- ar, - er, - ir)',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario10 = {
    num: 10,
    name: 'Seminario X',
    lang1: 'Francés',
    sub1: 'L\'expression de la quantité - Les partitifs',
    desc1: '- Uso de artículos partitivos (<em>de la, de l\', du, des</em>)\n- Adjetivos posesivos (<em>mon, ma, mes...</em>)',
    lang2: 'Alemán',
    sub2: 'Modalverben',
    desc2: '- Oraciones afirmativas y negativas con verbos modales\n- Declinación artículos y adjetivos + declinación mixta en acusativo',
    lang3: 'Japonés',
    sub3: 'Adjetivos - い',
    desc3: '- Conjugación de adjetivos- い (presente, pasado, afirmativo, negativo)',
    lang4: 'Italiano',
    sub4: 'Il futuro semplice dei verbi regolari',
    desc4: '- Conjugación de verbos regulares en futuro del indicativo\n- Verbos de excepción en futuro (<em>-gare, -care, -ciare, -giare</em>)',
    lang5: 'Ruso',
    sub5: 'Где? Здесь, тут, там',
    desc5: '- Adverbios de lugar здесь, тут, там.\n- Conjugación de verbo irregular жить (vivir)\n- Uso de preposiciones в на con caso preposicional.',
    lang6: 'Chino',
    sub6: 'Preposiciones de lugar',
    desc6: '- Uso y formación de preposiciones de lugar\n- Uso de 的 (de) con función posesiva',
    lang7: 'Portugués',
    sub7: 'Gostar de',
    desc7: '- Verbo <em>gostar</em> + preposición de\n- Pronombres y adjetivos demostrativos',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario11 = {
    num: 11,
    name: 'Seminario XI',
    lang1: 'Francés',
    sub1: 'Le futur proche',
    desc1: '- Verbo <em>aller</em> (ir) + futuro inmediato con <em>aller</em>',
    lang2: 'Alemán',
    sub2: 'Trennbare Verben',
    desc2: '- Uso y conjugación de verbos con prefijo separable (ej. <em>anziehen</em>)',
    lang3: 'Japonés',
    sub3: '何時ですか?+ Numeración',
    desc3: '- Números\n- La hora',
    lang4: 'Italiano',
    sub4: 'Futuro - Forme irregolari',
    desc4: '- Conjugación en futuro indicativo de verbos irregulares',
    lang5: 'Ruso',
    sub5: 'Где вы были вчера? Что вы делали?',
    desc5: '- Uso y formación de verbos en pasado',
    lang6: 'Chino',
    sub6: 'Preposiciones de lugar',
    desc6: '- Uso y formación de preposiciones de lugar\n- Uso de 的 (de) con función posesiva',
    lang7: 'Portugués',
    sub7: 'O plural',
    desc7: '- Formación de plural (sustantivos + adjetivos)\n- Pronombres posesivos',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario12 = {
    num: 12,
    name: 'Seminario XII',
    lang1: 'Francés',
    sub1: 'Venir de, être en train de, être sur le point de',
    desc1: '- Verbo venir y su uso en el pasado reciente\n- Verbo être y su uso en la formación del presente progresivo',
    lang2: 'Alemán',
    sub2: 'Trennbare Verben',
    desc2: '- Uso de verbos con prefijo separable\n- Pronombres personales (objeto directo, acusativo)',
    lang3: 'Japonés',
    sub3: 'Verbos de existencia ある - いる',
    desc3: '- Uso de verbos de existencia ある/いる\n- Partícula に como marcador de lugar + preposiciones de lugar',
    lang4: 'Italiano',
    sub4: 'Il presente progressivo',
    desc4: '- Presente progresivo\n- Formación regular e irregular de gerundio',
    lang5: 'Ruso',
    sub5: 'Вы знаете Бориса?',
    desc5: '- Pronombres personales en acusativo\n- Declinación de sustantivos en acusativo singular',
    lang6: 'Chino',
    sub6: '是 como marcador de lugar',
    desc6: '- Uso del verbo 是 (shì) como marcador de lugar\n- Preposiciones de lugar + interrogativo 谁 (shéi)',
    lang7: 'Portugués',
    sub7: 'Fazer, pôr, dizer, dar',
    desc7: '- Verbos fazer, pôr, dizer, dar en presente indicativo',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario13 = {
    num: 13,
    name: 'Seminario XIII',
    lang1: 'Francés',
    sub1: 'Les pronoms directs',
    desc1: '- Pronombres directos (<em>le, la, les</em>) en oraciones afirmativas y negativas\n- Uso de pronombres directos con los verbos <em>aimer</em> y <em>connaître</em>',
    lang2: 'Alemán',
    sub2: 'Trennbare Verben',
    desc2: '- Oraciones con verbos modales y verbos con prefijo separable\n- Identificación de verbos con prefijo separable en diccionarios',
    lang3: 'Japonés',
    sub3: '本はどこですか?',
    desc3: '- Preguntas y respuestas con preposiciones de lugar',
    lang4: 'Italiano',
    sub4: 'Stare per',
    desc4: '- Expresión verbal <em>stare per</em> + infinitivo para expresar futuro inmediato',
    lang5: 'Ruso',
    sub5: 'Откуда ты?',
    desc5: '- Declinación de sustantivos en genitivo singular\n- Nacionalidades: gentilicio vs. adjetivo + uso de preposición из',
    lang6: 'Chino',
    sub6: '几点? (jǐ diǎn?)',
    desc6: '- La hora (formal + informal)',
    lang7: 'Portugués',
    sub7: 'Os pronomes pessoais',
    desc7: '- Pronombres personales (sujeto, complemento, directo, indirecto)',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario14 = {
    num: 14,
    name: 'Seminario XIV',
    lang1: 'Francés',
    sub1: 'Les pronoms directs',
    desc1: '- Pronombres directos (<em>le, la, les</em>) en oraciones afirmativas y negativas\n- Formación de preguntas (formal, informal, inversión)',
    lang2: 'Alemán',
    sub2: 'Geben + Helfen',
    desc2: '- Verbos con complemento en dativo\n- Pronombres personales en dativo',
    lang3: 'Japonés',
    sub3: 'Partícula で',
    desc3: '- Partícula で como marcador de lugar\n- Practicar un deporte: 遊ぶ (あそぶ) vs. する',
    lang4: 'Italiano',
    sub4: 'Forma impersonale',
    desc4: '- Oraciones impersonales con <em>si</em>',
    lang5: 'Ruso',
    sub5: 'Чы это книги?',
    desc5: '- Preguntar ¿de quién? con чы\n- Formación de plurales (sustantivos) + plurales irregulares',
    lang6: 'Chino',
    sub6: '了y 没 (le y méi)',
    desc6: '- Partículas 了 y 没 para indicar pasado',
    lang7: 'Portugués',
    sub7: 'Os pronomes pessoais',
    desc7: '- Pronombres personales (suujeto, complemento, directo, indirecto)',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario15 = {
    num: 15,
    name: 'Seminario XV',
    lang1: 'Francés',
    sub1: 'Les pronoms indirects',
    desc1: '- Pronombres indirectos\n- Oraciones negativas con pronombres indirectos.',
    lang2: 'Alemán',
    sub2: 'Der Imperativ',
    desc2: '- Formación del modo imperativo rregular\n- Formas irregulares del imperativo',
    lang3: 'Japonés',
    sub3: 'La partícula も',
    desc3: '- Uso de la partícula も como también',
    lang4: 'Italiano',
    sub4: 'Forma impersonale dei verbi riflessivi',
    desc4: '- Forma impersonal de verbos reflexivos <em>(ci si ...)</em>',
    lang5: 'Ruso',
    sub5: 'Какие красивые дома!',
    desc5: '- Formación y uso de adjetivos en nominativo plural',
    lang6: 'Chino',
    sub6: '什么时候? (shénme shíhòu?)',
    desc6: '- Complementos circunstanciales de tiempo + días de la semana\n- Futuro',
    lang7: 'Portugués',
    sub7: 'Comparativo',
    desc7: '- Comparativo de cualidad + cantidad',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario16 = {
    num: 16,
    name: 'Seminario XVI',
    lang1: 'Francés',
    sub1: 'Le futur simple',
    desc1: '- Formación de futuro simple de verbos regulares\n- Formas irregulares del futuro simple\n- <em>Continuer de</em> + infinitivo',
    lang2: 'Alemán',
    sub2: 'Der Imperativ',
    desc2: '- Imperativo de verbos con prefijo separable',
    lang3: 'Japonés',
    sub3: 'Adjetivos- な',
    desc3: '- Forma descriptiva y predicativa de adjetivos\n- Adjetivos- な (afirmativo, negativo, presente pasado )\n- Adverbios de gradación とても y ちょっと',
    lang4: 'Italiano',
    sub4: 'Preposizioni di luogo',
    desc4: '- Preposiciones de lugar <em>(a, in, per, da...)</em>',
    lang5: 'Ruso',
    sub5: 'С кем?',
    desc5: '- Declinación de sustantivos singulares en caso instrumental.\n- Pronombres personales en instrumental\n- Preposiciones para el verbo играть (на, в)',
    lang6: 'Chino',
    sub6: '什么时候? (shénme shíhòu?)',
    desc6: '- Complementos circunstanciales de tiempo y su posición en la oración',
    lang7: 'Portugués',
    sub7: 'Modo imperativo',
    desc7: '- Modo imperativo de verbos regulares + irregulares',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario17 = {
    num: 17,
    name: 'Seminario XVII',
    lang1: 'Francés',
    sub1: 'Il y a, pendant, depuis, dans',
    desc1: '- Preposiciones temporales <em>(il y a, pendant, depuis, dans)</em>',
    lang2: 'Alemán',
    sub2: 'Die Pluralformen',
    desc2: '- Formación de plurales (sustantivos)',
    lang3: 'Japonés',
    sub3: 'Adjetivos- な',
    desc3: '- Forma descriptiva y predicativa de adjetivos\n- Adjetivos-な (afirmativo, negativo, presente pasado )\n- Adverbios de gradación とても y ちょっと',
    lang4: 'Italiano',
    sub4: 'Preposizioni di tempo',
    desc4: '- Preposiciones de tiempo <em>(da, per, tra)</em>\n- <em>Ora</em> vs. ormai vs. <em>adesso</em>',
    lang5: 'Ruso',
    sub5: 'Дни недели',
    desc5: '- Días de la semana\n- Uso de preposición в como marcador de tiempo',
    lang6: 'Chino',
    sub6: 'Concepción del tiempo',
    desc6: '- Concepción lineal y vertical del tiempo\n- Formación de adverbios de tiempo',
    lang7: 'Portugués',
    sub7: 'Não... (nem)... nem',
    desc7: '- Negación de varios elementos con <em>nem</em>\n- Preposiciones articuladas con <em>a</em>',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario18 = {
    num: 18,
    name: 'Seminario XVIII',
    lang1: 'Francés',
    sub1: 'Il y a, pendant, depuis, dans',
    desc1: '- Preposiciones temporales (<em>il y a, pendant, depuis, dans</em>)\n- Conjugación de verbos <em>-re</em> (ej. <em>attendre, écrire, faire</em>)',
    lang2: 'Alemán',
    sub2: 'Die Pluralformen',
    desc2: '- Formación de plurales (sustantivos)',
    lang3: 'Japonés',
    sub3: 'La forma volitiva (- ましょう)',
    desc3: '- Uso de - ましょう para invitar a realizar una actividad',
    lang4: 'Italiano',
    sub4: 'Di + articolo con significato partitivo',
    desc4: '- Uso y formación de artículos partitivos',
    lang5: 'Ruso',
    sub5: 'Который час?',
    desc5: '- La hora + adverbios de tiempo\n- Conjugación de verbos tipo спать',
    lang6: 'Chino',
    sub6: '你怎么过? (nǐ zěnmeguò?)',
    desc6: '- Traducción de carta',
    lang7: 'Portugués',
    sub7: 'Futuro',
    desc7: '- Conjugación de verbos regulares en futuro indicativo\n- Verbos irregulares en futuro indicativo',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario19 = {
    num: 19,
    name: 'Seminario XIX',
    lang1: 'Francés',
    sub1: 'Le pronom « en »',
    desc1: '- Uso de <em>en</em> para expresar cantidades indeterminadas\n- Oraciones afirmativas y negativas con <em>en</em>',
    lang2: 'Alemán',
    sub2: 'Grados del adjetivo',
    desc2: '- Comparativo de igualdad, superioridad + superlativo\n- Adjetivos irregulares',
    lang3: 'Japonés',
    sub3: 'La forma volitiva (- ましょう)',
    desc3: '- Uso de - ましょう para invitar a realizar una actividad',
    lang4: 'Italiano',
    sub4: 'Passato prossimo',
    desc4: '- Uso y formación del pasado compuesto con auxilar <em>essere/avere</em>\n- Formación de participio pasado + concordancia con verbos modales',
    lang5: 'Ruso',
    sub5: 'Мой день',
    desc5: '- Adverbios de tiempo (por la mañana, por la tarde)\n- Uso de preposición в como marcador de dirección (acusativo)\n- Conjugación de verbos <em>вставать, идти</em>',
    lang6: 'Chino',
    sub6: '你怎么过? (nǐ zěnmeguò?)',
    desc6: '- Traducción de carta + periodos de tiempo con 从 (cóng)',
    lang7: 'Portugués',
    sub7: 'Trazer ou levar?',
    desc7: '- Conjugación de verbos <em>trazer</em> + <em>levar</em> (presente, pretérito indicativo)',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario20 = {
    num: 20,
    name: 'Seminario XX',
    lang1: 'Francés',
    sub1: 'Le pronom y',
    desc1: '- Uso de pronombre y como complemento de lugar + verbos con <em>à</em>\n- Expresiones frecuentes con <em>y</em>',
    lang2: 'Alemán',
    sub2: 'Die Negation',
    desc2: '- Uso de <em>ja, nein, doch</em>\n- Negación de verbo, negación de persona, negación de complemento',
    lang3: 'Japonés',
    sub3: '好き(な) / きらい(な)',
    desc3: '- Uso de - 好き(な)/きらい(な) para expresar gustos/disgustos\n- Uso de 大 (だい) como intensificador',
    lang4: 'Italiano',
    sub4: 'Avere o essere? - Passato prossimo',
    desc4: '- Selección de verbo auxiliar para pasado compuesto <em>(essere vs. avere)</em>',
    lang5: 'Ruso',
    sub5: 'Приятного аппетита!',
    desc5: '- Alimentos + verbos <em>пить, есть</em>\n- Uso de почему y потому что para preguntar por y dar razones',
    lang6: 'Chino',
    sub6: '时候 (shíhòu)',
    desc6: '- 时候 para expresar periodos de tiempo <em>(cuando...)</em>',
    lang7: 'Portugués',
    sub7: 'Imperfeito',
    desc7: '- Conjugación de verbos regulares en imperfecto indicativo\n- Verbos irregulares en imperfecto indicativo',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario21 = {
    num: 21,
    name: 'Seminario XXI',
    lang1: 'Francés',
    sub1: 'Passé composé',
    desc1: '- Uso y formación del pasado compuesto con auxiliar <em>être/avoir</em>\n- Formación de participio pasado (verbos regulares)',
    lang2: 'Alemán',
    sub2: 'Dativpräpositionen',
    desc2: '- Preposiciones que rigen el caso dativo',
    lang3: 'Japonés',
    sub3: 'Forma-て',
    desc3: '- Formación de forma- て + uso como modo imperativo',
    lang4: 'Italiano',
    sub4: 'Passato prossimo - esercizi + Forme irregolari',
    desc4: '- Formas irregulares del participio pasado',
    lang5: 'Ruso',
    sub5: '"Tener": pasado, presente, futuro + negación',
    desc5: '- у меня en todos los tiempos verbales',
    lang6: 'Chino',
    sub6: '时候 (shíhòu)',
    desc6: '- 时候 para expresar periodos de tiempo <em>(cuando...)</em>',
    lang7: 'Portugués',
    sub7: 'Vir, saber',
    desc7: '- Conjugación de verbos <em>vir</em> + <em>saber</em> (presente, pretérito, imperfecto)',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario22 = {
    num: 22,
    name: 'Seminario XXII',
    lang1: 'Francés',
    sub1: 'Passé composé - ejercicios',
    desc1: '- Oraciones afirmativas y negativas en pasado\n- Uso de pronombres COD con passé composé',
    lang2: 'Alemán',
    sub2: 'Gefallen',
    desc2: '- Verbo <em>gefallen</em> (gustar) con dativo\n- Declinaciones mixtas en dativo',
    lang3: 'Japonés',
    sub3: '~てもいいです',
    desc3: '- Uso de ~てもいいです para pedir u otorgar permiso',
    lang4: 'Italiano',
    sub4: 'Altre preposizioni',
    desc4: '- Otros usos de preposiciones <em>di, da, in, a</em>',
    lang5: 'Ruso',
    sub5: 'В понедельник утром',
    desc5: '- Uso de <em>на</em> como marcador temporal/situacional',
    lang6: 'Chino',
    sub6: '时候? (shíhòu)',
    desc6: '- 时候 para expresar periodos de tiempo <em>(cuando...)</em>',
    lang7: 'Portugués',
    sub7: 'Verbos pronominais',
    desc7: '- Verbos con pronombre reflexivo y recíproco',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario23 = {
    num: 23,
    name: 'Seminario XXIII',
    lang1: 'Francés',
    sub1: 'J\'ai vu, j\'ai mis, j\'ai fait',
    desc1: '- Participios pasado irregulares',
    lang2: 'Alemán',
    sub2: 'Gehören',
    desc2: '- Verbo <em>gehören</em> (pertenecer) con dativo\n- Declinaciones mixtas en dativo',
    lang3: 'Japonés',
    sub3: '- てはいけません',
    desc3: '- Prohibición fuerte con - てはいけません',
    lang4: 'Italiano',
    sub4: 'Pronomi indiretti',
    desc4: '- Pronombres indirectos\n- Verbo <em>piacere</em> (gustar) + verbos afines',
    lang5: 'Ruso',
    sub5: 'Вы читали этот журнал?',
    desc5: '- Adjetivos demostrativos (nominativo)\n- Verbo покупать + лежать',
    lang6: 'Chino',
    sub6: 'Adjetivos predicativos + adverbios intensificadores',
    desc6: '- Adjetivos predicativos + adverbios intensificadores\n- Uso de 又 (yòu) para enlistar cualidades',
    lang7: 'Portugués',
    sub7: 'Ouvir, pedir',
    desc7: '- Conjugación de verbos <em>ouvir</em> + <em>pedir</em> (presente, pretérito, imperfecto)',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario24 = {
    num: 24,
    name: 'Seminario XXIV',
    lang1: 'Francés',
    sub1: 'Le passé composé avec être',
    desc1: '- Uso de <em>être</em> como auxiliar para tiempos compuestos\n- Concordancia de género + número en participio pasado',
    lang2: 'Alemán',
    sub2: 'Werden',
    desc2: '- <em>Werden</em> como verbo pleno\n- Futuro con werden como verbo auxiliar',
    lang3: 'Japonés',
    sub3: '~ている',
    desc3: '- Uso de ~ている como presente progresivo\n- Expresión de estados continuos con ~ている',
    lang4: 'Italiano',
    sub4: 'Pronomi diretti con participio passato',
    desc4: '- Uso de pronombres directos con participio pasado',
    lang5: 'Ruso',
    sub5: 'О ком? О чём?',
    desc5: '- Uso de <em>o</em> en caso hablativo/preposicional\n- Pronombres personales en preposicional/hablativo\n- Verbos con hablativo/preposicional',
    lang6: 'Chino',
    sub6: 'Comparación de sustantivos',
    desc6: '- Comparación de sustantivos (superioridad, igualdad, inferioridad)',
    lang7: 'Portugués',
    sub7: 'Sentir',
    desc7: '- Conjugación de <em>sentir</em> y verbos con cambio de vocal',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario25 = {
    num: 25,
    name: 'Seminario XXV',
    lang1: 'Francés',
    sub1: 'C\'est, il est',
    desc1: '- Uso de <em>c\'est / il est</em> para identificar, presentar y describir objetos\n- Formación de preguntas con <em>c\'est</em>',
    lang2: 'Alemán',
    sub2: 'El futuro',
    desc2: '- Futuro con verbo auxiliar werden <em>(Futur I)</em>',
    lang3: 'Japonés',
    sub3: 'どこに住んでいますか?',
    desc3: '- Uso del verbo <em>vivir</em> 住む (すむ)',
    lang4: 'Italiano',
    sub4: 'Forme dell\'imperfetto',
    desc4: '- Imperfecto indicativo de verbos regulares + irregulares más frecuentes',
    lang5: 'Ruso',
    sub5: 'Кто кого любить?',
    desc5: '- Declinación de adjetivos (posesivos, calificativos, demostrativos) en acusativo',
    lang6: 'Chino',
    sub6: 'Comparación de acciones',
    desc6: '- Comparación de acciones con 得 <em>(de)</em>',
    lang7: 'Portugués',
    sub7: 'Dormir, subir',
    desc7: '- Conjugación de verbos dormir + subir (presente, pretérito, imperfecto) y verbos con cambio de vocal',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario26 = {
    num: 26,
    name: 'Seminario XXVI',
    lang1: 'Francés',
    sub1: 'L\'impératif',
    desc1: '- Formación y uso del modo imperativo \n- Pronombres complemento con imperativo',
    lang2: 'Alemán',
    sub2: 'Preposiciones temporales ',
    desc2: '- Um, am, im, in, von, bis, ab como preposiciones temporales \n- Los días de la semana + estaciones del año',
    lang3: 'Japonés',
    sub3: '髪が長いです',
    desc3: '- Describir cualidades físicas ',
    lang4: 'Italiano',
    sub4: 'Uso dell\'imperfetto',
    desc4: '- Usos del tiempo imperfecto indicativo',
    lang5: 'Ruso',
    sub5: 'О чём пишут в газетах?',
    desc5: '- Declinaciones en hablativo/preposicional \n- Adverbios de tiempo ',
    lang6: 'Chino',
    sub6: '今天会下雨吗？ (jīntiān huì xià yǔ ma?)',
    desc6: '- Verbo modal 会 (huì) (<em>poder</em> + futuro) \n- Adverbio 太 (tài)',
    lang7: 'Portugués',
    sub7: 'Acabar de',
    desc7: '- Pasado reciente con <em>acabar de</em> \n- Verbos <em>precisar, ter de, ter</em> que para expresar necesidad ',
    lang8: '',
    sub8: '',
    desc8: ''
}




const seminario27 = {
    num: 27,
    name: 'Seminario XXVII',
    lang1: 'Francés',
    sub1: 'L\'adverbe',
    desc1: '- Adverbios simples ',
    lang2: 'Alemán',
    sub2: 'Preposiciones temporales ',
    desc2: '- Um, am, im, in, von, bis, ab como preposiciones temporales \n- Los días de la semana + estaciones del año',
    lang3: 'Japonés',
    sub3: 'Forma ～て para unir oraciones',
    desc3: '- Uso de  ～て como conjunción y (adjetivos + sustantivos)',
    lang4: 'Italiano',
    sub4: 'Passato prossimo o imperfetto di alcuni verbi',
    desc4: '- Verbos con cambio de significado en imperfecto',
    lang5: 'Ruso',
    sub5: 'Кто куда идёт?',
    desc5: '- Verbos de desplazamiento (ir a pie, en transporte) \n- Uso de <em>на</em> como indicador de medio de transporte ',
    lang6: 'Chino',
    sub6: '你在做什么呢？ (Nǐ zài zuò shénme ne?)',
    desc6: '- Acciones en progreso con 在... 呢 \n- Dictado de números de teléfono \n- Partícula modal 呢',
    lang7: 'Portugués',
    sub7: 'Diminutivo',
    desc7: '- Formación de diminutivo (sustantivos + adjetivos)',
    lang8: '',
    sub8: '',
    desc8: ''
}


const seminario28 = {
    num: 28,
    name: 'Seminario XXVIII',
    lang1: 'Francés',
    sub1: 'Les adverbes en - ment ',
    desc1: '- Formación de adverbios a partir de adjetivos \n- Concordancia femenina de adjetivos ',
    lang2: 'Alemán',
    sub2: 'Nach, zu',
    desc2: '- Preposiciones <em>nach</em> y zu para indicar dirección ',
    lang3: 'Japonés',
    sub3: 'Raíz verbal + に行く',
    desc3: '- Oraciones con propósito de desplazamiento <em>(ir a X para hacer Y)</em>',
    lang4: 'Italiano',
    sub4: 'Pronomi indiretti con pronomi diretti',
    desc4: '- Formación y uso de pronombres combinados',
    lang5: 'Ruso',
    sub5: 'Сколько времени… ?',
    desc5: '- Puntos y periodos de tiempo \n- Uso de <em>весь, всё, всю</em>',
    lang6: 'Chino',
    sub6: '因为l 所以 (yīnwèi… suǒyǐ)',
    desc6: '- Preguntar por y expresar causas <em>(¿por qué? porque...)</em>',
    lang7: 'Portugués',
    sub7: 'Verbo dever',
    desc7: '- Verbo <em>dever</em> para expresar suposición y obligación',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario29 = {
    num: 29,
    name: 'Seminario XXIX',
    lang1: 'Francés',
    sub1: 'Les relatifs ',
    desc1: '- Pronombres relativos simples <em>(qui, que, où)</em>',
    lang2: 'Alemán',
    sub2: 'Wächselpräpositionen ',
    desc2: '- Preposiciones con acusativo/dativo <em>(auf, über, durch, an...)</em>',
    lang3: 'Japonés',
    sub3: 'Forma corta',
    desc3: '- Uso y formación de formas cortas (verbos, adjetivos, sustantivos) ',
    lang4: 'Italiano',
    sub4: 'Pronomi rifl essivi con pronomi diretti',
    desc4: '- Pronombres combinados <em>(reflexivo + directo)</em>',
    lang5: 'Ruso',
    sub5: 'В каком городе вы живёте?',
    desc5: '- Declinación de adjetivos en caso preposicional \n- Declinación de adjetivos posesivos en caso preposicional',
    lang6: 'Chino',
    sub6: '昨天上午你去哪了？ (Zuótiān shàngwǔ nǐ qù nǎle?)',
    desc6: '- 了 para indicar un hecho o una realización \n- El adverbio 都 + partícula modal 啊 \n- Verbo modal 能',
    lang7: 'Portugués',
    sub7: 'Pronomes idefinidos',
    desc7: '- Pronombres indefinidos <em>(algum, alguns, nenhum...)</em>',
    lang8: '',
    sub8: '',
    desc8: ''
}

const seminario30 = {
    num: 30,
    name: 'Seminario XXX',
    lang1: 'Francés',
    sub1: 'L\'imparfait',
    desc1: '- Uso y formación del imperfecto indicativo ',
    lang2: 'Alemán',
    sub2: 'Wächselpräpositionen',
    desc2: '- Preposiciones y verbos con acusativo/dativo <em>(auf, über, durch, an...)</em>',
    lang3: 'Japonés',
    sub3: 'Discurso informal - Citaciones',
    desc3: '- Uso de lenguaje informal \n- Discurso indirecto (citaciones) con と言っていました / と思います ',
    lang4: 'Italiano',
    sub4: 'Condizionale semplice',
    desc4: '- Uso y formación del condicional simple de verbos regulares + irreg. ',
    lang5: 'Ruso',
    sub5: 'Вы давно были в Германии?',
    desc5: '- "Hace X tiempo" con <em>назад + долго</em>\n- Verbo de desplazamiento ходить',
    lang6: 'Chino',
    sub6: '这块手表是你的吗？ (zhè kuài shǒubiǎo shì nǐ de ma?) ',
    desc6: '- Frases con 的 como pronombre posesivo. \n- Clasificador 一下 \n- Verbo modal 真 ',
    lang7: 'Portugués',
    sub7: 'Verbo sair',
    desc7: '- Conjugación de verbo <em>sair</em> + verbos acabados en <em>-air</em>',
    lang8: '',
    sub8: '',
    desc8: ''
}


const seminario31 = {
    num: 31,
    name: 'Seminario XXXI',
    lang1: 'Francés',
    sub1: 'L\'imparfait et le passé composé',
    desc1: '- Distinción entre pasado compuesto e imperfecto',
    lang2: 'Alemán',
    sub2: 'Wohin oder wo?',
    desc2: '- Verbos con acusativo/dativo \n- Verbos <em>stellen, legen, setzen, hängen</em> + preposiciones',
    lang3: 'Japonés',
    sub3: 'ないでください',
    desc3: '- <em>"Por favor, no haga X"</em>',
    lang4: 'Italiano',
    sub4: 'Condizionale composto',
    desc4: '- Uso y formación del condicional compuesto',
    lang5: 'Ruso',
    sub5: 'Читаеть или прочитал?',
    desc5: '- Verbos perfectivos vs. imperfectivos',
    lang6: 'Chino',
    sub6: '这本书是我写的 (zhè běn shū shì wǒ xiě de) ',
    desc6: '- Estructura 是...的 para enfatizar el agente de una acción \n- El adverbio 已经 <em>("ya")</em> \n- <em>Oraciones con pronombres relativos</em>',
    lang7: 'Portugués',
    sub7: 'Mais- que- perfeito composto',
    desc7: '- Uso y formación del pasado pluscuamperfecto \n- Participios pasados irregulares',
    lang8: 'Inglés',
    sub8: 'To be + to have',
    desc8: '- Conjugación de verbos to be + to have \n- Oraciones afirmativas, negativas e interrogativas',
}
const seminario32 = {
    num: 32,
    name: 'Seminario XXXII',
    lang1: 'Francés',
    sub1: 'Le plus- que- parfait ',
    desc1: '- Uso y formación del pasado pluscuamperfecto',
    lang2: 'Alemán',
    sub2: 'Weil- Sätze',
    desc2: '- Preguntar y responder por la causa (warum? weil...) \n- Oraciones secundarias con <em>weil</em>',
    lang3: 'Japonés',
    sub3: 'Verbo + のが好きです',
    desc3: '- "Me gusta hacer X" \n- Expresar habilidades con 上手 / 下手',
    lang4: 'Italiano',
    sub4: 'Avverbi',
    desc4: '- Uso y formación de adverbios a partir de adjetivos <em>(-mente)</em>',
    lang5: 'Ruso',
    sub5: 'Aspecto perfectivo e imperfectivo',
    desc5: '- Clasificación de verbos perfectivos e imperfectivos ',
    lang6: 'Chino',
    sub6: '你现在在哪呢？ (Nǐ xiànzài zài nǎ ne?) ',
    desc6: '- Uso de adverbios 还 y 就  \n- Verbo 离 para expresar distancia espacio-temporal ',
    lang7: 'Portugués',
    sub7: 'Modo subjuntivo - presente',
    desc7: '- Formación y uso del subjuntivo presente',
    lang8: 'Inglés',
    sub8: 'Wh-questions',
    desc8: '- Pronombres interrogativos + demostrativos \n- Artículos definidos + indefinidos ',
}



const seminario33 = {
    num: 33,
    name: 'Seminario XXXIII',
    lang1: 'Francés',
    sub1: 'Le conditionnel ',
    desc1: '- Formación y uso del condicional presente + condicional pasado \n- Expresión de hipótesis y suposiciones ',
    lang2: 'Alemán',
    sub2: 'Nebensätze',
    desc2: '- Oraciones secundarias con <em>weil, wenn, als</em> ',
    lang3: 'Japonés',
    sub3: 'が - 何か y 何も',
    desc3: '- Uso de partícula が para marcar el agente\n- "Alguien" y "algo" con 何か y 何も ',
    lang4: 'Italiano',
    sub4: 'Particella <em>ci</em> con significato locativo',
    desc4: '- Uso de la partícula <em>ci</em> como complemento de lugar/dirección  ',
    lang5: 'Ruso',
    sub5: 'Должен, должна, должны',
    desc5: '- Expresar "deber" con <em>должен, должна, должны</em>',
    lang6: 'Chino',
    sub6: '我骑自行车来上班 (Wǒ qí zìxíngchē lái shàngbān) ',
    desc6: '- Verbos 坐, 骑, 划 como marcadores de medio de transporte. \n- Medios de transporte ',
    lang7: 'Portugués',
    sub7: 'Mais- que- perfeito (forma simples)',
    desc7: '- Uso y formación del pasado pluscuamperfecto simple + compuesto',
    lang8: 'Inglés',
    sub8: 'The numbers',
    desc8: '- Números ordinales + cardinales ',
}


const seminario34 = {
    num: 34,
    name: 'Seminario XXXIV',
    lang1: 'Francés',
    sub1: 'Le discours indirect au passé',
    desc1: '- Utilización y formación del discurso indirecto en pasado',
    lang2: 'Alemán',
    sub2: 'Vergangenheit: Perfekt',
    desc2: '- Uso y formación del pasado compuesto <em>(Perfekt)</em> \n- Formación de participio pasado <em>(Partizip II)</em> \n- Selección de verbo auxiliar <em>(haben/sein)</em> para el pasado compuesto ',
    lang3: 'Japonés',
    sub3: 'Formas cortas (pasado) ',
    desc3: '- Formas cortas en pasado (verbos, adjetivos, sustantivos) ',
    lang4: 'Italiano',
    sub4: 'Altri significati della particella ci',
    desc4: '- Usos de la partícula <em>ci</em> como complemento preposicional <em>(a,su,in,con)</em>',
    lang5: 'Ruso',
    sub5: 'Через или назад?',
    desc5: '- Uso de <em>через</em> y <em>назад</em> para ordenar eventos en el tiempo \n- <em>Начинать, начать, кончать, кончить</em> + infinitivo ',
    lang6: 'Chino',
    sub6: '我们下午去看电影，好吗？ (Wǒmen xiàwǔ qù kàn diànyǐng, hǎo ma?) ',
    desc6: '- Oraciones interrogativas con 好吗 \n- Oraciones "pivotal" con verbos causativos (请, 让, 叫) ',
    lang7: 'Portugués',
    sub7: 'Pronomes relativos invariáveis',
    desc7: '- Pronombres relativos <em>que, quem, onde</em>',
    lang8: 'Inglés',
    sub8: 'Do you like tacos?',
    desc8: '- Verbo like \n- Verbo auxiliar do en oraciones interrogativas, afirmativas y negativas ',
}


const seminario35 = {
    num: 35,
    name: 'Seminario XXXV',
    lang1: 'Francés',
    sub1: 'Le discours indirect au passé  ',
    desc1: '- Utilización y formación del discurso indirecto en pasado',
    lang2: 'Alemán',
    sub2: 'Vergangenheit: Perfekt ',
    desc2: '- Uso y pormación del pasado compuesto <em>(Perfekt)</em> \n- Formación de participio pasado <em>(Partizip II)</em> \n- Selección de verbo auxiliar <em>(haben/sein)</em> para el pasado compuesto',
    lang3: 'Japonés',
    sub3: 'Describir objetos con verbos y adjetivos ',
    desc3: '- <em>Formación</em> de "oraciones relativas" con verbos y adjetivos',
    lang4: 'Italiano',
    sub4: 'Particella locativa ci con i pronomi diretti ',
    desc4: '- Pronombres combinados con la partícula ci (objeto directo)',
    lang5: 'Ruso',
    sub5: 'Aspecto perfectivo e imperfetivo',
    desc5: '- Distinción y uso de verbos perfectivos e imperfectivos  ',
    lang6: 'Chino',
    sub6: '我们下午去看电影，好吗？ (Wǒmen xiàwǔ qù kàn diànyǐng, hǎo ma?) ',
    desc6: '- Oraciones interrogativas con 好吗 \n- Oraciones "pivotal" con verbos causativos (请, 让, 叫) ',
    lang7: 'Portugués',
    sub7: 'Pronomes relativos variáveis',
    desc7: '- Uso y formación de pronombres relativos variables <em>(o qual, os quais, cujo, cujos...)</em> ',
    lang8: 'Inglés',
    sub8: 'Object pronouns ',
    desc8: '- Adjetivación + plural de sustantivos \n- Pronombre objeto (O.D + O.I) + adjetivos posesivos ',
}


const seminario36 = {
    num: 36,
    name: 'Seminario XXXVI',
    lang1: 'Francés',
    sub1: 'Le futur antérieur ',
    desc1: '- Utilización y formación del futuro anterior \n- Resumen de tiempos del indicativo \n- Participios pasados irregulares',
    lang2: 'Alemán',
    sub2: 'Partizip II - Formas irregulares',
    desc2: '- Formas irregulares del participio pasado (Partizip II) ',
    lang3: 'Japonés',
    sub3: 'まだ~ていません',
    desc3: '- "Aún no..."',
    lang4: 'Italiano',
    sub4: 'Particella <em>ne</em> con significato partitivo ',
    desc4: '- Usos de la partícula ne como con significado partitivo ',
    lang5: 'Ruso',
    sub5: 'Кому ты пишешь письмо?',
    desc5: '- Declinación de sustantivos en el caso dativo \n- Verbos con complemento dativo \n- Resumen de tiempos y aspectos verbales',
    lang6: 'Chino',
    sub6: '您从几岁开始学习跳舞？ (Nín cóng jǐ suì kāishǐ xuéxí tiàowǔ?)',
    desc6: '- Uso de la preposición 从 (cóng) \n- Uso de 第 <em>(dì)</em> para indicar el orden <em>("números cardinales")</em> ',
    lang7: 'Portugués',
    sub7: 'Modo subjuntivo - presente ',
    desc7: '- Verbos de conjugación irregular en presente subjuntivo \n- Espresiones impersonales + conjunciones con subjuntivo ',
    lang8: 'Inglés',
    sub8: 'Whose book is that?',
    desc8: '- Pronombres posesivos + interrogativo <em>whose</em> \n- Adjetivos demostrativos <em>(this, that...)</em>',
}


const seminario37 = {
    num: 37,
    name: 'Seminario XXXVII',
    lang1: 'Francés',
    sub1: 'L\'hypothèse sur le futur',
    desc1: '- Expresión de eventos hipotéticos en el futuro \n- <em>Quand</em> y si para expresar hipótesis',
    lang2: 'Alemán',
    sub2: 'Trennbare Verben + Perfekt',
    desc2: '- Pasado compuesto <em>(Perfekt)</em> con verbos de prefijo separable',
    lang3: 'Japonés',
    sub3: 'La comparación ',
    desc3: '- Comparación de cualidades entre dos objetos ',
    lang4: 'Italiano',
    sub4: 'Futuro anteriore',
    desc4: '- Uso y formación del tiempo futuro anterior',
    lang5: 'Ruso',
    sub5: 'Что вам нравятся делать?',
    desc5: '- нравятся para indicar gustos ("me gusta") \n- Pronombres personales en caso dativo ',
    lang6: 'Chino',
    sub6: '从...到 (Cóng... dào) ',
    desc6: '- 从到 para indicar distancias espaciotemporales ',
    lang7: 'Portugués',
    sub7: 'Advérbios em: - mente',
    desc7: '- Uso y formación de adverbios a partir de adjetivos <em>(-mente)</em>',
    lang8: 'Inglés',
    sub8: 'Presente progresivo',
    desc8: '- Uso y formación del presente + pasado progresivo \n- Uso y formación de gerundio <em>(forma -ing)</em>',
}


const seminario38 = {
    num: 38,
    name: 'Seminario XXXVIII',
    lang1: 'Francés',
    sub1: 'L\'hypothèse sur le présent',
    desc1: '- Expresión de eventos hipotéticos en el presente \n- Condicional presente',
    lang2: 'Alemán',
    sub2: 'Trennbare Verben + Perfekt',
    desc2: '- Pasado compuesto <em>(Perfekt)</em> con verbos de prefijo separable',
    lang3: 'Japonés',
    sub3: 'La comparación entre tres o más objetos',
    desc3: '- Comparación de cualidades entre tres o más objetos objetos ',
    lang4: 'Italiano',
    sub4: 'Comparativo di maggioranza e minoranza ',
    desc4: '- Formación y uso del comparativo de superioridad / inferioridad ',
    lang5: 'Ruso',
    sub5: 'Сколько вам лет? ',
    desc5: '- Declinación de adjetivos en el caso dativo \n- <em>¿Cuántos años tienes?</em> ',
    lang6: 'Chino',
    sub6: '不要看电视了 (Bùyào kàn diànshìle)  ',
    desc6: '- Oraciones imperativas con 不要...了 别...了 <em>("no hagas")</em> \n- Uso de la preposición 对 <em>(duì)</em> con significado <em>"para"</em>',
    lang7: 'Portugués',
    sub7: 'Modo subjuntivo - imperfeito',
    desc7: '- Utilización y formación del subjuntivo imperfecto',
    lang8: 'Inglés',
    sub8: 'El futuro ',
    desc8: '- Uso y formación de futuro con <em>going to</em> + <em>will</em> \n - Oraciones afirmativas, negativas e interrogativas   ',
}

const seminario39 = {
    num: 39,
    name: 'Seminario XXXIX',
    lang1: 'Francés',
    sub1: 'L\'hypothèse sur le passé',
    desc1: '- Expresión de eventos hipotéticos en el pasado',
    lang2: 'Alemán',
    sub2: 'Trennbare Verben + Perfekt ',
    desc2: '- Pasado compuesto <em>(Perfekt)</em> con verbos de prefijo separable \n- Cambio de significado con partícula <em>zu</em>',
    lang3: 'Japonés',
    sub3: 'Adjetivo/sustantivo + の ',
    desc3: '- Omisión de sustantivo/adjetivo con la partícula の ',
    lang4: 'Italiano',
    sub4: 'Pronomi relativi che, cui, chi',
    desc4: '- Pronombres relativos invariables <em>che, cui, chi</em>',
    lang5: 'Ruso',
    sub5: 'Мне нужно купить овощи',
    desc5: '- Uso de <em>нужно / надо</em> para indicar necesidad <em>("necesito hacer X")</em> \n- Verbos <em>мочь / уметь</em> para expresar capacidad <em>("poder")</em> \n- Uso, formación y declinación de números ordinales  ',
    lang6: 'Chino',
    sub6: '昨天和你一起唱歌的人是谁？ (Zuótiān hé nǐ yīqǐ chànggē de rén shì shéi?) ',
    desc6: '- Frases verbales usadas como modificador <em>("relativos")</em> \n- Uso de 一点儿 / 多/ 得多 para matizar cantidad <em>("un poco, algo")</em>',
    lang7: 'Portugués',
    sub7: 'Expressões com o verbo dar',
    desc7: '- Expresiones comunes con el verbo <em>dar</em> \n- Futuro do pretérito <em>(condicional)</em> indicativo',
    lang8: 'Inglés',
    sub8: 'Comparativo y superlativo',
    desc8: '- Adjetivos en grado comparativo + superlativo ',
}


const seminario40 = {
    num: 40,
    name: 'Seminario XL',
    lang1: 'Francés',
    sub1: 'L\'hypothèse sur le passé ',
    desc1: '- Expresión de eventos hipotéticos en el pasado',
    lang2: 'Alemán',
    sub2: 'Trennbare Verben + Perfekt',
    desc2: '- Pasado compuesto <em>(Perfekt)</em> con verbos de prefijo separable \n- Cambio de significado con partícula <em>zu</em>',
    lang3: 'Japonés',
    sub3: '~つもりだ ',
    desc3: '- Comunicar planes con ~つもりだ <em>("pretendo / planeo")</em>',
    lang4: 'Italiano',
    sub4: 'Pronombre relativo il quale',
    desc4: '- Uso y formación de pronombres relativos variables <em>(el cual, la cual...)</em>',
    lang5: 'Ruso',
    sub5: 'Какая сегодня погода? ',
    desc5: '- El clima \n- Declinación de adjetivos en preposicional singular',
    lang6: 'Chino',
    sub6: '次 <em>(cì)</em>',
    desc6: '- Resumen tiempos verbales \n- Uso de 次 <em>(cì)</em> para contar veces <em>("vez")</em>',
    lang7: 'Portugués',
    sub7: 'Orações condicionais',
    desc7: '- Utilización y formación de oraciones condicionales (hipótesis)',
    lang8: 'Inglés',
    sub8: 'La comparación ',
    desc8: '- Comparación de igualdad y cantidad con <em>as</em> \n- Uso de <em>one</em> como pronombre indefinido ',
}

const seminario41 = {
    num: 41,
    name: 'Seminario XLI',
    lang1: 'Francés',
    sub1: 'Le passé composé, le pronom et l\'adverbe',
    desc1: '- Concordancia del participio pasado con el verbo auxiliar <em>avoir</em> \n- Posicionamiento del pronombre complemento en pasado',
    lang2: 'Alemán',
    sub2: 'Finalsätze - Um... zu / damit... ',
    desc2: '- Oraciones finales con <em>um... zu / damit (para, para que...)</em>',
    lang3: 'Japonés',
    sub3: 'Adjetivo + なる  ',
    desc3: '- Verbo なる para indicar cambio de estado <em>("volverse, convertirse")</em> ',
    lang4: 'Italiano',
    sub4: 'Trapassato prossimo',
    desc4: '- Formación y uso del pluscuamperfecto <em>(trapassato prossimo)</em>',
    lang5: 'Ruso',
    sub5: 'Что вы делаете летом?',
    desc5: '- Verbo <em>советовать (recomendar)</em> \n- Oraciones condicionales con если ',
    lang6: 'Chino',
    sub6: '着 (zhe)',
    desc6: 'Uso de 着 para indicar la continuación de un estado <em>("abierto, cerrado...")</em>',
    lang7: 'Portugués',
    sub7: 'Verbos irregulares ',
    desc7: '- Conjugacion presente de verbos en <em>-ear + -uir + iar</em>',
    lang8: 'Inglés',
    sub8: 'Pasado simple',
    desc8: '- Formación del pasado simple en verbos regulares <em>(-d; -ed)</em>  \n- Formas irregulares del pasado simple \n- Pronunciación de verbos regulares en inglés ',
}

const seminario42 = {
    num: 42,
    name: 'Seminario XLII',
    lang1: 'Francés',
    sub1: 'Le passé composé, le pronom et l\'adverbe',
    desc1: '- Concordancia del participio pasado con el verbo auxiliar <em>avoir</em> \n- Posicionamiento del adverbio en oraciones pasadas',
    lang2: 'Alemán',
    sub2: 'Relativpronomen',
    desc2: '- Pronombres relativos en nominativo ',
    lang3: 'Japonés',
    sub3: 'どこかに / どこにも  ',
    desc3: '- <em>"Algo", "nada", "algún lugar"</em> con どこかに/どこにも',
    lang4: 'Italiano',
    sub4: 'Imperativo',
    desc4: '- Imperativo regular + irregular',
    lang5: 'Ruso',
    sub5: 'С кем ты был на стадионе?',
    desc5: '- Declinación de adjetivos posesivos en caso instrumental \n- Declinación de adjetivos posesivos en caso preposicional  ',
    lang6: 'Chino',
    sub6: '也/都... 不 (Yě / dōu... bù)  ',
    desc6: '- Negación absoluta con 也/都... 不 <em>("para nada")</em> ',
    lang7: 'Portugués',
    sub7: 'Seguir, valer, caber, medir, perder ',
    desc7: '- Conjugación de verbos con cambio de vocal en la raíz ',
    lang8: 'Inglés',
    sub8: 'Present perfect',
    desc8: '- Uso y formación de present perfect \n- Present perfect + for/since; ever/never; yet/already/just  ',
}

const seminario43 = {
    num: 43,
    name: 'Seminario XLIII',
    lang1: 'Francés',
    sub1: 'Le passé composé, la négation et l\'interrogation ',
    desc1: '- Posición de la negación en frases en pasado \n- Estructuras interrogativas',
    lang2: 'Alemán',
    sub2: 'Relativpronomen',
    desc2: '- Pronombres relativos en acusativo + dativo',
    lang3: 'Japonés',
    sub3: '自転車で行きます  ',
    desc3: '- Partícula で para indicar medio de transporte \n- Preposiciones から/まで para indicar distancia \n- Describir periodos de tiempo (duración)  ',
    lang4: 'Italiano',
    sub4: 'Forme del congiuntivo presente',
    desc4: '- Formación y uso del subjuntivo presente <em>(regular + irregular)</em>',
    lang5: 'Ruso',
    sub5: 'Который, которая, которое - Свой',
    desc5: '- Pronombres relativos (nominativo) \n- Pronombres posesivos reflexivos <em>cвой ("su propio...")</em>',
    lang6: 'Chino',
    sub6: '就 (jiù) ',
    desc6: '- Uso de 就 para indicar una secuencia de eventos (acciones consecutivas) ',
    lang7: 'Portugués',
    sub7: 'Preposições simples ',
    desc7: '- Preposiciones simples + locuciones prepositivas',
    lang8: 'Inglés',
    sub8: 'Adverbs of manner ',
    desc8: '- Uso y formación de adverbios a partir de adjetivos (-ly) \n- Formas irregulares de adverbios ',
}

const seminario44 = {
    num: 44,
    name: 'Seminario XLIV',
    lang1: 'Francés',
    sub1: 'Le passé composé, la négation et l\'interrogation  ',
    desc1: '- Posición de la negación en frases en pasado \n- Estructuras interrogativas',
    lang2: 'Alemán',
    sub2: 'Relativsätze',
    desc2: '- Oraciones relativas + pronombres relativos',
    lang3: 'Japonés',
    sub3: 'たい ',
    desc3: '- Forma たい para expresar esperanza o aspiración <em>("quiero hacer X")</em>',
    lang4: 'Italiano',
    sub4: 'Uso del congiuntivo ',
    desc4: '- Usos + preposiciones del conjuntivo',
    lang5: 'Ruso',
    sub5: 'Это кошелёк моей закомой ',
    desc5: '- Verbos <em>слышать / услышать</em> \n- Declinación de adjetivos posesivos en caso genitivo \n- Declinación de adjetivos en caso genitivo ',
    lang6: 'Chino',
    sub6: 'Complementos simples de dirección',
    desc6: '- 来 / 去 <em>(lái / qù)</em> como complementos simples de dirección ',
    lang7: 'Portugués',
    sub7: 'Discurso indireto  ',
    desc7: '- Uso y formación del discurso indirecto',
    lang8: 'Inglés',
    sub8: 'Modal verbs ',
    desc8: '- Verbos modales en presente ',
}

const seminario45 = {
    num: 45,
    name: 'Seminario XLV',
    lang1: 'Francés',
    sub1: 'Le gérondif  ',
    desc1: '- Uso y formación del gerundio \n- Uso y formación del participio presente',
    lang2: 'Alemán',
    sub2: 'El caso genitivo',
    desc2: '- Declinación de sustantivos en genitivo',
    lang3: 'Japonés',
    sub3: '~たり  ~たりする ',
    desc3: '- Enunciar diversas actividades con ~たり ~たりする  ',
    lang4: 'Italiano',
    sub4: 'Forme del congiuntivo imperfetto',
    desc4: '- Formación y uso del subjuntivo imperfecto (regular + irregular) \n- Formación y uso del <em>congiuntivo trapassato</em>',
    lang5: 'Ruso',
    sub5: 'Сколько? ',
    desc5: '- Pronombres relativos (nominativo, acusativo, dativo, genitivo, prep...) ',
    lang6: 'Chino',
    sub6: '还是 vs. 或者 (háishì vs. huòzhě)',
    desc6: '- Uso de 还是 或者 para presentar alternativas ("o") ',
    lang7: 'Portugués',
    sub7: 'Discurso indireto',
    desc7: '- Uso y formación del discurso indirecto',
    lang8: 'Inglés',
    sub8: 'Modal verbs ',
    desc8: '- Verbos modales en presente + pasado',
}

const seminario46 = {
    num: 46,
    name: 'Seminario XLVI',
    lang1: 'Francés',
    sub1: 'Les relatifs simples (2)  ',
    desc1: '- Pronombres relativos dont + <em>ce que, ce qui, ce dont</em> \n- Énfasis con <em>c\'est... qui/que + ce qui... c\'est</em>',
    lang2: 'Alemán',
    sub2: 'Wortbildung',
    desc2: '- Formación de palabras (aglutinamiento) ',
    lang3: 'Japonés',
    sub3: '~ ことがある  ',
    desc3: '- Hablar de experiencias pasadas con ~ ことがある <em>("he hecho X")</em>',
    lang4: 'Italiano',
    sub4: 'Periodo ipotetico',
    desc4: '- Expresar hipótesis con se',
    lang5: 'Ruso',
    sub5: 'Когда у вас день рождения?',
    desc5: '- Cumpleaños + meses del año + fechas \n- Contar objetos (declinación genitiva) \n- Adjetivos demostrativos \n- Declinación de sustantivos en genitivo plural ',
    lang6: 'Chino',
    sub6: '又 (yòu... Yòu)  ',
    desc6: '- 又 <em>para enlistar cualidades</em> \n- Acción acompañante con 着 ',
    lang7: 'Portugués',
    sub7: 'Voz passiva com ser',
    desc7: '- Uso y formación de voz pasiva con auxiliar <em>ser</em>',
    lang8: 'Inglés',
    sub8: 'Preposiciones de tiempo ',
    desc8: '- Uso de preposiciones de tiempo <em>(in, at, on, near...)</em> \n- La hora ',
}

const seminario47 = {
    num: 47,
    name: 'Seminario XLVII',
    lang1: 'Francés',
    sub1: 'Les rélatifs composés',
    desc1: '- Pronombres relativos compuestos <em>(lequel, laquelle, auquel, etc.)</em>',
    lang2: 'Alemán',
    sub2: 'Voz pasiva',
    desc2: '- Uso y formación de voz pasiva con <em>werden</em>',
    lang3: 'Japonés',
    sub3: '~んです',
    desc3: '- Uso y formación de oraciones explicativas con ~んです sustantivos + adjetivos <em>("Es que...")</em>',
    lang4: 'Italiano',
    sub4: 'Frma passiva (essere) ',
    desc4: '- Uso y formación de voz passiva con essere',
    lang5: 'Ruso',
    sub5: 'Кем ты работаешь? ',
    desc5: '- Verbos con complemento en caso instrumental (работать, быть, стать) <em>(Trabajo de...; me volveré...)</em> ',
    lang6: 'Chino',
    sub6: 'Cambios de estado con 了  ',
    desc6: '- Uso de la partícula 了 para indicar cambios de estado <em>("...ya...")</em>',
    lang7: 'Portugués',
    sub7: 'Discurso indireto (2)',
    desc7: '- Uso y formación del discurso indirecto ',
    lang8: 'Inglés',
    sub8: 'Preposiciones de lugar ',
    desc8: 'Uso de preposiciones de lugar <em>(in, at, on, near...)</em>',
}

const seminario48 = {
    num: 48,
    name: 'Seminario XLVIII',
    lang1: 'Francés',
    sub1: 'La négation (2) ',
    desc1: '- Negación con <em>ni...ni..., sans, ne...que (ni, sin, sino, nada más)</em>',
    lang2: 'Alemán',
    sub2: 'Oraciones condicionales',
    desc2: '- Uso y formación de oraciones condicionales con Konjunktiv II',
    lang3: 'Japonés',
    sub3: '~ すぎる ~ほうがいいです',
    desc3: '- Se demasiado con すぎる \n- Dar consejos con ほうがいいです <em>("deberías de..., es mejor si...")</em>',
    lang4: 'Italiano',
    sub4: 'Il si passivante',
    desc4: '- Uso y formación de voz pasiva con <em>si</em>',
    lang5: 'Ruso',
    sub5: 'Во время обеда мы смотрим телевизор',
    desc5: '- Uso de во время como sinónimo de <em>cuando/durante</em>',
    lang6: 'Chino',
    sub6: '多长 (duō cháng)',
    desc6: '- 多长 para preguntar por duración <em>("¿Cuánto tiempo?")</em>\n - "presente perfecto" con 了 <em>("he vivido...")</em>',
    lang7: 'Portugués',
    sub7: 'Verbos abundantes',
    desc7: '- Participios pasados con dos formas <em>(irregular + regular)</em>',
    lang8: 'Inglés',
    sub8: 'How long...? ',
    desc8: '- Uso de <em>how</em> para preguntar por cantidades \n- <em>For</em> y <em>since</em> como marcadores de tiempo ',
}

const seminario49 = {
    num: 49,
    name: 'Seminario XLIX',
    lang1: 'Francés',
    sub1: 'Le subjonctif ',
    desc1: '- Uso y formación del subjuntivo presente + verbos irregulares \n- <em>Il faut que</em> + subjuntivo',
    lang2: 'Alemán',
    sub2: 'Oraciones condicionales',
    desc2: '- Uso y formación de oraciones condicionales con Konjunktiv II ',
    lang3: 'Japonés',
    sub3: '~ なければいけません / ~なきゃいけません',
    desc3: '- Expresar obligaciones con ~ なければいけません/ ~なきゃいけません  <em>("tener que")</em>',
    lang4: 'Italiano',
    sub4: 'Passato remoto ',
    desc4: '- Uso y formación del pasado simple <em>(passato remoto)</em>',
    lang5: 'Ruso',
    sub5: 'Preposiciones',
    desc5: '- Preposiciones regidas por casos gramaticales ',
    lang6: 'Chino',
    sub6: 'Complemento potencial: 得 y 不 ',
    desc6: '- Expresar capacidades con 得 y 不 como complementos <em>("puedo...")</em>',
    lang7: 'Portugués',
    sub7: 'Voz passiva com se',
    desc7: '- Uso y formación de voz pasiva con <em>se</em>',
    lang8: 'Inglés',
    sub8: 'Oraciones imperativas',
    desc8: '- Imperativo afirmativo y negativo + <em>let\'</em>s',
}

const seminario50 = {
    num: 50,
    name: 'Seminario L',
    lang1: 'Francés',
    sub1: 'Les verbes subjectifs',
    desc1: '- Verbos con complemento en subjuntivo \n- Formación del subjuntivo irregular',
    lang2: 'Alemán',
    sub2: 'Präteritum',
    desc2: '- Uso y formación del pasado simple <em>(Präteritum)</em>',
    lang3: 'Japonés',
    sub3: '~でしょう ',
    desc3: '- Expresar posibilidades con でしょう <em>("puede que...")</em> \n- Pronóstico del clima',
    lang4: 'Italiano',
    sub4: 'Trapassato remoto',
    desc4: '- Uso y formación del trapassato remoto <em>("ebbi parlato...")</em> ',
    lang5: 'Ruso',
    sub5: 'Где были туристы?',
    desc5: '- Declinación de sustantivos plurales en caso preposicional \n- Declinación de adjetivos plurales en caso preposicional \n- Uso de <em>нужен, нужна, нужно, нужны</em> para indicar necesidad ',
    lang6: 'Chino',
    sub6: 'Adverbio (- mente): 地 ',
    desc6: '- Uso y formación de adverbios a partir de sustantivos con  地 <em>(de)</em> ',
    lang7: 'Portugués',
    sub7: 'Tempos compostos do subjuntivo',
    desc7: '- Conjugación de tiempos compuestos del modo subjuntivo <em>(presente, pasado, futuro)</em>',
    lang8: 'Inglés',
    sub8: 'Pronombres relativos',
    desc8: '- Uso y formación de pronombres relativos <em>(who, which...)</em> ',
}

const seminario51 = {
    num: 51,
    name: 'Seminario LI',
    lang1: 'Francés',
    sub1: 'Les constructions impersonelles ',
    desc1: '- Uso de construcciones impersonales <em>(il faut que, il va mieux que...)</em> \n - Uso y formación del subjuntivo presente + pasado',
    lang2: 'Alemán',
    sub2: 'Präteritum + Formas irregulares',
    desc2: '- Uso y formación del pasado simple <em>(Präteritum)</em> \n- Formas irregulares del pasado simple',
    lang3: 'Japonés',
    sub3: 'Verbos potenciales',
    desc3: '- Expresar capacidades usando la forma potencial <em>("puedo...")</em> \n- Uso y formación de formas potenciales ',
    lang4: 'Italiano',
    sub4: 'Concordanza dei tempi dell\'indicativo',
    desc4: '- Concordancia de los tiempos del indicativo en frases secundarias',
    lang5: 'Ruso',
    sub5: 'Императив + <em>встретить</em>, узнать',
    desc5: '- Uso y formación del modo imperativo \n- Verbos встретить + узнать ',
    lang6: 'Chino',
    sub6: 'Tan pronto como... ',
    desc6: '- Eventos seguidos con 一l 就 <em>(Yī… jiù)</em>',
    lang7: 'Portugués',
    sub7: 'Infinitivo pessoal ',
    desc7: '- Uso y formación del infinitivo personal ',
    lang8: 'Inglés',
    sub8: 'Discurso indirecto ',
    desc8: '- Discurso indirecto con y sin <em>that</em>',
}

const seminario52 = {
    num: 52,
    name: 'Seminario LII',
    lang1: 'Francés',
    sub1: 'Les constructions impersonelles',
    desc1: '- Uso de construcciones impersonales <em>(il faut que, il va mieux que...)</em> \n- Uso y formación del subjuntivo presente + pasado',
    lang2: 'Alemán',
    sub2: 'Fragewörter',
    desc2: '- Uso y formación de interrogativos a partir de preposiciones <em>(woher?)</em>',
    lang3: 'Japonés',
    sub3: '~し + そうです',
    desc3: '- Uso de し como sinónimo de porque \n- Describir cualidades aparentes con そうです ("parece..., se ve...) ',
    lang4: 'Italiano',
    sub4: 'Gerundio presente e gerundio passato ',
    desc4: '- Uso y formación del gerundio presente + pasado \n- <em>Pur + gerundio</em> para expresar consesiones <em>("aún siendo...")</em>',
    lang5: 'Ruso',
    sub5: 'Кого вы встречаете?',
    desc5: '- Declinación de sustantivos plurales en caso acusativo \n- Declinación de adjetivos plurales en caso acusativo ',
    lang6: 'Chino',
    sub6: '"Algo de" con 一些 ',
    desc6: '- Expresar cantidades no determinadas con 一些 <em>(yīxiē)</em>  ',
    lang7: 'Portugués',
    sub7: 'Infinitivo pessoal',
    desc7: '- Uso y formación del infinitivo personal ',
    lang8: 'Inglés',
    sub8: 'Second conditional ',
    desc8: '- Hablar de situaciones imaginarias y sus consecuencias',
}

const seminario53 = {
    num: 53,
    name: 'Seminario LIII',
    lang1: 'Francés',
    sub1: 'Le passif',
    desc1: '- Uso y formación de voz pasiva ',
    lang2: 'Alemán',
    sub2: 'Verbos + preposición ',
    desc2: '- Verbos con complemento preposicional (acusativo + dativo) ',
    lang3: 'Japonés',
    sub3: '~なら + ~てみる',
    desc3: '- Uso de なら en oraciones de contraste y limitación <em>(sólo, nada más...)</em> \n- Uso de てみる para expresar la idea de hacer tentativamente algo (voy a ver si...) ',
    lang4: 'Italiano',
    sub4: 'Infinito passato',
    desc4: '- Uso y formación del infinito passato <em>("Despues de haber visto...")</em>',
    lang5: 'Ruso',
    sub5: 'Grados del adjetivo - comparativo',
    desc5: '- Uso y formación del comparativo compuesto \n- Comparativo simple: formas regulares, irregulares + excepciones ',
    lang6: 'Chino',
    sub6: '先l 再 para indicar secuencia',
    desc6: '- Enlistar eventos con 先l 再 (primero..., luego...) ',
    lang7: 'Portugués',
    sub7: 'Verbos seguidos de preposição',
    desc7: '- Verbos + adjetivos con complemento preposicional',
    lang8: 'Inglés',
    sub8: 'Third conditional',
    desc8: '- Plantear situaciones hipotéticas en el pasado <em>("hubiera...")</em>',
}

const seminario54 = {
    num: 54,
    name: 'Seminario LIV',
    lang1: 'Francés',
    sub1: 'Les prépositions et les verbes',
    desc1: '- Verbos, adjetivos y sustantivos con complemento preposicional <em>(de, à)</em> ',
    lang2: 'Alemán',
    sub2: 'Verben mit Präpisitionen ',
    desc2: '- Verbos con complemento preposicional \n- Complemento con <em>da (dafür, daran, darüber, etc...)</em> ',
    lang3: 'Japonés',
    sub3: '一週間に三回',
    desc3: '- Describir la frecuencia con la que un evento se lleva a cabo <em>("una vez cada dos semanas")</em>',
    lang4: 'Italiano',
    sub4: 'Discorso indiretto con frase principale al presente',
    desc4: '- Discurso indirecto con frase principal en presente <em>(Juan dijo que es...)</em> ',
    lang5: 'Ruso',
    sub5: 'Grados del adjetivo - superlativo',
    desc5: '- Uso y formación del superlativo compuesto \n- Superlativo simple: formas regulares, irregulares + excepciones',
    lang6: 'Chino',
    sub6: 'Preguntas avanzadas con 吗 + <u>Adjetivos + 那么 / 这么</u>',
    desc6: '- Especificar oraciones interrogativas con 吗 \n- Enfatizar el grado de un adjetivo con 那么 / 这么 <em>("así de, tan...")</em>',
    lang7: 'Portugués',
    sub7: 'Verbos seguidos de preposição',
    desc7: '- Verbos + adjetivos con complemento preposicional',
    lang8: 'Inglés',
    sub8: 'Voz pasiva ',
    desc8: '- Uso y formación de voz pasiva',
}


const seminario55 = {
    num: 55,
    name: 'Seminario LV',
    lang1: 'Francés',
    sub1: 'Les relations logiques',
    desc1: '- Expresar causa y consecuencia con <em>parce que, comme, à cause de...</em>',
    lang2: 'Alemán',
    sub2: 'Satzstellung: Vorfeld',
    desc2: '- Oraciones con complemento en primera posición <em>("El martes comí pizza, pizza comí el martes, el martes pizza comí")</em>',
    lang3: 'Japonés',
    sub3: 'あげる / くれる / もらう ',
    desc3: '- あげる / くれる / もらう como <em>dar y recibir</em>',
    lang4: 'Italiano',
    sub4: 'Verbos seguidos de preposición',
    desc4: '- Verbos con complemento preposicional <em>(a, di, su)</em>',
    lang5: 'Ruso',
    sub5: 'Participio pasado',
    desc5: '- Uso y formación de participio pasado <em>("El dibujo <u>pintado</u> por el artista")</em> \n- Uso del caso instrumental para indicar el agente de la acción ',
    lang6: 'Chino',
    sub6: '"Con": 跟 + "Otra vez" con 又  ',
    desc6: '- Uso de la preposición <u>con</u> 跟 \n- Uso de 又 para expresar <em>otra vez / de nuevo</em>',
    lang7: 'Portugués',
    sub7: 'Tema libre',
    desc7: '',
    lang8: 'Inglés',
    sub8: 'Discurso indirecto ',
    desc8: '- Discruso indirecto con y sin <em>that</em>',
}



const seminario56 = {
    num: 56,
    name: 'Seminario LVI',
    lang1: 'Francés',
    sub1: 'Apporter, emporter, amener, emmener',
    desc1: '- "Llevar" y "traer" con apporter, emporter, amener, emmener',
    lang2: 'Alemán',
    sub2: 'Konjunktiv I',
    desc2: '- Uso y formación de Konjunktiv I (verbos irregulares + regulares) \n- Discurso indirecto con <em>Konjunktiv I</em>',
    lang3: 'Japonés',
    sub3: '欲しい ',
    desc3: '- 欲しい para expresar querer <em>(un objeto)</em> ',
    lang4: 'Italiano',
    sub4: 'Discorso indiretto con frase principale al passato',
    desc4: '- Discurso indirecto con frase principal en pasado <em>(Juan dijo que era...)</em>',
    lang5: 'Ruso',
    sub5: 'Voz pasiva ',
    desc5: '- Uso y formación de voz pasiva (verbos perfectivos + imperfectivos) ',
    lang6: 'Chino',
    sub6: '下去: complemento de resultado ',
    desc6: '- Expresar la continuación de una acción con 下去 <em>("Seguir haciendo...")</em> ',
    lang7: 'Portugués',
    sub7: 'Tema libre',
    desc7: '',
    lang8: 'Inglés',
    sub8: 'Adjetivos compuestos',
    desc8: '- Uso y formación de adjetivos compuestos',
}


const seminario57 = {
    num: 57,
    name: 'Seminario LVII',
    lang1: 'Francés',
    sub1: 'Singulier et pluriel des noms',
    desc1: '- Formación de sustantivos en plural',
    lang2: 'Alemán',
    sub2: 'Indirekte Rede: Übungen ',
    desc2: '- Discurso indirecto en pasado + presente + futuro + imperativo',
    lang3: 'Japonés',
    sub3: '<u>Número + も / Número + しか</u> + <u>Contadores</u>',
    desc3: '- Expresar cantidades con contadores \n- Uso de も + しか para expresar <em>"tantos como" / nada más</em>',
    lang4: 'Italiano',
    sub4: 'Discorso indiretto con i verbi chiedere e domandare ',
    desc4: '- Discurso indirecto de peticiones y frases imperativas',
    lang5: 'Ruso',
    sub5: 'Verbos semireflexivos',
    desc5: '- Uso de verbos semiureflexivos <em>(equivalente al <u>se</u> apasivante)</em>',
    lang6: 'Chino',
    sub6: 'El condicional ',
    desc6: '- Oraciones condicionales con 如果 ... 就 (rúguǒ... jiù) ',
    lang7: 'Portugués',
    sub7: 'Tema libre',
    desc7: '',
    lang8: 'Inglés',
    sub8: 'Phrasal verbs',
    desc8: '- Uso y formación de <em>phrasal verbs</em>',
}


const seminario58 = {
    num: 58,
    name: 'Seminario LVIII',
    lang1: 'Francés',
    sub1: 'Tout, toute, tous, toutes',
    desc1: '- Tout: adjetivo vs. pronombre <em>("todo")</em>',
    lang2: 'Alemán',
    sub2: 'Übung zur indirekten Fragen',
    desc2: '- Reporte indirecto de preguntas y peticiones <em>("preguntó que si...")</em>  ',
    lang3: 'Japonés',
    sub3: 'Oraciones para describir objetos',
    desc3: '- Uso de oraciones para describir objetos <em>(oraciones relativas)</em>',
    lang4: 'Italiano',
    sub4: 'Le congiunzioni coordinative',
    desc4: '- Conjunciones coordinativas <em>(e, o, ma, anche, neanche, dunque, etc...)</em>',
    lang5: 'Ruso',
    sub5: 'Adverbio verbal ',
    desc5: '- Uso y formación de adverbio verbal de verbos imperfectivos <em>(gerundio)</em>',
    lang6: 'Chino',
    sub6: '"Desde que" con 以来 + Expresar el propósito con 为了 ',
    desc6: '- Expresar lo que ha ocurrido un un punto determinado de tiempo con 以来 \n- Uso de 为了 para expresar propósito <em>("para")</em>',
    lang7: 'Portugués',
    sub7: 'Tema libre',
    desc7: '',
    lang8: 'Inglés',
    sub8: 'Verbos auxiliares en respuestas cortas + Question tags',
    desc8: '- Respuestas cortas con verbos auxiliares \n- Preguntas cortas <em>(question tags)</em> ',
}


const seminario59 = {
    num: 59,
    name: 'Seminario LIX',
    lang1: 'Francés',
    sub1: 'Quelqu\'un, n\'importe quoi, quiconque',
    desc1: '- Uso de pronombres y adjetivos de identidad (alguno, algún, etc.)',
    lang2: 'Alemán',
    sub2: '- Uso y formación del pasado compuesto en infinitivo con verbos modales',
    desc2: '- Verbos con complemento preposicional (acusativo + dativo) ',
    lang3: 'Japonés',
    sub3: '~てあげる / くれる / もらう ',
    desc3: '- Dar y recibir servicios (favores) con ~てあげる / くれる / もらう',
    lang4: 'Italiano',
    sub4: 'Il ne partitivo',
    desc4: '- Uso de la partícula ne con significado partitivo \n- Uso de artículos determinados para describir al todo',
    lang5: 'Ruso',
    sub5: 'Participio pasado ',
    desc5: '- Uso y formación de adverbio verbal de verbos perfectivos (gerundio)',
    lang6: 'Chino',
    sub6: '"Sólo" con 只 + "Sólo si" con 只有 ',
    desc6: '- Uso del adverbio sólo/únicamente con 只 (zhǐ) \n- Expresar acciones que dependen de otras con 只有 (zhǐyǒu)',
    lang7: 'Portugués',
    sub7: 'Tema libre',
    desc7: '',
    lang8: 'Inglés',
    sub8: '<u>Verbo + infinitivo</u> + <u>Verbo + obj. + infinitivo</u>',
    desc8: '- Uso y formación de oraciones infinitivas con y sin objeto',
}


const seminario60 = {
    num: 60,
    name: 'Seminario LX',
    lang1: 'Francés',
    sub1: '- Uso de pronombres y adjetivos de identidad (alguno, algún, etc.)',
    desc1: '- Uso y formación de voz pasiva ',
    lang2: 'Alemán',
    sub2: 'Modalverben mit Infinitiv: Perfekt',
    desc2: '- Uso y formación del pasado compuesto en infinitivo con verbos modales',
    lang3: 'Japonés',
    sub3: '~たら',
    desc3: '- Oraciones condicionales con ~たら',
    lang4: 'Italiano',
    sub4: 'Da & per + infinito',
    desc4: '- Uso de preposiciones finales da + per',
    lang5: 'Ruso',
    sub5: 'Adverbio verbal (св + нсв)',
    desc5: '- Uso de adverbios verbales de verbos св + нсв',
    lang6: 'Chino',
    sub6: '"Cualquier" con interrogativos',
    desc6: '- Uso y formación de pronombres indefinidos con 都 (dōu)',
    lang7: 'Portugués',
    sub7: 'Tema libre',
    desc7: '',
    lang8: 'Inglés',
    sub8: 'Like vs as + As: razón y tiempo',
    desc8: '- <em>Like y as</em> como equivalentes de <em>"como / tal como"</em> \n- Uso de as como sinónimo de because \n- Uso de as como marcador de tiempo',
}



const modulo1 = [seminario1, seminario2, seminario3, seminario4, seminario5, seminario6, seminario7, seminario8, seminario9, seminario10];
const modulo2 = [seminario11, seminario12, seminario13, seminario14, seminario15, seminario16, seminario17, seminario18, seminario19, seminario20, seminario21, seminario22, seminario23, seminario24, seminario25];
const modulo3 = [seminario26, seminario27, seminario28, seminario29, seminario30, seminario31, seminario32, seminario33, seminario34, seminario35, seminario36, seminario37, seminario38, seminario39, seminario40];
const modulo4 = [seminario41, seminario42, seminario43, seminario44, seminario45, seminario46, seminario47, seminario48, seminario49, seminario50];
const modulo5 = [seminario51, seminario52, seminario53, seminario54, seminario55, seminario56, seminario57, seminario58, seminario59, seminario60];

const fullTemary = [...modulo1, ...modulo2, ...modulo3, ...modulo4, ...modulo5];

const downloads = {
    modulo1: 'https://firebasestorage.googleapis.com/v0/b/academia-de-lenguas-f6f35.appspot.com/o/temario%2FTemario%20-%20Iidiomas%20-%20Mo%CC%81dulo%20I.pdf?alt=media&token=fb496675-7c68-4a2a-8ea7-439df204e6a5',
    modulo2: 'https://firebasestorage.googleapis.com/v0/b/academia-de-lenguas-f6f35.appspot.com/o/temario%2FTemario%20-%20Idiomas%20-%20Mo%CC%81dulo%20II.pdf?alt=media&token=6552932a-35ac-45b0-b4b3-2b9de8d28bcb',
    modulo3: 'https://firebasestorage.googleapis.com/v0/b/academia-de-lenguas-f6f35.appspot.com/o/temario%2FTemario%20-%20Idiomas%20-%20Mo%CC%81dulo%20III.pdf?alt=media&token=0daaca42-a233-41ec-ac59-a6927d1eefe0',
    modulo4: 'https://firebasestorage.googleapis.com/v0/b/academia-de-lenguas-f6f35.appspot.com/o/temario%2FTemario%20-%20Idiomas%20-%20Mo%CC%81dulo%20IV.pdf?alt=media&token=057543b0-614b-424b-90b9-e9739609f2d9',
    modulo5: 'https://firebasestorage.googleapis.com/v0/b/academia-de-lenguas-f6f35.appspot.com/o/temario%2FTemario%20-%20Idiomas%20-%20Mo%CC%81dulo%20V.pdf?alt=media&token=63c0ebd1-4571-4edd-9df9-a80905b0c1f7'
}

export { modulo1, modulo2, modulo3, modulo4, modulo5, downloads, fullTemary }
