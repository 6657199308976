import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import "./FilterGenerations.scss"
type Props = {
	filterEs: any;
	handleSelect: (args: any) => void;
	eyeColor: any;
	EyeIcon: any;
	setModalShow?: (modalShow: boolean) => void;
};

const FilterGenerations = ({ filterEs, handleSelect, eyeColor, EyeIcon, setModalShow }: Props) => {

	return (
		<div className="right-aligned-elements">
			<DropdownButton title={filterEs} className="dropdown-container" id="gen-filter" onSelect={handleSelect}>
				<Dropdown.Item eventKey={"active"}>Activas</Dropdown.Item>
				<Dropdown.Item eventKey={"all"}>Todas</Dropdown.Item>
				<Dropdown.Item eventKey={"past"}>Pasadas</Dropdown.Item>
				<Dropdown.Item eventKey={"future"}>Próximas</Dropdown.Item>
			</DropdownButton>
			<img className={eyeColor} src={EyeIcon} alt="eye-icon" />
			{setModalShow && <button onClick={() => setModalShow(true)}>Nueva generación</button>}
		</div>
	);
};
export default FilterGenerations;
