import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import speiLogo from "./images/spei.png";
import { useHistory } from "react-router";
import "./SpeiRef.scss";

function SpeiRef() {
	const history = useHistory();
	const location = useLocation<any>();
	if (location.state == undefined) {
		history.push({
			pathname: "/",
		});
	}
	const client = location.state.client;
	const course = location.state.course;
	const charge = location.state.charge;
	var date = new Date(charge.payment_method.expires_at * 1000);

	var month;

	switch (date.getMonth()) {
		case 0:
			month = "Enero";
			break;
		case 1:
			month = "Febrero";
			break;
		case 2:
			month = "Marzo";
			break;
		case 3:
			month = "Abril";
			break;
		case 4:
			month = "Mayo";
			break;
		case 5:
			month = "Junio";
			break;
		case 6:
			month = "Julio";
			break;
		case 7:
			month = "Agosto";
			break;
		case 8:
			month = "Septiembre";
			break;
		case 9:
			month = "Octubre";
			break;
		case 10:
			month = "Noviembre";
			break;
		case 11:
			month = "Diciembre";
			break;
	}

	return (
		<div className="container-speiref">
			<div>
				<h1>Referencia para pago por medio de SPEI</h1>
			</div>
			<div className="card-speiref">
				<div>
					<img src={speiLogo} alt="" />
				</div>
				<div>
					<h4>Comprador:</h4>
					<p>{client.name}</p>
				</div>
				<div>
					<h4>Nombre de la empresa:</h4>
					<p>Academia de Lenguas Internacionales</p>
				</div>
				<div>
					<h4>Monto a pagar:</h4>
					<h2>{course.inversion}</h2>
				</div>
				<div>
					<h4>Referencia de pago: (CLABE)</h4>
					<div className="speiref-num-container">
						<h2>{charge.payment_method.clabe}</h2>
					</div>
				</div>
				<div>
					<h4>Fecha de expiración:</h4>
					<p>
						{date.getDate()} de {month} de {date.getFullYear()}
					</p>
				</div>
				<div>
					<h4>Número de orden:</h4>
					<p>{charge.order_id}</p>
				</div>
				<br />
				<div style={{ wordBreak: "break-word" }}>
					<p className="instrucciones-text" style={{ fontSize: "3rem" }}>
						Instrucciones:
					</p>
					<p style={{ marginTop: "10px" }}>1. Acceder a tu portal de banca en línea.</p>
					<p>2. Registrar la CLABE de este recibo para hacer transferencia. El banco receptor es STP.</p>
					<p>3. Hacer la transferencia en línea por la cantidad exacta marcada en este recibo, o la transferencia será rechazada.</p>
					<p>
						4. Para confirmar tu pago, tu banca en línea te proporcionará un recibo digital. Verifica si se ha hecho correctamente. Guarda tu
						comprobante de transferencia.
					</p>
					<p>
						5. Opción A: Envía tu comprobante de inscripción por WhatsApp al teléfono <a href="https://wa.me/525623925453">+52 56 2392 5453</a>, al{" "}
						<a href="https://wa.me/525551848431">+52 55 5184 8431</a> o a la dirección de correo electrónico{" "}
						<a className="contact-email-text">contacto@academiadelenguasinternacionales.mx</a> para continuar con el proceso de registro.
						<br />
						<br />
						opcion B: Actualiza tu pago en el siguiente link al tener una cuenta creada:{" "}
						<Link to="/micuenta/actualizar-pagos" style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}>
							https://academiadelenguasinternacionales.mx/micuenta/actualizar-pagos
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
}

export default SpeiRef;
