import React from "react";
import "./BurgerBtn.scss";
import { ReactComponent as BurgerLine } from "@assets/icons/burger-line.svg";

export const BurgerBtn = React.forwardRef(({ onClick, openNav }: any, ref: any) => {
	return (
		<div className={`burger-btn-main-container ${openNav ? "active" : ""}`} role="button" onClick={onClick} ref={ref}>
			<BurgerLine className="top" />
			<BurgerLine className="middle" />
			<BurgerLine className="bottom" />
		</div>
	);
});
