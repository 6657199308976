import "./InstitutionDescription.scss";

export const InstitutionDescription = () => {
	return (
		<div className="institution-description-main-container">
			<div className="institution-description">
				<div className="separator"></div>
				Somos la <span>única institución en el mundo</span> que imparte un <span>programa de alto rendimiento</span> en el
				aprendizaje de <span>ocho idiomas simultáneos.</span>
			</div>
		</div>
	);
};
