import { useState, useEffect } from "react";
import { getActiveGenerations } from "@services/GenerationService";
import { GenType, TGeneration } from "interfaces/Generations";

function useActiveGenerations() {
	const [activeGenerations, setActiveGenerations] = useState<Array<GenType>>([]);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchActiveGenerations = async () => {
		try {
			setLoading(true);
			const response = await getActiveGenerations();
			const modes = response.map((gen) => gen.type);
			setActiveGenerations(modes);
			setLoading(false);
		} catch (error: any) {
			console.error(["ERROR_FETCHING_ACTIVE_GENERATIONS"], error);
			setError(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchActiveGenerations();
	}, []);

	return { activeGenerations, loading, error };
}

export default useActiveGenerations;
