import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import "./FormExperienceComponent.scss";

export function FormExperienceComponent(props) {
	const [hadDifficulties, setHadDifficulties] = useState(false);
	
	return (
		<Form id="my-form" className="experience-form-container" noValidate validated={props.validated} onSubmit={props.handleSubmit}>
			<Row style={props.step === 1 ? { display: "flex" } : { display: "none" }}>
				<Col>
					{props.user === null && (
						<Row>
							<Form.Group as={Col} controlId="validationCustom01">
								<Form.Label>
									<a className="obligatory">*</a>Correo:
								</Form.Label>
								<Form.Control required type="email" name="email" placeholder={props.read ? '' : "Correo"} />
								<Form.Control.Feedback type="invalid">Ingresa un correo Válido.</Form.Control.Feedback>
							</Form.Group>
						</Row>
					)}
					<Row>
						<Form.Group as={Col} controlId="validationCustom01">
							<Form.Label>
								<a></a>¿Tuviste dificultades para acceder a las grabaciones?
							</Form.Label>
							<div className="radio-containers">
								<Form.Check label="Sí" type="radio" value="true" 
									name="multipleQ1" 
									onClick={() => setHadDifficulties(true)} 
									id="multipleQ1-1" 
									defaultChecked={props.form2Answers?.multipleQ1 === "true"}
									disabled={props.read}
								/>
								<Form.Check label="No" type="radio" value="false" 
									name="multipleQ1" 
									onClick={() => setHadDifficulties(false)} 
									id="multipleQ1-2" 
									defaultChecked={props.form2Answers?.multipleQ1 === "false"}
									disabled={props.read}
								/>
								<Form.Check
									label="No las consulté"
									type="radio"
									value="dontKnow"
									onClick={() => setHadDifficulties(false)}
									name="multipleQ1"
									id="multipleQ1-3"
									defaultChecked={props.form2Answers?.multipleQ1 === "dontKnow"}
									disabled={props.read}
								/>
							</div>
						</Form.Group>
					</Row>
					{(hadDifficulties || props.form2Answers?.multipleQ1 === "true") && (
						<Row style={{ display: "flex" }}>
							<Form.Group as={Col} controlId="validationCustom20">
								<Form.Label>Cuéntanos qué ocurrió. Hacemos lo mejor por mejorar.</Form.Label>
								<Form.Control
									as="textarea"
									rows={4}
									style={{ resize: "none" }}
									placeholder={props.read ? '' : "Tu respuesta"}
									name="multipleQ1_Other"
									className="notObligatoryInput"
									defaultValue={props.form2Answers?.multipleQ1_Other}
									readOnly={props.read}
								/>
							</Form.Group>
						</Row>
					)}
					<Row>
						<Form.Group as={Col} controlId="validationCustom02">
							<Form.Label>
								<a></a>¿Viste las sesiones grabadas como forma de repaso?
							</Form.Label>
							<div className="radio-containers">
								<Form.Check label="Sí" type="radio" value="true" 
									name="multipleQ2" id="multipleQ2-1"
									defaultChecked={props.form2Answers?.multipleQ2 === "true"}
									disabled={props.read}
								/>
								<Form.Check label="No" type="radio" value="false" 
									name="multipleQ2" 
									id="multipleQ2-2"
									defaultChecked={props.form2Answers?.multipleQ2 === "false"}
									disabled={props.read}
								/>
							</div>
						</Form.Group>
					</Row>
					<Row style={{ top: "15px" }}>
						<Form.Group as={Col} controlId="validationCustom18">
							<Form.Label>
								<a></a>¿Cuántas veces consultaste las grabaciones?
							</Form.Label>
							<br></br>
							<div className="radio-containers row-container">
								<Form.Check className="validationCustomTenOpt" label="1 - 3" 
									type="radio" value={"1 - 3"} name="multipleQ3" 
									id="multipleQ3-1" 
									defaultChecked={props.form2Answers?.multipleQ3 === "1 - 3"}
									disabled={props.read}
								/>
								<Form.Check className="validationCustomTenOpt" label="4 - 7" 
									type="radio" value={"4 - 7"} name="multipleQ3" 
									id="multipleQ3-2"
									defaultChecked={props.form2Answers?.multipleQ3 === "4 - 7"}
									disabled={props.read}
								/>
								<Form.Check className="validationCustomTenOpt" label="8 - 12" 
									type="radio" value={"8 - 12"} name="multipleQ3" 
									id="multipleQ3-3" 
									defaultChecked={props.form2Answers?.multipleQ3 === "8 - 12"}
									disabled={props.read}
								/>
								<Form.Check className="validationCustomTenOpt" label="12+" 
									type="radio" value={"12+"} name="multipleQ3" 
									id="multipleQ3-4" 
									defaultChecked={props.form2Answers?.multipleQ3 === "12+"}
									disabled={props.read}
								/>
							</div>
						</Form.Group>
					</Row>
					<Row style={{ top: "15px" }}>
						<Form.Group as={Col} controlId="validationCustom17">
							<Form.Label>
								<a></a>¿Cuánto tiempo le dedicaste al estudio de los idiomas entre semana?
							</Form.Label>
							{props.read ? (
								<Form.Control
									type="text"
									defaultValue={props.form2Answers?.timeStudyDuringWeek}
									readOnly
								/> ) : 
								(<div className="time-of-study-row-container">
									<div className="input-container hours">
										<Form.Control type="text" name="q171" defaultValue={"2"} placeholder="" />
										<Form.Label>horas </Form.Label>
									</div>
									<div className="input-container each">
										<Form.Label>cada</Form.Label>
										<Form.Control type="text" name="q172" defaultValue={"2"} placeholder="" />
									</div>
									<div className="input-container hours">
										<Form.Control as="select" name="q173" defaultValue={"días"}>
											<option value={"días"}>Días</option>
											<option value={"semanas"}>Semanas</option>
										</Form.Control>
									</div>
								</div>)}
						</Form.Group>
					</Row>
					<Row style={{ top: "15px" }}>
						<Form.Group as={Col} controlId="validationCustom04">
							<Form.Label>
								<a></a>Del 1 al 10, ¿Cómo evaluarías el contenido del curso?
							</Form.Label>
							<br></br>
							<div className="radio-containers row-container">
								<Form.Check className="validationCustomTenOpt" label="1 - 2" 
									type="radio" value={"1 - 2"} name="multipleQ4" 
									id="multipleQ4-1" 
									defaultChecked={props.form2Answers?.multipleQ4 === "1 - 2"}
									disabled={props.read}
								/>
								<Form.Check className="validationCustomTenOpt" label="3 - 4" 
									type="radio" value={"3 - 4"} name="multipleQ4" 
									id="multipleQ4-2" 
									defaultChecked={props.form2Answers?.multipleQ4 === "3 - 4"}
									disabled={props.read}
								/>
								<Form.Check className="validationCustomTenOpt" label="5 - 6" 
									type="radio" value={"5 - 6"} name="multipleQ4" 
									id="multipleQ4-3" 
									defaultChecked={props.form2Answers?.multipleQ4 === "5 - 6"}
									disabled={props.read}
								/>
								<Form.Check className="validationCustomTenOpt" label="7 - 8" 
									type="radio" value={"7 - 8"} name="multipleQ4" 
									id="multipleQ4-4" 
									defaultChecked={props.form2Answers?.multipleQ4 === "7 - 8"}
									disabled={props.read}
								/>
								<Form.Check className="validationCustomTenOpt" label="9 - 10" 
									type="radio" value={"9 - 10"} name="multipleQ4" 
									id="multipleQ4-5" 
									defaultChecked={props.form2Answers?.multipleQ4 === "9 - 10"}
									disabled={props.read}
								/>
							</div>
						</Form.Group>
					</Row>
				</Col>
			</Row>
			<Row style={props.step === 2 ? { display: "flex" } : { display: "none" }}>
				<Col>
					<Row>
						<Col>
							<Row className="mb-4">
								<Form.Group as={Col} controlId="validationCustom05">
									<Form.Label>
										<a></a>Del 1 al 10, ¿Cómo evaluarías a tu profesor@?
									</Form.Label>
									<br></br>
									<div className="radio-containers row-container">
										<Form.Check className="validationCustomTenOpt" label="1 - 2" 
										type="radio" value={"1 - 2"} name="multipleQ5" 
										id="multipleQ5-1" 
										defaultChecked={props.form2Answers?.multipleQ5 === "1 - 2"}
										disabled={props.read}
									/>
										<Form.Check className="validationCustomTenOpt" label="3 - 4" 
										type="radio" value={"3 - 4"} name="multipleQ5" 
										id="multipleQ5-2" 
										defaultChecked={props.form2Answers?.multipleQ5 === "3 - 4"}
										disabled={props.read}
									/>
										<Form.Check className="validationCustomTenOpt" label="5 - 6" 
										type="radio" value={"5 - 6"} name="multipleQ5" 
										id="multipleQ5-3" 
										defaultChecked={props.form2Answers?.multipleQ5 === "5 - 6"}
										disabled={props.read}
									/>
										<Form.Check className="validationCustomTenOpt" label="7 - 8" 
										type="radio" value={"7 - 8"} name="multipleQ5" 
										id="multipleQ5-4" 
										defaultChecked={props.form2Answers?.multipleQ5 === "7 - 8"}
										disabled={props.read}
									/>
										<Form.Check className="validationCustomTenOpt" label="9 - 10" 
										type="radio" value={"9 - 10"} name="multipleQ5" 
										id="multipleQ5-5" 
										defaultChecked={props.form2Answers?.multipleQ5 === "9 - 10"}
										disabled={props.read}
									/>
									</div>
								</Form.Group>
							</Row>
							<Row className="mb-4" style={{ top: "15px" }}>
								<Form.Group as={Col} controlId="validationCustom06">
									<Form.Label>
										<a></a>Del 1 al 10, ¿Cómo calificarías el material de trabajo?
									</Form.Label>
									<br></br>
									<div className="radio-containers row-container">
										<Form.Check className="validationCustomTenOpt" label="1 - 2" 
											type="radio" value={"1 - 2"} name="multipleQ6" 
											id="multipleQ6-1" 
											defaultChecked={props.form2Answers?.multipleQ6 === "1 - 2"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="3 - 4" 
											type="radio" value={"3 - 4"} name="multipleQ6" 
											id="multipleQ6-2" 
											defaultChecked={props.form2Answers?.multipleQ6 === "3 - 4"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="5 - 6" 
											type="radio" value={"5 - 6"} name="multipleQ6" 
											id="multipleQ6-3" 
											defaultChecked={props.form2Answers?.multipleQ6 === "5 - 6"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="7 - 8" 
											type="radio" value={"7 - 8"} name="multipleQ6" 
											id="multipleQ6-4" 
											defaultChecked={props.form2Answers?.multipleQ6 === "7 - 8"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="9 - 10" 
											type="radio" value={"9 - 10"} name="multipleQ6"
											id="multipleQ6-5"
											defaultChecked={props.form2Answers?.multipleQ6 === "9 - 10"}
											disabled={props.read}
										 />
									</div>
								</Form.Group>
							</Row>
							<Row className="mb-4" style={{ top: "15px" }}>
								<Form.Group as={Col} controlId="validationCustom07">
									<Form.Label>
										<a></a>Del 1 al 10, ¿Cómo calificarías la atención de los administradores de WhatsApp?
									</Form.Label>
									<br></br>
									<div className="radio-containers row-container">
										<Form.Check className="validationCustomTenOpt" label="1 - 2" 
											type="radio" value={"1 - 2"} name="multipleQ7" 
											id="multipleQ7-1" 
											defaultChecked={props.form2Answers?.multipleQ7 === "1 - 2"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="3 - 4" 
											type="radio" value={"3 - 4"} name="multipleQ7" 
											id="multipleQ7-2" 
											defaultChecked={props.form2Answers?.multipleQ7 === "3 - 4"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="5 - 6" 
											type="radio" value={"5 - 6"} name="multipleQ7" 
											id="multipleQ7-3" 
											defaultChecked={props.form2Answers?.multipleQ7 === "5 - 6"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="7 - 8" 
											type="radio" value={"7 - 8"} name="multipleQ7" 
											id="multipleQ7-4" 
											defaultChecked={props.form2Answers?.multipleQ7 === "7 - 8"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="9 - 10" 
											type="radio" value={"9 - 10"} name="multipleQ7"
											id="multipleQ7-5"
											defaultChecked={props.form2Answers?.multipleQ7 === "9 - 10"}
											disabled={props.read}
										 />
									</div>
								</Form.Group>
							</Row>
							<Row className="mb-4" style={{ top: "15px" }}>
								<Form.Group as={Col} controlId="validationCustom08">
									<Form.Label>
										<a></a>Del 1 al 10, ¿Cómo calificarías tu experiencia en el programa?
									</Form.Label>
									<br></br>
									<div className="radio-containers row-container">
									<Form.Check className="validationCustomTenOpt" label="1 - 2" 
											type="radio" value={"1 - 2"} name="multipleQ8" 
											id="multipleQ8-1" 
											defaultChecked={props.form2Answers?.multipleQ8 === "1 - 2"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="3 - 4" 
											type="radio" value={"3 - 4"} name="multipleQ8" 
											id="multipleQ8-2" 
											defaultChecked={props.form2Answers?.multipleQ8 === "3 - 4"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="5 - 6" 
											type="radio" value={"5 - 6"} name="multipleQ8" 
											id="multipleQ8-3" 
											defaultChecked={props.form2Answers?.multipleQ8 === "5 - 6"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="7 - 8" 
											type="radio" value={"7 - 8"} name="multipleQ8" 
											id="multipleQ8-4" 
											defaultChecked={props.form2Answers?.multipleQ8 === "7 - 8"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="9 - 10" 
											type="radio" value={"9 - 10"} name="multipleQ8"
											id="multipleQ8-5"
											defaultChecked={props.form2Answers?.multipleQ8 === "9 - 10"}
											disabled={props.read}
										 />
									</div>
								</Form.Group>
							</Row>
							<Row className="mb-4" style={{ top: "15px" }}>
								<Form.Group as={Col} controlId="validationCustom09">
									<Form.Label>
										<a></a>Del 1 al 10, ¿Cuánto consideras que has aprendido?
									</Form.Label>
									<br></br>
									<div className="radio-containers row-container">
									<Form.Check className="validationCustomTenOpt" label="1 - 2" 
											type="radio" value={"1 - 2"} name="multipleQ9" 
											id="multipleQ9-1" 
											defaultChecked={props.form2Answers?.multipleQ9 === "1 - 2"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="3 - 4" 
											type="radio" value={"3 - 4"} name="multipleQ9" 
											id="multipleQ9-2" 
											defaultChecked={props.form2Answers?.multipleQ9 === "3 - 4"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="5 - 6" 
											type="radio" value={"5 - 6"} name="multipleQ9" 
											id="multipleQ9-3" 
											defaultChecked={props.form2Answers?.multipleQ9 === "5 - 6"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="7 - 8" 
											type="radio" value={"7 - 8"} name="multipleQ9" 
											id="multipleQ9-4" 
											defaultChecked={props.form2Answers?.multipleQ9 === "7 - 8"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="9 - 10" 
											type="radio" value={"9 - 10"} name="multipleQ9"
											id="multipleQ9-5"
											defaultChecked={props.form2Answers?.multipleQ9 === "9 - 10"}
											disabled={props.read}
										 />
									</div>
								</Form.Group>
							</Row>
							<Row className="mb-4">
								<Form.Group as={Col} controlId="validationCustom15">
									<Form.Label>
										<a></a>Del 1 al 10, ¿Cómo calificarías tu experiencia usando la plataforma web?
									</Form.Label>
									<br></br>
									<div className="radio-containers row-container">
									<Form.Check className="validationCustomTenOpt" label="1 - 2" 
											type="radio" value={"1 - 2"} name="multipleQ15" 
											id="multipleQ15-1" 
											defaultChecked={props.form2Answers?.multipleQ15 === "1 - 2"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="3 - 4" 
											type="radio" value={"3 - 4"} name="multipleQ15" 
											id="multipleQ15-2" 
											defaultChecked={props.form2Answers?.multipleQ15 === "3 - 4"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="5 - 6" 
											type="radio" value={"5 - 6"} name="multipleQ15" 
											id="multipleQ15-3" 
											defaultChecked={props.form2Answers?.multipleQ15 === "5 - 6"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="7 - 8" 
											type="radio" value={"7 - 8"} name="multipleQ15" 
											id="multipleQ15-4" 
											defaultChecked={props.form2Answers?.multipleQ15 === "7 - 8"}
											disabled={props.read}
										/>
										<Form.Check className="validationCustomTenOpt" label="9 - 10" 
											type="radio" value={"9 - 10"} name="multipleQ15"
											id="multipleQ15-5"
											defaultChecked={props.form2Answers?.multipleQ15 === "9 - 10"}
											disabled={props.read}
										 />
									</div>
								</Form.Group>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row style={props.step === 3 ? { display: "flex" } : { display: "none" }}>
				<Col>
					<Row>
						<Col>
							<Row className="mr-auto">
								<Form.Group as={Col} controlId="validationCustom10">
									<Form.Label>
										<a></a>¿Recomendarías el programa?
									</Form.Label>
									<br></br>

									<div className="radio-containers column-container mr-auto">
										<Form.Check
											label="Si"
											type="radio"
											value={"true"}
											onChange={(e) => props.setMultipleQ10(e.target.value)}
											name="multipleQ10"
											id="multipleQ10-1"
											defaultChecked={props.form2Answers?.multipleQ10 === "true"}
											disabled={props.read}
										/>
										<Form.Check
											label="No"
											type="radio"
											value={"false"}
											onChange={(e) => props.setMultipleQ10(e.target.value)}
											name="multipleQ10"
											id="multipleQ10-2"
											defaultChecked={props.form2Answers?.multipleQ10 === "false"}
											disabled={props.read}
										/>
										<Form.Check
											label="Tal vez"
											type="radio"
											value={"maybe"}
											onChange={(e) => props.setMultipleQ10(e.target.value)}
											name="multipleQ10"
											id="multipleQ10-3"
											defaultChecked={props.form2Answers?.multipleQ10 === "maybe"}
											disabled={props.read}
										/>
										<Form.Check
											label="Otro"
											type="radio"
											value={"other"}
											onChange={(e) => props.setMultipleQ10(e.target.value)}
											name="multipleQ10"
											id="multipleQ10-4"
											defaultChecked={props.form2Answers?.multipleQ10 === "other"}
											disabled={props.read}
										/>
									</div>
								</Form.Group>
							</Row>
							{(props.MultipleQ10 === "other" || props.form2Answers?.multipleQ10 === "other") && (
								<Row style={{ display: "flex" }}>
									<Form.Group as={Col} controlId="validationCustom11">
										<Form.Label>¿Cuáles?</Form.Label>
										<Form.Control required type="text" placeholder={props.read ? '' : "Tu respuesta"}
											name="multipleQ10_Other" 
											className="notObligatoryInput" 
											defaultValue={props.form2Answers?.multipleQ10_Other}
											readOnly={props.read}
										/>
									</Form.Group>
								</Row>
							)}
							<Row>
								<Form.Group as={Col} controlId="validationCustom12">
									<Form.Label>
										<a></a>¿Qué fue lo que más te agradó del programa?{" "}
									</Form.Label>
									<Form.Control type="text" name="q11"
										placeholder={props.read ? '' : "Tu respuesta"}
										defaultValue={props.form2Answers?.q11}
										readOnly={props.read}
									/>
								</Form.Group>
							</Row>

							<Row>
								<Form.Group as={Col} controlId="validationCustom13">
									<Form.Label>
										<a></a>¿Qué mejorarías en el programa?
									</Form.Label>
									<Form.Control type="text" name="q12" 
										placeholder={props.read ? '' : "Tu respuesta"}
										defaultValue={props.form2Answers?.q12}
										readOnly={props.read}
									/>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} controlId="validationCustom14">
									<Form.Label>
										<a className="obligatory">*</a>Comentario sobre el programa
									</Form.Label>
									<Form.Control required as="textarea" rows={4} style={{ resize: "none" }} 
										name="q13"
										placeholder={props.read ? '' : "Tu respuesta"}
										defaultValue={props.form2Answers?.q13}
										readOnly={props.read}
									/>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} controlId="validationCustom16">
									<Form.Label>
										<a className="obligatory">*</a>¿Qué mejorarías en la plataforma web?
									</Form.Label>
									<Form.Control required as="textarea" rows={4} style={{ resize: "none" }} 
										name="q14"
										placeholder={props.read ? '' : "Tu respuesta"}
										defaultValue={props.form2Answers?.q14}
										readOnly={props.read}
									/>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} controlId="validationCustom19">
									<Form.Label>¿Qué consideras lo más importante que has aprendido hasta el momento?</Form.Label>
									<Form.Control as="textarea" rows={4} style={{ resize: "none" }} 
										name="q19" 
										placeholder={props.read ? '' : "Tu respuesta"}
										defaultValue={props.form2Answers?.mostImportantLearning}
										readOnly={props.read}
									/>
								</Form.Group>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
			{props.user === null && (
				<Row className="buttons-row-container" style={{ padding: "0 15px", top: "50px", position: "relative" }}>
					{props.step > 1 && (
						<button className="tertiary" type="button" style={{ marginRight: "auto" }} onClick={() => props.setStep(props.step - 1)}>
							Atrás
						</button>
					)}
					{props.step < 3 && (
						<button className="tertiary" type="button" style={{ marginLeft: "auto" }} onClick={() => props.setStep(props.step + 1)}>
							Continuar
						</button>
					)}
					{props.step === 3 && (
						<>
							{!props.loading && (
								<button form="my-form" className="tertiary return-btn-style" type="submit">
									Finalizar
								</button>
							)}
							{props.loading && (
								<div className="spinner-border text-danger" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							)}
						</>
					)}
				</Row>
			)}
		</Form>
	);
}
