import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";


function EditCategoryModal(props) {

    const [category, setCategory] = useState({ index: props.subcategory.index, subcategory: props.subcategory.subcategory, color: props.subcategory.color })

    const closeModal = () => {
        props.onHide();
    }

    useEffect(() => {
        setCategory(props.subcategory)
    }, [props.subcategory]);

    return (
        <Modal
            show={props.show}
            onHide={closeModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="bookmark-word-note-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Editar color
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label htmlFor="bookmark-note"><span style={{ color: category.color, fontSize: "2rem" }}>{category.subcategory}</span></label>
                <div className="input-container" style={{ width: 100, height: 50 }}>
                    <input
                        type="color"
                        name="bookmark-note"
                        value={category.color}
                        onChange={(e) => setCategory({ ...category, color: e.target.value })}
                        placeholder="Nota a revisar"
                        id="bookmark-note"
                        style={{ height: "100%" }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex">
                <button className="action-btn secondary-btn-bg" onClick={closeModal}>Cancelar</button>
                <button className="action-btn linear-bg" onClick={() => props.save(category)}>Guardar</button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditCategoryModal;