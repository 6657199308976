import { EyeBlock, NextArrowScroll } from "assets/Imports";
import { getDate, getImage, romanize } from "functions/Functions";
import { useEffect, useState, useRef } from "react";
import RowRecordings from "./RowRecordings";

export function CardsContainer(props) {


	const [position, setPosition] = useState(0);
	const [maxPosition, setMaxposition] = useState(0);
	const [scroll, setScroll] = useState<any>([
		{ module: 0, total: 1 },
		{ module: 0, total: 1 },
		{ module: 0, total: 1 },
		{ module: 0, total: 1 },
		{ module: 0, total: 1 },
	]);

	let posi: any = 0;

	function toggleScroll(direction, index) {
		if (direction === "left") {
			const element: any = document.getElementById("cards-container-" + index);
			let temparrya = scroll;
			temparrya[index].total = element.scrollWidth;
			temparrya[index].module = temparrya[index].module - element.scrollWidth / 4;
			element.scrollLeft -= element.scrollWidth / 4;

			setScroll([...temparrya]);
		}
		if (direction === "rigth") {
			const element: any = document.getElementById("cards-container-" + index);
			let temparrya = scroll;
			temparrya[index].total = element.scrollWidth;
			temparrya[index].module = temparrya[index].module + element.scrollWidth / 4;
			element.scrollLeft += element.scrollWidth / 4;

			setScroll([...temparrya]);
		}
	}

	const posision = (index) => {
		const scrollWidth: any = document.getElementById("cards-container-" + index)?.scrollWidth;
		let maxposision: any = document.getElementById("cards-container-" + index)?.offsetWidth;
		maxposision = scrollWidth - maxposision;
		setMaxposition(maxposision);
	};

	const check = (index) => {
		const posision: any = document.getElementById("cards-container-" + index)?.scrollLeft;
		setPosition(posision);
	};

	useEffect(() => {
		check(props.index);
		posision(props.index);
	}, []);

	return (
		<div className="module-container" key={"recording-module-" + props.index}>
			<div className="mb-2 title-module-container" key={"modulo-container-" + props.index}>
				<h2>
					Módulo{" "}
					<span className="patillas-font">
						{romanize(props.order === "modules" ? props.index + 1 : props.recordings.length - props.index)}
					</span>
				</h2>
			</div>
			<div id={"prueba-" + props.index} className="cards-container-relative">
				{position > 0 && (
					<div className="left show-arrow" onClick={() => toggleScroll("left", props.index)}>
						{" "}
						<img style={{ height: 50 }} src={NextArrowScroll} alt="" />{" "}
					</div>
				)}
				{position < maxPosition && (
					<div className="rigth-arrow show-arrow" onClick={() => toggleScroll("rigth", props.index)}>
						<img style={{ height: 50 }} src={NextArrowScroll} alt="" />
					</div>
				)}
				<div className="cards-container" id={"cards-container-" + props.index} onScroll={() => check(props.index)}>
					{props.module.map((recording, index2) => {
						return (
							<RowRecordings
								index2={index2}
								recording={recording}
								paidSeminaries={props.paidSeminaries}
								nextSeminaries={props.nextSeminaries}
								genId={props.genId}
								materialId={props.materialId}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}
