import { useState } from "react";
import parse from "html-react-parser";
import Modal from "react-bootstrap/Modal";

export function ToolTipModal(props) {
	const [step, setStep] = useState<number>(1);

	function returnStepText(step) {
		switch (step) {
			case 1:
				return "Lo primero que se debe de hacer es seleccionar el idioma en cuestión.";
				break;
			case 2:
				return (
					"Se seleccionará la categoría, la cual se le asignará a las palabras que se importarán.\n\n" +
					"<b>Categoría nueva:</b>\n" +
					"En caso que se quiera agregar una nueva categoría, se seleccionará la opción de nueva, despúes aparecerá un campo de texto donde se tendrá que ingresar el nombre de la nueva categoría."
				);
				break;
			case 3:
				return (
					"Antes de pegar los datos, en el campo de texto se mostrará una leyenda de la forma correcta en la que se deberán de ingresar los datos para importarlos de manera correcta.\n\n" +
					"Se pegarán los datos desde el archivo de su preferencia.\n\n" +
					"<b>Importante:</b>\n" +
					"<b>1.</b>\tEn la primera columna deberá de ir el término en el idioma seleccionado.\n" +
					"<b>2.</b>\tEn la segunda columna deberá de ir el término en su significado en español.\n" +
					"<b>3.</b>\tEn caso de que se seleccione el idioma chino o japonés, habrá una tercera columna, donde deberá de ir el término en kanzi o kanji, según sea el idioma seleccionado.\n\n" +
					"<b>Notas:</b>\n" +
					"<b>-</b>\tEn chino es obligatorio ingresar el kanzi, en japones ingresar el kanji es opcional.\n" +
					"<b>-</b>\tCada término deberá de estar separado por un espacio de tabulador.\n" +
					"<b>-</b>\tEn el significado en español es posible ingresar dos o más valores, solo separandolos con una coma(,). (Ejemplo: 'ser,estar').\n" +
					""
				);
				break;
			case 4:
				return "Una vez que se hayan llenado todos los campos correctamente. El botón de importar se desbloqueará y podremos realizar la importación de las palabras ingresadas.";
				break;
			default:
				return "";
				break;
		}
	}

    const closeModal = () => {
        props.onHide();
        setStep(1);
    }

	return (
		<Modal show={props.show} onHide={closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Guía para importar</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h4>
					<b>Paso {step}:</b>
				</h4>
				<p style={{ whiteSpace: "pre-line" }}>{parse(returnStepText(step))}</p>
			</Modal.Body>
			<Modal.Footer className={step > 1 ? "justify-content-between" : ""}>
				{step > 1 && (
					<button className="action-btn secondary-btn-bg" onClick={() => setStep(step - 1)}>
						Atrás
					</button>
				)}
				{step < 4 ? (
					<button className="action-btn linear-bg" onClick={() => setStep(step + 1)}>
						Siguiente
					</button>
				) : (
					<button className="action-btn linear-bg" onClick={closeModal}>
						Cerrar
					</button>
                )}
			</Modal.Footer>
		</Modal>
	);
}
