import { getCurrentAnniversary } from "@utils/utilFunctions";
import "./PromotionSlide.scss";

// Aniversario actual (string de 2 digitos)
const year:string = getCurrentAnniversary();

export default function PromotionSlide() {

	// Cargamos el path a la imagen de forma dinamica, dependiendo de en que aniversario nos encontramos
	const anniversaryImg = require(`assets/images/contenido_aniversarios/aniversarios_svg_1/${year}.svg`)

	return (
		<div className="slide-main-container promotion">
			<div className="content">
				<h3>
					Conviértete en <span>Políglota</span>
				</h3>
				<h2>Hoy mismo</h2>
					<img src={anniversaryImg} alt="Imagen aniversario" />
			</div>
		</div>
	);
}
