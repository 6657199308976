import "./Slide.scss";
import "./Images.scss";

interface SlidePropsI {
	module: {
		number: number;
		title: string;
		romanNumber: string;
		duration: string;
		skills: Array<string>;
		images: Array<{ class: string; url: any }>;
	};
}
export const Slide = (props: SlidePropsI) => {
	const { module } = props;

	return (
		<div className={"modules-slide-main-container " + module.romanNumber}>
			{module.images.map((image, i) => {
				return <img className={image.class + " img" + i} src={image.url} key={image.class + i} />;
			})}
			<div className={`info-container ${module.romanNumber}`}>
				<h2>Módulo {module.romanNumber}</h2>
				<h4 className="title">{module.title}</h4>
				<h4>
					<b>Duración:</b> {module.duration}
				</h4>
				<h4 className="m-0">
					<b>Habilidades al finalizarlo:</b>
				</h4>
				<ul>
					{module.skills.map((skill) => {
						return <li key={skill}>{skill}</li>;
					})}
				</ul>
				<button>Ver programa</button>
			</div>
		</div>
	);
};
