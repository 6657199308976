import { GenerationTypesToSpanishModalitiesTable, TGeneration } from "interfaces/Generations";
import { Dispatch, SetStateAction } from "react";

type ModalitySwichProps = {
	selectedElement: number;
	setSelectedElement: Dispatch<SetStateAction<number>>;
	generations:Array<TGeneration>;
};

const ModalitySwitch = (props: ModalitySwichProps) => {
	const { selectedElement, setSelectedElement, generations } = props;
	return (
		<div className="modality-switch-main-container">
			<div className="modality-switch-buttons-container">
				{generations.map((e, index) => {
					return <button
					className={`modality-toggle-button ${e.type} ${selectedElement === index && "selected"}`}
					onClick={() => setSelectedElement(index)} key={e.id}
				>
					{GenerationTypesToSpanishModalitiesTable[e.type]}
				</button>
				})}
			</div>
		</div>
	);
};

export default ModalitySwitch;
