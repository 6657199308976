import { Modal } from "react-bootstrap";
import { seminariesNumber } from "functions/DefaultValues";
import { lastStep } from "@api/Videos/VideosApi";
import { useState, useEffect } from "react";
import { getSeminaryMaterialByNum, getDatesByGenId } from "services/GenerationService";
import { db } from "initFirebase";
import { Form } from "react-bootstrap";
import {
	doc,
	updateDoc,
	query,
	collection,
	getDocs,
	addDoc,
	where,
	getDoc,
} from "firebase/firestore";
import "./SaveVideoModal.scss";
import { romanize } from "functions/Functions";
import { useAuth } from "@hooks/useAuth";

import useLinksHistory from "@hooks/LinkHistory/useLinksHistory";
export function SaveVideoModal(props) {
	const [selectedSeminary, setSelectedSeminary] = useState<any>(0);
	const [seminaryDate, setSeminaryDate] = useState<any>();
	const [genDates, setGenDates] = useState<Array<any>>();
	const [formatedDate, setFormatedDate] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const [diffDate, setDiffDate] = useState<any>();
	const [gens, setGens] = useState<Array<any>>(new Array());
	const [gen, setGen] = useState("");
	const [formatedGenName, setFormatedGenName] = useState<string>(props.video?.savepath);
	const [videoPart, setVideoPart] = useState<string>("videoUrl");
	const [wantToNotify, setWantToNotify] = useState<boolean>(true);
	const [previousVideoPart, setPreviousVideoPart] = useState<string>("");

	const { SaveLinksHistory } = useLinksHistory();

	const listErrors = {
		ERROR_SEMINARIO: "Selecciona un seminario",
	};

	const changeVideoPart = (newPart) => {
		setPreviousVideoPart(videoPart);
		setVideoPart(newPart);
	};

	const getGenerations = async () => {
		let array: any = [];

		const q = query(collection(db, "generations"));
		const querySnapshot = await getDocs(q);
		let i = 0;
		querySnapshot.forEach(async (doc) => {
			const data = {
				...doc.data(),
				id: doc.id,
			};
			array.push(data);
			i++;

			if (i === querySnapshot.size) {
				setGens(array);
			}
		});
	};

	const doLastStep = async () => {
		setLoading(true);
		try {
			if (selectedSeminary === 0) {
				alert(listErrors.ERROR_SEMINARIO);
				throw new Error(listErrors.ERROR_SEMINARIO);
			}
			const hasVideoBeenProcessed = Boolean(props.video.lastStep);

			if (!hasVideoBeenProcessed) {
				await lastStep(props.videoUrl);
			}
			console.log(
				hasVideoBeenProcessed ? "Video ya formateado con anterioridad" : "Se guardó el video"
			);
			saveVideo();
			if (wantToNotify) {
				sendNotif();
				console.log("Notificación enviada");
			}
		} catch (error) {
			console.error(error);
		}
	};

	const formatSeminaryDate = async (date: string) => {
		let materialDate = new Date(+date.split("/")[2], +date.split("/")[1] - 1, +date.split("/")[0]);
		let tempFormatedDate = materialDate.toLocaleString("es-Mx", {
			weekday: "long",
			day: "numeric",
			month: "long",
		});
		let index = genDates?.indexOf(date)! + 1;
		setSeminaryDate(date);
		setSelectedSeminary(index);
		setFormatedDate(romanize(index) + " " + tempFormatedDate);
	};

	const getRecentDate = async () => {
		if (props.show === true) {
			var generationId = "";

			if (gen?.length > 0) {
				generationId = gen;
			} else {
				generationId = props?.gensRecord?.filter((e) =>
					e.name?.replace(/\s/g, "").includes(props?.video?.savepath?.replace(/\s/g, ""))
				)[0]?.id;
			}
			let today = new Date();
			let recentDate: any = "";

			if (generationId !== null) {
				console.log(generationId);
				let tempGenDates = await getDatesByGenId(generationId);
				let correctedDates: any = [];
				let correctedDate = "";
				console.log(tempGenDates);
				tempGenDates?.map((e, index) => {
					correctedDate =
						e.split("/")[0] + "/" + (parseInt(e.split("/")[1]) + 1) + "/" + e.split("/")[2];
					correctedDates.push(correctedDate);
				});
				setGenDates(correctedDates);
				let index2 = 0;
				let materialDate: any = "";
				tempGenDates?.map((date, index) => {
					materialDate = new Date(+date.split("/")[2], +date.split("/")[1], +date.split("/")[0]);
					if (today > materialDate) {
						recentDate = materialDate.toLocaleString("es-Mx", {
							weekday: "long",
							day: "numeric",
							month: "long",
						});
						index2 = index;
						let Diff_in_time = today.getTime() - materialDate.getTime();
						let Diff_in_days = Diff_in_time / (1000 * 3600 * 24);
						setDiffDate(Math.trunc(Diff_in_days));
					}
				});
				setFormatedDate(romanize(index2 + 1) + " " + recentDate);
				if (tempGenDates) {
					setSeminaryDate(correctedDates[index2]);
				}
				setSelectedSeminary(index2 + 1);
			}
		}
	};

	const sendNotif = async () => {
		const q2 = query(collection(db, "users"), where("generationId", "==", gen));
		const querySnapshot2 = await getDocs(q2);
		if (selectedSeminary !== 0) {
			querySnapshot2.forEach(async (user) => {
				await addDoc(collection(db, "notifications"), {
					name: "Academia de Lenguas Internacionales",
					text: "Ya está disponible la grabación del Seminario " + romanize(selectedSeminary),
					date: new Date(),
					userId: user.id,
					userType: 2,
					seen: false,
					profileColor: "#000000",
					type: "recordingsReady",
					seminaryNum: selectedSeminary,
				});
			});
		}
	};

	const handleChecked = (e: any) => {
		console.log(e.target.checked);
	};

	useEffect(() => {
		(async () => {
			if (gen && props.numSeminaryVideo) {
				const seminary = await getSeminaryMaterialByNum(gen, props.numSeminaryVideo);
				console.log(seminary);
				props.setCurrentSeminary(seminary);
			}
		})();
	}, [gen, props.numSeminaryVideo]);

	const saveVideo = async () => {
		const seminary = await getSeminaryMaterialByNum(gen, selectedSeminary);
		const videoDate = new Date(props.video.createdAt.seconds * 1000);
		const updateDate = new Date("09/03/2023");

		if (seminary !== null) {
			const LinksHistoryParams = {
				db: db,
				genId: gen,
				seminaryId: seminary.id,
				newLink: `https://academia.inowu.dev/${videoDate > updateDate ? "mnt" : "media"}/${props.videoUrl}master.m3u8`,
				videoPart: videoPart,
				seminaryCurrent: props.videoData,
			};
			const { updatedHistory, dontResetField } = await SaveLinksHistory(LinksHistoryParams);

			if (props.video.generationAssigned) {
				// Obtener la referencia de la generación anterior
				const oldGenRef = doc(db, "generations", props.video.generationAssigned);
				const oldGenSnap = await getDoc(oldGenRef);

				if (oldGenSnap.exists()) {
					// Obtener la referencia del seminario anterior
					const oldSeminaryQuery = query(
						collection(db, "generations", oldGenSnap.id, "material"),
						where("num", "==", props.video.seminaryAssigned)
					);
					const oldSeminarySnap = await getDocs(oldSeminaryQuery);

					if (oldSeminarySnap.size > 0) {
						const oldSeminary = oldSeminarySnap.docs[0];
						const oldSeminaryRef = doc(
							db,
							"generations",
							oldGenSnap.id,
							"material",
							oldSeminary.id
						);

						// Actualizar la parte asignada del seminario anterior a vacío si dontreset es true
						if (dontResetField !== false) {
							await updateDoc(oldSeminaryRef, {
								[props.video.partAssigned]: "",
							});
						}
					}
				}
			}

			const docRef = doc(db, "generations", gen, "material", seminary.id);

			await updateDoc(docRef, {
				Historylinks: updatedHistory,
				[videoPart]: `https://academia.inowu.dev/${videoDate > updateDate ? "mnt" : "media"}/${props.videoUrl}master.m3u8`,
			}).then(async (res) => {
				const videoRef = doc(db, "uploadedVideos", props.video.id);
				await updateDoc(videoRef, {
					generationAssigned: gen,
					seminaryAssigned: seminary.num,
					partAssigned: videoPart,
					lastStep: true,
					assignmentDate: new Date(),
				}).then(() => {
					alert("Video guardado con éxito.");
					setSelectedSeminary(0);
					props.onHide();
				});
			});

			setLoading(false);
		} else {
			alert("No se ha encontrado el seminario solicitado.");
		}
	};

	useEffect(() => {
		getRecentDate();
		if (props.gensRecord.length > 0) {
			const selectedGen = props.gensRecord.find((e) => e.id === gen);
			const formattedName = selectedGen
				? selectedGen.name.replace("Programa de Alto Rendimiento en 8 Idiomas Simultáneos - ", "")
				: "";

			setFormatedGenName(formattedName);
		}
	}, [gen]);

	useEffect(() => {
		getRecentDate();
		if (props.show === true) {
			if (props?.video?.generationAssigned) {
				setGen(props?.video?.generationAssigned);
			} else {
				setGen(
					props?.gensRecord?.filter((e) =>
						e?.name?.replace(/\s/g, "").includes(props?.video?.savepath.replace(/\s/g, ""))
					)[0]?.id
				);
			}
		}
	}, [props.show]);

	useEffect(() => {
		getGenerations();
	}, []);

	return (
		<Modal
			className="save-video-modal-component"
			show={props.show}
			onHide={props.onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Asigna esta grabación a un seminario
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="save-video-modal-body-container">
					<div className="save-video-row">
						<label htmlFor="seminary">
							{formatedGenName}
							<br></br>
							{formatedDate}
						</label>
						<div className="diff-days">(Fecha más reciente: hace {diffDate} días)</div>
						<label htmlFor="seminary" className="seminary-word">
							Selecciona una generación.
						</label>
						<select
							className="form-control"
							id="savepath"
							name="savepath"
							placeholder="Ejemplo: 2022/mayo/video_1"
							value={gen}
							onChange={(e) => setGen(e.target.value)}
							required
						>
							{gens.map((gen, index) => {
								return (
									<option value={gen.id} key={"opcion-gen-" + index}>
										{gen.name.replace(
											"Programa de Alto Rendimiento en 8 Idiomas Simultáneos - ",
											""
										)}
									</option>
								);
							})}
						</select>
						<div className="invalid-feedback">Por favor selecciona una generación.</div>
						<label htmlFor="seminary" className="seminary-word">
							Selecciona un seminario.
						</label>
						<select
							name="seminary"
							id="seminary"
							value={seminaryDate}
							onChange={(e) => formatSeminaryDate(e.target.value)}
						>
							<option disabled value={0}>
								Selecciona una opción
							</option>
							{genDates?.map((date, index) => {
								return (
									<option value={date} key={"save-video-select-option-" + index}>
										{romanize(index + 1) + " " + date}
									</option>
								);
							})}
						</select>
						<label htmlFor="video-part" className="seminary-word">
							Selecciona la parte correspondiente.
						</label>
						<select
							name="video-part"
							id="seminary"
							value={videoPart}
							onChange={(e) => {
								changeVideoPart(e.target.value);
							}}
						>
							<option disabled value={0}>
								Selecciona una opción
							</option>
							<option value={"videoUrl"}>Parte 1</option>
							<option value={"videoUrl12"}>Parte 2</option>
							<option value={"videoUrl13"}>Parte 3</option>
						</select>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="custom-control custom-switch mr-auto">
					<input
						type="checkbox"
						className="custom-control-input"
						checked={wantToNotify}
						onChange={(e) => setWantToNotify(e.target.checked)}
						id="customSwitch1"
					/>
					<label className="custom-control-label" htmlFor="customSwitch1">
						Notificar a los estudiantes
					</label>
				</div>
				<button
					className={"action-btn linear-bg " + (loading ? "disabled" : "")}
					onClick={doLastStep}
					disabled={loading}
				>
					Guardar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
