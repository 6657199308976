import "./Footer.scss";
import { ReactComponent as FlagLogo } from "@assets/logos/new-flag-logo.svg";
import { ReactComponent as FacebookIcon } from "@assets/icons/facebook.svg";
import { ReactComponent as YoutubeIcon } from "@assets/icons/youtube.svg";
import { ReactComponent as InstagramIcon } from "@assets/icons/instagram.svg";
import { Link } from "react-router-dom";

function Footer() {
	return (
		<footer>
			<div className="logo-container">
				<FlagLogo />
			</div>
			<div className="center-container">
				<div className="social">
					<a href="https://www.facebook.com/academiadelenguas" target="_blank">
						<FacebookIcon />
					</a>
					<a href="https://www.youtube.com/channel/UCWtwKRyJ4TMiC2iXAfo0Meg" target="_blank">
						{" "}
						<YoutubeIcon />
					</a>
					<a href="https://www.instagram.com/academiadelenguas/" target="_blank">
						<InstagramIcon />
					</a>
				</div>
				<div className="name">
					<p>Academia de Lenguas Internacionales</p>
					<div className="separator"></div>
					<p>Fundación Carime Vargas García, A.C</p>
				</div>
				<h6>© 1989-2023 Todos los Derechos Reservados MX2014</h6>
			</div>
			<div className="privacy">
				<div className="privacy-links-container">
					<Link to={"/politicas"}>Términos y condiciones de uso</Link>
					<Link to={"/politicas"}>Aviso de privacidad</Link>
				</div>
			
			</div>
		</footer>
	);
}

export default Footer;
