import TestimonialsContainer from "@containers/TestimonialsContainer/TestimonialsContainer";
import FaqsButton from "@components/FaqsButton/FaqsButton";

function TestimonialScreen() {
	return (
		<div style={{ overflow: "hidden" }}>
			<TestimonialsContainer />
			<FaqsButton />
		</div>
	);
}

export default TestimonialScreen;
