import "./DynamixText.css";
import { addDoc, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "initFirebase";
import { useEffect, useState, useContext } from "react";
import firebase from "firebase/compat/app";
import { v4 as uuidv4 } from "uuid";
import { getSubtitle } from "functions/Generations/FunctionsGenerations";
import { CloseCircleIcon, DocumentAttachFileIcon, FolderIcon, TextIcon } from "assets/Imports";
import { useAuth } from "@hooks/useAuth";
import { compareGenerations } from "functions/Generations/FunctionsGenerations";
import { MenusDataContext } from "../CollaboratorContainer/context/menu-data.provider";
import { useGenerations } from "@hooks/GenerationsFilters/useGenerations";
import FormControl from "react-bootstrap/FormControl";
import { LocationsForm } from "./components/LocationsForm/LocationsForm";
import { useGetCalendar } from "@hooks/DynamicText/useGetCalendars";
import { useEditCalendar } from "@hooks/DynamicText/useGetCalendars";
import toast, { Toaster } from "react-hot-toast";
import PricesStep from "./components/Prices/PricesStep/PricesStep";
import PricesEdit from "./components/Prices/PricesEdit/PricesEdit";

function DynamicText({ user }) {
	const [step, setStep] = useState(0);
	const [option, setOption] = useState(0);
	const [edit, setEdit] = useState("");
	const [editLocation, setEditLocation] = useState<any>({ name: "" });
	const [editMain, setEditMain] = useState<any>({ text: "" });
	const [editVideo, setEditVideo] = useState<any>({ titulo1: "" });
	const [editNavbar, setEditNavbar] = useState<any>({ name: "" });
	const [location, setLocation] = useState<any>(null);
	const [fechas, setFechas] = useState<any>(null);
	const [editFechas, setEditFechas] = useState<any>({ text: "" });
	const [main, setMain] = useState<any>(null);
	const [navbar, setNavbar] = useState<any>(null);
	const [video, setVideo] = useState<any>(null);
	const [program1, setProgram1] = useState<any>(null);
	const [program2, setProgram2] = useState<any>(null);
	const [program3, setProgram3] = useState<any>(null);
	const subLocation = ["location", "main", "navbar", "video", "kids"];
	const subInscription = ["program1", "program2", "program3"];
	const subInformacion = ["fechas"];
	const [testimonials, setTestimonials] = useState<any>(null);
	const [editTestimonial, setEditTestimonial] = useState<any>({ name: "", desc: "" });
	const [antesEmpezar, setAntesEmpezar] = useState<any>([]);
	const [temario, setTemario] = useState<any>([]);
	const [seminario, setSeminario] = useState<any>([]);
	const [metodos, setMetodos] = useState<any>([]);
	const [ninos, setNinos] = useState<any>([]);
	const [announcements, setAnnouncements] = useState<any>([]);
	const [editFaq, setEditFaq] = useState<any>({ question: "", answer: "" });
	const [editAnnouncement, setEditAnnouncement] = useState<any>({
		title: "",
		description: "",
		active: true,
		generationId: "all",
		notify: true,
	});
	const [, set] = useState();
	const [editKids, setEditKids] = useState({ text: "", fieldName: "" });
	const [kids, setKids] = useState({
		costo: "",
		duracion: "",
		fecha: "",
		horario: "",
		horario2: "",
		titulo: "",
		subDoc: "",
		id: "",
		parentId: "",
	});
	const [editProgram, setEditProgram] = useState<any>({
		costo: "",
		duracion: "",
		final: "",
		inversion: "",
		nombre: "",
		pagos: "",
		precio: 0,
		url: "",
	});

	const [nuestroPrograma, setNuestroPrograma] = useState<any>({ fileUrl: "", fileName: "", name: "", id: "" });
	const [editNuestroPrograma, setEditNuestroPrograma] = useState<any>({ fileUrl: "", fileName: "", name: "", file: {}, id: "" });
	const { selectedUserPreview, togglePreviewModeUser } = useAuth();
	const { ispreviewMode, PreviewMode } = useContext(MenusDataContext);

	const { ActualCalendar, setActualCalendar }: any = useGetCalendar("type", "calendar", "dynamic_copies", edit);

	const { editedCalendar, editDataCalendar, setEditedCalendar, loadCalendar } = useEditCalendar("dynamic_copies"); // Reemplaza "tuColeccion" con el nombre de tu colección

	//basta solo la instancia de este custom hook para cargar toda la logica de filtros de generaciones y obtener las propias generaciones
	const { gens, handleSelect, filterEs, filteredAnnouncements } = useGenerations(compareGenerations, announcements);

	const getDocuments = async () => {
		const q = query(collection(db, "dynamic_copies"));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(async (doc) => {
			if (doc.data().name === "landing") {
				subLocation.forEach(async (element) => {
					const querySnapshot2 = await getDocs(collection(db, "dynamic_copies", doc.id, element));
					switch (element) {
						case "location":
							querySnapshot2.forEach((doc2) => {
								const data = {
									...doc2.data(),
									parentId: doc.id,
									subDoc: element,
									id: doc2.id,
								};
								setLocation(data);
							});
							break;
						case "navbar":
							querySnapshot2.forEach((doc2) => {
								const data = {
									...doc2.data(),
									parentId: doc.id,
									subDoc: element,
									id: doc2.id,
								};
								setNavbar(data);
							});
							break;
						case "video":
							const videoData: any = [];
							querySnapshot2.forEach((doc2) => {
								const data = {
									...doc2.data(),
									parentId: doc.id,
									subDoc: element,
									id: doc2.id,
								};
								videoData.push(data);
							});
							setVideo(videoData);
							break;
						case "main":
							querySnapshot2.forEach((doc2) => {
								const data = {
									...doc2.data(),
									parentId: doc.id,
									subDoc: element,
									id: doc2.id,
								};
								setMain(data);
							});
							break;
						case "kids":
							querySnapshot2.forEach((doc2) => {
								const data: any = {
									...doc2.data(),
									parentId: doc.id,
									subDoc: element,
									id: doc2.id,
								};
								setKids(data);
							});
							break;
						default:
							break;
					}
				});
			} else if (doc.data().name === "informacion") {
				subInformacion.forEach(async (element) => {
					const querySnapshot2 = await getDocs(collection(db, "dynamic_copies", doc.id, element));
					querySnapshot2.forEach((doc2) => {
						const data = {
							...doc2.data(),
							parentId: doc.id,
							subDoc: element,
							id: doc2.id,
						};
						setFechas(data);
					});
				});
			} else if (doc.data().name === "inscription") {
				subInscription.forEach(async (element) => {
					const querySnapshot2 = await getDocs(collection(db, "dynamic_copies", doc.id, element));
					switch (element) {
						case "program1":
							querySnapshot2.forEach((doc2) => {
								const data = {
									...doc2.data(),
									parentId: doc.id,
									subDoc: element,
									id: doc2.id,
								};
								setProgram1(data);
							});
							break;
						case "program2":
							querySnapshot2.forEach((doc2) => {
								const data = {
									...doc2.data(),
									parentId: doc.id,
									subDoc: element,
									id: doc2.id,
								};
								setProgram2(data);
							});
							break;
						case "program3":
							querySnapshot2.forEach((doc2) => {
								const data = {
									...doc2.data(),
									parentId: doc.id,
									subDoc: element,
									id: doc2.id,
								};
								setProgram3(data);
							});
							break;
						default:
							break;
					}
				});
			} else if (doc.data().name === "nuestro programa") {
				setNuestroPrograma({ id: doc.id, ...doc.data() });
			}
		});
	};
	const getFaq = async () => {
		const q = query(collection(db, "faq"));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach(async (doc) => {
			if (doc.data().name === "Generales") {
				let array: any = [];
				const querySnapshot2 = await getDocs(collection(db, "faq", doc.id, "questions"));
				let index = 0;
				querySnapshot2.forEach((doc2) => {
					index = index + 1;
					const data = {
						parentId: doc.id,
						id: doc2.id,
						...doc2.data(),
					};
					array.push(data);
					if (index === querySnapshot2.size) {
						setAntesEmpezar(array);
					}
				});
			} else if (doc.data().name === "En línea") {
				let array: any = [];
				const querySnapshot2 = await getDocs(collection(db, "faq", doc.id, "questions"));
				let index = 0;
				querySnapshot2.forEach((doc2) => {
					index = index + 1;
					const data = {
						parentId: doc.id,
						id: doc2.id,
						...doc2.data(),
					};
					array.push(data);
					if (index === querySnapshot2.size) {
						setTemario(array);
					}
				});
			} else if (doc.data().name === "Presencial") {
				let array: any = [];
				const querySnapshot2 = await getDocs(collection(db, "faq", doc.id, "questions"));
				let index = 0;
				querySnapshot2.forEach((doc2) => {
					index = index + 1;
					const data = {
						parentId: doc.id,
						id: doc2.id,
						...doc2.data(),
					};
					array.push(data);
					if (index === querySnapshot2.size) {
						setSeminario(array);
					}
				});
			} else if (doc.data().name === "Inscripción y métodos de pago") {
				let array: any = [];
				const querySnapshot2 = await getDocs(collection(db, "faq", doc.id, "questions"));
				let index = 0;
				querySnapshot2.forEach((doc2) => {
					index = index + 1;
					const data = {
						parentId: doc.id,
						id: doc2.id,
						...doc2.data(),
					};
					array.push(data);
					if (index === querySnapshot2.size) {
						setMetodos(array);
					}
				});
			} else if (doc.data().name === "Plataforma de aprendizaje") {
				let array: any = [];
				const querySnapshot2 = await getDocs(collection(db, "faq", doc.id, "questions"));
				let index = 0;
				querySnapshot2.forEach((doc2) => {
					index = index + 1;
					const data = {
						parentId: doc.id,
						id: doc2.id,
						...doc2.data(),
					};
					array.push(data);
					if (index === querySnapshot2.size) {
						setNinos(array);
					}
				});
			}
		});
	};

	const getTestimonials = async () => {
		let testimonialsArray: any = [];
		const q = query(collection(db, "testimonials"));
		const querySnapshot = await getDocs(q);
		let index = 0;
		querySnapshot.forEach(async (doc) => {
			index = index + 1;
			const data = {
				id: doc.id,
				...doc.data(),
			};

			testimonialsArray.push(data);

			if (index === querySnapshot.size) {
				setTestimonials(testimonialsArray);
			}
		});
	};

	const getAnnouncement = async () => {
		let announcementArray: any = new Array();
		const q = query(collection(db, "announcement"));
		const querySnapshot = await getDocs(q);

		let index = 0;

		querySnapshot.forEach((doc) => {
			index = index + 1;

			const data = {
				id: doc.id,
				...doc.data(),
			};
			announcementArray.push(data);
			if (index === querySnapshot.size) {
				setAnnouncements(announcementArray);
			}
		});
	};

	useEffect(() => {
		getDocuments();
		getTestimonials();
		getFaq();
		getAnnouncement();
		//	getGenerations();
	}, []);

	useEffect(() => {
		setOption(0);
	}, [step]);

	const goBack = () => {
		if (option === 0 || (step !== 1 && step !== 3 && step !== 5)) {
			setStep(0);
		}
		setEdit("");
		setOption(0);
		setEditLocation({ name: "" });
		setEditMain({ text: "" });
	};

	const handleEditLocation = (location, index, locationIndex) => {
		setEdit("location");
		setEditLocation({ ...location, index: index, locationIndex: locationIndex });
	};

	const handleEditMain = (main, mainIndex) => {
		setEdit("main");
		setEditMain({ text: main, index: mainIndex });
	};

	const handleEdit = (type, text, fieldName) => {
		if (type === "kids") {
			setEdit("kids");
			setEditKids({ text: text, fieldName: fieldName });
		}
	};

	const handleEditVideo = (v) => {
		setEdit("video");
		setEditVideo(v);
	};

	const handleEditNavbar = (nav) => {
		setEdit("navbar");
		setEditNavbar(nav);
	};

	const handleEditProgram = (pro) => {
		setEdit("program");
		setEditProgram(pro);
	};

	const handleEditFechas = (fecha, index) => {
		setEdit("fechas");
		setEditFechas({ text: fecha, index: index });
	};

	const handleEditTestimonial = (testimonial, index) => {
		setEdit("testimonials");
		setEditTestimonial({ ...testimonial, index: index });
	};

	const handleChangeLocation = (e) => {
		const { name, value } = e.target;
		setEditLocation({ ...editLocation, [name]: value });
	};

	const handleEditFaq = (qa, index) => {
		setEdit("faq");
		setEditFaq({ ...qa, index: index });
	};

	const handleEditAnnouncement = (ad, index) => {
		setEdit("anno");
		setEditAnnouncement({ ...ad, index: index });
	};

	const handleChangeMain = (e) => {
		const { name, value } = e.target;
		setEditMain({ ...editMain, text: e.target.value });
	};

	const handleChangeVideo = (e) => {
		setEditVideo({ ...editVideo, titulo1: e.target.value });
	};

	const handleEditNuestroPrograma = () => {
		setEdit("filesNuestroPrograma");
		setEditNuestroPrograma(nuestroPrograma);
		setOption(1);
	};

	const handleEditCalendar = () => {
		setEdit("Calendar");
		setEditedCalendar(ActualCalendar);
		setOption(2);
	};

	const handleChangeFile = (e) => {
		setEditNuestroPrograma({ ...editNuestroPrograma, fileName: e.target.files[0].name, file: e.target.files[0], fileUrl: "" });
	};
	const handleChangeFileCalendar = (e) => {
		if (e.target.files[0]) {
			setEditedCalendar({
				...editedCalendar,
				FileName: e.target.files[0].name,
				fileCalendar: e.target.files[0],
				FileUrl: "",
			});
		}
	};

	const handleChangeNameCalendar = (e) => {
		if (editedCalendar !== null && editedCalendar.name !== e.target.value) {
			setEditedCalendar({
				...editedCalendar,
				name: e.target.value,
			});
		}
	};

	const deleteFile = () => {
		setEditNuestroPrograma({ ...editNuestroPrograma, fileUrl: "", fileName: "", name: "", file: {} });
	};
	const deleteFileCalendar = () => {
		setEditedCalendar({ ...editedCalendar, FileUrl: "", FileName: "", name: "", fileCalendar: {} });
	};

	const handleChangeNavbar = (e) => {
		if (edit === "navbar") {
			setEditNavbar({ ...editNavbar, name: e.target.value });
		} else if (edit === "fechas") {
			setEditFechas({ ...editFechas, text: e.target.value });
		} else if (edit === "testimonials") {
			const { name, value } = e.target;
			if (name === "name") {
				setEditTestimonial({ ...editTestimonial, name: e.target.value });
			} else {
				setEditTestimonial({ ...editTestimonial, desc: e.target.value });
			}
		} else if (edit === "faq") {
			const { name, value } = e.target;
			if (name === "question") {
				setEditFaq({ ...editFaq, question: e.target.value });
			} else {
				setEditFaq({ ...editFaq, answer: e.target.value });
			}
		} else if (edit === "program") {
			const { name, value } = e.target;
			setEditProgram({ ...editProgram, [name]: e.target.value });
		} else if (edit === "anno") {
			const { name, value } = e.target;
			if (name === "title") {
				setEditAnnouncement({ ...editAnnouncement, title: value });
			} else if (name === "description") {
				setEditAnnouncement({ ...editAnnouncement, description: value });
			}
		} else if (edit === "kids") {
			setEditKids({ ...editKids, text: e.target.value });
		}
	};

	const saveEdit = async () => {
		if (edit === "location") {
			if (editLocation.locationIndex === "locations2") {
				location.locations2[editLocation.index].name = editLocation.name;
				const locationRef = doc(db, "dynamic_copies", location.parentId, edit, location.id);
				await updateDoc(locationRef, {
					locations2: location.locations2,
				});
				setEdit("");
				setEditLocation({ name: "" });
			} else {
				location.locations[editLocation.index].name = editLocation.name;
				const locationRef = doc(db, "dynamic_copies", location.parentId, edit, location.id);
				await updateDoc(locationRef, {
					locations: location.locations,
				});
				setEdit("");
				setEditLocation({ name: "" });
			}
		} else if (edit === "main") {
			const mainRef = doc(db, "dynamic_copies", main.parentId, edit, main.id);
			if (editMain.index === "titulo2") {
				await updateDoc(mainRef, {
					titulo2: editMain.text,
				});
				setMain({ ...main, titulo2: editMain.text });
			} else if (editMain.index === "titulo1") {
				await updateDoc(mainRef, {
					titulo1: editMain.text,
				});
				setMain({ ...main, titulo1: editMain.text });
			} else if (editMain.index === "titulo3") {
				await updateDoc(mainRef, {
					titulo3: editMain.text,
				});
				setMain({ ...main, titulo3: editMain.text });
			} else if (editMain.index === "button") {
				await updateDoc(mainRef, {
					button: editMain.text,
				});
				setMain({ ...main, button: editMain.text });
			}
			setEdit("");
			setEditMain({ text: "" });
		} else if (edit === "kids") {
			const kidsRef = doc(db, "dynamic_copies", kids.parentId, edit, kids.id);
			await updateDoc(kidsRef, {
				[editKids.fieldName]: editKids.text,
			}).then(() => {
				setKids({ ...kids, [editKids.fieldName]: editKids.text });
				setEditKids({ text: "", fieldName: "" });
			});
		} else if (edit === "video") {
			if (editVideo.type && editVideo.type === "vimeo" && !editVideo.titulo1.includes("vimeo")) {
				toast.error("El link no es válido");
				return;
			}
			const navbarRef = doc(db, "dynamic_copies", editVideo.parentId, edit, editVideo.id);
			await updateDoc(navbarRef, {
				titulo1: editVideo.titulo1,
			});
			setNavbar({ ...editNavbar });
			setEdit("");
			setEditNavbar({ name: "" });
			toast.success("Los datos se han guardado");
		} else if (edit === "navbar") {
			const navbarRef = doc(db, "dynamic_copies", navbar.parentId, edit, navbar.id);
			await updateDoc(navbarRef, {
				name: editNavbar.name,
			});
			setNavbar({ ...editNavbar });
			setEdit("");
			setEditNavbar({ name: "" });
		} else if (edit === "fechas") {
			const fechasRef = doc(db, "dynamic_copies", fechas.parentId, edit, fechas.id);
			if (editFechas.index === "copie1") {
				await updateDoc(fechasRef, {
					copie1: editFechas.text,
				});
				setFechas({ ...fechas, copie1: editFechas.text });
			} else if (editFechas.index === "fechaInicioInscripcion") {
				await updateDoc(fechasRef, {
					fechaInicioInscripcion: editFechas.text,
				});
				setFechas({ ...fechas, fechaInicioInscripcion: editFechas.text });
			} else if (editFechas.index === "fecha_limite") {
				await updateDoc(fechasRef, {
					fecha_limite: editFechas.text,
				});
				setFechas({ ...fechas, fecha_limite: editFechas.text });
			} else if (editFechas.index === "fecha_limite2") {
				await updateDoc(fechasRef, {
					fecha_limite2: editFechas.text,
				});
				setFechas({ ...fechas, fecha_limite2: editFechas.text });
			} else if (editFechas.index === "red_copie1") {
				await updateDoc(fechasRef, {
					red_copie1: editFechas.text,
				});
				setFechas({ ...fechas, red_copie1: editFechas.text });
			} else if (editFechas.index === "red_copie2") {
				await updateDoc(fechasRef, {
					red_copie2: editFechas.text,
				});
				setFechas({ ...fechas, red_copie2: editFechas.text });
			} else if (editFechas.index === "red_copie3") {
				await updateDoc(fechasRef, {
					red_copie3: editFechas.text,
				});
				setFechas({ ...fechas, red_copie3: editFechas.text });
			} else if (editFechas.index === "header") {
				await updateDoc(fechasRef, {
					header: editFechas.text,
				});
			}
			setEdit("");
			setEditFechas({ text: "" });
			getDocuments();
		} else if (edit === "testimonials") {
			const testimonialRef = doc(db, "testimonials", editTestimonial.id);
			await updateDoc(testimonialRef, {
				name: editTestimonial.name,
				desc: editTestimonial.desc,
			});
			setEdit("");
			setEditTestimonial({ name: "", desc: "" });
			getTestimonials();
		} else if (edit === "faq") {
			const faqRef = doc(db, "faq", editFaq.parentId, "questions", editFaq.id);

			await updateDoc(faqRef, {
				question: editFaq.question,
				answer: editFaq.answer,
			});
			setEdit("");
			setEditFaq({ question: "", answer: "" });
			getFaq();
		} else if (edit === "program") {
			const programRef = doc(db, "dynamic_copies", editProgram.parentId, editProgram.subDoc, editProgram.id);

			await updateDoc(programRef, {
				costo: editProgram.costo,
				duracion: editProgram.duracion,
				final: editProgram.final,
				inversion: editProgram.inversion,
				nombre: editProgram.nombre,
				pagos: editProgram.pagos,
				precio: editProgram.precio,
				url: editProgram.url,
				...(editProgram.extra !== undefined && { extra: editProgram.extra }),
			});
			setEdit("");
			setEditProgram({
				costo: "",
				duracion: "",
				final: "",
				inversion: "",
				nombre: "",
				pagos: "",
				precio: 0,
				url: "",
			});
			getDocuments();
		} else if (edit === "anno") {
			const annoRef = doc(db, "announcement", editAnnouncement.id);

			await updateDoc(annoRef, {
				title: editAnnouncement.title,
				description: editAnnouncement.description,
				active: editAnnouncement.active,
				generationId: editAnnouncement.generationId,
			});

			// if (editAnnouncement.generationId !== "all") {

			const q2 = query(collection(db, "users"), where("generationId", "==", editAnnouncement.generationId));
			const querySnapshot2 = await getDocs(q2);

			let index = 0;
			if (editAnnouncement.active && editAnnouncement.notify) {
				querySnapshot2.forEach(async (user) => {
					await addDoc(collection(db, "notifications"), {
						name: "Academia de Lenguas Internacionales",
						text: "Se ha hecho un nuevo aviso.",
						date: new Date(),
						userId: user.id,
						userType: 2,
						seen: false,
						profileColor: "#000000",
						type: "newAnnouncement",
					});
				});
			}
			setEdit("");
			setEditAnnouncement({ title: "", description: "", active: false, generationId: "all", notify: true });
			getAnnouncement();
		} else if (edit === "filesNuestroPrograma") {
			if (editNuestroPrograma.fileUrl === "" && editNuestroPrograma.fileName !== "") {
				const upload = await firebase
					.storage()
					.ref(`materiales/${uuidv4()}/${editNuestroPrograma.fileName}`)
					.put(editNuestroPrograma.file);
				await upload.ref.getDownloadURL().then(async (res) => {
					const data = {
						name: editNuestroPrograma.fileName,
						linkFile: res,
					};

					const nuestroProgramaRef = doc(db, "dynamic_copies", editNuestroPrograma.id);

					await updateDoc(nuestroProgramaRef, {
						fileName: data.name,
						fileUrl: data.linkFile,
					}).then(() => {
						setNuestroPrograma({ id: editNuestroPrograma.id, fileName: data.name, fileUrl: data.linkFile, file: {} });
						setEdit("");
						setOption(0);
					});
				});
			} else {
				const nuestroProgramaRef = doc(db, "dynamic_copies", editNuestroPrograma.id);

				await updateDoc(nuestroProgramaRef, {
					fileName: editNuestroPrograma.fileName,
					fileUrl: editNuestroPrograma.fileUrl,
				}).then(() => {
					setNuestroPrograma({
						id: editNuestroPrograma.id,
						fileName: editNuestroPrograma.fileName,
						fileUrl: editNuestroPrograma.fileUrl,
						file: editNuestroPrograma.file,
					});
					setEdit("");
					setOption(0);
				});
			}
		}
		setEdit("");
	};

	const listItems = [
		{ name: "Homepage", step: 1 },
		{ name: "Testimonios", step: 2 },
		{ name: "FAQ", step: 3 },
		{ name: "Inscripción", step: 4 },
		{ name: "Pagos", step: 5 },
		{ name: "Aviso", step: 6 },
		{ name: "Nuestro programa", step: 7 },
		{ name: "Precios", step: 8}
	];

	const saveEditCalendar = async (id: string) => {
		if (editedCalendar !== null && editedCalendar.FileName !== ActualCalendar.FileName) {
			if (editedCalendar.fileCalendar !== null) {
				const upload = await firebase
					.storage()
					.ref(`calendar/${uuidv4()}/${editedCalendar.FileName}`)
					.put(editedCalendar.fileCalendar);

				await upload.ref.getDownloadURL().then(async (res) => {
					const newData = {
						FileName: editedCalendar.FileName,
						FileUrl: res,
						name: editedCalendar.name,
					};

					editDataCalendar(id, newData);

					setOption(0);
					setEdit("");
				});
			} else {
				const newData = {
					FileName: editedCalendar.FileName,
					FileUrl: editedCalendar.FileUrl, // Mantenemos el FileUrl existente
					name: editedCalendar.name,
				};

				editDataCalendar(id, newData);

				setOption(0);
				setEdit("");
			}
		} else if (editedCalendar !== null && editedCalendar.name !== "") {
			const newData = {
				FileName: editedCalendar.FileName, // Puedes modificar esto si es necesario
				FileUrl: ActualCalendar.FileUrl, // Mantenemos el FileUrl existente
				name: editedCalendar.name,
			};

			editDataCalendar(id, newData);

			setOption(0);
			setEdit("");
		}
	};
	return (
		<>
			<Toaster />
			<h1>Textos dinámicos</h1>
			<p>Cambia los textos de la página principal.</p>
			<div className="dynamic-text-card-mainbox">
				<div className="dynamic-text-card show">
					<p>Página</p>

					<ul>
						{listItems.map((item) => {
							// Comprueba si previewMode está activo
							if (ispreviewMode === true) {
								// Si está activo, compara con selectedUserPreview
								if (selectedUserPreview?.DinamicText?.includes(item?.name)) {
									return (
										<li
											className={step === item.step ? "active" : ""}
											onClick={() => {
												setStep(item.step);
												item.step === 4 && setOption(0);
											}}
											key={item.name}
										>
											<img src={FolderIcon} alt="folder-icon" /> {item.name}
										</li>
									);
								}
							} else {
								// Si previewMode no está activo, utiliza user
								if (user?.userType !== 4 || user?.dynamicText?.includes(item.name)) {
									return (
										<li
											className={step === item.step ? "active" : ""}
											onClick={() => {
												setStep(item.step);
												item.step === 4 && setOption(0);
											}}
											key={item.name}
										>
											<img src={FolderIcon} alt="folder-icon" /> {item.name}
										</li>
									);
								}
							}

							return null;
						})}
					</ul>
				</div>
				<div className={"dynamic-text-card" + (step !== 0 ? " show" : "")} style={{ marginRight: "auto", marginLeft: "auto" }}>
					<div className="row align-items-center">
						<div className="col-12 col-md-7" style={{ marginTop: "15px" }}>
							<p>Texto</p>
						</div>
						{step === 6 && (
							<div className="col-2 col-md-4 d-flex justify-content-end">
								<FormControl
									as="select"
									id="gen-filter"
									style={{ width: "117px", height: "38px", borderRadius: "20px", cursor: "pointer" }}
									className="me-sm-2"
									onChange={(e) => handleSelect(e.target.value)}
									title={filterEs}
								>
									<option value={"active"}>Activas</option>
									<option value={"all"}>Todas</option>
								</FormControl>
							</div>
						)}
					</div>
					<ul>
						{(option !== 0 || step !== 0) && (
							<>
								<p onClick={goBack} style={{ marginBottom: 10, cursor: "pointer", fontSize: "1rem" }}>
									{"<-Atrás"}
								</p>
							</>
						)}

						{step === 1 && option === 0 && (
							<>
								<li onClick={() => setOption(1)}>
									<img src={FolderIcon} alt="folder-icon" /> Ubicaciones
								</li>
								<li onClick={() => setOption(2)}>
									<img src={FolderIcon} alt="folder-icon" /> Main
								</li>
								<li onClick={() => setOption(3)}>
									<img src={FolderIcon} alt="folder-icon" /> Navbar
								</li>
								<li onClick={() => setOption(4)}>
									<img src={FolderIcon} alt="folder-icon" /> Video
								</li>
								<li onClick={() => setOption(5)}>
									<img src={FolderIcon} alt="folder-icon" /> Niños
								</li>
							</>
						)}

						{step === 1 &&
							option === 1 &&
							location.locations.map((location, i) => {
								return (
									<li
										onClick={() => handleEditLocation(location, i, "locations")}
										className={editLocation.name === location.name ? "active" : ""}
										key={"location-" + i}
									>
										<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {location.name}
									</li>
								);
							})}
						{step === 1 &&
							option === 1 &&
							location.locations2.map((location, i) => {
								return (
									<li
										onClick={() => handleEditLocation(location, i, "locations2")}
										className={editLocation.name === location.name ? "active" : ""}
										key={"location2-" + i}
									>
										<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {location.name}
									</li>
								);
							})}
						{step === 1 && option === 2 && (
							<>
								<li
									onClick={() => handleEditMain(main.titulo1, "titulo1")}
									onChange={(e) => handleChangeMain(e)}
									className={editMain.text === main.titulo1 ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {main.titulo1}
								</li>
								<li
									onClick={() => handleEditMain(main.titulo2, "titulo2")}
									onChange={(e) => handleChangeMain(e)}
									className={editMain.text === main.titulo2 ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {main.titulo2}
								</li>
								<li
									onClick={() => handleEditMain(main.titulo3, "titulo3")}
									onChange={(e) => handleChangeMain(e)}
									className={editMain.text === main.titulo3 ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {main.titulo3}
								</li>
								<li
									onClick={() => handleEditMain(main.button, "button")}
									onChange={(e) => handleChangeMain(e)}
									className={editMain.text === main.button ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {main.button}
								</li>
							</>
						)}
						{step === 1 && option === 3 && (
							<>
								<li
									onClick={() => handleEditNavbar(navbar)}
									onChange={(e) => handleEditNavbar(e)}
									className={editNavbar.name === navbar.name ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {navbar.name}
								</li>
							</>
						)}
						{step === 1 && option === 4 && (
							<>
								{video &&
									video.map((vid, i) => {
										return (
											<li
												onClick={() => handleEditVideo(vid)}
												onChange={(e) => handleEditVideo(e)}
												className={editVideo.titulo1 === vid.titulo1 ? "active" : ""}
												key={vid.titulo1 + i}
											>
												<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" />
												{vid.type ? "Link Landing" : vid.titulo1}
											</li>
										);
									})}
							</>
						)}
						{step === 1 && option === 5 && (
							<>
								<li
									onClick={() => handleEdit("kids", kids.costo, "costo")}
									onChange={(e) => handleEditVideo(e)}
									className={editKids.text === kids.costo ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {kids.costo}
								</li>
								<li
									onClick={() => handleEdit("kids", kids.duracion, "duracion")}
									onChange={(e) => handleEditVideo(e)}
									className={editKids.text === kids.duracion ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {kids.duracion}
								</li>
								<li
									onClick={() => handleEdit("kids", kids.fecha, "fecha")}
									onChange={(e) => handleEditVideo(e)}
									className={editKids.text === kids.fecha ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {kids.fecha}
								</li>
								<li
									onClick={() => handleEdit("kids", kids.horario, "horario")}
									onChange={(e) => handleEditVideo(e)}
									className={editKids.text === kids.horario ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {kids.horario}
								</li>
								<li
									onClick={() => handleEdit("kids", kids.titulo, "titulo")}
									onChange={(e) => handleEditVideo(e)}
									className={editKids.text === kids.titulo ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {kids.titulo}
								</li>
							</>
						)}
						{step === 2 &&
							option === 0 &&
							testimonials.map((testimonial, i) => {
								return (
									<li
										onClick={() => handleEditTestimonial(testimonial, i)}
										className={editTestimonial.name === testimonial.name ? "active" : ""}
										key={"testimonial-" + i}
									>
										<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {testimonial.name}
									</li>
								);
							})}
						{step === 3 && option === 0 && (
							<>
								<li onClick={() => setOption(1)}>
									<img src={FolderIcon} alt="folder-icon" /> Generales
								</li>
								<li onClick={() => setOption(2)}>
									<img src={FolderIcon} alt="folder-icon" /> En línea
								</li>
								<li onClick={() => setOption(3)}>
									<img src={FolderIcon} alt="folder-icon" /> Presencial
								</li>
								<li onClick={() => setOption(4)}>
									<img src={FolderIcon} alt="folder-icon" /> Inscripción y métodos de pago
								</li>
								<li onClick={() => setOption(5)}>
									<img src={FolderIcon} alt="folder-icon" /> Plataforma de aprendizaje
								</li>
							</>
						)}
						{step === 3 &&
							option === 1 &&
							antesEmpezar.map((qa, i) => {
								return (
									<li
										onClick={() => handleEditFaq(qa, "antesEmpezar")}
										className={editFaq.question === qa.question ? "active" : ""}
										key={"antesEmpezar-" + i}
									>
										<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {qa.question}
									</li>
								);
							})}
						{step === 3 &&
							option === 2 &&
							temario.map((qa, i) => {
								return (
									<li
										onClick={() => handleEditFaq(qa, "antesEmpezar")}
										className={editFaq.question === qa.question ? "active" : ""}
										key={"termario-" + i}
									>
										<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {qa.question}
									</li>
								);
							})}
						{step === 3 &&
							option === 3 &&
							seminario.map((qa, i) => {
								return (
									<li
										onClick={() => handleEditFaq(qa, "antesEmpezar")}
										className={editFaq.question === qa.question ? "active" : ""}
										key={"seminario-" + i}
									>
										<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {qa.question}
									</li>
								);
							})}
						{step === 3 &&
							option === 4 &&
							metodos.map((qa, i) => {
								return (
									<li
										onClick={() => handleEditFaq(qa, "antesEmpezar")}
										className={editFaq.question === qa.question ? "active" : ""}
										key={"metodos-" + i}
									>
										<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {qa.question}
									</li>
								);
							})}
						{step === 3 &&
							option === 5 &&
							ninos.map((qa, i) => {
								return (
									<li
										onClick={() => handleEditFaq(qa, "antesEmpezar")}
										className={editFaq.question === qa.question ? "active" : ""}
										key={"ninos-" + i}
									>
										<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {qa.question}
									</li>
								);
							})}
						{step === 4 && option === 0 && (
							<>
								<li
									onClick={() => handleEditFechas(fechas.copie1, "copie1")}
									onChange={(e) => handleChangeMain(e)}
									className={editFechas.text === fechas.copie1 ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {fechas.copie1}
								</li>
								<li
									onClick={() => handleEditFechas(fechas.fechaInicioInscripcion, "fechaInicioInscripcion")}
									onChange={(e) => handleChangeMain(e)}
									className={editFechas.text === fechas.fechaInicioInscripcion ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {fechas.fechaInicioInscripcion}
								</li>
								<li
									onClick={() => handleEditFechas(fechas.fecha_limite, "fecha_limite")}
									onChange={(e) => handleChangeMain(e)}
									className={editFechas.text === fechas.fecha_limite ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {fechas.fecha_limite}
								</li>
								<li
									onClick={() => handleEditFechas(fechas.fecha_limite2, "fecha_limite2")}
									onChange={(e) => handleChangeMain(e)}
									className={editFechas.text === fechas.fecha_limite2 ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {fechas.fecha_limite2}
								</li>
								<li
									onClick={() => handleEditFechas(fechas.red_copie1, "red_copie1")}
									onChange={(e) => handleChangeMain(e)}
									className={editFechas.text === fechas.red_copie1 ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {fechas.red_copie1}
								</li>
								<li
									onClick={() => handleEditFechas(fechas.red_copie2, "red_copie2")}
									onChange={(e) => handleChangeMain(e)}
									className={editFechas.text === fechas.red_copie2 ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {fechas.red_copie2}
								</li>
								<li
									onClick={() => handleEditFechas(fechas.red_copie3, "red_copie3")}
									onChange={(e) => handleChangeMain(e)}
									className={editFechas.text === fechas.red_copie3 ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {fechas.red_copie3}
								</li>
								<li
									onClick={() => handleEditFechas(fechas.header, "header")}
									onChange={(e) => handleChangeMain(e)}
									className={editFechas.text === fechas.header ? "active" : ""}
								>
									<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {fechas.header}
								</li>
							</>
						)}
						{step === 5 && option === 0 && (
							<>
								<li onClick={() => setOption(1)}>
									<img src={FolderIcon} alt="folder-icon" /> Program1
								</li>
								<li onClick={() => setOption(2)}>
									<img src={FolderIcon} alt="folder-icon" /> Program2
								</li>
							</>
						)}
						{step === 5 && option === 1 && (
							<li
								onClick={() => handleEditProgram(program1)}
								onChange={(e) => handleEditProgram(e)}
								className={editProgram.nombre === program1.nombre ? "active" : ""}
							>
								<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {program1.nombre}
							</li>
						)}
						{step === 5 && option === 2 && (
							<li
								onClick={() => handleEditProgram(program2)}
								onChange={(e) => handleEditProgram(e)}
								className={editProgram.nombre === program2.nombre ? "active" : ""}
							>
								<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {program2.nombre}
							</li>
						)}
						{step === 6 && (
							<ul>
								{filteredAnnouncements.map((announcement) => {
									const generation: any = gens.find((e) => e.id === announcement.generationId);
									const generationName = generation ? generation.name : "Nombre Generación no encontrado";
									return (
										<li
											key={announcement.id}
											onClick={() => handleEditAnnouncement(announcement, "antesEmpezar")}
											className={editAnnouncement.id === announcement.id ? "active" : ""}
										>
											<img style={{ width: "1rem" }} src={TextIcon} alt="text-icon" /> {getSubtitle(generationName)}
										</li>
									);
								})}
							</ul>
						)}

						{step === 7 && (
							<>
								<li onClick={() => handleEditNuestroPrograma()} className={option === 1 ? "active" : ""}>
									Archivo pdf
								</li>

								<li onClick={() => handleEditCalendar()} className={option === 2 ? "active" : ""}>
									{ActualCalendar !== null ? ActualCalendar.FileName : "Calendario"}
								</li>
							</>
						)}
						{step === 8 && (
							<PricesStep setEdit={setEdit}/>
						)}
					</ul>
				</div>
				<div className={"dynamic-text-card" + (edit !== "" ? " show" : "")}>
					{edit === "location" && (
						<>
							<LocationsForm
								locationRef={location}
								location={editLocation}
								setEditLocation={() => setEditLocation({ name: "" })}
								setEdit={() => setEdit("")}
							/>
							{/* <div className="editDynamicTextInputContainer">
								<input type="text" value={editLocation.name} name="name" onChange={(e) => handleChangeLocation(e)} />
							</div>
							{editLocation.desc !== undefined && (
								<div className="editDynamicTextInputContainer">
									<input type="text" name="desc" onChange={(e) => handleChangeLocation(e)} value={editLocation.desc} />
								</div>
							)}
							<div>
								<button className="btn-save-dynamic-copies" onClick={saveEdit}>
									Guardar
								</button>
							</div> */}
						</>
					)}
					{edit === "main" && (
						<>
							<div className="editDynamicTextInputContainer">
								<input type="text" value={editMain.text} name={editMain.index} onChange={(e) => handleChangeMain(e)} />
							</div>
							<div>
								<button className="btn-save-dynamic-copies" onClick={saveEdit}>
									Guardar
								</button>
							</div>
						</>
					)}
					{edit === "kids" && (
						<>
							<div className="editDynamicTextInputContainer">
								<input type="text" value={editKids.text} name={editMain.fieldName} onChange={(e) => handleChangeNavbar(e)} />
							</div>
							<div>
								<button className="btn-save-dynamic-copies" onClick={saveEdit}>
									Guardar
								</button>
							</div>
						</>
					)}
					{edit === "video" && (
						<>
							<div className="editDynamicTextInputContainer">
								<input type="text" value={editVideo.titulo1} name={"titulo1"} onChange={(e) => handleChangeVideo(e)} />
							</div>
							<div>
								<button className="btn-save-dynamic-copies" onClick={saveEdit}>
									Guardar
								</button>
							</div>
						</>
					)}
					{edit === "navbar" && (
						<>
							<div className="editDynamicTextInputContainer">
								<input type="text" value={editNavbar.name} name={"titulo1"} onChange={(e) => handleChangeNavbar(e)} />
							</div>
							<div>
								<button className="btn-save-dynamic-copies" onClick={saveEdit}>
									Guardar
								</button>
							</div>
						</>
					)}
					{edit === "fechas" && (
						<>
							<div className="editDynamicTextInputContainer">
								{editFechas.index === "header" ? (
									<textarea
										className="w-100 h-100 border-0"
										rows={8}
										name="header"
										id="header"
										onChange={(e) => handleChangeNavbar(e)}
										value={editFechas.text}
									/>
								) : (
									<input type="text" value={editFechas.text} onChange={(e) => handleChangeNavbar(e)} />
								)}
							</div>
							<div>
								<button className="btn-save-dynamic-copies" onClick={saveEdit}>
									Guardar
								</button>
							</div>
						</>
					)}
					{edit === "testimonials" && (
						<>
							<div className="editDynamicTextInputContainer">
								<input type="text" value={editTestimonial.name} name="name" onChange={(e) => handleChangeNavbar(e)} />
							</div>
							<div className="editDynamicTextInputContainer">
								<input type="text" name="desc" onChange={(e) => handleChangeNavbar(e)} value={editTestimonial.desc} />
							</div>
							<div>
								<button className="btn-save-dynamic-copies" onClick={saveEdit}>
									Guardar
								</button>
							</div>
						</>
					)}
					{edit === "faq" && (
						<>
							<div className="editDynamicTextInputContainer">
								<input type="text" value={editFaq.question} name="question" onChange={(e) => handleChangeNavbar(e)} />
							</div>
							<div className="editDynamicTextInputContainer">
								<input type="text" name="answer" onChange={(e) => handleChangeNavbar(e)} value={editFaq.answer} />
							</div>
							<div>
								<button className="btn-save-dynamic-copies" onClick={saveEdit}>
									Guardar
								</button>
							</div>
						</>
					)}
					{edit === "program" && (
						<>
							<div className="editDynamicTextInputContainer">
								<input type="text" value={editProgram.nombre} name="nombre" onChange={(e) => handleChangeNavbar(e)} />
							</div>
							<div className="editDynamicTextInputContainer">
								<input type="text" name="costo" onChange={(e) => handleChangeNavbar(e)} value={editProgram.costo} />
							</div>
							<div className="editDynamicTextInputContainer">
								<input type="text" name="duracion" onChange={(e) => handleChangeNavbar(e)} value={editProgram.duracion} />
							</div>
							<div className="editDynamicTextInputContainer">
								<input type="text" name="final" onChange={(e) => handleChangeNavbar(e)} value={editProgram.final} />
							</div>
							<div className="editDynamicTextInputContainer">
								<input type="text" name="inversion" onChange={(e) => handleChangeNavbar(e)} value={editProgram.inversion} />
							</div>
							<div className="editDynamicTextInputContainer">
								<input type="text" name="pagos" onChange={(e) => handleChangeNavbar(e)} value={editProgram.pagos} />
							</div>
							<div className="editDynamicTextInputContainer">
								<input type="number" name="precio" onChange={(e) => handleChangeNavbar(e)} value={editProgram.precio} />
							</div>
							<div className="editDynamicTextInputContainer">
								<input type="text" name="url" onChange={(e) => handleChangeNavbar(e)} value={editProgram.url} />
							</div>
							<div className="editDynamicTextInputContainer">
								<input type="text" name="extra" onChange={(e) => handleChangeNavbar(e)} value={editProgram.extra} />
							</div>
							<div>
								<button className="btn-save-dynamic-copies" onClick={saveEdit}>
									Guardar
								</button>
							</div>
						</>
					)}
					{edit === "anno" && (
						<>
							<label htmlFor="title">Título:</label>
							<div className="editDynamicTextInputContainer">
								<input
									type="text"
									value={editAnnouncement.title}
									name="title"
									id="title"
									onChange={(e) => handleChangeNavbar(e)}
								/>
							</div>
							<label htmlFor="description">Descripción:</label>
							<div className="editDynamicTextInputContainer">
								<textarea
									className="w-100 h-100 border-0"
									rows={4}
									name="description"
									id="description"
									onChange={(e) => handleChangeNavbar(e)}
									value={editAnnouncement.description}
								/>
							</div>
							<div className="d-flex align-items-center">
								<input
									id="activo"
									checked={editAnnouncement.active}
									onChange={(e) => setEditAnnouncement({ ...editAnnouncement, active: e.target.checked })}
									type="checkbox"
								/>
								<label className="m-0 ml-2" htmlFor="activo">
									Activo
								</label>
							</div>
							<div className="d-flex align-items-center">
								<input
									id="notify"
									checked={editAnnouncement.notify}
									onChange={(e) => setEditAnnouncement({ ...editAnnouncement, notify: e.target.checked })}
									type="checkbox"
								/>
								<label className="m-0 ml-2" htmlFor="notify">
									Notificar
								</label>
							</div>
							<label className="mt-3" htmlFor="">
								Generación
							</label>
							<select
								className="w-100"
								style={{ borderRadius: 10, padding: 5, marginBottom: 10, color: "black" }}
								name="generationId"
								id="generationId"
								value={editAnnouncement.generationId}
								onChange={(e) => setEditAnnouncement({ ...editAnnouncement, generationId: e.target.value })}
								disabled
							>
								<option value="all">Todos</option>
								{gens.map((gen) => {
									return (
										<option key={gen.id} value={gen.id}>
											{getSubtitle(gen.name)}
										</option>
									);
								})}
							</select>
							<div className="mt-3">
								<button className="btn-save-dynamic-copies" onClick={saveEdit}>
									Guardar
								</button>
							</div>
						</>
					)}
					{edit === "filesNuestroPrograma" && (
						<>
							{editNuestroPrograma.fileUrl !== "" ? (
								<div className="file-nuestro-progama-card">
									<a target="_blank" href={editNuestroPrograma.fileUrl}>
										<span>{editNuestroPrograma.fileName}</span>
									</a>
									<img className="delete-icon-btn" onClick={() => deleteFile()} src={CloseCircleIcon} alt="close-circle-icon" />
								</div>
							) : (
								<>
									{editNuestroPrograma.fileName !== "" && (
										<>
											<div className="file-nuestro-progama-card">
												<span>{editNuestroPrograma.fileName}</span>
												<img
													className="delete-icon-btn"
													onClick={() => deleteFile()}
													src={CloseCircleIcon}
													alt="close-circle-icon"
												/>
											</div>
										</>
									)}
								</>
							)}
							<div className="dropfiles-materiales mb-2">
								<label htmlFor="file">
									<img src={DocumentAttachFileIcon} alt="document-attach-file-icon" />
									<p>Arrastra y suelta el archivo que se mostrara en el apartado de nuestro programa</p>
								</label>
								<input id="file" name="file" type="file" onChange={(e) => handleChangeFile(e)} />
							</div>
							<div className="mt-3">
								<button className="btn-save-dynamic-copies" onClick={saveEdit}>
									Guardar
								</button>
							</div>
						</>
					)}

					{editedCalendar !== null && edit === "Calendar" && (
						<>
							{editedCalendar.FileUrl !== "" ? (
								<div className="file-nuestro-progama-card">
									<a target="_blank" href={editedCalendar.FileUrl}>
										<span>{editedCalendar.FileName}</span>
									</a>
									<img className="delete-icon-btn" onClick={() => deleteFile()} src={CloseCircleIcon} alt="close-circle-icon" />
								</div>
							) : (
								<>
									{editedCalendar.FileName !== "" && (
										<>
											<div className="file-nuestro-progama-card">
												<span>{editedCalendar.FileName}</span>
												<img
													className="delete-icon-btn"
													onClick={() => deleteFileCalendar()}
													src={CloseCircleIcon}
													alt="close-circle-icon"
												/>
											</div>
										</>
									)}
								</>
							)}
							<label htmlFor="title">
								Nombre del archivo <span style={{ fontSize: "10px" }}>(El que verán tus usuarios)</span>
							</label>
							<div className="editDynamicTextInputContainer">
								<input
									type="text"
									defaultValue={editedCalendar.name}
									name="titleCalendar"
									id="titleCalendar"
									onChange={(e) => handleChangeNameCalendar(e)}
								/>
							</div>
							<div className="dropfiles-materiales mb-2">
								<label htmlFor="file">
									<img src={DocumentAttachFileIcon} alt="document-attach-file-icon" />
									<p>Arrastra y suelta el archivo que se mostrara en el apartado de nuestro programa</p>
								</label>
								<input id="file" name="file" type="file" onChange={(e) => handleChangeFileCalendar(e)} />
							</div>
							<div className="mt-3">
								<button
									className="btn-save-dynamic-copies"
									onClick={() => {
										saveEditCalendar(ActualCalendar.id);
									}}
								>
									Guardar
								</button>
							</div>
						</>
					)}
					{edit.includes("prices") && (
						<PricesEdit currency={edit}/>
					)}
				</div>
			</div>
		</>
	);
}

export default DynamicText;
