import { Modal } from "react-bootstrap";
import "./RejectInvoiceModal.scss";
import useInvoices from "./useInvoices";
import { useState } from "react";
import useCollaboratorLogs, { collaborator, user } from "@hooks/collaborators/useCollaboratorLogs";

export function RejectInvoiceModal(props) {
	// const {rejectInvoice, invoices} = useInvoices();
	const [loader, setLoader] = useState<boolean>();
	const { invoiceLog } = useCollaboratorLogs();

	const handleReject = async (e) => {
		setLoader(true);
		e.preventDefault();
		let tempValidation = await props.rejectInvoice(props.invoice, e.target.rejectReason.value);
		const { collaborator, invoice } = props;
		if (tempValidation && collaborator) {
			const tempCollaborator: collaborator = {
				name: collaborator.name,
				id: collaborator.id,
				role: collaborator.collaborator_rol ?? '',
			};

			const tempUser: user = {
				name: invoice.name,
				id: invoice.userId ?? "",
			};

			await invoiceLog("reject", tempCollaborator, tempUser, e.target.rejectReason.value);
		}
		setLoader(false);
		props.onHide();
	};

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			dialogClassName="reject-invoice-dialog"
			contentClassName="reject-invoice-content"
		>
			<Modal.Header closeButton className="ri-modal-header">
				<Modal.Title className="ri-modal-title">
					Rechazar Factura
					<span>¿Cuál es el motivo del rechazo?</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="ri-modal-body">
				<form onSubmit={handleReject}>
					<textarea name="rejectReason" required></textarea>
					<button className={"reject-btn" + (loader === true ? "disabled-btn" : "")} type="submit" disabled={loader}>
						Rechazar Factura
					</button>
				</form>
			</Modal.Body>
		</Modal>
	);
}
