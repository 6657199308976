function ModuleSelectorBar({ moduleSelector, setModuleSelector }) {

    return (
        <div className="module-selector-bar-container">
            <h3>Módulos</h3>
            <div className="module-selector-bar">
                <div className={"cell " + (moduleSelector === 1 ? 'active' : '')} onClick={() => setModuleSelector(1)}>
                    I
                </div>
                <div className={"cell " + (moduleSelector === 2 ? 'active' : '')} onClick={() => setModuleSelector(2)}>
                    II
                </div>
                <div className={"cell " + (moduleSelector === 3 ? 'active' : '')} onClick={() => setModuleSelector(3)}>
                    III
                </div>
                <div className={"cell " + (moduleSelector === 4 ? 'active' : '')} onClick={() => setModuleSelector(4)}>
                    IV
                </div>
                <div className={"cell " + (moduleSelector === 5 ? 'active' : '')} onClick={() => setModuleSelector(5)}>
                    V
                </div>
                <div className={"cell " + (moduleSelector === 0 ? 'active' : '')} onClick={() => setModuleSelector(0)} style={{ borderRight: 0 }}>
                    Todos
                </div>
            </div>
        </div>
    );
}

export default ModuleSelectorBar;