import './styles.scss'

function NextPaymentCard({ nextDate, totalPay }) {

    let modulos;
    let payModulo;

    if (totalPay >= 3000 && totalPay < 7500) {
        modulos = "II";

        payModulo = 7500;
    } else if (totalPay >= 7500 && totalPay < 12000) {
        modulos = "III";
        payModulo = 12000;
    } else if (totalPay >= 12000 && totalPay < 15000) {
        modulos = "IV";
        payModulo = 15000;
    } else if (totalPay >= 15000 && totalPay < 18000) {
        modulos = "V";
        payModulo = 18000;
    } else if (totalPay == 18000) {
        modulos = "0";
        payModulo = 18000;
    } else {
        modulos = "I";
        payModulo = 3000;
    }

    return (
        <div className="next-payment-card-container">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <p className="next-payment-card-right-text next-payment-card-title">Siguiente pago:</p>
                <div className='next-payment-line'>
                    <p className="next-payment-card-right-text">Pago módulo {modulos}, restan: ${(payModulo - totalPay).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} MXN</p>

                </div>
                <p className="next-payment-card-right-text">Fecha sugerida del próximo pago: {nextDate}</p>
            </div>
            <div>
                <p className="next-payment-card-left-text">Importes disponibles:</p>
                {(18000 - totalPay >= 1500 && totalPay % 1000 === 500) && <p className="next-payment-card-left-text">$ 1,500.00 MXN</p>}
                {18000 - totalPay >= 3000 && <p className="next-payment-card-left-text">$3,000.00 MXN</p>}
                {18000 - totalPay >= 4500 && (
                    <div className='question-price-container' style={{display: 'flex', justifyContent: 'flex-end', gap: 10, alignItems: 'center'}}>
                        <div className='question-icon'>
                            ?
                            <div className='info-box'>
                                <p>El módulo II consta de 15 seminarios. <br /> Puedes pagarlo en una sola exhibición o continuar con bloques de 10. <br /> En ese caso tu siguiente pago ($3,000MXN) cubriría los 10 primeros seminarios del módulo II.</p>
                            </div>
                        </div>
                        <p className="next-payment-card-left-text">$4,500.00 MXN</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default NextPaymentCard;