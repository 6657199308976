import React from "react";
import Select, { components, SingleValueProps } from "react-select";
import { useState, useEffect } from "react";
import {
	BrazilFlagPng,
	ChinaFlagPng,
	CloseCircleIcon,
	DownArrowIcon,
	FranceFlagPng,
	GermanyFlagPng,
	ItalyFlagPng,
	JapanFlagPng,
	RussiaFlagPng,
	SearchIcon,
	UnitedStatesFlagPng,
} from "assets/Imports";

const MultiSelect = ({ words, selectedLanguages, setSelectedLang }) => {
	const availableLanguages = [
		{ value: "french", label: "Francés", image: FranceFlagPng },
		{ value: "german", label: "Alemán", image: GermanyFlagPng },
		{ value: "italian", label: "Italiano", image: ItalyFlagPng },
		{ value: "japanese", label: "Japonés", image: JapanFlagPng },
		{ value: "chinese", label: "Chino", image: ChinaFlagPng },
		{ value: "portuguese", label: "Portugués", image: BrazilFlagPng },
		{ value: "russian", label: "Ruso", image: RussiaFlagPng },
		{ value: "english", label: "Inglés", image: UnitedStatesFlagPng },
	];

	
	const Option = (props) => (
		<components.Option {...props}>
			<div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "20px" }}>
				<img src={props.data.image} alt="logo" width={18} height={18} />
				{props.label}
			</div>
		</components.Option>
	);

	const filteredOptions = availableLanguages.filter((option) => {
		switch (option.value) {
			case "french":
				return words.french.length > 0;
			case "german":
				return words.german.length > 0;
			case "italian":
				return words.italian.length > 0;
			case "japanese":
				return words.japanese.length > 0;
			case "chinese":
				return words.chinese.length > 0;
			case "portuguese":
				return words.portuguese.length > 0;
			case "russian":
				return words.russian.length > 0;
			case "english":
				return words.english.length > 0;
			default:
				return true;
		}
	});

	const SingleValue = ({ children, ...props }: SingleValueProps<any, true, any>) => (
		<components.SingleValue {...props}>
			{selectedLanguages.map((selectedLang) => (
				<div key={selectedLang.value}>
					<img src={selectedLang.image} alt="s-logo" className="selected-logo" />
				</div>
			))}

			{children}
		</components.SingleValue>
	);

	const handleLanguageChange = (selectedOptions) => {
		const selectedValues = selectedOptions.map((data) => data.value);
		setSelectedLang(selectedValues);
	};

	useEffect(() => {
		// Verifica si el array de lenguajes seleccionados está vacío
		if (selectedLanguages.length === 0) {
			setSelectedLang(["all"]);
		}
	}, [selectedLanguages, availableLanguages]);

	return (
		<Select
			placeholder="Selecciona un idioma...."
			defaultValue={"all"}
			isMulti
			name="languages"
			options={filteredOptions}
			className="basic-multi-select"
			classNamePrefix="select"
			styles={{
				singleValue: (base) => ({
					...base,
					display: "flex",
					alignItems: "center",
				}),
			}}
			components={{ SingleValue, Option }}
			onChange={handleLanguageChange}
		/>
	);
};

export default MultiSelect;
