import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { db } from "initFirebase";
import { addDoc, collection, getDocs, query, updateDoc, doc, getDoc } from "firebase/firestore";
import "./FormStyle.scss";
import { whichIsWrongForm1 } from "functions/Forms/FormsFunctions";

function FormContainer() {
	const windowUrl = window.location.search;
	const params = new URLSearchParams(windowUrl);
	const genId: any = params.get("id") !== null ? params.get("id") : "No existe";
	const [validated, setValidated] = useState(false);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const [haveStudied, setHaveStudied] = useState("false");
	const [speakOtherLang, setSpeakOtherLang] = useState("false");
	const [haveCertification, setHaveCertification] = useState("false");
	const [genName, setGenName] = useState("No existe");

	useEffect(() => {
		if (genId !== "No existe") {
			getGen();
		}
	}, []);

	const getGen = async () => {
		const genRef = doc(db, "generations", genId);
		const docSnap = await getDoc(genRef);

		if (docSnap.exists()) {
			setGenName(docSnap.data().name);
		} else {
			// doc.data() will be undefined in this case
			console.log("No such document!");
		}
	};

	// useEffect(() => {
	//     changeUsers();
	// }, [])

	const handleSubmit = async (event) => {
		setLoading(true);
		const form = event.currentTarget;
		let userId = "No existe";
		let generationId = genId;
		let generationName = genName;
		let matricula = "No existe";

		if (form.checkValidity() === false) {
			event.preventDefault();
			setLoading(false);
			event.stopPropagation();
			whichIsWrongForm1(form);
		} else {
			event.preventDefault();
			await db
				.collection("users")
				.where("email", "==", form.email.value)
				.get()
				.then(async (snap) => {
					if (snap.docs.length > 0) {
						userId = snap.docs[0].id;
						generationId = snap.docs[0].data().generationId;
						matricula = snap.docs[0].data().matricula;
						var docRef = db.collection("generations").doc(snap.docs[0].data().generationId);
						await docRef
							.get()
							.then((doc: any) => {
								if (doc.exists) {
									generationName = doc.data().name;
								} else {
									// doc.data() will be undefined in this case
									console.log("No such document!");
								}
							})
							.catch((error) => {
								console.log("Error getting document:", error);
							});
					}
				})
				.then(async () => {
					const docRef = await addDoc(collection(db, "forms"), {
						email: form.email.value,
						name: form.name.value,
						firstLastName: form.firstLastName.value,
						secondLastName: form.secondLastName.value,
						profession: form.profession.value,
						cellphone: form.cellphone.value,
						secondaryEmail: form.secondaryEmail.value,
						birthdate: form.birthdate.value,
						gender: form.gender.value,
						haveStudied: form.haveStudied.value === "true" ? true : false,
						whichOnes: form.whichOnes.value,
						whereStudied: form.whereStudied.value,
						speakOtherLang: form.speakOtherLang.value === "true" ? true : false,
						whichLangs: form.whichLangs.value,
						haveCertification: form.haveCertification.value === "true" ? true : false,
						whichCertification: form.whichCertification.value,
						academiaKnown: form.academiaKnown.value,
						reasonToLearn: form.reasonToLearn.value,
						favoritesLangs: form.favoritesLangs.value,
						timeOfStudy: form.timeOfStudy.value,
						userId: userId,
						generationId: generationId,
						generationName: generationName,
						matricula: matricula,
						createdAt: new Date().toLocaleString(),
					}).then(() => {
						if (userId !== "No existe") {
							console.log("con userid");
							var docRef = db
								.collection("users")
								.doc(userId)
								.update({
									formAnswered: true,
								})
								.then(() => {
									alert("Formulario enviado");
									history.push("/");
								});
						} else {
							console.log("sinuserId");
							alert("Formulario enviado");
							history.push("/");
						}
					});
				});
		}

		setValidated(true);
	};

	return (
		<div className="form-main-container">
			<div className="modal-form-header-container">
				<h2 className="form-title">Formulario</h2>
				<div className="steps-circles-container">
					<div onClick={() => setStep(1)} className={"circle " + (step >= 1 ? "active" : "")}>
						<h3>1</h3>
					</div>
					<div onClick={() => setStep(2)} className={"circle " + (step >= 2 ? "active" : "")}>
						<h3>2</h3>
					</div>
					<div onClick={() => setStep(3)} className={"circle " + (step >= 3 ? "active" : "")}>
						<h3>3</h3>
					</div>
				</div>
			</div>
			<p style={{ margin: 0, textAlign: "end" }}>
				<a className="obligatory">*</a> Campos obligatorios.
			</p>
			{step === 1 && <p className="font-weight-bold text-center">Datos personales</p>}
			{step === 2 && <p className="font-weight-bold text-center">Datos de contacto</p>}
			{step === 3 && <p className="font-weight-bold text-center">Cuéntanos sobre ti</p>}
			<Form id="my-form" noValidate validated={validated} onSubmit={handleSubmit}>
				<Row style={step === 1 ? { display: "flex" } : { display: "none" }}>
					<Col>
						<Row>
							<Form.Group as={Col} controlId="validationCustom02">
								<Form.Label>
									<a className="obligatory">*</a>Nombre:
								</Form.Label>
								<Form.Control required type="text" name="name" placeholder="Nombre" />
								<Form.Control.Feedback type="invalid">Por favor ingresa un nombre.</Form.Control.Feedback>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} controlId="validationCustom03">
								<Form.Label>
									<a className="obligatory">*</a>Apellido paterno:
								</Form.Label>
								<Form.Control required type="text" name="firstLastName" placeholder="Apellido paterno" />
								<Form.Control.Feedback type="invalid">Por favor ingresa tu apellido paterno.</Form.Control.Feedback>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} controlId="validationCustom04">
								<Form.Label>
									<a className="obligatory">*</a>Apellido materno:
								</Form.Label>
								<Form.Control required type="text" name="secondLastName" placeholder="Apellido materno" />
								<Form.Control.Feedback type="invalid">Por favor ingresa tu apellido materno.</Form.Control.Feedback>
							</Form.Group>
						</Row>
					</Col>
					<Col>
						<Row>
							<Form.Group as={Col} controlId="validationCustom05">
								<Form.Label>Ocupación:</Form.Label>
								<Form.Control type="text" className="notObligatoryInput" name="profession" placeholder="Profesión" />
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} controlId="validationCustom08">
								<Form.Label>Fecha de nacimiento:</Form.Label>
								<Form.Control type="date" name="birthdate" className="notObligatoryInput" />
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} controlId="validationCustom18">
								<Form.Label>
									<a className="obligatory">*</a>Género:
								</Form.Label>
								<Form.Check label="Hombre" type="radio" value="hombre" required name="gender" />
								<Form.Check label="Mujer" type="radio" value="mujer" required name="gender" />
								<Form.Check label="Sin especificar" type="radio" value="sin especificar" required name="gender" />
							</Form.Group>
						</Row>
					</Col>
				</Row>
				<Row style={step === 2 ? { display: "flex" } : { display: "none" }}>
					<Col>
						<Row>
							<Form.Group as={Col} controlId="validationCustom01">
								<Form.Label>
									<a className="obligatory">*</a>Correo:
								</Form.Label>
								<Form.Control required type="email" name="email" placeholder="Correo" />
								<Form.Control.Feedback type="invalid">Ingresa un correo Válido.</Form.Control.Feedback>
							</Form.Group>
						</Row>
						<Row>
							{/*  Añadiremos este número a un grupo de WhatsApp, en donde te compartiremos los enlaces de Zoom y el material de trabajo */}
							<Form.Group as={Col} controlId="validationCustom06">
								<Form.Label style={{ width: "100%" }}>
									<div style={{ display: "flex", justifyContent: "space-between", position: "relative" }}>
										<p className="cellularStyle">
											<a className="obligatory">*</a>Teléfono de contacto (WhatsApp)
										</p>
										<div className="question-icon" style={{lineHeight:'21px'}}>
											?
											<div className="info-box">
												<p>Añadiremos este número a un grupo de WhatsApp, en donde te compartiremos los enlaces de Zoom y el material de trabajo</p>
											</div>
										</div>
									</div>
								</Form.Label>
								<Form.Control required type="text" minLength={10} name="cellphone" placeholder="Número de celular" />
								<Form.Control.Feedback type="invalid">Por favor ingresa un celular válido.</Form.Control.Feedback>
							</Form.Group>
						</Row>
					</Col>
					<Col>
						<Row>
							{/* Daremos de alta esta cuenta para que tengas acceso a las grabaciones de los seminarios */}
							<Form.Group as={Col} controlId="validationCustom07">
								<Form.Label style={{ width: "100%" }}>
									<div style={{ display: "flex", justifyContent: "space-between", position: "relative" }}>
										Correo Gmail
										<div className="question-icon" style={{lineHeight:'21px'}}>
											?
											<div className="info-box">
												<p>Daremos de alta esta cuenta para que tengas acceso a las grabaciones de los seminarios.</p>
											</div>
										</div>
									</div>
								</Form.Label>
								<Form.Control type="email" name="secondaryEmail" className="notObligatoryInput" placeholder="Correo electrónico (GMAIL)" />
							</Form.Group>
						</Row>
					</Col>
				</Row>
				<Row style={step === 3 ? { display: "flex" } : { display: "none" }}>
					<Col>
						<Row>
							<Form.Group as={Col} controlId="validationCustom15">
								<Form.Label>
									<a className="obligatory">*</a>¿Cómo conociste la Academia?
								</Form.Label>
								<Form.Control required type="text" placeholder="Tu respuesta" name="academiaKnown" />
								<Form.Control.Feedback type="invalid">Por favor ingresa una respuesta.</Form.Control.Feedback>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} controlId="validationCustom16">
								<Form.Label>
									<a className="obligatory">*</a>Razón por la que te interesa convertirte en políglota
								</Form.Label>
								<Form.Control required type="text" placeholder="Tu razón" name="reasonToLearn" />
								<Form.Control.Feedback type="invalid">Por favor ingresa una respuesta.</Form.Control.Feedback>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} controlId="validationCustom17">
								<Form.Label>Idioma(s) favorito(s)</Form.Label>
								<Form.Control type="text" placeholder="Tu(s) favorito(s)" className="notObligatoryInput" name="favoritesLangs" />
							</Form.Group>
						</Row>
					</Col>
					<Col>
						<Row>
							<Form.Group as={Col} controlId="validationCustom09">
								<Form.Label>
									<a className="obligatory">*</a>¿Has estudiado algún idioma?
								</Form.Label>
								<Form.Check label="Sí" type="radio" value="true" required onChange={(e) => setHaveStudied(e.target.value)} name="haveStudied" />
								<Form.Check label="No" type="radio" value="false" required onChange={(e) => setHaveStudied(e.target.value)} name="haveStudied" />
							</Form.Group>
						</Row>
						<Row style={haveStudied === "true" ? { display: "flex" } : { display: "none" }}>
							<Form.Group as={Col} controlId="validationCustom19">
								<Form.Label>¿Cuáles?</Form.Label>
								<Form.Control type="text" placeholder="Tu respuesta" name="whichOnes" />
							</Form.Group>
						</Row>
						<Row style={haveStudied === "true" ? { display: "flex" } : { display: "none" }}>
							<Form.Group as={Col} controlId="validationCustom10">
								<Form.Label>¿Dónde?</Form.Label>
								<Form.Control type="text" placeholder="Tu respuesta" className="notObligatoryInput" name="whereStudied" />
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} controlId="validationCustom11">
								<Form.Label>
									<a className="obligatory">*</a>¿Hablas algún idioma extranjero?
								</Form.Label>
								<Form.Check label="Sí" type="radio" value="true" required onChange={(e) => setSpeakOtherLang(e.target.value)} name="speakOtherLang" />
								<Form.Check
									label="No"
									type="radio"
									value="false"
									required
									onChange={(e) => setSpeakOtherLang(e.target.value)}
									name="speakOtherLang"
								/>
							</Form.Group>
						</Row>
						<Row style={speakOtherLang === "true" ? { display: "flex" } : { display: "none" }}>
							<Form.Group as={Col} controlId="validationCustom12">
								<Form.Label>¿Cuáles?</Form.Label>
								<Form.Control type="text" placeholder="Cuáles" className="notObligatoryInput" name="whichLangs" />
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} controlId="validationCustom13">
								<Form.Label>
									<a className="obligatory">*</a>¿Cuentas con alguna certificación?
								</Form.Label>
								<Form.Check
									label="Sí"
									type="radio"
									value="true"
									required
									onChange={(e) => setHaveCertification(e.target.value)}
									name="haveCertification"
								/>
								<Form.Check
									label="No"
									type="radio"
									value="false"
									required
									onChange={(e) => setHaveCertification(e.target.value)}
									name="haveCertification"
								/>
							</Form.Group>
						</Row>
						<Row style={haveCertification === "true" ? { display: "flex" } : { display: "none" }}>
							<Form.Group as={Col} controlId="validationCustom14">
								<Form.Label>¿Cuáles?</Form.Label>
								<Form.Control type="text" placeholder="Cuáles" className="notObligatoryInput" name="whichCertification" />
							</Form.Group>
						</Row>
					</Col>
				</Row>
				<Row style={step === 3 ? { display: "flex" } : { display: "none" }}>
					<Form.Group as={Col} controlId="validationCustom20">
						<Form.Label>¿Cuánto tiempo de estudio tienes planeado dedicarle al estudio de los idiomas entre semana?</Form.Label>
						<Form.Control type="text" placeholder="Tu respuesta" className="notObligatoryInput" name="timeOfStudy" />
					</Form.Group>
				</Row>
				<Row className="buttons-row-container" style={{ padding: "0 15px" }}>
					{step > 1 && (
						<button className="tertiary" type="button" style={{ marginRight: "auto" }} onClick={() => setStep(step - 1)}>
							Atrás
						</button>
					)}
					{step < 3 && (
						<button className="tertiary" type="button" style={{ marginLeft: "auto" }} onClick={() => setStep(step + 1)}>
							Continuar
						</button>
					)}
					{step === 3 && (
						<>
							{!loading && (
								<button form="my-form" className="tertiary return-btn-style" type="submit">
									Finalizar
								</button>
							)}
							{loading && (
								<div className="spinner-border text-danger" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							)}
						</>
					)}
				</Row>
			</Form>
		</div>
	);
}

export default FormContainer;
