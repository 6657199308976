import FileSaver from "file-saver";
import * as XLSX from 'xlsx/xlsx.mjs';

interface ExportToExcelProps {
  csvData: object[];
  headers: string[][];
  fileName?: string;
}

const ExportToExcelDefaultProps: ExportToExcelProps = {
  csvData: [{}],
  headers: [[""]],
  fileName: "Archivo de Excel",
};

const ExportToExcel: React.FunctionComponent<ExportToExcelProps> = (props) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const handleClick = () => {
    var headerKeysToLabels: object[];
    var headerKeys: string[];
    headerKeysToLabels = [{}];
    headerKeys = [];

    for (let i = 0; i < props.headers.length; i++) {
      const keyNLabel = props.headers[i];
      headerKeys.push(keyNLabel[0]);
      headerKeysToLabels[0][keyNLabel[0]] = keyNLabel[1]
        ? keyNLabel[1]
        : keyNLabel[0];
    }

    const ws = XLSX.utils.json_to_sheet(headerKeysToLabels, {
      header: headerKeys,
      skipHeader: true,
    });

    ws["!cols"] = [];

    for (let i = 0; i < headerKeys.length; i++) {
      const key = headerKeys[i];
      if (
        props.csvData[0] !== undefined &&
        props.csvData[0][key] !== undefined
      ) {
        ws["!cols"].push({
          wch: Math.max(
            ...props.csvData.map((item) => item[key] === undefined ? 1 : item[key].toString().length),
            headerKeysToLabels[0][headerKeys[i]].length
          ),
        });
      } else {
        ws["!cols"].push({ wch: headerKeysToLabels[0][headerKeys[i]].length });
      }
    }

    XLSX.utils.sheet_add_json(ws, props.csvData, {
      header: headerKeys,
      skipHeader: true,
      origin: -1,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, props.fileName + fileExtension);
  };

  return (
    <>
      <a onClick={(e) => handleClick()}>
        {props.children ? (
          props.children
        ) : (
          <button className="tertiary">Descargar</button>
        )}
      </a>
    </>
  );
};
ExportToExcel.defaultProps = ExportToExcelDefaultProps;

export default ExportToExcel;
