import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { db } from 'initFirebase';

export const NotifyUsersConsultPost = async (seminaryNum, consultPost, consultId, profileColor) => {

    const q = query(collection(db, "generations"));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (gen) => {
        const pastSeminary = new Array();
        const nextSeminary = new Array();
        const allSeminaries = new Array();
        const actualDate = new Date();
        actualDate.setHours(0, 0, 0);

        const g = query(collection(db, "generations", gen.id, "material"));

        const seminariesSnap = await getDocs(g);

        seminariesSnap.forEach((seminary) => {
            const seminaryDate = new Date();
            const dateArray = seminary.data().date.split("/");

            seminaryDate.setDate(dateArray[0]);
            seminaryDate.setMonth(dateArray[1]);
            seminaryDate.setFullYear(dateArray[2]);
            seminaryDate.setHours(0, 0, 0);

            allSeminaries.push({ id: seminary.id, ...seminary.data() });
            if (seminaryDate <= actualDate) {
                pastSeminary.push({ id: seminary.id, ...seminary.data() });
            } else {
                nextSeminary.push({ id: seminary.id, ...seminary.data() });
            }
        });


        nextSeminary.sort(compare2);
        pastSeminary.sort(compare2);

        const u = query(collection(db, "users"), where("generationId", "==", gen.id));

        const usersSnap = await getDocs(u);


        usersSnap.forEach(async (user) => {
            let totalPay = 0;
            let paidSeminaries = 0;
            const payments = new Array();

            if (user.data().promotion) {
                totalPay = 18000;
                paidSeminaries = 60;
            } else {
                const p = query(collection(db, "paymentHistory"), where("userId", "==", user.id), where("verified", "==", true));
                const paymentSnap = await getDocs(p);

                if (paymentSnap.size === 0) {
                } else {
                    paymentSnap.forEach((payment) => {
                        payments.push({ id: payment.id, ...payment.data() });
                        totalPay = totalPay + parseInt(payment.data().amount);
                    });
                }
            }

            if ((totalPay === 12000 || totalPay === 15000) && payments.length === 1) {
                paidSeminaries = 60;
            } else {
                paidSeminaries = Math.floor(totalPay / 300);
            }


            if (nextSeminary.length > 0) {
                if (pastSeminary[pastSeminary.length - 1] !== undefined && pastSeminary[pastSeminary.length - 1].num >= seminaryNum && paidSeminaries >= seminaryNum) {
                    if (user.data().userType !== 2) {
                        await addDoc(collection(db, 'notifications'), {
                            name: "Academia de Lenguas Internacionales",
                            text: consultPost,
                            date: new Date(),
                            userId: user.id,
                            consultId: consultId,
                            consultAnswerId: null,
                            userType: 2,
                            seen: false,
                            profileColor: profileColor,
                            genId: null,
                            type: 'publication',
                            seminaryNum: seminaryNum,
                        });
                    }
                }
            }

        });
    });

}


function compare2(a, b) {
    if (a.num < b.num) {
        return -1;
    }
    if (a.num > b.num) {
        return 1;
    }
    return 0;
}