import {
	addDoc,
	collection,
	doc,
	getDoc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { db } from "initFirebase";
import Facturapi from "facturapi";
import { getGenerationNameById } from "./GenerationService";
import emailjs from "emailjs-com";
// PROD
const ApiKey = "sk_live_p5lozky2nB8J0OwV7RZ6JzK4mB7jZK4Ar9v3YamX1N";
//TEST
//const ApiKey = "sk_test_WxOVo1rY8RGgm6AQenxaZjb4MK3bdplPJyaqXDZ290";

const paymentFormText = {
	"01": "Efectivo",
	"02": "Cheque nominativo",
	"03": "Transferencia electrónica de fondos",
	"04": "Tarjeta de crédito",
	"05": "Monedero electrónico",
	"06": "Dinero electrónico",
	"08": "Vales de despensa",
	"12": "Dación en pago",
	"13": "Pago por subrogación",
	"14": "Pago por consignación",
	"15": "Condonación",
	"17": "Compensación",
	"23": "Novación",
	"24": "Confusión",
	"25": "Remisión de deuda",
	"26": "Prescripción o caducidad",
	"27": "A satisfacción del acreedor",
	"28": "Tarjeta de débito",
	"29": "Tarjeta de servicios",
	"30": "Aplicación de anticipos",
	"31": "Intermediario pagos",
	"99": "Por definir",
};
export interface FirebaseDate {
	nanoseconds: number;
	seconds: number;
}

const dateOptions: any = {
	day: "numeric",
	month: "long",
	year: "numeric",
};

export interface InvoiceData {
	createdAt: Date; // Date of the creation of the invoice doc in firebase, despite any means of creation
	ApiId?: string;
	userId?: string;
	userGen?: string; // User generation at the moment of the request of invoice
	userGenName?: string;
	apiCreatedAt?: Date; // register date when generating, created_at (invoice object)
	requestDate?: Date | FirebaseDate; // Date that this invoice was requested by the user
	issueDate?: Date; // Fecha de expedicion, date (invoice object)
	acceptedDate?: Date | FirebaseDate;
	rejectedDate?: Date | FirebaseDate;
	rejectReason?: string;
	fiscalRegime: string;
	street: string;
	city: string;
	state: string; // State in mexico
	paymentReceiptLink?: string;
	fiscalSituationReceiptLink?: string;
	name: string; // Name entered in the form
	email: string;
	paymentForm: string; // In the form, "Metodo de pago"
	use: string; // Uso de factura
	rfc: string;
	zipCode: string;
	corporateName: string; // In form, "Razon Social"
	amount: number; // In form, "Monto de factura"
	netPrice?: number; // "Precio Neto"
	status: string;
	matricula?: string;
	id?: string;
	canceledDate?: Date | FirebaseDate;
	ticketNumber?: number; //Numero de folio interno (api)
	paymentId?: string; // if the invoice is autogenerated, there will be a previously approved payment associated with it.
}

export interface InvoiceApiData {
	legal_name: string;
	tax_id: string;
	tax_system: string;
	zip: string;
	street: string;
	city: string;
	state: string;
	email: string;
	product_description: string;
	product_key: string;
	price: number;
	payment_form: string;
	use: string;
}

export async function checkForPendingInvoice(userId: string) {}

export async function getFbInvoiceByPaymentId(paymentId: string) {
	const q = query(collection(db, "invoices"), where("paymentId", "==", paymentId), orderBy("createdAt", "desc"), limit(1));
	const qSnap = await getDocs(q);
	let invoice;
	if (!qSnap.empty) {
		invoice = qSnap.docs[0].data();
	} else {
		invoice = "";
	}
	return invoice;
}

export async function getInvoiceStatusByPaymentId(paymentId: string) {
	const q = query(collection(db, "invoices"), where("paymentId", "==", paymentId), orderBy("createdAt", "desc"), limit(1));
	const qSnap = await getDocs(q);
	let status: string;
	if (!qSnap.empty) {
		status = qSnap.docs[0].data().status;
	} else {
		status = "";
	}
	return status;
}

export async function sendInvoiceByEmail(invoiceApi: any, invoiceForm: InvoiceData) {
	const pdfName: string = `FACTURA - ${invoiceForm.matricula ?? ""} - ${invoiceApi.folio_number}.pdf`;

	fetch(`https://www.facturapi.io/v2/invoices/${invoiceApi.id}/pdf`, {
		headers: {
			Authorization: `Bearer ${ApiKey}`,
		},
	})
		.then((response) => response.blob())
		.then(async (blob) => {
			const reader = new FileReader();
			let base64String;
			const currentDate = new Date();
			const day = currentDate.getDate().toString().padStart(2, "0");
			const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
			const year = currentDate.getFullYear();
			const now = `${day}/${month}/${year}`;
			reader.readAsDataURL(blob);
			reader.onloadend = function () {
				base64String = reader.result;
				const emailParams = {
					email: invoiceForm.email,
					filename: pdfName,
					content: base64String,
					name: invoiceForm.name,
					rfc: invoiceApi.customer.tax_id,
					ticket: invoiceApi.folio_number,
					corporateName: invoiceForm.corporateName.toUpperCase(),
					state: invoiceForm.state,
					fiscalRegime: invoiceForm.fiscalRegime,
					date: now,
					street: invoiceForm.street,
					invoiceEmail: invoiceForm.email,
					zipCode: invoiceForm.zipCode,
					use: invoiceForm.use,
					city: invoiceForm.city,
					paymentForm: paymentFormText[invoiceForm.paymentForm],
					netPrice: invoiceForm.netPrice,
					...(invoiceForm.netPrice ? { iva: (invoiceForm.amount - invoiceForm.netPrice).toFixed(2) } : {}),
					iAmount: invoiceForm.amount,
					matricula: invoiceForm.matricula ?? "",
				};
				emailjs.send("service_1kfzhtl", "template_vzsz96m", emailParams, "user_PPI0O8nofs9cbuJ3JRWlT");
			};
		})
		.catch((error) => {
			console.error(error);
		});
}

export async function cancelInvoiceApi(invoiceApiId: string) {
	const facturapi = new Facturapi(ApiKey);
	return facturapi.invoices
		.cancel(invoiceApiId, { motive: "03" })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			alert("Ocurrió un error al intentar cancelar la factura");
			return null;
		});
}

export async function downloadInvoicePDF(invoiceApiId: string, ticketNumber?: number, matricula?: string, format?: string) {
	const pdfName: string = "FACTURA - " + (matricula ?? "") + " - " + (ticketNumber ?? "");
	
	fetch(`https://www.facturapi.io/v2/invoices/${invoiceApiId}/${format}`, {
		headers: {
			Authorization: `Bearer ${ApiKey}`,
		},
	})
		.then((response) => {
			if (response.status !== 200) {
				alert("Por el momento este servicio no está disponible. Prueba más tarde.");
				return null;
			}
			return response.blob();
		})
		.then(async (blob) => {
			if (blob !== null) {
				const url = URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.download = pdfName;
				document.body.appendChild(link);
				link.click();
			}
		})
		.catch((error) => {
			alert("Por el momento este servicio no está disponible. Prueba más tarde.");
		});
}

export async function addGenNameFB(invoiceId: string, genId: string) {
	const docRef = doc(db, "invoices", invoiceId);
	let tempGen = await getGenerationNameById(genId);
	if (tempGen) {
		let formattedGenName = tempGen.replace("Programa de Alto Rendimiento en 8 Idiomas Simultáneos - ", "");
		await updateDoc(docRef, { userGenName: formattedGenName });
	}
}

export async function generateInvoice(data: InvoiceApiData) {
	const facturapi = new Facturapi(ApiKey);

	try {
		const invoice = await facturapi.invoices.create({
			customer: {
				legal_name: data.legal_name,
				email: data.email,
				tax_id: data.tax_id,
				tax_system: data.tax_system,
				address: {
					zip: data.zip,
					street: data.street,
					city: data.city,
					state: data.state,
				},
			},
			use: data.use,
			items: [
				{
					product: {
						description: data.product_description,
						product_key: data.product_key,
						price: data.price,
					},
				},
			],
			payment_form: data.payment_form,
		});
		return invoice;
	} catch (error) {
		alert(
			"Ocurrió un error al intentar generar la factura, revisa que los campos de RFC, razón social, código postal y régimen fiscal sean correctos, o vuelve a intentar más tarde. \nTambién verificar que el uso de CFDI coincida con el régimen fiscal."
		);
		return null;
	}
}

export async function uploadInvoiceFireBase(data: InvoiceData) {
	const docRef = await addDoc(collection(db, "invoices"), data);
	if (docRef !== undefined) {
		return docRef.id;
	} else {
		alert("Hubo un error al registrar la factura en la base de datos");
		return null;
	}
}

export async function pendingInvoicesCount() {
	const q = query(collection(db, "invoices"), where("status", "==", "Pending"));
	const qSnap = await getDocs(q);
	if (qSnap.size > 0) {
		return qSnap.size;
	} else {
		return 0;
	}
}

export async function getAllInvoices() {
	// Get all invoices available
	const q = query(collection(db, "invoices"), orderBy("createdAt", "desc"));
	const qSnap = await getDocs(q);
	let invoices: any = [];
	if (qSnap.size > 0) {
		qSnap.docs.map((invoice, index) => {
			let data = { id: invoice.id, ...invoice.data() };
			invoices.push(data);
		});
		return invoices;
	} else {
		return;
	}
}

export async function totalInvoicesCount() {
	const facturapi = new Facturapi(ApiKey);
	const invoiceSearch = await facturapi.invoices.list();
	if (invoiceSearch) {
		return invoiceSearch.data.length;
	}
}

export async function invoicesLeftCount() {
	const facturapi = new Facturapi(ApiKey);
	const availableInvoicesInPlan: number = 150;
	const paymentDate: number = 8;
	let now = new Date();
	let nowDate = new Date().getDate();
	let initDate: Date = new Date();
	let endDate: Date = new Date();
	if (nowDate >= paymentDate) {
		initDate.setDate(paymentDate);
		endDate.setDate(paymentDate - 1);
		endDate.setMonth(now.getMonth() + 1);
		initDate.setHours(0, 0, 0, 0);
	} else {
		initDate.setHours(0, 0, 0, 0);
		initDate.setMonth(now.getMonth() - 1);
		initDate.setDate(paymentDate);
		endDate.setDate(paymentDate - 1);
	}

	let invoicesLeft: number = 0;
	await facturapi.invoices
		.list({
			date: {
				gte: initDate,
				lte: endDate,
			},
		})
		.then((res) => {
			invoicesLeft = res.total_results;
		});

	return availableInvoicesInPlan - invoicesLeft;
}

export async function updateDbStatus(
	newStatus: string,
	id: string,
	rejectReasonText?: string,
	apiId?: string,
	TicketNumber?: number
) {
	const docRef = doc(db, "invoices", id);
	await updateDoc(docRef, {
		status: newStatus,
		...(rejectReasonText ? { rejectReason: rejectReasonText } : {}),
		...(newStatus === "Rejected" ? { rejectedDate: new Date() } : {}),
		...(newStatus === "Accepted" ? { acceptedDate: new Date(), ApiId: apiId, ticketNumber: TicketNumber } : {}),
		...(newStatus === "Canceled" ? { acceptedDate: "", canceledDate: new Date() } : {}),
	}).then(() => {
		alert("Estatus actualizado con éxito");
	});
}

export async function updateDbPaymentStatus(newStatus: string, id: string) {
	// This function updates the status of a payment relating to its respective invoice.
	const docRef = doc(db, "paymentHistory", id);
	await updateDoc(docRef, {}).then(() => {
		alert("Estatus actualizado con éxito");
	});
}

export function firebaseDateFormatter(date: FirebaseDate) {
	let tempDate = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
	let formattedDate = tempDate.toLocaleString("es-ES", dateOptions).replace(/(\d+) de (\w+) de (\d+)/, "$1 de $2 de $3");
	return formattedDate;
}
export function firebaseDateTimeFormatter(onlyTime: boolean, date: FirebaseDate) {
	let tempDate = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);

	let dateOptions: Intl.DateTimeFormatOptions = {
		day: "numeric",
		month: "long",
		year: "numeric",
	};
	let timeOptions: Intl.DateTimeFormatOptions = {
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	};

	let formattedDate = tempDate.toLocaleString("es-ES", dateOptions);
	let formattedTime = tempDate.toLocaleString("es-ES", timeOptions);
	if (onlyTime === false) {
		return `${formattedDate} - ${formattedTime}`;
	} else {
		return `${formattedTime}`;
	}
}
