import { AuthProvider, useAuth } from "@hooks/useAuth";
import ConfirmadosScreen from "@screens/admin/ConfirmadosScreen";
import DenegadosScreen from "@screens/admin/DenegadosScreen";
import InscritosScreen from "@screens/admin/InscritosScreen";
import Login from "@screens/admin/Login";
import UsersScreen from "@screens/admin/UsersScreen";
import ContactScreen from "@screens/ContactScreen";
import EnrollScreen from "@screens/EnrollScreen";
import FAQScreen from "@screens/FAQScreen";
import InscriptionScreen from "@screens/InscriptionScreen";
import KidsScreen from "@screens/KidsScreen";
import { ProgramScreen } from "@screens/ProgramScreen";
import QuestionsScreen from "@screens/QuestionsScreen";
import TestimonialScreen from "@screens/TestimonialScreen";
import ThankYouScreen from "@screens/ThankYouScreen";
import { createBrowserHistory } from "history";
import CheckoutScreen from "@screens/CheckoutScreen";
import { Redirect, Route, Router, Switch, useRouteMatch } from "react-router-dom";
import "./theme/styles.scss";
import OxxoRefScreen from "@screens/OxxoRefScreen";
import SpeiRefScreen from "@screens/SpeiRefScreen";
import InvoiceScreen from "@screens/InvoiceScreen";
import LogInScreen from "@screens/LogInScreen";
import SignUpScreen from "@screens/SignUpScreen";
import MyAccountScreen from "@screens/MyAccountScreen";
import AdminScreen from "@screens/admin-v2/AdminScreen";
import FormsScreen from "@screens/FormsScreen";
import FormsScreen2 from "@screens/FormsScreen2";
import MobileAboutAPP from "@screens/MobileAboutAPP";
import CheckoutScreenTesting from "@screens/CheckoutScreenTesting";
import PoliticasScreen from "@screens/PoliticasScreen";
import SendEmail from "@screens/SendEmail";
import SeminariesServiceProvider from "./services/SeminariesService";
import { MenuDataProvider } from "@containers/Admin/CollaboratorContainer/context/menu-data.provider";
import MainLayout from "layouts/MainLayout";
import NotAuthRoute from "functions/NotAuthRoute/NotAuthRoute";
import { HomeActionsProvider } from "./hooks/HomeActions";

import Home from "@screens/Home/Home";
import CurrencyContextProvider from "contexts/CurrencyContext";
import VocabularyStreakContextProvider from "contexts/VocabularyStreakContext";
function App() {
	const history = createBrowserHistory();
	return (
		<AuthProvider>
			<CurrencyContextProvider>
				<VocabularyStreakContextProvider>
					<Router history={history}>
						<MainLayout>
							<Switch>
								<Route exact path="/">
									<HomeActionsProvider>
										<Home />
									</HomeActionsProvider>
								</Route>

								<PrivateRoute path="/admin">
									<MenuDataProvider>
										<AdminScreen />
									</MenuDataProvider>
								</PrivateRoute>

								<Route exact path="/ingresar">
									<NotAuthRoute>
										<LogInScreen />
									</NotAuthRoute>
								</Route>
								<Route exact path="/registrarse">
									<NotAuthRoute>
										<SignUpScreen />
									</NotAuthRoute>
								</Route>
								<Route exact path="/formulario">
									<FormsScreen />
								</Route>
								<Route exact path="/formulario-experiencia">
									<FormsScreen2 />
								</Route>
								<Route exact path="/testingCheckoutInowu">
									<CheckoutScreenTesting />
								</Route>
								<Route exact path="/politicas">
									<PoliticasScreen />
								</Route>
								<AuthRoute path="/micuenta">
									<SeminariesServiceProvider>
										<MenuDataProvider>
											<MyAccountScreen />
										</MenuDataProvider>
									</SeminariesServiceProvider>
								</AuthRoute>
								<Route exact path="/programa">
									<ProgramScreen />
								</Route>
								<Route exact path="/testimonios">
									<TestimonialScreen />
								</Route>
								<Route exact path="/dudas">
									<FAQScreen />
								</Route>
								<Route exact path="/inscripcion">
									<EnrollScreen />
								</Route>
								<Route exact path="/inscribete">
									<InscriptionScreen />
								</Route>
								<Route exact path="/checkout">
									<CheckoutScreen />
								</Route>
								<Route exact path="/facturacion">
									<InvoiceScreen />
								</Route>
								<Route exact path="/oxxoref">
									<OxxoRefScreen />
								</Route>
								<Route exact path="/speiref">
									<SpeiRefScreen />
								</Route>
								<Route exact path="/ninos">
									<KidsScreen />
								</Route>
								<Route exact path="/contacto">
									<ContactScreen />
								</Route>
								<Route exact path="/gracias">
									<ThankYouScreen />
								</Route>
								<Route exact path="/dudas/:id">
									<QuestionsScreen />
								</Route>
								<Route exact path="/admin/login">
									<Login />
								</Route>
								<Route exact path="/send-email/inowutesting">
									<SendEmail />
								</Route>
								<Route exact path="/mobileAboutApp">
									<MobileAboutAPP />
								</Route>

								{/* <PrivateRoute path="/admin">
              <Admin />
            </PrivateRoute> */}
							</Switch>
						</MainLayout>
					</Router>
				</VocabularyStreakContextProvider>
			</CurrencyContextProvider>
		</AuthProvider>
	);
}

const Admin = () => {
	let { path /*url*/ } = useRouteMatch();
	return (
		<Switch>
			<PrivateRoute exact path={`${path}/inscritos`}>
				<InscritosScreen />
			</PrivateRoute>
			<PrivateRoute exact path={`${path}/denegados`}>
				<DenegadosScreen />
			</PrivateRoute>
			<PrivateRoute exact path={`${path}/confirmados`}>
				<ConfirmadosScreen />
			</PrivateRoute>
			<PrivateRoute exact path={`${path}/usuarios`}>
				<UsersScreen />
			</PrivateRoute>
			<PrivateRoute exact path={`${path}/horarios`}>
				<h1>Hola</h1>
			</PrivateRoute>
		</Switch>
	);
};

function PrivateRoute({ children, ...rest }) {
	const { user } = useAuth();

	if (user !== null) {
		return (
			<Route
				{...rest}
				render={({ location }) =>
					user.userType === 2 || user.userType === 3 || user.userType === 4 ? (
						children
					) : (
						<Redirect
							to={{
								pathname: "/ingresar",
								state: { from: location },
							}}
						/>
					)
				}
			/>
		);
	} else {
		return (
			<Route
				{...rest}
				render={({ location }) => (
					<Redirect
						to={{
							pathname: "/ingresar",
							state: { from: location },
						}}
					/>
				)}
			/>
		);
	}
}

function AuthRoute({ children, ...rest }) {
	const { user } = useAuth();

	return (
		<Route
			{...rest}
			render={({ location }) =>
				user !== null ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/ingresar",
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}

export default App;
