import PercentageBar from "@components/PercentageBar/PercentageBar";
import "./VocabularySeminaryStyles.scss";
import { getImage, romanize } from "functions/Functions";
import VocabularyDetailSeminaryCard from "../VocabularyDetailSeminaryCard/VocabularyDetailSeminaryCard";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Calendar, EyeBlock } from "assets/Imports";
import { useAuth } from "@hooks/useAuth";
import { fetchVocabularyProgress } from "@services/VocabularyService";

const seminaryDataCache = {};

function VocabularySeminaryCard({ block, seminary, nextClass, gKey, lastElementRef }) {
	const [isVisible, setIsVisible] = useState(false);
	const history = useHistory();
	const user = useAuth().user;
	const [openDetail, setOpenDetail] = useState<boolean>(false);
	const [seminaryData, setSeminaryData] = useState({ totalWords: 0, dominatedWords: 0 });
	const seminaryCardRef = useRef<HTMLDivElement>(null);

	const getSeminaryData = async () => {
		try {
			const res = await fetchVocabularyProgress(seminary, seminary);
			seminaryDataCache[seminary] = res;
			setSeminaryData(res);
		} catch (error) {
			console.error(" error fetching seminary progress", error);
		}
	};

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsVisible(entry.isIntersecting);
			},
			{
				root: null,
				rootMargin: "0px",
				threshold: 0.1,
			}
		);

		if (seminaryCardRef.current) {
			observer.observe(seminaryCardRef.current);
		}

		return () => {
			if (seminaryCardRef.current) {
				observer.unobserve(seminaryCardRef.current);
			}
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		if (seminaryDataCache[seminary]) {
			setSeminaryData(seminaryDataCache[seminary]);
		} else {
			getSeminaryData();
		}
	}, []);

	return (
		<>
			<div className="vocabulary-card" ref={seminaryCardRef}>
				<div
					className="vocabulary-card-main-info"
					onClick={block || nextClass ? undefined : () => setOpenDetail((prev) => !prev)}
					role="button"
				>
					<div className="card-info-container">
						<div className="image-container">
							{isVisible && (
								<img src={getImage(parseInt(seminary))} alt="seminary picture" loading="lazy" />
							)}
						</div>
						<div className="name-and-progress-container">
							<h3>Seminario {romanize(seminary)}</h3>
							<p>
								{seminaryData.dominatedWords}/{seminaryData.totalWords} términos dominados
							</p>
						</div>
					</div>
					{isVisible && (
						<>
							{nextClass && (
								<div className="percentage-container">
									<p className="next-class-text">
										{" "}
										<img src={Calendar} alt="class icon" /> Clase próxima
									</p>
								</div>
							)}
							{block && (
								<div className="block">
									<div className="enroll-btn-container fill"></div>
									<div className="message-block-container">
										<img src={EyeBlock} alt="block eye image" />
										<p>Aún no tienes acceso a este módulo.</p>
									</div>
									<div className="enroll-btn-container">
										<button
											className="return-btn-style"
											onClick={() => history.push("/micuenta/historial-de-pagos")}
										>
											Inscríbete
										</button>
									</div>
								</div>
							)}
							{nextClass && !block && (
								<div className="block">
									<div className="enroll-btn-container fill2"></div>
									<FontAwesomeIcon icon={faLock as IconProp} style={{ color: "white" }} />
									<p>Aún no se lleva a cabo este seminario.</p>
								</div>
							)}
							{!block && !nextClass && (
								<div className="percentage-container">
									<h3 className="percentage-of-seminary-text">Progreso del seminario</h3>
									<PercentageBar
										percentage={Math.floor(
											(seminaryData.dominatedWords / seminaryData.totalWords) * 100
										)}
									/>
								</div>
							)}
						</>
					)}
				</div>
				{openDetail && <VocabularyDetailSeminaryCard seminary={seminary} gKey={gKey} />}
			</div>
		</>
	);
}

export default VocabularySeminaryCard;
