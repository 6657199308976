import NotificationCard from "@components/NavbarCustom/components/Notification/NotificationCard";
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { db } from "../../initFirebase";
import "./NotificationsStyles.scss";
import { notification } from "antd";

function NotificationContainer({ user, notificationContainerRef }) {
	const [notifications, setNotifications] = useState<Array<any>>([]);
	const [notSeenNotificationsCount, setNotSeenNotificationsCount] = useState<number>(0);
	const [notCount, setNotCount] = useState<number>(0);
	const [userMode, setUserMode] = useState<boolean>(false);

	const getNotifications = async () => {
		let array = new Array();
		let countArray = new Array();
		let q;
		if (user.userType !== 4) {
			q = query(
				collection(db, "notifications"),
				where("userId", "in", [user.id, user.userType === 2 && !userMode ? "admin" : "all"]),
				orderBy("date", "desc")
			);
		} else {
			q = query(
				collection(db, "notifications"),
				...(!userMode ? [where("sectionId", "in", user.permissions)] : [where("userId", "==", user.id)]),
				orderBy("date", "desc")
			);
		}

		const suscribe = onSnapshot(q, (querySnapshot) => {
			array = [];
			countArray = [];
			setNotifications([]);

			querySnapshot.forEach((doc) => {
				// doc.data() is never undefined for query doc snapshots
				const data: any = {
					id: doc.id,
					...doc.data(),
				};

				if (data.seen === false) {
					countArray.push(data);
				}
				array.push(data);
			});
			
			setNotSeenNotificationsCount(countArray.length);
			setNotifications(array);
		});
	};


	const seenAll = async () => {
		if (window.confirm("¿Estás seguro de marcar todas como leídas?")) {
			notifications.forEach(async (notification) => {
				const docRef = doc(db, "notifications", notification.id);
				await updateDoc(docRef, {
					seen: true,
				});
			});
		}
	};

	useEffect(() => {
		if (user) {
			getNotifications();
		}
	}, [userMode]);

	return (
		<div className="notifications-container-main-component" ref={notificationContainerRef}>
			<div>
				<h1>Notificaciones</h1>
			</div>
			{user !== null && user !== undefined && (user.userType === 2 || user.userType === 4) && (
				<Form className="translate-input-container mt-2">
					<Form.Check
						id="custom-switch-2"
						checked={userMode}
						onChange={(e) => setUserMode(e.target.checked)}
						label={user.userType === 2 ? "Admin" : "Colaborador"}
					/>
					<Form.Check
						type="switch"
						id="custom-switch-2"
						checked={userMode}
						onChange={(e) => setUserMode(e.target.checked)}
						label={"Usuario"}
					/>
				</Form>
			)}
			<div style={{ padding: "5px 25px", display: "flex", justifyContent: "flex-end" }}>
				<span
					onClick={seenAll}
					style={{
						color: "#ca5050",
						cursor: "pointer",
						textAlign: "end",
						border: "1px solid #ca5050",
						borderRadius: 10,
						padding: "2px 5px",
					}}
				>
					Marcar todas como leídas
				</span>
			</div>
			<div>
				<div className="notification-main-container">
					{notifications.length > 0 && (
						<>
							{notifications.map((notification, index) => {
								return <NotificationCard user={user} notification={notification} key={notification.id}/>;
							})}
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default NotificationContainer;
