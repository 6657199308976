import React, { useEffect, useState } from "react";
import "../../CollaboratorContainer.css";
import "../../CollaboratorContainer.scss";
import { collection, collectionGroup, getDocs, query, where } from "firebase/firestore";
import { db } from "initFirebase";
import { Link, useHistory } from "react-router-dom";
import { compareGenerations, getSubtitle } from "functions/Generations/FunctionsGenerations";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { EyeIcon } from "assets/Imports";
import { SearchIcon, CheckmarIcon, CheckmarkCircle, EditIcon, Bottletrash, CloseCircle } from "assets/Imports";
import { Table } from "react-bootstrap";
import { EditCollaboratorModal } from "../modals/EditCollaboratorModal";
import { UseRole } from "@hooks/collaborators/UseRole";
import { AddCollaboratorModal } from "../modals/AddCollaboratorModal";
import useCollaboratorLogs from "@hooks/collaborators/useCollaboratorLogs";
import { firebaseDateTimeFormatter } from "@services/InvoiceServices";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "@hooks/useAuth";
import { LogTypes, LogActions } from "../../TypesLogsCollaborators";
import EventLog from "../modals/eventsModal";

function TableRegisterCollaborator(props) {
	const { state_Add_Collaborator, NameContainer, NameTh1, NameTh2, NameTh3, NameButton, user } = props;
	const [showModaLogs, setModalLogs] = useState<boolean>(false);
	const [selectedUser, setSelectedUser] = useState<any>(null);
	const [collaboratorsLogs, setcollaboratorsLogs] = useState<any>([]);
	const [error, setError] = useState(null);
	const { getLatestLogs } = useCollaboratorLogs();
	const [logId, setSelectedLogId] = useState<string>("");
	const { PaymentRequest, GenerationAssignment, Invoice, contactReply } = LogTypes;
	const { Accept, Reject, Request, Cancel } = LogActions;
	const { handleSelectedUserPreview } = useAuth();

	
	useEffect(() => {
		const unsubscribe = getLatestLogs(
			(logs) => {
				
				setcollaboratorsLogs(logs);
			},
			(error) => {
				console.error("Ocurrió un error al obtener los registros:", error);
				toast.error("Hubo un error en obtener los registros de colaboradores");
				setError(error);
			}
		);

		return () => {
			// Esto es para "limpiar" la suscripción al dejar el componente
			unsubscribe();
		};
	}, []);

	return (
		<>
			{/*<EditCollaboratorModal show={showModalEdit} Hide={() => setModalEdit(false)} SelectedUser={selectedUser} />*/}
			{/*	<AddCollaboratorModal showmodal={showModalAdd} Hide={() => setshowModalAdd(false)} />*/}
			<Toaster />
			<EventLog
				show={showModaLogs}
				selectedUser={selectedUser}
				Hide={() => {
					setModalLogs(false);
				}}
				selectedLogId={logId} // Agrega esta línea
				setSelectedLogId={setSelectedLogId}
			/>
			<div className="collaborator-title-container">
				<h1>{NameContainer}</h1>
			</div>
			<div className="edit-gen-card">
				<Table hover responsive>
					<thead>
						<tr>
							<th>{NameTh1}</th>
							<th>{NameTh2}</th>
							<th>{NameTh3}</th>
						</tr>
					</thead>
					<tbody>
						{collaboratorsLogs?.map((data, index) => (
							<tr
								key={index}
								onClick={() => {
									setSelectedUser(data);
									handleSelectedUserPreview(data);
									setModalLogs(true);
									setSelectedLogId(data.idLog);
								}}
								style={{ cursor: "pointer" }}
							>
								<td className="name-cell" style={{ fontWeight: "bold" }}>
									{data?.collaboratorName === null || data?.collaboratorName === undefined
										? "Sin nombre"
										: data?.collaboratorName}
								</td>
								<td style={{ fontWeight: "bold" }}>
									{(data?.type === Invoice && data?.action === Reject) ||
									(data?.type === PaymentRequest && data?.action === Reject)
										? `${data?.description}:  \t${data?.userName}`
										: data?.description}
								</td>
								<td style={{ color: "gray" }}>{firebaseDateTimeFormatter(false, data?.createdAt)} </td>
							</tr>
						))}
					</tbody>
				</Table>
			</div>
		</>
	);
}

export default TableRegisterCollaborator;
