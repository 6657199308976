import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { db } from "initFirebase";
import { useAuth } from "@hooks/useAuth";

export const MenusDataContext = createContext<any>(null);

export const MenuDataProvider: React.FC = ({ children }) => {
	const [rutasAccesibles, setRutasAccesibles] = useState<any>([]);
	const [rutasAccesiblesColb, setRutasAccesiblesColab] = useState<any>([]);
	const [withpermissions, setpermissions] = useState(false);
	const [financeRoute, setFinanceRoutes] = useState<any[]>([]);
	const [academicRoute, setAcademicRoutes] = useState<any[]>([]);
	/*const [collaboratorMenusAccess, setCollaboratorMenusAccess] = useState<any>([]);*/
	const history = useHistory();
	const user = useAuth().user;

	const [isPreviewMode, setIsPreviewMode] = useState<boolean>(() => {
		const savedIsPreviewMode = localStorage.getItem("isPreviewMode");
		return savedIsPreviewMode === "true";
	});

	const togglePreviewMode = () => {
		setIsPreviewMode((prevMode) => !prevMode);
	};

	useEffect(() => {
		localStorage.setItem("isPreviewMode", isPreviewMode.toString());
	}, [isPreviewMode]);

	useEffect(() => {
		const savedIsPreviewMode = localStorage.getItem("isPreviewMode");
		setIsPreviewMode(savedIsPreviewMode === "true");
	}, []);

	useEffect(() => {
		const obtenerDatosColeccion = async () => {
			try {
				const colRef = collection(db, "admin_routes");
				const querySnapshot = await getDocs(colRef);

				let datos: any = [];
				querySnapshot.forEach((doc) => {
					const dataWithId = { id: doc.id, ...doc.data() };
					datos.push(dataWithId);
				});

				// Separar los datos que tienen "position" de los que no
				const datosConPosition = datos.filter((a) => a.position !== undefined);
				const datosSinPosition = datos.filter((a) => a.position === undefined);

				// Ordenar los datos que tienen "position"
				datosConPosition.sort((a, b) => a.position - b.position);

				// Concatenar los datos ordenados con los que no tienen "position"
				datos = datosConPosition.concat(datosSinPosition);
				setRutasAccesiblesColab(datos)
				
				setAcademicRoutes(datos.filter((e) => e.TypeOfRoute === "Academic"));
				setFinanceRoutes(datos.filter((e) => e.TypeOfRoute === "Finance"));
				datos = datos?.filter((e) => e.TypeOfRoute === undefined);
				
				setRutasAccesibles(datos);
				const rutasAccesiblesNames = datos?.filter((ruta) => user?.permissions?.includes(ruta.id)).map((ruta) => ruta.Titulo);
				setpermissions(rutasAccesiblesNames);
			} catch (error) {
				console.log("Error al obtener los datos de la colección:", error);
			}
		};

		obtenerDatosColeccion();
	}, []);

	return (
		<MenusDataContext.Provider
			value={{
				//collaboratorMenusAccess: collaboratorMenusAccess,//
				//AgregarMenus: agregarMenus,
				PreviewMode: togglePreviewMode,
				//Remover: removerMenus,//
				ispreviewMode: isPreviewMode,
				rutasAccesibles: rutasAccesibles,
				withpermissions: withpermissions,
				academicRoute: academicRoute,
				rutasAccesiblesColb:rutasAccesiblesColb,
				financeRoute: financeRoute,
				setRutasAccesibles,
			}}
		>
			{children}
		</MenusDataContext.Provider>
	);
};
