import AsideNav from "@components/AsideNav/AsideNav";
import Footer from "@components/Footer/Footer";
import NavbarCustom from "@components/NavbarCustom/NavbarCustom";
import PaymentMethodsContent from "@containers/PaymentMethodsContainer/PaymentMethodsContent";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useParams,
	useRouteMatch,
	useHistory,
	BrowserRouter,
	useLocation,
} from "react-router-dom";
import { MenusDataContext } from "@containers/Admin/CollaboratorContainer/context/menu-data.provider";
import React, { useContext, useEffect, useState } from "react";
import PaymentHistoryContent from "@containers/PaymentHistoryContainer/PaymentHistoryContent";
import PaymentUpdateContent from "@containers/PaymentUpdateContainer/PaymentUpdateContent";
import MyAccountContent from "@containers/MyAccountContainer/MyAccountContent";
import { useAuth } from "../hooks/useAuth";
import MaterialContainer from "@containers/MaterialContainer/MaterialContainer";
import { getAuth } from "firebase/auth";
import TemaryContainer from "@containers/TemaryContainer/TemaryContainer";
import ConsultsContainer from "@containers/ConsultsContainer/ConsultsContainer";
import ConsultPostContainer from "@containers/ConsultPostContainer/ConsultPostContainer";
import IsSeminaryPaid from "./validateRoute/IsSeminaryPaid";
import NotificationsContainer from "@containers/NotificationsContainer/NotificationsContainer";
import ConsultServiceProvider from "../services/ConsultsService";
import SeminariesServiceProvider from "../services/SeminariesService";
import { SeminariesServiceContext } from "../services/SeminariesService";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";
import FormModal2 from "@components/ModalFormUser/FormModal2";
import { object } from "firebase-functions/lib/providers/storage";
import FormModal from "@components/ModalFormUser/FormModal";
import VocabularyContainer from "@containers/VocabularyContainer/VocabularyContainer";
import VocabularySeminaryContainer from "@containers/VocabularySeminaryContainer/VocabularySeminaryContainer";
import Recordings from "@containers/RecordingsContainer/Recordings";
import RecordingsSection from "@containers/RecordingsSectionContainer/RecordingsSection";
import Maintenance from "@components/Maintenance/Maintenance";
import FooterPreviewMode from "@components/Admin/FooterPreviewMode";
import { VocabularySeminaryContextProvider } from "contexts/VocabularySeminaryContext";

function MyAccountScreen() {
	let { path, url } = useRouteMatch();
	const history = useHistory();
	const location = useLocation<any>();
	const maintenance = false;

	const { ispreviewMode, PreviewMode, rutasAccesibles } = useContext(MenusDataContext);
	const [formModalShow, setFormModalShow] = useState(false);
	const [formModalShow2, setFormModalShow2] = useState(false);
	let seminariesContext = React.useContext(SeminariesServiceContext);
	let user = useAuth().user;
	const { selectedUserPreview, togglePreviewModeUser, setQuizOn, quizOn } = useAuth();
	let userId;
	if (user !== null) {
		userId = user.id;
		user = { ...user, id: userId };

		if (user.userType === 3) {
			history.push("/admin/material");
		}
	}

	if (user === null) {
		history.push("/ingresar");
	}

	const lastSeen = async () => {
		const userRef = doc(db, "users", user.id);

		const update = await updateDoc(userRef, {
			lastSeen: new Date(),
		});
	};

	useEffect(() => {
		if (user !== null) {
			isFormAnsweredRegister();
		}

		lastSeen().then(() => {
			setInterval(() => lastSeen(), 60000);
		});
	}, []);

	useEffect(() => {
		setQuizOn(false);
	}, [window.location.href]);

	const doesntEvenAttribute = async (attribute, value) => {
		//dynamic key
		const userRef = doc(db, "users", user.id);
		type Attribute = any;
		type Value = boolean;
		const obj: Record<Attribute, Value> = {
			[attribute]: value,
		};
		const docRef = await updateDoc(userRef, obj);
		return docRef;
	};

	const isFormAnsweredRegister = () => {
		if (
			user.formAnswered === false &&
			user.generationId !== "" &&
			user.userType !== 3 &&
			window.location.href.indexOf("/micuenta/historial-de-pagos") !== -1
		) {
			//CONDICIÓN PARA CUANDO SE NAVEGUE A HISTORIAL DE PAGOS CON EL STATE DE MOSTRAR EL MODAL DE NUEVO PAGO SIN QUE SE SOBREPONGAN
			if (location.state !== undefined && location.state.openPayModal) {
				setFormModalShow(false);
			} else {
				setFormModalShow(true);
			}
		} else {
			setFormModalShow(false);
			isFormAnswered();
		}
	};

	const isFormAnswered = () => {
		var a = user.formAnswered2;
		try {
			if (a === undefined && user.formAnswered === true) {
				if (seminariesContext.nextSeminaries[0]["num"] >= 11 && seminariesContext.nextSeminaries[0]["num"] <= 25) {
					setFormModalShow2(true);
				}
			} else {
				if (a === false && user.generationId !== "" && user.userType !== 2 && user.userType !== 3) {
					if (seminariesContext.nextSeminaries[0]["num"] >= 11) {
						setFormModalShow2(true);
					}
				} else {
					setFormModalShow2(false);
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<>
			{user !== null && (
				<>
					<FormModal show={formModalShow} onHide={() => setFormModalShow(false)} user={user} />
					<FormModal2 show={formModalShow2} onHide={() => setFormModalShow2(false)} user={user} />
				</>
			)}

			<div
				className={"myaccount-screen-main-container " + (quizOn ? "align-items-center justify-content-center" : "")}
				style={{ display: "flex", position: "relative", width: "100vw", maxWidth: "2100px" }}
			>
				{!quizOn && <AsideNav user={user} path={path} rutasAccesibles={rutasAccesibles} />}
				<div className="my-account-content-container" id="mainScroll">
					<Switch>
						<Route exact path={`${path}`}>
							<MyAccountContent user={user} />
						</Route>
						<Route path={`${path}/historial-de-pagos`}>
							<PaymentHistoryContent user={user} />
						</Route>
						<Route path={`${path}/metodos-de-pago`}>
							<PaymentMethodsContent user={user} />
						</Route>
						<Route path={`${path}/actualizar-pagos`}>
							<PaymentUpdateContent user={user} />
						</Route>
						<Route path={`${path}/materiales`}>
							<MaterialContainer user={user} />
						</Route>
						<Route path={`${path}/temario`}>
							<TemaryContainer user={user} />
						</Route>
						<Route exact path={`${path}/consultas`}>
							<ConsultsContainer user={user} />
						</Route>
						<Route exact path={`${path}/vocabulario`}>
							{maintenance ? <Maintenance /> : <VocabularyContainer user={user} />}
						</Route>
						<Route exact path={`${path}/vocabulario/:num`}>
							{maintenance ? (
								<Maintenance />
							) : (
								<IsSeminaryPaid>
									<VocabularySeminaryContextProvider>
										<VocabularySeminaryContainer user={user} />
									</VocabularySeminaryContextProvider>
								</IsSeminaryPaid>
							)}
						</Route>
						<Route exact path={`${path}/grabaciones/:id`}>
							<Recordings user={user} />
						</Route>
						<Route exact path={`${path}/grabaciones`}>
							<RecordingsSection user={user} />
						</Route>
						<Route path={`${path}/consultas/:num`}>
							<ConsultServiceProvider>
								<IsSeminaryPaid>
									<ConsultPostContainer user={user} />
								</IsSeminaryPaid>
							</ConsultServiceProvider>
						</Route>
						<Route path={`${path}/notificaciones`}>
							<NotificationsContainer user={user} notificationContainerRef={null} />
						</Route>
					</Switch>
				</div>

				{ispreviewMode ? (
					<FooterPreviewMode
						onClick={() => {
							history.push("/admin/colaboradores");
							PreviewMode();
							togglePreviewModeUser();
						}}
					/>
				) : null}
				{/* <Footer /> */}
			</div>
		</>
	);
}

export default MyAccountScreen;
