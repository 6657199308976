import { useAuth } from "@hooks/useAuth";
import ThankyouContainer from "@containers/ThankyouContainer/ThankyouContainer";

function ThankYouScreen() {
	let user = useAuth().user;

	return (
		<div style={{ overflow: "hidden" }}>
			<ThankyouContainer user={user} />
		</div>
	);
}

export default ThankYouScreen;
