import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Col, Row, Container, Dropdown } from "react-bootstrap";
import "./stylesModal.css";
import { Fecha, ArrowDown, Todos, Nofound, Topayment } from "assets/Imports";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import useCollaboratorLogs from "@hooks/collaborators/useCollaboratorLogs";
import CollaboratorContainer from "../../CollaboratorContainer";
import { firebaseDateFormatter, firebaseDateTimeFormatter } from "@services/InvoiceServices";
import { addDays, format, subDays } from "date-fns";
import { LogTypes, LogActions } from "../../TypesLogsCollaborators";
import { timeLog } from "console";
import { multiValueRemoveCSS } from "react-select/dist/declarations/src/components/MultiValue";
import { useHistory } from "react-router-dom";

interface EventLogProps {
	show: boolean;
	Hide: () => void;
	selectedUser: any;
	selectedLogId?: string;
	setSelectedLogId?: any;
}

const EventLog = ({ show, Hide, selectedUser, selectedLogId, setSelectedLogId }: EventLogProps) => {
	const [showModal, setShowModal] = useState(false);
	const [dateRange, setDateRange] = useState<any>([
		{
			startDate: null,
			endDate: null,
			key: "selection",
		},
	]);
	const history = useHistory();
	const [selectedOption, setSelectedOption] = useState("");
	const [selectedTime, setSelectedTime] = useState("");
	const [eventDate, setEventDate] = useState("");
	const [eventDetail, setEventDetail] = useState("");
	const { getCollaboratorLogsById } = useCollaboratorLogs();
	const [logsCollaborator, setLogsCollaborator] = useState<any>([]);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [selectedLog, setSelectedLog] = useState(null);

	const { PaymentRequest, GenerationAssignment, Invoice, contactReply } = LogTypes;
	const { Accept, Reject, Request, Cancel } = LogActions;
	const [filterType, setFilterType] = useState("Todas las secciones"); // new state variable for the filter option

	const handleSelect = (ranges) => {
		setDateRange([ranges.selection]);
	};

	useEffect(() => {
		let unsubscribe;
		if (selectedUser?.id || selectedUser?.collaboratorId) {
			unsubscribe = getCollaboratorLogsById(
				selectedUser?.id || selectedUser?.collaboratorId,
				(logs) => {
					const start = dateRange[0]?.startDate?.getTime();
					const end = addDays(dateRange[0]?.endDate, 1).getTime();
					if (start && end) {
						logs = logs.filter((log) => {
							const time = log.createdAt?.toDate()?.getTime();
							firebaseDateFormatter(time);
							return time >= start && time <= end;
						});
					}
					if (filterType !== "Todas las secciones") {
						logs = logs.filter((log) => log.type === filterType);
					}

					if (selectedLogId) {
						logs = logs.filter((log) => log.logID === selectedLogId);
					}

					setLogsCollaborator(logs);
				},
				(error) => {
					console.error(error);
				}
			);
		}

		return () => {
			if (unsubscribe && typeof unsubscribe === "function") {
				unsubscribe();
			}
		};
	}, [dateRange, filterType, selectedUser?.id, selectedUser?.collaboratorId, selectedLogId]);

	useEffect(() => {
		if (selectedLogId) {
			const log = logsCollaborator.find((log) => log.logID === selectedLogId);
			setSelectedLog(log);
		} else {
			setSelectedLog(null);
		}
	}, [selectedLogId, logsCollaborator]);

	const toggleDropdownOpen = () => {
		if (dropdownOpen) {
			// Reestablecer el rango de fechas
			setDateRange([
				{
					startDate: null,
					endDate: null,
					key: "selection",
				},
			]);
		}
		// Cambiar el estado del dropdown
		setDropdownOpen(!dropdownOpen);
	};

	function getOptionsFilter(value) {
		switch (value) {
			case GenerationAssignment:
				return "Generaciones";
				break;
			case PaymentRequest:
				return "Solicitud Pago";
				break;
			case contactReply:
				return "Contacto";
				break;
			case Invoice:
				return "Facturación";
				break;
			default:
				return "Todas las secciones";
				break;
		}
	}
	function stripHtml(html) {
		var doc = new DOMParser().parseFromString(html, "text/html");
		return doc.body.textContent || "";
	}
	
	return (
		<Modal
			show={show}
			onHide={() => {
				Hide();
				setFilterType("Todas las secciones");
			}}
			dialogClassName="user-profile-modal"
			scrollable={true}
		>
			<Modal.Header className="d-flex header" closeButton>
				<div className="image-containerModal">
					{!selectedLogId ? (
						<>
							<Dropdown>
								<Dropdown.Toggle
									variant="link"
									id="dropdown-date"
									className="dropdown-toggle"
									style={{ caret: "none", marginLeft: "-105%" }}
								>
									<img src={Fecha} alt="Fecha" />
									{/*	<img src={ArrowDown} style={{ marginLeft: "2px" }} />*/}
								</Dropdown.Toggle>

								<Dropdown.Menu style={{ minWidth: "max-content" }} className="date-range-picker-dropdown">
									<DateRange ranges={dateRange} onChange={handleSelect} rangeColors={["#f33e5b", "#3ecf8e", "#fed14c"]} />
								</Dropdown.Menu>
							</Dropdown>

							<Dropdown>
								<Dropdown.Toggle
									variant="link"
									id="dropdown-date"
									className="dropdown-toggle"
									style={{
										display: "flex",
										alignItems: "center",
										marginTop: "-1%",
										justifyContent: "space-between",
										backgroundColor: "transparent",
										borderColor: "transparent",
										marginLeft: "-26%",
									}}
								>
									<span
										style={{
											color: "#bc4e4e",
											textDecoration: "none",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											maxWidth: "100%",
											fontSize: "66%",
											fontWeight: "500",
										}}
									>
										{`Realizado en: ${getOptionsFilter(filterType)}`}
									</span>
								</Dropdown.Toggle>
								<Dropdown.Menu style={{ minWidth: "300px", color: "gray" }} className="date-range-picker-dropdown">
									<Dropdown.Item onClick={() => setFilterType("Todas las secciones")}>Todas las secciones</Dropdown.Item>
									<Dropdown.Item onClick={() => setFilterType(GenerationAssignment)}>Generaciones</Dropdown.Item>
									<Dropdown.Item onClick={() => setFilterType(PaymentRequest)}>Solicitudes de pago</Dropdown.Item>
									<Dropdown.Item onClick={() => setFilterType(contactReply)}>Contacto</Dropdown.Item>
									<Dropdown.Item onClick={() => setFilterType(Invoice)}>Facturación</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</>
					) : (
						<span
							style={{
								margin: 0,
								marginBottom: "4%",
								cursor: "pointer",
								color: "#ca5050",
								fontWeight: "bold",
								textDecoration: "underline",
							}}
							onClick={() => {
								setSelectedLogId("");
							}}
						>
							Click para restablecer los logs {}
						</span>
					)}
				</div>
				<Modal.Title className="title">
					<h3 style={{ fontSize: "2rem !important" }}>Registro de Actividad </h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					{logsCollaborator.length === 0 || logsCollaborator === null || logsCollaborator === undefined ? (
						<div className="d-flex flex-column justify-content-center align-items-center">
							<img src={Nofound} alt="No data" className="img-fluid" />
							<p className="text-center text-muted fw-bold" style={{ fontWeight: "bolder", marginTop: "2%" }}>
								{" "}
								<span style={{ textDecoration: "underline" }}>
									{selectedUser?.NameCollaborator || selectedUser?.name || selectedUser?.collaboratorName}
								</span>{" "}
								aún no ha generado movimientos.
							</p>
						</div>
					) : (
						logsCollaborator.map((data, index) => (
							<Container className="mb-3" key={index}>
								<label className="label_activitie">{firebaseDateFormatter(data?.createdAt)}</label>

								{data?.type === contactReply ? (
									<>
										<Row
											className="mx-2"
											onClick={() => {
												history.push({
													pathname: "/admin/contacto",
													state: {
														contactId: data.contactId,
														checkedMessage: data?.checkedMessage,
													},
												});
											}}
											style={{ cursor: "pointer" }}
										>
											<Col xs={12} md={3} className="text-center px-2">
												{firebaseDateTimeFormatter(true, data?.createdAt)}
											</Col>
											<Col xs={12} md={9} className="px-2" style={{ color: "#6b6b6b", textAlign: "justify" }}>
												{data.description}
											</Col>
										</Row>
									</>
								) : (
									<Row className="mx-2">
										<Col xs={12} md={3} className="text-center px-2">
											{firebaseDateTimeFormatter(true, data?.createdAt)}
										</Col>
										<Col xs={12} md={9} className="px-2" style={{ color: "#6b6b6b", textAlign: "justify" }}>
											{data.description}
										</Col>
									</Row>
								)}

								{data?.type === GenerationAssignment && (
									<>
										<Row>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												<span className="label_activitie">
													{" "}
													{data?.newGenName === "" || data?.newGenName === undefined || data?.newGenName === null
														? "Generación eliminada"
														: "Anterior"}
													:
												</span>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={3} className="text-center"></Col>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												{data?.oldGenName?.replace("Programa de Alto Rendimiento en 8 Idiomas Simultáneos - ", "") ||
													"Este usuario no tenia una generación activada."}
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												<span className="label_activitie">Correo estudiante:</span>
											</Col>
										</Row>

										<Row>
											<Col xs={12} md={3} className="text-center"></Col>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												{data?.userEmail || "Sin datos del correo"}
											</Col>
										</Row>
										{data?.newGenName === "" || data?.newGenName === undefined || data?.newGenName === null ? null : (
											<>
												{" "}
												<Row>
													<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
														<span className="label_activitie">Nueva:</span>
													</Col>
												</Row>
												<Row>
													<Col xs={12} md={3} className="text-center"></Col>
													<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
														{data?.newGenName?.replace("Programa de Alto Rendimiento en 8 Idiomas Simultáneos - ", "") ||
															"Sin datos de la nueva generación"}
													</Col>
												</Row>
											</>
										)}
									</>
								)}
								{data?.type === PaymentRequest && (
									<>
										<Row>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												<span className="label_activitie">Estudiante:</span>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={3} className="text-center"></Col>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												{data?.userName || "Sin datos del estudiante"}
											</Col>
										</Row>
										{data?.action === Accept && (
											<>
												<Row className="align-items-center justify-content-center">
													<Col xs={12} md={9} style={{ textAlign: "center", display: "flex" }}>
														<span
															className="label_activitie"
															style={{ marginRight: "10px", cursor: "pointer" }}
															onClick={() => {
																history.push({
																	pathname: "/admin/actualizar-pagos",
																	state: {
																		paymentId: data.paymentId,
																		checkedPayment: data?.check,
																	},
																});
															}}
														>
															Ver transacción
														</span>
														<img src={Topayment} alt="your_alt_text" className="image_class" />
													</Col>
												</Row>
											</>
										)}
										{data?.action === Reject && (
											<>
												<Row>
													<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
														<span className="label_activitie">Motivo de rechazo:</span>
													</Col>
												</Row>
												<Row>
													<Col xs={12} md={3} className="text-center"></Col>
													<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
														{data?.rejectReason}
													</Col>
												</Row>
											</>
										)}
									</>
								)}
								{data?.type === Invoice && (
									<>
										<Row>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												<span className="label_activitie">Estudiante:</span>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={3} className="text-center"></Col>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												{data?.userName}
											</Col>
										</Row>
										{data?.action === Accept && (
											<>
												<Row>
													<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
														<span className="label_activitie">Tipo:</span>
													</Col>
												</Row>
												<Row>
													<Col xs={12} md={3} className="text-center"></Col>
													<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
														Existente {/*valor provicional */}
													</Col>
												</Row>
											</>
										)}
										{data?.action === Reject && (
											<>
												{data?.rejectReason ? (
													<>
														<Row>
															<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
																<span className="label_activitie">Motivo de rechazo:</span>
															</Col>
														</Row>
														<Row>
															<Col xs={12} md={3} className="text-center"></Col>
															<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
																{data?.rejectReason}
															</Col>
														</Row>
													</>
												) : (
													"Sin motivo de rechazo"
												)}
											</>
										)}
									</>
								)}

								{data?.type === contactReply && (
									<>
										<Row>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												<span className="label_activitie">Mensaje:</span>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={3} className="text-center"></Col>
											<Col
												xs={12}
												md={9}
												style={{
													color: "#6b6b6b",
													textAlign: "center",
													overflow: "hidden",
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													cursor: "pointer",
												}}
												onClick={() => {
													history.push({
														pathname: "/admin/contacto",
														state: {
															contactId: data.contactId,
															checkedMessage: data?.checkedMessage,
														},
													});
												}}
											>
												{stripHtml(data?.replyMessage)}
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												<span className="label_activitie">Correo:</span>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={3} className="text-center"></Col>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												{data?.recipientEmails.length > 0 ? data?.recipientEmails.join(", ") : data?.recipientEmails}
											</Col>
										</Row>

										<Row>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												<span className="label_activitie">Celular:</span>
											</Col>
										</Row>
										<Row>
											<Col xs={12} md={3} className="text-center"></Col>
											<Col xs={12} md={9} style={{ color: "#6b6b6b", textAlign: "center" }}>
												{data?.phone}
											</Col>
										</Row>
									</>
								)}
							</Container>
						))
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default EventLog;
