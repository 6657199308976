import { db } from "initFirebase";
import { updateDoc, doc } from "firebase/firestore";
import { toast } from "react-hot-toast";

export function useDeleteCollaborator() {
	const deleteCollaborator = async (userId) => {
		try {
			const userRef = doc(db, "users", userId);
			await updateDoc(userRef, { userType: 1 });
		} catch (error) {
			console.error("Error al actualizar el tipo de usuario:", error);
			toast.error("Hubo un problema al actualizar el tipo de usuario.");
		}
	};

	return deleteCollaborator;
}
