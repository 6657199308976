import { Card } from "./Card/Card";
import "./LanguagesCards.scss";
import { ReactComponent as FranceFlag } from "./Card/images/france-flag.svg";
import { ReactComponent as GermanFlag } from "./Card/images/german-flag.svg";
import { ReactComponent as JapanFlag } from "./Card/images/japan-flag.svg";
import { ReactComponent as ItalyFlag } from "./Card/images/italian-flag.svg";
import { ReactComponent as ChinaFlag } from "./Card/images/china-flag.svg";
import { ReactComponent as RussiaFlag } from "./Card/images/russian-flag.svg";
import { ReactComponent as BrazilFlag } from "./Card/images/brazil-flag.svg";
import { ReactComponent as USAFlag } from "./Card/images/usa-flag.svg";

const languages = {
	english: {
		spanish: "Inglés",
		description: "Variante americana",
		img: <USAFlag />,
	},
	japanese: {
		spanish: "Japonés",
		description: "",
		img: <JapanFlag />,
	},
	french: {
		spanish: "Francés",
		description: "Variante francesa",
		img: <FranceFlag />,
	},
	italian: {
		spanish: "Italiano",
		description: "",
		img: <ItalyFlag />,
	},
	russian: {
		spanish: "Ruso",
		description: "",
		img: <RussiaFlag />,
	},
	german: {
		spanish: "Alemán",
		description: "Variante alemana",
		img: <GermanFlag />,
	},

	chinese: {
		spanish: "Chino",
		description: "Mandarín Simplificado",
		img: <ChinaFlag />,
	},

	portuguese: {
		spanish: "Portugués",
		description: "Variante brasileña",
		img: <BrazilFlag />,
	},
};

export const LanguagesCards = () => {
	return (
		<div className="languages-cards-main-container">
			{Array.from(Object.keys(languages)).map((language) => {
				return <Card language={{ ...languages[language], language: language }} key={"home-lang-card-" + language} />;
			})}
		</div>
	);
};
