import React, { useEffect, useState, useContext } from "react";
import "./CollaboratorContainer.css";
import "./CollaboratorContainer.scss";
import { collection, collectionGroup, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { DocumentData } from "firebase/firestore/lite";
import { Link, useHistory } from "react-router-dom";
import { compareGenerations, getSubtitle } from "functions/Generations/FunctionsGenerations";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { EyeIcon } from "assets/Imports";
import { SearchIcon, CheckmarIcon, CheckmarkCircle, EditIcon, CloseCircle } from "assets/Imports";
import { Table } from "react-bootstrap";
import { AddCollaboratorModal } from "./components/modals/AddCollaboratorModal";
import { AddRolModal } from "./components/modals/AddRolModal";
import TableCollaborator from "./components/table/TableCollaborator";
import { UseRole } from "@hooks/collaborators/UseRole";
import { UseCollaborator } from "@hooks/collaborators/UseCollaborator";
import toast, { Toaster } from "react-hot-toast";
import TableRolCollaborator from "./components/table/TableRolCollaborator";
import TableRegisterCollaborator from "./components/table/TableRegisterCollaborator";
import { MenusDataContext } from "./context/menu-data.provider";
import { useAuth } from "@hooks/useAuth";
function CollaboratorContainer({ user }) {
	const [showModalAdd, setshowModalAdd] = useState(false);
	const [showModalRol, setModalRol] = useState(false);
	const {selectedUserPreview} = useAuth()



	
	return (
		<>
			<Toaster />

			<TableRegisterCollaborator
				NameContainer={"Registro de Colaboradores"}
				SubTitle={""}
				NameTh1={"Colaborador"}
				NameTh2={"Descripción"}
				NameTh3={"Fecha de acción"}
				NameButton={"Añadir Colaborador"}
				user={user}
			/>
			
			<TableCollaborator
				NameContainer={"Colaboradores"}
				SubTitle={"Listado de los colaboradores añadidos"}
				NameTh1={"Nombre"}
				NameTh2={"Correo Electrónico"}
				NameTh3={"Secciones Asignadas"}
				NameButton={"Añadir Colaborador"}
				user={user}
			/>

			
			<TableRolCollaborator
				NameContainer={"Roles de Colaboradores"}
				SubTitle={"Agregar roles  para tus colaboradores"}
				NameTh1={"Nombre de rol"}
				NameTh2={"Nombre corto"}
				NameButton={"Añadir Rol"}
				user={user}
			/>
		</>
	);
}

export default CollaboratorContainer;
