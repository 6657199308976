import { useEffect, useState } from "react";
import { DropdownButton, Dropdown, Modal, Form, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { db } from "initFirebase";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import "./FormModal2.scss";
import { whichIsWrongForm2 } from "functions/Forms/FormsFunctions";
import { FormExperienceComponent } from "@components/FormExperience/FormExperienceComponent";
import { getForm2InfoByUserId } from "@services/FormServices";

function FormModal2(props) {
	const [validated, setValidated] = useState(false);
	const [step, setStep] = useState(1);
	const history = useHistory();
	const [hover, setHover] = useState(false);
	const [MultipleQ10, setMultipleQ10] = useState("0");
	const [loading, setLoading] = useState(false);
	const [form2Answers, setForm2Answers] = useState<any>(null);

	const handleSubmit = async (event) => {
		setLoading(true);
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			setLoading(false);
			event.stopPropagation();
			whichIsWrongForm2(form, props.user);
		} else {
			event.preventDefault();
			const genRef = doc(db, "generations", props.user.generationId);
			const docSnap: any = await getDoc(genRef);
			const docRef = await addDoc(collection(db, "forms2"), {
				//Bloque 1
				email: props.user.email,
				name: props.user.name,

				//Bloque 2
				multipleQ1: form.multipleQ1.value,
				multipleQ1_Other: form.multipleQ1_Other !== undefined ? form.multipleQ1_Other.value : "-",
				multipleQ2: form.multipleQ2.value,
				multipleQ3: form.multipleQ3.value,
				multipleQ4: form.multipleQ4.value,
				timeStudyDuringWeek: `${form.q171.value} horas cada ${form.q172.value} ${form.q173.value}`,

				//Bloque 3
				multipleQ5: form.multipleQ5.value,
				multipleQ6: form.multipleQ6.value,
				multipleQ7: form.multipleQ7.value,
				multipleQ8: form.multipleQ8.value,
				multipleQ9: form.multipleQ9.value,
				multipleQ15: form.multipleQ15.value,

				//Bloque 4
				multipleQ10: form.multipleQ10.value,
				multipleQ10_Other: form.multipleQ10_Other !== undefined ? form.multipleQ10_Other.value : "-",
				q11: form.q11.value,
				q12: form.q12.value,
				q13: form.q13.value,
				q14: form.q14.value,
				mostImportantLearning: form.q19.value,

				userId: props.user.id,
				generationId: props.user.generationId,
				generationName: docSnap.data().name,
				matricula: props.user.matricula,
				createdAt: new Date().toLocaleString(),
			}).then(async () => {
				const userRef = doc(db, "users", props.user.id);

				await updateDoc(userRef, {
					formAnswered2: true,
				});

				alert("Formulario enviado");

				history.go(0);
			});
		}

		setValidated(true);
	};

	function getName(name) {
		if (name.length === 4) {
			return name[0] + " " + name[1];
		} else if (name.length <= 3) {
			return name[0];
		} else if (name.length === 5) {
			return name[0] + " " + name[1] + " " + name[2];
		}
	}

	function getFirstLastName(name) {
		if (name.length === 4) {
			return name[2];
		} else if (name.length <= 3) {
			return name[1];
		} else if (name.length >= 5) {
			return name[name.length - 2];
		}
	}

	function getSecondLastName(name) {
		if (name.length === 4) {
			return name[3];
		} else if (name.length == 3) {
			return name[2];
		} else if (name.length >= 5) {
			return name[name.length - 1];
		} else {
			return "";
		}
	}

	const getFormsAnswers = async () => {
		let tempForm2Answers = await getForm2InfoByUserId(props.user.id);
		if (tempForm2Answers !== null && tempForm2Answers !== undefined){
			setForm2Answers(tempForm2Answers);
		}
	}

	useEffect(()=> {
		if (props.read){
			getFormsAnswers();
		}
	},[])

	return (
		<>
			<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" className="form-modal">
				{/* <div className="info-form-modal">
                    <h2>¡Hola de nuevo, ya llegaste al Módulo II!</h2>
                    <p>Por favor, ayúdanos a llenar el siguiente formulario para conocer tu experiencia.</p>
                </div>
                <div className="info-form-modal-card">
                    <h2>¡Hola de nuevo, ya llegaste al Módulo II!</h2>
                    <p>Por favor, ayúdanos a llenar el siguiente formulario para conocer tu experiencia</p>
                </div> */}
				<Modal.Header>
					<div className="modal-form-header-container">
						<Modal.Title id="contained-modal-title-vcenter" className="form-title">
							Encuesta sobre la experiencia del estudiante
						</Modal.Title>
						<div className="steps-circles-container">
							<div onClick={() => setStep(1)} className={"circle " + (step >= 1 ? "active" : "")}>
								<h3>1</h3>
							</div>
							<div onClick={() => setStep(2)} className={"circle " + (step >= 2 ? "active" : "")}>
								<h3>2</h3>
							</div>
							<div onClick={() => setStep(3)} className={"circle " + (step >= 3 ? "active" : "")}>
								<h3>3</h3>
							</div>
						</div>
					</div>
				</Modal.Header>
				<Modal.Body>
					<p style={{ margin: 0, textAlign: "start" }}>
						<a className="obligatory">*</a> Campos obligatorios.
					</p>
					<p className="font-weight-bold text-center">Módulo II</p>
					<FormExperienceComponent
						validated={validated}
						setStep={setStep}
						step={step}
						MultipleQ10={MultipleQ10}
						setMultipleQ10={setMultipleQ10}
						handleSubmit={handleSubmit}
						user={props.user}
						form2Answers={form2Answers}
						read={props.read}
					/>
				</Modal.Body>
				<Modal.Footer>
					{step > 1 && (
						<button className="tertiary form2-btn" style={{ marginRight: "auto" }} onClick={() => setStep(step - 1)}>
							Atrás
						</button>
					)}
					{props.read &&
						<button className="tertiary form2-btn" onClick={props.onHide}>
								Cerrar
						</button>
					}
					{step < 3 && (
						<button className="tertiary form2-btn" onClick={() => setStep(step + 1)}>
							Continuar
						</button>
					)}
					{step === 3 && (
						<>
							{!loading && !props.read && (
								<button form="my-form" className="tertiary return-btn-style form2-btn" type="submit">
									Finalizar
								</button>
							)}
							{loading && (
								<div className="spinner-border text-danger" role="status">
									<span className="sr-only">Loading...</span>
								</div>
							)}
						</>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default FormModal2;
