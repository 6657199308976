import { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import { GetMissingMaterialParams } from "interfaces/getMissingMaterialHook";
import { sendNotificationMissingMaterials } from "./sendNotification";

const useGetMissingMaterial = () => {
	const [missingMaterial, setMissingMaterial] = useState<any>([]);
	const [loading, setLoading] = useState(false);

	const getMissingMaterial = async ({ userId, generationId, materialId }: GetMissingMaterialParams) => {
		setLoading(true);

		try {
			const VerifyMissingMaterial = firebase.functions().httpsCallable("VerifyMissingMaterial");
			const response = await VerifyMissingMaterial({ generationId: generationId, materialId: materialId });
			if (response.data.missingMaterials !== undefined && response.data.missingMaterials.length >= 0) {
				setMissingMaterial(response.data);
			}
			setLoading(false);
		} catch (error) {
			console.error("Error fetching customer info:", error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (missingMaterial && missingMaterial.missingMaterials) {
			sendNotificationMissingMaterials(missingMaterial);
		}
	}, [missingMaterial]);
	return getMissingMaterial;
};

export default useGetMissingMaterial;
