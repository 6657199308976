import "./SeminaryInfoModal.scss";
import Modal from "react-bootstrap/Modal";
import { getLangSpanishSimple } from "@components/Vocabulary/PersonalizedTest/functions/functions";
import { capitalizeFirstLetter, romanize } from "functions/Functions";
import parse from "html-react-parser";
import { getCorrectWord } from "@containers/VocabularySeminaryContainer/components/Test/functions/TestFunctions";
import React, { useEffect } from "react";
import { CheckmarkCircle } from "assets/Imports";
import { WordRowCard } from "@components/Vocabulary/WordRowCard/WordRowCard";
import { language } from "../../../../interfaces/Interfaces";
import { CustomSpinner } from "@components/Spinner/SpinnerMyAccountView";

type SeminaryInfoModalProps = {
	show: boolean;
	onHide: () => void;
	words: any[];
	num: number;
	gKey?: string;
	setCountRefresh?: React.Dispatch<React.SetStateAction<number>>;
	countRefresh?: number;
	language?: string;
	loading?: boolean;
};

export function SeminaryInfoModal({
	show,
	onHide,
	words,
	num,
	gKey = "",
	setCountRefresh = () => {},
	countRefresh = 0,
	language = "",
	loading = false,
}: SeminaryInfoModalProps) {
	return (
		<Modal
			className="see-seminary-info-modal"
			show={show}
			onHide={onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Seminario {romanize(num)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h4 className="pl-3">
					<b>{capitalizeFirstLetter(getLangSpanishSimple(language))}</b>
				</h4>
				<div
					className="container m-0 p-0"
					style={{
						display: "flex",
						flexWrap: "wrap",
						gap: "20px",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					{loading && <CustomSpinner height={200} borderSize={4} size={30} />}
					{words.map((word, index) => {
						if (word.native_word !== "") {
							return (
								<WordRowCard
									word={word}
									language={word.language}
									answerType={"normal"}
									index={index}
									gKey={gKey}
									key={"word-middle-screen-" + index}
									setCountRefresh={setCountRefresh}
									countRefresh={countRefresh}
								/>
							);
						}
					})}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="action-btn linear-bg" onClick={onHide}>
					Cerrar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
