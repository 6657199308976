import { Spinner20vh } from "@components/Spinner/SpinnerMyAccountView";
import { QuizDefaultInfoModal } from "@containers/Admin/VocabularySeminaryContainer/components/QuizDefaultInfo/QuizDefaultInfoModal";
import { addDoc, collection, deleteDoc, doc, getDocs, getDoc, orderBy, query, updateDoc, where } from "firebase/firestore";
import { returnFlagByLanguage, romanize, sortOrderAsc } from "functions/Functions";
import { db } from "initFirebase";
import { segmentsQuiz } from "interfaces/Interfaces";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./DefaultQuizzesMainStyles.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "functions/DragDropFunctions";
import { getUniqueTotalWords } from "@containers/VocabularySeminaryContainer/components/Test/functions/TestFunctions";
import { ListIconVocabulary } from "assets/Imports";

import { v4 as uuidv4 } from "uuid";

export interface defaultQuiz {
	id: string;
	uuid: string;
	answerType: string;
	language: string;
	name: string;
	quizWords: segmentsQuiz;
	seminary: number;
	version: number;
	order: number;
}

export function DefaultQuizzesMain(props) {
	const [loading, setLoading] = useState<boolean>(true);
	const [defaultQuizzes, setDefaultQuizzes] = useState<Array<defaultQuiz>>(new Array());
	const history = useHistory();
	const [showQuiz, setShowQuiz] = useState<boolean>(false);
	const [quizIndex, setQuizIndex] = useState<number>(0);

	const getDefaultQuizzes = async () => {
		const q = query(collection(db, "defaultQuizzes"), where("seminary", "==", parseInt(props.num)));
		const snap: any = await getDocs(q);
		const temp: any = new Array();
		await Promise.all(
			snap.docs.map(async (quiz, index) => {
				const data: any = {
					id: quiz.id,
					answerType: quiz.data().answerType,
					language: quiz.data().language,
					name: quiz.data().name,
					quizWords: quiz.data().quizWords,
					seminary: quiz.data().seminary,
					version: quiz.data().version,
					uuid: quiz.data().uuid,
					order: quiz.data().order !== undefined ? quiz.data().order : index,
				};

				if (quiz.data().order === undefined) {
					const docRef: any = doc(db, "defaultQuizzes", quiz.id);
					await updateDoc(docRef, {
						order: index,
					});
				}
				//console.log(data);
				temp.push(data);
			})
		);
		setDefaultQuizzes(temp.sort(sortOrderAsc));
		setLoading(false);
	};

	const onDragEnd = async ({ destination, source }) => {
		// dropped outside the list
		if (!destination) return;

		const newItems = reorder(defaultQuizzes, source.index, destination.index);

		newItems.forEach((quiz, index) => {
			quiz.order = index;
		});
		//console.log(newItems);
		setDefaultQuizzes(newItems);

		await Promise.all(
			newItems.map(async (quiz, index) => {
				const docRef = doc(db, "defaultQuizzes", quiz.id || quiz.uuid);
				await updateDoc(docRef, {
					order: index,
				});
			})
		);

		setDefaultQuizzes(newItems);
	};

	const handleSeeQuiz = async (index) => {
		setQuizIndex(index);
		setShowQuiz(true);
	};

	const duplicateQuiz = async (quiz: defaultQuiz) => {
		if (window.confirm("¿Seguro que quieres duplicar esta ronda de estudio predeterminada?")) {
			try {
				const finalDefaultQuiz = {
					answerType: quiz.answerType,
					quizWords: quiz.quizWords,
					language: quiz.language,
					version: 1,
					name: quiz.name + " (copia)",
					seminary: quiz.seminary,
					order: quiz.order !== undefined ? quiz.order + 1 : 0,
				};

				const newQuiz: any = {
					...finalDefaultQuiz,
				};

				// Guarda la nueva ronda en la base de datos y obtén el ID asignado por Firebase
				const quizRef = await addDoc(collection(db, "defaultQuizzes"), {
					...newQuiz,
				});

				// Actualiza el ID del nuevo elemento copiado con el ID asignado por Firebase
				const newQuizId = quizRef.id;
				newQuiz.id = newQuizId;

				// Encuentra el índice de la ronda original
				const originalQuizIndex = defaultQuizzes.findIndex((q) => q.id === quiz.id);

				// Inserta la nueva ronda justo debajo de la original
				const newDefaultQuizzes = [...defaultQuizzes];
				newDefaultQuizzes.splice(originalQuizIndex + 1, 0, newQuiz);

				// Actualiza el orden de las rondas
				newDefaultQuizzes.forEach((q, index) => {
					q.order = index;
				});

				// Actualiza el estado con el nuevo orden
				setDefaultQuizzes(newDefaultQuizzes);
				updateQuizzesOrder(newDefaultQuizzes);
			} catch (error) {
				console.error("Error al duplicar el quiz:", error);
			}
		}
	};
	const updateQuizzesOrder = async (newDefaultQuizzesOrder: Array<any>) => {
		await Promise.all(
			newDefaultQuizzesOrder.map(async (quiz) => {
				await updateDoc(doc(db, "defaultQuizzes", quiz.id), { order: quiz.order });
			})
		);
	};

	const handleDeleteQuiz = async (quiz: defaultQuiz) => {
		if (window.confirm("¿Deseas eliminar esta ronda predeterminada?")) {
			await deleteDoc(doc(db, "defaultQuizzes", quiz.id || quiz.uuid)).then(() => {
				getDefaultQuizzes();
			});
		}
	};

	const scrollToLastEdit = () => {
		const element = document.getElementById("default-quiz-card-" + props.editQuiz?.id);

		if (element) {
			element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
			props.setEditQuiz(undefined);
		}
	};

	useEffect(() => {
		getDefaultQuizzes();
	}, []);

	useEffect(() => {
		if (props.editQuiz && !loading) {
			scrollToLastEdit();
		}
	}, [loading]);

	return (
		<div className="default-quizzes-admin-main-container">
			{defaultQuizzes.length > 0 && (
				<QuizDefaultInfoModal show={showQuiz} onHide={() => setShowQuiz(false)} quiz={defaultQuizzes[quizIndex]} />
			)}
			<div className="d-flex justify-content-start w-100">
				<button className="goback-btn m-0" onClick={() => history.push("/admin/vocabulario/seminario/" + props.num)}>
					Atrás
				</button>
			</div>
			<div className="actions-btn-container">
				<div className="title-container">
					<h1 className="d-flex align-items-center" style={{ gap: 10, color: "#ca5050" }}>
						<img src={ListIconVocabulary} alt="icono de lista" />
						Seminario
						<span style={{ fontFamily: "initial", alignSelf: "end", lineHeight: "52px" }}>{romanize(props.num)}</span>
					</h1>
				</div>
				<button className="action-btn linear-bg" onClick={() => props.setView(2)}>
					Nueva ronda
				</button>
			</div>
			<div className="default-quizzes-container">
				{loading ? (
					<Spinner20vh />
				) : (
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable-list">
							{(provided) => (
								<div ref={provided.innerRef} {...provided.droppableProps} className="default-quizzes-container">
									{defaultQuizzes.map((quiz, index) => {
										return (
											<Draggable draggableId={`defaultQuizz-` + quiz.id} index={index} key={"default-quiz-card-" + quiz.id}>
												{(provided, snapshot) => (
													<div
														className="default-quiz-card"
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<div className="info-container" id={"default-quiz-card-" + quiz.id}>
															<div className="image-container">{returnFlagByLanguage(quiz.language)}</div>
															<div className="name-container">
																<h3>{quiz.name}</h3>
															</div>
														</div>
														<div className="actions-container">
															<p className="words-number">{getUniqueTotalWords(quiz.quizWords)} Términos</p>
															<button className="action-btn linear-bg" onClick={() => handleSeeQuiz(index)}>
																Ver
															</button>
															<button className="action-btn secondary-btn-bg" onClick={() => props.handleEditQuiz(quiz)}>
																Editar
															</button>
															<button className="action-btn secondary-btn-bg" onClick={() => handleDeleteQuiz(quiz)}>
																Eliminar
															</button>
															<button className="action-btn secondary-btn-bg" onClick={() => duplicateQuiz(quiz)}>
																Duplicar
															</button>
														</div>
													</div>
												)}
											</Draggable>
										);
									})}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				)}
			</div>
		</div>
	);
}
