import { Counter } from 'assets/Imports';
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';
import './styles.scss';

interface CounterContainerProps {
	fechasInfo: any;
}

function CounterContainer(props: CounterContainerProps) {
	const [showCard, setShowCard] = React.useState(false);
	const Completionist = () => <span>You are good to go!</span>;

	// Renderer callback with condition
	// const renderer = ({ days, hours, minutes, seconds, completed }) => {
	//   if (completed) {
	//     setShowCard(!showCard);
	//     return <></>;
	//   } else {
	//     return (
	//       <span>
	//         {days} d {hours} h {minutes} m {seconds} s
	//       </span>
	//     );
	//   }
	// };

	return (
		<div className="section__counter section__counter__personalStyle">
			<Container
				className={
					'shadow-lg p-4 mb-5 bg-white rounded ' + (showCard ? 'hide' : '')
				}
			// style={{ height: '10rem' }}
			>
				<Row>
					<Col lg={6} md={6} xs={12} className="text-center text-md-left">
						<Row style={{ justifyContent: 'center' }}>
							<h2 className='text-danger' style={{ textAlign: 'center' }}>Cierre de inscripción</h2>
						</Row>
						<Row className="mb-1 d-flex justify-content-center">
							<p className='m-0'>
								<b>{props.fechasInfo.fecha_limite2}</b>
							</p>
						</Row>
						<Row className="d-flex justify-content-center">
							<h2 className='text-danger'>Faltan:</h2>
						</Row>
						<Row className="d-flex justify-content-center">
							<Countdown
								date={new Date(props.fechasInfo.fechaInicioInscripcion)}
								onComplete={() => setShowCard(!showCard)}
								renderer={({ days, hours, minutes, seconds }) => (
									<span>
										{days} d {hours} h {minutes} m {seconds} s
									</span>
								)}
							>
								<Completionist />
							</Countdown>
						</Row>
					</Col>
					<Col lg={6} md={6} xs={12}>
						<Image fluid src={Counter} alt="Counter image" />
					</Col>
				</Row>
			</Container>
			<Container
				className={
					'red shadow-lg p-4 mb-5 bg-white ' + (!showCard ? 'hide' : '')
				}
				style={{ borderRadius: '10px' }}
			>
				<Row>
					<Col md={7}>
						<p className="mt-4">
							<b>{props.fechasInfo.red_copie1}</b>
						</p>
						<p className="mt-4">{props.fechasInfo.red_copie2}</p>
					</Col>
					<Col md={4}>
						<Image className="counter-card-image-style" fluid src={Counter} alt="Counter image" />
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default CounterContainer;
