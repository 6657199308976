import "./Maintenance.scss";

function Maintenance(props) {
	return (
		<div className={"maintenance-component-main-container"}>
			<h2>Sección en mantenimiento</h2>
			<h4>Vuelve más tarde para seguir estudiando.</h4>
			<h4>Día y hora de restauración estimada: 16 de enero a las 17:00 horas</h4>
		</div>
	);
}

export default Maintenance;
