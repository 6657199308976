import NavbarCustom from "@components/NavbarCustom/NavbarCustom";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useRouteMatch,
    useHistory
} from "react-router-dom";

import React, { useContext, useEffect, useState } from "react";
import MobileDetect from 'mobile-detect';
import { useAuth } from "../../hooks/useAuth";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import Collapse from 'react-bootstrap/Collapse';
import ListGroup from 'react-bootstrap/ListGroup';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BaselineKeyboardArrowDown from 'react-md-icon/dist/BaselineKeyboardArrowDown';
import BaselineGrade from 'react-md-icon/dist/BaselineGrade';
import BaselinePhoneIphone from 'react-md-icon/dist/BaselinePhoneIphone';
import { FaArrowRight } from 'react-icons/fa';
import Img1 from './screenIMG/iPhone-X-Preview01.png';
import Img2 from './screenIMG/iPhone-X-Preview02.png';
import Img3 from './screenIMG/iPhone-X-Preview03.png';
import Img4 from './screenIMG/iPhone-X-Preview04.png';
import Img5 from './screenIMG/iPhone-X-Preview05.png';
import Img6 from './screenIMG/iPhone-X-Preview06.png';
import Img7 from './screenIMG/iPhone-X-Preview07.png';
import shareIcon from './screenIMG/512px-Ei-share-apple.png';
import iphoneIcon from './screenIMG/iphone.png';
import logoHD from './screenIMG/image 3.png';
import './iosStyles.css'
import { faL } from "@fortawesome/free-solid-svg-icons";
import Img1A from './screenIMG/1A.png';
import Img2A from './screenIMG/2A.png';
import Img3A from './screenIMG/3A.png';
import Img4A from './screenIMG/4A.png';
import Califications from "./components/Califications";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { Star } from "assets/Imports";
import { getCurrentAnniversary } from "@utils/utilFunctions";


const mdAPI = new MobileDetect(window.navigator.userAgent);

function IOS_View() {

    const [open, setOpen] = useState(true);

    const user = useAuth().user;

    const [modalShow, setModalShow] = useState(false);
    const [width, setWidth] = useState(0);

    const [counter, setCounter] = useState<number>(0);

    const getCounter = () => {
        const unsub = onSnapshot(doc(db, "mobileCounter", "counter"), (doc) => {
            setCounter(parseInt(doc.data() !== undefined ? doc.data()!.counter : 0));
        });
    }

    const obtener = async () => {
        const washingtonRef = doc(db, "mobileCounter", "counter");

        // Set the "capital" field of the city 'DC'
        await updateDoc(washingtonRef, {
            counter: counter + 1,
        });
        setModalShow(true);
    }

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)

        handleResize()


        return () => {
            window.removeEventListener("resize", handleResize)
        }

    }, [setWidth]);

    useEffect(() => {
        getCounter();
    }, [])

    return (
        <>
            <Modal
                show={modalShow}
                onClick={() => setModalShow(false)}
                onHide={() => setModalShow(false)}
                className="modalIOS"
                id="modalIOS"
            >
                <Col>
                    <Row style={{ paddingLeft: "5%", paddingTop: "25px" }}>
                        <div className="boxStep">
                            <h3 className="boxStepText">Paso 1</h3>
                        </div>
                        <Col>
                            <div>
                                <div className="boxText">
                                    <Row style={{
                                        paddingLeft: "30px",
                                        paddingTop: "10px",
                                        marginTop: "15px"

                                    }}>
                                        <h3 className="boxTextRegular">Abre la sección&nbsp;</h3><h3 className="boxTextBold">"opciones"</h3>
                                    </Row>
                                </div>

                            </div>

                        </Col>
                    </Row>

                    <div className="first-step">
                        <Row style={{ paddingLeft: "5%", paddingTop: "35px" }}>
                            <Col className="modalImg">
                                <div className="circle" />
                                <img
                                    className="d-block boxImg"
                                    src={Img1A}
                                />
                            </Col>
                        </Row>
                    </div>


                    <Row style={{ paddingLeft: "5%", paddingTop: "25px" }}>
                        <div className="boxStep">
                            <h3 className="boxStepText">Paso 2</h3>
                        </div>
                        <Col>
                            <div>
                                <div className="boxText">
                                    <Row style={{
                                        paddingLeft: "30px",
                                        paddingTop: "10px",
                                        marginTop: "15px"

                                    }}>
                                        <h3 className="boxTextRegular">Selecciona&nbsp;</h3><h3 className="boxTextBold">"Agregar a pantalla de inicio"</h3>
                                    </Row>
                                </div>

                            </div>

                        </Col>
                    </Row>

                    <Row style={{ paddingLeft: "5%", paddingTop: "35px" }}>
                        <Col className="modalImg">
                            <img
                                className="d-block boxImg"
                                src={Img2A}
                            />
                        </Col>
                    </Row>

                    <Row style={{ paddingLeft: "5%", paddingTop: "25px" }}>
                        <div className="boxStep">
                            <h3 className="boxStepText">Paso 3</h3>
                        </div>
                        <Col>
                            <div>
                                <div className="boxText">
                                    <Row style={{
                                        paddingLeft: "30px",
                                        paddingTop: "10px",
                                        marginTop: "15px"

                                    }}>
                                        <h3 className="boxTextRegular">Utiliza&nbsp;</h3>
                                        <h3 className="boxTextBold">"Academia de Lenguas Internacionales"</h3>
                                        <h3 className="boxTextRegular">&nbsp;como nombre</h3>
                                    </Row>
                                </div>

                            </div>

                        </Col>
                    </Row>

                    <Row style={{ paddingLeft: "5%", paddingTop: "35px" }}>
                        <Col className="modalImg">
                            <img
                                className="d-block boxImg"
                                src={Img3A}
                            />
                        </Col>
                    </Row>

                    <Row style={{ paddingLeft: "5%", paddingTop: "25px" }}>
                        <div className="boxStep">
                            <h3 className="boxStepText">Paso 4</h3>
                        </div>
                        <Col>
                            <div>
                                <div className="boxText">
                                    <Row style={{
                                        paddingLeft: "30px",
                                        paddingTop: "10px",
                                        marginTop: "15px"

                                    }}>
                                        <h3 className="boxTextRegular">Accede a la aplicación e inicia sesión</h3>
                                    </Row>
                                </div>

                            </div>

                        </Col>
                    </Row>

                    <Row style={{ paddingLeft: "5%", paddingTop: "35px" }}>
                        <Col className="modalImg">
                            <img
                                className="d-block boxImg"
                                src={Img4A}
                            />
                        </Col>
                    </Row>

                    <Row style={{ paddingLeft: "5%", paddingTop: "25px", paddingBottom: "30px" }}>
                        <div className="boxStep">
                            <h3 className="boxStepText">¡Listo!</h3>
                        </div>
                        <Col>
                            <div>
                                <div className="boxText">
                                    <Row style={{
                                        paddingLeft: "30px",
                                        paddingTop: "10px",
                                        marginTop: "15px"

                                    }}>
                                        <h3 className="boxTextRegular">Ya puedes disfrutar de la academia de lenguas internacionales desde tu móvil</h3>
                                        <h3 id="boxTextLight">*Se requiere de una conexión activa a internet</h3>
                                    </Row>
                                </div>

                            </div>

                        </Col>
                    </Row>
                </Col>
            </Modal>
            <div className="mobile-about-app-container">
                <Container className="second-container-mobile-app">
                    <Row style={{ paddingTop: "30px" }}>
                        <Col className="mobile-image-container" style={{
                            paddingRight: "2%",
                            paddingLeft: "0"
                        }}>
                            <Image
                                style={{ borderRadius: "26px", width: "118px", height: "auto", }}
                                src={logoHD}
                                alt=" "
                                className=" "
                            />
                        </Col>
                        <Col className=" " style={{
                            paddingRight: "7%",
                            width: "300px !important",
                            paddingLeft: "0",
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Row>
                                <h2 className="section1_Text1">Academia de Lenguas Internacionales</h2>
                            </Row>
                            <Row>
                                <h3 className="section1_Text2" style={{ color: "#3478F6" }} >Plataforma de aprendizaje</h3>
                            </Row>
                            <Row style={{ paddingTop: "11px", flexGrow: 1 }} >
                                <Row>
                                    <Col style={{ height: "23.53px", paddingLeft: "0" }} >
                                        <Button id="obtenerButton" style={{ backgroundColor: "#3478F6", height: "inherit" }} onClick={obtener} >

                                            <h3 className="section1_Text2C">OBTENER</h3>
                                        </Button>
                                        {user !== null && user.userType === 2 && (
                                            <p className="counter-text">Contador: {counter}</p>
                                        )}
                                    </Col>

                                    {width > 900 && (
                                        <div className="android-banner-container w-100 mt-auto">
                                            <div className="caracteristica-container">
                                                <h3 className="big-text">{getCurrentAnniversary()} años</h3>
                                                <h3 className="small-text">formando políglotas</h3>
                                            </div>
                                            <div className="line-divisor"></div>
                                            <div className="caracteristica-container">
                                                <h3 className="small-text">EDAD</h3>
                                                <h3 className="big-text">17+</h3>
                                                <h3 className="small-text">AÑOS</h3>
                                            </div>
                                            <div className="line-divisor"></div>
                                            <div className="caracteristica-container">
                                                <h3 className="small-text">Duración</h3>
                                                <h3 className="big-text">60</h3>
                                                <h3 className="small-text">Seminarios</h3>
                                            </div>
                                            <div className="line-divisor"></div>
                                            <div className="caracteristica-container">
                                                <h3 className="big-text">4.5 <img src={Star} style={{ filter: 'invert(56%) sepia(6%) saturate(173%) hue-rotate(182deg) brightness(99%) contrast(91%)' }} alt="estrella" /></h3>
                                                <h3 className="small-text">Calificación</h3>
                                            </div>
                                        </div>
                                    )}
                                </Row>

                            </Row>
                        </Col>
                    </Row>

                    {width < 900 && (
                        <>
                            <div id="collapseLine"></div>
                            <div className="android-banner-container w-100 mt-2">
                                <div className="caracteristica-container">
                                    <h3 className="big-text">{getCurrentAnniversary()} años</h3>
                                    <h3 className="small-text">formando políglotas</h3>
                                </div>
                                <div className="line-divisor"></div>
                                <div className="caracteristica-container">
                                    <h3 className="small-text">EDAD</h3>
                                    <h3 className="big-text">17+</h3>
                                    <h3 className="small-text">AÑOS</h3>
                                </div>
                                <div className="line-divisor"></div>
                                <div className="caracteristica-container">
                                    <h3 className="small-text">Duración</h3>
                                    <h3 className="big-text">60</h3>
                                    <h3 className="small-text">Seminarios</h3>
                                </div>
                                <div className="line-divisor"></div>
                                <div className="caracteristica-container">
                                    <h3 className="big-text">4.5 <img src={Star} style={{ filter: 'invert(56%) sepia(6%) saturate(173%) hue-rotate(182deg) brightness(99%) contrast(91%)' }} alt="estrella" /></h3>
                                    <h3 className="small-text">Calificación</h3>
                                </div>
                            </div>
                        </>
                    )}
                    {width < 900 ? (
                        <Row>
                            <Carousel className="carouselIOS">
                                <Carousel.Item style={{ width: "90% !important" }} className="" >
                                    <img
                                        className="d-block w-100"
                                        src={Img1}
                                        alt="First slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item style={{ width: "90% !important" }} className="" >
                                    <img
                                        className="d-block w-100"
                                        src={Img2}
                                        alt="Second slide"
                                    />

                                </Carousel.Item>
                                <Carousel.Item style={{ width: "90% !important" }} className="" >
                                    <img
                                        className="d-block w-100"
                                        src={Img3}
                                        alt="Third slide"
                                    />

                                </Carousel.Item>
                                <Carousel.Item style={{ width: "90% !important" }} className="" >
                                    <img
                                        className="d-block w-100"
                                        src={Img4}
                                        alt="Fourth slide"
                                    />

                                </Carousel.Item>
                                <Carousel.Item style={{ width: "90% !important" }} className="" >
                                    <img
                                        className="d-block w-100"
                                        src={Img5}
                                        alt="Fifth slide"
                                    />

                                </Carousel.Item>
                                <Carousel.Item style={{ width: "90% !important" }} className="" >
                                    <img
                                        className="d-block w-100"
                                        src={Img6}
                                        alt="Sixth slide"
                                    />

                                </Carousel.Item>
                                <Carousel.Item style={{ width: "90% !important" }} className="" >
                                    <img
                                        className="d-block w-100"
                                        src={Img7}
                                        alt="Sixth slide"
                                    />

                                </Carousel.Item>
                            </Carousel>
                        </Row>
                    ) : (
                        <div className="images-mobile-app-container mt-4">
                            <img className="d-block" style={{ width: '210px', borderRadius: 10 }} src={Img1} alt="Second slide" />
                            <img className="d-block" style={{ width: '210px', borderRadius: 10 }} src={Img2} alt="Second slide" />
                            <img className="d-block" style={{ width: '210px', borderRadius: 10 }} src={Img3} alt="Second slide" />
                            <img className="d-block" style={{ width: '210px', borderRadius: 10 }} src={Img4} alt="Second slide" />
                            <img className="d-block" style={{ width: '210px', borderRadius: 10 }} src={Img5} alt="Second slide" />
                            <img className="d-block" style={{ width: '210px', borderRadius: 10 }} src={Img6} alt="Second slide" />
                            <img className="d-block" style={{ width: '210px', borderRadius: 10 }} src={Img7} alt="Second slide" />
                        </div>
                    )}
                    <Row>
                        <Button
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}

                            id="collapse"

                        >

                            <Row >
                                <Col style={{ paddingRight: "0", textAlign: "right", paddingLeft: "0" }}>
                                    <Image
                                        style={{ width: "10px", height: "auto" }}
                                        src={iphoneIcon}
                                        alt=""
                                        className=""
                                    />
                                </Col>
                                <Col><h3 className="section4_Text1" style={{ padding: " 11px 0 0 0" }} >iPhone App</h3></Col>
                                <Col style={{ paddingLeft: "0", textAlign: "left" }}>
                                    <BaselineKeyboardArrowDown style={{ color: "#8A898E" }} >
                                    </BaselineKeyboardArrowDown>
                                </Col>

                            </Row>

                            <div id="collapseLine"></div>

                        </Button>
                        <Collapse in={open}>
                            <div id="example-collapse-text">

                                <h3 className="section5_Text1B"  >Con la aplicación móvil puedes: </h3>

                                <ul className="section5_Text1">
                                    <li>Acceder a los materiales</li>
                                    <li>Acceder a las listas de vocabulario</li>
                                    <li>Acceder a los apuntes de la pizarra</li>
                                    <li>Acceder a las respuestas de ejercicios</li>
                                    <li>Acceder a los enlaces de las grabaciones</li>
                                    <li>Consultar el temario del programa</li>
                                    <li>Plantear y contestar dudas referentes a los seminarios</li>
                                    <li>Estudiar vocabulario</li>
                                    <li>(Próximamente) Poner en práctica tus conocimientos</li>
                                    <li>Consultar las grabaciones</li>
                                    <li>Realizar un próximo pago</li>
                                    <li>Solicitar una factura</li>
                                    <li>Consultar tu historial de pagos</li>
                                    <li>Consultar días de descanso</li>
                                </ul>
                            </div>
                        </Collapse>
                    </Row>

                    <Califications OS="ios" />


                </Container>
            </div >
        </>
    )
}


export default IOS_View;
