import { RocketIcon } from "assets/Imports";
import parse from "html-react-parser";
import { proTips } from "../../functions/DefaultCharacters";
import "./LoaderTest.scss";

export function LoaderTest(props) {
  return (
    <div className="loader-container">
      <div className="creating-test-row">
        {/* <button onClick={endQuiz}>Check</button> */}
        <h3>Estamos revisando tu ronda de estudio...</h3>
      </div>
      <div className="rocket-row">
        <img src={RocketIcon}  alt="nave espacial" />
      </div>
      <div className="progress-bar-row">
        <div className="spinner-border text-danger" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      <div className="pro-tip-row">
        <h2>Pro tip:</h2>
        <h3>{parse(proTips[Math.floor(Math.random() * proTips.length)])}</h3>
      </div>
    </div>
  );
}
