import { useState } from "react";
import {
	DocumentData,
	DocumentReference,
	DocumentSnapshot,
	addDoc,
	collection,
	doc,
	getDoc,
	updateDoc,
} from "firebase/firestore";
import { db } from "initFirebase";
import firebase from "firebase/compat/app";
const useEmailResponse = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<any>(null);

	const sendEmailResponse = async (documentId, user, emailUser, message, files, questions, subject, collaboratorId,collaboratorName) => {
		try {
			const responseObj = {
				createdAt: new Date(),
				replyText: message,
				question: questions,
				from: user,
				files: files,
				subject: subject,
				email: emailUser,
				collaboratorId: collaboratorId,
				collaboratorName:collaboratorName,
			};
			//console.log(documentId, responseObj);
			await db
				.collection("newsletter")
				.doc(documentId)
				.update({
					response: firebase.firestore.FieldValue.arrayUnion(responseObj),
					answered: true,
				});
		} catch (error) {
			setError("Error al enviar el correo y guardar la respuesta");
			console.error(error);
		}

		setLoading(false);
	};

	return {
		loading,
		error,
		sendEmailResponse,
	};

/*	const setAnsweredById = async (contactId) => {
		setLoading(true);
		setError(null);
		try {
			await db.collection("newLetter").doc(contactId).update({
				answered: true,
			});
		} catch (error) {
			setError("Error al actualizar el campo answered");
			console.error(error);
		}

		setLoading(false);
	};

	return {
		loading,
		error,
		sendEmailResponse,
		setAnsweredById,
	};*/
};

export default useEmailResponse;
