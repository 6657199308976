import { httpsCallable } from "firebase/functions";
import { functions } from "initFirebase";

export const sendEmailToAdmin = async (body) => {
	const sendEmail = httpsCallable(functions, "emailMessageNewContact");

	try {
		const sendEmailResponse = await sendEmail(body);
		return sendEmailResponse;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const sendEmailToClient = async (body) => {
	const sendEmail = httpsCallable(functions, "emailForNewClient");

	try {
		const sendEmailresponse = await sendEmail(body);
		return sendEmailresponse;
	} catch (error) {
		throw error;
	}
};
