
import FaqsButton from "@components/FaqsButton/FaqsButton";
import FAQContainer from "@containers/FAQContainer/FAQContainer";

function FAQScreen() {


	return (
		<div style={{ overflow: "hidden", position: "relative" }}>
			<FAQContainer />
			<FaqsButton />
		</div>
	);
}

export default FAQScreen;
