import ProgramContainer from "@components/ProgramContainer/ProgramContainer";
import FaqsButton from "@components/FaqsButton/FaqsButton";

export const ProgramScreen = () => {
	return (
		<div style={{ overflow: "hidden", position: "relative" }}>
			<ProgramContainer />
			<FaqsButton />
		</div>
	);
};
