import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import InputMask from "react-input-mask";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "initFirebase";
import { GenTypes, SimpleDate, TNewGeneration, isGenInPerson, isGenOnline } from "interfaces/Generations";
import { getSimpleDate } from "@utils/utilFunctions";

interface copyMaterial {
	id: string;
	num: number;
	files: Array<files>;
	videoUrl: string;
}

interface files {
	name: string;
	linkFile: string;
}

// IMPROVE: Talvez podria llevar este simpleToday y newGenDefaultValues al modulo Generations.ts?, ya que se usa en mas de un lugar.
// Representa la fecha de hoy en el formato "simple" DD/MM/YYYY
const simpleToday: SimpleDate = getSimpleDate(new Date());

const newGenDefaultValues: TNewGeneration = {
	name: "",
	initialDate: simpleToday,
	schedule: "",
	asuetos: [],
	type: GenTypes.InPerson,
	sede: "none",
	classDay: "",
	startHour: "",
	endHour: "",
	cirilic: "",
	active: false,
	createdAt: new Date(),
	visible: false,
	promoDate: simpleToday,
	limit: 0,
	countdownEnabled: false,
	hoursPerWeek: 2,
	totalHours: 120,
	regularPrice: 300,
	promoPrice: 250,
	regularPriceUSD: 18,
	promoPriceUSD: 15,
};

const weekDays = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];
const uppercaseCyrillicCharacters = [
	"А",
	"Б",
	"В",
	"Г",
	"Д",
	"Е",
	"Ё",
	"Ж",
	"З",
	"И",
	"Й",
	"К",
	"Л",
	"М",
	"Н",
	"О",
	"П",
	"Р",
	"С",
	"Т",
	"У",
	"Ф",
	"Х",
	"Ц",
	"Ч",
	"Ш",
	"Щ",
	"Ъ",
	"Ы",
	"Ь",
	"Э",
	"Ю",
	"Я",
];
// Componente tipo Modal para crear una nueva generacion.
export function NewGenModal(props) {
	const [numAsuetos, setNumAsuetos] = useState(0);
	const [gen, setGen] = useState<TNewGeneration>(newGenDefaultValues); // The new generation
	const [finalDate, setFinalDate]: any = useState("");
	const [refresh, setRefresh] = useState(false);
	const [loading, setLoading] = useState(false);
	const [newGenSteps, setNewGenSteps] = useState(0);
	const [gens, setGens] = useState<Array<any>>(new Array());
	const [copy, setCopy] = useState<string>("new");
	const [visible, setVisile] = useState(true); //estado para decidir si la generación sera visible o no inicialmente, por si se llega a requerir despues

	// Set states to default values
	const resetDefaults = () => {
		setGen(newGenDefaultValues);
		setCopy("new");
		setNumAsuetos(0);
		setNewGenSteps(0);
		setLoading(false);
	};

	const getGenerations = async () => {
		let array: any = [];
		const q = query(collection(db, "generations"));
		const querySnapshot = await getDocs(q);
		let i = 0;
		querySnapshot.forEach(async (doc) => {
			const data = {
				...doc.data(),
				id: doc.id,
			};
			array.push(data);
			i++;

			if (i === querySnapshot.size) {
				array.sort(compare);
				setGens(array);
			}
		});
	};

	function compare(a, b) {
		if (a.createdAt > b.createdAt) {
			return -1;
		}
		if (a.createdAt < b.createdAt) {
			return 1;
		}
		return 0;
	}

	function compareNum(a, b) {
		if (a.num < b.num) {
			return -1;
		}
		if (a.num > b.num) {
			return 1;
		}
		return 0;
	}

	const moreAsuetos = () => {
		let asuetos = new Array(...JSON.parse(JSON.stringify(gen.asuetos)));
		setNumAsuetos(numAsuetos + 1);
		asuetos.push({ ["name"]: "asueto" + numAsuetos, date: "" });
		setGen({ ...gen, asuetos: asuetos });
	};

	const deleteAsueto = () => {
		let asuetos = new Array(...JSON.parse(JSON.stringify(gen.asuetos)));
		setNumAsuetos(numAsuetos - 1);
		asuetos.pop();
		setGen({ ...gen, asuetos: asuetos });
	};

	const onChangeAsuetos = (e, index) => {
		let asuetos = new Array(...JSON.parse(JSON.stringify(gen.asuetos)));
		asuetos[index].date = e.target.value;
		setGen({ ...gen, asuetos: asuetos });
	};

	const getCopyInfo = async () => {
		setLoading(true);

		if (copy === "new") {
			saveGen("new");
		} else {
			const materialsRef = query(collection(db, "generations", copy, "material"));
			const materialsSnap = await getDocs(materialsRef);

			let index = 0;
			let materialTemp = new Array();
			materialsSnap.forEach((material) => {
				index++;

				const data: copyMaterial = {
					id: material.id,
					files: material.data().files,
					num: material.data().num,
					videoUrl: material.data().videoUrl,
				};

				materialTemp.push(data);

				if (index === materialsSnap.size) {
					saveGen("copy", materialTemp.sort(compareNum));
				}
			});
		}
	};

	const saveGen = async (type: string, material?: Array<copyMaterial>) => {
		const newGen: TNewGeneration = {
			active: false,
			type: gen.type,
			initialDate: gen.initialDate,
			asuetos: gen.asuetos,
			classDay: gen.classDay,
			startHour: gen.startHour,
			visible: visible,
			createdAt: new Date(),
			endHour: gen.endHour,
			name: gen.name,
			sede: gen.sede,
			schedule: gen.schedule,
			cirilic: gen.cirilic,
			limit: isGenInPerson(gen.type) ? 20 : 10000,
			countdownEnabled: gen.countdownEnabled,
			hoursPerWeek: gen.hoursPerWeek,
			totalHours: gen.totalHours,
			regularPrice: gen.regularPrice,
			promoPrice: gen.promoPrice,
			regularPriceUSD: gen.regularPriceUSD,
			promoPriceUSD: gen.promoPriceUSD,
			...(isGenInPerson(gen.type) && { promoDate: gen.promoDate }),
			...(isGenInPerson(gen.type) && { genCopyId: copy }),
		};

		const docRef = await addDoc(collection(db, "generations"), newGen);

		await addDoc(collection(db, "announcement"), {
			active: false,
			description: "",
			title: "",
			generationId: docRef.id,
		});

		let initialDateSplit: any = gen.initialDate?.split("/");
		let lastDate = initialDateSplit[0] + "/" + (initialDateSplit[1] - 1) + "/" + initialDateSplit[2];

		const date = new Date();
		for (let index = 1; index <= 60; index++) {
			let dateSplit = lastDate.split("/");
			date.setDate(parseInt(dateSplit[0]));
			date.setMonth(parseInt(dateSplit[1]));
			date.setFullYear(parseInt(dateSplit[2]));

			let dateString = "";

			if (index > 1) {
				date.setDate(date.getDate() + 7);
				gen.asuetos.forEach((day) => {
					if (date.getMonth() < 9) {
						if (date.getDate() < 10) {
							dateString = "0" + date.getDate() + "/0" + (date.getMonth() + 1) + "/" + date.getFullYear();
						} else {
							dateString = date.getDate() + "/0" + (date.getMonth() + 1) + "/" + date.getFullYear();
						}
					} else {
						if (date.getDate() < 10) {
							dateString = "0" + date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
						} else {
							dateString = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
						}
					}
					if (dateString === day.date) {
						date.setDate(date.getDate() + 7);
					}
				});
			}

			lastDate = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();

			const materialRef = await addDoc(collection(db, "generations", docRef.id, "material"), {
				num: index,
				videoUrl: type === "new" ? "" : material![index - 1].videoUrl,
				files: type === "new" ? [] : material![index - 1].files,
				date: lastDate,
			});

			if (index === 60) {
				props.getGenerations();
				setLoading(false);
				setGen(newGenDefaultValues);
				props.onHide();
			}
		}
		alert("Generacion creada con éxito!");
		setLoading(false);
	};

	useEffect(() => {
		getGenerations();
	}, []);

	return (
		<Modal
			show={props.show}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="new-generation-modal"
			onHide={props.onHide}
			onExited={resetDefaults}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Nueva generación {isGenOnline(gen.type) ? " - En línea" : " - Presencial"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="setminheightmodal">
				{newGenSteps === 0 && (
					<>
						<p>Ingresa estos datos para poder crear la nueva generación.</p>
						<div className="new-gen-modal-input-row">
							<label htmlFor="gen-name">Nombre de la generación</label>
							<div className="input-new-gen-container">
								<input
									className="input-new-gen"
									name="gen-name"
									value={gen.name}
									id="gen-name"
									type="text"
									onChange={(e) => setGen({ ...gen, name: e.target.value })}
									placeholder="Nombre de la generación"
								/>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="initial-date">Fecha de inicio</label>
							<div className="input-new-gen-container">
								<InputMask
									required
									className="input-new-gen"
									type="text"
									name="initial-date"
									id="initial-date"
									value={gen.initialDate}
									onChange={(e) => setGen({ ...gen, initialDate: e.target.value })}
									placeholder="dd/MM/aaaa"
									mask="99/99/9999"
									maskChar=""
								></InputMask>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="modality">Modalidad</label>
							<div className="input-new-gen-container">
								<select
									className="w-100"
									style={{ border: "none" }}
									value={gen.type}
									onChange={(e) => setGen({ ...gen, type: e.target.value as GenTypes })}
									name="modality"
									id="modality"
								>
									<option value="none" disabled>
										Selecciona una opción
									</option>
									<option value={GenTypes.InPerson}>En persona</option>
									<option value={GenTypes.OnlineAM}>Online matutino</option>
									<option value={GenTypes.OnlinePM}>Online vespertino</option>
								</select>
							</div>
						</div>
						{isGenInPerson(gen.type) && (
							<>
								<div className="new-gen-modal-input-row">
									<label htmlFor="final-promo-date">Fecha final de promoción</label>
									<div className="input-new-gen-container">
										<InputMask
											required
											className="input-new-gen"
											type="text"
											name="final-promo-date"
											id="final-promo-date"
											value={gen.promoDate}
											onChange={(e) => setGen({ ...gen, promoDate: e.target.value })}
											placeholder="dd/MM/aaaa"
											mask="99/99/9999"
											maskChar=""
										></InputMask>
									</div>
								</div>
							</>
						)}
						<div className="new-gen-modal-input-row">
							<label htmlFor="sede">Sede</label>
							<div className="input-new-gen-container">
								<select
									className="w-100"
									style={{ border: "none" }}
									value={gen.sede}
									onChange={(e) => setGen({ ...gen, sede: e.target.value })}
									name="sede"
									id="sede"
								>
									<option value="none">Selecciona una opción</option>
									{isGenInPerson(gen.type) ? (
										<option value="Narvarte">Narvarte</option>
									) : (
										<option value="Modalidad en línea (ZOOM)">ZOOM</option>
									)}
								</select>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="classDay">Día de la semana</label>
							<div className="input-new-gen-container">
								<select
									className="w-100"
									style={{ border: "none" }}
									value={gen.classDay}
									onChange={(e) => setGen({ ...gen, classDay: e.target.value })}
									name="classDay"
									id="classDay"
								>
									<option value="">Selecciona una opción</option>
									{weekDays.map((day, index) => {
										return (
											<option value={day} key={"week-day-option-" + index}>
												{day}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="startHour">Hora de inicio</label>
							<div className="input-new-gen-container">
								<input
									className="input-new-gen"
									type="time"
									name="startHour"
									id="startHour"
									value={gen.startHour}
									onChange={(e) => setGen({ ...gen, startHour: e.target.value })}
								/>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="endHour">Hora final</label>
							<div className="input-new-gen-container">
								<input
									className="input-new-gen"
									type="time"
									name="endHour"
									id="endHour"
									value={gen.endHour}
									onChange={(e) => setGen({ ...gen, endHour: e.target.value })}
								/>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="cirilic">Caracter cirílico</label>
							<div className="input-new-gen-container">
								<select
									className="input-new-gen"
									name="cirilic"
									id="cirilic"
									value={gen.cirilic}
									onChange={(e) => setGen({ ...gen, cirilic: e.target.value })}
								>
									<option value="">Sin caracter</option>
									{uppercaseCyrillicCharacters.map((char) => {
										return (
											<option key={`Opcion - ${char}`} value={char}>
												{char}
											</option>
										);
									})}
								</select>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="schedule">Horario de la generación</label>
							<div className="input-new-gen-container">
								<input
									className="input-new-gen"
									name="schedule"
									value={gen.schedule}
									id="schedule"
									type="text"
									onChange={(e) => setGen({ ...gen, schedule: e.target.value })}
									placeholder="Horario de la generación"
								/>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="hoursPerWeek">Horas semanales</label>
							<div className="input-new-gen-container">
								<input
									className="input-new-gen"
									name="hoursPerWeek"
									value={gen.hoursPerWeek}
									max={100}
									maxLength={3}
									id="hoursPerWeek"
									step={1}
									type="number"
									onChange={(e) => setGen({ ...gen, hoursPerWeek: parseInt(e.target.value) })}
									placeholder="Horas semanales"
								/>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="totalHours">Total de horas</label>
							<div className="input-new-gen-container">
								<input
									className="input-new-gen"
									name="totalHours"
									value={gen.totalHours}
									max={9999}
									maxLength={4}
									id="totalHours"
									step={1}
									type="number"
									onChange={(e) => setGen({ ...gen, totalHours: parseInt(e.target.value) })}
									placeholder="Total de horas"
								/>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="regularPrice">Tarifa regular</label>
							<div className="input-new-gen-container">
								<input
									className="input-new-gen"
									name="regularPrice"
									value={gen.regularPrice}
									max={9999}
									maxLength={4}
									id="regularPrice"
									step={1}
									type="number"
									onChange={(e) => setGen({ ...gen, regularPrice: parseInt(e.target.value) })}
									placeholder="Precio regular"
								/>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="promoPrice">Tarifa promocional</label>
							<div className="input-new-gen-container">
								<input
									className="input-new-gen"
									name="promoPrice"
									value={gen.promoPrice}
									max={9999}
									maxLength={4}
									id="promoPrice"
									step={1}
									type="number"
									onChange={(e) => setGen({ ...gen, promoPrice: parseInt(e.target.value) })}
									placeholder="Precio promocional"
								/>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="regularPriceUSD">Tarifa regular USD</label>
							<div className="input-new-gen-container">
								<input
									className="input-new-gen"
									name="regularPriceUSD"
									value={gen.regularPriceUSD}
									max={9999}
									maxLength={4}
									id="regularPriceUSD"
									step={1}
									type="number"
									onChange={(e) => setGen({ ...gen, regularPriceUSD: parseInt(e.target.value) })}
									placeholder="Precio regular en USD"
								/>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="promoPriceUSD">Tarifa promocional USD</label>
							<div className="input-new-gen-container">
								<input
									className="input-new-gen"
									name="promoPriceUSD"
									value={gen.promoPriceUSD}
									max={9999}
									maxLength={4}
									id="promoPriceUSD"
									step={1}
									type="number"
									onChange={(e) => setGen({ ...gen, promoPriceUSD: parseInt(e.target.value) })}
									placeholder="Precio promocional en USD"
								/>
							</div>
						</div>
						<div className="new-gen-modal-input-row">
							<label htmlFor="countdownEnabled">Cuenta regresiva</label>
							<div style={{ display: "inline-block", marginLeft: "10px", verticalAlign: "middle" }}>
								<input
									className="input-new-gen"
									name="countdownEnabled"
									checked={gen.countdownEnabled}
									id="countdownEnabled"
									type="checkbox"
									onChange={(e) => setGen({ ...gen, countdownEnabled: e.target.checked })}
									placeholder="Horario de la generación"
								/>
							</div>
						</div>
					</>
				)}
				{newGenSteps === 1 && (
					<>
						<p>Si hay algún asueto o fecha vacacional durante el transcurso de la generación, ingrésalo aquí</p>
						{gen.asuetos.length > 0 ? (
							<>
								{gen.asuetos.map((asueto, index) => {
									return (
										<>
											<label htmlFor={asueto.name}>Fecha de {index + 1} </label>
											<div className="input-new-gen-container" key={"day-off-input-" + index}>
												<InputMask
													required
													className="input-new-gen"
													type="text"
													name={asueto.name}
													id={asueto.name}
													value={asueto.date}
													onChange={(e) => onChangeAsuetos(e, index)}
													placeholder="dd/MM/aaaa"
													mask="99/99/9999"
													maskChar=""
												></InputMask>
											</div>
										</>
									);
								})}
								<button className="secondary-action mr-2" onClick={deleteAsueto}>
									Remover
								</button>
							</>
						) : (
							<></>
						)}
						<button className="primary-action" onClick={moreAsuetos}>
							Agregar asueto
						</button>
					</>
				)}
				{newGenSteps === 2 && (
					<>
						<p>
							Como esta es una generación presencial, selecciona si quieres agregarle los archivos desde cero o selecciona la
							generación para copiárselos.
						</p>
						<div className="select-input-container">
							<select name="select-copy" id="select-copy" value={copy} onChange={(e) => setCopy(e.target.value)}>
								<option value="new">Desde cero</option>
								{gens.length > 0 &&
									gens.map((gen, index) => {
										return (
											<option value={gen.id} key={"gen-select-option-" + index}>
												{gen.name.replace("Programa de Alto Rendimiento en 8 Idiomas Simultáneos - ", "")}
											</option>
										);
									})}
							</select>
						</div>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				{loading === false ? (
					<>
						{newGenSteps === 0 && (
							<button className="secondary-action" onClick={props.onHide}>
								Cancelar
							</button>
						)}
						{newGenSteps >= 1 && (
							<button className="secondary-action" onClick={() => setNewGenSteps(newGenSteps - 1)}>
								Atrás
							</button>
						)}
						{((newGenSteps < 1 && isGenOnline(gen.type)) || (newGenSteps < 2 && isGenInPerson(gen.type))) && (
							<button className="primary-action" onClick={() => setNewGenSteps(newGenSteps + 1)}>
								Continuar
							</button>
						)}
						{((newGenSteps === 1 && isGenOnline(gen.type)) || (newGenSteps === 2 && isGenInPerson(gen.type))) && (
							<button className="primary-action" onClick={getCopyInfo}>
								Crear
							</button>
						)}
					</>
				) : (
					<div className="w-100">
						<div className="ml-auto mr-4" style={{ width: "fit-content" }}>
							<div className="spinner-border text-danger" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					</div>
				)}
			</Modal.Footer>
		</Modal>
	);
}
