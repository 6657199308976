import { addDoc, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from 'initFirebase';

export async function getFormInfoByUserId(Id:string) {
    const q = query(collection(db, "forms"), where("userId", "==", Id));
	const qSnap = await getDocs(q);	
	if (qSnap.size > 0) {
		const form = {
            ...qSnap.docs[0].data(),
                id: qSnap.docs[0].id,
        }
        return form
	} else {
		return null;
	}
}

export async function getForm2InfoByUserId(Id:string) {
    const q = query(collection(db, "forms2"), where("userId", "==", Id));
	const qSnap = await getDocs(q);	
	if (qSnap.size > 0) {
		const form = {
            ...qSnap.docs[0].data(),
                id: qSnap.docs[0].id,
        }
        return form
	} else {
		return null;
	}
}