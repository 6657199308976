import "./ProgramMainInfo.scss";
import { ReactComponent as HablaIcon } from "@assets/icons/habla.svg";
import { ReactComponent as EscribeIcon } from "@assets/icons/escribe.svg";
import { ReactComponent as LeeIcon } from "@assets/icons/lee.svg";
import { ReactComponent as TraduceIcon } from "@assets/icons/traduce.svg";
import { ReactComponent as InterpretaIcon } from "@assets/icons/interpreta.svg";

const skills = [HablaIcon, EscribeIcon, LeeIcon, TraduceIcon, InterpretaIcon];

export const ProgramMainInfo = () => {
	return (
		<div className="program-main-info-main-container">
			<div className="header">
				<h2 className="one">
					<span>1</span> curso
				</h2>
				<div className="separator"></div>
				<h2 className="two">
					<span>8</span> idiomas
				</h2>
				<div className="separator"></div>
				<h2 className="three">
					<span>60</span> semanas
				</h2>
			</div>
			<div className="description">
				En cada seminario verás los ocho idiomas y practicarás todas las habilidades necesarias para expresarse en un idioma
				extranjero.
			</div>
			<div className="skills">
				{skills.map((Skill, i) => {
					return (
						<div className={"item item" + (i + 1)}>
							<Skill key={i} />
						</div>
					);
				})}
			</div>
		</div>
	);
};
