import { useEffect, useState } from "react";
import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { db } from 'initFirebase';
import parse from 'html-react-parser';
import { AdminIcon, Logo2 } from "assets/Imports";

function ConsultAnswers({ consultId, cardIndex, user }) {


    const [loading, setLoading] = useState(true);
    const [consultAnswers, setConsultAnswers] = useState<Array<consultAnswer>>([]);

    interface consultAnswer {
        name: string;
        id: string;
        userId: string;
        answerText: string;
        date: { seconds: number, nanoseconds: number };
        userType: number;
        profileColor: string;
    }

    const getConsultAnswers = async () => {
        let answersArray = new Array();
        const q = query(collection(db, "consultAnswer"), where('consultId', '==', consultId), where('verified', '==', true), orderBy('date', 'asc'), limit(3));

        const snap = await getDocs(q);

        snap.forEach((answer) => {
            answersArray.push({ ...answer.data(), id: answer.id })
        });

        setConsultAnswers(answersArray);
        setLoading(false)
    }

    useEffect(() => {
        getConsultAnswers();
    }, [cardIndex])

    if (loading) {
        return (
            <div className='post-container' style={{ height: 100, justifyContent: 'center', alignItems: 'center' }}>
                <div className="spinner-border text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    function getInitials(name: string) {
        const initials = name.split(' ');
        let fullInitials = '';

        initials.forEach((word) => {
            if (word !== '') {
                fullInitials += word[0];
            }
        })

        return (fullInitials[0] + '' + (fullInitials.length > 1 ? fullInitials[1] : ''))
    }

    return (
        <>
            {consultAnswers.length > 0 ? (
                <>
                    {consultAnswers.map((answer, index) => {
                        return (
                            <div className='post-container' key={'consultAnswer-' + index}>
                                <div className='profile-pic-container' style={{ backgroundColor: answer.profileColor }}>
                                    {answer.userType === 2 ? (
                                        <img src={Logo2} alt="logo academia de lenguas internacionales" />
                                    ) : (
                                        <h3>{getInitials(answer.name)}</h3>
                                    )}
                                </div>
                                <div className='post-text-container'>
                                    <div className='post-info-container'>
                                        {answer.userType === 2 && (
                                            <img src={AdminIcon} alt="sombrero de graduacion" />
                                        )}
                                        {answer.userType === 2 ? (
                                            <h3 className="admin">Academia de Lenguas Internacionales</h3>
                                        ) : (
                                            <h3>{answer.name}</h3>
                                        )}
                                        {user.userType === 2 && (
                                            <p>{new Date(answer.date.seconds * 1000).toLocaleDateString('en-gb')} - {new Date(answer.date.seconds * 1000).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}</p>
                                        )}
                                    </div>
                                    <div className='text-container'>
                                        {parse(answer.answerText)}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export default ConsultAnswers;