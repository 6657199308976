import { useEffect, useState, useContext } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import { FiEdit2 } from "react-icons/fi";
import {
	SearchIcon,
	CheckmarIcon,
	CheckmarkCircle,
	EditIcon,
	CloseCircle,
	EditIcon2,
	Arrow,
	Eye,
	DinamicTextBack,
	Menus,
} from "assets/Imports";

import Select from "react-select";
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	orderBy,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import { getNumsAndDatesByGenId, Seminary, getGens, Gens } from "services/GenerationService";
import PhoneInput from "react-phone-number-input/mobile";
import es from "react-phone-number-input/locale/es.json";
import {
	CheckmarkIcon,
	StreakFlameIcon,
	SurveyIcon1,
	SurveyIcon2,
	TrashIcon,
	book,
	openBook,
	book_normal,
	Book_normal_white,
	Dollar,
	Dollar_white,
	Cardd,
	Card_white,
	InvoiceAdminIcon,
	Ticker,
	Ticket_white,
	User,
	InvoicesAdmin_White,
	User_white,
	Users,
	Users_white,
	Dinamics,
	Dinamics_white,
	Notification_white,
	Docs,
	Docs_white,
	Video,
	Video_white,
	Menu_white,
	Notification,
	Arrow_next,
} from "assets/Imports";
import "./stylesModal.scss";
import "./stylesModal.css";
import "../../CollaboratorContainer.scss";
import "../../../VocabularyContainer/components/Generations/GenerationsCardStyle.scss";
import DynamicText from "./DynamicalText";
import { MenusDataContext } from "../../context/menu-data.provider";
import { setConstantValue } from "typescript";
import { db } from "initFirebase";
import useCounter from "@hooks/collaborators/UseCounter";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "@hooks/useAuth";
export function SeccionsModal(props) {
	const { show, Hide, handleRemoveMenu, selectedUser, selectedMenu, setSelectedMenus, setSelectedItems, selectedItems } = props;
	const [showDinamycalText, setDinamycalText] = useState(false);
	const { rutasAccesibles,rutasAccesiblesColb } = useContext(MenusDataContext);
	const [selectedIds, setSelectedIds] = useState<any>([]);
	const { counters, initCounter, decrementCounter, incrementCounter } = useCounter();
	const [defaultSelectedIds, setDefaultSelectedIds] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(true);
	// Agrega un nuevo estado para mantener la copia de seccionsCollaboratorId
	const [userSections, setUserSections] = useState<any>(selectedUser?.seccionsCollaboratorId || []);
	const [childrenRoutes, setChildrenRoutes] = useState<any>();

	const { user } = useAuth().user;

	const StatusTitleRoute = {
		NA: "NA",
	};

	const { NA } = StatusTitleRoute;

	useEffect(() => {
		if (props.show) {
			setDefaultSelectedIds(selectedIds);
		}
	}, [props.show, selectedIds]);

	const handleDivClick = (id) => {
		let tempArray = JSON.parse(JSON.stringify(userSections));
		const findIndex = tempArray.indexOf(id);
		if (findIndex !== -1) {
			tempArray.splice(findIndex, 1);
			decrementCounter(id);
		} else {
			tempArray.push(id);
			incrementCounter(id);
		}
		setUserSections(tempArray);
	};

	const handlesave = async () => {
		try {
			props.onAddMenu(userSections);
			//setSelectedIds([]);
		} catch (error) {
			console.error("Error al guardar los datos: ", error);
			//toast.error("Error al guardar los datos: ");
		}
	};

	function getIconNormal(value) {
		switch (value) {
			case "/colaboradores":
				return User;
			case "/invoice":
				return InvoicesAdmin_White;
				break;
			case "":
				return openBook;
			case "/material":
				return book_normal;
			case "/actualizar-pagos":
				return Dollar;
			case "/historial-de-pagos":
				return Cardd;
			case "/contacto":
				return Users;
			case "/textos":
				return Dinamics;
			case "/consultas":
				return Notification;
			case "/vocabulario":
				return Menus;
			case "/formularios":
				return Docs;
			case "/videos":
				return Video;
			default:
				return undefined; // Devuelve undefined en caso de no coincidencia
		}
	}
	function getIconWhite(value) {
		switch (value) {
			case "/colaboradores":
				return Users_white;
			case "/invoice":
				return InvoiceAdminIcon;
				break;
			case "":
				return book;
			case "/material":
				return Book_normal_white;
			case "/actualizar-pagos":
				return Dollar_white;
			case "/historial-de-pagos":
				return Card_white;
			case "/contacto":
				return User_white;
			case "/textos":
				return Dinamics_white;
			case "/consultas":
				return Notification_white;
			case "/vocabulario":
				return Menu_white;
			case "/formularios":
				return Docs_white;
			case "/videos":
				return Video_white;
			default:
				return undefined; // Devuelve undefined en caso de no coincidencia
		}
	}

	function getIconLefth(value) {
		switch (value) {
			case "/textos":
				return Arrow_next;
			default:
				return undefined;
		}
	}

	function getOnclickEvent(value) {
		switch (value) {
			case "/textos":
				return setDinamycalText(true);
			default:
				return null;
		}
	}
	useEffect(() => {
		if (show && selectedMenu) {
			const tempIds: any = [];
			selectedMenu.map((section: any) => {
				tempIds.push(section.id);
			});
			setUserSections(tempIds);
			setLoading(false);
		}
	}, [show]);

	useEffect(() => {
		if (show && rutasAccesiblesColb) {
			rutasAccesiblesColb.forEach((ruta) => {
				initCounter(ruta.id);
			});
		}
	}, [rutasAccesibles, show]); // dependencia en rutasAccesibles

	const renderRutasAccesibles = () => {
		return rutasAccesiblesColb.map((item) => (
			<>
				{item.Titulo === NA ? null : (
					<div
						key={item.id}
						className={`generations-card sections  card_sessions ${userSections.indexOf(item.id) !== -1 ? "selected" : ""}`}
						onClick={() => {
							handleDivClick(item.id);
						}}
						style={{
							marginBottom: "10px",
							padding: "10px",
							display: "flex",
							alignItems: "center",
						}}
					>
						<Row>
							<Col xs={12} md={6} className="text-center">
								<div className="h2-container">
									<div style={{ display: "flex", alignItems: "center" }}>
										{userSections.indexOf(item.id) !== -1 ? (
											<img src={getIconWhite(item.Ruta)} alt="Icono" style={{ width: "20px", height: "20px" }} />
										) : (
											<img src={getIconNormal(item.Ruta)} alt="Icono" style={{ width: "20px", height: "20px" }} />
										)}
										<div style={{ marginLeft: "10px", maxWidth: "300px" }}>
											<div className="d-flex">
												<h2
													className="first-h2"
													style={{
														fontSize: "16px",
														display: "flex",
														cursor: "pointer",
														whiteSpace: "nowrap",
														
														textOverflow: "ellipsis",
														marginLeft: "5%",
													}}
													onClick={(event) => {
														event.stopPropagation(); // Añade esta línea
														getOnclickEvent(item.Ruta);
													}}
												>
													{item.Titulo}
													{getIconLefth(item.Ruta) && (
														<img src={getIconLefth(item.Ruta)} alt="Icono" style={{ width: "20px", height: "20px" }} />
													)}
												</h2>
											</div>
											<h2
												className="second-h2"
												style={{
													fontWeight: "200",
													fontFamily: "Poppins",
													fontSize: "14px",
													display: "flex",
													alignItems: "center",
													marginLeft: "8px",
													cursor: "pointer",
												}}
											>
												{Object.keys(counters).length > 0 && (
													<span style={{ marginRight: "4px", cursor: "pointer" }}>{`${counters[item.id]}`}</span>
												)}
												<span style={{ cursor: "pointer" }}>Colaboradores</span>
											</h2>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				)}
			</>
		));
	};

	return (
		<Modal show={show} onHide={Hide} dialogClassName="user-profile-modal" scrollable={true}>
			<Modal.Header className="header" closeButton>
				<Modal.Title className="title">
					<div>
						<Row>
							<Col>
								<h3 style={{ fontSize: "2rem !important", marginLeft: "20px" }}>Secciones</h3>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="name-streak" style={{ marginLeft: "20px", marginTop: "2%", display: "-ms-inline-grid" }}>
									<h2 style={{ fontSize: "28px" }}>{selectedUser?.NameCollaborator}</h2>
									<span style={{ fontSize: "15px", fontWeight: "normal", color: "black" }}>
										Selecciona las secciones del admin en las que el colaborador tendrá acceso
									</span>
								</div>
							</Col>
						</Row>
					</div>
				</Modal.Title>
			</Modal.Header>

			{!loading && (
				<Modal.Body className="modal-body">
					{showDinamycalText === true ? (
						<DynamicText
							back={() => {
								setDinamycalText(false);
							}}
							setSelectedItems={setSelectedItems}
							selectedItems={selectedItems}
						/>
					) : (
						<>
							<div>{renderRutasAccesibles()}</div>
						</>
					)}
				</Modal.Body>
			)}

			<Modal.Footer>
				<div className="ml-auto">
					<button
						className="btn btn-secondary secondary-action"
						onClick={() => {
							setSelectedIds(defaultSelectedIds);
							props.Hide();
						}}
					>
						Cancelar
					</button>
					<button
						className="btn btn-secondary primary-action ml-2"
						onClick={() => {
							handlesave();
							props.Hide();
						}}
					>
						Guardar
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}
