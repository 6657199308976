import { useEffect, useState } from "react";
import { collection, query, where, getDocs, getDoc, doc, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { DocumentData } from "firebase/firestore/lite";
import toast, { Toaster } from "react-hot-toast";

const GetRoles = async () => {
	try {
		const querySnapshot = await getDocs(collection(db, "Roles_Collaborators"));
		const rolesData = querySnapshot.docs.map((doc) => ({
			id: doc.id,
			roleName: doc.data().RoleName,
			shortName: doc.data().ShortName,
		}));
		return rolesData;
	}catch (error) {
		console.log("Error al obtener los roles:", error);
		return [];
	}
};

const AddRole = async (newRole) => {
	try {
		// Agregar el nuevo rol a la colección con un ID automático
		await addDoc(collection(db, "Roles_Collaborators"), {
			RoleName: newRole.roleName,
		});
		//console.log("Nuevo rol agregado exitosamente");//
		toast.success("Nuevo rol agregado exitosamente");
	} catch (error) {
		console.error("Error al agregar el nuevo rol:", error);
		toast.error("No se agrego el nuevo rol");
	}
};

const updateRole = async (roleId, updatedRole) => {
	try {
		// Acceder al documento específico en la colección
		const docRef = doc(db, "Roles_Collaborators", roleId);

		// Actualizar los campos del rol
		await updateDoc(docRef, {
			RoleName: updatedRole.roleName,
		});
		// Enviar una notificación de éxito
		toast.success(`Se ah actualizado exitosamente el rol:  ${updatedRole.roleName}`);
	} catch (error) {
		console.error("Error al actualizar el rol:", error);
		toast.error("No se pudo actualizar el rol");
	}
	return updateRole;
};

const deleteRole = async (roleId) => {
	try {
		const docRef = doc(db, "Roles_Collaborators", roleId);
		await deleteDoc(docRef);
		toast.success(`El rol ha sido eliminado exitosamente`);
		 // Resuelve la promesa cuando se complete la eliminación
	} catch (error) {
		console.error("Error al eliminar el rol:", error);
		toast.error("No se pudo eliminar el rol");
		// Rechaza la promesa si hay un error
	}
};

export const UseRole = { GetRoles, AddRole, updateRole, deleteRole };
