import { collection, getDocs, query, where, orderBy, getDoc, doc, or, and } from "firebase/firestore";
import { db } from "initFirebase";
import { GenTypes, TGeneration } from "interfaces/Generations";

export interface Seminary {
	id: string;
	date: string;
	num: number;
}

export interface Gens {
	id: string;
	name: string;
	type?: string;
	schedule?: string;
}

export async function getSeminaryMaterialByNum(generationId: string, seminaryNum: number) {
	const q = query(collection(db, "generations", generationId, "material"), where("num", "==", seminaryNum));
	const qSnap = await getDocs(q);

	if (qSnap.size > 0) {
		const seminary = {
			...qSnap.docs[0].data(),
			id: qSnap.docs[0].id,
		} as Seminary;
		return seminary;
	} else {
		return null;
	}
}

export async function getDatesByGenId(generationId: string) {
	const q = query(collection(db, "generations", generationId, "material"), orderBy("num"));
	const qSnap = await getDocs(q);
	let dates: any = [];

	if (qSnap.size > 0) {
		qSnap.docs.map((material, index) => {
			dates.push(material.data().date);
			return null;
		});
		return dates;
	} else {
		return null;
	}
}

export async function getNumsAndDatesByGenId(generationId: string) {
	const q = query(collection(db, "generations", generationId, "material"), orderBy("num"));
	const qSnap = await getDocs(q);
	let genArray: Seminary[] = [];
	if (qSnap.size > 0) {
		qSnap.docs.map((material) => {
			let object: Seminary = { id: material.data().id, date: material.data().date, num: material.data().num };
			genArray.push(object);
			return null;
		});
		return genArray;
	} else {
		return null;
	}
}

export async function getSeminaryNumberById(generationId: string, seminaryId: string) {
	const q = doc(db, "generations", generationId, "material", seminaryId);
	const qSnap = await getDoc(q);
	if (qSnap.exists()) {
		return qSnap.data().num;
	} else {
		return null;
	}
}

export async function getGenerationNameById(generationId: string) {
	const q = doc(db, "generations", generationId);
	const qSnap = await getDoc(q);
	if (qSnap.exists()) {
		return qSnap.data().name;
	} else {
		return null;
	}
}

export async function getGens() {
	const q = query(collection(db, "generations"));
	const qSnap = await getDocs(q);
	let gens: Gens[] = [];
	if (qSnap.size > 0) {
		qSnap.docs.map((material, index) => {
			let object = { id: material.data().id, name: material.data().name, schedule: material.data().schedule };
			gens.push(object);
			return null;
		});
		return gens;
	} else {
		return [null];
	}
}

// Obtener generaciones que estan abiertas a inscripcion (active = true)
export const getActiveGenerations = async ():Promise<Array<TGeneration>> => {
	const q1 = query(collection(db, "generations"), where("active", "==", true));
	const snap = await getDocs(q1);
	
	let tempActiveGens:Array<TGeneration> = []

	snap.docs.map((doc) => {
		let docData = doc.data();
		let genObj:TGeneration = {
			id: doc.id,
			...docData
		} as TGeneration
		tempActiveGens.push(genObj)
		return null;
	})
	return tempActiveGens
};

export const getActiveOnlineGenerations = async (): Promise<Array<TGeneration>> => {
	// Query to get online generations searching for type equal to "online", "online-am" or "online-pm"
	const activeOnlineGenerationsQuery = query(
		collection(db, "generations"),
		and(
			where("active", "==", true),
			or(where("type", "==", GenTypes.OnlineAM), where("type", "==", GenTypes.OnlinePM), where("type", "==", "online"))
		)
	);
	try {
		const querySnapshot = await getDocs(activeOnlineGenerationsQuery);
		let generations: TGeneration[] = [];

		if (!querySnapshot.empty) {
			querySnapshot.docs.map((generationDoc) => {
				const data: TGeneration = generationDoc.data() as TGeneration;
				generations.push(data);
				return null;
			});
		}
		return generations;
	} catch (error: any) {
		throw new Error(error.message);
	}
};

export const getActiveInPersonGenerations = async (): Promise<Array<TGeneration>> => {
	// Query to get online generations searching for type equal to "online", "online-am" or "online-pm"
	const activeOnlineGenerationsQuery = query(
		collection(db, "generations"),
		where("active", "==", true),
		where("type", "==", GenTypes.InPerson)
	);
	try {
		const querySnapshot = await getDocs(activeOnlineGenerationsQuery);
		let generations: TGeneration[] = [];

		if (!querySnapshot.empty) {
			querySnapshot.docs.map((generationDoc) => {
				const data: TGeneration = generationDoc.data() as TGeneration;
				generations.push(data);
				return null;
			});
		}
		return generations;
	} catch (error: any) {
		throw new Error(error.message);
	}
};


