import { useState } from "react";
import "./ContactCard.scss";
import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "initFirebase";
import { useLocation } from "react-router-dom";
import { EyeIcon, GrayTrashIcon, ReplyArrow, TrashIcon, UnreadEyeIcon, MailIcon } from "assets/Imports";

function ContactCard(props) {
	const {
		data,
		setRefresh,
		refresh,
		setAScroll,
		aScroll,
		setReplyContactModal,
		setShowReply,
		setContactToReply,
		setContactNotificationSeen,
		highlighted,
		checking,
	} = props;
	const date = new Date(data.created.seconds * 1000);
	const [check, setCheck] = useState(false);
	const [loading, setLoading] = useState(false);
	const handleReply = () => {
		setContactToReply(data);
		setReplyContactModal(true);
	};

	const setReaded = async () => {
		setLoading(true);
		const letterRef = doc(db, "newsletter", data.id);
		if (data.status === "pending") {
			await updateDoc(letterRef, {
				status: "resolved",
			}).then(async () => {
				const notificationQuery = query(collection(db, "notifications"), where("newsLetterId", "==", data.id));
				const snap = await getDocs(notificationQuery);
				if (snap.size > 0) {
					const notificationRef = doc(db, "notifications", snap.docs[0].id);
					await updateDoc(notificationRef, {
						check: true,
					});
				}
				await setContactNotificationSeen(data.id);
			});
		} else {
			await updateDoc(letterRef, {
				status: "pending",
			});
		}
		setRefresh(!refresh);
		setLoading(false);
	};

	const deleteMessage = async () => {
		if (
			window.confirm("¿Estás seguro de que deseas eliminar esta solicitud de contacto? No es posible rehacer esta acción") ===
			true
		) {
			await deleteDoc(doc(db, "newsletter", data.id));
			setRefresh(!refresh);
		}
	};

	if (loading === false) {
		return (
			<div
				className={`payment-history-card-container ${highlighted ? "highlighted" : ""}`}
				style={{ flexDirection: "column" }}
				id={"contact-card-" + data.id}
			>
				<div className="payment-history-card-container-box">
					<div className="payment-history-right-content-card-container">
						<div className="text-payment-history-container">
							<p id="nameContactCard" className={data.status === "resolved" ? "resolved-text" : ""}>
								{data.name}
							</p>
							<p className={"textContactCard prefered " + (data.status === "resolved" ? "resolved-text" : "")}>
								Método preferido de contacto: {data.prefered}
							</p>
							<p className={"textContactCard" + (data.status === "resolved" ? "resolved-text" : "")}>{data.email}</p>
							<p className={"textContactCard" + (data.status === "resolved" ? "resolved-text" : "")}>{data.phone}</p>
						</div>
					</div>

					<div className="payment-history-left-content-card-container" style={{ alignSelf: "flex-end" }}>
						<p className={data.status === "resolved" ? "resolved-text" : "realizacion-date"} style={{ textAlign: "right" }}>
							{date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}
						</p>
						<div className="respond-read-container">
							<div onClick={handleReply}>
								<img src={ReplyArrow}></img>
								<span style={{ paddingRight: "10px" }}>Responder</span>
							</div>

							{data.answered && data.status === "resolved" ? (
								<div
									onClick={() => {
										setShowReply(true);
										setContactToReply(data);
									}}
								>
									<img src={MailIcon}></img>
									<span style={{ paddingRight: "10px" }}> {data.response.length <= 1 ? "Ver respuesta" : "Ver respuestas"}</span>
								</div>
							) : null}

							<p
								className={data.status === "resolved" ? "resolved-text" : ""}
								style={{ cursor: "pointer", textAlign: "end" }}
								onClick={setReaded}
							>
								{data.status === "pending" ? (
									<img src={EyeIcon} alt="eye-icon" />
								) : (
									<img className="unread-eye" src={UnreadEyeIcon} alt="unread-eye-icon" />
								)}
								Marcar como leído
							</p>
						</div>

						<p
							className={data.status === "resolved" ? "resolved-text" : ""}
							style={{ textAlign: "end", cursor: "pointer", width: "fit-content", marginLeft: "auto" }}
							onClick={deleteMessage}
						>
							{data.status === "pending" ? (
								<img src={TrashIcon} alt="trash-icon" />
							) : (
								<img className="unread-eye" src={GrayTrashIcon} alt="undelete-trash-icon" />
							)}
							Eliminar
						</p>
					</div>
				</div>
				<div style={{ width: "100%", marginTop: 20 }}>
					<div>
						<p className={data.status === "resolved" ? "resolved-text" : ""}>{data.description}</p>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div style={{ width: "100%", display: "flex", height: 266, justifyContent: "center", alignItems: "center" }}>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}
}

export default ContactCard;
