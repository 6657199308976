import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "initFirebase";
import "./MaterialContainer.css";
import ProgressBarComponent from "@components/ProgressBar/ProgressBarComponent";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import FormModal from "@components/ModalFormUser/FormModal";
import { useSeminariesService } from "../../services/SeminariesService";
import { getDate, getFileName, getPayDate } from "../../functions/Functions";
import { romanize, getModuleNumber } from "../../functions/Functions";
import { AdminGenSelector } from "@components/Admin/AdminGenSelector/AdminGenSelector";
import { compareGenerations } from "functions/Generations/FunctionsGenerations";
import { useGenerations } from "@hooks/GenerationsFilters/useGenerations";

import { OpenBook } from "assets/Imports";
import moment from "moment-timezone";
import { isGenInPerson } from "interfaces/Generations";

function MaterialContainer({ user }) {
	let name;

	if (user !== null) {
		name = user.name.split(" ");
	}

	const history = useHistory();
	const windowUrl = window.location.search;
	const params = new URLSearchParams(windowUrl);
	const seminaryNum: any = params.get("num") !== null ? params.get("num") : 0;
	const [genId, setGenId] = useState<string>(user.generationId);
	const [gen, setGen]: any = useState(null);

	const [refresh, setRefresh] = useState(false);
	const [check, setCheck] = useState(false);
	const [materiales, setMateriales]: any = useState([]);
	const [loading, setLoading] = useState(true);
	const [pastMaterial, setPastMateriales]: any = useState([]);
	const [sesionesPagadas, setSesionesPagadas] = useState(0);
	const [sesionesActuales, setSesionesActuales] = useState(0);
	const [porcentaje, setPorcentaje] = useState(0);
	const [total, setTotal] = useState(0);
	const [formModalShow, setFormModalShow] = useState(false);
	const { paidSeminaries, seminaries }: any = useSeminariesService();

	const { gens, handleCheck, load, eyeColor,setGens, setEyeColor } = useGenerations(compareGenerations);

	const getGenerations = async () => {
		let array: any = [];

		if (user.generationId !== undefined && user.generationId !== "") {
			const docRef = doc(db, "generations", genId);
			const docSnap = await getDoc(docRef);

			getMateriales({ id: docSnap.id, ...docSnap.data() });
		} else {
			setLoading(false);
		}
	};

	const isFormAnswered = () => {
		if (user.formAnswered === false && user.generationId !== "") {
			setFormModalShow(true);
		} else {
			setFormModalShow(false);
		}
	};

	function compare2(a, b) {
		const dateASplit = a.date.split("/");
		const dateBSplit = b.date.split("/");

		const dateAString =
			dateASplit[2] +
			"-" +
			(parseInt(dateASplit[1]) < 10 ? "0" + dateASplit[1] : dateASplit[1]) +
			"-" +
			(parseInt(dateASplit[0]) < 10 ? "0" + dateASplit[0] : dateASplit[0]);
		const dateBString =
			dateBSplit[2] +
			"-" +
			(parseInt(dateBSplit[1]) < 10 ? "0" + dateBSplit[1] : dateBSplit[1]) +
			"-" +
			(parseInt(dateBSplit[0]) < 10 ? "0" + dateBSplit[0] : dateBSplit[0]);

		if (dateAString < dateBString) {
			return -1;
		}
		if (dateAString > dateBString) {
			return 1;
		}
		return 0;
	}

	function compare(a, b) {
		if (a.num > b.num) {
			return -1;
		}
		if (a.num < b.num) {
			return 1;
		}
		return 0;
	}

	const getMateriales = async (data) => {
		let nextMaterial: any = [];
		let pastMaterial: any = [];
		const today = moment();

		data.asuetos.forEach((asueto) => {
			let materialDate = new Date();

			let dateS = asueto.date.split("/");

			dateS[1] = parseInt(dateS[1]) - 1 + "";
			materialDate.setDate(dateS[0]);
			materialDate.setMonth(dateS[1]);
			materialDate.setFullYear(dateS[2]);
			materialDate.setHours(0, 0, 0);

			const materialDateM = moment.tz(`${moment(materialDate).format("YYYY-MM-DD")} 00:00:00`, "America/Mexico_City");

			const as = {
				name: "Asueto",
				date: dateS[0] + "/" + dateS[1] + "/" + dateS[2],
				type: "asueto",
				files: [],
				videoUrl: "",
				num: 0,
			};

			if (materialDateM.isAfter(today) || materialDateM.isSame(today, "date")) {
				nextMaterial.push(as);
			}
		});

		const q = query(collection(db, "generations", data.id, "material"), orderBy("num", "asc"));

		const querySnapshot = await getDocs(q);

		let i = 0;

		querySnapshot.forEach((doc) => {
			let materialDate = new Date();

			i++;
			// doc.data() is never undefined for query doc snapshots
			const data2: any = {
				...doc.data(),
				id: doc.id,
			};

			const endHour = data.endHour.split(":");
			let materialDateSplit = data2.date.split("/");
			// const materialEndDate = moment.tz(`${materialDateSplit[2]}-`)
			materialDate.setDate(materialDateSplit[0]);
			materialDate.setMonth(materialDateSplit[1]);
			materialDate.setFullYear(materialDateSplit[2]);

			const materialEndDate = moment.tz(
				`${moment(materialDate).format("YYYY-MM-DD")} ${endHour[0]}:00:00`,
				"America/Mexico_City"
			);

			if (materialEndDate.isAfter(today)) {
				if (isGenInPerson(data.type)) {
					materialDate.setDate(materialDate.getDate() + 1);
					if (materialEndDate.isAfter(today)) {
						const answerIndex = data2.files.findIndex((e) => e.name.includes("Respuestas"));
						if (answerIndex !== -1) {
							data2.files.splice(answerIndex, 1);
						}

						const apuntesIndex = data2.files.findIndex((e) => e.name.includes("Apuntes"));
						if (apuntesIndex !== -1) {
							data2.files.splice(apuntesIndex, 1);
						}
						data2.videoUrl = "";
					}
				}

				nextMaterial.push(data2);
			} else {
				pastMaterial.push(data2);
			}
			if (i === querySnapshot.size) {
				getPayments(pastMaterial, nextMaterial.sort(compare2));
				setGen(data);
			}
		});
		// setCheck(true);
		// setRefresh(!refresh)
	};

	const getPayments = async (pastMaterial, nextMaterial) => {
		const qry = query(collection(db, "paymentHistory"), where("userId", "==", user.id), where("verified", "==", true));
		const qrySnapshot = await getDocs(qry);

		let i = 0;
		let total = 300 * paidSeminaries;
		let sesionesPagadas = paidSeminaries;
		let sesionesActuales = pastMaterial.length;

		setPorcentaje((sesionesActuales / 60) * 100);

		if (sesionesPagadas < 11) {
			console.log("add");
			let materialIndex = seminaries.findIndex((i) => i.num === 11);
			let nextModuleMaterial = seminaries[materialIndex];
			let payDateString = nextModuleMaterial.date.split("/");
			let payDate = new Date();
			payDate.setDate(payDateString[0]);
			payDate.setMonth(payDateString[1]);
			payDate.setFullYear(payDateString[2]);
			payDate.setDate(payDate.getDate() - 3);
			nextMaterial.push({
				date: payDate.getDate() + "/" + payDate.getMonth() + "/" + payDate.getFullYear(),
				num: 10.5,
				type: "payDay",
			});
		} else if (sesionesPagadas < 26) {
			console.log("add");
			let materialIndex = seminaries.findIndex((i) => i.num === 26);
			let nextModuleMaterial = seminaries[materialIndex];
			let payDateString = nextModuleMaterial.date.split("/");
			let payDate = new Date();
			payDate.setDate(payDateString[0]);
			payDate.setMonth(payDateString[1]);
			payDate.setFullYear(payDateString[2]);
			payDate.setDate(payDate.getDate() - 3);
			nextMaterial.push({
				date: payDate.getDate() + "/" + payDate.getMonth() + "/" + payDate.getFullYear(),
				num: 25.5,
				type: "payDay",
			});
		} else if (sesionesPagadas < 41) {
			console.log("add");
			let materialIndex = seminaries.findIndex((i) => i.num === 41);
			let nextModuleMaterial = seminaries[materialIndex];
			let payDateString = nextModuleMaterial.date.split("/");
			let payDate = new Date();
			payDate.setDate(payDateString[0]);
			payDate.setMonth(payDateString[1]);
			payDate.setFullYear(payDateString[2]);
			payDate.setDate(payDate.getDate() - 3);
			nextMaterial.push({
				date: payDate.getDate() + "/" + payDate.getMonth() + "/" + payDate.getFullYear(),
				num: 40.5,
				type: "payDay",
			});
		} else if (sesionesPagadas < 51) {
			console.log("add");
			let materialIndex = seminaries.findIndex((i) => i.num === 51);
			let nextModuleMaterial = seminaries[materialIndex];
			let payDateString = nextModuleMaterial.date.split("/");
			let payDate = new Date();
			payDate.setDate(payDateString[0]);
			payDate.setMonth(payDateString[1]);
			payDate.setFullYear(payDateString[2]);
			payDate.setDate(payDate.getDate() - 3);
			nextMaterial.push({
				date: payDate.getDate() + "/" + payDate.getMonth() + "/" + payDate.getFullYear(),
				num: 50.5,
				type: "payDay",
			});
		}

		setTotal(total);
		setMateriales(nextMaterial.sort(compare2));
		setSesionesPagadas(sesionesPagadas);
		setSesionesActuales(sesionesActuales);
		setPastMateriales(pastMaterial.sort(compare));
		setCheck(true);
		setLoading(false);
	};

	const scrollToMaterial = () => {
		if (seminaryNum !== null && seminaryNum != "0") {
			let scroll = window.document.getElementById("material-" + seminaryNum);

			if (scroll !== null) {
				scroll.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
			}
		}
	};

	const getGenerationsAdmin = async () => {
		const q = query(collection(db, "generations"));
		const qs = await getDocs(q);

		const genArray = new Array();

		qs.docs.map((gen) => {
			genArray.push({ ...gen.data(), id: gen.id });
		});

		setGens(genArray);
	};

	useEffect(() => {
		isFormAnswered();
		getGenerations();
		if (user.userType === 2) {
			getGenerationsAdmin();
		}
	}, []);

	useEffect(() => {
		if (!loading && user.userType === 2) {
			setLoading(true);
			getGenerations();
		}
	}, [genId]);

	useEffect(() => {
		if (!loading) {
			scrollToMaterial();
		}
	}, [loading]);

	if (loading) {
		return (
			<div style={{ width: "100%", display: "flex", height: "50vh", justifyContent: "center", alignItems: "center" }}>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	if (loading)
		return (
			<div style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<div className="spinner-border text-danger" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);

	return (
		<>
			<FormModal show={formModalShow} onHide={() => setFormModalShow(false)} user={user} />
			{user.userType === 2 && (
				<AdminGenSelector
					setGenId={setGenId}
					genId={genId}
					load={load}
					handleCheck={handleCheck}
					gens={gens}
					eyeColor={eyeColor}
					setEyeColor={setEyeColor}
				/>
			)}
			<div style={{ marginBottom: 40 }}>
				<h1>¡Bienvenido, {name[0]}!</h1>
			</div>
			{total === 0 ? (
				<>
					<h2 style={{ marginTop: 80, textAlign: "center" }}>Aún no te encuentras inscrito a ningún programa</h2>
					<div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 40 }}>
						<p>Comienza tu experiencia</p>
						<button onClick={() => history.push("/inscripcion")} className="tertiary return-btn-style">
							Inscríbete
						</button>
					</div>
				</>
			) : (
				<>
					{sesionesPagadas >= 10 ? (
						<>
							<div className="header-material-container">
								<h2>{gen.name}</h2>
								<div>
									<p style={{ margin: 0 }}>{porcentaje.toFixed(2)}%</p>
									<ProgressBarComponent now={porcentaje} totalPay={sesionesActuales} type={"class"} />
									<a className="total-sesions-text">60 seminarios</a>
								</div>
							</div>
							{materiales.length > 0 && <h2>Próximos seminarios</h2>}
							{JSON.parse(JSON.stringify(materiales))
								.slice(1, 3)
								.sort(compare)
								.map((material, index) => {
									return (
										<>
											<div id={"material-" + material.num} className="next-class-card" key={index}>
												<div className="block-next-seminary-card">
													{material.type !== undefined && material.type === "payDay" ? (
														<>
															<h2 style={{ color: "#ca5050", fontSize: "1.5rem", margin: 0, minWidth: 300, marginBottom: 10 }}>
																Módulo {getModuleNumber(material.num)} <br />{" "}
																<span style={{ color: "#00000080", width: "100%", fontSize: "1.25rem" }}>
																	Fecha limite de inscripción
																</span>
															</h2>
														</>
													) : (
														<>
															{material.type === "asueto" ? (
																<h2 style={{ color: "#ca5050", fontSize: "1.5rem", margin: 0, minWidth: 300 }}>
																	{material.name}
																</h2>
															) : (
																<h2 style={{ color: "#ca5050", fontSize: "1.5rem", margin: 0, minWidth: 300 }}>
																	Módulo {getModuleNumber(material.num)} -{" "}
																	<span style={{ fontFamily: "auto" }}>{romanize(material.num)}</span>{" "}
																	<FontAwesomeIcon icon={faLock as any} />
																</h2>
															)}
														</>
													)}
													{material.type !== undefined && material.type === "payDay" && (
														<Link to={"/micuenta/historial-de-pagos"}>
															<button className="return-btn-style rfs-1">Inscribirme</button>
														</Link>
													)}
													<p className="regular-text next-pay-text-date">{getDate(material.date)}</p>
												</div>
											</div>
										</>
									);
								})}

							{(materiales.length > 0 && materiales[0].num <= paidSeminaries) ||
							(materiales.length === 0 && pastMaterial[0].num === paidSeminaries) ? (
								<>
									{materiales[0] !== undefined && (materiales[0].type === undefined || materiales[0].type === "asueto") && (
										<div id={"material-" + materiales[0].num} className="next-class-card" style={{ border: "2px solid #ca5050" }}>
											<div>
												<p className="regular-text" style={{ color: "#ca5050", fontSize: "1.5rem", margin: 0 }}>
													Siguiente seminario:
												</p>
											</div>
											<div style={{ marginBottom: 10 }}>
												{materiales[0].type === "asueto" ? (
													<h2 style={{ color: "#212529", fontSize: "1.5rem", margin: 0, textAlign: "center" }}>Asueto</h2>
												) : (
													<div className="pastMaterialNumbersContainer">
														<h3 style={{ color: "#ca5050", margin: 0 }}>Módulo {getModuleNumber(materiales[0].num)}</h3>
														<h2 className="seminaryNumberMateriales">{romanize(materiales[0].num)}</h2>
													</div>
												)}
											</div>
											<div className="date-next-material-container">
												<p className="regular-text">{getDate(materiales[0].date)}</p>
											</div>
											{materiales[0].videoUrl !== "" && (
												<div>
													{materiales[0].videoUrl !== "" && (
														<>
															{/* {(materiales[0].videoUrl.includes(".m3u8")) ? (
                                                                <Link to={"/micuenta/grabaciones/" + materiales[0].id}>
                                                                    <button className="see-recordings-btn"><img srsc="@img/camera-icon.svg" alt="icono de camara de video" /> Ver grabación</button>
                                                                </Link>
                                                            ) : (
                                                                <p className="regular-text text-bolder">Ver en Youtube: <a href={materiales[0].videoUrl}>{materiales[0].videoUrl}</a></p>
                                                            )} */}
														</>
													)}
													{materiales[0].videoUrl2 !== undefined && materiales[0].videoUrl2 !== "" && (
														<p className="regular-text text-bolder">
															Ver en Youtube: <a href={materiales[0].videoUrl2}>{materiales[0].videoUrl2}</a>
														</p>
													)}
												</div>
											)}
											{materiales[0].notes !== undefined && materiales[0].notes !== "" && (
												<div>
													<p className="regular-text mb-0 text-bolder">Notas:</p>
													<p className="regular-text" style={{ whiteSpace: "pre-wrap" }}>
														{materiales[0].notes}
													</p>
												</div>
											)}
											<div style={{ display: "flex" }} className={"material-container justify-content-center"}>
												{materiales[0].files.map((file, index) => {
													return (
														<a
															style={{ textDecoration: "none" }}
															href={file.linkFile}
															target="_blank"
															download={true}
															key={index}
														>
															<button className="material-file-button">
																{" "}
																<img src={OpenBook} alt="document-attach-icon" style={{ marginRight: 10 }} />
																{getFileName(file.name)}
															</button>
														</a>
													);
												})}
											</div>
										</div>
									)}
								</>
							) : (
								<div
									id={"material-" + materiales[0].num}
									className="next-class-card pay-day"
									style={{ border: "2px solid #ca5050", display: "flex", alignItems: "center", justifyContent: "space-between" }}
								>
									<h2
										style={{
											color: "#ca5050",
											fontSize: "1.5rem",
											margin: 0,
											minWidth: 300,
											marginBottom: 10,
											textAlign: "center",
										}}
									>
										Módulo {getModuleNumber(materiales[0].num)} <br />{" "}
										<span style={{ color: "#00000080", width: "100%", fontSize: "1.25rem" }}>Fecha límite de inscripción</span>
									</h2>
									<Link to={"/micuenta/historial-de-pagos"}>
										<button className="return-btn-style rfs-1">Inscribirme</button>
									</Link>
									<p className="regular-text next-pay-text-date">{getPayDate(materiales[0].date)}</p>
								</div>
							)}
							{pastMaterial.length > 0 && <h2 style={{ marginTop: 20 }}>Seminarios anteriores</h2>}
							{pastMaterial.map((pastMat, index) => {
								return (
									<div id={"material-" + pastMat.num} className="next-class-card" key={"materiales-anteriores-card" + index}>
										<div className="pastMaterialNumbersContainer">
											<h3 style={{ color: "#ca5050", margin: 0 }}>Módulo {getModuleNumber(pastMat.num)}</h3>
											<h2 className="seminaryNumberMateriales">{romanize(pastMat.num)}</h2>
										</div>
										<div style={{ display: "flex", justifyContent: "space-between" }}>
											<p className="regular-text">{getDate(pastMat.date)}</p>
										</div>
										{pastMat.num <= sesionesPagadas ? (
											<>
												<div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
													{pastMat.videoUrl !== "" && (
														<>
															{/* {(pastMat.videoUrl.includes(".m3u8")) ? (
                                                                <Link to={"/micuenta/grabaciones/" + pastMat.id}>
                                                                    <button className="see-recordings-btn"><img srdc="@img/camera-icon.svg" alt="icono de camara de video" /> Ver grabación</button>
                                                                </Link>
                                                            ) : (
                                                                <p className="regular-text text-bolder">Ver en Youtube: <a href={pastMat.videoUrl}>{pastMat.videoUrl}</a></p>
                                                            )} */}
														</>
													)}
													{/* <div className="posision-infobox" >
                                                        <div className="question-icon3">
                                                            ?
                                                            <div className="info-box3">
                                                                <p>
                                                                    ¿Cómo acceder a las grabaciones mediante Youtube?<p />

                                                                    Paso I: En YouTube, inicia sesión desde: {user.email}.<p />

                                                                    Paso II: Una vez iniciada sesión, abre el enlace directo. Puedes hacer clic en el o copiar y pegar el vínculo en tu navegador.                                </p>
                                                            </div>
                                                        </div>
                                                    </div> */}

													{pastMat.videoUrl2 !== undefined && pastMat.videoUrl2 !== "" && (
														<p className="regular-text text-bolder contain-youtube">
															<div className="info-contain">
																<div className="text-youtube">Ver en Youtube:</div>

																<div className="question-icon3" style={{ lineHeight: "20px" }}>
																	?
																	<div className="info-box3">
																		<p>
																			<b className="tittle-youtube">¿Cómo acceder a las grabaciones mediante YouTube?</b>
																			<p />
																			<b>Paso I:</b> En YouTube, inicia sesión desde: {user.email}
																			<p />
																			<b>Paso II:</b> Una vez iniciada sesión, abre el enlace directo. Puedes hacer clic en él o
																			copiar y pegar el vínculo en tu navegador.{" "}
																		</p>
																	</div>
																</div>
																<p></p>
															</div>
															<div className="url-video">
																<a href={pastMat.videoUrl2} style={{ wordBreak: "break-all" }}>
																	{pastMat.videoUrl2}
																</a>
															</div>
														</p>
													)}
												</div>

												{pastMat.notes !== undefined && pastMat.notes !== "" && (
													<div>
														<p className="regular-text m-0 text-bolder">Notas:</p>
														<p className="regular-text" style={{ whiteSpace: "pre-wrap" }}>
															{pastMat.notes}
														</p>
													</div>
												)}
												<div style={{ display: "flex" }} className={"material-container justify-content-center"}>
													{pastMat.files.map((file, index) => {
														return (
															<>
																{user.formAnswered ? (
																	<a
																		style={{ textDecoration: "none" }}
																		href={file.linkFile}
																		target="_blank"
																		download={true}
																		key={index}
																	>
																		<button className="material-file-button">
																			{" "}
																			<img src={OpenBook} alt="document-attach-icon" />
																			{getFileName(file.name)}
																		</button>
																	</a>
																) : (
																	<button className="material-file-button" onClick={() => setFormModalShow(true)}>
																		{" "}
																		<img src={OpenBook} alt="document-attach-icon" />
																		{getFileName(file.name)}
																	</button>
																)}
															</>
														);
													})}
												</div>
											</>
										) : (
											<div style={{ display: "flex", justifyContent: "center", marginTop: 20, marginBottom: 20 }}>
												<FontAwesomeIcon icon={faLock as any} style={{ width: 50, height: 50 }} />
											</div>
										)}
									</div>
								);
							})}
						</>
					) : (
						<>
							<h2>
								Una vez finalizada tu inscripción, verás en esta sección los enlaces para las grabaciones, materiales, apuntes y
								respuestas de los ejercicios.
							</h2>
							<h2 style={{ textAlign: "center", marginTop: 40 }}>
								Restante ${(3000 - total).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} MXN
							</h2>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<button onClick={() => history.push("/micuenta/actualizar-pagos")} className="tertiary return-btn-style">
									Añadir un nuevo pago
								</button>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
}

export default MaterialContainer;
