import Footer from "@components/Footer/Footer";
import NavbarCustom from "@components/NavbarCustom/NavbarCustom";
import React, { useContext, useEffect, useState } from "react";
import MobileDetect from "mobile-detect";
import { useAuth } from "../hooks/useAuth";
import IOS_View from "./mobileAboutApp/IOS_View";
import Android_View from "./mobileAboutApp/Android_View";

const mdAPI = new MobileDetect(window.navigator.userAgent);

function MobileAboutAPP() {
	const [navbarOpen, setNavbarOpen] = useState<boolean>(false);
	const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);
	const [OS, setOS] = useState("");
	const [md, setMd] = useState({});

	useEffect(() => {
		setMd(mdAPI);
	}, [md]);

	useEffect(() => {
		if (md["ua"] !== undefined) {
			var data = md["ua"];

			if (data.includes("Mac OS") || data.includes("iPhone")) {
				setOS("IOS");
			} else if (data.includes("Android")) {
				setOS("Android");
			} else {
				setOS("Unknown");
			}
		}
	}, [md]);

	return (
		<>
			<div style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}>
				{OS == "Android" ? (
					<>
						<Android_View />
					</>
				) : (
					<IOS_View></IOS_View>
				)}
			</div>
		</>
	);
}

export default MobileAboutAPP;
