import { useAuth } from "@hooks/useAuth";
import { httpsCallable } from "firebase/functions";
import { functions } from "initFirebase";
import { CardI } from "interfaces/Conekta/Conekta";
import React from "react";
import { Button, Modal } from "react-bootstrap";

type DeleteCardModalProps = {
	show: boolean;
	onHide: (refresh: boolean) => void;
	card: CardI | null;
};

const DeleteCardModal = ({ show, onHide, card }: DeleteCardModalProps) => {
	const { user } = useAuth();
	const deleteCard = () => {
		if (card) {
			let tryDeleteCard = httpsCallable(functions, "deleteCard");
			tryDeleteCard({
				customerId: user.conektaId,
				cardId: card.id,
			}).then((res) => {
				onHide(true);
			});
		}
	};
	return (
		<Modal
			show={show}
			onHide={() => onHide(false)}
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Olvidar tarjeta</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				Se borrará {card?.brand} terminada en {card?.last4} de tu cuenta y ya no podras utilizarla,
				¿estas seguro?
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onHide(false)}>
					Cancelar
				</Button>
				<Button variant="danger" onClick={deleteCard}>
					Confirmar
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteCardModal;
