import { useEffect, useState } from "react";
import { ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Modal from "react-bootstrap/Modal";
import "./CommentModal.scss";
import { addWordReport, getUsers } from "@api/Vocabulary";
import { addDoc, collection } from "firebase/firestore";
import { db } from "initFirebase";
import { AuthProvider, useAuth } from "@hooks/useAuth";

export function CommentModal(props) {
	const { user } = useAuth();

	const [report, setReport] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	const send = async () => {
		let date: any = new Date();
		if (
			date &&
			props.seminary !== undefined &&
			props.word_data_id !== undefined &&
			props.user_id !== undefined &&
			report.length > 0
		) {
			setLoading(true);
			await addWordReport(date, props.seminary, props.word_data_id, report, props.vocabularyUserId)
				.then(async (res) => {
					alert("Muchas gracias por tu observación.\nRealizaremos los cambios pertinentes a la brevedad.");
					setReport("");
					if (user.userType !== 2) {
						await addDoc(collection(db, "notifications"), {
							name: props.user_name,
							text: `<p>Nuevo comentario para el término "${props.word}"</p>`,
							date: date,
							userId: "admin",
							userType: 1,
							seen: false,
							profileColor: props.user_color,
							type: "new-comment-report",
							title: "Hay un nuevo comentario!",
							reportId: res.reportId,
							sectionId: "a77YK7egfFXA0kVOHrFV"
						}).then(() => {
							//sendEmail();
						});
					}
					props.onHide();
				})
				.catch((error) => {
					console.log(error);
					alert("Ha ocurrido un error.");
				});
		} else {
			alert("Ocurrió un error o el mensaje esta vacío, intentalo de nuevo.");
		}
	};
	useEffect(() => {
		setLoading(false);
	}, []);

	return (
		<Modal show={props.show} onHide={props.onHide} className="comment-modal" word={props.word} onShow={() => setLoading(false)}>
			<ModalHeader closeButton>
				<ModalTitle>Señalar término</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<textarea
					className="text-input"
					placeholder="¿Cuál es tu observación?"
					maxLength={300}
					onChange={(e) => setReport(e.target.value)}
				/>
			</ModalBody>
			<ModalFooter>
				<button className="back">
					<div>Regresar</div>
				</button>
				<button
					className={"send"}
					onClick={() => send()}
					disabled={loading}
					style={{ background: loading === true ? "grey" : "" }}
				>
					<div>Enviar</div>
				</button>
			</ModalFooter>
		</Modal>
	);
}
