import "./MiddleScreenStyles.scss";
import { WordRowCard } from "@components/Vocabulary/WordRowCard/WordRowCard";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";

export function MiddleScreen(props) {
	const [key, setKey] = useState<string>("");
	const [actualSegment, setActualSegment] = useState([]);

	const getKey = async () => {
		let tryKey = await firebase.functions().httpsCallable("getAudio");
		tryKey().then((res) => {
			setKey(res.data);
		});
	};

	useEffect(() => {
		getKey();
		let temp = JSON.parse(JSON.stringify(props.actualSegmentQuiz)).sort(function (a, b) {
			return 0.5 - Math.random();
		});
		setActualSegment(temp);
	}, []);

	return (
		<div className="middle-screen-card">
			{props.actualSegment > 0 && (
				<>
					<h2 className="w-100 m-0 dynamic-text">
						<a>{props.headerText}</a>
					</h2>
					<div className="words-progress-bar-container progress-bar-middlescreen">
						<div className="words-progress-bar">
							<div className="words-progress" style={{ width: (props.correctsAnswers.length / props.totalWords) * 100 + "%" }}></div>
						</div>
						<p>
							{props.correctsAnswers.length}/{props.totalWords} términos
						</p>
						<h2>Progreso</h2>
					</div>
				</>
			)}
			<h2 className="w-100 mb-auto block-text">{props.terminosBloque}</h2>
			<div className="words-container">
				{actualSegment.length > 0 &&
					actualSegment.map((word, index) => {
						return (
							<WordRowCard
								word={word}
								language={props.language}
								answerType={props.answerType}
								index={index}
								gKey={key}
								key={"word-middle-screen-" + index}
								countRefresh={props.countRefresh}
								setCountRefresh={props.setCountRefresh}
								setMiddleScreenEditWordModal={props.setMiddleScreenEditWordModal}
							/>
						);
					})}
			</div>
		</div>
	);
}
