import React from 'react';
import { Modulo } from '@models/module';
import { Col, Image, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/esm/Container';
import './styles.scss';

interface ModulesProps {
  data: Modulo;
  image: string;
}
function ModuleB(props: ModulesProps) {
  return (
    <div className="module__container">
      <Container>
        <Row>
          <Col
            xs={{ span: 12, order: 2 }}
            xl={{ span: 7, order: 1 }}
            md={{ span: 7, order: 1 }}
            lg={{ span: 7, order: 1 }}
            className="first__column-b"
          >
            <h4>{props.data.titulo}</h4>
            <p>
              <b>Duración: </b> {props.data.duracion}
            </p>
            <p>
              <b>Habilidades al finalizarlo: </b>
              <ul>
                {props.data.habilidades &&
                  props.data.habilidades.map((i) => {
                    return <li>{i}</li>;
                  })}
              </ul>
            </p>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            xl={{ span: 4, order: 2 }}
            md={{ span: 4, order: 2 }}
            lg={{ span: 4, order: 2 }}
          >
            <Image
              fluid
              src={props.image}
              alt={props.image}
              className="module1"
            />{' '}
          </Col>
          <Col
            xs={{ span: 0, order: 3 }}
            xl={{ span: 3, order: 3 }}
            md={{ span: 3, order: 3 }}
            lg={{ span: 3, order: 3 }}
          ></Col>
        </Row>
      </Container>
    </div>
  );
}

export default ModuleB;
