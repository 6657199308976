import React from "react";
import VocabularySeminaryCard from "../VocabularySeminaryCard/VocabularySeminaryCard";
import { vocabularyModules } from "utils/VocabularyConstants";
import VocabularyModuleCard from "../VocabularyModuleCard/VocabularyModuleCard";
import { useSeminaryVocabularyInfoModalContext } from "contexts/SeminaryVocabularyInfoModalContext";
import { useAuth } from "@hooks/useAuth";

const ModulesContainer = () => {
	const { audioKey } = useSeminaryVocabularyInfoModalContext();
	const { user } = useAuth();
	return (
		<div className="vocabulary-modules-container">
			{user && user?.userType === 2 && (
				<VocabularySeminaryCard
					block={false}
					seminary={0}
					nextClass={false}
					gKey={audioKey}
					lastElementRef={undefined}
				/>
			)}
			{vocabularyModules.map((module) => (
				<VocabularyModuleCard key={`vocabulary-module-${module.number}-card`} module={module} />
			))}
		</div>
	);
};

export default ModulesContainer;
