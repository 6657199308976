import { SeminaryInfoModal } from "@containers/VocabularyContainer/components/SeminaryInfoModal/SeminaryInfoModal";
import { fetchWordsBySeminaryAndLanguage } from "@services/VocabularyService";
import { httpsCallable } from "firebase/functions";
import { functions } from "initFirebase";
import { quizWords } from "interfaces/Interfaces";
import React, { useEffect } from "react";

const vocabularyBySeminaryAndLanguage = {};

type ShowSeminaryInfoModal = {
	show: boolean;
	seminary: number | null;
	language: string | null;
};

type SeminaryVocabularyInfoModalContextType = {
	showSeminaryInfoModal: ShowSeminaryInfoModal;
	openModal: (seminary: number, language: string) => void;
	hideModal: () => void;
	audioKey: string;
	loadingWords: boolean;
};

const SeminaryVocabularyInfoModalContext =
	React.createContext<SeminaryVocabularyInfoModalContextType>({
		showSeminaryInfoModal: { show: false, seminary: null, language: null },
		openModal: () => {},
		hideModal: () => {},
		audioKey: "",
		loadingWords: true,
	});

export const useSeminaryVocabularyInfoModalContext = () => {
	return React.useContext(SeminaryVocabularyInfoModalContext);
};

const SeminaryVocabularyInfoModalProvider = ({ children }: { children: React.ReactNode }) => {
	const [showSeminaryInfoModal, setShowSeminaryInfoModal] = React.useState<ShowSeminaryInfoModal>({
		show: false,
		seminary: null,
		language: null,
	});
	const [words, setWords] = React.useState<quizWords[]>([]);
	const [audioKey, setAudioKey] = React.useState<string>("");
	const [loadingWords, setLoadingWords] = React.useState(true);
	const [countRefresh, setCountRefresh] = React.useState(0);

	const getAudioKey = async () => {
		try {
			let tryKey = httpsCallable(functions, "getAudio");
			const res = await tryKey();
			setAudioKey(res.data as string);
		} catch (error) {
			console.error("Error fetching audio key", error);
		}
	};

	const getWords = async (seminary: number, language: string) => {
		try {
			const res = await fetchWordsBySeminaryAndLanguage(language, seminary, seminary);
			vocabularyBySeminaryAndLanguage[seminary] = {
				...vocabularyBySeminaryAndLanguage[seminary],
				[language]: res,
			};
			setWords(res);
			setLoadingWords(false);
		} catch (error) {
			console.error("Error fetching words", error);
		}
	};

	const openModal = (seminary: number, language: string) => {
		setShowSeminaryInfoModal({ show: true, seminary, language });
	};

	const hideModal = () => {
		setShowSeminaryInfoModal({ show: false, seminary: null, language: null });
		setWords([]);
		setLoadingWords(true);
	};

	useEffect(() => {
		const { show, seminary, language } = showSeminaryInfoModal;
		if (show && seminary !== null && seminary !== undefined && language) {
			const words = vocabularyBySeminaryAndLanguage[seminary]?.[language];
			if (words) {
				setWords(words);
				setLoadingWords(false);
			} else {
				getWords(seminary, language);
			}
		}
	}, [showSeminaryInfoModal]);

	useEffect(() => {
		getAudioKey();
	}, []);

	// necesario para que se actualice al editar una palabra, no borrar
	// en el futuro que se refactorice el edit modal, se eliminara.
	useEffect(() => {
		if (countRefresh > 0) {
			const { show, seminary, language } = showSeminaryInfoModal;
			if (show && seminary && language) {
				getWords(seminary, language);
			}
		}
	}, [countRefresh]);

	return (
		<SeminaryVocabularyInfoModalContext.Provider
			value={{ showSeminaryInfoModal, audioKey, loadingWords, openModal, hideModal }}
		>
			{
				<SeminaryInfoModal
					show={showSeminaryInfoModal.show}
					onHide={hideModal}
					words={words}
					num={showSeminaryInfoModal.seminary || 0}
					gKey={audioKey}
					loading={loadingWords}
					setCountRefresh={setCountRefresh}
					countRefresh={countRefresh}
				/>
			}
			{children}
		</SeminaryVocabularyInfoModalContext.Provider>
	);
};

export default SeminaryVocabularyInfoModalProvider;
