export function Spinner20vh() {
	return (
		<div style={{ height: "20vh", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
			<div className="spinner-border text-danger" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
}
export function SpinnerCustom({ height }) {
	return (
		<div style={{ height: height, display: "flex", justifyContent: "center", alignItems: "center" }}>
			<div className="spinner-border text-danger" role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
}

export function CustomSpinner({ height, borderSize, size }) {
	return (
		<div style={{ height: height, width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
			<div className="spinner-border text-danger" style={{ height: size, width: size, borderWidth: borderSize }} role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
}

export function CustomSpinnerContainer({ height, borderSize, size }) {
	return (
		<div style={{ height: height, width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
			<div className="spinner-border text-danger" style={{ height: size, width: size, borderWidth: borderSize }} role="status">
				<span className="sr-only">Loading...</span>
			</div>
		</div>
	);
}