
import FormContainer from "@containers/FormContainer/FormContainer";

function FormsScreen() {
	return (
		<>
			<div style={{ display: "flex", flexDirection: "column", minHeight: "100%", justifyContent: "space-between" }}>
				<FormContainer />
			</div>
		</>
	);
}

export default FormsScreen;
