
import FormContainer2 from "@containers/FormContainer/FormContainer2";

function FormsScreen2() {

	return (
		<>
			<div style={{ display: "flex", flexDirection: "column", minHeight: "100%", justifyContent: "space-between" }}>
				<FormContainer2 />
			</div>
		</>
	);
}

export default FormsScreen2;
