import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./LanguagesCardStyles.scss";



function LanguagesCard({data}){

    const[language,setLanguage] = useState('');

    const checkData = () =>{
            if(data.idioma == 'french') return setLanguage('Francés');
            if(data.idioma == 'chinese') return setLanguage('Chino');
            if(data.idioma == 'japanese') return setLanguage('Japonés');
            if(data.idioma == 'german') return setLanguage('Alemán');
            if(data.idioma == 'english') return setLanguage('Ingles');
            if(data.idioma == 'portuguese') return setLanguage('Portugués');
            if(data.idioma == 'italian') return setLanguage('Italiano');
            if(data.idioma == 'russian') return setLanguage('Ruso');       
    }

    useEffect(()=>{
        checkData();
    },[data])

    return(
        <div className="vocabulary-languages-card">
            <div className="title">
                <h2>{language}</h2>
                <h3>{data.total_words} términos</h3>
            </div>
            <div className="action">
                <Link to={`/admin/vocabulario/idioma/${data.idioma}/${data.id}`}>
                    <button >Revisar</button>
                </Link>
            </div>
        </div>
        )
}

export default LanguagesCard;