import { useState, useEffect } from "react";
import { db } from "initFirebase";
import { AnyMxRecord } from "dns";

interface Params {
	FileName?: string;
	FileUrl?: string;
	name?: string;
	IsAvailable?: boolean;
	type?: string;
	fileCalendar?: any;
	id?: string;
}

export const useGetCalendar = (criteria: string, value: string, collectionName: string, edit: string) => {
	const [ActualCalendar, setActualCalendar] = useState<Params | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const querySnapshot = await db.collection(collectionName).where(criteria, "==", value).get();
				if (!querySnapshot.empty) {
					const document = querySnapshot.docs[0].data() as Params;
					const id = querySnapshot.docs[0].id;
					setActualCalendar({ ...document, id });
				} else {
					setActualCalendar(null);
				}
			} catch (error) {
				console.error("Error al obtener los datos:", error);
			}
		};

		fetchData();
	}, [criteria, value, collectionName, edit]);

	return { ActualCalendar, setActualCalendar };
};

export const useEditCalendar = (collectionName: string) => {
	const [editedCalendar, setEditedCalendar] = useState<Params | null>(null);
	const [loadCalendar, setLoadCalendar] = useState<boolean>(false);

	const editDataCalendar = async (id: string, newData: Partial<Params>) => {
		try {
			const docRef = db.collection(collectionName).doc(id);
			await docRef.update(newData);
			console.log("hook", newData);
			setEditedCalendar(newData as Params);
		} catch (error) {
			console.error("Error al editar los datos:", error);
		} finally {
		}
	};

	return { editedCalendar, editDataCalendar, setEditedCalendar, loadCalendar, setLoadCalendar };
};
