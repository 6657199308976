import RedHeader from "@components/RedHeader/RedHeader";
import KidsSecondSection from "@components/KidsContainer/KidsSecondSection";
import { useEffect, useState } from "react";
import { db } from "initFirebase";
import { Container } from "react-bootstrap";
import "./styles.scss";
import RedBoxCopie from "@components/KidsContainer/RedBoxCopie/RedBoxCopie";
import { ModulesSection } from "assets/Imports";

function KidsContainer() {
  const [kidsInfo, setKidsInfo] = useState<any>({});
  const [kidsSecondInfo, setKidsSecondInfo] = useState<any>({});

  const getKidsInfo = async () => {
    db.collection("dynamic_copies")
      .doc("PZawOf0iPkuFe5Ldx8DU")
      .collection("primera")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setKidsInfo({
            ...doc.data(),
            id: doc.id,
          });
        });
      });
  };

  const getKidsSecondInfo = async () => {
    db.collection("dynamic_copies")
      .doc("PZawOf0iPkuFe5Ldx8DU")
      .collection("segunda")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setKidsSecondInfo({
            ...doc.data(),
            id: doc.id,
          });
        });
      });
  };

  useEffect(() => {
    getKidsInfo();
    getKidsSecondInfo();
  }, []);

  return (
    <>
      <div className="header__kids">
        <RedHeader titulo={kidsInfo.titulo ? kidsInfo.titulo : "PROGRAMA PARA NIÑOS POLÍGLOTAS"} />
        <Container>
          <h1 className="d-block d-sm-none">{kidsInfo.titulo ? kidsInfo.titulo : "PROGRAMA PARA NIÑOS POLÍGLOTAS"}</h1>
          <div className="description">
            <p className="text-right">
              <li>{kidsInfo.right_copie}</li>
            </p>
            <p>{kidsInfo.paragraph1}</p>
            <p>{kidsInfo.paragraph2}</p>
          </div>
        </Container>
      </div>
      <img src={ModulesSection} alt="Wave" className="wave" />
      <RedBoxCopie price={kidsSecondInfo.redBoxPrice} copie={kidsSecondInfo.redBoxCopie} />
      <div className="section__kids">
        <KidsSecondSection data={kidsSecondInfo} />
      </div>
    </>
  );
}

export default KidsContainer;
