import { Dropdown } from "react-bootstrap";
import mxFlag from "@img/flag-currency-mx.png";
import usFlag from "@img/flag-currency-us.png";
import "./CurrencySelector.scss";
import { useCurrencyContext } from "contexts/CurrencyContext";

const CurrencySelector = () => {
	const { currencyCountry, setCurrencyCountry } = useCurrencyContext();

	const handleCurrencySelection = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCurrencyCountry(e.target.id); // The elements Id contains a string that indicates the country
	};

	return (
		<div className="currency-selector-container flex-center-center">
			<Dropdown>
				<Dropdown.Toggle size={"sm"}>
					<div className="current-currency-container">
						{currencyCountry === "mx" ? (
							<>
								<img src={mxFlag} /><span>Precios en MXN</span>
							</>
						) : (
							<>
								<img src={usFlag} />Precios en USD
							</>
						)}
					</div>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<b>Divisa para compra</b>
					<div className="currency-option">
						<input type="radio" name="currency" id="us" onChange={handleCurrencySelection} checked={currencyCountry === "us"} />
						<label htmlFor="us">Precios en USD</label>
						<img src={usFlag} />
					</div>
					<div className="currency-option">
						<input type="radio" name="currency" id="mx" onChange={handleCurrencySelection} checked={currencyCountry === "mx"} />
						<label htmlFor="mx">Precios en MXN</label>
						<img src={mxFlag} />
					</div>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

export default CurrencySelector;
