import { CloseIcon, PDFIcon, XML } from "assets/Imports";
import "./InvoiceAdminCard.scss";
import { useEffect, useRef, useState } from "react";
import { RejectInvoiceModal } from "./RejectInvoiceModal";
import "./RequestInvoiceModal.scss";
import { InvoiceData, firebaseDateFormatter } from "@services/InvoiceServices";
import { UserProfileModal } from "../PaymentRequestContainer/components/UserProfileModal/UserProfileModal";
import useInvoices from "./useInvoices";
import { getGenerationNameById } from "@services/GenerationService";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { useLocation } from "react-router-dom";
import { SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import useCollaboratorLogs, { collaborator, user } from "@hooks/collaborators/useCollaboratorLogs";
import { getFirestore, Timestamp } from "firebase/firestore";
import { Alert } from "react-bootstrap";
import { Bottletrash } from "assets/Imports";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
export function InvoiceAdmincard({ invoice, ...props }) {
	const [view, setView] = useState<number>(1);
	const [userProfileModal, setUserProfileModal] = useState<boolean>(false);
	const [reqDate, setReqDate] = useState<string>();
	const [rejectedDate, setRejectedDate] = useState<string>();
	const [acceptedDate, setAcceptedDate] = useState<string>();
	const [canceledDate, setCanceledDate] = useState<string>();
	const [userGenName, setUserGenName] = useState<string>();
	const [ableToCancel, setAbleToCancel] = useState<boolean>();
	const [modified, setModified] = useState<boolean>(false); // State to check whether changes have been made in the invoice (For "solicitudes" categorie)
	const [loader, setLoader] = useState<boolean>(false);
	const { invoiceLog } = useCollaboratorLogs();
	const [netPrice, setNetPrice] = useState(invoice.netPrice);
	const [selectedFiscalRegime, setSelectedFiscalRegime] = useState(invoice.fiscalRegime);
	const [selectedUse, setSelectedUse] = useState(invoice.use);
	const [selectedPaymentForm, setSelectedPaymentForm] = useState(invoice.paymentForm);

	let defaultDateValue = ""; // o puedes usar una fecha por defecto

	if (invoice.requestDate) {
		const date = invoice.requestDate.toDate(); // Convertir de Timestamp a Date
		date.setHours(12); // Ajustar la hora a mediodía
		defaultDateValue = date.toISOString().split("T")[0];
	}

	const options: any = {
		day: "numeric",
		month: "long",
		year: "numeric",
	};
	const location: any = useLocation();
	const cardRef: any = useRef(null);

	const statusSpanish = {
		Pending: "Pendiente",
		Accepted: "Aprobada",
		Rejected: "Negado",
		AutoGenerated: "Autogenerada",
		AdminGenerated: "Aprobada",
		Canceled: "Cancelada",
	};
	const paymentFormText = {
		"01": "Efectivo",
		"02": "Cheque nominativo",
		"03": "Transferencia electrónica de fondos",
		"04": "Tarjeta de crédito",
		"05": "Monedero electrónico",
		"06": "Dinero electrónico",
		"08": "Vales de despensa",
		"12": "Dación en pago",
		"13": "Pago por subrogación",
		"14": "Pago por consignación",
		"15": "Condonación",
		"17": "Compensación",
		"23": "Novación",
		"24": "Confusión",
		"25": "Remisión de deuda",
		"26": "Prescripción o caducidad",
		"27": "A satisfacción del acreedor",
		"28": "Tarjeta de débito",
		"29": "Tarjeta de servicios",
		"30": "Aplicación de anticipos",
		"31": "Intermediario pagos",
		"99": "Por definir",
	};

	const handleViewChange1 = () => setView(1);
	const handleViewChange2 = () => setView(2);
	const handleViewChange3 = () => setView(3);

	const handleSaveChanges = async (e) => {
		e.preventDefault();
		const form = e.target.form;
		const docRef = doc(db, "invoices", invoice.id);

		let selectedDate = new Date(form.requestDate.value);
		selectedDate.setHours(selectedDate.getHours() + selectedDate.getTimezoneOffset() / 60);
		const firebaseDate = Timestamp.fromDate(selectedDate);

		updateDoc(docRef, {
			rfc: form.rfc.value,
			corporateName: form.corporateName.value.toUpperCase(),
			email: form.email.value,
			zipCode: form.zipCode.value,
			amount: parseFloat(form.invoiceAmount.value),
			netPrice: parseFloat((form.invoiceAmount.value / 1.16).toFixed(2)),
			fiscalRegime: selectedFiscalRegime,
			street: form.street.value,
			city: form.city.value,
			use: selectedUse,
			state: form.state.value,
			paymentForm: selectedPaymentForm.replace(/_\d+$/, ""),
			requestDate: firebaseDate,
		}).then((res) => {
			invoice.rfc = form.rfc.value;
			invoice.corporateName = form.corporateName.value.toUpperCase();
			invoice.email = form.email.value;
			invoice.zipCode = form.zipCode.value;
			invoice.amount = parseFloat(form.invoiceAmount.value);
			invoice.netPrice = parseFloat((form.invoiceAmount.value / 1.16).toFixed(2));
			invoice.fiscalRegime = selectedFiscalRegime;
			invoice.street = form.street.value;
			invoice.city = form.city.value;
			invoice.use = selectedUse;
			invoice.state = form.state.value;
			invoice.paymentForm = selectedPaymentForm.replace(/_\d+$/, "");
			invoice.requestDate = firebaseDate;
			setModified(false);
			form.reset();
		});
	};

	const handleDiscardChanges = (e) => {
		e.preventDefault();
		e.target.form.reset();
		setModified(false);
		return;
	};

	const handleInvoiceChanges = async () => {
		if (!modified) {
			setModified(true);
		}
	};

	const getUserGenName = async () => {
		let tempGen = await getGenerationNameById(invoice.userGen);
		if (tempGen) {
			setUserGenName(tempGen.replace("Programa de Alto Rendimiento en 8 Idiomas Simultáneos -", ""));
		}
	};

	const dateHandler = () => {
		if (invoice.status === "Pending") {
			let formattedDate = firebaseDateFormatter(invoice.requestDate);
			setReqDate(formattedDate);
		} else if (invoice.status === "Rejected") {
			let formattedDate = firebaseDateFormatter(invoice.rejectedDate);
			setRejectedDate(formattedDate);
		} else if (invoice.status === "Canceled") {
			if (invoice.requestDate) {
				let formattedDate = firebaseDateFormatter(invoice.requestDate);
				setReqDate(formattedDate);
			}
			let formattedDate = invoice.canceledDate ? firebaseDateFormatter(invoice.canceledDate) : "-";
			setCanceledDate(formattedDate);
		} else {
			if (invoice.requestDate) {
				let formattedDate = firebaseDateFormatter(invoice.requestDate);
				setReqDate(formattedDate);
			}
			let formattedDate = firebaseDateFormatter(invoice.acceptedDate);
			setAcceptedDate(formattedDate);
		}
	};

	const handleInvoiceCancelation = async () => {
		if (window.confirm("Estas seguro de cancelar esta factura?")) {
			setLoader(true);
			let tempValidation = await props.cancelInvoice(invoice.ApiId, invoice.id);
			console.log(tempValidation);
			if (props.collaborator && tempValidation) {
				let tempCollaborator: collaborator = {
					name: props.collaborator.name,
					id: props.collaborator.id,
					role: props.collaborator.collaborator_rol ?? "",
				};
				let tempUser: user = { name: invoice.name, id: invoice.userId ?? "" };
				await invoiceLog("cancel", tempCollaborator, tempUser);
				await props.setContactNotificationSeen(invoice.id);
			}
			props.getInvoices();
			setLoader(false);
		} else {
			return;
		}
	};

	const handleDownload_file = async (format: string) => {
		let matricula = "";
		if (invoice.userId && !invoice.matricula) {
			const userRef = doc(db, "users", invoice.userId);
			const userDoc = await getDoc(userRef);

			if (userDoc.exists()) {
				matricula = userDoc.data().matricula;
			}
		} else {
			matricula = invoice.matricula;
		}
		props.downloadInvoice(invoice.ApiId, invoice.ticketNumber, matricula, format);
	};

	const handleAccept = async () => {
		setLoader(true);
		let tempValidation = await props.acceptInvoice(invoice);
		if (props.collaborator && tempValidation) {
			let tempCollaborator: collaborator = {
				name: props.collaborator.name,
				id: props.collaborator.id,
				role: props.collaborator.collaborator_rol ?? "",
			};
			let tempUser: user = { name: invoice.name, id: invoice.userId ?? "" };
			await invoiceLog("accept", tempCollaborator, tempUser);
			await props.setContactNotificationSeen(invoice.id);
		}
		setLoader(false);
	};

	const handleReject = async () => {
		if (window.confirm("Estas seguro de rechazar esta solicitud?")) {
			console.log(invoice.id, invoice.name);
			await props.setContactNotificationSeen(invoice.id);
			props.setInvoiceToReject(invoice);
			props.setRejectInvoiceModal(true);
		} else {
			return;
		}
	};

	const getStatusSpanish = (status: string) => {
		return statusSpanish[status];
	};

	const getPaymentFormText = (pf: string) => {
		return paymentFormText[pf];
	};

	useEffect(() => {
		// Dates come as a firebase timestamp, so is important to format them accordingly
		setReqDate("");
		setRejectedDate("");
		setAcceptedDate("");
		if (invoice !== undefined) {
			dateHandler();
			if (invoice.userGen) {
				getUserGenName();
				if (!invoice.userGenName) {
					props.addGeneration(invoice);
				}
			}
		}
		// Solo se establece la vista en 1 si no está ya en otro estado
		if (view !== 2 && view !== 3) {
			setView(1);
		}
		if (invoice.createdAt) {
			let tempValidation = props.cancelationValidation(invoice.createdAt);
			setAbleToCancel(tempValidation);
		}
	}, [invoice, props.cat, invoice.requestDate]);

	useEffect(() => {
		if (location?.state !== undefined && location?.state?.invoiceId === cardRef.current.id) {
			props.setCategorie(location?.state.cat);
			cardRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
			cardRef.current.style.border = "3px solid red";
			location.state = undefined;
		} else {
			cardRef.current.style.border = "none";
		}
	}, [location?.state]);

	return (
		<div id={invoice.id} ref={cardRef} className="invoice-admin-card-main-container">
			<form onSubmit={handleSaveChanges}>
				<UserProfileModal
					id={invoice.userId}
					key={invoice.userId}
					user={invoice}
					show={userProfileModal}
					onHide={() => setUserProfileModal(false)}
					collaborator={props.collaborator}
				/>
				{(view === 1 || view === 3) && (
					<div className="iac-container-v1">
						<div className="iac-left-part-container">
							<div className="section-1">
								<span
									className={
										"name " +
										(invoice.status === "Rejected" || invoice.status === "Canceled"
											? "red"
											: invoice.status !== "Pending"
											? "green"
											: "")
									}
								>
									{invoice.name}
								</span>
								<span className="amount">${invoice.amount.toString().slice(0, 10)} MXN</span>
							</div>
							<div className="section-2">
								{invoice.status === "Pending" ? (
									<>
										<span className="date-label">Fecha de solicitud</span>
										<span className="actual-date">{reqDate}</span>
									</>
								) : invoice.status === "Rejected" ? (
									<>
										<span className="date-label">Fecha de rechazo</span>
										<span className="actual-date red">{rejectedDate}</span>
									</>
								) : invoice.status === "Canceled" ? (
									<>
										<span className="date-label">Fecha de cancelación</span>
										<span className="actual-date red">{canceledDate}</span>
									</>
								) : (
									<>
										<span className="date-label">Fecha de aceptación</span>
										<span className="actual-date green">{acceptedDate}</span>
									</>
								)}
							</div>
							<div className="section-3">
								<span className="state-label">Estado</span>
								<span
									className={
										"actual-state " +
										(invoice.status === "Rejected" || invoice.status === "Canceled"
											? "red"
											: invoice.status === "Pending"
											? "grey"
											: "green")
									}
								>
									{getStatusSpanish(invoice.status)}
								</span>
							</div>
						</div>
						<div className="iac-right-part-container" style={{ display: "flex", alignItems: "center" }}>
							{view === 1 && (
								<>
									{invoice.status === "Rejected" || invoice.status === "Pending" ? (
										<button
											type="button"
											className="check-btn"
											onClick={invoice.status === "Rejected" ? handleViewChange3 : handleViewChange2}
										>
											Revisar
										</button>
									) : (
										<button className="check-invoice-btn" onClick={handleViewChange2}>
											Ver factura
										</button>
									)}
								</>
							)}
							{view === 3 && <img src={CloseIcon} className="iac-close-btn" onClick={handleViewChange1} alt="Cerrar"></img>}
						</div>
					</div>
				)}
				{view === 3 && (
					<div className="iac-reject-container">
						<span className="label">Motivo del rechazo</span>
						<span className="value">{invoice.rejectReason}</span>
					</div>
				)}
				{view === 2 && (
					<div className="iac-container-v2">
						<img src={CloseIcon} className="iac-close-btn" onClick={handleViewChange1}></img>
						<div className="section-1">
							<div className="row top">
								<span
									className={
										"name " +
										(invoice.status === "Rejected" || invoice.status === "Canceled"
											? "red"
											: invoice.status !== "Pending"
											? "green"
											: "")
									}
								>
									{invoice.name}
								</span>
								<button className="view-profile-responsive" onClick={() => setUserProfileModal(true)} type="button">
									Ver perfil
								</button>
							</div>
							{invoice.userGen && (
								<div className="row">
									<div className="column" style={{ width: "100%" }}>
										<span className="label">Generación</span>
										<span className="value">{userGenName}</span>
									</div>
								</div>
							)}
							<div className="row">
								{invoice.status === "Pending" ? (
									<div className="column">
										<span className="label">RFC</span>
										<span className="value">
											<input defaultValue={invoice.rfc} maxLength={15} onChange={handleInvoiceChanges} name="rfc" required />
										</span>
									</div>
								) : (
									<div className="column">
										<span className="label">RFC</span>
										<span className="value">{invoice.rfc}</span>
									</div>
								)}
								{invoice.status === "Pending" ? (
									<div className="column">
										<span className="label">Razón social</span>
										<span className="value">
											<textarea
												defaultValue={invoice.corporateName}
												onChange={handleInvoiceChanges}
												name="corporateName"
												maxLength={70}
												required
											/>
										</span>
									</div>
								) : (
									<div className="column">
										<span className="label">Razón social</span>
										<span className="value">{invoice.corporateName}</span>
									</div>
								)}
							</div>

							<div className="row" style={{ alignItems: "center" }}>
								{invoice.status === "Pending" ? (
									<div className="column">
										<span className="label">Régimen fiscal</span>
										<select
											name="fiscalRegime"
											value={selectedFiscalRegime}
											onChange={(e) => {
												setSelectedFiscalRegime(e.target.value);
												handleInvoiceChanges();
											}}
											required
											style={{ borderRadius: "14px", padding: "2%" }}
										>
											<option value="" disabled>
												Selecciona una opción
											</option>
											<option value="601">(601) General de Ley Personas Morales</option>
											<option value="603">(603) Personas Morales con Fines no Lucrativos</option>
											<option value="605">(605) Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
											<option value="606">(606) Arrendamiento</option>
											<option value="607">(607) Régimen de Enajenación o Adquisición de Bienes</option>
											<option value="608">(608) Demás ingresos</option>
											<option value="609">(609) Consolidación</option>
											<option value="610">(610) Residentes en el Extranjero sin Establecimiento Permanente en México</option>
											<option value="611">(611) Ingresos por Dividendos (socios y accionistas)</option>
											<option value="612">(612) Personas Físicas con Actividades Empresariales y Profesionales</option>
											<option value="614">(614) Ingresos por intereses</option>
											<option value="615">(615) Régimen de los ingresos por obtención de premios</option>
											<option value="616">(616) Sin obligaciones fiscales</option>

											<option value="620">(620) Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
											<option value="621">(621) Incorporación Fiscal</option>
											<option value="622">(622) Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
											<option value="623">(623) Opcional para Grupos de Sociedades</option>
											<option value="624">(624) Coordinados</option>
											<option value="625">
												(625) Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas
											</option>
											<option value="626">(626) Régimen Simplificado de Confianza</option>
											<option value="628">(628) Hidrocarburos</option>
											<option value="629">(629) De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</option>
											<option value="630">(630) Enajenación de acciones en bolsa de valores</option>
										</select>
									</div>
								) : (
									<div className="column">
										<span className="label">Régimen fiscal</span>
										<span className="value">{invoice.fiscalRegime}</span>
									</div>
								)}
								{invoice.requestDate &&
									(invoice.status === "Pending" ? (
										<div className="column">
											<span className="label">Fecha de solicitud</span>
											<span className="value">
												<input
													type="date"
													defaultValue={defaultDateValue}
													onChange={handleInvoiceChanges}
													name="requestDate"
													required
												/>
											</span>
										</div>
									) : (
										<div className="column">
											<span className="label">Fecha de solicitud</span>
											<span className="value">{reqDate}</span>
										</div>
									))}
							</div>

							<div className="row">
								{acceptedDate ? (
									<>
										<div className="column ">
											<span className="label">Correo</span>
											<span className="value">{invoice.email}</span>
										</div>
										<div className="column">
											<span className="label">Fecha de aceptación</span>
											<span className="value green">{acceptedDate}</span>
										</div>
									</>
								) : invoice.status === "Pending" ? (
									<div className="column bottom">
										<span className="label">Correo</span>
										<span className="value">
											<input
												defaultValue={invoice.email}
												onChange={handleInvoiceChanges}
												name="email"
												required
												maxLength={50}
												type="email"
											/>
										</span>
									</div>
								) : invoice.status === "Canceled" ? (
									<>
										<div className="column ">
											<span className="label">Correo</span>
											<span className="value">{invoice.email}</span>
										</div>
										<div className="column">
											<span className="label">Fecha de cancelación</span>
											<span className="value red">{canceledDate}</span>
										</div>
									</>
								) : (
									<div className="column bottom">
										<span className="label">Correo</span>
										<span className="value">{invoice.email}</span>
									</div>
								)}
							</div>
						</div>
						<div className="separator"></div>
						<div className="section-2">
							<div className="row">
								{invoice.status === "Pending" ? (
									<>
										<div className="column">
											<span className="label">Estado</span>
											<span className="value">
												<textarea
													defaultValue={invoice.state}
													onChange={handleInvoiceChanges}
													name="state"
													maxLength={70}
													required
													style={{ width: "100%", height: "100%", overflow: "auto", whiteSpace: "normal" }}
												/>
											</span>
										</div>
									</>
								) : (
									<div className="column">
										<span className="label">Estado</span>
										<span className="value">{invoice.state}</span>
									</div>
								)}
							</div>

							<div className="row">
								{invoice.status === "Pending" ? (
									<div className="column">
										<span className="label">Calle</span>
										<span className="value">
											<textarea
												defaultValue={invoice.street}
												onChange={handleInvoiceChanges}
												name="street"
												maxLength={70}
												required
												style={{ width: "100%", height: "100%", overflow: "auto", whiteSpace: "normal" }}
											/>
										</span>
									</div>
								) : (
									<div className="column">
										<span className="label">Calle</span>
										<span className="value">{invoice.street}</span>
									</div>
								)}
							</div>
							<div className="row">
								{invoice.status === "Pending" ? (
									<div className="column">
										<span className="label">Código postal</span>
										<span className="value">
											<input
												defaultValue={invoice.zipCode}
												type="text"
												onChange={handleInvoiceChanges}
												name="zipCode"
												required
												maxLength={11}
											/>
										</span>
									</div>
								) : (
									<div className="column">
										<span className="label">Código postal</span>
										<span className="value">{invoice.zipCode}</span>
									</div>
								)}
							</div>
							<div className="row">
								{invoice.status === "Pending" ? (
									<div className="column">
										<span className="label">Ciudad</span>{" "}
										<input
											defaultValue={invoice.city}
											type="text"
											style={{ padding: "2%", borderRadius: "14px" }}
											onChange={handleInvoiceChanges}
											name="city"
											required
											maxLength={45}
										/>
									</div>
								) : (
									<div className="column">
										<span className="label">Ciudad</span>
										<span className="value">{invoice.city}</span>
									</div>
								)}
							</div>
						</div>
						<div className="separator-2"></div>
						<div className="section-3">
							<div className="row">
								<div className="column-price">
									<div style={{ display: "flex", flexDirection: "column" }}>
										<span className="label">Precio neto: {"  "}</span>
										<span className="value">$ {invoice.netPrice} MXN</span>
									</div>

									<div className="column-price-button">
										<button
											className="view-profile"
											onClick={() => {
												setUserProfileModal(true);
											}}
											type="button"
										>
											Ver perfil
										</button>
									</div>
								</div>
							</div>

							<div className="row">
								{invoice.status === "Pending" ? (
									<div className="column">
										<span className="label">Uso de factura</span>
										<select
											name="use"
											value={selectedUse}
											onChange={(e) => {
												setSelectedUse(e.target.value);
												handleInvoiceChanges();
											}}
											required
											style={{ borderRadius: "14px", padding: "2%", width: "50%" }}
										>
											<option value="" disabled>
												Selecciona una opción
											</option>
											<option value="G03">Gastos en general</option>
											<option value="D10">Pagos por servicios educativos (colegiaturas)</option>
										</select>
									</div>
								) : (
									<div className="column">
										<span className="label">Uso de factura</span>
										<span className="value">{invoice.use}</span>
									</div>
								)}

								<div className="column-2">
									<span className="label">Estado</span>
									<span
										className={
											"value " +
											(invoice.status === "Rejected" || invoice.status === "Canceled"
												? "red"
												: invoice.status === "Pending"
												? "grey"
												: "green")
										}
									>
										{getStatusSpanish(invoice.status)}
									</span>
								</div>
							</div>
							<div className="row">
								{invoice.status === "Pending" ? (
									<div className="column">
										<span className="label">Método de pago:</span>
										<select
											name="fiscalRegime"
											value={selectedPaymentForm}
											onChange={(e) => {
												setSelectedPaymentForm(e.target.value);
												handleInvoiceChanges();
											}}
											required
											style={{ borderRadius: "14px", padding: "2%", maxWidth: "120px" }}
										>
											<option value="" disabled>
												Selecciona una opción
											</option>
											<option value="01">Efectivo</option>
											<option value="03_1">Oxxo Pay</option>
											<option value="03_2">SPEI</option>
											<option value="03_3">Transferencia</option>
											<option value="03_4">Depósito Bancario</option>
											<option value="04_1">Tarjeta</option>
											<option value="04_2">Paypal</option>
											<option value="03_5">Otro</option>
										</select>
									</div>
								) : (
									<div className="column">
										<span className="label">Método de pago</span>
										<span className="value">{getPaymentFormText(invoice.paymentForm)}</span>
										{/* <span className="p-method-detail"> {getPaymentFormText(invoice.paymentForm)}</span> */}
									</div>
								)}

								{invoice.status === "Pending" ? (
									<div className="column-2">
										<span className="label">Monto facturado</span>
										<span className="value">
											$
											<input
												defaultValue={invoice.amount}
												type="number"
												style={{ width: "50%" }}
												onChange={handleInvoiceChanges}
												name="invoiceAmount"
												required
												max={300000}
											/>
											MXN
										</span>
									</div>
								) : (
									<div className="column-2">
										<span className="label">Monto facturado</span>
										<span className="value">$ {invoice.amount} MXN</span>
									</div>
								)}
							</div>
							<div className="invoice-action-btns">
								{invoice.status === "Pending" ? (
									<img
										src={Bottletrash}
										title="Borrar solicitud."
										alt="Borrar factura"
										style={{ marginRight: "10px", cursor: "pointer" }}
										onClick={() => {
											Swal.fire({
												title: `¿Eliminar la solicitud de ${invoice?.name}?`,
												text: "La solicitud se eliminará permanentemente.",
												icon: "warning",
												showCancelButton: true,
												confirmButtonColor: "#3085d6",
												cancelButtonColor: "#d33",
												confirmButtonText: "Sí, eliminar",
												cancelButtonText: "No, cancelar",
												backdrop: false,
											}).then(async (result) => {
												if (result.isConfirmed) {
													try {
														await props.deleteInvoiceById(invoice.id); // Esperar a que se complete la operación de eliminación

														await props.getInvoices();

														if (props.collaborator) {
															let tempCollaborator: collaborator = {
																name: props.collaborator.name,
																id: props.collaborator.id,
																role: props.collaborator.collaborator_rol ?? "",
															};
															let tempUser: user = { name: invoice.name, id: "" };
															await invoiceLog("deleted", tempCollaborator, tempUser);
														}

														toast.success("Factura eliminada con éxito");
														//fetchRoles(); // Llama a fetchRoles solo después de que se haya completado la operación de eliminación
													} catch (error) {
														console.error("Hubo un error al borrar la factura:", error);
														toast.error("Hubo un error al borrar la factura");
													}
												}
											});
										}}
									/>
								) : null}
								{invoice.paymentReceiptLink && !invoice.acceptedDate && (
									<a href={invoice.paymentReceiptLink} target="_blank">
										<button className="see-receipt-btn" type="button">
											Ver comprobante
										</button>
									</a>
								)}
								{invoice.status === "Pending" && (
									<>
										<button
											className="reject-btn"
											onClick={handleReject}
											type="button"
											disabled={modified}
											style={modified ? { backgroundColor: "lightgray", borderColor: "gray", color: "gray" } : {}}
										>
											Rechazar
										</button>
										{ableToCancel ? (
											<button
												className={"approve-btn" + (loader === true ? "disabled-btn" : "")}
												onClick={handleAccept}
												type="button"
												disabled={modified || loader}
												style={modified ? { backgroundColor: "lightgray", borderColor: "gray", color: "gray" } : {}}
											>
												{!loader ? (
													"Aprobar"
												) : (
													<div className="spinner">
														<SpinnerCustom height={"auto"} />
													</div>
												)}
											</button>
										) : (
											<Alert variant={"warning"}>El periodo para aprobar esta factura ya pasó.</Alert>
										)}
									</>
								)}
								{invoice.acceptedDate && (
									<>
										<button
											type="button"
											className="see-receipt-btn"
											onClick={() => {
												handleDownload_file("xml");
											}}
										>
											<img src={XML} alt="Icono de XMl"></img>
										</button>
										<button
											type="button"
											className="see-receipt-btn"
											onClick={() => {
												handleDownload_file("pdf");
											}}
										>
											<img src={PDFIcon} alt="Icono de PDF"></img>
										</button>

										{ableToCancel && (
											<button type="button" disabled={loader} className="reject-btn" onClick={handleInvoiceCancelation}>
												{!loader ? (
													"Cancelar factura"
												) : (
													<div className="spinner">
														<SpinnerCustom height={"auto"} />
													</div>
												)}
											</button>
										)}
									</>
								)}
								{!invoice.paymentReceiptLink && !invoice.acceptedDate && (
									<Alert variant={"danger"}>Esta solicitud no cuenta con un comprobante.</Alert>
								)}
							</div>
						</div>
					</div>
				)}
				{modified && view === 2 && (
					<div className="invoice-changes-container">
						<button onClick={handleDiscardChanges} className="discard-changes-btn">
							Descartar cambios
						</button>
						<button type="submit" onClick={handleSaveChanges} className="save-changes-btn">
							Guardar cambios
						</button>
					</div>
				)}
			</form>
		</div>
	);
}
