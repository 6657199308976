import { getAllCategories, getSubCategories, updateWordTest } from "@api/Vocabulary";
import { returnWarningTypeImage } from "@containers/VocabularySeminaryContainer/functions/VocabularyFunctions";
import { returnFlag } from "functions/Functions";
import { quizWords, quizWordsEditModal } from "interfaces/Interfaces";
import { category, subCategory } from "interfaces/Vocabulary/VocabularyInterfaces";
import { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import Modal from "react-bootstrap/Modal";
import "./EditWordModal.scss";
import { WrenchIcon } from "assets/Imports";
import useVocabulary from "@hooks/useVocabulary";
import { SpinnerCustom } from "@components/Spinner/SpinnerMyAccountView";
import { useVocabularySeminaryContext } from "contexts/VocabularySeminaryContext";
import { useAuth } from "@hooks/useAuth";
export function EditWordModal(props) {
	const { getWordData } = useVocabulary();
	const [word, setWord] = useState(props.word);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingSave, setLoadingSave] = useState<boolean>(false);
	const [categories, setCategories] = useState<Array<category>>(new Array());
	const [subCategories, setSubCategories] = useState<Array<subCategory>>(new Array());
	const [warningType, setWarningType] = useState<string>("warning");
	const [editCategory, setEditCategory] = useState<boolean>(false);
	const [editSubCategory, setEditSubCategory] = useState<boolean>(false);
	const [value, setValue] = useState<string>(""); // Content inside React Quill 'avisos'
	const [value2, setValue2] = useState<string>(""); // Content inside React Quill 'aviso extendido'
	const [warn, setwarn] = useState<Array<string>>([
		"warning",
		"memory",
		"grammar",
		"advice",
		"fake_friend",
		"similarity",
		"pronunciation",
		"did_YouKnow",
	]);
	const { getSeminary, view } = useVocabularySeminaryContext();
	let user = useAuth().user;
	var Font = Quill.import("formats/font");
	Font.whitelist = ["cirilico"];
	Quill.register(Font, true);
	var Block = Quill.import("blots/block");
	Block.tagName = "p";
	Block.className = "pre";
	Quill.register(Block, true);
	var toolbarOptions = [
		[{ size: ["small", false, "large", "huge"] }], // custom dropdown
		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		["bold", "italic", "underline", "strike"],
		[{ indent: "-1" }, { indent: "+1" }], // toggled buttons

		["clean"], // remove formatting button
	];

	const onChange = (e) => {
		setWord({ ...word, [e.target.name]: e.target.value });
	};

	const onChangeSubCategories = (e) => {
		const newValue = parseInt(e.target.value);
		const tempSubCat = JSON.parse(JSON.stringify(word.sub_category));
		const index = tempSubCat.findIndex((e) => e === newValue);

		if (index === -1) {
			tempSubCat.push(newValue);
		} else {
			tempSubCat.splice(index, 1);
		}
		setWord({ ...word, [e.target.name]: tempSubCat });
	};
	const getCategories = async () => {
		await getAllCategories().then(async (resCat) => {
			await getSubCategories().then((resSubCat) => {
				setCategories(
					resCat.categories.sort((a: any, b: any) =>
						a.family > b.family ? 1 : a.family < b.family ? -1 : 0
					)
				);
				setSubCategories(
					resSubCat.categories.sort((a: any, b: any) =>
						a.sub_family > b.sub_family ? 1 : a.sub_family < b.sub_family ? -1 : 0
					)
				);
			});
		});
	};

	const getWord = async (categories_temp, sub_categories_temp) => {
		if (word.native_word !== undefined) {
			let meaning = "";
			await Promise.all(
				word.meaning.map((me, index) => {
					meaning += me + (word.meaning.length - 1 === index ? "" : "|");
				})
			);

			// Se guardan los ids para poder utilizarlo de esta manera en los selects
			const subCategoriesTemp: Array<any> = [];
			word.sub_category.map((sub) => {
				if (sub !== null) {
					const id: number = sub_categories_temp.filter((e) => e.sub_family === sub)[0].id;
					subCategoriesTemp.push(id);
				}
			});

			setWord({
				...word,
				meaning: meaning,
				category:
					categories_temp[
						categories_temp.findIndex(
							(e) =>
								e.family === (typeof word.category === "string" ? word.category : word.category[0])
						)
					].id,
				sub_category: subCategoriesTemp,
			});

			setLoading(false);
		}
	};

	const getWordById = async (wordDataId: number) => {
		await getWordData(wordDataId)
			.then((res) => {
				setWord(res);
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	const saveWord = async () => {
		const meanings = word.meaning.toString();
		setLoadingSave(true);

		const data = {
			word_id: word.word_id,
			word_data_id: word.id,
			user_id: user.id,
			native_word: word.native_word,
			meanings: meanings.split("|"),
			family_id: parseInt(word.category.toString()),
			warning: word.warning,
			memory: word.memory,
			grammar: word.grammar,
			advice: word.advice,
			language_Id: parseInt(word.language_Id),
			fake_friend: word.fake_friend,
			similarity: word.similarity,
			optional_word: word.optional_word === "" ? null : word.optional_word,
			sub_category: word.sub_category,
			pronunciation: word.pronunciation ? word.pronunciation : "",
			did_YouKnow: word.did_YouKnow ? word.did_YouKnow : "",
		};

		await updateWordTest(data)
			.then((res) => {
				// Call the getWords function from props to refetch data
				if (props.getQuiz !== undefined) {
					props.getQuiz(true);
				} else if (props.getWords !== undefined) {
					props.getWords();
				} else if (props.countRefresh !== undefined) {
					props.setCountRefresh(props.countRefresh + 1);
				} else if (props.getAllWordReports) {
					props.getAllWordReports();
				} else if (getSeminary !== undefined && view === 1) {
					getSeminary();
				}
				onHide();
				setLoadingSave(false);
			})
			.catch((error) => {
				console.error(error);
				alert("Ha ocurrido un error.");
				setLoadingSave(false);
			});
	};

	function returnSubCatValues(sub_categories: Array<any>) {
		let value = "";
		sub_categories.map((subCat, i) => {
			if (subCategories.filter((e) => e.id === subCat).length > 0) {
				value +=
					subCategories.filter((e) => e.id === subCat)[0].sub_family +
					(i < sub_categories.length - 1 ? ", " : "");
			}
		});
		return value;
	}

	const onHide = () => {
		setWord(undefined);
		setLoading(true);
		props.onHide();
	};

	useEffect(() => {
		if (categories.length === 0) {
			getCategories();
		}
	}, []);

	useEffect(() => {
		if (word !== undefined && word !== null && props.show && loading && !!categories.length) {
			getWord(categories, subCategories);
		}
	}, [word, props.show, categories]);

	useEffect(() => {
		if (props.show && props.word_data_id && word === undefined) {
			getWordById(props.word_data_id);
		} else if (props.show && props.word !== undefined) {
			setWord(props.word);
		}
	}, [props.show]);

	useEffect(() => {
		if (word) {
			setWord({ ...word, [warningType]: value });
		}
	}, [value]);

	return (
		<Modal
			show={props.show}
			onHide={onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			className="edit-word-in-test-modal"
			centered
		>
			<Modal.Header closeButton>
				<img className="llave-img" src={WrenchIcon} alt="icono de llave inglesa" />
				<Modal.Title id="contained-modal-title-vcenter">Editar término</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<SpinnerCustom height={400} />
				) : (
					<div className="container">
						<div className="row">
							<div className="col-sm mb-3">
								<label htmlFor="native_word" className="form-label">
									{word.language === "chinese" ? "Pinyin" : "Término"}
									{returnFlag(word.language)}
								</label>
								<input
									type="text"
									className="form-control"
									id="native_word"
									name="native_word"
									value={word.native_word}
									onChange={onChange}
								/>
							</div>
							<div className="col-sm mb-3">
								<label htmlFor="native_word" className="form-label">
									Significado + variaciones
								</label>
								<input
									type="text"
									className="form-control"
									id="meaning"
									name="meaning"
									value={word.meaning}
									onChange={onChange}
								/>
								<legend className="mt-2" style={{ fontSize: "0.875rem" }}>
									El carácter | es el separador de palabras.
								</legend>
							</div>
						</div>
						{(word.language === "chinese" || word.language === "japanese") && (
							<div className="row">
								<div className="col-sm mb-3">
									<label htmlFor="native_word" className="form-label">
										{word.language === "chinese" && "Kanzi"}
										{word.language === "japanese" && "Kanji"}
									</label>
									<input
										type="text"
										className="form-control"
										id="optional_word"
										name="optional_word"
										value={word.optional_word === null ? "" : word.optional_word}
										onChange={onChange}
									/>
								</div>
								<div className="col-sm mb-3"></div>
							</div>
						)}
						<div className="row">
							<div className="col-sm mb-3">
								<label htmlFor="warning" className="form-label d-flex align-items-center">
									Categoría
									<img
										className="llave-img"
										src={WrenchIcon}
										alt="icono de llave inglesa"
										role={"button"}
										onClick={() => setEditCategory(!editCategory)}
									/>
								</label>
								{editCategory ? (
									<div className="warning-note-inputs-container ">
										<select
											value={word.category}
											className="form-control w-100"
											name="category"
											onChange={onChange}
											aria-label="Default select example"
										>
											<option value="none">Seleccionar una categoría</option>
											{categories.length > 0 &&
												categories.map((category, index) => {
													if (category.singular !== "") {
														return (
															<option value={category.id} key={"category-option-select-" + index}>
																{category.singular}
															</option>
														);
													}
												})}
										</select>
									</div>
								) : (
									<div className="warning-note-inputs-container readOnly ">
										<input
											type="text"
											className="form-control border-0"
											id="category"
											name="category"
											value={
												categories.filter((e) => e.id === parseInt(word.category.toString()))[0]
													?.singular
											}
											disabled
										/>
									</div>
								)}
							</div>
							<div className="col-sm mb-3">
								<label htmlFor="warning" className="form-label d-flex align-items-center">
									Sub categorías
									<img
										className="llave-img"
										src={WrenchIcon}
										alt="icono de llave inglesa"
										role={"button"}
										onClick={() => setEditSubCategory(!editSubCategory)}
									/>
									{editSubCategory && (
										<a
											style={{ cursor: "pointer" }}
											className="clean-subcategories "
											onClick={() => {
												editSubCategory && (word.sub_category = []);
											}}
										>
											Limpiar
										</a>
									)}
								</label>
								{editSubCategory ? (
									<div className="warning-note-inputs-container ">
										<select
											value={word.sub_category}
											multiple
											className="form-control w-100"
											name="sub_category"
											onChange={onChangeSubCategories}
											aria-label="Default select example"
										>
											<option value="none">Seleccionar una categoría</option>
											{subCategories.length > 0 &&
												subCategories.map((subCategory, index) => {
													return (
														<option
															value={subCategory.id}
															key={"sub-category-option-select-" + index}
														>
															{subCategory.sub_family}
														</option>
													);
												})}
										</select>
									</div>
								) : (
									<div className="warning-note-inputs-container readOnly ">
										<input
											type="text"
											className="form-control border-0"
											id="category"
											name="category"
											value={returnSubCatValues(word.sub_category)}
											disabled
										/>
									</div>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-sm mb-3">
								<label htmlFor="warning" className="form-label">
									Notas
								</label>
								<div className="warning-note-inputs-container">
									{returnWarningTypeImage(warningType)}
									<select
										className="form-control"
										value={warningType}
										onChange={(e) => setWarningType(e.target.value)}
										name="warningType"
										id="warningType"
									>
										<option value="warning">Advertencia</option>
										<option value="memory">Memoria</option>
										<option value="grammar">Gramática</option>
										<option value="advice">Consejo</option>
										<option value="fake_friend">Falso amigo</option>
										<option value="similarity">Similitud</option>
										<option value="pronunciation">Pronunciación</option>
										<option value="did_YouKnow">¿Sabías que...?</option>
									</select>
								</div>
								<div>
									{warn.map((val: any, index: number) => {
										if (val === warningType) {
											return (
												<ReactQuill
													modules={{ toolbar: toolbarOptions }}
													theme="snow"
													defaultValue={word[warningType]}
													onChange={setValue}
													key={"textQuill " + index}
												/>
											);
										}
									})}
								</div>
							</div>
						</div>
					</div>
				)}
			</Modal.Body>
			<Modal.Footer className="justify-content-center justify-content-sm-between">
				<button className="action-btn secondary-btn-bg" onClick={props.onHide}>
					Cerrar
				</button>
				<button
					disabled={loadingSave}
					className={`action-btn linear-bg ${loadingSave ? "disabled-btn" : ""}`}
					onClick={saveWord}
				>
					Guardar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
