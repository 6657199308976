import { UserProfileModal } from "@containers/Admin/PaymentRequestContainer/components/UserProfileModal/UserProfileModal";
import { useAuth } from "@hooks/useAuth";
import { query, collection, getDocs, where, doc, getDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { useState } from "react";

function TRow({ studentInfo, getUsers, index }) {
	const [loadCheck1, setLoadCheck1] = useState<boolean>(false);
	const [loadCheck2, setLoadCheck2] = useState<boolean>(false);
	const [refresh, setRefresh] = useState(false);
	const [gen, setGen]: any = useState(null);
	const [student, setStudent]: any = useState(null);
	const userDocRef = doc(db, "users", studentInfo.id);
	const [userProfileModal, setUserProfileModal] = useState<boolean>(false);
	const { user } = useAuth();
	const collaborator = user;
	const getUser = async () => {
		const userRef = doc(db, "users", studentInfo.id);
		const docSnap = await getDoc(userRef);

		if (docSnap.exists() && docSnap.data().generationId !== undefined && docSnap.data().generationId !== "") {
			const genRef = doc(db, "generations", docSnap.data()?.generationId);
			const genSnap = await getDoc(genRef);

			if (genSnap.exists()) {
				setGen({ id: genSnap.id, ...genSnap.data() });
				getStudent({ id: genSnap.id, ...genSnap.data() }, { id: docSnap.id, ...docSnap.data() });
			}
		} else {
			getStudent({ initialDate: "10/10/2022" }, { id: docSnap.id, ...docSnap.data() });
		}
	};

	const getStudent = async (gen, studentInfo) => {
		console.log("getstudent")
		let dateSplit = gen.initialDate.split("/");
		const date = new Date();
		date.setDate(dateSplit[0]);
		date.setMonth(dateSplit[1] - 1);
		date.setFullYear(dateSplit[2]);
		let userr = {};
		let pagos = new Array();
		let totalPay = 0;
		let status = "";
		let weeks = 0;
		const q = query(collection(db, "paymentHistory"), where("userId", "==", studentInfo.id), where("verified", "==", true));

		const querySnapshot = await getDocs(q);

		if (querySnapshot.size > 0) {
			querySnapshot.forEach((doc) => {
				totalPay = totalPay + parseInt(doc.data().amount);
				pagos.push({ id: doc.id, ...doc.data() });
			});
		}

		if ((querySnapshot.size === 1 && totalPay === 12000) || studentInfo.promotion === true) {
			status = "Pago completo";
			userr = {
				...studentInfo,
				status: status,
				totalPay: totalPay,
				numPayments: querySnapshot.size,
				paidModules: 5,
				pagos: pagos.sort(comparePaymentsDate),
			};
		} else {
			let modules = 0;
			if (totalPay >= 3000 && totalPay < 7500) {
				modules = 1;
			} else if (totalPay >= 7500 && totalPay < 12000) {
				modules = 2;
			} else if (totalPay >= 12000 && totalPay < 15000) {
				modules = 3;
			} else if (totalPay >= 15000 && totalPay < 18000) {
				modules = 4;
			} else if (totalPay == 18000) {
				modules = 5;
			} else {
				modules = 0;
			}
			weeks = Math.floor(totalPay / 1500) * 5;
			let nextDate = new Date();
			nextDate.setDate(dateSplit[0]);
			nextDate.setMonth(dateSplit[1] - 1);
			nextDate.setFullYear(dateSplit[2]);
			let now = new Date();
			nextDate.setDate(date.getDate() + weeks * 7);
			nextDate.setHours(0, 0, 0);
			if (now > nextDate) {
				status = "Pago retrasado";
			} else {
				status = "Pago completo";
			}

			userr = {
				...studentInfo,
				status: status,
				totalPay: totalPay,
				numPayments: querySnapshot.size,
				nextDate: nextDate,
				paidModules: modules,
				pagos: pagos.sort(comparePaymentsDate),
			};
		}
		setStudent(userr);
	};

	function comparePaymentsDate(a, b) {
		let x = a.createdAt.split("/");
		let y = b.createdAt.split("/");
		const date1 = new Date();
		const date2 = new Date();
		date1.setDate(x[0]);
		date1.setMonth(x[1]);
		date1.setFullYear(x[2]);
		date2.setDate(y[0]);
		date2.setMonth(y[1]);
		date2.setFullYear(y[2]);

		if (date1 > date2) {
			return -1;
		}
		if (date1 < date2) {
			return 1;
		}
		return 0;
	}

	const handleCheck = async (e, id) => {
		setLoadCheck1(true);

		console.log(e.target.checked);

		var userRef = db.collection("users").doc(id);

		// Set the "capital" field of the city 'DC'
		await userRef
			.update({
				formAnswered: e.target.checked,
			})
			.then(() => {
				getUsers();
			});
	};

	const handleCheckForm2 = async (e, id) => {
		setLoadCheck2(true);
		console.log(e.target.checked);

		var userRef = db.collection("users").doc(id);

		// Set the "capital" field of the city 'DC'
		await userRef
			.update({
				formAnswered2: e.target.checked,
			})
			.then(() => {
				getUsers();
			});
	};

	// useEffect(() => {
	// 	getUser();
	// 	setLoadCheck1(false);
	// 	setLoadCheck2(false);
	// }, [user]);

	return (
		<tr>
			{/* {student !== null && ( */}
				<>
					<UserProfileModal
						id={studentInfo.id}
						setRefresh={setRefresh}
						refresh={refresh}
						show={userProfileModal}
						onHide={() => setUserProfileModal(false)}
						key={studentInfo.id}
						collaborator={collaborator}
					/>
				</>
			{/* )} */}
			<td>{studentInfo.name}</td>
			<td className="email-td">{studentInfo.email}</td>
			<td>
				<button className="action-btn linear-bg" onClick={() => setUserProfileModal(true)}>
						Ver perfil
				</button>
			</td>
			<td>{studentInfo.phone}</td>
			<td>{studentInfo.matricula !== "" ? studentInfo.matricula : "No inscrito"}</td>
			<td>{studentInfo.schedule !== "" ? studentInfo.schedule : "No inscrito"}</td>
			<td>
				{studentInfo.lastSeen !== undefined ? (
					<span>
						{new Date(studentInfo.lastSeen.seconds * 1000).toLocaleDateString("en-gb")} <br />{" "}
						{new Date(studentInfo.lastSeen.seconds * 1000).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })}
					</span>
				) : (
					"Sin registro"
				)}
			</td>
			<td className="text-center">{studentInfo.modules}</td>
			<td className="text-center">
				{loadCheck1 ? (
					<div className="spinner-border text-danger" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				) : (
					<input type="checkbox" name="checkbox" id={"checkbox" + index} checked={studentInfo.formAnswered} onChange={(e) => handleCheck(e, studentInfo.id)} />
				)}
			</td>
			<td className="text-center">
				{loadCheck2 ? (
					<div className="spinner-border text-danger" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				) : (
					<input
						type="checkbox"
						name="checkbox"
						id={"checkbox" + index}
						checked={studentInfo.formAnswered2}
						onChange={(e) => handleCheckForm2(e, studentInfo.id)}
					/>
				)}
			</td>
		</tr>
	);
}

export default TRow;
