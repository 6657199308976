import { consultGermanDictionary, getWord } from "../../functions/TestFunctions";
import "./TestWordContainerStyles.scss";

export function TestWordContainer(props) {
	const answerType = props.answerType !== undefined ? props.answerType : props.quiz.answerType;
	return (
		<div className="test-word-container">
			{(props.quiz!.language !== "chinese" && props.quiz!.language !== "japanese") ||
			((props.quiz!.language === "chinese" || props.quiz!.language === "japanese") && answerType !== "normal") ? (
				<h2 className="text-center" onClick={() => props.listenWord()} role="button">
					{props.quiz!.language === "german" &&
						props.actualSegmentQuiz.singular_category === "sustantivo" &&
						answerType === "normal" &&
						!props.actualSegmentQuiz.native_word.includes("eine Million") &&
						props.actualSegmentQuiz.sub_category.length > 0 && (
							<span style={{ color: "#999999" }}>
								{consultGermanDictionary(props.actualSegmentQuiz.sub_category).toString() + " "}
								{consultGermanDictionary(props.actualSegmentQuiz.sub_category).toString().length > 0 && <br />}
							</span>
						)}
					<span>{getWord(answerType, props.actualSegmentQuiz)}</span>
				</h2>
			) : (
				<h2
					className="text-center d-flex justify-content-center"
					style={{ gap: 10, flexWrap: "wrap" }}
					onClick={() => props.listenWord()}
					role="button"
				>
					{/* {getWord(answerType, props.actualSegmentQuiz)} */}
					{props.quiz!.language === "chinese" && props.answerType === undefined ? (
						props.actualSegmentQuiz
							.optional_word!.replace(/\s/g, "")
							.split("")
							.map((char, index) => {
								return (
									<span className="d-flex" style={{ flexDirection: "column" }} key={"char-" + index}>
										<span>{char + " "}</span>
										{props.seeInfo && (
											<span
												style={{
													color: "#00000080",
													fontSize: "2rem",
												}}
											>
												{props.actualSegmentQuiz.native_word.split(" ")[index]}
											</span>
										)}
									</span>
								);
							})
					) : (
						<span
							className="d-flex"
							style={{
								flexDirection: props.answerType === undefined ? "column" : "row",
								gap: props.answerType === undefined ? 0 : 5,
							}}
						>
							<span>
								{props.actualSegmentQuiz.optional_word !== null && props.actualSegmentQuiz.optional_word !== ""
									? props.actualSegmentQuiz.optional_word
									: props.actualSegmentQuiz.native_word}
							</span>
							{props.seeInfo &&
								props.actualSegmentQuiz.optional_word !== null &&
								props.actualSegmentQuiz.optional_word !== "" && (
									<span
										style={{
											color: "#00000080",
											fontSize: "2rem",
										}}
									>
										{props.actualSegmentQuiz.native_word}
									</span>
								)}
						</span>
					)}
				</h2>
			)}
			<p className={"see-info-btn " + (props.seeInfo ? "active" : "")} onClick={() => props.setSeeInfo(!props.seeInfo)}>
				{props.seeInfo ? "Ocultar detalles" : "Ver detalles"}
			</p>
			{props.seeInfo && (
				<>
					<div className="text-p">
						{props.actualSegmentQuiz.singular_category === undefined
							? props.actualSegmentQuiz.category.map((word) => {
									if (word !== null) return word + " ";
							  })
							: props.actualSegmentQuiz.singular_category}
					</div>
					<div className="text-p">
						{props.actualSegmentQuiz.sub_category.map((word, index1) => {
							if (word !== null)
								return (
									<>
										{word.includes(".") ? (
											<>
												{word
													.replaceAll(".", ";.;")
													.split(";")
													.map((char, index) => {
														if (char === "mf" || char === "fm") {
															return (
																<>
																	<span
																		style={{
																			color: props.dictionarySubColor[char[0]],
																		}}
																	>
																		{char[0]}
																	</span>
																	<span
																		style={{
																			color: props.dictionarySubColor[char[1]],
																		}}
																	>
																		{char[1] +
																			(props.actualSegmentQuiz.sub_category.length > 1 &&
																			index1 <= props.actualSegmentQuiz.sub_category.length - 2 &&
																			index === word.replaceAll(".", ";.;").split(";").length - 1
																				? ", "
																				: "")}
																	</span>
																</>
															);
														} else {
															return (
																<span
																	key={"char-color-sub-category-" + index}
																	style={{
																		color: props.dictionarySubColor[char] ? props.dictionarySubColor[char] : "#000000",
																	}}
																>
																	{char +
																		(props.actualSegmentQuiz.sub_category.length > 1 &&
																		index1 <= props.actualSegmentQuiz.sub_category.length - 2 &&
																		index === word.replaceAll(".", ";.;").split(";").length - 1
																			? ", "
																			: "")}
																</span>
															);
														}
													})}
											</>
										) : (
											<span
												style={{
													color: props.dictionarySubColor[word] ? props.dictionarySubColor[word] : "#000000",
												}}
											>
												{word +
													(props.actualSegmentQuiz.sub_category.length > 1 &&
													index1 <= props.actualSegmentQuiz.sub_category.length - 2
														? ", "
														: "")}
											</span>
										)}
										{props.actualSegmentQuiz.sub_category.length > 1 && " "}
									</>
								);
						})}{" "}
					</div>
				</>
			)}
		</div>
	);
}
