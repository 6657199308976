import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc } from "firebase/firestore";
import { db } from "initFirebase";
import { IGalleries, IGalleryData } from "interfaces/Interfaces";
import firebase from "firebase/compat/app";
import { v4 as uuidv4 } from "uuid";

export async function retreiveGalleries(): Promise<IGalleryData> {
	const q = query(collection(db, "galleries"));
	const querySnapshot = await getDocs(q);
	const galleries: any = [];
	const settings: any = [];
	querySnapshot.forEach((doc) => {
		if (doc.id === "settings") {
			settings.push({ id: doc.id, ...doc.data() });
		} else {
			galleries.push({ id: doc.id, ...doc.data() });
		}
	});
	return { galleries: galleries.sort((a, b) => a.order - b.order), settings: settings[0] };
}
export async function addGalleries(body: IGalleries) {
	await addDoc(collection(db, "galleries"), {
		title: body.title,
		image: body.image,
		imageResponsive: body.imageResponsive,
		order: body.order,
	});
	return "exito";
}
export async function updateGallerySettings(interval: number) {
	const galleryRef = doc(db, "galleries", "settings");
	await updateDoc(galleryRef, { interval });
	return "exito";
}
export async function updateGalleries(body: IGalleries) {
	const galleryRef = doc(db, "galleries", body.id as string);
	await updateDoc(galleryRef, {
		title: body.title,
		image: body.image,
		imageResponsive: body.imageResponsive,
		order: body.order,
		imageUSD: body.imageUSD ? body.imageUSD : "",
		imageResponsiveUSD: body.imageResponsiveUSD ? body.imageResponsiveUSD : "",
	});
	return "exito";
}
export async function deleteGallery(body: IGalleries) {
	await deleteDoc(doc(db, "galleries", body.id as string));
	return "exito";
}
export async function UploadImage(file: any): Promise<any> {
	let image_url = "";
	const upload = await firebase.storage().ref(`galleries/${uuidv4()}/${file.name}`).put(file);
	await upload.ref.getDownloadURL().then(async (res) => {
		image_url = res;
	});
	return image_url;
}
