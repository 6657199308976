import { createContext, useContext, useEffect, useState } from "react";
import { addDoc, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "initFirebase";
import { consults } from "../containers/ConsultPostContainer/Interfaces/Interfaces";
import { useParams } from "react-router-dom";
import { useAuth } from "@hooks/useAuth";
import { getAuth } from "firebase/auth";

export const ConsultServieContext = createContext({
	verifiedConsults: Array<consults>(),
	personalConsults: Array<consults>(),
	unVerifiedConsults: Array<consults>(),
	addConsult: () => {},
	updateConsult: () => {},
	deleteConsult: () => {},
});

const ConsultServiceProvider = ({ children }) => {
	const [verifiedConsults, setVerifiedConsults] = useState<Array<consults>>([]);
	const [unVerifiedConsults, setUnVerifiedConsults] = useState<Array<consults>>([]);
	const [personalConsults, setPersoalConsults] = useState<Array<consults>>([]);
	const [validated, setValidated] = useState<boolean>(false);
	const { num } = useParams<any>();
	let user = useAuth().user;
	user = { ...user, id: getAuth().currentUser!.uid };

	const getConsultsAndUnsub = () => {
		let verifiedConsultsArray = new Array();
		let unVerifiedConsultsArray = new Array();
		let myConsults = new Array();

		const q = query(collection(db, "consults"), where("seminaryNum", "==", parseInt(num)), orderBy("date", "desc"));

		const unsubscribe = onSnapshot(q, (querySnapshot) => {
			verifiedConsultsArray = [];
			unVerifiedConsultsArray = [];
			myConsults = [];

			const data = querySnapshot.docs.map((consult, index) => {
				const data: any = {
					id: consult.id,
					...consult.data(),
				};

				if (data.verified === true) {
					if (verifiedConsults.some((item) => item.id === data.id) === false) {
						verifiedConsultsArray.push(data);
					}
					if (data.userId === user.id) {
						if (personalConsults.some((item) => item.id === data.id) === false) {
							myConsults.push(data);
						}
					}
				} else {
					if (
						user.userType === 2 ||
						user.userType === 4 ||
						(data.userId === user.id && user.userType !== 2 && user.userType !== 4)
					) {
						if (unVerifiedConsults.some((item) => item.id === data.id) === false) {
							unVerifiedConsultsArray.push(data);
						}
					}
				}
			});

			if (validated === true) {
				console.log("si");
				setPersoalConsults([...personalConsults, ...myConsults]);
				setVerifiedConsults([...verifiedConsults, ...verifiedConsultsArray]);
			}
			setUnVerifiedConsults([...unVerifiedConsults, ...unVerifiedConsultsArray]);
		});

		return unsubscribe;
	};

	const validateHour = async () => {
		if (user.userType !== 2 && user.userType !== 4 && parseInt(num) !== 0) {
			const seminaryRef = query(collection(db, "generations", user.generationId, "material"), where("num", "==", parseInt(num)));

			const docSnap = await getDocs(seminaryRef);

			const seminary = docSnap.docs[0].data();

			const dateSeminarySplit = seminary.date.split("/");
			const dateSeminary = new Date();
			dateSeminary.setDate(dateSeminarySplit[0]);
			dateSeminary.setMonth(dateSeminarySplit[1]);
			dateSeminary.setFullYear(dateSeminarySplit[2]);
			dateSeminary.setUTCHours(23, 0, 0);

			const today = new Date();

			if (today >= dateSeminary) {
				setValidated(true);
				console.log("validado");
			} else {
				console.log("no validado");
				setValidated(false);
			}
		} else {
			setValidated(true);
		}
	};

	useEffect(() => {
		validateHour();
		const unsubscribe = getConsultsAndUnsub();
		return () => {
			unsubscribe();
		};
	}, [validated]);

	const updateConsult = (consult, seminaries) => {};

	return (
		<ConsultServieContext.Provider
			value={{
				verifiedConsults: verifiedConsults,
				personalConsults: personalConsults,
				unVerifiedConsults: unVerifiedConsults,
				addConsult: () => {},
				updateConsult: () => {},
				deleteConsult: () => {},
			}}
		>
			{children}
		</ConsultServieContext.Provider>
	);
};

export default ConsultServiceProvider;

export const useConsultService = () => useContext(ConsultServieContext);
