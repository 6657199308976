import NewsletterContainer from '@components/EnrollmentContainer/NewsletterContainer/NewsletterContainer';
import { Location, WaveOther } from 'assets/Imports';
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import {
  FaFacebook,
  FaWhatsapp,
  FaEnvelope,
  FaInstagram,
} from 'react-icons/fa';
import './styles.scss';

function ContactContainer() {
  return (
    <div>
      <div className="header__contact">
        <Container>
          <h1>Contacto</h1>
        </Container>
      </div>
      <img src={WaveOther} alt="Logo" className="wave" />
      <Container className="socials">
        <Row>
          <Col xl={6} lg={6} md={12} xs={12} className="social_icons">
            <a
              href="https://www.facebook.com/academiadelenguas"
              className="border-0 rounded-0 p-0"
            >
              <FaFacebook size={60} color={'black'} className="align-middle" />
              <span className="align-middle">
                <u>Academia de Lenguas</u>
              </span>
            </a>
            <br />
            <a
              href={'https://www.instagram.com/academiadelenguas/'}
              className="border-0 rounded-0 p-0"
            >
              <FaInstagram size={60} color={'black'} className="align-middle" />
              <span className="align-middle">
                @<u>academiadelenguas</u>
              </span>
            </a>
            <br />
            <a
              href="https://wa.me/525623925453"
              className="border-0 rounded-0 p-0"
            >
              <FaWhatsapp size={60} color={'black'} className="align-middle" />
              <span className="align-middle">+52 56 2392 5453</span>
            </a>
            <br />
            <a
              href="mailto:contacto@academiadelenguasinternacionales.mx"
              className="border-0 rounded-0 p-0"
            >
              <FaEnvelope size={60} color={'black'} className="align-middle" />
              <span className="align-middle">
                <u>Enviar correo</u>
              </span>
            </a>
          </Col>
          <Col xl={6} lg={6} md={0} xs={12} className="image-col">
            <Image fluid src={Location} alt="Contacto" />
          </Col>
        </Row>
        <Row>
          <Col md={1}></Col>
          <Col md={10}>
            <NewsletterContainer />
          </Col>
          <Col md={1}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactContainer;
